import * as React from 'react';
import { View, Text, TextInput, ImageBackground, Alert, Dimensions, TouchableOpacity, useWindowDimensions } from 'react-native';
import styles from '../../Styles/styles';
import Spinner from 'react-native-loading-spinner-overlay';
import { CheckIfEmailExists, GetLocalUserManual, ResetEmail } from '../../Helpers/Functions/Online/UserFunctions';
import { useContext } from 'react';
import { learnMoreLookupData } from '../../Helpers/Storage/LearnMoreInfoLookup';
import AppContext from '../../Contexts/AppContext';
import { useIsFocused } from '@react-navigation/native';
import VGInput from '../../Components/Input/Input';
import VGButton from '../../Components/Button/Button';

const image = { uri: "/Users/lenniebudgell/Documents/SKPLY/verigrain/vgrepos/testreact/vgtest/assets/bgmenu.png" }

function ForgotPasswordScreen({ navigation }: { navigation: any }) {
    const [email, setEmail] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const windowHeight = useWindowDimensions().height;
    const currentContext = useContext(AppContext);
    const isFocused = useIsFocused();
    const appContext = React.useContext(AppContext);
    React.useEffect(() => {
      if(isFocused)
      {
        const getPages = async() => {

          let data =await GetLocalUserManual();
          if(data != null)
          {
            currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'PasswordReset'))
          }
          else
          {
            currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='PasswordReset'));
    
          }
        };
        getPages();
            }
    },[isFocused])


    async function onResetPress() {
      const status = await CheckIfEmailExists(email);

      if(status)
      {
        const reset = await ResetEmail(email)

        if(reset)
        {
            appContext.setAlertOptions({title:'Password email sent',desc:'Please check your inbox.',options:[
            {text:'OK', role:'PRIMARY', onPress:() => {
                appContext.setShowAlert(false);
              }}
          ]});
           appContext.setShowAlert(true); 
        }
      }
    }

    return (

      <View style={{flex:1,justifyContent:'flex-start'}}>





<ImageBackground source={require('../../assets/mainmenusplash.png')} style={{width: Dimensions.get('window').width,height: Dimensions.get('window').height}}>

        {isLoading ?     <Spinner
        visible={isLoading}
        textContent={'Resetting Email...'}
        cancelable={false}
        textStyle={styles.spinner}
        /> : null }

<VGInput label='Email' value={email}
    onChangeText={setEmail}
/>

    <View style={{alignItems:'stretch', justifyContent: 'center', marginTop:10,marginHorizontal:10 }}>
    
    <VGButton role="PRIMARY" onPress={onResetPress}
    >Reset</VGButton>
</View>
     
        </ImageBackground>
      </View>
  
    )
  }



  export default ForgotPasswordScreen;