import * as React from 'react';
import { DrawerActions } from '@react-navigation/native';

import { View, Text, TouchableOpacity, DeviceEventEmitter, Dimensions } from 'react-native';

import Toast from 'react-native-tiny-toast';
import { ApiCall } from '../../Helpers/Functions/GeneralFunctions';
import { ApiResponse } from '../../Helpers/GeneralTypes';
import VGButton from '../../Components/Button/Button';



function NotActivatedScreen({ route, navigation }: { route: any, navigation: any }) {


  let { email } = route.params;


  var width = Dimensions.get('window').width; //full width
  var height = Dimensions.get('window').height; //full height



  DeviceEventEmitter.addListener('OpenMenu', OpenDrawer);
  function OpenDrawer() {
    navigation.dispatch(DrawerActions.openDrawer())
  }


  async function onSubscribePress(){


    try {
      const resentEmail = await ApiCall<ApiResponse>("/api/send-activation-email", { Emailaddress: email });

      if (resentEmail.response == 0) {
        Toast.show('Activation Email sent');
      }
      else {
        Toast.show('Could not send activation email at this time');
      }
    }
    catch (error) {
      console.log('could not send activation email')
    }
  }


  return (
    <View style={{ alignContent: 'flex-start', marginHorizontal: 10, flex: 1, justifyContent: 'flex-start' }}>


      <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', backgroundColor: 'orange', paddingVertical: 3 }}>
        <Text style={{ color: 'white', fontSize: 28, alignSelf: 'center' }}>Account not activated</Text>
      </View>

      <View style={{ marginTop: 60 }}>
        <Text style={{ fontSize: 22, alignSelf: 'center', textAlign: 'center' }}>You must subscribe to our service before using the rest of this app</Text>
      </View>
      
      <VGButton role="PRIMARY" buttonStyle={{marginTop:20}} onPress={onSubscribePress}
      >Subscribe</VGButton>

    </View>
  );
}


export default NotActivatedScreen;