import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, Image, TouchableOpacity, Dimensions, Alert } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome';
import { DeleteField, UpdateField, ValidateField } from '../../../Helpers/Functions/Offline/FieldFunctions';
import { Field } from '../../../Helpers/Storage/UserState';
import { Overlay } from 'react-native-elements';
import { setProp } from '../../../Helpers/GeneralTypes';
import { FormatErrorMessages, safeParseInt } from '../../../Helpers/Functions/GeneralFunctions';
import { useContext } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { defaultField } from '../../../Helpers/Storage/DefaultStates';
import AppContext from '../../../Contexts/AppContext';
import spacing from '../../../Styles/Spacing';
import VGInput from '../../../Components/Input/Input';

import VGButton from '../../../Components/Button/Button';
import textStyles from '../../../Styles/Text';
import FieldForm from '../../../Components/Forms/FieldForm';

function UpdateFieldScreen(props: { currentField: Field, setCurrentField: setProp<Field>, fields: Field[], setFields: setProp<Field[]>, isModifyingField: boolean, setIsModifyingField: setProp<boolean>, deletedFields: Field[], setDeletedFields: setProp<Field[]> }) {
   
    const appContext = useContext(AppContext);

    const [currentField,setCurrentField] = React.useState<Field>({...props.currentField});

    async function onSavePress() {

        try {

            const profile = await GetData('Profile');
            const validator = await ValidateField(currentField);
            if(validator.length == 0)
            {


            const status = await UpdateField(currentField);

            if (status) {
                //update the location and the bin list
                let oldLocList = props.fields;
                let index = oldLocList.findIndex(x => x.id == currentField.id);
                oldLocList[index] =currentField;
                props.setFields([...oldLocList]);
                Toast.show('Updated Field')
                props.setIsModifyingField(false);
              //  currentContext.setCurrentScreenName({id:'FieldMenu'});

            }
            else {
                Toast.show('Could not update field');
            }
        }
        else{
            const fullString =  FormatErrorMessages(validator);
            appContext.setAlertOptions({title:'Invalid Field',desc:fullString,options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);   
        }
        }
        catch (error) {
            appContext.setAlertOptions({title:'Could not updated fields',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true); 
        }


    }

    function onDeletePress(){


        appContext.setAlertOptions({title:'Are you sure you want to delete this field?',desc:'Deleted information will be retained for 6 months',options:[
            {text:'Delete', role:'DESTRUCTIVE', onPress:async() => {
                try {
                    const profile = await GetData('Profile');
                    let fieldStatus = await DeleteField(props.currentField)

                    //If it's a valid id, remove the field from the field list
                    //and add it to the deleted list
                    if (fieldStatus != null) {
                        let oldFieldList = props.fields;
                        let oldDeletedFieldList = props.deletedFields;
                        if (oldDeletedFieldList == null) {
                            oldDeletedFieldList = [];
                        }
                        let index = oldFieldList.findIndex(x => x.id == props.currentField.id);

                        oldDeletedFieldList.push(oldFieldList[index])
                        oldFieldList.splice(index, 1);
                        props.setDeletedFields(oldDeletedFieldList);
                        props.setFields(oldFieldList);
                        props.setIsModifyingField(false);
                     appContext.setShowAlert(false);


                    }
                }
                catch (error) {
                    console.log(error);
             
                    appContext.setAlertOptions({title:'Could not delete field',desc:'Please try again at a later time',options:[{text:'OK', role:'PRIMARY', onPress:()=>{appContext.setShowAlert(false);}}]})
                }
                appContext.setShowAlert(false);}},
            {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
          ]});
           appContext.setShowAlert(true); 

    }


    

    return (
        <View style={spacing.MainContainer} >
            <View style={spacing.ScreenHeader}>
                <Text style={textStyles.title}>Edit Field</Text>
            </View>

            <FieldForm currentField={currentField} setCurrentField={setCurrentField} />

            <VGButton role="PRIMARY" onPress={onSavePress}
                >Save</VGButton>

            <VGButton role="DESTRUCTIVE" onPress={onDeletePress}
                >Delete</VGButton>

            <VGButton role="CANCEL" 
                onPress={() => {
                    props.setIsModifyingField(false);
                }}>Close</VGButton>

        </View>
    )
}

export default UpdateFieldScreen;