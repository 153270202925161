
import React from "react";
import { View, Text, Image, TouchableOpacity, Alert, FlatList, } from "react-native";
import Toast from "react-native-tiny-toast";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { setProp } from "../../../Helpers/GeneralTypes";
import { BinLocation } from "../../../Helpers/Storage/UserState";
import { ListLocState } from "./BinsScreen";
import { UpdateBinLocation } from "../../../Helpers/Functions/Offline/BinFunctions";
import VGButton from "../../../Components/Button/Button";
import AppContext from "../../../Contexts/AppContext";

type PropTypes = {
    locations: BinLocation[];
    setLocations: setProp<BinLocation[]>;
    parentMode: ListLocState;
    setParentMode: setProp<ListLocState>;
}

/** 
 * Shows a list of deleted Bin Locations and allows selecting them for restoration
 */
function RestoringBinLocationScreen(props: PropTypes) {
    const appContext = React.useContext(AppContext);
    const [tempDeletedLocs, setTempDeletedLocs] = React.useState<BinLocation[]>([]);
    const [locsToRestore, setLocsToRestore] = React.useState<BinLocation[]>([]);

    React.useEffect(() => {
        if (props.locations != null) {
            setTempDeletedLocs(props.locations.filter(x => x.expired == 1));
        }
    }, [props.locations]);

    function ToggleRestore(loc: BinLocation) {
        const index = locsToRestore.findIndex(x => x.id == loc.id);
        const tempLocs = locsToRestore;
        console.log(index);
        console.log(tempLocs);
        console.log(loc);

        if (index != -1) {
            tempLocs.splice(index, 1);
        }
        else {
            tempLocs.push(loc);
            console.log(tempLocs);
        }


        setLocsToRestore([...tempLocs]);
    }

    async function onRestorePress() {
        try {
            const profile = await GetData('Profile');

            let restoredLocs = [];

            for(const location of locsToRestore)
            {
                const restore = {...location,expired:0};
                const status = await UpdateBinLocation(restore);
                if(status)
                {
                    restoredLocs.push(restore);
                }
            }
      
            if (restoredLocs != undefined && restoredLocs.length > 0) {
                const oldLocList = props.locations;
        

                const updatedList = oldLocList.map(location => {
                    if(restoredLocs.find(x => x.id == location.id))
                    {
                        return {...location, expired:0}
                    }
                    else
                    {
                        return location;
                    }
                })


                

                props.setLocations(updatedList);
                setLocsToRestore([]);
           
                props.setParentMode({...props.parentMode,current_mode:'list_locations'});
            }
            else {
                Toast.show('Could not restore storage locations');
            }
        }
        catch
        {
            appContext.setAlertOptions({title:'Could not restore storage locations',desc:'Please try again at a later time',options:[{text:'OK',role:'PRIMARY', onPress:() =>{appContext.setShowAlert(false);}}]})
            appContext.setShowAlert(true);   
        }

    }

    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', marginHorizontal: 10 }}>

            {(tempDeletedLocs == null || tempDeletedLocs.length == 0) ?
                <Text style={{ fontSize: 18, alignSelf: 'center' }}>No Storage Locations to Restore.</Text>
                : null}

            <FlatList
                data={tempDeletedLocs}
                renderItem={({ item, index }: { item: BinLocation, index: number }) =>
                    <View >
                        <View style={{ flexDirection: 'row', borderWidth: 1, borderColor: 'lightgrey', marginVertical: 3, alignSelf: 'stretch' }}>
                            <TouchableOpacity
                            accessibilityLabel={item.location}
                                style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignSelf: 'center', padding: 5 }}
                                onPress={() => {
                                    ToggleRestore(item);
                                }} >
                                {locsToRestore.findIndex(x => x.id == item.id) != -1 ?
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/OrangeField.png')} />
                                    :
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/BlueField.png')} />
                                }
                                <Text style={{ fontSize: 18, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5 }}>{item.location}</Text>
                            </TouchableOpacity>
                        </View>

                    </View>
                }
                keyExtractor={(item, index) => index.toString()}
            >
            </FlatList>

            <VGButton role="PRIMARY" onPress={onRestorePress} >Restore</VGButton>
            
            <VGButton role="CANCEL" 
                onPress={() => {
                    props.setParentMode({...props.parentMode,current_mode:'list_locations'});

                }}
            >Cancel</VGButton>
        </View>)
}

export default RestoringBinLocationScreen