import React, { useState, useEffect, useRef } from 'react'
import { View, Text, FlatList, useWindowDimensions, StyleSheet, TouchableOpacity } from 'react-native'
import Svg, { Image, Path, SvgUri, SvgXml } from 'react-native-svg'
import { Bin, BinLocation, BinSampleHistory, GrainSubCrop, GrainType, StorageType } from '../../../Helpers/Storage/UserState';
import { CapacityType, GetBinCapacities, StorageData } from '../../../Helpers/Functions/Offline/StorageFunctions';
import BinSVG from './bin_images'
import { CalculateBinImage } from '../../../Helpers/Functions/Offline/BinFunctions';
import { useNavigationState, } from '@react-navigation/native';
import textStyles from '../../../Styles/Text';
import styles from '../../../Styles/styles';





function StorageDetailScreen({ route, navigation }: { route: {params: {
  bin: Bin;
  grainData: {
      grainType: GrainType;
      grainSubCrop: GrainSubCrop;
  };
  volume: number;
  location: BinLocation;
  storageType: StorageType;
  lastState: string;
}[]
          },
  navigation: any }) {

  const windowWidth = useWindowDimensions().width;
  let renderSeparatorView = () => {
    return (
      <View style={{
        height: 2,
        width: windowWidth,
        backgroundColor: "orange",
      }}
      />
    );
  };

  return (
    <View style={{ flex: 1, justifyContent: 'space-between' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
        <Text style={[styles.title]} accessible={true} accessibilityLabel={route.params[0].grainData.grainType.name}>{route.params[0].grainData.grainType.name} Bins</Text>
      </View>
      <FlatList
        data={route.params}
        keyExtractor={(item, index) => index.toString()}
        ItemSeparatorComponent={renderSeparatorView}
        renderItem={({ item, index }:{item:{
          bin: Bin;
          grainData: {
              grainType: GrainType;
              grainSubCrop: GrainSubCrop;
          };
          volume: number;
          location: BinLocation;
          storageType: StorageType;
          lastState: string;
        },index:number}) => {

          let bin_image = CalculateBinImage(item?.volume ?? 0, item.bin.size, item.storageType?.short_name ?? '', item?.lastState == 'in' ? 'in' :'out' ?? 'in');
          return(
          <TouchableOpacity onPress={() => {
            navigation.navigate("BinDetailsScreen",{bin:item.bin,binLocation:item.location,grainData:item.grainData,storageType:item.storageType,currentVolume:item.volume,status:item.lastState})
          }}
          accessibilityLabel='Storage_Details'
          style={{ flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', marginVertical: 3, left: 15, paddingEnd: 20 }}>
            <View style={{ flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'space-between', marginVertical: 8, left: 15, paddingEnd: 20, paddingBottom: 5 }}>
              <Text style={[pageStyles.textInfo, textStyles.info]} accessible={true} accessibilityLabel={'name'}>Name: {item.bin.name}</Text>
              <Text style={[pageStyles.textInfo, textStyles.info]} accessible={true} accessibilityLabel={'location'}>Location: {item.location.location}</Text>
              <Text style={[pageStyles.textInfo, textStyles.info]} accessible={true} accessibilityLabel={'volume'}>Volume: {item?.volume ?? 0}bu / {item.bin.size}bu</Text>
              <Text style={[pageStyles.textInfo, textStyles.info]} accessible={true} accessibilityLabel={'freespace'}>Free Space: {item.bin.size - (item?.volume ?? 0)}bu</Text>

            </View>
            <BinSVG accessible={true} accessibilityLabel={bin_image} binImage={bin_image} width={125} height={150} />
          </TouchableOpacity>
          )
        }
        }
      />
    </View>
  );
};


export default StorageDetailScreen;
const pageStyles = StyleSheet.create({
  textInfo: { fontSize: 16, alignSelf: 'flex-start', flexWrap: 'wrap', flexShrink: 1 },
});