import React, { useContext } from "react";
import { Alert, TouchableOpacity, View,TextInput,Image,FlatList,Text } from "react-native";
import Toast from "react-native-tiny-toast";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { GetBinMakeModelData, GetBinsByLocation, GetDeletedBins, UpdateBinLocation } from "../../../Helpers/Functions/Offline/BinFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { Bin, BinLocation, BinMake, BinModel } from "../../../Helpers/Storage/UserState";
import { ListLocState } from "./BinsScreen";
import styles from "../../../Styles/styles";
import Spinner from 'react-native-loading-spinner-overlay';
import UpdateBinScreen from "./UpdateBinScreen";
import RestoringBinScreen from "./RestoringBinScreen";

import AddingBinLocationScreen from "./AddingBinLocationScreen";
import { learnMoreLookupData } from "../../../Helpers/Storage/LearnMoreInfoLookup";
import AppContext from "../../../Contexts/AppContext";
import { GetLocalUserManual } from "../../../Helpers/Functions/Online/UserFunctions";
import NetworkContext from "../../../Contexts/NetworkContext";

import VGInput from "../../../Components/Input/Input";
import VGButton from "../../../Components/Button/Button";
import AddBinScreen from "./AddBinScreen";


type modes = 'edit_location' | 'edit_location_details' | 'restore_bin' | 'add_quick_bin' | 'edit_quick_bin' | 'add_board_bin' | 'edit_board_bin';
export type EditBinLocState = { current_mode: modes, current_bin: Bin };

type PropTypes = {
    parentMode: ListLocState;
    setParentMode: setProp<ListLocState>;
    locations: BinLocation[];
    setLocations: setProp<BinLocation[]>;
}

/**
 * Allows editing the Bin Location, also lists the Bins that exist at that Location
 */
function EditBinLocation(props: PropTypes) {

    const appContext = React.useContext(AppContext);
    const networkContext = useContext(NetworkContext);
    const [tempLocation, setTempLocation] = React.useState<BinLocation>({ id: -1, location: "", profile: "", expired:0, updated_at: undefined });
    const [bins, setBins] = React.useState<Bin[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [binMakeList,setBinMakeList] = React.useState<BinMake[]>([]);
    const [binModelList,setBinModelList] = React.useState<BinModel[]>([]);

    // Keep track of this screens state, we also look at the parent screens state with props.parentMode
    const [mode, setMode] = React.useState<EditBinLocState>({ current_mode: 'edit_location', current_bin: null });

    const locationRef = React.useRef(null);

    const currentContext = useContext(AppContext);

    //This UseEffect will grab the Bin Make Model Data that a user can use to auto-populate their own fields
    React.useEffect(() => {
        const grabVerigrainBins = async() => {

            let data = await GetBinMakeModelData(null);
            if(data != null)
            {
          
                setBinMakeList(data.make_list);
                setBinModelList(data.model_list);
            }
        }

        
        grabVerigrainBins();
    },[])


    //This Use Effect will grab all bins related to the selected location
    //Grabs bins by location
    //deleted bins
    //and any bin board specifications (Make,model,etc)
    React.useEffect(() => {
        setTempLocation(props.parentMode.current_location);

        const GrabBinData = async () => {

            const profile = await GetData('Profile');
            try
            {
             
                //Grab the bins by location
                let bins = await GetBinsByLocation(props.parentMode.current_location?.id, profile);
               setBins(bins);

            }
            catch(error)
            {
                
                console.log('Could not grab bins');
               Toast.show('Could not find any bins');
            }

        }

        GrabBinData();

    }, [props.parentMode.current_location]);

    function onDeletePress() {

        appContext.setAlertOptions({title:'Are you sure you want to delete this location?',desc:'Deleted information will be retained for 6 months, deleting this location will also delete all bins associated with this location',options:[
            {text:'Delete', role:'DESTRUCTIVE', onPress:async() => {
                try {
                    const profile = await GetData('Profile');
                    const expired = {...tempLocation,expired:1};
                    let status = await UpdateBinLocation(expired);

                    if (status) {
                        let oldLocList = props.locations;
                        let index = oldLocList.findIndex(x => x.id == tempLocation.id);
                        oldLocList.splice(index, 1,expired);
                        props.setLocations([...oldLocList]);
                        props.setParentMode({...props.parentMode,current_mode:'list_locations'});
                        appContext.setShowAlert(false);
                        Toast.show('Deleted Location');
                    }
                    else {
                        appContext.setShowAlert(false);
                        Toast.show('Could not delete Location');
                    }
                }
                catch(error)
                {
                    
                    appContext.setAlertOptions({title:'Could not update storage location',desc:"Please try again at a later time",options:[{text:'OK', role:'PRIMARY', onPress:() =>{appContext.setShowAlert(false);}}]})
                }
            }},
            {text:'Cancel', role:'CANCEL', onPress:() => { appContext.setShowAlert(false);}}
          ]});
           appContext.setShowAlert(true);

    }
    
    async function onRestorePress() {
        let data =await GetLocalUserManual();
        if(data != null)
        {
          currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'RestoreObjects'))
        }
        else
        {
          currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='RestoreObjects'));
        }
        // props.setParentMode({...props.parentMode,current_mode:'restore'});
        setMode({...mode,current_mode:'restore_bin'});
    }

    function OnAddStoragePress()
     {
        
                                        
        const getPages = async() => {
                                        
            let data =await GetLocalUserManual();
            if(data != null)
            {
              currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'BinBoardSetup'))
            }
            else
            {
              currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='BinBoardSetup'));
            }
          };
          getPages(); 

   

            appContext.setAlertOptions({title:'Select setup option',desc:"",options:[
                {text:'Quick Setup',role:'PRIMARY',onPress:() =>{setMode({...mode,current_mode:'add_quick_bin'});
                appContext.setShowAlert(false);}},
                {text:'Bin Board Setup',role:'SECONDARY',onPress:() => {   setMode({...mode,current_mode:'add_board_bin'});appContext.setShowAlert(false);}}
            ]});
            appContext.setShowAlert(true);

          
    }

    function OnClickStorage(item: Bin) {

        try
        {
           
            if(item?.model_id != null)
            {
                console.log('setting board bin');
                setMode({...mode,current_mode:'edit_board_bin',current_bin:item});
      
            }
            else
            {
                console.log('setting quick bin' + JSON.stringify(item));
                setMode({...mode,current_mode:'edit_quick_bin',current_bin:item});

            }
        }
        catch(error)
        {
            console.log('No bin board specs found...');
            setMode({...mode,current_mode:'edit_quick_bin',current_bin:item});
          
        }
     
        //TODO, determine weather a bin has a bin board spec sheet or is just a quick bin
    }

    return (
        <>
            {mode.current_mode == 'edit_location' ?
                <View style={{ flex: 1, justifyContent: 'space-evenly', marginHorizontal: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                        <Text accessible={true} accessibilityLabel="Edit Storage Location" style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Edit Storage Location</Text>
                    </View>

                    <Spinner
                        visible={isLoading}
                        textContent={'Loading...'}
                        cancelable={false}
                        textStyle={styles.spinner}
                    />

                    <VGInput label="Location" value={tempLocation.location}
                        onChangeText={(text) => {
                            setTempLocation({...tempLocation,location:text});
                        }}
                    />

                    <FlatList
                        data={bins?.filter(x => x.expired == 0)}
                        renderItem={({ item}: {item:Bin}) =>
                      
                          
                                    <TouchableOpacity
                                    accessibilityLabel={item.name}
                                        style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignSelf: 'stretch', padding: 5, alignItems: 'center', borderWidth: 3, borderColor: 'lightgrey', marginVertical: 3 }} 
                                        onPress={() => {OnClickStorage(item)}} >
                                        <Image style={{
                                            width: 30, height: 30, borderRadius: 5,
                                            overflow: 'hidden'
                                        }} source={require('../../../Icons/BlueBin.png')} />

                                        <Text style={{ fontSize: 18, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5 }}>{item.name}</Text>
                                    </TouchableOpacity>

                        }
                        keyExtractor={(item, index) => index.toString()}
                    >
                    </FlatList>


                    <VGButton role="PRIMARY" onPress={OnAddStoragePress}
                    >Add New Storage</VGButton>
                    <VGButton role="SECONDARY" onPress={ () => { 
                               setMode({...mode,current_mode:'edit_location_details'});
                    }}
                    >Edit Location Details</VGButton>

                    <VGButton role="DESTRUCTIVE" onPress={onDeletePress} >Delete</VGButton>

                    <VGButton role="SECONDARY" onPress={onRestorePress} >Restore Deleted Storage</VGButton>

                    <VGButton role="CANCEL"  onPress={() => {
                        props.setParentMode({...props.parentMode,current_location:null,current_mode:'list_locations'});
                          
                        }}>Close</VGButton>
                    
                </View> : null}

            {/* Change what screen is being displayed based off of the mode variable as well as bin set up type*/}

            {mode.current_mode == 'edit_location_details' ? <AddingBinLocationScreen type='edit' currentLocation={tempLocation} setCurrentLocation={setTempLocation} locations={props.locations} parentMode={mode} setLocations={props.setLocations} setParentMode={setMode} /> : null}
            {mode.current_mode == 'add_board_bin' || mode.current_mode == 'add_quick_bin' ? <AddBinScreen  binMakeList={binMakeList} binModelList={binModelList} type={mode.current_mode == 'add_board_bin' ? 'board' : 'quick'}  currentLocation={props.parentMode.current_location} locations={props.locations} bins={bins}  parentMode={mode} 
            OnBinSave={(savedBin:Bin) => { 
                
                if(bins?.length == 0 || bins == null)
                {
                    setBins([savedBin]);
                }
                else
                {
                    setBins([...bins,savedBin]); 
                }
             
                
                setMode({...mode,current_mode:'edit_location'});}}
             OnCancel={() => {setMode({...mode,current_mode:'edit_location'});}}
              OnModeChange={() => {setMode({...mode,current_mode:'add_board_bin'});}}
             OnLocationSave={(savedLoc) => {
                const index = props.locations.findIndex(x => x.id == savedLoc.id);
                if(index != -1)
                {
                    let oldLoc = props.locations;
                    oldLoc[index] = savedLoc;
                    props.setLocations([...oldLoc]);
                }
             }} /> : null}
             
            {mode.current_mode == 'edit_board_bin'|| mode.current_mode == 'edit_quick_bin' ? <UpdateBinScreen  binMakeList={binMakeList} binModelList={binModelList} type={mode.current_mode == 'edit_board_bin' ? 'board' : 'quick'}  currentLocation={props.parentMode.current_location} locations={props.locations}  bins={bins} selectedBin={mode.current_bin}
             OnAddBoardDetaillsPress={() => {setMode({...mode,current_mode:'edit_board_bin'})}}
              OnClose={() => {setMode({...mode,current_mode:'edit_location'})}} 
            OnDelete={(deletedBin:Bin) => {
                const index = bins.findIndex(x => x.id == deletedBin.id);
                if(index != -1)
                {
                    var arr = [...bins];
                    arr.splice(index,1);
                    setBins(arr);
                }
                setMode({...mode,current_mode:'edit_location'});
            }}
             OnUpdate={(bin:Bin) => {
                const index = bins.findIndex(x => x.id == bin.id);
                if(index != -1)
                {
                    var arr = [...bins];
                    arr.splice(index,1,bin);
                    setBins(arr);

                }
                setMode({...mode,current_mode:'edit_location'});
             }}
              OnUpdateLocation={(binLoc:BinLocation) => {}}/> : null}
            {mode.current_mode == 'restore_bin' ? <RestoringBinScreen  currentLocation={props.parentMode.current_location} bins={bins} setBins={setBins} parentMode={mode} setParentMode={setMode} /> : null}
        </>
    )
}

export default EditBinLocation;


