import { Bin } from "../../../Helpers/Storage/UserState";

import * as React from 'react';
import { View, Text, TouchableOpacity, Dimensions, FlatList, KeyboardAvoidingView, Platform } from 'react-native';
import { Overlay } from "react-native-elements";
import moment from "moment";
import { setProp } from "../../../Helpers/GeneralTypes";
import { SampleDate } from "../PrimarySampleScreens/SampleSelectorScreen";

// TODO: See if you can use this component in both the new sample and sample search screens
function SampleDatePicker(props: { setData: setProp<typeof props.data[number]>, data: SampleDate[], setPickingData: any, pickingData: boolean }) {
  const width = Dimensions.get('window').width; //full width
  const height = Dimensions.get('window').height; //full height

  let renderSeparatorView = () => {
    return (
      <View style={{
        height: 2,
        width: width,
        backgroundColor: "orange",
      }}
      />
    );
  };


  return (
    <Overlay isVisible={props.pickingData} onBackdropPress={() => { props.setPickingData('') }}>
  <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding':'height'}>
      <View style={{ width: width / 1.2, height: height / 1.5 }}>

        <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
          <Text style={{ alignSelf: 'center', fontSize: 28 }}>Pick a Date/Time</Text>
        </View>

        <FlatList
          ItemSeparatorComponent={renderSeparatorView}
          contentContainerStyle={{ justifyContent: 'space-evenly', alignContent: 'flex-end' }}
          keyExtractor={(item, index) => index.toString()}
          data={props.data}
          renderItem={({ item,index }) =>
            <TouchableOpacity accessibilityLabel={'Date_'+index} onPress={() => {
              console.log("Selecting date...");
              console.log(item);
              props.setData({ ...item });
              props.setPickingData(null);


            }}>
         
              <Text style={{ fontSize: 24, padding: 10 }}>{moment.unix(item.date).local().format('LLL')}</Text>
            </TouchableOpacity>
          }
        >
        </FlatList>

      </View>

      </KeyboardAvoidingView>
    </Overlay>
  )
}
export default SampleDatePicker;