import React from "react";
import { View, Text, Image, Dimensions, FlatList, TouchableOpacity, Alert, StyleSheet, ScrollView, TextInput, Platform } from "react-native";
import { Overlay } from "react-native-elements";
import { GetData, GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import { Bin, BinLocation, BinVolumeHistory, Buyer, CharacteristicSubOption, CharacteristicType, GrainVariety, SampleRecordCharacteristic, SampleDestination, SampleGrainVariety, SampleSource, Enterprise, Lab, MasterSample, StorageType, InstrumentModel, BinSampleHistory, GrainType } from "../../../Helpers/Storage/UserState";
import * as Progress from 'react-native-progress';
import Toast from "react-native-tiny-toast";
import moment from "moment";
import { ShareSamples } from "../../../Helpers/Functions/Online/SampleFunctions";
import Icon from "react-native-vector-icons/FontAwesome";
import { LogBox } from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useIsFocused } from "@react-navigation/native";
import { GetUser } from "../../../Helpers/Functions/Online/UserFunctions";
import { defaultBin, defaultGrain } from "../../../Helpers/Storage/DefaultStates";
import { CapacityType, GetBinCapacities, GetVolumeHistoryArray, SaveVolumeHistoryRecord } from "../../../Helpers/Functions/Offline/StorageFunctions";
import { useImmer } from "use-immer";
import { GetMasterSampleRecords } from "../../../Helpers/Functions/Offline/SampleFunctions";
import { GetStorageTypes } from "../../../Helpers/Functions/Offline/BinFunctions";

import BinVolumeAdjuster from "../../HelperScreens/BinVolumeAdjuster";
import AppContext from "../../../Contexts/AppContext";
import BinHistoryScreen from "../BinContentsScreen/BinHistoryScreen";


LogBox.ignoreLogs([
    'Non-serializable values were found in the navigation state',
]);
function StorageDisplayScreen({ route, navigation }: { route: any, navigation: any }) {
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full width

    const isFocused = useIsFocused();

    const appContext = React.useContext(AppContext);



    //This use effect is used to lock the screen orientation when the user leaves this screen,
    //or allows landscape mode when on the screen
    React.useEffect(() => {

        if(Platform.OS != 'web')
        {
        if (!isFocused) {

            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);

        }
        else {
            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.ALL);

        }
    }
    }, [isFocused])

    type StorageData = {
        location: BinLocation,
        bins: {
            bin: Bin,
            binVolumeHistory: BinVolumeHistory[],
            samples: {
                sample: MasterSample,
                binData: {sampleSource:SampleSource|null,sampleDestination:SampleDestination|null},
                characteristics: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[],
                grains: {grainType:GrainType,grainVarieties:GrainVariety[]}
            }[]
        }[]
    }[];
    
    const [storage,setStorage] = React.useState<StorageData>(route.params.storageData);

    const [masterSamples,setMasterSamples] = React.useState<MasterSample[]>([])

    //After storage data is found, loop through and determine average data from each bin, calculated based on all samples in the bin at the time
    const [weightedAverage, setWeightedAverage] = React.useState<{ locationID: number, binID: number, characteristicData: { charID: number, charSubID: number, charName: string, charSubName: string, sourceType: string, sourceID: number, sourceName: string, average: number } }[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const [binCapacity, setBinCapacity] = React.useState<CapacityType[]>([]);

    //Create a bin history object that will combine the manual entries as well as the sample entries
    const [binHistory, setBinHistory] = React.useState<BinSampleHistory[]>([]);


    const [adjustingVolume, setAdjustingVolume] = React.useState(false);
    const [selectedVolume, setSelectedVolume] = React.useState(0);
    const [selectedBin, setSelectedBin] = React.useState<Bin>({...defaultBin});
    const [unit, setUnit] = React.useState('bushel');

    const [bushelWeightOverride, setBushelWeightOverride] = React.useState<{ binID: number, bushelWeight: number }[]>([]);

    const [selectedHistoryBin, setSelectedHistoryBin] = React.useState<Bin>();


    const [viewingHistory,setViewingHistory] = React.useState<boolean>(false);
    const [storageTypes,setStorageTypes] = React.useState<StorageType[]>([]);
    React.useEffect(() => {
        const types = GetStorageTypes();
  
        if(types != null)
        {
          setStorageTypes(types);
        }
      },[])
  

    const [fullCharHeaderWidth, setFullCharHeaderWidth] = React.useState<{ locationID: number, width: number }[]>([]);
    //Used to determine between Tons and Tonnes
    const [tonnage, setTonnage] = React.useState<number>(45.059);

    React.useEffect(() => {
        if(route?.params?.selectedBin != null)
        {
                console.log('SELCTED BIN HAS BEEN FOUND!!!');
                setSelectedBin({...route?.params?.selectedBin??defaultBin})
        }
        else
        {
            console.log('bin not found');
        }
    },[])
    //This effect sets the tonnage offset based on user location
    React.useEffect(() => {

        
        const getUserData = async () => {

            const email = await GetData("Email");

            //Grab secondary Users
            let data = await GetUser(email);

            if (data.country == "Canada") {
                setTonnage(1);
            }
            else {
                setTonnage(1.10231);
            }
        }
        getUserData();
    }, [])


    React.useEffect(() => {
        const getMasterSampleRecords = async() => {
            const masterSampleRecords = (await GetOfflineStateParsed("master_samples"));
            setMasterSamples(masterSampleRecords);
        }
        getMasterSampleRecords();
    },[])

    //This useeffect is called when the storage data object is filled from the data from the search screen
    React.useEffect(() => {

        //If there is storageData and the characteristics of the dataobject is not null
        if (storage && storage.length != 0) {

            let hist = GetVolumeHistoryArray(storage);
            setBushelWeightOverride(hist.currentOverride);
            setWeightedAverage(hist.weighted);
            setBinHistory(hist.binDataHistory);

        }
    }, [storage])





    React.useEffect(() => {

        let capacityList = GetBinCapacities(binHistory)
        setBinCapacity(capacityList);
        
    }, [binHistory])


    // React.useEffect(() => {

    //     if (unit == 'tonne') {

    //         let binObj = storage[0].bins[0].samples[storage[0].bins[0].samples.length - 1].grains[0].grain_id;
    //         let grainObj = dataObject.grains.grainTypes.find(x => x.id == binObj);

    //         for (const [key, value] of Object.entries(grainObj)) {
    //             console.log(`${key}: ${value}`);
    //         }
    //     }
    // }, [unit])

    React.useEffect(() => {

        if(selectedHistoryBin != null)
        {
            setViewingHistory(true);
        }
    },[selectedHistoryBin])



    //Look up for row and column heights
    const [dimensions, setDimensions] = React.useState<{ locationID: number, rowData: { rowIndex: number, rowHeight: number }[], columnData: { header: string, columnWidth: number }[] }[]>([]);

    //Once weighted average is calculated, we can generate table dimensions
    React.useEffect(() => {

        //For each location create a single rowHeight lookup     
        const tableStatus = GenerateTableDimensions(storage, weightedAverage);
        setDimensions([...tableStatus])

    }, [weightedAverage])

    //Since characteristics are dynamic we'll have a useeffect that calculates the final width
    //that tallies the width of all characteristics associate with a location
    //Once dimensions are calculated, we also need to calculate the width of the characteristic header
    React.useEffect(() => {
        if (dimensions != null && storage != null) {
            let currentCharacterWidthList: { locationID: number, width: number }[] = [];

            // //For each location
            storage.forEach(x => {

                let currentCharHeaderWidth = 0;

                //Filter the characteristics based on location
                const locationAverage = weightedAverage.filter(j => j.locationID == x.location.id);
                locationAverage.forEach(char => {

                    //Find the dimensions based on the header string ID,if not found we ignore it
                    currentCharHeaderWidth += dimensions?.find(q => q.locationID == x.location.id)?.columnData?.find(s => s.header == char.characteristicData.charSubName + ' ' + char.characteristicData.sourceName)?.columnWidth ?? 0;
                })
                //push the data
                currentCharacterWidthList.push({ locationID: x.location.id, width: currentCharHeaderWidth });

            })
            setFullCharHeaderWidth([...currentCharacterWidthList]);

        }
    }, [dimensions])





    return (
        <View style={{ flex: 1 }}>

{/* {bin:Bin,binHistory:{volume:number,added:boolean,sample?:MasterSample,creation_date:Date}[]} */}

{viewingHistory ? <BinHistoryScreen OnClosed={() =>{setViewingHistory(false); setSelectedHistoryBin(null);}} historyData={{bin:binHistory.find(x => x.bin.id == selectedHistoryBin.id).bin,binHistory:binHistory.find(x => x.bin.id == selectedHistoryBin.id).binHistory}}  /> : null}


            {isLoading ?
                <Overlay overlayStyle={{ backgroundColor: 'rgba(0,0,0,0)', shadowColor: 'rgba(0,0,0,0)' }} isVisible={isLoading} >
                    <Progress.CircleSnail size={width / 1.75} color={'orange'} thickness={10} />
                    <Text style={{ alignSelf: 'center', color: 'white', fontSize: 22 }}>Fetching Data...</Text>
                </Overlay>

                : null}

{adjustingVolume ? <BinVolumeAdjuster OnClosed={() => {setAdjustingVolume(false)}} OnSaved={(volumeHistory) => {
    let currentHistory = binHistory.find(x => x.bin == selectedBin);
    currentHistory.binHistory.push({added:true,creation_date:new Date(),volume:volumeHistory,sample:null})
    setBinHistory([...binHistory,currentHistory]);
    setAdjustingVolume(false);
  }} historyData={binHistory.find(x => x.bin == selectedBin)} adjustingVolume={adjustingVolume}  /> : null}






            <View style={{ flex: 1, marginHorizontal: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                    <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Storage - Display</Text>
                </View>


                <FlatList
                    data={storage}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={({ item, index }) => (

                        <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 3, marginVertical: 5, paddingLeft: 5, paddingRight: 5 }} >




                            <View style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center', paddingVertical: 3, paddingLeft: 5, paddingRight: 5 }}>

                                <Text style={{ fontSize: 20, alignSelf: 'center', textAlign: 'center', flex: 1 }}>{item.location.location}</Text>
                            </View>


                            <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center', justifyContent: 'flex-end' }}>
                                <TouchableOpacity style={{ flex: 1, paddingLeft: 5, flexDirection: 'row', justifyContent: 'flex-start' }}
                                    onPress={async () => {
                                        try {
                                            const profile = await GetData('Profile');
                                            const email = await GetData('Email');

                                            //  sampleList: {sampleID: number,sampleType:string}[],emailList:{ID:number,email:string}[],profile:string,email:string)

                                            const binList = item.bins;

                                            let emailList: Buyer[] = [];


                                            let sampleList: MasterSample[] = [];
                                            binList.forEach(x => {
                                                x.samples.forEach(sample => {
                                                    console.log('this here sample');
                                                    sampleList.push(sample.sample);
                                                })
                                            })
                                            let status = await ShareSamples(sampleList, emailList, profile, email);
                                            if (status) {
                                                navigation.goBack();
                                            }
                                        }
                                        catch (error) {
                                            console.log(error);

                                            appContext.setAlertOptions({title:'Could not share samples',desc:'Please try again at a later time',options:[
                                                {text:'OK', role:'PRIMARY', onPress:() => {
                                                 
                                                    appContext.setShowAlert(false);
                                                  }}
                                              ]});
                                               appContext.setShowAlert(true); 
                                        }
                                    }}
                                >

                                    <Image style={{
                                        width: 30, height: 30, borderRadius: 5,
                                        overflow: 'hidden',
                                    }} source={require('../../../Icons/BlueShare.png')} />


                                    <Text style={{ paddingLeft: 10, fontSize: 16, alignSelf: 'center' }}>Share Send</Text>
                                </TouchableOpacity>
                            </View>


                            <View style={{ paddingLeft: 5, paddingRight: 5 }}>




                                <ScrollView horizontal={true}>

                                    <View style={styles.table}>

                                        <View style={styles.row}>

                                            {/* History, Cont, and Map Offset  */}
                                            <View style={[{ width: 240 }]}></View>


                                            {/* Bin Name Offset */}
                                            <View style={[{ width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'bin_name')?.columnWidth ?? 200 }]}><Text></Text></View>

                                            {/* Sample ID and Storage Type Offset */}
                                            <View style={[{ width: 320 }]}><Text></Text></View>
                                            {/* GrainType Type and Variety Offset */}
                                            <View style={[styles.cell, { width: (dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'grain_type')?.columnWidth ?? 200) + (dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'grain_variety')?.columnWidth ?? 200) }]}><Text>GrainType</Text></View>


                                            {/* TODO: Change to use dimensions */}
                                            {fullCharHeaderWidth && fullCharHeaderWidth.find(x => x.locationID == item.location.id) != undefined && fullCharHeaderWidth.find(x => x.locationID == item.location.id).width > 0 ?
                                                <View style={[styles.cell, { width: fullCharHeaderWidth && fullCharHeaderWidth.find(x => x.locationID == item.location.id) != undefined ? fullCharHeaderWidth.find(x => x.locationID == item.location.id).width : 0 }]}><Text>Characteristics</Text></View>
                                                : <></>}



                                            <View style={[styles.cell, { width: 300 }]}><Text>Bushels (BU)</Text></View>
                                            <View style={[styles.cell, { width: 300 }]}><Text>Tonnes (MT)</Text></View>
                                            <View style={[styles.cell, { width: 200 }]}><Text>Incoming</Text></View>
                                            <View style={[styles.cell, { width: 200 }]}><Text>Outgoing</Text></View>


                                        </View>



                                        <View style={[styles.row, { height: dimensions.find(x => x.locationID == item.location.id)?.rowData[0]?.rowHeight ?? 30 }]}>

                                            <View style={[styles.cell, { width: 80, height: '100%' }]}><Text>History</Text></View>
                                            {/* <View style={[styles.cell, { width: 80, height: '100%' }]}><Text>Cont</Text></View> */}
                                            <View style={[styles.cell, { width: 80, height: '100%' }]}><Text>Map</Text></View>

                                            <View style={[styles.cell, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'bin_name')?.columnWidth ?? 200, height: '100%' }]}><Text>Name</Text></View>
                                            <View style={[styles.cell, { width: 160, height: '100%' }]}><Text>Sample ID</Text></View>
                                            <View style={[styles.cell, { width: 160, height: '100%' }]}><Text>Storage Type</Text></View>
                                            <View style={[styles.cell, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'grain_type')?.columnWidth ?? 200, height: '100%' }]}><Text>Grain</Text></View>
                                            <View style={[styles.cell, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'grain_variety')?.columnWidth ?? 200, height: '100%' }]}><Text>Variety</Text></View>

                                            {/* Characteristics go here! */}
                                            {

                                                weightedAverage.map((x, index) => {
                                                    if (x.locationID == item.location.id) {
                                                        return (


                                                            <View key={index} style={[styles.cell, { width: dimensions.find(q => q.locationID == item.location.id)?.columnData.find(w => w?.header == x.characteristicData.charSubName + ' ' + x.characteristicData.sourceName)?.columnWidth ?? 200, height: '100%' }]}>

                                                                {splitText(x.characteristicData.charSubName + ' ' + x.characteristicData.sourceName).map(x => {
                                                                    return <Text style={{ flex: 1, textAlignVertical: 'center' }}>{x}</Text>
                                                                })}
                                                            </View>

                                                        );
                                                    }
                                                })
                                            }

                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>Total</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>Used</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>Avail</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>Total</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>Used</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>Avail</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>BU</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>MT</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>BU</Text></View>
                                            <View style={[styles.cell, { width: 100, height: '100%' }]}><Text>MT</Text></View>
                                            <View style={[styles.cell, { width: 120, height: '100%' }]}><Text>Date</Text></View>
                                            <View style={[styles.cell, { width: 120, height: '100%' }]}><Text>Time</Text></View>
                                            <View style={[styles.cell, { width: 120, height: '100%' }]}><Text>Status</Text></View>
                                            <View style={[styles.cell, { width: 120, height: '100%' }]}><Text>Volume Adjust</Text></View>
                                        </View>


                                        {/* For each record */}

                                        <FlatList
                                            nestedScrollEnabled
                                            data={item.bins}
                                            keyExtractor={(item, index) => index.toString()}
                                            //initialScrollIndex={route.params.selectedBin != null ? item.bins.findIndex((b)=>{return b.bin.id = route?.params?.selectedBin.id})??0 : 0}
                                            renderItem={({ item: item2, index }) => {
                                                
                                                const autoCellStyle = (item2.bin.id == route.params.selectedBin?.id) ? styles.highlighted : styles.cell;
                                                return (


                                                <View style={[styles.row, { height: dimensions.find(x => x.locationID == item.location.id)?.rowData[index + 1]?.rowHeight ?? 30 }]}>

                                                    <TouchableOpacity style={[styles.cell, { width: 80, backgroundColor: '#1c448c', height: '100%' }]} onPress={() => {

                                                        console.log('Tap Tap Tapping');
                                                        if (selectedHistoryBin && selectedHistoryBin.id == item2.bin.id) {
                                                            setSelectedHistoryBin(null);
                                                        }
                                                        else {
                                                           // setHistoryData({ volumeHistory: binHistory.find(x => x.binID == item2.bin.id).history, historyData: item2.binVolumeHistory, bin: item2.bin, location: item.location })
                                                            setSelectedHistoryBin(item2.bin);
                                                        }

                                                       // navigation.navigate('StorageHistoryScreen', { volumeHistory: binHistory.find(x => x.bin.id == item2.bin.id).binHistory, historyData: item2.samples, bin: item2.bin, location: item.location })

                                                    }}>

                                                        <Icon name={'chevron-down'} size={18.9} color="white" />
                                                        {/* <Text >{selectedHistoryBin && selectedHistoryBin.id == item2.bin.id ? 'Hide' : 'Show'}</Text> */}
                                                    </TouchableOpacity>

                                        
                                                    
                                                    <TouchableOpacity style={[styles.cell, { width: 80, backgroundColor: '#1c448c', height: '100%' }]} onPress={() => {

                                                            const loc = storage.find(x => x.location.id == item2.bin.location_id).location;
                                                            if(loc != undefined)
                                                            {
                                                                navigation.navigate('YardOverview', {location: loc , selectedBin:item2.bin}) 
                                                            }
                                                    }}>
                                                        {/* <Icon name={selectedHistoryBin && selectedHistoryBin.id == item2.bin.id ? 'chevron-up' : 'chevron-down'} size={18.9} color="white" /> */}

                                                        <Icon name={'chevron-down'} size={18.9} color="white" />
                                                        {/* <Text >{selectedHistoryBin && selectedHistoryBin.id == item2.bin.id ? 'Hide' : 'Show'}</Text> */}
                                                    </TouchableOpacity>

                                                    <View style={[autoCellStyle, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x?.header == 'bin_name')?.columnWidth ?? 200, height: '100%'}]}>

                                                        {/* Split name if too long */}
                                                        {splitText(item2.bin.name).map(x => {
                                                            return <Text style={{ flex: 1 }}>{x}</Text>
                                                        })}

                                                    </View>
                                                    <Text style={[autoCellStyle, { width: 160, height: '100%' }]}>{masterSamples?.find(x => x?.id == item2?.samples[0]?.sample?.id)?.barcode?? 'N/A'}</Text>
                                                    <Text style={[autoCellStyle, { width: 160, height: '100%' }]}>{ storageTypes.find(x => x?.id == item2?.bin.storage_type_id)?.short_name??'N/A'}</Text>

                                                    <Text style={[autoCellStyle, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'grain_type')?.columnWidth ?? 200, height: '100%' }]}>{item2?.samples[0]?.grains?.grainType?.name ?? 'N/A'}</Text>


                                                    {/* <Text style={[styles.cell,{width:getColumnWidth(columnWidthLookup,item.location.id,3)}]}>{ item2.samples && item2.samples[item2.samples.length-1] && item2.samples[item2.samples.length-1].grains[0] ? item2.samples[item2.samples.length-1].grains[0].grain_id : 'N/A'}</Text> */}
                                                    <Text style={[autoCellStyle, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(x => x.header == 'grain_variety')?.columnWidth ?? 200, height: '100%' }]}>{item2?.samples[0]?.grains?.grainVarieties?.length > 1 ? 'Multiple Varieties' :item2?.samples[0]?.grains?.grainVarieties[0]?.name  ?? 'N/A'}</Text>


                                                    {/* Characteristics go here! */}
                                                    {

                                                        weightedAverage.map((x, index) => {
                                                            if (x.binID == item2.bin.id) {
                                                                return (


                                                                    <Text key={index} style={[autoCellStyle, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(j => j.header == x.characteristicData.charSubName + ' ' + x.characteristicData.sourceName)?.columnWidth ?? 200, height: '100%' }]}> {isNaN(x.characteristicData.average) ? 'N/A' : x.characteristicData.average} </Text>

                                                                );
                                                            }
                                                            else if (x.locationID == item.location.id) {
                                                                return (
                                                                    <Text key={index} style={[autoCellStyle, { width: dimensions.find(x => x.locationID == item.location.id)?.columnData.find(j => j.header == x.characteristicData.charSubName + ' ' + x.characteristicData.sourceName)?.columnWidth ?? 200, height: '100%' }]}>N/A</Text>
                                                                );
                                                            }
                                                        })

                                                    }


                                                    <Text style={[autoCellStyle, { width: 100, height: '100%' }]}> {item2.bin.size}</Text>

                                                    <Text style={[autoCellStyle, { width: 100, height: '100%' }]}> {binCapacity && binCapacity?.find(x => x.binID == item2.bin.id) ?
                                                        Math.abs(binCapacity?.find(x => x.binID == item2.bin.id)?.bushelsUsed)
                                                        : ''}</Text>

                                                    <Text style={[autoCellStyle, { width: 100, height: '100%' }]}> {binCapacity && binCapacity?.find(x => x.binID == item2.bin.id) ?
                                                        item2.bin.size - Math.abs(binCapacity?.find(x => x.binID == item2.bin.id)?.bushelsUsed)
                                                        : ''}</Text>


<Text style={[autoCellStyle, { width: 100, height: '100%' }]}> {item2.bin.size * tonnage}</Text>

<Text style={[autoCellStyle, { width: 100, height: '100%' }]}> {binCapacity && binCapacity?.find(x => x.binID == item2.bin.id) ?
    Math.abs(binCapacity?.find(x => x.binID == item2.bin.id)?.bushelsUsed) * tonnage
    : ''}</Text>

<Text style={[autoCellStyle, { width: 100, height: '100%' }]}> {binCapacity && binCapacity?.find(x => x.binID == item2.bin.id) ?
    item2.bin.size - Math.abs(binCapacity?.find(x => x.binID == item2.bin.id)?.bushelsUsed) * tonnage
    : ''}</Text>


<Text style={[autoCellStyle,{width:100,height:'100%'}]}>N/A</Text>
<Text style={[autoCellStyle,{width:100,height:'100%'}]}>N/A</Text>


                                                    {
                                                        bushelWeightOverride && bushelWeightOverride.find(x => x.binID == item2.bin.id) != null ?
                                                            <Text style={[autoCellStyle, { width: 100, height: '100%' }]}>{((item2.bin.size / bushelWeightOverride.find(x => x.binID == item2.bin.id).bushelWeight) * tonnage).toFixed(2)}</Text>
                                                            :
                                                            <Text style={[autoCellStyle,{width:100,height:'100%'}]}>N/A</Text>
                                                    }



                                                    {

                                                        bushelWeightOverride && bushelWeightOverride.find(x => x.binID == item2.bin.id) != null && binCapacity?.find(x => x.binID == item2.bin.id) ?
                                                            <Text style={[autoCellStyle, { width: 100, height: '100%' }]}>{((binCapacity?.find(x => x.binID == item2.bin.id)?.bushelsUsed / bushelWeightOverride.find(x => x.binID == item2.bin.id).bushelWeight) * tonnage).toFixed(0)}</Text>
                                                            :
                                                        <Text style={[autoCellStyle,{width:100,height:'100%'}]}>N/A</Text>

                                                    }

<Text style={[autoCellStyle,{width:120,height:'100%'}]}>{moment(masterSamples?.find(x => x?.id == item2?.samples[0]?.sample?.id)?.created_at).format('MMM Do YYYY').toString()?? 'N/A'}</Text>
<Text style={[autoCellStyle,{width:120,height:'100%'}]}>{moment(masterSamples?.find(x => x?.id == item2?.samples[0]?.sample?.id)?.created_at).format('h:mm a').toString()?? 'N/A'}</Text>


                                                    <Text style={[autoCellStyle, { width: 120, height: '100%' }]}>Available</Text>

                                                    <TouchableOpacity style={[autoCellStyle, { width: 120, height: '100%', alignSelf: 'center' }]} onPress={() => {
                                                        setSelectedBin(item2.bin);

                                                        const binVol = binCapacity?.find(x => x.binID == item2.bin.id)?.bushelsUsed;
                                                        setSelectedVolume(binVol);
                                                        setAdjustingVolume(true);

                                                    }}
                                                    ><Text style={{ textAlign: 'center', textAlignVertical: 'center' }}>Adjust</Text></TouchableOpacity>

                                                </View>






                                            )}
                                            } />






                                    </View>



                                </ScrollView>




                            </View>
                        </View>

                    )} />


            </View>
        </View>
    );
}
export default StorageDisplayScreen




const styles = StyleSheet.create({
    table: {
        flex: 1,
    },
    row: { flexDirection: 'row', textAlign: 'center', alignItems: 'center' },
    column: { flexDirection: 'column' },
    cell: {
        borderWidth: 1, textAlignVertical: 'center', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', textAlign: 'center', width: 200, paddingVertical: 5, paddingHorizontal: 5, alignItems: 'center'
    },
    highlighted:{
        borderWidth:1,flexDirection:'column',alignSelf:'center',textAlign:'center',justifyContent:'flex-end',width:200,paddingVertical:5,paddingHorizontal:5,alignItems:'center', backgroundColor:'orange'
      },
});





function splitText(name: string): string[] {

    let wordChunks = name?.match(/.{1,20}(\s|$)/g);

    return wordChunks??[name];
}

function GenerateTableDimensions(
    storage:{
        location: BinLocation,
        bins: {
            bin: Bin,
            binVolumeHistory: BinVolumeHistory[],
            samples: {
                sample: MasterSample,
                binData: {sampleSource:SampleSource|null,sampleDestination:SampleDestination|null},
                characteristics: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[],
                grains: {grainType:GrainType,grainVarieties:GrainVariety[]}
            }[]
        }[]
    }[],
    weightedAverage: { locationID: number, binID: number, characteristicData: { charID: number, charSubID: number, charName: string, charSubName: string, sourceType: string, sourceName: string, sourceID: number, average: number } }[]) {

    let dimensions: { locationID: number, rowData: { rowIndex: number, rowHeight: number }[], columnData: { header: string, columnWidth: number }[] }[] = [];

    storage.forEach(location => {

        //An array of row data, stores the row Index as well as the height for that row
        let locationRowData: { rowIndex: number, rowHeight: number }[] = [];


        //stores the width of a column, use a header string rather than index since this will only effect colums with large varying degrees of text length
        let locationColumnData: { header: string, columnWidth: number }[] = []
        //filter characteristics by location ID
        const weightedAverageByLocation = weightedAverage.filter(x => x.locationID == location.location.id);
        let currentHeight = 0;
        //Characteristics are headers, so they get their own section that determines width and height
        weightedAverageByLocation.forEach(x => {

            const characteristicTypeWidth = GetColumnWidth([x.characteristicData], ['charSubName', 'sourceName']);
            locationColumnData.push({ header: x.characteristicData.charSubName + ' ' + x.characteristicData.sourceName, columnWidth: characteristicTypeWidth });


            const binHeight = GetColumnHeight(x.characteristicData.charSubName + ' ' + x.characteristicData.sourceName);
            currentHeight = CheckHeight(currentHeight, binHeight);
        })

        if (currentHeight == 0) {
            currentHeight = 40;
        }
        console.log('Current height for characteristic header is: ' + currentHeight);
        locationRowData.push({ rowIndex: 0, rowHeight: currentHeight });





        location.bins.forEach((bin, index) => {
            let currentHeight = 0;
            const binHeight = GetColumnHeight(bin.bin.name);
            currentHeight = CheckHeight(currentHeight, binHeight);

            //Grab the first grain type
            const grain = bin?.samples[0]?.grains?.grainType ?? defaultGrain;
            const grainTypeHeight = GetColumnHeight(grain.name);
            currentHeight = CheckHeight(currentHeight, grainTypeHeight);

            console.log('Height for row:' + index + 'is ' + currentHeight);
            locationRowData.push({ rowIndex: index + 1, rowHeight: currentHeight });

        })


        //COLUMN DATA



        //Grab column dimensions
        let binNameWidth = GetColumnWidth(location.bins.map(x => x.bin), ['name']);

        //Push column Width
        locationColumnData.push({ header: 'bin_name', columnWidth: binNameWidth });

        let grainTypes: GrainType[] = [];
        let grainVarieties: GrainVariety[] = [];

        //Grab a list of sample Grains
        location.bins.forEach(bin => {
            bin.samples.forEach(sample => {
                grainVarieties.concat(sample.grains.grainVarieties);
                grainTypes.push(sample.grains.grainType);
            })
        })


        const grainTypeWidth = GetColumnWidth(grainTypes, ['name']);
        locationColumnData.push({ header: 'grain_type', columnWidth: grainTypeWidth });
        console.log('grain type width is: ' + grainTypeWidth);

        const grainVarietyWidth = GetColumnWidth(grainVarieties, ['name']);
        locationColumnData.push({ header: 'grain_variety', columnWidth: grainVarietyWidth });

        dimensions.push({ locationID: location.location.id, rowData: locationRowData, columnData: locationColumnData });

    }



    )
    return dimensions;
}



//This function takes a generic Array and tries to gran the max width based on a specific object property
function GetColumnWidth<T>(dataArray: T[], property: string[]): number {


    let currentWidth = 0;
    //For each record
    dataArray.forEach(data => {

        let fullName = '';
        //loop through each property and add it as a full length column name
        property.forEach(prop => {
            try
            {
                fullName += data[prop]?.toString() + ' ';
            }
            catch(error)
            {
                console.log('Key Pair' + prop + ' does not exist...');
            }
        
        });
        //Use the property to grab specific text that is shown to the user
        const wordArray = splitText(fullName);
        //Find the longest word
        const maxLength = FindLongestString(wordArray);
        //if the longest word is greater than the current width of the column, we store it
        //as the new max width
        if (maxLength > currentWidth) {
            currentWidth = maxLength;
        }
    });
    if (currentWidth == 0) {
        currentWidth = 1;
    }
    //Return the max width + 20 to account for padding, and the max height + 20 to account for padding
    return currentWidth * 10 + 100;
}

function GetColumnHeight(data: string): number {


    const wordArray = splitText(data);
    //Determine the height based on number of lines
    return wordArray?.length * 20 + 10;
}
//Find the longest word in an array of strings
function FindLongestString(wordArray: string[]): number {

    const longest = wordArray.reduce(function (a, b) { return a.length > b.length ? a : b })
    return longest.length;
}

function CheckHeight(currentRowHeight: number, height: number): number {

    if (height > currentRowHeight) {
        return height;
    }
    return currentRowHeight;
}
