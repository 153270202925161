import * as React from "react";
import { View, ScrollView, StyleSheet, FlatList,Image, Dimensions,Text, TouchableOpacity } from "react-native";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import Icon from 'react-native-vector-icons/FontAwesome';

import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { SafeAreaView } from "react-native-safe-area-context";
import { Bin, BinLocation, GrainSubCrop, GrainType, MasterSample, StorageType } from "../../../Helpers/Storage/UserState";
import { GetAllBins, GetBinsByLocation, GetLocations } from "../../../Helpers/Functions/Offline/BinFunctions";
import { defaultBin, defaultBinLocation } from "../../../Helpers/Storage/DefaultStates";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import { BarChart, Grid, XAxis, YAxis } from 'react-native-svg-charts';
import * as scale from 'd3-scale';
import { GetTotalBinVolume } from "../../../Helpers/Functions/Offline/StorageFunctions";
import {Text as SVGText} from 'react-native-svg';
import BinDetailsScreen from "./BinDetailsScreen";
import { filter } from "lodash";
import { useIsFocused } from "@react-navigation/native";
import VGButton from "../../../Components/Button/Button";
import Colours from "../../../Styles/Colours";
import { Overlay } from "react-native-elements";
import Spinner from "react-native-loading-spinner-overlay";

import styles from '../../../Styles/styles';

function StorageContentsScreen({ route, navigation }: { route: any; navigation: any }) {
  
  const isFocused = useIsFocused();
  enum StorageSelection {
    Default,
    selectingLocation,
    selectingBin,
    ViewingData,
  }
  var width = Dimensions.get('window').width; //full width
  var height = Dimensions.get('window').height; //full height

  const [pickingGrainType,setPickingGrainType] = React.useState<boolean>(false);
  const [selectedGrainType,setSelectedGrainType] = React.useState<{
    grainType: GrainType;
    grainSubCrop: GrainSubCrop;
}>(null);

const [isLoading,setIsLoading] = React.useState<boolean>(false);

  //TODO - ADD Location 
  const [binVolumeList,setBinVolumeList] = React.useState<{bin:Bin,  grainData: {
    grainType: GrainType;
    grainSubCrop: GrainSubCrop;
},volume:number,location:BinLocation,storageType:StorageType,lastState:string}[]>([]);

  const [grainChartData,setGrainTypeChartData] = React.useState<{grain:GrainType,grainSubCrop:GrainSubCrop, totalVolume: number, currentVolume:number}[]>([]);
  React.useEffect(() => {

    if(isFocused)
    {
      const GetAllAsyncBins = async() => {

        try
        {
          setIsLoading(true);
          const allBins = await GetAllBins();
          setIsLoading(false);
          let binVolumeArray:{bin:Bin,grainData: {grainType:GrainType,grainSubCrop:GrainSubCrop},volume:number,location:BinLocation,storageType:StorageType,lastState:string}[] = [];
    
          allBins.forEach(bin => {
    
            const binVolumeData = GetTotalBinVolume(bin);
    
            if(binVolumeData != null)
            {
              binVolumeArray.push(binVolumeData);
            }
           
          })
    
          setBinVolumeList([...binVolumeArray]);
        }
        catch(error)
        {
          setIsLoading(false);
        }

      }
      GetAllAsyncBins();
    }

  },[isFocused])

  React.useEffect(() => {


    if(binVolumeList.length != 0)
    {
      let grainChartArray:{grain:GrainType,grainSubCrop:GrainSubCrop, totalVolume: number, currentVolume:number}[] = [];

      let uniqueGrainTypes:{
        grainType: GrainType;
        grainSubCrop: GrainSubCrop;
    }[] = [];
  
      binVolumeList.forEach(bin => {
        try
        {
          if(uniqueGrainTypes.find(x => x?.grainType?.id == bin?.grainData?.grainType.id && x?.grainSubCrop?.id == bin?.grainData?.grainSubCrop.id) == null)
          {
            uniqueGrainTypes.push(bin.grainData);
          }
        }
        catch(error)
        {
          console.log('no grain found');
        }
        
      })
  
      uniqueGrainTypes.forEach(grain => {
  

        try
        {
          const allBinsByGrainType = binVolumeList.filter(x =>x?.grainData?.grainType?.id == grain.grainType.id && x?.grainData?.grainSubCrop?.id == grain.grainSubCrop.id);
  
          const totalAllBins = allBinsByGrainType.map(x => x?.bin.size).reduce((a,b) => a + b);
          const currentCapacityAllBIns= allBinsByGrainType.map(x => x?.volume).reduce((a,b) => a + b);
    
          
          grainChartArray.push({grain:grain.grainType,grainSubCrop:grain.grainSubCrop,totalVolume:totalAllBins,currentVolume:currentCapacityAllBIns});
  
        }
        catch(error)
        {
          console.log('No grain chart data');
        }
  
  
      })
  
  
      setGrainTypeChartData(grainChartArray);
    }


  },[binVolumeList])

  let renderSeparatorView = () => {
    return (
      <View style={{
          height: 2, 
          width: width,
          backgroundColor: "orange",
        }}
      />
    );
  };

React.useEffect(() => {
  if(selectedGrainType != null && selectedGrainType?.grainType?.id != null)
  {
    const filteredBinsByGrainType = binVolumeList.filter(x => x?.grainData?.grainType.id == selectedGrainType?.grainType?.id && x?.grainData?.grainSubCrop.id == selectedGrainType?.grainSubCrop?.id)
    //NAVIGATE TO THO'S SCREEN HERE
    navigation.navigate("StorageDetailScreen", filteredBinsByGrainType);

  }
},[selectedGrainType])

  const myItemSeparator = () => {
    return <View style={{ height: 1, backgroundColor: "gold", marginHorizontal: 10 }} />;
  };



  const transformDataForBarChart = (
    number: number
): { value: number; svg: { fill: string; stroke?: string; strokeWidth?: number } } => {
    if (number <= 3000) {
        return {
            value: number,
            svg: {
                fill: 'red',
            },
        }
    } else if (number > 3000 && number < 5000) {
        return {
            value: number,
            svg: {
                stroke: 'orange',
                strokeWidth: 2,
                fill: '#FFD580',
            },
        }
    } else {
        return {
            value: number,
            svg: {
                fill: 'green',
            },
        }
    }
};

//const dataWithPickedColors = data.map((item) => transformDataForBarChart(item.value));

const dataWithPickerColors = grainChartData.map(x => {return {label:x?.grain.name, value:x?.currentVolume} }).map(item => transformDataForBarChart(item.value))
const CUT_OFF = 5000


const Labels = ({ x, y, bandwidth, data }) =>
data.map((item, index) => (
    <SVGText
        key={item.label}
        x={ 250}
        y={y(index) + bandwidth / 2}
        fontSize={14}
        fontWeight = "bold"
        fill={item.value > CUT_OFF ? 'white' : 'black'}
        //fill="#333"
        alignmentBaseline={'middle'} 
    >
        {item.value}
    </SVGText>
))

const scaleDownValue = (value, max) => {
  const biggestVolume = grainChartData.reduce((a, b) => a.currentVolume > b.currentVolume ? a : b);
  if(value == biggestVolume.currentVolume){
    return max * 0.68 ;
  } else {
    return (maxBarWidth/biggestVolume.currentVolume * value * 0.68);
  }
  
}


  return (
    <View style={{flex:1,marginHorizontal:10}}>


{isLoading ? <Spinner
        visible={isLoading}
        textContent={'Loading Bar Chart...'}
        cancelable={false}
        textStyle={styles.spinner}
      /> : null}


    <View>
          <Text style= {{fontSize:18, textAlign: 'center'}}>Grain Inventory</Text>
  </View>
  

  {pickingGrainType?
  
  <Overlay  isVisible={pickingGrainType}  onBackdropPress={() => {setPickingGrainType(false)}}>

  <View style={{width:width/1.2,height:height/1.2}}>

<View style={{flexDirection:'row',alignSelf:'center'}}>
<Text style={{alignSelf:'center',fontSize:28,textAlign:'center',flex:1}}>Select Grain</Text>
<TouchableOpacity   onPress={() => {

  }}>
       <FontAwesome name="question-circle-o" size={35} color="grey" />
  </TouchableOpacity>
</View>



      {grainChartData == undefined || grainChartData.length == 0 ?
        <View style={{flexDirection:'row',alignSelf:'center'}}>
        <Text style={{alignSelf:'center',fontSize:20}}>No data available</Text>
        </View>
     :
     null
     }
    
  <FlatList 
  ItemSeparatorComponent={renderSeparatorView}
        contentContainerStyle={{justifyContent:'space-evenly',alignContent:'flex-end'}}
        data={grainChartData.map(x => ({grainType:x?.grain,grainSubCrop:x?.grainSubCrop}))}
        renderItem={({ item }) => 
      

            <TouchableOpacity
            accessibilityLabel={item.grainType.name}
            onPress= {() => {
               
              try
              {
          
               setSelectedGrainType(item)
                setPickingGrainType(false);
              }
              catch(error)
              {
                console.log('Error selecting: ' + error);
              }
 
            
            }} >


<Text style={{fontSize:24,padding:10}}>{item.grainType.name} {item?.grainSubCrop?.name != null ? ' - ' + item?.grainSubCrop?.name : ''}</Text>
            </TouchableOpacity>
            }
            keyExtractor={(item,index) => index?.toString()}
        >
        </FlatList>
        </View>

        <View>

        <VGButton role="CANCEL" onPress={() => {
          setPickingGrainType(false);
        }}>Cancel</VGButton>
  </View>
  </Overlay>
: null}   



{/* CUSTOM SVG IMPLEMENTATION */}
<View style={{ flexDirection: 'column', flex:1, paddingVertical: 10}}>
        {grainChartData.map((item, index) => (
         <View key={index} style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10, marginHorizontal:10, top:20 }}>
            <Text accessible={true} accessibilityLabel={'Grain_Label_'+item.grain.name} style={{ width:'20%', textAlign:'left' }}>{item.grain.name}</Text>
            <TouchableOpacity
              accessibilityLabel={item.grain.name + '_Barchart'}
              onPress={() => {
               const filteredBinsByGrain = binVolumeList.filter(x => x?.grainData?.grainType.id == item.grain.id)
               navigation.navigate("StorageDetailScreen", filteredBinsByGrain);
             }}
             style={{ flexDirection: 'row', alignItems: 'center', left:10}}
             >
              <View
               style={{...styles2.bar, width:scaleDownValue(item.currentVolume, maxBarWidth)}}
             />
             </TouchableOpacity>
             <Text accessible= {true} accessibilityLabel={item.grain.name +'_Current_Volume'} style={{width:'14%',position:'absolute',right: 0.5, textAlign: 'right'}}>{item.currentVolume} bu</Text>
         
          </View>
        ))}
      </View>
      
              <VGButton role="PRIMARY" onPress={() => {setPickingGrainType(true)}}>View Bin Details</VGButton>

    </View>
  );
}
export default StorageContentsScreen;

const maxBarWidth = Dimensions.get('window').width /1.2;
const styles2 = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: "#fff",
  },
  item: {
    padding: 20,
    marginTop: 5,
    fontSize: 15,
  },
  bar: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'orange',
    color:'orange',
    height: 50,
    borderRadius: 10,
  },

  header: { height: 50, backgroundColor: "#537791" },
  text: { textAlign: "center", fontWeight: "100" },
  dataWrapper: { marginTop: -1 },
  row: { height: 40, backgroundColor: "#E7E6E1" },
  singleHead: { backgroundColor: "#c8e1ff" },
  head: { flex: 1, backgroundColor: "#c8e1ff" },
  title: { flex: 1, backgroundColor: "#f6f8fa" },
  titleText: { textAlign: "center" },
});