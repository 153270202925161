import moment from "moment";
import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { defaultGrain, defaultGrainSubCrop, defaultMasterSample } from "../../Storage/DefaultStates";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { BinLocation, Bin, BinVolumeHistory, MasterSample, SampleSource, SampleDestination, CharacteristicType, CharacteristicSubOption, GrainVariety, UserState, Buyer, Enterprise, Field, StorageType, Lab, InstrumentModel, BinSampleHistory, SampleGrainVariety, GrainType, GrainSubCrop } from "../../Storage/UserState";
import { GetGrainAndSubCropTypes } from "../Grains";

export type StorageData = {
    location: BinLocation,
    bins: {
        bin: Bin,
        binVolumeHistory: BinVolumeHistory[],
        samples: {
            sample: MasterSample,
            binData: {sampleSource:SampleSource|null,sampleDestination:SampleDestination|null},
            characteristics: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[],
            grains: {grainType:GrainType,grainSubCrop:GrainSubCrop,grainVarieties:GrainVariety[]}
        }[]
    }[]
}[];


/* This function will return ALL data pretaining to the storage screen 
* This included the following
* A list of locations
* A list of bins that relate to that location
* A list of samples related to a bin
* A list of charcteristics and grains related to a sample 
* A master list of Grain Types and Varieties (A lookup table)
* A master list of Characteristic types and sub options (A lookup table)
*/
export function GetStorageData(searchBy: 'Grain' | 'Location', id: number, profile?: string):StorageData {
    
    if(searchBy == 'Location')
    {
       const data = GetStorageDataFromLocation(id);
       return data;
    }
    else
    {
        const data = GetStorageDataFromGrain(id);
        return data;
     }

}

export function SaveVolumeHistoryRecord(bin_id: number, selectedVolume: number, profile: string, userID: string) {
    const binHistory: BinVolumeHistory = {
        id:null,
        bin_id:bin_id,
        current_volume:selectedVolume,
        user_id:parseInt(userID),
        created_at: undefined,
        updated_at: undefined
      }
    
      return AddToQueue("bin_volume_history", binHistory, "add");
}

export function GetVolumeHistoryArray(storage:StorageData){
   //The weight characteristics, no duplicate characteristics in this one
   let weighted: { locationID: number, binID: number, characteristicData: { charID: number, charSubID: number, charName: string, charSubName: string, sourceName: string, sourceType: string, sourceID: number, average: number } }[] = [];

   //The individual characteristics
   let charData: { locationID: number, binID: number, characteristicData: { charID: number, charSubID: number, charName: string, charSubName: string, sourceType: string, sourceID: number, value: number }[] }[] = [];

   //The history data for the bin
   let binDataHistory: BinSampleHistory[] = [];

   //For each location
   storage.forEach(location => {



       //For each bin withing a location
       location.bins.forEach(bin => {


           //The bin history
           let currentBinHistory: BinSampleHistory  = {bin:bin.bin, binHistory:[]}


           //an array of characteristics
           let currentCharData: { charID: number, charSubID: number, charName: string, charSubName: string, sourceType: string, sourceID: number, value: number }[] = [];

           bin.binVolumeHistory.forEach(history => {
               currentBinHistory.binHistory.push({ volume: history.current_volume,added:history.current_volume > 0 ? true:false,creation_date:history.created_at});
           })
           //for each sample found in the bin
           bin.samples.forEach(sample => {

               currentBinHistory.binHistory.push({ volume: sample.sample.volume, added: sample.binData.sampleSource != null ? false : true, sample: sample.sample,creation_date:sample.sample.created_at })


               //add the characteristic to the list
               sample.characteristics.forEach(character => {
                   //currentCharData.push({ charID: character.characteristicType.id, charSubID: character.characteristicSubOption.id, charName: '', charSubName: '', sourceType: character.source.enterprise??character.source.buyer??character.source.lab??character.source.userInstrument??null, sourceID: character.source_id, value: parseInt(character.) })
               })
           })


           //push data 
           charData.push({ locationID: location.location.id, binID: bin.bin.id, characteristicData: currentCharData });

           binDataHistory.push(currentBinHistory);

       });

   })

   let currentOverride: { binID: number, bushelWeight: number }[] = [];
   //Now we have a list of ALL characteristics pretaining to a BIN


   charData.map(x => {

       let bushelWeightList: number[] = [];
       //loop through characteristics
       x.characteristicData.map(j => {

           let total = 0;
           let count = 0;
           if (weighted.find(x => x.characteristicData.charID == j.charID && x.characteristicData.charSubID == j.charSubID && x.characteristicData.sourceID == j.sourceID && x.characteristicData.sourceType == j.sourceType) == undefined) {
               const data = x.characteristicData.filter(q => q.charID == j.charID && q.charSubID == j.charSubID && q.sourceID == j.sourceID && q.sourceType == j.sourceType);
               data.forEach(charDataPoint => {


                   //Is the characteristic a bushel weight?
                   if (charDataPoint.charID == 1 && charDataPoint.charSubID == 11 && charDataPoint.value != 0) {
                       bushelWeightList.push(charDataPoint.value);
                   }
                   //if a user has inputted a value in the characteristic list, then add to weighted average
                   if (charDataPoint.value != 0 && charDataPoint.value != null) {
                       total += charDataPoint.value;
                       count++;
                   }


               })


               if (storage != undefined) {
                   weighted.push({
                       locationID: x.locationID,
                       binID: x.binID,
                       characteristicData: { charID: j.charID, charSubID: j.charSubID, charName: j.charName?? '', charSubName: j.charSubName ?? '', sourceName: j.sourceType?? '', sourceID: j.sourceID, sourceType: j.sourceType, average: total / count } 
                   })
               }


           }

       })

       if (bushelWeightList.length > 0) {
           let totalBushelWeight = 0;
           bushelWeightList.forEach(weight => totalBushelWeight += weight);
           currentOverride.push({ binID: x.binID, bushelWeight: totalBushelWeight / bushelWeightList.length });
       }
   });


   binDataHistory.forEach(bin => {


       bin.binHistory.sort((a, b) => {

           return moment(a.creation_date, 'YYYY-MM-DD hh:mm:ss').unix() - moment(b.creation_date, 'YYYY-MM-DD hh:mm:ss').unix();
       })

   })
   

    return {
        binDataHistory: binDataHistory,
        currentOverride: currentOverride,
        weighted:weighted
    }
}
export type CapacityType={binID: number, bushelsUsed: number, lastState: 'in' | 'out' };
export function GetBinCapacities(binHistory: BinSampleHistory[]) {
    //Does the binHistory for volumes exist, and is there more than 0 entries?
    let capacityList: CapacityType[] = [];
    if (binHistory && binHistory.length > 0) {
        //create a capacitylist



        //for each binHistory object
        binHistory.forEach(bin => {

            //reset the used bushels counter
            let used = 0;
            //Find the last occurence of a manual entry
            let index = bin.binHistory.map(x => x.added).lastIndexOf(false);
            let last = 'in';
            //If a manual entry exists
            if (index != -1) {

                //set the base volume
                used = bin.binHistory[index].volume;
                for (let i = index; i < bin.binHistory.length; i++) {
                    //If output is positive add the volume
                    if (bin.binHistory[i].volume > 0) {
                        used += bin.binHistory[i].volume
                        last = 'in';
                    }
                    //it's negative, remove the volume
                    else if (bin.binHistory[i].volume <= 0) {
                        used -= bin.binHistory[i].volume;
                        last = 'out';
                    }
                }
            }
            //no manual entries, proceed as usual starting from the first element
            else {
                for (let i = 0; i < bin.binHistory.length; i++) {
                    if (bin.binHistory[i].volume > 0) {
                        used += bin.binHistory[i].volume
                        last = 'in';
                    }
                    else if (bin.binHistory[i].volume <= 0) {
                        used -= bin.binHistory[i].volume;
                        last = 'out';
                    }
                }
            }
            //add to list
            capacityList.push({ binID: bin.bin.id, bushelsUsed: used, lastState: last === 'in' ? 'in' : 'out'});
        })
    }
    return capacityList;
}




export function GetStorageDataFromLocation(location_id: number):StorageData{


    let fullStorage:StorageData = [];
    
    const location = GetOfflineStateParsed('bin_locations').find(x => x.id == location_id);

    const bins = GetOfflineStateParsed('bins').filter(x => x.location_id == location.id && x.expired == 0);

    console.log('BINS' + JSON.stringify(bins));
    //Instead of grabbing the offline state each time, grab it once and then filter
    const fullBinVolHistory = GetOfflineStateParsed('bin_volume_history');
    const fullSampleSource = GetOfflineStateParsed('sample_source');
    const fullSampleDestination = GetOfflineStateParsed('sample_destination');
    const fullSamples = GetOfflineStateParsed('master_samples');
    const fullSampleCharacteristics = GetOfflineStateParsed('sample_record_characteristics');
    const fullCharTypes = GetOfflineStateParsed('characteristic_types');
    const fullCharSubOptions = GetOfflineStateParsed('characteristic_sub_options');
    const fullSampleGrains = GetOfflineStateParsed('sample_grain_varieties');
    const fullEnterprises = GetOfflineStateParsed('enterprises');
    const fullBuyers = GetOfflineStateParsed('buyers');
    const fullLabs = GetOfflineStateParsed('labs');
    const fullInstrumentModels = GetOfflineStateParsed('instrument_models');
    const fullGrainTypes = GetGrainAndSubCropTypes();
    const fullGrainVarieties = GetOfflineStateParsed('grain_varieties');

    let  binData: {
        bin: Bin,
        binVolumeHistory: BinVolumeHistory[],
        samples: {
            sample: MasterSample,
            binData: {sampleSource:SampleSource|null,sampleDestination:SampleDestination|null},
            characteristics: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[],
            grains: {grainType:GrainType,grainSubCrop:GrainSubCrop,grainVarieties:GrainVariety[]}
        }[]
    }[] = [];


    bins.forEach(bin => {
    
        const binVolHistory = fullBinVolHistory.filter(x => x.bin_id == bin.id);

      
        const sampleSource = fullSampleSource.filter(x => x.source_bin_id == bin.id).map(x => {return x.master_sample_id});
        const sampleDestination = fullSampleDestination.filter(x => x.destination_bin_id == bin.id).map(x => {return x.master_sample_id});
        const masterSampleIDList = [...new Set(sampleSource.concat(sampleDestination))];

        const samples = fullSamples.filter(function(sample)
        {
            if(masterSampleIDList.find(j => j == sample.id))
            {
                return sample;
            }
        });

        let sampleData: {
            sample: MasterSample,
            binData: {sampleSource:SampleSource|null,sampleDestination:SampleDestination|null},
            characteristics: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[],
            grains: {grainType:GrainType,grainSubCrop:GrainSubCrop,grainVarieties:GrainVariety[]}
        }[] = [];

        samples.forEach(sample => {

            const sampleSource = fullSampleSource.find(x => x.master_sample_id == sample.id && x.source_bin_id != null);
            const sampleDestination = fullSampleDestination.find(x => x.master_sample_id == sample.id && x.destination_bin_id != null);
            const finalDestination:{sampleSource:SampleSource|null,sampleDestination:SampleDestination|null}  = {sampleSource:sampleSource??null,sampleDestination:sampleDestination??null};
            const sampleChars = fullSampleCharacteristics.filter(x => x.master_sample_id == sample.id);

           let charData: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[] = [];
            
            sampleChars.forEach(char => {
                const charSub = fullCharSubOptions.find(x => x.id == char.characteristic_sub_id);
                const charType = fullCharTypes.find(x => x.id == charSub.characteristic_type_id);

                    if(char.enterprise_id != null)
                    {
                        const enterprise = fullEnterprises.find(x => x.id == char.enterprise_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:enterprise,lab:null,userInstrument:null,buyer:null}})
                    }
                    else if(char.buyer_id != null)
                    {
                        const buyer = fullBuyers.find(x => x.id == char.buyer_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:null,lab:null,userInstrument:null,buyer:buyer}})

                    }
                    else if(char.lab_id != null)
                    {
                        const lab = fullLabs.find(x => x.id == char.lab_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:null,lab:lab,userInstrument:null,buyer:null}})

                    }
                    else if(char.user_instrument_id != null)
                    {
                        const userInstrument = fullInstrumentModels.find(x => x.id == char.user_instrument_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:null,lab:null,userInstrument:userInstrument,buyer:null}})
                    }
            });

            let grainData: {grainType:GrainType,grainSubCrop:GrainSubCrop,grainVarieties:GrainVariety[]}= {grainType:defaultGrain,grainSubCrop:defaultGrainSubCrop,grainVarieties:[]};
            const sampleGrains = fullSampleGrains.filter(x => x.master_sample_id == sample.id);
            console.log('sample garins: ' + JSON.stringify(sampleGrains));
            if(sampleGrains != null)
            {
          
                const grainType = fullGrainTypes.types.find(x => x.grainSubCrop.id == sample.sub_crop_id && x.grainType.id == sample.grain_type_id);
                console.log('Grain TYPE: ' + JSON.stringify(grainType));
                grainData.grainType = grainType.grainType;
                grainData.grainSubCrop = grainType.grainSubCrop;
                const grainVarieties = fullGrainVarieties?.filter(function(grainVar)
                    {
                        if(sampleGrains.find(x => x?.grain_variety_id == grainVar.id))
                        {
                            return grainVar;
                        }
                    }
                )
                grainData.grainVarieties = grainVarieties;
            }

            sampleData.push({sample:sample,binData:finalDestination,characteristics:charData,grains:grainData})
        })

        binData.push({bin:bin,binVolumeHistory:binVolHistory,samples:sampleData});
    })
    fullStorage.push({location:location,bins:binData});

    return fullStorage;

}




export function GetStorageDataFromGrain(grain_id: number) : StorageData{

    let fullStorage:StorageData = [];

    const fullBinVolHistory = GetOfflineStateParsed('bin_volume_history');
    const fullSampleSource = GetOfflineStateParsed('sample_source');
    const fullSampleDestination = GetOfflineStateParsed('sample_destination');
    const fullSamples = GetOfflineStateParsed('master_samples');
    const fullSampleCharacteristics = GetOfflineStateParsed('sample_record_characteristics');
    const fullCharTypes = GetOfflineStateParsed('characteristic_types');
    const fullCharSubOptions = GetOfflineStateParsed('characteristic_sub_options');
    const fullSampleGrains = GetOfflineStateParsed('sample_grain_varieties');
    const fullEnterprises = GetOfflineStateParsed('enterprises');
    const fullBuyers = GetOfflineStateParsed('buyers');
    const fullLabs = GetOfflineStateParsed('labs');
 
    const fullInstrumentModels = GetOfflineStateParsed('instrument_models');
    const allGrains = GetGrainAndSubCropTypes();

    const fullGrainVarieties = GetOfflineStateParsed('grain_varieties');
    const fullLocations = GetOfflineStateParsed('bin_locations');
    const fullBins = GetOfflineStateParsed('bins');
    const masterIDList = fullSampleGrains.filter(x => x.grain_type_id == grain_id).map(x => {return x.master_sample_id});

    const SSBins = fullSampleSource.filter(function(SS)
    {
        if(masterIDList.find(x => x == SS.master_sample_id) && SS.source_bin_id != null)
        {
            return SS;
        }
    }).map(x => {return x.source_bin_id});
    const SDBins = fullSampleDestination.filter(function(SD)
    {
        if(masterIDList.find(x => x == SD.master_sample_id) && SD.destination_bin_id != null)
        {
            return SD;
        }
    }).map(x => {return x.destination_bin_id});
   const allSampleBinsFound = SSBins.concat(SDBins);

   const binsFound = fullBins.filter(function(bin){
    if(allSampleBinsFound.find(x => x == bin.id))
    {
        return bin;
    }
   })
   const locationsFound = fullLocations.filter(function(loc) {
        if(binsFound.find(x => x.location_id == loc.id))
        {
            return loc;
        }
   })
 
   locationsFound.forEach(loc => {


    let  binData: {
        bin: Bin,
        binVolumeHistory: BinVolumeHistory[],
        samples: {
            sample: MasterSample,
            binData: {sampleSource:SampleSource|null,sampleDestination:SampleDestination|null},
            characteristics: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[],
            grains: {grainType:GrainType,grainSubCrop:GrainSubCrop,grainVarieties:GrainVariety[]}
        }[]
    }[] = [];

    const bins = fullBins.filter(x => x.location_id == loc.id);

    bins.forEach(bin => {
    
        const binVolHistory = fullBinVolHistory.filter(x => x.bin_id == bin.id);

      
        const sampleSource = fullSampleSource.filter(x => x.source_bin_id == bin.id).map(x => {return x.master_sample_id});
        const sampleDestination = fullSampleDestination.filter(x => x.destination_bin_id == bin.id).map(x => {return x.master_sample_id});
        const masterSampleIDList = [...new Set(sampleSource.concat(sampleDestination))];

        const samples = fullSamples.filter(function(sample)
        {
            if(masterSampleIDList.find(j => j == sample.id))
            {
                return sample;
            }
        });

        let sampleData: {
            sample: MasterSample,
            binData: {sampleSource:SampleSource|null,sampleDestination:SampleDestination|null},
            characteristics: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[],
            grains: {grainType:GrainType,grainSubCrop:GrainSubCrop,grainVarieties:GrainVariety[]}
        }[] = [];

        samples.forEach(sample => {

            const sampleSource = fullSampleSource.find(x => x.master_sample_id == sample.id && x.source_bin_id != null);
            const sampleDestination = fullSampleDestination.find(x => x.master_sample_id == sample.id && x.destination_bin_id != null);
            const finalDestination:{sampleSource:SampleSource|null,sampleDestination:SampleDestination|null}  = {sampleSource:sampleSource??null,sampleDestination:sampleDestination??null};
            const sampleChars = fullSampleCharacteristics.filter(x => x.master_sample_id == sample.id);

           let charData: {characteristicType:CharacteristicType,characteristicSubOption:CharacteristicSubOption,source:{lab:Lab|null,userInstrument:InstrumentModel|null,buyer:Buyer|null,enterprise:Enterprise|null}}[] = [];
            
            sampleChars.forEach(char => {
                const charSub = fullCharSubOptions.find(x => x.id == char.characteristic_sub_id);
                const charType = fullCharTypes.find(x => x.id == charSub.characteristic_type_id);

                    if(char.enterprise_id != null)
                    {
                        const enterprise = fullEnterprises.find(x => x.id == char.enterprise_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:enterprise,lab:null,userInstrument:null,buyer:null}})
                    }
                    else if(char.buyer_id != null)
                    {
                        const buyer = fullBuyers.find(x => x.id == char.buyer_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:null,lab:null,userInstrument:null,buyer:buyer}})

                    }
                    else if(char.lab_id != null)
                    {
                        const lab = fullLabs.find(x => x.id == char.lab_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:null,lab:lab,userInstrument:null,buyer:null}})

                    }
                    else if(char.user_instrument_id != null)
                    {
                        const userInstrument = fullInstrumentModels.find(x => x.id == char.user_instrument_id);
                        charData.push({characteristicSubOption:charSub,characteristicType:charType,source:{enterprise:null,lab:null,userInstrument:userInstrument,buyer:null}})
                    }
            });

            let grainData: {grainType:GrainType,grainSubCrop:GrainSubCrop,grainVarieties:GrainVariety[]}= {grainType:defaultGrain,grainSubCrop:defaultGrainSubCrop,grainVarieties:[]};
            const sampleGrains = fullSampleGrains.filter(x => x.master_sample_id == sample.id);
            if(sampleGrains != null)
            {
                        
                const grainType = allGrains.types.find(x => x.grainSubCrop.id == sample.sub_crop_id && x.grainType.id == sample.grain_type_id);
                grainData.grainType = grainType.grainType;
                grainData.grainSubCrop = grainType.grainSubCrop;
                const grainVarieties = fullGrainVarieties.filter(function(grainVar)
                    {
                        if(sampleGrains.find(x => x.grain_variety_id == grainVar.id))
                        {
                            return grainVar;
                        }
                    }
                )
                grainData.grainVarieties = grainVarieties;
            }

            sampleData.push({sample:sample,binData:finalDestination,characteristics:charData,grains:grainData})
        })

        binData.push({bin:bin,binVolumeHistory:binVolHistory,samples:sampleData});
    })
    fullStorage.push({location:loc,bins:binData});
   })

   return fullStorage;


}

export function GetTotalBinVolume(bin:Bin): {bin:Bin,grainData:{grainType:GrainType,grainSubCrop:GrainSubCrop},volume:number,location:BinLocation,storageType:StorageType,lastState:'in'|'out'}|null {

    //const fullBinVolHistory = GetOfflineStateParsed('bin_volume_history');
    const fullSampleSource = GetOfflineStateParsed('sample_source').filter( x => x.source_bin_id == bin.id);
    const fullSampleDestination = GetOfflineStateParsed('sample_destination').filter( x => x.destination_bin_id == bin.id);;
    const masterSamples = GetOfflineStateParsed('master_samples');

    const allLocations = GetOfflineStateParsed('bin_locations');
    
    const allGrains = GetGrainAndSubCropTypes();

    const storageTypes = GetOfflineStateParsed('storage_types');

    const binVolHistory = GetOfflineStateParsed('bin_volume_history').filter(x => x.bin_id == bin.id);
    //get the master sample list for a source_bin
    let sampleSourceList = [];

    //get the master sample list for a destination_bin
    let sampleDestinationList = [];

    //now get the list of master sample record using master_sample_id of source_bin table for a particular source_bin_id

     sampleSourceList = fullSampleSource.filter(x => x.source_bin_id == bin.id).map(x => {return x.master_sample_id});
   
    //now get the list of master sample record using master_sample_id of source_bin table for a particular source_bin_id
     sampleDestinationList = fullSampleDestination.filter(x => x.destination_bin_id == bin.id).map(x => {return x.master_sample_id});

     if(sampleSourceList.length == 0 && sampleDestinationList.length == 0)
     {
        return null;
     }


     //get total amount from source bin
        
     const all_source_master_samples = masterSamples.filter(function(masterSample){
      
        if(sampleSourceList.find(x => x == masterSample.id))
        {
            return masterSample;
        }
     });

     let sourceTotal = 0;
     
     if(all_source_master_samples.length > 0)
     {
        sourceTotal =   all_source_master_samples.map(x => x.volume).reduce((a,b) => a + b);
     }
   

     const all_destination_master_samples = masterSamples.filter(function(masterSample){
        if(sampleDestinationList.find(x => x == masterSample.id))
        {
            return masterSample;
        }
     });
     let destinationTotal = 0;
     
     if(all_destination_master_samples.length > 0)
     {
        destinationTotal =   all_destination_master_samples.map(x => x.volume).reduce((a,b) => a + b);
     }

     //Could be [all_source_master_samples.length-1] instead of [0]

     //We want to grab the last Master_Sample record that was created either from the source or destination tables
    
     const lastSampleDestination = all_destination_master_samples[0];
     const lastSampleSource = all_source_master_samples[0];
 
let lastMasterSample:MasterSample = {...defaultMasterSample};

let status = 'in';
//If the destination is not null we can use that masterSample ID
    if(lastSampleDestination != null && lastSampleSource != null)
    {
        if(lastSampleDestination.created_at > lastSampleSource.created_at)
        {
            lastMasterSample = lastSampleDestination;
            status = 'out';
        }
        else
        {
            lastMasterSample = lastSampleSource;
            status = 'in';
        }
    }
    else if(lastSampleDestination != null)
    {
        lastMasterSample = lastSampleDestination;
    }
    else
    {
        lastMasterSample = lastSampleSource;
    }


    const grain = allGrains.types.find(x => x.grainType.id == lastMasterSample.grain_type_id && x.grainSubCrop.id == lastMasterSample.sub_crop_id);

const loc = allLocations.find(x => x.id == bin.location_id);
const storage = storageTypes.find(x => x.id == bin.storage_type_id);
     // get total

     let binCurrentVolume = destinationTotal - sourceTotal;

     binVolHistory.forEach(hist => {
        binCurrentVolume += hist.current_volume;
     })


     return {bin:bin, grainData:grain,volume:binCurrentVolume,location:loc,lastState:status == 'in' ? 'in':'out',storageType:storage};
     
    
}


