import { Bin, BinLocation } from "../../../Helpers/Storage/UserState";

import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity, Modal, Alert, Dimensions, Image, FlatList, KeyboardAvoidingView, Platform } from 'react-native';
import { Overlay } from "react-native-elements";
import { addBinLocationSelector, addBinSelector, defaultBin } from "../../../Helpers/Storage/DefaultStates";
import AddNewSampleBin from "../AddSampleModals/AddNewSampleBin";
import AddNewSampleBinLocation from "../AddSampleModals/AddNewSampleBinLocation";
import { BarCodeScanningResult, Camera } from "expo-camera";
import { setProp } from "../../../Helpers/GeneralTypes";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { GetBinDataByBarcode } from "../../../Helpers/Functions/Offline/BinFunctions";
import spacing from "../../../Styles/Spacing";
import textStyles from "../../../Styles/Text";
import BarcodeReader from "../../HelperScreens/BarcodeReader";
import VGButton from "../../../Components/Button/Button";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

// TODO: See if you can use this component in both the new sample and sample search screens
function BinSamplePicker(props: { bins: Bin[],canAdd:boolean, setBins?: setProp<Bin[]>, locations: BinLocation[], setLocations: setProp<BinLocation[]>, setPickingData: ((arg0: boolean) => void) | setProp<boolean>, pickingData: boolean, currentBin?: { bin: Bin, location: BinLocation }, setCurrentBin?: setProp<typeof props.currentBin> }) {

  const [pickingLocation, setPickingLocation] = React.useState(true);
  const [selectableBins, setSelectableBins] = React.useState<Bin[]>();
  const [scanningBarcode, setScanningBarcode] = React.useState(false);


  const [isAddingLocation, setIsAddingLocation] = React.useState(false);
  const [isAddingBin, setIsAddingBin] = React.useState(false);

  React.useEffect(() => {

    if (props.currentBin != null) {
      console.log("Filtering bins");
      const curBinList = props.bins.filter(x => (x.location_id == props.currentBin.location.id) && x.expired == 0);
      setSelectableBins(curBinList);
    }

  }, [props.currentBin?.location]);

  
  let renderSeparatorView = () => {
    return (
      <View style={{
        height: 2,
        width: "100%",
        backgroundColor: "orange",
      }}
      />
    );
  };

  function onBarcodeRead(val:string) {
    val = val.charAt(0) == 'S' ? val : "S" + val
    //Find a bin
    let found = props.bins.find((bin)=>{return bin.barcode == val});

    //if found, set the bin and location
    if(found !== undefined){
      let foundLoc = props.locations.find(loc => loc.id == found.location_id);

      if(foundLoc !== undefined){
        props.setCurrentBin({bin:found, location:foundLoc})
        props.setPickingData(false);
      }
    }
  }

  return (

  
    <Overlay isVisible={props.pickingData} onBackdropPress={() => { props.setPickingData(false) }}>
     
     <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding':'height'}>
      {!isAddingBin && !isAddingLocation ?
        <View style={spacing.Modal}>
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <Text style={textStyles.title}>{pickingLocation ? 'Pick a Location' : 'Pick a bin'}</Text>
          </View>

          <View style={spacing.VerticalSpacer}></View>


          {pickingLocation ?
            <FlatList
              ItemSeparatorComponent={renderSeparatorView}
              contentContainerStyle={{ justifyContent: 'space-evenly', alignContent: 'flex-end' }}
              keyExtractor={(item, index) => index.toString()}
              data={props.locations}
              renderItem={({ item }) =>
                <TouchableOpacity
                accessibilityLabel={item.location}
                onPress={() => {

           
                    const curBin: Bin = { ...defaultBin };
                    const bin = props.currentBin != null ? props.currentBin.bin : curBin
                    //Add data directly to sample record instead of to an equipment object
                    props.setCurrentBin({ bin: bin, location: item });
                    setPickingLocation(false);
              
                }}>
                  <Text style={{ fontSize: 24, padding: 10 }}>{item.location}</Text>
                </TouchableOpacity>
              }
            >
            </FlatList>
            :
            <FlatList
              ItemSeparatorComponent={renderSeparatorView}
              contentContainerStyle={{ justifyContent: 'space-evenly', alignContent: 'flex-end' }}
              keyExtractor={(item, index) => index.toString()}
              data={selectableBins}
              renderItem={({ item }) =>


                <TouchableOpacity
                accessibilityLabel={item.name}
                onPress={() => {
               
                    //Add data directly to sample record instead of to an equipment object
                    props.setCurrentBin({ bin: item, location: props.currentBin.location });
                    setPickingLocation(true);
                    props.setPickingData(false);
              


                }}>
                  <Text style={{ fontSize: 24, padding: 10 }}>{item.name}</Text>
                </TouchableOpacity>




              }
            >
            </FlatList>
          }


          {props.canAdd ?
          pickingLocation ?      <VGButton role="PRIMARY" onPress={async () => {
            setIsAddingLocation(true);
          }}>Add Location</VGButton>
           :


                <VGButton role="PRIMARY" onPress={async () => {
            setIsAddingBin(true);
          }}>Add Bin</VGButton> 
          : null}
        
   

            <VGButton role="SECONDARY" onPress={async () => {
              setScanningBarcode(true);
            }}>Scan Barcode</VGButton>

            <VGButton role="CANCEL" onPress={async () => {
              if(pickingLocation) {
                props.setPickingData(false);
              } else {
                setPickingLocation(true);
              }
            }}>{pickingLocation ? "Cancel" : "Back"}</VGButton>
          

        </View>
        :
        isAddingBin ?
          <View style={spacing.Modal}>
            <AddNewSampleBin locations={props.locations} setLocations={props.setLocations} bins={props.bins} setBins={props.setBins} isAddingBin={isAddingBin} setIsAddingBin={setIsAddingBin} currentBin={props.currentBin} setCurrentBin={props.setCurrentBin} setPickingData={props.setPickingData} />
          </View>
          :
          <View style={spacing.Modal}>
            <AddNewSampleBinLocation locations={props.locations} setLocations={props.setLocations} isAddingLocation={isAddingLocation} setIsAddingLocation={setIsAddingLocation} setIsAddingBin={setIsAddingBin} currentBin={props.currentBin} setCurrentBin={props.setCurrentBin} />
          </View>
      }

      {scanningBarcode ? <BarcodeReader isNumeric={false} onBarcodeRead={onBarcodeRead} setPickingData={setScanningBarcode} /> : null}


       

      
      </KeyboardAvoidingView>

    </Overlay>
  )
}
export default BinSamplePicker




const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  unfocusedContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  middleContainer: {
    flexDirection: 'row',
    flex: .5,
  },
  focusedContainer: {
    flex: 6,
  },
})