import React from 'react'
import { Alert, Dimensions, Platform, View,Text, ScrollView} from 'react-native'
import { Overlay } from 'react-native-elements'
import { TouchableOpacity } from 'react-native-gesture-handler'
import VGButton from '../../Components/Button/Button'
import ButtonRoles from '../../Styles/ButtonRoles'
import spacing from '../../Styles/Spacing'
import styles from '../../Styles/styles'
import textStyles from '../../Styles/Text'




function CustomAlert(props:{title:string,desc:string,options:{text:string,onPress:Function,role:keyof typeof ButtonRoles}[],displayAlert:boolean})
{
    
    return (
        <Overlay overlayStyle={styles.alertOverlay} isVisible={props.displayAlert}  onBackdropPress={() => {}}>

        <View style={Platform.OS == 'web' ? styles.popupAlertWeb : styles.popupAlertMobile }>
    
                <View style={spacing.alertTitle} ><Text style={textStyles.title}>{props.title}</Text></View>
                <ScrollView style={spacing.alertDescription}><Text style={textStyles.info}>{props.desc}</Text></ScrollView>

       { props.options.map(option => {return <VGButton key={option.text} onPress={() => {option.onPress()}} role={option.role}>{option.text}</VGButton>})}
        </View>
        </Overlay>
    )

}

export default CustomAlert