import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, Image, TouchableOpacity, Alert } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { FlatList } from 'react-native-gesture-handler';
import { RestoreDeletedFields } from '../../../Helpers/Functions/Offline/FieldFunctions';
import { Field } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import { useContext } from 'react';
import AppContext from '../../../Contexts/AppContext';
import VGButton from '../../../Components/Button/Button';


function RestoringFieldScreen(props: { fields: Field[], setFields: setProp<Field[]>, checkedFields: Field[], setCheckedFields: setProp<Field[]>, deletedFields: Field[], setDeletedFields: setProp<Field[]>, isRestoring: boolean, setIsRestoring: setProp<boolean> }) {
    const appContext = useContext(AppContext);

    async function onRestorePress(){

        try {
            //Restore the deleted fields, put them back in the field list
            //we dont want to call the api again
            const profile = await GetData('Profile');
            let deletedFieldList = await RestoreDeletedFields(props.checkedFields, profile)


            if (deletedFieldList) {

                //set the deleted fields list to no longer include checked fields
                let deletedFields = props.deletedFields;
                deletedFields =    deletedFields.filter(x => { return !props.checkedFields.includes(x) })

                props.setDeletedFields([...deletedFields]);

                //set the fields list with the newly restored fields
                let oldFieldList = [...props.fields];
                props.checkedFields.forEach(x => {
                    //reset the expire date, so they show up on the list
                    x.expire_date = null;
                    //push the checked fields to the list
                    oldFieldList.push(x);
                })
                props.setFields(oldFieldList);
                props.setCheckedFields([]);
                props.setIsRestoring(false);
            }
            else {
                Toast.show('Could not restore fields');
            }

        }
        catch (error) {
            console.log(error);
            appContext.setAlertOptions({title:'Could not restore fields',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true); 
            }
        }
    

    return (

        <View style={{ flex: 1, justifyContent: 'flex-start' }}>

            <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                <Text style={{ fontSize: 28, alignSelf: 'flex-start', color: 'black' }}>Restoring Fields</Text>
            </View>
            <FlatList
                data={props.deletedFields}
                renderItem={({ item, index }) =>
                    <View >

                        <View style={{ flexDirection: 'row', borderWidth: 3, borderColor: 'lightgrey', marginVertical: 3, alignSelf: 'stretch' }}>
                            <TouchableOpacity
                            accessibilityLabel={item.name}
                                style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignSelf: 'center', padding: 5 }}
                                onPress={() => {
                                    if (props.checkedFields?.find(x => x.id == item.id) != undefined) {
                                        let oldField = props.checkedFields;
                                        const index = oldField.findIndex(x => x.id == item.id);
                                        oldField.splice(index, 1);
                                        props.setCheckedFields([...oldField])
                                    }
                                    else {
                                        let oldField = props.checkedFields;
                                        oldField?.push(item);
                                        props.setCheckedFields([...oldField]);



                                    }

                                }} >
                                {props.checkedFields?.find(x => x.id == item.id) != undefined ?
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/OrangeField.png')} />

                                    :
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/BlueField.png')} />

                                }

                                <Text style={{ fontSize: 16, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5 }}>{item.name}</Text>
                            </TouchableOpacity>
                        </View>

                    </View>
                }
                keyExtractor={(item, index) => index.toString()}
            >
            </FlatList>

            <VGButton role="PRIMARY" onPress={onRestorePress}
            >Restore</VGButton>


            <VGButton role="CANCEL"  
                onPress={() => {    
                    props.setIsRestoring(false);
                }}
            >Cancel</VGButton>
            
        </View>
    );
}
export default RestoringFieldScreen;