import { CropSelectOption, GrainSubCrop, GrainSupplier, GrainType, GrainVariety } from "../../../Helpers/Storage/UserState";
import React, { useEffect, useState, useMemo } from "react";
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView, TextInput, Alert } from "react-native";
import { Overlay } from "react-native-elements";
import VGButton from "../../../Components/Button/Button";
import { defaultGrain, defaultGrainSubCrop } from "../../../Helpers/Storage/DefaultStates";
import Icon from "react-native-vector-icons/FontAwesome";
import HybridInput from "../../../Components/Input/HybridInput";
import AddItemButton from "../../../Components/Button/AddItemButton";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import OverlayDropdown from "../../../Components/Input/OverlayDropdown";
import AddVariety from "./AddVariety";
import { SaveGrainSelection, ValidateGrain, ValidateVariety } from "../../../Helpers/Functions/Grains";
import { AlertError, FormatErrorMessages, GenerateNumberID } from "../../../Helpers/Functions/GeneralFunctions";
import { FlatList } from "react-native-gesture-handler";
import RemoveItemButton from "../../../Components/Button/RemoveItemButton";
import Spacing from "../../../Styles/Spacing";
import textStyles from "../../../Styles/Text";
import RadioButton from "../../../Components/Input/RadioButton";
import SelectItemButton from "../../../Components/Button/SelectItemButton";
import CustomTextInput from "../../../Components/Input/CustomTextInput";
import VGInput from "../../../Components/Input/Input";
import AppContext from "../../../Contexts/AppContext";



function RequestGrain( props : {
    grainTypes:GrainType[],
    subcrops:GrainSubCrop[],
    cropSelectOptions:CropSelectOption[],
    onSaved: Function,
    onClosed:Function
}) {

    const appContext = React.useContext(AppContext);


    //#region Variables
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;
    //Create a new list that we can use incase the user cancels the save process
    const [currentGrainTypes,setCurrentGrainTypes] = React.useState<GrainType[]>([...props.grainTypes]);
    const [currentGrainSubCrops,setCurrentGrainSubCrops] = React.useState<GrainSubCrop[]>([...props.subcrops]);
    const [filteredSubCrops,setFilteredSubCrops] = React.useState<GrainSubCrop[]>([...props.subcrops]);
    const [currentCropSelectOptions,setCurrentCropSelectOptions] = React.useState<CropSelectOption[]>([...props.cropSelectOptions]);
    const [varietiesAdded,setVarietiesAdded] = React.useState<GrainVariety[]>([]);

    enum ScreenState {Default,PickingGrainType,PickingGrainSubCrop,AddingVariety};
    const [currentScreenState,setCurrentScreenState] = React.useState<ScreenState>(ScreenState.Default);

    const [selectedGrainType,setSelectedGrainType] = React.useState<GrainType>({...defaultGrain});
    const [selectedGrainSubcrop,setSelectedGrainSubcrop] = React.useState<GrainSubCrop>({...defaultGrainSubCrop});

    //Used for manual entry
    const [manualEntryGrainType,setManualEntryGrainType] = React.useState<GrainType>({...defaultGrain});
    const [manualEntrySubCrop,setManualEntrySubCrop] = React.useState<GrainSubCrop>({...defaultGrainSubCrop});

    const grainTypeRef = React.useRef(null);
    const grainSubRef = React.useRef(null);

    const [addingCustomGrainType,setAddingCustomGrainType] = React.useState<boolean>(false);
    const [addingCustomGrainSubCrop,setAddingCustomGrainSubCrop] = React.useState<boolean>(false);
    //#endregion


    //#region USE EFFECTS
    React.useEffect(() => {
        
        // const filteredCropSelectOptions = currentCropSelectOptions.filter(x => x.grain_type_id == selectedGrainType.id);

        let subCrops:GrainSubCrop[] = [];

        currentGrainSubCrops.forEach(subCrop => {
           if(subCrops.findIndex(x => x.id == subCrop.id) == -1 && subCrop.name != '' && subCrop.name != null)
           {
            subCrops.push(subCrop);
           }
        })

     
    
        setFilteredSubCrops(subCrops);

        
    },[selectedGrainType])


    

    //Ensure that the grainSubCrop uses the default NULL record from the actual database
    React.useEffect(() => {
        if(selectedGrainSubcrop?.name == '' || selectedGrainSubcrop == null)
        {
            setSelectedGrainSubcrop({...defaultGrainSubCrop});
        }
    },[selectedGrainSubcrop])

    React.useEffect(() =>{
        if(addingCustomGrainType)
        {
            setAddingCustomGrainSubCrop(true);
        }
    },[addingCustomGrainType]);
    
    //#endregion


    //#region FUNCTIONS
    
        /**
     * This function will attempt to save user grain data
     */

    async function SaveGrain()
    {
        const validGrain = ValidateGrain(addingCustomGrainType ? manualEntryGrainType :selectedGrainType, addingCustomGrainSubCrop ? manualEntrySubCrop: selectedGrainSubcrop);
        if(validGrain.length == 0)
        {
            //if the user is creating custom entries, we need to generate the ID before trying to save
            const manualType = {...manualEntryGrainType, id: await GenerateNumberID()}
            const manualSubCrop = {...manualEntrySubCrop, id: await GenerateNumberID()}
            
            const isAddingSubCrop = addingCustomGrainSubCrop && manualEntrySubCrop?.name != null && manualEntrySubCrop?.name != '' ? true :false; 

            const grainStatus = await SaveGrainSelection(addingCustomGrainType ? manualType :selectedGrainType,isAddingSubCrop ? manualSubCrop: selectedGrainSubcrop,varietiesAdded,addingCustomGrainType,isAddingSubCrop);
    
            if(grainStatus != null)
            {
             console.log('Saved Grain Data');   
                   props.onSaved(true);
            }
            else
            {
                props.onSaved(false);
                console.log('Could not save Grain data');
            }
           
        }
        else
        {
            const fullString =  FormatErrorMessages(validGrain);
            appContext.setAlertOptions({title:'Invalid Grain',desc:fullString,options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);   
        }
    }


    function SaveVariety(variety:GrainVariety)
    {
        const validVariety = ValidateVariety(variety);
                
        if(validVariety.length == 0)
        {
                setVarietiesAdded([...varietiesAdded,variety]);
                setCurrentScreenState(ScreenState.Default);
   


        
        }
        else
        {

                const fullString =  FormatErrorMessages(validVariety);
                appContext.setAlertOptions({title:'Invalid Variety',desc:fullString,options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                        appContext.setShowAlert(false);}}
                  ]});
                   appContext.setShowAlert(true);   
            }
        }

        function OpenDropDown(state:ScreenState)
        {
            setCurrentScreenState(state);
        }

       /**
        * 
        * @param stateToChangeTo Change to either manual text or dropdown selection for grain types
        * @returns 
        */
       function ToggleGrainType(stateToChangeTo:boolean)
       {
        setAddingCustomGrainType(stateToChangeTo);
       }


       /**
        * 
        * @param stateToChangeTo Change to either manual text or dropdown selection for grain Sub Crops
        * @returns 
        */
       function ToggleGrainSubCrop(stateToChangeTo:boolean)
       {
        setAddingCustomGrainSubCrop(stateToChangeTo);
       }

       
        function FocusInput(refToFocus:React.MutableRefObject<any>)
        {
            refToFocus.current.focus();
        }


        function RemoveVarietyFromList(index:number)
        {
            setVarietiesAdded([...varietiesAdded.slice(0,index),...varietiesAdded.slice(index+1)]);

        }


       function Close()
       {
        props.onClosed();
       }
     

       function OnGrainTypeChangeText(textToChange:string)
       {
        setManualEntryGrainType({...manualEntryGrainType,name:textToChange});
       }

       function  OnGrainSubChangeText(textToChange:string)
       {
        setManualEntrySubCrop({...manualEntrySubCrop,name:textToChange});
       }
   

       function OnSelectGrainType(item:GrainType)
       {
        setSelectedGrainType(item);
        //setAddingCustomGrainType(false);
        setCurrentScreenState(ScreenState.Default);
       }

       function OnSelectGrainSubCrop(item:GrainSubCrop)
       {
        setSelectedGrainSubcrop(item);
        setCurrentScreenState(ScreenState.Default);
       }


    //#endregion




    return(

<>
{currentScreenState != ScreenState.AddingVariety ?

 <View style={{flex:1}}>
 <View style={Spacing.ScreenHeader}>
     <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Request Grain</Text>
 </View>


 <OverlayDropdown dropdownStatus={currentScreenState == ScreenState.PickingGrainSubCrop ? true: false} listToSelectFrom={filteredSubCrops} propertiesToShow={['name']} onCanceled={() => {OpenDropDown(ScreenState.Default)}} onSelected={(item) => {OnSelectGrainSubCrop(item)}}/>
 <OverlayDropdown dropdownStatus={currentScreenState == ScreenState.PickingGrainType ? true: false} listToSelectFrom={currentGrainTypes.filter((v,i,a) => a.indexOf(v) === i)} propertiesToShow={['name']} onCanceled={() => {OpenDropDown(ScreenState.Default)}} onSelected={(item) => {OnSelectGrainType(item)}}/>

<View style={Spacing.RadioRow}>
    <RadioButton labelName="Default" editable={true} checked={!addingCustomGrainType} onPress={() => {ToggleGrainType(false)}} testLabel="Default_Type_Radio_Button" />
    <RadioButton labelName="Custom Grain Type" editable={true} checked={addingCustomGrainType} onPress={() => {ToggleGrainType(true)}} testLabel="Custom_Type_Radio_Button" />
</View>


{addingCustomGrainType ? 
    <VGInput label="Grain Type" type="text" required
    onChangeText={(text) => {OnGrainTypeChangeText(text)}}
    testLabel="Manual_Entry_Grain_Type" ref={grainTypeRef} 
    value={manualEntryGrainType.name}
  />
 :
  <VGInput label="Grain Type" type="picker" required
  onPress={() => {OpenDropDown(ScreenState.PickingGrainType)}} 
testLabel="Manual_Entry_Grain_Type"
  value={selectedGrainType.name}
/>
}

{!addingCustomGrainType ?
    <View style={Spacing.RadioRow}>
    <RadioButton labelName="Default" editable={true} checked={!addingCustomGrainSubCrop} onPress={() => {ToggleGrainSubCrop(false)}} testLabel="Default_Sub_Crop_Radio_Button" />
    <RadioButton labelName="Custom Sub Crop" editable={true} checked={addingCustomGrainSubCrop} onPress={() => {ToggleGrainSubCrop(true)}} testLabel="Custom_Sub_Crop_Radio_Button" />
</View>
: null}


{addingCustomGrainSubCrop ? 
    <VGInput label="Sub Crop" type="text" required
    onChangeText={(text) => {OnGrainSubChangeText(text)}}
    testLabel="Manual_Entry_Grain_Sub_Crop_Input" ref={grainSubRef} 
    value={manualEntrySubCrop.name}
  />
 :
  <VGInput label="Sub Crop" type="picker" required
  onPress={() => {OpenDropDown(ScreenState.PickingGrainSubCrop)}} 
  testLabel="Manual_Entry_Grain_Sub_Crop_Input"
  value={selectedGrainSubcrop.name}
/>
}

 <AddItemButton buttonName="Add Variety" currentCount={varietiesAdded?.length??0} onPress={() => {OpenDropDown(ScreenState.AddingVariety)}} selecting={false} testLabel="Add_Variety_Button" />

 <View style={{flex:1,flexDirection:'column',borderColor:'black',borderWidth:3,marginVertical:5}}>
     <Text style={textStyles.title}>Varieties</Text>

        <FlatList
            keyExtractor={(item, index) => index.toString()}
            data={varietiesAdded}
                renderItem={({item,index}) => (
                    <RemoveItemButton testLabel={item.name} name={item.name} onPress={() => {RemoveVarietyFromList(index)}}/>
                )}
        />
 </View>
 <View style={{flexDirection:'column',justifyContent:'flex-end'}}>
 <VGButton role="PRIMARY" testLabel="Save_Grains_Button"  onPress={SaveGrain} >Save</VGButton>

 <VGButton role="CANCEL" testLabel="Close_Request_Grain_Screen_Button"  onPress={Close} >Close</VGButton>

 </View>

</View>
 :
    <AddVariety onClosed={() => {OpenDropDown(ScreenState.Default)}} onSaved={SaveVariety} />
 }

</>


    );

}
export default RequestGrain