export function ValidateSampleBarcode(data: string):string {
    if(data== null || (data.length != 8 && data.match(/^([1-4]{1}[0-9]{7})|([6]{1}[0-9]{7})$/) == null))
   {
       return "Must be 8 digits";
   }
   else{
    return ""
   }
}

export function ValidateBinBarcode(data: string): string {
    if(data== null || (data.toString().length != 9 && data.match(/^[Ss][0-9]{8}$/) == null))
    {
       return "Must be 'S' followed 8 digits \n Ex. S12345678"
    }
    else{
        return "";
    }
}