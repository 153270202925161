import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect } from "react";
import { useState } from "react";
import { TouchableOpacity, Text, StyleProp, GestureResponderEvent, StyleSheet, ViewStyle, TextStyle, ColorValue } from "react-native";

import spacing from "../../Styles/Spacing";
import textStyles from "../../Styles/Text";
import ButtonRoles from "../../Styles/ButtonRoles";
import Colours from "../../Styles/Colours";


type PropType = {
    role: keyof typeof ButtonRoles;
    onPress: (event: GestureResponderEvent) => void,
    children?: string | JSX.Element | never[];
    buttonStyle?: ViewStyle;
    textStyle?: TextStyle;
    disabled?: boolean;
}

/**
 * A button using Verigrain's styling, according to the Style Guide.
 * 
 * For testing, use "Button_[Text]" where [Text] is the text on the button
 * 
 * @param role 
 * The role of the button. See style guide for more information.
 * defaults to PRIMARY
 * 
 * when role is "FAB", the text is the Fontawesome Icon you would like to display.
 * when no text is entered, the defult is "plus"
 * if you see a '?' or no icon at all, it means you made a typo or tried using a PRO icon
 * 
 * 
 * @param buttonStyle
 * Styles are defined by role, but buttonStyle and textStyle
 * are included in case an override is needed. 
 * buttonStyle adds to the TouchableOpacity element's style
 * @param textStyle
 * textStyle adds to the Text element's style
 */
function VGButton(props: PropType) {

    const [container_style, setContainerStyle] = useState<ViewStyle>(styles.container_style);
    const [text_style, setTextStyle] = useState<TextStyle>(styles.text_style);

    let textColour:ColorValue; 
    let bgColour:ColorValue;
    let borderColour:ColorValue;
    let borderWidth:number = 0;
    let shadow:boolean = true;
    
    /**
     * Gets the text and button styles based on the button's role
     * @returns the styles as an object
     */
    function getStyles():{textStyle:TextStyle,buttonStyle:ViewStyle}{

        if(props.role != null && props.role == 'FAB'){
            return {
                buttonStyle:{
                    backgroundColor: Colours.VG_BLUE,
                    borderRadius: 50,
                    width: 56,
                    height: 56,
                    position: "absolute",
                    right: 16,
                    bottom: 16
                },
                textStyle:{
                    display:"none"
                }
            }

        }

        if(props.disabled == null || props.disabled == false){
            switch(props.role){
                case "PRIMARY": 
                textColour = Colours.WHITE;
                bgColour = Colours.VG_BLUE;
                break;
    
                case "SECONDARY" : 
                textColour = Colours.VG_BLUE;
                bgColour = Colours.WHITE;
                borderColour = Colours.VG_BLUE;
                borderWidth = 2;
                break;
    
                case "CANCEL" : 
                textColour = Colours.VG_GREY;
                bgColour = Colours.WHITE;
                borderColour = Colours.VG_GREY;
                borderWidth = 2;
                break;
    
                case "DESTRUCTIVE" : 
                textColour = Colours.WHITE;
                bgColour = Colours.ERROR;
                break;
                
                default: 
                textColour = Colours.WHITE;
                bgColour = Colours.VG_BLUE;
                break;
            }
        }
        else{
            textColour = Colours.VG_GREY;
            bgColour = Colours.VG_GREY_30;
            shadow = false;
        }

        //android doesn't allow shadows, only elevation
        //TODO test shadow on ios
        return {
            textStyle: {
                color:textColour
            },

            buttonStyle: {
                backgroundColor: bgColour,
                borderColor: borderColour,
                borderWidth: borderWidth,
                elevation: shadow ? 3 : 0,
                shadowOpacity: shadow ? 0.25 : 0,
            }
        }
    }
    

    useEffect(() => {
        
        const container_styles: ViewStyle[] = [];
        const text_styles: TextStyle[] = [];
        const roleStyle = getStyles();

        container_styles.push(roleStyle.buttonStyle);
        container_styles.push(spacing.Element);
        text_styles.push(roleStyle.textStyle);
        
        container_styles.push(styles.container_style);
        text_styles.push(styles.text_style);


        if (props.buttonStyle != null) {
            container_styles.push(props.buttonStyle);
        }

        if (props.textStyle != null) {
            text_styles.push(props.textStyle);
        }


        setContainerStyle(StyleSheet.flatten(container_styles));
        setTextStyle(StyleSheet.flatten(text_styles));
    }, [props.disabled, props.buttonStyle, props.textStyle, props.role]);

    

    return (
        <TouchableOpacity 
            accessibilityLabel= {typeof props.children == 'string' ? "Button_" + props.children : "Button_" + props.role}
            disabled={props.disabled}
            style={container_style}
            onPress={props.onPress}>
            {props.role == "FAB" ? <FontAwesome size={24} 
            name={ props.children == null ? "plus" : props.children as any}
            style={{alignSelf:"center", color:Colours.WHITE}} /> 
            :
            typeof props.children === "string" ?
                <Text style={text_style}>{props.children}</Text>
                : props.children != null ?
                    props.children
                    : null
            }
        </TouchableOpacity>
    );
}

export default VGButton;

const styles = StyleSheet.create({
    container_style: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'stretch',
        paddingVertical: 10,
        paddingHorizontal:15,
        shadowColor: Colours.BLACK,
        shadowOffset: {height: 2, width: 0},
        shadowRadius: 3,
    },
    text_style: {
        textAlign: 'center',
        fontFamily: 'Barlow Semi Condensed Medium',
        fontSize: 22
    },
});