import React from "react";
import { TextInput, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import Icon from 'react-native-vector-icons/FontAwesome';
import VGInput, { height as InputHeight} from "../../../../Components/Input/Input";
import { ValidID } from "../../../../Helpers/Functions/GeneralFunctions";
import { setProp } from "../../../../Helpers/GeneralTypes";
import { CharacteristicSubOption, CharacteristicType, Enterprise, SampleRecordCharacteristic, Buyer, MasterSample, Lab, InstrumentModel } from "../../../../Helpers/Storage/UserState";
import Colours from "../../../../Styles/Colours";
import spacing, { MediumSpacing } from "../../../../Styles/Spacing";
import textStyles from "../../../../Styles/Text";


type PropTypes = {
    characteristicType: CharacteristicType;
    characteristicSubOptions: CharacteristicSubOption[];
    sampleChars: SampleRecordCharacteristic[];
    setSampleChars: setProp<SampleRecordCharacteristic[]>;
    selectedSample: MasterSample;
    selectedBuyer?: Buyer;
    selectedEnterprise?: Enterprise;
    selectedInstrument?:InstrumentModel;
    selectedLab?:Lab;
};

function CharacteristicInput(props: PropTypes) {
    const [isCollapsed, setCollapsed] = React.useState(true);

    function onItemPress(sub) {
        let oldSampleChars = props.sampleChars;
        const index = oldSampleChars.findIndex(c =>c.characteristic_sub_id == sub.id)
        if (index > -1) {
            oldSampleChars.splice(index, 1);
        }
        else {
         
            if (ValidID(props?.selectedSample?.id)) {
                console.log('Pushing to sample RE');
                let enterpriseID = null;
                if(props.selectedEnterprise != null && ValidID(props.selectedEnterprise.id))
                {
                    enterpriseID = props.selectedEnterprise.id;
                }
                let buyerID = null;
                if(props.selectedBuyer != null && ValidID(props.selectedBuyer.id))
                {
                    buyerID = props.selectedBuyer.id;
                }
                let labID = null;
                if(props.selectedLab != null && ValidID(props.selectedLab.id))
                {
                    labID = props.selectedLab.id;
                }
                let instrumentID = null;
                if(props.selectedInstrument != null && ValidID(props.selectedInstrument.id))
                {
                    instrumentID = props.selectedInstrument.id;
                }
                else
                {
                    console.log('Not a valid instrument...' + JSON.stringify(props.selectedInstrument));
                }
                oldSampleChars.push({buyer_id:buyerID,characteristic_sub_id:sub.id,created_at:null,enterprise_id:enterpriseID, id:null,lab_id:labID,master_sample_id:props.selectedSample.id,updated_at:null, user_instrument_id:instrumentID,value:null});
            }
            else {
                console.error("NO VALID SAMPLE");
            }
        }
        props.setSampleChars([...oldSampleChars]);
    }

    function onChangeValueText(text, sub) {
        let oldChars = props.sampleChars;
        let sampleChar = oldChars.find(x => x.characteristic_sub_id == sub.id);
        if (sampleChar != undefined) {
            oldChars.find(x => x.characteristic_sub_id == sub.id).value = text;
            props.setSampleChars([...oldChars]);
        }
        else {
            console.log('Could not find sample char');
        }
    }

    return (
        <View style={spacing.Element} >
            <TouchableOpacity onPress={() => {
                setCollapsed(!isCollapsed);
            }} style={{ flexDirection: 'row'}}>
                <Text style={{ fontSize: 28, flex: 1, left: 10 }}>{props.characteristicType.name}</Text>
                <Icon style={{ right: 10 }} name={!isCollapsed ? "minus" : "plus"} size={24} color="#1c448c" />
            </TouchableOpacity>
            {props.characteristicSubOptions.map((sub) => {
                if (!isCollapsed) {
                    return <TouchableOpacity key={sub.id} onPress={()=>onItemPress(sub)} style={{flexDirection:"row", height:InputHeight + MediumSpacing}} >
                                <Icon style={{ alignSelf: 'center' }} name="square" size={35} color={props.sampleChars.findIndex(x => x.characteristic_sub_id == sub.id) > -1 ? 'orange' : '#1c448c'} />
                                <View style={{ alignSelf: 'center',  marginLeft: 30 }}></View>
                                <Text style={[textStyles.pickerItem, {flex: 1, alignSelf:"center", color: props.sampleChars.findIndex(x => x.characteristic_sub_id == sub.id) >= 0 ? Colours.VG_AMBER : Colours.BLACK }]}>{sub.name}</Text>
                                
                                {props.sampleChars.findIndex(x => x.characteristic_sub_id == sub.id) > -1 ?
                                    <View style={{flex:1, alignSelf:"center"}}>
                                        <VGInput label="value" testLabel={sub.name + '_Input'}
                                            onChangeText={(text)=>onChangeValueText(text, sub)}
                                            keyboardType={sub.id == -1 ? 'default' : 'numeric'}
                                            value={props.sampleChars.find(x =>  x.characteristic_sub_id == sub.id)?.value ?? ''}
                                        />
                                    </View>
                                    
                                    :
                                    null
                                    }
                    </TouchableOpacity>
                }
                else {
                    return null;
                }

            })}
        </View>
    );
}

export default CharacteristicInput;