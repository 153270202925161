import * as React from 'react';
import { View, Text, TouchableOpacity, Modal, Dimensions, Image } from 'react-native';
import styles from '../../../Styles/styles'
import { Document } from '../../../Helpers/Storage/UserState';
import Config from '../../../config';
import VGButton from '../../../Components/Button/Button';

function ViewSampleDocument(props: { document: Document, setPickingData: any }) {



    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height
    const [doneLoading, setDoneLoading] = React.useState(false);

    return (
        <View style={styles.container}>

            <Modal
                visible={true}
                onRequestClose={() => { props.setPickingData(null) }}>

                <View style={{ flex: 10 }}>


                    {props.document.doc_type == 'jpg' || props.document.doc_type == 'png' ?

                        <Image source={{ uri: Config.apiUrl + '/documents/' + props.document.file_path.toString() }}

                            style={{ width: width, height: height - 80 }} />

                        :
                        // <Pdf
                        //     trustAllCerts={false}

                        //     source={{ uri: Config.apiUrl + '/documents/' + props.document.file_path.toString(), cache: true }}
                        //     onLoadComplete={(numberOfPages, filePath) => {
                        //         console.log(`Number of pages: ${numberOfPages}`);
                        //     }}
                        //     onPageChanged={(page, numberOfPages) => {
                        //         console.log(`Current page: ${page}`);
                        //     }}
                        //     onError={(error) => {
                        //         console.log(error);
                        //     }}
                        //     onPressLink={(uri) => {
                        //         console.log(`Link pressed: ${uri}`);
                        //     }}
                        //     style={{ flex: 1, width: width, height: height }}
                        // />
                        <></>
                    }
                

                </View>


                <VGButton role="CANCEL" onPress={() => {
                    props.setPickingData(null);
                }}>Cancel</VGButton>

            </Modal>
        </View>
    )
}

export default ViewSampleDocument;