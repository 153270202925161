import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { CharacteristicSubOption, CharacteristicType, MasterSample, SampleRecordCharacteristic } from "../../Storage/UserState";
import { GenerateNumberID, GenerateNumberIDSync } from "../GeneralFunctions";

export async function GetCharacteristicOptions(): Promise<{ types: CharacteristicType[]; options: CharacteristicSubOption[] }> {
  const characteristic_types = await GetOfflineStateParsed("characteristic_types");
  const characteristic_sub_options = await GetOfflineStateParsed("characteristic_sub_options");

  return {types: characteristic_types, options: characteristic_sub_options};
}

export async function SaveSampleRecordCharacteristics(selectedOptions: SampleRecordCharacteristic[]): Promise<SampleRecordCharacteristic[]> {

  let options:SampleRecordCharacteristic[] = [];


  for(const src of selectedOptions)
  {
    let old = src;
    old.id = await GenerateNumberID();
    options.push(old);
  }
  const status = await AddToQueue("sample_record_characteristics", options, "add");

  if (status.length == 0) {
    return options;
  } else {
    // TODO: do something about the failed sample characteristics
    return options.filter(o => status.findIndex(x => x.id === o.id) === -1);
  }
}



export async function UpdateSampleRecordCharacteristic(sampleChar: SampleRecordCharacteristic): Promise<SampleRecordCharacteristic> {

  const status = await AddToQueue("sample_record_characteristics", sampleChar, "update");

  return status ? sampleChar : null;
}

export async function GetSampleRecordCharacteristics(samples: MasterSample[], profile: string): Promise<SampleRecordCharacteristic[]> {
  const charArray: SampleRecordCharacteristic[] = [];

  if (profile != null) {
    const sampleCharList = await GetOfflineStateParsed("sample_record_characteristics");

    sampleCharList.filter((char) => {
      if(samples.findIndex(x => x.id == char.master_sample_id) != -1) {
        charArray.push(char);
      }
    });
  }

  return charArray;
}
