import moment from "moment";

import { GetData, GetAsyncDataRaw, StoreAsyncData, ConcatOfflineState } from "../AsyncStorageHelper";
import { SyncQueue } from "../Storage/OfflineQueue";
import { OfflineUserInfo, UserState } from "../Storage/UserState";
import { ApiCall } from "./GeneralFunctions";
export async function GetUpdatedData(email: string, lastUpdate?: Date): Promise<UserState & OfflineUserInfo> {

 
    const formData = new FormData();
    formData.append('Email',email);
   

    if (lastUpdate && lastUpdate instanceof Date && !isNaN(lastUpdate as any)) {
        formData.append('LastUpdate',moment(lastUpdate).utc().format("YYYY-MM-DD HH:mm:ss"));
    }
    const result = await ApiCall<{ response: number, data: UserState & OfflineUserInfo }>("/offline/get-user-data", formData);

    //  console.log("\n\n\nUSER DATA DOWNLOAD DEBUG:\n", result, "\n\n\n");

    return result != null ? result.data : null;
}

/**
 * Tries to get all of the data connected to the current user since the "Last Update" datetime.
 * A Date object can be passed in to manually override this date.
 * 
 * @param manual_update_date Manual Override for the Last Update Date that is used to query the database
 * @returns Whether or not it was successful
 */
export async function LoadData(manual_update_date?: Date): Promise<boolean> {
    const email = await GetData("Email");

    let lastUpdate;

    if (manual_update_date == null) {
        const date_string =  GetAsyncDataRaw("LastUpdate");
        
        console.log('Attempted Date String: ' + JSON.stringify(date_string));
        try
        {
            const timestamp = Date.parse(date_string);
            if(!isNaN(timestamp))
            {
                lastUpdate = new Date(date_string);
                console.log("Last Update: ", lastUpdate.toISOString());
            }
            else
            {
                console.log('No Lastupdate was found, if it should, please fix ASAP');
                lastUpdate = new Date(1995, 11, 17)  
            }
        }
        catch(error)
        {
            console.log('Invalid date occured, grabbing all data...' + error);
            lastUpdate = new Date(1995, 11, 17)  
        }
      
     
    }
    else {
        lastUpdate = manual_update_date;
    }

    //Get the datetime before sending the api request to avoid any issues
    //with missing data added as the api responds
    const now = new Date();

    const status = await GetUpdatedData(email, lastUpdate);

    // If we successfully hear back from the api
    if (status != null) {
        await StoreAsyncData("User", status.user);
        await StoreAsyncData("SecondaryUsers", status.other_users);
        delete status.user;
        delete status.other_users;
        // Attempt to merge the response into the current offline state
        try {
            await ConcatOfflineState(status);
           // console.log("\n\nReturned Info: ", status, "\n\n");
            await StoreAsyncData("LastUpdate", now.toISOString());
            console.log("Successfully updated local storage");
         
           // Toast.show("Data update finished");
            return true;

        }
        catch(e) {
          //  Toast.show("Could not update local data...");
            console.log("Problem updating data: ", e);
            return false;
        }
    }
    else {
        console.log("Could not reach database...");
        return false;
    }
}

export async function FullSync() {


    //Only attempt to load data if logging in for first time and your on DEV mode,
    const date_string =  GetAsyncDataRaw("LastUpdate");
    if(date_string == null && __DEV__)
    {
        console.log('Loading data on start-up')
        await LoadData();

    }
    else if(!__DEV__)
    {
        console.log('Loading data on production')
        await LoadData();
    }
    console.log('Attempting to Sync Offline Queue...');
  //  Toast.show("Starting sync...");
    await SyncQueue();
}