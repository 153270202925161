import * as React from 'react';
import { DrawerActions, NavigationContainer, useIsFocused } from '@react-navigation/native';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList} from 'react-native';

//Similar to the barcode camera, except it has no barcode scan capabilities
 function CustomImage(props: {enterpriseID:number,width:number,height:number})
{

  const isFocused = useIsFocused();



    const project = () => {
        switch(props.enterpriseID) {
  
          case 1:   return <View>
          <Image
          style={{ resizeMode: 'contain',width:props.width,height:props.height,padding:10}}
          source={require("../../assets/AEG_Logo.png")}/></View>;

          case 2:   return <>
          <Image
               style={{ resizeMode: 'contain',width:props.width,height:props.height}}
          source={require("../../assets/Grower_Logo.png")}/></>;

          case 3: return <>
                    <Image
               style={{ resizeMode: 'contain',width:props.width,height:props.height}}
          source={require("../../assets/CGF_Logo.png")}/>
          </>
                    case 4: return <>
                    <Image
               style={{ resizeMode: 'contain',width:props.width,height:props.height}}
          source={require("../../assets/Lighthouse_Logo.png")}/>
          </>

          case 5: return <>
          <Image
          style={{ resizeMode: 'contain',width:props.width,height:props.height}}
          source={require("../../assets/Global_Grain.png")}/>
          </>
          case 6: return <>
            <Image
              style={{ resizeMode: 'contain',width:props.width,height:props.height}}
              source={require("../../assets/JGL_Logo.png")}/>
              </>
                        case 7: return <>
                        <Image
                          style={{ resizeMode: 'contain',width:props.width,height:props.height}}
                          source={require("../../assets/Pristine_Logo.png")}/>
                          </>
                                    case 8: return <>
                                    <Image
                                      style={{ resizeMode: 'contain',width:props.width,height:props.height}}
                                      source={require("../../assets/Exceed_Logo.png")}/>
                                      </>
          default:
          return <></>
        }
      }
 


    return(

        <View>
            {project()}
        </View>
       
        
    );
}



export default CustomImage