import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity, Alert,Image, DeviceEventEmitter, Share, Platform, Dimensions, Linking } from 'react-native';
import {ScrollView } from 'react-native-gesture-handler';
import { GetData } from '../../Helpers/AsyncStorageHelper';
import Icon from 'react-native-vector-icons/FontAwesome';
import moment from 'moment';
import { PendingAnalysis } from '../../Helpers/Storage/UserState';
import { useIsFocused } from '@react-navigation/native';
import { useContext } from 'react';
import { learnMoreLookupData } from '../../Helpers/Storage/LearnMoreInfoLookup';
import AppContext from '../../Contexts/AppContext';
import { GetLocalUserManual } from '../../Helpers/Functions/Online/UserFunctions';
import { GetAllAnalysisRecords } from '../../Helpers/Functions/Online/AnalysisFunctions';
import { GetPendingBuyerRecords } from '../../Helpers/Functions/Offline/BuyerAnalysisFunctions';
import spacing from '../../Styles/Spacing';
 function SamplesPendingScreen({ route, navigation }: { route: any, navigation: any })
{



const [analysisRecords,setAnalysisRecords] = React.useState<PendingAnalysis[]>();
const [shownAnalysis,setShownAnalysis] = React.useState<{sampleID:number,barcode:number}[]>([]);
const [showAllAnalysis,setShowAllAnalysis] = React.useState(false);
const currentContext = useContext(AppContext);
const isFocused = useIsFocused();
React.useEffect(() => {
  if(isFocused)
  {
    const getPages = async() => {
                                    
        let data =await GetLocalUserManual();
        if(data != null)
        {
          currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'PendingAnalysis'))
        }
        else
        {
          currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='PendingAnalysis'));
        }
      };
      getPages();       }
},[isFocused])

  React.useEffect(() => {


    const getRecords = async ()=> {

        const profile = await GetData('Profile');

                  //Grab Record Data
                  const sampleRecords = await GetAllAnalysisRecords(profile);
                  const buyerRecords = await GetPendingBuyerRecords(profile);

                  let records :PendingAnalysis[] = []; 

                  if(sampleRecords != null)
                  {
                    records=  records.concat(sampleRecords);
                  }
                  if(buyerRecords != null)
                  {
                      console.log('These are records!' + buyerRecords);
                    //  records=    records.concat(buyerRecords);
                  }
      
 
         
  
                      //sort by date
                      const sortedRecords= records.sort((a,b) => 
                      moment((a.date).toString(), "YYYY-MM-DD hh:mm:ss").unix()
                      < 
                      moment((b.date).toString(), "YYYY-MM-DD hh:mm:ss").unix()
                       ? 1 : -1);
                        
          
                      setAnalysisRecords(sortedRecords);
                  

    }
    getRecords();

        
  },[])



    
    return (

        <View style={spacing.MainContainer}>


  <ScrollView contentContainerStyle={{flexGrow:1,marginHorizontal:10 }}>
      
       

{
analysisRecords?.map((record,index) => {

    return      <View key={index} style={{borderColor:'lightgrey', borderWidth:2, paddingVertical:3,marginVertical:5, paddingLeft: 5, paddingRight:5}}>
   
    <View style={{flexDirection:'row', marginVertical:5,alignItems:'center'}}>
        <Text style={{paddingLeft:10,flex:1,fontSize:18}}>Barcode: </Text>
        <Text style={{paddingRight:10,fontSize:18}} >{record.barcode}</Text>
    </View>
    <View style={{flexDirection:'row', marginVertical:5,alignItems:'center'}}>
    <Text style={{paddingLeft:10,fontSize:18,flex:1}}>{record.lab}: </Text>
        <Text style={{fontSize:16,flexWrap:'wrap',marginRight:10}} >{record.name}</Text>
    </View>

    {record.name != '' && record.name != null ? 
        <View style={{flexDirection:'row', marginVertical:5,alignItems:'center'}}>
        <Text style={{paddingLeft:10,fontSize:18,flex:1}}>Name: </Text>
            <Text style={{fontSize:16,flexWrap:'wrap',marginLeft:10,flex:1}}  numberOfLines={1} >{record.name}</Text>
        </View>
    : null}
    <View style={{flexDirection:'row', marginVertical:5,alignItems:'center'}}>
    <Text style={{paddingLeft:10,flex:1,fontSize:18}}>Grain: </Text>
        <Text style={{paddingRight:10,fontSize:18}} >{record.grain_name}</Text>
    </View>

    <View style={{flexDirection:'row', marginVertical:5,alignItems:'center'}}>
    <Text style={{paddingLeft:10,flex:1,fontSize:18}}>Received: </Text>

    <TouchableOpacity onPress={async() => {

//set the record received to either 0 or 1 (like a checkbox)
//TODO save to order_meta table
        let oldRecords = analysisRecords;
        const index = oldRecords.findIndex(x => x.order_id == record.order_id);
       oldRecords[index].received = oldRecords[index].received == 0 ? 1 : 0; 
       setAnalysisRecords([...oldRecords]);
    }}>
    {record.received == 0 ?
          <Icon style={{paddingRight:10}} name="square-o" size={35} color="black" />
        :
        <Icon style={{paddingRight:10}} name="check-square" size={35} color="black" />
        }

    </TouchableOpacity>
    </View>


</View>

})
}

</ScrollView>

        </View>
                    

    )}


export default SamplesPendingScreen; 



async function UpdateRecordStatus(analysisRecords: { sampleID: number; barcode: number; grain: string; lab: string; received: number; purchaseID: number; }[] | undefined) {
   
    let IDString = '';
    let receiveString = '';
    //loop through each record and add the receive boolean and ID
    analysisRecords?.forEach(x => {
        IDString += x.purchaseID + ',';
        receiveString += x.received + ',';
    })

    let link =  "https://webservice.verigrain.com/api/update-record-status?IDList=" +  IDString + '&ReceiveList=' + receiveString ;
    console.log(link);

    //Token header to keep requests secure
    var myHeaders = new Headers();
    myHeaders.append(
    'token',
    '$2y$12ri75v9amOGZgTs2oTK3mZe1YEOburB7BJfJ64M9QKAM5YTggvoPVa',
    );
//grab the api and check if email exists
var requestOptions = {
method: 'POST',
headers: myHeaders,
};
const getSampleData = () => fetch(link, requestOptions)
.then(response => response.json())
.then(result => {
    return result;
})
.catch(error => {
    console.log('error', error);
    return 'error';
});


const sampleData = await getSampleData();

if(sampleData.response == 0)
{
    return true;
}
else
{
    return false;
}

}



