


import * as React from 'react';
import { useIsFocused } from '@react-navigation/native';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import Toast from 'react-native-tiny-toast';
import OverlayPicker from '../../HelperScreens/OverlayPicker';
import { GetStorageTypes } from '../../../Helpers/Functions/Offline/BinFunctions';
import { Bin, BinLocation, StorageType } from '../../../Helpers/Storage/UserState';
import { addBinSelector, defaultBin } from '../../../Helpers/Storage/DefaultStates';
import { SaveBinDetails } from '../../../Helpers/Functions/Offline/BinFunctions';
import VGInput from '../../../Components/Input/Input';
import VGButton from '../../../Components/Button/Button';


//This function houses a modal with is used to add a new location to the profile's location data
function AddNewSampleBin(props: { bins: Bin[], setBins: any, locations: BinLocation[], setLocations: any, isAddingBin: boolean, setIsAddingBin: any, currentBin: { bin: Bin, location: BinLocation }, setCurrentBin: any, setPickingData: any }) {

    let isFocused = useIsFocused();
    const [storageTypes, setStorageTypes] = React.useState<StorageType[]>([])

    const NameRef = React.useRef(null);
    const [selectedType, setSelectedType] = React.useState<StorageType>();

    const sizeRef = React.useRef(null);
    const [selectedLocation, setSelectedLocation] = React.useState<BinLocation>({ ...props.currentBin.location });
    const [pickingLocation, setPickingLocation] = React.useState(false);
    const [pickingType, setPickingType] = React.useState(false);

    //This useEffect will grab the data before rendering the page
    //this means we dont need to use a button to grab data!


    const [currentLocalBin, setCurrentLocalBin] = React.useState<{ bin: Bin, location: BinLocation }>({ bin: { ...defaultBin }, location: props.currentBin.location });

    async function onSavePress(){
        const profile = await GetData('Profile');
        let bin = currentLocalBin;
        bin.bin.location_id = currentLocalBin.location.id;
        let savedBin = await SaveBinDetails(bin.bin, profile);

        if (savedBin != null) {

            let oldBinList = props.bins;
            oldBinList.pop();
            oldBinList.push(savedBin);
            oldBinList.push(addBinSelector);
            props.setBins([...oldBinList]);

            let oldBin = currentLocalBin;
            oldBin.bin = savedBin;
            props.setCurrentBin({ ...oldBin });
            props.setIsAddingBin(false);
            props.setPickingData(false);

        }
        else {
            console.log("Error adding new Bin from Sample.");
        }
    }


    React.useEffect(() => {

        const getStorage = async () => {
            let storageTypes = await GetStorageTypes();

            if (storageTypes != null) {
                setStorageTypes(storageTypes);
            }
            else {
                Toast.show('Could not grab storage types');
            }
        }
        getStorage();


    }, []);

    React.useEffect(() => {
        if (selectedType && selectedType.id != 0) {
            let oldBin = currentLocalBin;
            oldBin.bin.storage_type_id = selectedType.id;
            props.setCurrentBin({ ...oldBin });
        }
    }, [selectedType])

    function onChangeNameText(text){
        let oldBin = currentLocalBin;
        let bin2: Bin = oldBin.bin;
        bin2.name = text;
        props.setCurrentBin({ bin: bin2, location: oldBin.location });
    }

    function onChangeSizeText(text) {
        let oldBin = currentLocalBin;
        let bin2: Bin = oldBin.bin;
        if (!isNaN(parseInt(text))) {

            bin2.size = parseInt(text);
            props.setCurrentBin({ bin: bin2, location: oldBin.location });
        }
        else {
            bin2.size = 0;
            props.setCurrentBin({ bin: bin2, location: oldBin.location });
        }

    }

    function onTypeInputPress(){
        setPickingType(true);
    }

    function onLocationInputPress(){
        setPickingLocation(true);
    }

    function onCancelPress(){
        props.setIsAddingBin(false);
    }



    return (
        <View style={{ flex: 1, justifyContent: 'space-evenly', marginHorizontal: 10, backgroundColor: 'white' }}>

            {/* Overlay pickers for the inputs below */}
            <OverlayPicker text={'Pick a Location'} type={['location']} isVisible={pickingLocation} setVisible={setPickingLocation} setData={props.setLocations} data={props.locations} />
            <OverlayPicker type={['full_name']} text={'Pick a Storage Type'} isVisible={pickingType} setVisible={setPickingType} setData={setSelectedType} data={storageTypes} />


            {/* Title */}
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', backgroundColor: 'white', paddingVertical: 3, marginTop: 10 }}>
                <Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Add Bin</Text>
            </View>

            {/* Input Fields */}
            <VGInput type="picker" label="Location" 
                value={currentLocalBin?.location?.location ?? ''} 
                onPress={onLocationInputPress}
            />

            <VGInput label="Name" 
                onChangeText={onChangeNameText}
                value={currentLocalBin?.bin?.name ?? ''}
            />

            <VGInput type='picker' label="Type" 
                value={selectedType?.short_name ?? ''}
                onPress={onTypeInputPress}  
            />

            <VGInput label="Size" 
                keyboardType={'numeric'} 
                onChangeText={onChangeSizeText}
                value={![null, 0].includes(currentLocalBin.bin.size) ? currentLocalBin.bin.size.toString() : ''}
            />

            {/* Buttons */}
            <VGButton role="PRIMARY" onPress={onSavePress}
                >Save</VGButton>

            <VGButton role="CANCEL" onPress={onCancelPress}>Close</VGButton>

        </View>

    )
}










export default AddNewSampleBin