import React from 'react';
import FlatbottomTemplate from '../../../bin_images/FlatbottomTemplate.svg';
import HopperTemplate from '../../../bin_images/HopperTemplate.svg';
import FlatbottomOneIn from '../../../bin_images/1-8FlatbottomIn.svg';
import FlatbottomOneOut from '../../../bin_images/1-8FlatbottomOut.svg';
import FlatbottomTwoIn from '../../../bin_images/2-8FlatbottomIn.svg';
import FlatbottomTwoOut from '../../../bin_images/2-8FlatbottomOut.svg';
import FlatbottomThreeIn from '../../../bin_images/3-8FlatbottomOut.svg';
import FlatbottomThreeOut from '../../../bin_images/3-8FlatbottomOut.svg';
import FlatbottomFourIn from '../../../bin_images/4-8FlatbottomOut.svg';
import FlatbottomFourOut from '../../../bin_images/4-8FlatbottomOut.svg';
import FlatbottomFiveIn from '../../../bin_images/5-8FlatbottomOut.svg';
import FlatbottomFiveOut from '../../../bin_images/5-8FlatbottomOut.svg';
import FlatbottomSixIn from '../../../bin_images/6-8FlatbottomOut.svg';
import FlatbottomSixOut from '../../../bin_images/6-8FlatbottomOut.svg';
import FlatbottomSevenIn from '../../../bin_images/7-8FlatbottomOut.svg';
import FlatbottomSevenOut from '../../../bin_images/7-8FlatbottomOut.svg';
import FlatbottomEight from '../../../bin_images/8-8Flatbottom.svg';
import HopperOneIn from '../../../bin_images/1-8HopperIn.svg';
import HopperOneOut from '../../../bin_images/1-8HopperOut.svg';
import HopperTwoIn from '../../../bin_images/2-8HopperIn.svg';
import HopperTwoOut from '../../../bin_images/2-8HopperOut.svg';
import HopperThreeIn from '../../../bin_images/3-8HopperIn.svg';
import HopperThreeOut from '../../../bin_images/3-8HopperOut.svg';
import HopperFourIn from '../../../bin_images/4-8HopperIn.svg';
import HopperFourOut from '../../../bin_images/4-8HopperOut.svg';
import HopperFiveIn from '../../../bin_images/5-8HopperIn.svg';
import HopperFiveOut from '../../../bin_images/5-8HopperOut.svg';
import HopperSixIn from '../../../bin_images/6-8HopperIn.svg';
import HopperSixOut from '../../../bin_images/6-8HopperOut.svg';
import HopperSevenIn from '../../../bin_images/7-8HopperIn.svg';
import HopperSevenOut from '../../../bin_images/7-8HopperOut.svg';
import HopperEight from '../../../bin_images/8-8Hopper.svg';

const BinSVGs = {
  FlatbottomTemplate: FlatbottomTemplate,
  FlatbottomOneIn: FlatbottomOneIn,
  FlatbottomOneOut: FlatbottomOneOut,
  FlatbottomTwoIn: FlatbottomTwoIn,
  FlatbottomTwoOut: FlatbottomTwoOut,
  FlatbottomThreeIn: FlatbottomThreeIn,
  FlatbottomThreeOut: FlatbottomThreeOut,
  FlatbottomFourIn: FlatbottomFourIn,
  FlatbottomFourOut: FlatbottomFourOut,
  FlatbottomFiveIn: FlatbottomFiveIn,
  FlatbottomFiveOut: FlatbottomFiveOut,
  FlatbottomSixIn: FlatbottomSixIn,
  FlatbottomSixOut: FlatbottomSixOut,
  FlatbottomSevenIn: FlatbottomSevenIn,
  FlatbottomSevenOut: FlatbottomSevenOut,
  FlatbottomEight: FlatbottomEight,
  HopperTemplate: HopperTemplate,
  HopperOneIn: HopperOneIn,
  HopperOneOut: HopperOneOut,
  HopperTwoIn: HopperTwoIn,
  HopperTwoOut: HopperTwoOut,
  HopperThreeIn: HopperThreeIn,
  HopperThreeOut: HopperThreeOut,
  HopperFourIn: HopperFourIn,
  HopperFourOut: HopperFourOut,
  HopperFiveIn: HopperFiveIn,
  HopperFiveOut: HopperFiveOut,
  HopperSixIn: HopperSixIn,
  HopperSixOut: HopperSixOut,
  HopperSevenIn: HopperSevenIn,
  HopperSevenOut: HopperSevenOut,
  HopperEight: HopperEight,
}
export default function BinSVG(props) {
  const BinImage = BinSVGs[props.binImage];
  return <BinImage width={props.width} height={props.height} />;
}

// export  default {
  
//   'image1': require('../../../bin_images/HopperTemplate.svg'),
//   'image2': require('../../../bin_images/FlatbottomTemplate.svg'),
// }