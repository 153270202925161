
import { UserState, UserStateKey } from './Storage/UserState';
import { SecureStoreKeys, AsyncStorageKeys } from './StorageKeys';
import { EnsureArray } from './GeneralTypes';
import moment from 'moment';
import { defaultUserState } from './Storage/DefaultStates';
import { MMKV } from 'react-native-mmkv';
import { Platform } from 'react-native';

export const storage = new MMKV();

export const userCredentialStorage = new MMKV();


/**
 * Stores a key value pair in the encrypted storage using the provided key
 * @param key A string that matches one of the string in the SecureStoreKeys type
 * @param value A string or JSON value to be stored in the key value pair
 */
export  function StoreData(key: SecureStoreKeys, value: string): void {
    try {
 
        let jsonValue = value;
        if (typeof value !== 'string') jsonValue = JSON.stringify(value);
        userCredentialStorage.set(key,value);
     
    } catch (error) {
        console.error(`Failure storing data in SecureStore:\n\tError: ${error}\n\tKey: ${key}`);
         storeError(`Failure storing data in SecureStore:\n\tError: ${error}\n\tKey: ${key}`);
    }
}


/**
 * Retrieves data from the encrypted storage
 * @param key A key from the SecureStoreKeys type
 * @returns The string value stored in the key value pair
 */
export  function GetData(key: SecureStoreKeys): string {
    try {

        let data;
       data = userCredentialStorage.getString(key);
      
        return data;
    } catch (error) {
        console.log(error);
         storeError(error);
        return '';
    }
}

/**
 * Gets data from the encrypted storage and parses the string, returning it as the given type
 * @param key A key from the SecureStoreKeys type
 * @returns A parsed value of the given type
 */
export async function GetParsedData<T>(key: SecureStoreKeys): Promise<T> {
    try {
        
        let json;
 
        json = userCredentialStorage.getString(key);
        const result = JSON.parse(json);
        return result;
    } catch (error) {
        console.log(error);
        await storeError(error);
        return null;
    }
}

/**
 * Removes a key-value pair from the encrypted storage
 * @param key A key from the SecureStoreKeys type
 * @returns void
 */
export async function RemoveData(key: SecureStoreKeys): Promise<void | ""> {
    try {

        userCredentialStorage.delete(key);

    } catch (error) {
        await storeError(error);
        return '';
    }
}

/*************************** Async Storage Functions ***************************/

/**
 * Attempts to store a value using the given key, will catch and log any errors
 * @param key A key from the AsyncStorageKeys type
 * @param value A value to be stringified and stored in the key-value pair
 * @returns void
 */
export async function StoreAsyncData(key: AsyncStorageKeys, value: NonNullable<any>) {
    try {
        return StoreAsyncDataUnsafe(key, value);
    } catch (e) {
        // saving error
        await storeError(e);
        console.log('Error saving data to Async Storage: ', e);
    }
}

/**
 * Attempts to store a value using the given key, does NOT catch errors
 * @param key A key from the AsyncStorageKeys type
 * @param value A value to be stringified and stored in the key-value pair
 * @returns void
 */
export function StoreAsyncDataUnsafe(key: AsyncStorageKeys, value: any) {
    if (value == undefined) return;

    let jsonValue = value;
    if (typeof value !== 'string') jsonValue = JSON.stringify(value);
    storage.set(key, jsonValue);
    //AsyncStorage.setItem(key, jsonValue);
}

export function RemoveAsyncData(key:AsyncStorageKeys)
{
    try
    {
        storage.delete(key);
    }
    catch
    {
        console.log('key could not be deleted')
    }

}
/**
 * Attempts to retrieve a value using the given key, will catch and log any errors
 * @param key A key from the AsyncStorageKeys type
 * @returns The value in string form
 */
export function GetAsyncDataRaw(key: AsyncStorageKeys): string {
    try {
        //const jsonValue = await AsyncStorage.getItem(key);
        const jsonValue = storage.getString(key);
        return jsonValue;
    } catch (e) {
        // error reading value
        console.log(`Error reading [${key}]: ${e}`);
        storeError(e);
    }
}

/**
 * Attempts to retrieve a value using the given key, parses the value using the given type.
 * Will catch and log any errors
 * @param key A key from the AsyncStorageKeys type
 * @returns The value parsed and returned as the given type
 */
export  function GetAsyncDataParsed<T>(key: AsyncStorageKeys): T {
    try {
        //const jsonValue = await AsyncStorage.getItem(key);
        const jsonValue = storage.getString(key);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
         storeError(e);
        // error reading value
    }
}

/*************************** Offline State Functions ***************************/

/*
*   These functions are wrappers for the AsyncStorage specialized for interacting with
*   user data. 
*/


// const reDateDetect = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;  // starts with: yyyy-mm-dd hh:mm:ss
export const DateKeys = ['created_at', 'updated_at', 'expire_date', 'date', 'allow_from_date'];

/**
 * Returns the stored array of the given key in the relevant type.
 * Ex. the key "fields" will return a Field[] following the userstate interface
 * @param key Key from the UserState interface
 * @returns An array of the given key's type
 */
export function GetOfflineStateParsed<T extends UserStateKey>(key: T): UserState[T] {
    if(key == null) return null;

    try {
        const raw_value = storage.getString(key);
        // const raw_value = await AsyncStorage.getItem(key);
        if(raw_value == null) return [];
        
        const parsed_value = JSON.parse(raw_value, (key, value) => {
            if (DateKeys.indexOf(key) > -1 && typeof value == 'string') {
                //might need to check for multiple formats?
                const date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss').toDate();

                return date;
            }
            return value;
        });
        return Array.isArray(parsed_value) ? parsed_value : [];
    } catch (e) {
        console.log('Error accessing Offline State\n', e, '\n\n');
        console.log('key: ', key);
        storeError('Error accessing Offline State\n' + e + '\nkey: ' + key);
        return [];
    }
}

/**
 * Saves data to the offline state, can not update existing data.
 * @param key Name of the table to save record in
 * @param record Record to save
 * @returns True if successful, false if any issues occur
 */
export function SaveDataToOfflineState<T extends UserStateKey>(key: T, record: EnsureArray<UserState[T]>[number]): boolean{
    try {
        let table =  GetOfflineStateParsed(key);

        if ("created_at" in record) {
            record.created_at = new Date();
        }
        if("updated_at" in record)
        {
            record.updated_at = new Date();
        }

        if (Array.isArray(table)) {
        
            table.push(record as any);
        }
        else {
            console.log('CREATING NEW TABLE>>>>>>>>>>');
            table = [record as any];
        }
       // console.log('Record Being Saved:\n', record, '\n\n');


        storage.set(key, JSON.stringify(table as any));
        // await AsyncStorage.setItem(key, JSON.stringify(table as any));
        return true;
    } catch (e) {
         storeError('Error Saving Data Locally:\n' + e + '\n\nRecord Being Saved:\n' + record + '\n\n');
        console.log('Error Saving Data Locally:\n', e, '\n\n', 'Record Being Saved:\n', record, '\n\n');
        return false;
    }
}

/**
 * Updates a record in place inside the Offline State
 * @param key Name of the table to update the record in
 * @param record Record to update
 * @param old_id Optional, can be used if the updated record has a new id
 * @returns True if update is successful
 */
export function UpdateDataInOfflineState<T extends UserStateKey>(key: T, record: EnsureArray<UserState[T]>[number], old_id?: number): boolean {
    try {
        const table =  GetOfflineStateParsed(key);

        if (!Array.isArray(table)) return false;

        let index = -1;
        if ("id" in record) {
            index = table.findIndex((x) => x.id === (old_id == null ? record.id : old_id));
        }

        if (index == null) return false;

        if ("updated_at" in record) {
            record.updated_at = new Date();
        }

     
        table[index] = { ...record };
        storage.set(key, JSON.stringify(table as any));
        // await AsyncStorage.setItem(key, JSON.stringify(table as any));
        return true;
    } catch (e) {
        if (old_id == null) {
             storeError('Error Saving Data Locally:\n' + e + '\n\nRecord Being Saved:\n' + record)
            console.log('Error Saving Data Locally:\n', e, '\n\n', 'Record Being Saved:\n', record, '\n\n');
        } else {
             storeError('Error Replacing Record In Offline State:\n' + e + '\n\n' + `Replacing ID ${old_id} with:\n` + record)
            console.log('Error Replacing Record In Offline State:\n', e, '\n\n', `Replacing ID ${old_id} with:\n`, record, '\n\n');
        }
        return false;
    }
}

/**
 * Deletes a record in place inside the Offline State
 * @param key Name of the table to update the record in
 * @param record Record to update
 * @param old_id Optional, can be used if the updated record has a new id
 * @returns True if update is successful
 */
export function RemoveDataInOfflineState<T extends UserStateKey>(key: T, record: EnsureArray<UserState[T]>[number], old_id?: number): boolean 
{

    try {
        let table =  GetOfflineStateParsed(key);

        if (!Array.isArray(table)) return false;

        let index = -1;
        if ("id" in record) {
            index = table.findIndex((x) => x.id === (old_id == null ? record.id : old_id));
        }

        if (index == null || index == -1) return false;
     
        table.splice(index,1);
        storage.set(key, JSON.stringify(table as any));
        // await AsyncStorage.setItem(key, JSON.stringify(table as any));
        return true;
    } catch (e) {
        if (old_id == null) {
             storeError('Error Deleting Data Locally:\n' + e + '\n\nRecord Being Deleted:\n' + record)
            console.log('Error Deleting Data Locally:\n', e, '\n\n', 'Record Being Deleted:\n', record, '\n\n');
        } else {
             storeError('Error Deleting Record In Offline State:\n' + e + '\n\n' + `Replacing ID ${old_id} with:\n` + record)
            console.log('Error Deleting Record In Offline State:\n', e, '\n\n', `Replacing ID ${old_id} with:\n`, record, '\n\n');
        }
        return false;
    }
}

/**
 * Will **replace** whatever data is stored using the given key with the provided array.
 * @param key Key from the UserState
 * @param new_array Array of data to store
 * @returns True if successful, false otherwise
 */
export  function ReplaceDataInOfflineState<T extends UserStateKey>(key: T, new_array: EnsureArray<UserState[T]>): boolean {
    try {
        storage.set(key, JSON.stringify(new_array));
        // await AsyncStorage.setItem(key, JSON.stringify(new_array));
        return true;
    } catch (e) {
         storeError(`Error Replacing ${key} In Offline State:\n${e}\n\n`);
        console.log(`Error Replacing ${key} In Offline State:\n${e}\n\n`);
        return false;
    }
}

/**
 * Combines all the arrays returned by the API with the ones stored in the OfflineState
 * @param other Takes in a UserState object to combine with the one stored locally
 */
export function ConcatOfflineState(other: UserState): void {
    try {
        const to_update: [string, string][] = [];

        for (const key in other) {

            //console.log('Key? ' + key.toString());
            //skip any empty arrays
            if ((other[key] as any[]).length == 0 || other[key] == undefined) {
                continue;
            }

            //Check if any data exists currently
            const current_data =  GetOfflineStateParsed(key as any);
            if (current_data == null) {
                to_update.push([key, JSON.stringify(other[key])]);
                continue;
            }

            let combined_array: UserState[UserStateKey];
            let all_ids;
            let unique_ids: Set<number>;

            //BinBarcodes and bin board specs dont have an id so we have to add an exception here
            if (key === "bin_barcodes" || key === "bin_board_specs") {
                combined_array = current_data.concat(other[key]);
                all_ids = combined_array.map((x) => x.bin_id);
                unique_ids = new Set(all_ids);
            } else {
                combined_array = current_data.concat(other[key]);
                all_ids = combined_array.map((x) => x.id);
                unique_ids = new Set(all_ids);
            }

            let combined_data = [];

            //If this is true then there are duplicate values
            if (unique_ids.size !== all_ids.length) {
                try {
                    const added_ids: Set<number> = new Set(other[key].map(x => x.id));
                    const new_array = [...other[key]];

                    for (const val of current_data) {
                        const id = key === "bin_board_specs" ? val.bin_id : val.id;
                        // only use local records if we did not receive a copy of it from the api
                        if (!added_ids.has(id)) {
                            new_array.push(val);
                            added_ids.add(id);
                        }
                    }

                    combined_data = new_array;
                }
                catch (error) {
                    console.log('ERROR' + error);

                    console.log('Key Error? ' + JSON.stringify(other[key]));
                }


            }
            else {
                //No duplicate values, so we can use the concatonated array
                combined_data = combined_array;
            }

            to_update.push([key, JSON.stringify(combined_data)])
        }

        return to_update.forEach(update => {
            storage.set(update[0], update[1]);
        })
        // return AsyncStorage.multiSet(to_update, (errors) => {
        //     if (errors != null) {
        //         console.log(`Errors merging Offline State:\n`);
        //         for (const e of errors) {
        //             console.log(`\t${e.message}\n`);
        //         }
        //         console.log('\n\n');
        //     }
        // });
    } catch (e) {
         storeError("Error during Offline State Concat..." + e);
        console.log("\nError during Offline State Concat...", e, "\n\n");
        return;
    }
}

/**
 * Clears every key-value pair storing offline state information
 * @returns void
 */
export  function ResetOfflineState(): void {
    const multi_set = Object.keys(defaultUserState).map(x => { return [x, "[]"] });

    return multi_set.forEach(update => {
        storage.set(update[0], update[1]);
    })
    // return AsyncStorage.multiSet(multi_set, (errors) => {
    //     if (errors != null) {
    //         console.log(`Errors resetting Offline State:\n`);
    //         for (const e of errors) {
    //             storeError(e.message);
    //             console.log(`\t${e.message}\n`);
    //         }
    //         console.log('\n\n');
    //     }
    // });
}


/**
 * 
 * @param error Can be of type Error, String, Object, else it will just send whatever it is to sentry
 * @param level Is the severity, using Sentry.Native.Severity. Critical,Debug,Error,Fatal,Info,Log,Warning
 */
export  function logError(error, level: any) {
    //Setting the scope so we can se the error
    // Sentry.Native.withScope(function (scope) {
    //     scope.setLevel(level);
    //     //If its an error send it, if its a string make a new error and send it
    //     if (error instanceof Error) {
    //         Sentry.Native.captureException(error);
    //     } else if (typeof error === 'string') {
    //         Sentry.Native.captureException(new Error(error))
    //     } else if (typeof error === 'object') {
    //         for (const err in error) {
    //             console.log("Sending to Sentry:");
    //             console.log(JSON.stringify(error[err], null, 2));
    //             Sentry.Native.captureException(new Error(JSON.stringify(error[err], null, 2)));
    //         }
    //     } else { // If it's not one the types we will send a message so that we will know in the future
    //         scope.setLevel(Sentry.Native.Severity.Warning);
    //         Sentry.Native.captureException(new Error("Type of error not found line 33 OfflineSentry: " + error));
    //     }
    // });
    //After the logs are sent they should be cleared
     clearSentryErrors();
};

export  function storeError(error: NonNullable<any>) {
    try {
        let data = [];
        let errors =  GetAsyncDataParsed<Array<object>>('SentryStorage'); // get the current errors
        if (errors) {
            for (const obj in errors) {
                data.push(errors[obj]); // Loop through the current sentry storage data and add it to a new array
            }
        }
        data.push(error); // Add the newest error to the array
         StoreAsyncData('SentryStorage', data); //Add it to and update the storage
    } catch (e) {
        console.log('There is an error with storeError: ' + e);
       // Sentry.Native.captureMessage('There is an error with storeError: ' + e);
    }
};

export async function uploadErrors() {
    let errors = await GetAsyncDataParsed<Array<object>>('SentryStorage');
    if (errors.length > 0) {
       // await logError(errors, Sentry.Native.Severity.Error);
    } else {
        console.log("No errors to log");
    }
    //Use for displaying errors in testing
    // console.log("uploadErrors called, Errors returned were:");
    // console.log(errors);

}

export  function clearSentryErrors() {
    let data = [];
     StoreAsyncData('SentryStorage', data);
    console.log("Sentry Storage Cleared!");
    let errors =  GetAsyncDataParsed<Array<object>>('SentryStorage'); // get the current errors
    console.log("Sentry Storage " + errors.length);
}