 import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, TouchableOpacity, Image, Alert, useWindowDimensions, } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { FlatList } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome5';
import {InstrumentModelsRaw, SaveNewInstrumentBrand, SaveNewInstrumentModel, SaveUserInstrumentModels } from '../../../Helpers/Functions/Offline/InstrumentFunctions';
import { CharacteristicSubOption, CharacteristicType, InstrumentBrand, InstrumentCharacteristic, InstrumentModel, UserInstrumentModel } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import { GetCharacteristicOptions } from '../../../Helpers/Functions/Offline/CharacteristicFunctions';
import { xor } from 'lodash';
import { GenerateNumberID, ValidID } from '../../../Helpers/Functions/GeneralFunctions';
import OverlayPicker from '../../HelperScreens/OverlayPicker';
import { FontAwesome } from '@expo/vector-icons';
import CharacteristicsSelectorModal from '../StackModals/Characteristics/CharacteristicsSelectorModal';
import VGInput from '../../../Components/Input/Input';
import spacing from '../../../Styles/Spacing';
import textStyles from '../../../Styles/Text';
import AppContext from '../../../Contexts/AppContext';
import VGButton from '../../../Components/Button/Button';

type PropTypes = {
    mode: 'add' | 'list' | 'view';
    setVisible: (visible?: boolean) => void;
    //passed in so we can add it to the list of selected instruments
    user_instrument_data: UserInstrumentModel[];
    setUserInstrumentData: setProp<UserInstrumentModel[]>;
    //needed to add a new brand if the user adds a brand that doesn't exist
    instrument_brands: InstrumentBrand[];
    setInstrumentBrands: setProp<InstrumentBrand[]>;
    //needed for the same reason as brands
    instrument_models: InstrumentModel[];
    setInstrumentModels: setProp<InstrumentModel[]>;
    //needed to provide a list of characteristics for a user when creating an instrument
    characteristicTypes:CharacteristicType[],
    characteristicSubOptions:CharacteristicSubOption[],
    instrumentCharacteristics:InstrumentCharacteristic[],
    setInstrumentCharacteristics: setProp<InstrumentCharacteristic[]>

};

function AddInstrumentScreenUpdated(props: PropTypes) {
    const appContext = React.useContext(AppContext);
    const defaultInstrumentModel: UserInstrumentModel = {
        id: -1,
        model_id: -1,
        profile: null,
        updated_at: new Date(),
    };
    const [showCharList, setShowCharList] = React.useState(false);

    const [selectedCharacterSubOptions,setSelectecCharacterSubOptions] = React.useState<CharacteristicSubOption[]>([]);
    const [pickingBrand,setPickingBrand] = React.useState(false);
    const [selectedBrand,setSelectedBrand] = React.useState<InstrumentBrand>({id:-2,created_at:null,name:'',profile:'',updated_at:null});
    const [model, setModel] = React.useState('');


    const nameRef = React.useRef(null);
    const modelRef = React.useRef(null);

    const windowWidth = useWindowDimensions().width;

    let renderSeparatorView = () => {
        return (
            <View style={{
                height: 2,
                width: windowWidth,
                backgroundColor: "orange",
            }}
            />
        );
    };



    // React.useEffect(() => {
    //     if (props.mode !== 'add') {
    //         setCharOptions({ types: props.instrumentCharData.char_types, options: props.instrumentCharData.char_sub_options });
    //     } else {
    //         const grabData = async () => {
    //             const data = await GetCharacteristicOptions();

    //             if (data != null) {
    //                 setCharOptions(data);
    //             }
    //         }
    //         grabData();
    //     }
    // }, [props.mode, props.instrumentCharData.char_types, props.instrumentCharData.char_sub_options]);


    React.useEffect(() => {

    },[selectedBrand])

    async function onRequestPress(){
                             
        const cleanModel = model.trim();


        if (!ValidID(selectedBrand.id) || cleanModel == '') {
            console.log(selectedBrand.id);
            appContext.setAlertOptions({title:'Error',desc:'Make sure both the Brand and Model are filled out.',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                 
                    appContext.setShowAlert(false);
                  }}
              ]});
               appContext.setShowAlert(true); 
            return;
        }

        //const newName = `${cleanBrand}-${cleanModel}`;

        if (selectedCharacterSubOptions.length <= 0) {

            appContext.setAlertOptions({title:'Error',desc:'Please select at least one characteristic for the instrument.',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                 
                    appContext.setShowAlert(false);
                  }}
              ]});
               appContext.setShowAlert(true); 
            return;
        }


        try {
            const profile = await GetData('Profile');

            const newInstrumentModelToSave: InstrumentModel = {
                id: await GenerateNumberID(), // generated in save function
                model_name: cleanModel,
                brand_id: selectedBrand.id, // updated later
                profile: null, // added in save function
                internet_option: null,
                web_link: null,
                created_at: new Date(),
                updated_at: new Date(),
            };
            const userInstrumentModelToSave: UserInstrumentModel = {
                id: await GenerateNumberID(), // generated in save function
                model_id: newInstrumentModelToSave.id,
                profile: null, // added in save function
                updated_at: new Date(),
            };

            
            //Save models
            
            const instrumentModelStatus = await SaveNewInstrumentModel(newInstrumentModelToSave,selectedCharacterSubOptions, profile);
            if (instrumentModelStatus != null) {
                console.log("NEW MODEL BEING SAVED");
                let oldModels = props.instrument_models;
                oldModels.push(newInstrumentModelToSave);
                props.setInstrumentModels(oldModels);

                let instrumentCharacteristicList = props.instrumentCharacteristics;
                const final =  instrumentCharacteristicList.concat(instrumentModelStatus.instrumentCharacteristics);

                console.log('FINAL CHAR USER LIST: ' + JSON.stringify(final));
                  props.setInstrumentCharacteristics([...final]);


                //Update the user Instrument Model's model_id(fk)
                userInstrumentModelToSave.model_id = instrumentModelStatus.instrumentModel.id;
            }
            
            const userInstrumentModelStatus = await SaveUserInstrumentModels([instrumentModelStatus.instrumentModel],profile,true);
     
            if (userInstrumentModelStatus != null) {
                let oldUserInstrumentData = props.user_instrument_data;
                userInstrumentModelStatus.userInstrumentToAdd.forEach(x => {
                    oldUserInstrumentData.push(x);
                })
                props.setUserInstrumentData(oldUserInstrumentData);

           
                appContext.setAlertOptions({title:'Request Sent',desc:'Your requested user instrument will be available to be used in a sample once the review process is complete.',options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                     
                        appContext.setShowAlert(false);
                      }}
                  ]});
                   appContext.setShowAlert(true); 
                props.setVisible(false);
            }
           
        }
        catch (e) {
            console.log(e);
            appContext.setAlertOptions({title:'Could not send instrument request',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                 
                    appContext.setShowAlert(false);
                  }}
              ]});
               appContext.setShowAlert(true); 
        }
    }

    function onBrandPress(){
        setPickingBrand(true);
    }

    function onChangeModelText(text){
        setModel(text);
    }

    function onCharacteristicPress(){
        setShowCharList(true);
    }

    return (
        <>
            {!showCharList ?
                //if we are not showing the characteristic list and adding a new instrument
                props.mode == 'add' ?
                    <View style={spacing.MainContainer}>

                        <View style={spacing.ScreenHeader}>
                            <Text style={textStyles.title}>Request Instrument</Text>
                        </View>

                        {/* Instrument Information- Group */}
                        <View>
                            
                            <VGInput label="Brand" type="picker" 
                                onPress={onBrandPress} value={selectedBrand.name}
                            />
                           
                            <VGInput label="Model" 
                                value={model} onChangeText={onChangeModelText}
                            />
                           
                        </View>

                        <View style={spacing.VerticalSpacer}></View>

                       
                        <VGInput icon="plus" type="picker" value="" testLabel="Input_Edit Characteristics"
                            onPress={onCharacteristicPress}
                            label={
                                        'Edit Characteristics'
                                        +
                                        (selectedCharacterSubOptions?.length > 0 ? ' (' + selectedCharacterSubOptions?.length + ' selected)' : '')
                                    } 
                        />
                        
                        {
                            selectedCharacterSubOptions?.length > 0?
                            <View style={{ flex: 2, flexDirection: 'column', borderWidth: 3, borderColor: 'lightgrey', marginVertical: 3, alignSelf: 'stretch', marginTop: 15, paddingVertical: 3 }}>
                                    <View style={{ flexDirection: 'row', marginLeft: 5, marginBottom: 5 }}>
                                        <Text style={{ fontSize: 16 }}>Selected Characteristics</Text>
                                    </View>
                                    <FlatList
                                        data={selectedCharacterSubOptions}
                                        keyExtractor={(item, index) => index.toString()}
                                        ItemSeparatorComponent={renderSeparatorView}
                                        renderItem={({ item, index }) =>
                                        <View style={{ flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', marginVertical: 3, left: 15 }}>

                                                <Text style={{ fontSize: 16, alignSelf: 'center', flexWrap: 'wrap', flexShrink: 1 }}>
                                                    {item?.name??''}   
                                                 </Text>

                                                <TouchableOpacity

                                                style={{ alignSelf: 'center', paddingLeft: 5, paddingRight: 20 }} onPress={() => {
                                                    
                                                    let oldList = selectedCharacterSubOptions;
                                                    oldList.splice(index,1);
                                                    setSelectecCharacterSubOptions([...oldList]);
                                                }}>
                                                    <Icon name="minus-square" size={35} color="red" />
                                                </TouchableOpacity>
                                            </View>
                                        }
                                        />
                                </View>
                                : null
                            }
                       

                        <View style={{ flex: 1, justifyContent: 'flex-end', marginBottom: 20 }}>
                            <VGButton role="PRIMARY" onPress={onRequestPress} 
                            >Request</VGButton>


                            <VGButton role="CANCEL" onPress={() => {
                                props.setVisible(false);
                            }}>Cancel</VGButton>
                        </View>

                    </View>
                     :
                    // //This should never be reached
                     <Text>Something went wrong!</Text>
                    :
                    //if we are showing the characteristics list, then we only want to see this
                    <CharacteristicsSelectorModal
                        addFromAnalysis={false}
                        mode={"User Instrument"}
                        selectedSubOptions={selectedCharacterSubOptions}
                        setSelectedOptions={setSelectecCharacterSubOptions}
                        isVisible={showCharList}
                        setVisible={setShowCharList}
                        readonly={false}
                        charOptions={{types:props.characteristicTypes,options:props.characteristicSubOptions}}
                        // instrumentBrands={props.instrument_brands}
                        //instrumentModels={props.instrument_models}
                       
                    />

            }

            {pickingBrand ? <OverlayPicker type={['name']} text={'Pick a Brand'} isVisible={pickingBrand} setVisible={() => {setPickingBrand(false)}} setData={setSelectedBrand} data={props.instrument_brands.filter(x => x.name != 'Request Instrument')} />: null}

        </>
    );
}

export default AddInstrumentScreenUpdated;