enum Colours {
    VG_AMBER = '#FFA400',
    VG_BLUE = '#003594',
    VG_BLUE_INPUT = "#CCD6EA",
    VG_GREY = '#7C878E',
    VG_GREY_70 = "#A3AAAF",
    VG_GREY_30 = "#D7DBDD",
    VG_GREY_INPUT = "#F2F3F3",
    INFO = '#00BFFF',
    SUCCESS = '#008000',
    ERROR = '#8B0000',
    ERROR_INPUT = "#E8CCCC",
    WARNING = '#FFD700',
    BLACK = '#000000',
    WHITE = '#FFFFFF'
}

export default Colours;