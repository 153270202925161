import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TouchableOpacity, Image, } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { FlatList } from 'react-native-gesture-handler';
import { Alert } from 'react-native';
import { RestoreDeletedcompanies } from '../../../Helpers/Functions/Offline/BuyerFunctions';
import { Buyer, UserEquipment } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import VGButton from '../../../Components/Button/Button';
import AppContext from '../../../Contexts/AppContext';
import { RestoreDeletedEquipment } from '../../../Helpers/Functions/Offline/EquipmentFunctions';

function RestoreEquipmentScreen(props: { deletedEquipment: UserEquipment[],OnSave:(restoredEquipment:UserEquipment[])=>void,OnCancel:()=>void}) {

    const appContext = React.useContext(AppContext);

    const [checkedEquipment,setCheckedEquipment] = React.useState<UserEquipment[]>([]);

    function OnRestorePress()
    {
        try
        {
            const equip = RestoreDeletedEquipment(checkedEquipment);

            if(equip != null)
            {
                props.OnSave(equip);
            }
            else
            {
                Toast.show('Could not restore Equipment');
            }
        }
        catch(error)
        {
            Toast.show('Could not restore Equipment' + error);

        }
    }

    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', marginHorizontal: 10 }}>
            <FlatList
                data={props.deletedEquipment}
                renderItem={({ item, index }) =>

                <View >

                <View style={{ flexDirection: 'row', borderWidth: 1, borderColor: 'lightgrey', marginVertical: 3, alignSelf: 'stretch' }}>
                    <TouchableOpacity
                        style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignSelf: 'center', padding: 5 }}
                        onPress={() => {
                                if (checkedEquipment?.find(x => x.id == item.id) != undefined) {
                                        let oldField = checkedEquipment;
                                        const index = oldField.findIndex(x => x.id == item.id);
                                        oldField.splice(index, 1);
                                        setCheckedEquipment([...oldField])
                                        console.log(oldField);
                                    }
                                    else {
                                    
                                        setCheckedEquipment([...checkedEquipment,item]);
                                    }

                        }} >

{checkedEquipment?.find(x => x.id == item.id) != undefined ?
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/OrangeField.png')} />

                                    :
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/BlueField.png')} />


                                }

                        <Text style={{ fontSize: 16, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5,color:checkedEquipment.find(x => x.id == item.id) ? 'orange' : 'black' }}>{item.name}</Text>
                    </TouchableOpacity>
                </View>


            </View>
                }
                keyExtractor={(item, index) => index.toString()}
            >
            </FlatList>

            <VGButton role="PRIMARY" onPress={OnRestorePress} >Restore</VGButton>


            <VGButton role="CANCEL"
                onPress={props.OnCancel}
            >Cancel</VGButton>


        </View>
    );
}

export default RestoreEquipmentScreen;