import React from "react";
import { Dimensions, View,Text,Image, TouchableOpacity, Vibration, Alert, GestureResponderEvent, PermissionsAndroid, Linking} from "react-native";
import { Overlay } from "react-native-elements";
import VGButton from "../../../Components/Button/Button";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { ConvertBase64ToValue, ConvertIntToByte, TakeSample, WriteCharacteristic } from "../../../Helpers/Functions/Offline/ProgressSampleFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { ManualProgressTimes, ManualSamplingTypes, MasterSample } from "../../../Helpers/Storage/UserState";
import spacing from "../../../Styles/Spacing";
import styles from "../../../Styles/styles";
import textStyles from "../../../Styles/Text";
import { Sound } from 'expo-av/build/Audio';
import { Audio } from 'expo-av';
import { AddToQueue } from "../../../Helpers/Storage/OfflineQueue";
import { BleManager, Device } from "react-native-ble-plx";
import { AccuSamplerIdentifiers, UltraSamplerIdentifiers } from "./SMARTSCOOP_IDENTIFIERS";
import { GenerateNumberIDSync } from "../../../Helpers/Functions/GeneralFunctions";
import AppContext from "../../../Contexts/AppContext";
import * as Location from 'expo-location';
function UltraSamplerScreen(props:{
    manualProgressTimes:ManualProgressTimes[],
    sample:MasterSample,
    isVisible:boolean,
    isHidden:boolean,
    manualSamplingType:ManualSamplingTypes,
    ToggleHide:() => void,
    OnFinish:() => void,
    OnSave:(data:string)=>void
})
{

    const appContext = React.useContext(AppContext);
    const [alertSound, setAlertSound] = React.useState<Sound>();
    const [successSound,setSuccessSound] = React.useState<Sound>();
    const [connecting,setConnecting] = React.useState<boolean>(false);
    const [manualProgressTimes,setManualProgressTimes] = React.useState<ManualProgressTimes[]>(props.manualProgressTimes);
    const [sample,setSample] = React.useState<MasterSample>(props.sample);
    const [selectedSamplingMethod,setSelectedSamplingMethod] = React.useState<ManualSamplingTypes>(props.manualSamplingType);
    const [ticking,setTicking] = React.useState<boolean>(false);
    const [samplesRemaining,setSamplesRemaining] = React.useState(-1);
    const [average,setAverage] = React.useState(0);
    const [quality,setQuality] = React.useState(0);

    const [dataSent,setDataSent] = React.useState<string>('');
    const [dataSentValue,setDataSentValue] = React.useState<number>();
    const [dataReceived,setDataReceived] = React.useState<string>('');
    const [dataReceivedValue,setDataReceivedValue] = React.useState<number>();

    const [device,setDevice] = React.useState<Device>(null);
    const [bluetoothManager,setBluetoothManager] = React.useState<BleManager>(null);

    async function PlayAlertSound()
    {
        const {sound} = await Audio.Sound.createAsync(require('../../../assets/gentle-alarm-474.mp3'));
        setAlertSound(sound);
        await sound.playAsync();
    }

    async function PlaySuccessSound()
    {
        const {sound} = await Audio.Sound.createAsync(require('../../../assets/arpeggio-467.mp3'));
        setSuccessSound(sound);
        await sound.playAsync();
    }

    React.useEffect(() => {
        return alertSound
        ? () => {
            alertSound.unloadAsync();
          }
        : undefined;
    },[alertSound])

    React.useEffect(() => {
        return successSound
        ? () => {
            successSound.unloadAsync();
          }
        : undefined;
    },[successSound])

    /**
     * This function will toggle the timer start/stop
     */
    function OnTimerPress()
    {
       // ticking ? setTicking(false) : setTicking(true);
    }


    /**
     * CURRENTLY IN TESTING!!!
     * This function will generate a random number between 10-30 and send it to the Target Time hex code to the ultrasampler
     * first 4 bits convey the variable to assign to and the last 4 bits convey the value
     * Final Example 10040030
     */
    async function WriteSampleDataToDevice() {
        
        let num = Math.floor(Math.random() * (30 - 10 + 1) + 10)
        const targetTime = ConvertIntToByte(num);
        setDataSentValue(num);
        setDataSent(UltraSamplerIdentifiers.BT_TARGET_TIME_ID.toString() + targetTime.toString());
        //Write to device
        console.log('Final UUID: ' +parseInt(UltraSamplerIdentifiers.BT_TARGET_TIME_ID.toString() + targetTime.toString()) );

          WriteCharacteristic(device,UltraSamplerIdentifiers.BT_ULTRASAMPLER_ID,UltraSamplerIdentifiers.BT_WRITE_ID,parseInt(UltraSamplerIdentifiers.BT_TARGET_TIME_ID.toString() + targetTime.toString()),true)
    }

    async function MonitorSampleData()
    {
      
        const userID = await GetData('ID');
        bluetoothManager.monitorCharacteristicForDevice(device.id,UltraSamplerIdentifiers.BT_ULTRASAMPLER_ID,UltraSamplerIdentifiers.BT_READ_ID,(error,char) => {
            if(char != undefined)
            {
                let value = ConvertBase64ToValue(char.value);
                setDataReceivedValue(value);
                setDataReceived(char.value);
           
            }
        });

    }

    function ConnectToSmartSampler()
    {

        const grabPermission = async() => {
            const stat =  await HasValidBTPermissions();
            if(!stat)
            {
              appContext.setAlertOptions({title:'Permissions required',desc:'VeriGrain collects location data to enable Bluetooth features and to record current location when a sample is taken.\n Open your app setting and enable. \n - Nearby Devices \n -Location',
              options:[
                  {text:'OK',role:'PRIMARY',onPress:() => {appContext.setShowAlert(false);Linking.openSettings(); }},
                  {text:'Cancel',role:'SECONDARY',onPress:()=>{appContext.setShowAlert(false);}}
              ]})
              appContext.setShowAlert(true);
            }
            else
            {
                console.log('permissions already exist');
            }
          }
         grabPermission();    

        console.log('Scanning');
        setConnecting(true);
        bluetoothManager.startDeviceScan(
            null,
            null,
             (error, originalDevice:Device) => {
              if (error) {
                setConnecting(false);
                appContext.setAlertOptions({title:'Permissions required',desc:'VeriGrain collects location data to enable Bluetooth features and to record current location when a sample is taken.\n Open your app setting and enable. \n - Nearby Devices \n -Location',
                options:[
                    {text:'OK',role:'PRIMARY',onPress:() => {appContext.setShowAlert(false);Linking.openSettings(); }},
                    {text:'Cancel',role:'SECONDARY',onPress:()=>{appContext.setShowAlert(false);}}
                ]})
                appContext.setShowAlert(true);
                console.log('error', JSON.stringify(error));
              } else if (originalDevice) {         
            
                //If found stop the scan and set the device
                if ( originalDevice.name == 'Adafruit Bluefruit LE' ) {
                  bluetoothManager.stopDeviceScan();
                  setConnecting(false);
                  originalDevice.connect().then((originalDevice:Device) => {
                 
                    originalDevice.discoverAllServicesAndCharacteristics().then((originalDevice: Device) => {
                      setDevice(originalDevice)
                    })
                  })
                
                }
              }
            }
          );
    }

    function OnDonePressed()
    {

        props.OnFinish();

    }

    //This function will get the samples remaining
    function GetSamplesRemaining()
    {
        //Is the samples required field not null?
        if(sample?.samples_required != null)
        {
            //take the length of the progress time array and subtracct from samples requires to get samples remaining
            let num = sample?.samples_required - manualProgressTimes.length;
            setSamplesRemaining(num);
        }

    }

    async function FinishSample(finish?:boolean)
    {
        
        const finishedSample:MasterSample = {...sample,completed:finish?1:0};
        const queueStatus = await AddToQueue('master_samples',finishedSample,'update');
        console.log('finish status: ' + queueStatus);
        if(queueStatus)
        {
            props.OnFinish;
        }
    }


    //This function will get the average time for a sample
    function GetAverageTime()
    {
        //grab the average by totalling the time array and dive by length
        let total = 0;
        manualProgressTimes.forEach(time => {
            total += time.time;
        });

        const avg = total / manualProgressTimes.length;

        //the min and max ranges to determine the quality
        //If the target time was 10, the maximum average to be considered a 'perfect' would be 15 and the minimum would be 5
        const PerfectQuality = sample.target_time * .5;
        const OkayQuality = sample.target_time * .7;
   
 
        if(!isNaN(avg))
        {
            if(avg <= sample.target_time + PerfectQuality && avg >= sample.target_time - PerfectQuality )
            {
                setQuality(3);
            }
            else if (avg <= sample.target_time + OkayQuality && avg >=  sample.target_time - OkayQuality )
            {
                setQuality(2);
            }
            else
            {
                setQuality(1);
            }
    
            setAverage(avg);
        }
    }

    /**
     * This use effect will keep track of a ticking boolean to determine whether to start the timer or not
     */
    React.useEffect(() => {
        //If the user started the timer
        if(ticking)
        {
             //WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_IS_PAUSED_ID,0);

        }
        else
        {
          //   WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_IS_PAUSED_ID,1);

        }
    },[ticking])


    /**
     * This useeffect gets triggered each time a new manualProgress time is created OR when the samples required gets changes (useful on startup)
     */
    React.useEffect(() => {

        //Get samples remaining and average time
        GetSamplesRemaining();
        GetAverageTime();
    },[manualProgressTimes,sample?.samples_required]);


    /**
     * This use effect will trigger each time the sample remaining variable gets changed
     */
    React.useEffect(() => {
        //Ensure we dont check if its been initialized
        if(samplesRemaining != -1 && samplesRemaining != null)
        {
            //If there are no more samples to take
            if(samplesRemaining == 0)
            {
                //stop the clock
                setTicking(false);
          
                appContext.setAlertOptions({title:'Sample Completed',desc:'Your sample will no longer be in-progress',options:[
                    {text:'OK', role:'PRIMARY', onPress:async()=>{
                        appContext.setShowAlert(false);
                       await  FinishSample(true);
                    }}
                ]})
                appContext.setShowAlert(true);
            }
        }
    },[samplesRemaining])

    //TODO set permissions here


    /**
     * This useeffect will trigger each time the current_time is changed
     */
    React.useEffect(() => {

        //if the time is less than 10 and 3 seconds have elapsed than vibrate the phone and play an alert sound
        if(sample.time_current  % 3 == 0 && sample.time_current <= 10)
        {
          Vibration.vibrate([500,500], false);
          PlayAlertSound();
        }

    },[sample?.time_current])


 
    React.useEffect(()=>{
        if(bluetoothManager != null)
        {
            if(device != null)
            {
              //  ConnectToSmartSampler();
            }
        }
    },[bluetoothManager])

    React.useEffect(() => {
        const grabPermission = async() => {
          const stat =  await HasValidBTPermissions();
          if(!stat)
          {
            appContext.setAlertOptions({title:'Permissions required',desc:'VeriGrain collects location data to enable Bluetooth features and to record current location when a sample is taken.\n Open your app setting and enable. \n - Nearby Devices \n -Location',
            options:[
                {text:'OK',role:'PRIMARY',onPress:() => {appContext.setShowAlert(false);Linking.openSettings(); }},
                {text:'Cancel',role:'CANCEL',onPress:()=>{appContext.setShowAlert(false);}}
            ]})
            appContext.setShowAlert(true);
          }
        }
       grabPermission();
    },[])


    React.useEffect(() => {
        if(device != null && device?.name == 'Adafruit Bluefruit LE')
        {
            console.log('Connecting to adafruit bluefruit service');
            const ConnectData = async() => {
                await WriteSampleDataToDevice();
                await MonitorSampleData();
       
            }
            ConnectData();
         

        }
    },[device])

    React.useEffect(() => {
        setBluetoothManager(new BleManager());
    },[])
    
    return(

        <>
        {!props.isHidden ?
        <Overlay fullScreen isVisible={!props.isHidden}>
 
                <View style={spacing.TitleBar}>
                    <Text style={styles.title}>UltraSampler MT</Text>
                </View>

                <VGButton role={"SECONDARY" } disabled={device == null ? false:true} onPress={() => {ConnectToSmartSampler()}}>{device == null ? connecting ? 'Connecting...' : 'Connect' : 'Connected' }</VGButton>

                <VGButton role={"PRIMARY"} disabled={device == null ? true:false} onPress={WriteSampleDataToDevice}>Send Data</VGButton>


                <VGButton role="CANCEL" onPress={OnDonePressed}>Finish</VGButton>


                <View style={styles.samplingListContainer}>

                    <View style={styles.samplingListInnerContainer}>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Data Sent (HEX)</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{dataSent??'N/A'}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Data Sent (Value)</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{dataSentValue??'N/A'}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Data Received (HEX)</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{dataReceived??'N/A'}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Data Received (Value)</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{dataReceivedValue??'N/A'}</Text>
                            </View>
                        </View>

                    </View>
                </View>
             



            {/* <VGButton role="CANCEL"
                 onPress={props.ToggleHide}>Main Menu</VGButton> */}

        </Overlay>
                :
                <TouchableOpacity style={{flex:1,
                    width: Dimensions.get('window').width - 10,
                    alignSelf:'center',
                    height: 50,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position:'absolute',
                    bottom:10}}  onPress={props.ToggleHide} >
         
             <Text style={{color:'white',textAlign:'center'}}>Return To AccuSampler</Text>
           </TouchableOpacity>
                }
        </>
    );
}
export default UltraSamplerScreen;


export async function HasValidBTPermissions():Promise<boolean>{

    let stat = true;

        const wifiPerm = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.NEARBY_WIFI_DEVICES);
        const locPerm = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);

        
        //if(locPerm && wifiPerm) //NEED TO FIX WIFI PERMISSIONS
        if(locPerm)
        {
            console.log('success! ' + wifiPerm);
            return true;
        }
         await PermissionsAndroid.requestMultiple([PermissionsAndroid.PERMISSIONS.NEARBY_WIFI_DEVICES,PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION]).then((results) => {
            console.log('WIFI PERM AND LOC PERM HERE');
            console.log(results);
            if(results['android.permission.NEARBY_WIFI_DEVICES'] === 'granted' && results['android.permission.ACCESS_FINE_LOCATION'] === 'granted' )
            {
                
               console.log('success!');
               stat = true;
            }
            else if(results['android.permission.NEARBY_WIFI_DEVICES'] === 'never_ask_again' && results['android.permission.ACCESS_FINE_LOCATION'] === 'never_ask_again')
            {
               stat = false;
            
            }
            else
            {
                stat = false;
            }
         })


    return stat;
}
 



