
import * as React from "react";

import { Bin, BinLocation, BinVolumeHistory, CharacteristicSubOption, CharacteristicType, GrainSubCrop, GrainType, MasterSample, SampleDestination, SampleSource, StorageType } from "../../../Helpers/Storage/UserState";
import Toast from "react-native-tiny-toast";
import { GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import VGButton from "../../../Components/Button/Button";
import BinDetailsComponent from "./BinDetailsComponent";


function BinDetailsScreen({ route, navigation }: { route: {params: {
    bin:Bin,
    binLocation:BinLocation,
    storageType:StorageType,
    grainData?:{
      grainType: GrainType;
      grainSubCrop: GrainSubCrop;
  },
    currentVolume:number,
    status?:string,
    isPopUp?:boolean;
            }
            },
    navigation: any }) {


      async function OnPrimaryButtonPress()
      {
        try{
          navigation.navigate('YardOverview', {location:route.params.binLocation, selectedBin:route.params.bin??null});
        }
        catch{
          Toast.show("Sorry, Location not on Map")
        }
      
      }

  return (

    <BinDetailsComponent OnPrimaryButtonPress={OnPrimaryButtonPress} bin={route.params.bin} binLocation={route.params.binLocation} currentVolume={route.params.currentVolume} storageType={route.params.storageType} grainData={route.params.grainData} isPopUp={false} status={route.params.status} />
  );
}
export default BinDetailsScreen;
