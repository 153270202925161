import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity, SectionList, DeviceEventEmitter, Dimensions, Image, Platform, ImageSourcePropType, Alert} from 'react-native';
import Constants from 'expo-constants'
import {geoCode, getBinColor, getMapBinIcon} from '../../../Helpers/Functions/MapFunctions';
import * as geolib from 'geolib';
import { CheckBox } from 'react-native-elements';
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
// import MapboxGL from '@rnmapbox/maps';
 import * as turfCircle from '@turf/circle'
import { Bin } from '../../../Helpers/Storage/UserState';
import VGInput from '../../../Components/Input/Input';
import VGButton from '../../../Components/Button/Button';
import AppContext from '../../../Contexts/AppContext';
//MapboxGL.setAccessToken('sk.eyJ1IjoiYnJhZHl3YXJmb3JkIiwiYSI6ImNrd2tjeGUzNzE1c3oyb2w1c2NkOGZ2d2YifQ.zbUW4UH2FPV8i4E9fJrh8Q');
interface Props  {
    /**Whether we are using this picker to pick a "location" or a "bin" */
    type: functionType;
    /**Center of the map to display, in format [longitude, latitude] 
     * (eg. [-107.235386, 51.968342]) 
     */
    center: number[];
    /**The onSave method that will run when the user selects the Save button 
     * @param coord: the number array with the selected coordinate, in the format [longitude, latitude] eg: [-107.235386, 51.968342]
    */
   onSave: (coord:number[],zoom:number)=>void;
   /**The onCancel method that will run when the user selects the Cancel button */
   onCancel: ()=>void;
   /**In 'bin' mode, a list of the bins to display on the map */
   binSpecs?:Bin[];
    zoom?:number;
    selectedBinSpec?:Bin;
}

type functionType = "location"|"bin";


function YardMapPicker(props:Props)
{
 
    const appContext = React.useContext(AppContext);

    const postalRef = React.useRef(null);
    const [mapFrozen,setMapFrozen] = React.useState(false);
    const [scrollable, setScrollable] = React.useState(true);
    const [zoomable, setZoomable] = React.useState(true);
    const [shouldMapRespond, setShouldMapRespond] = React.useState<boolean>(true);
    const [snappingChecked, setSnappingChecked] = React.useState<boolean>(false);

    const [center,setCenter] = React.useState<number[]>([...props.center]);
    const [marker, setMarker] = React.useState<JSX.Element>(null);
    const [mapBins, setMapBins] = React.useState([]);
    const [binMarker, setBinMarker] = React.useState(null);
    const [selectedCoord, setSelectedCoord] = React.useState<number[]>();
const [postalCode,setPostalCode] = React.useState('');
    const _map = React.useRef(null);
    const _cam = React.useRef(null);

    let zoom = props.zoom??15
    const minZoom = props.type ==='location' ? 1 : 16;
    const maxZoom = props.type ==='location' ? 15 : 19;


    const gridFactor = 5.0; //in meters
    const DISTANCE_TO_SNAP = 3;

    //Effect to use once the component loads
    React.useEffect(()=>{

        try
        {
        //if the picker is for bins, load the list of bins and add them to the map
        if(props.type === 'bin' && props?.binSpecs != null){


            let mb = props?.binSpecs.map(bs => {

                //put all of the bins that have locations (except the current placing bin, if modifying) onto the map
                if(bs.latitude != undefined && bs.longitude != null && bs.id !== props.selectedBinSpec.id){
                   
                //     return         <MapboxGL.ShapeSource 
                //     id={"symbolLocationSource" + bs.id.toString()??'-2'}
                //     hitbox={{width: 20, height: 20}}
                //     shape={
                //       turfCircle.default([bs.longitude,bs.latitude],bs.diameter/2,{units:'inches',steps:200})
                //   }
                //     >
                //       <MapboxGL.FillLayer id={"Fill" + bs.id.toString()??'-2'} style={{fillColor:getBinColor(null,null)}} />
                //     </MapboxGL.ShapeSource>
                return <></>
                }
            });

            setMapBins(mb);
            

            //also, set the binMarker
            setBinMarker(getMapBinIcon(null, null));
        }
        }
        catch(error)
        {
            console.log('Error: ' + error);
        }


    },[])

    //Check Postal code
    //TODO: Check between Canada and US
    React.useEffect(() => {
        var regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if(postalCode.match(regex))
        {
            goToPostal();
            const getcoord = async() => {
                const coord =  await geoCode(postalCode);
                setCenter(coord);
    
            };
            getcoord();
     
        }
    },[postalCode])

    React.useEffect(()=>{
        if(mapFrozen){
            setScrollable(false);
        }
        else{
            setScrollable(true);
        }
    }, [mapFrozen])

    async function goToPostal(){
        const coord =  await geoCode(postalCode);
        setCenter(coord);
    };

    // function getIconBounds(item:Bin, newCoord?:number[]) : [GeoJSON.Position, GeoJSON.Position, GeoJSON.Position, GeoJSON.Position] {
    //      //get the radius to calculate the bounds
    //      //radius is in feet, so we need to calculate meters for the functions
    //      try
    //      {
    //         const radius = item.diameter / 39.37 //TODO worry about meters??
    //         const c0 = newCoord? newCoord[0] : item.longitude, c1 = newCoord? newCoord[1] : item.latitude;
    //         const top = geolib.computeDestinationPoint([c0, c1], radius, 0);
    //         const right = geolib.computeDestinationPoint([c0,c1], radius, 90);
    //         const bottom = geolib.computeDestinationPoint([c0,c1], radius, 180);
    //         const left = geolib.computeDestinationPoint([c0,c1], radius, 270);
            
    //         const bounds = geolib.getBounds([top, right, bottom, left]);
            
    //         //use the bounds to create the four corners of the image
    //         return [
    //            [bounds.minLng, bounds.maxLat],
    //            [bounds.maxLng, bounds.maxLat],
    //            [bounds.maxLng, bounds.minLat],
    //            [bounds.minLng, bounds.minLat]
    //         ]
    //      }
    //      catch(error)
    //      {
    //          console.log('Error: ' + error);
    //          return [[0,0],[0,0],[0,0],[0,0]];

    //      }


    // }
    React.useEffect(() => {

        if(props.type == 'location')
        {

            appContext.setAlertOptions({title:'Use current location',desc:'Would you like to use your current location for bin location mapping?',options:[
                {text:'Use My Location', role:'PRIMARY', onPress:async() => {
                 
                    appContext.setShowAlert(false);
                    const permissionResponse = await Location.getForegroundPermissionsAsync();
        

                    if(!permissionResponse.granted)
                    {

                        appContext.setAlertOptions({title:'Location Permissions Required',desc:'VeriGrain collects location data to enable Bluetooth features and to record current location when a sample is taken',options:[
                            {text:'Allow', role:'PRIMARY', onPress:async()=>{
                                appContext.setShowAlert(false);
                                let {status} = await Location.requestForegroundPermissionsAsync();
                                if (status == Permissions.PermissionStatus.DENIED) {
                                    return;
                                  }
                        
                                  let location = await Location.getCurrentPositionAsync({});
                               
                                  setCenter([location.coords.longitude,location.coords.latitude]);
                            }},
                            {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
                        ]});
                        appContext.setShowAlert(true);
                      
                    }
                    else
                    {
                       
                        let {status} = await Location.requestForegroundPermissionsAsync();
                        if (status == Permissions.PermissionStatus.DENIED) {
                            console.log('access denied');
                            return;
                          }
                            let location = await Location.getCurrentPositionAsync();
                          setCenter([location.coords.longitude,location.coords.latitude]); 
                    
                }
                  }},
                  {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true); 
            
        }
    
    },[])

    function findClosestGridCoord(coord:[number, number]){

        //first, we get the distance to the nearest Lat and Long lines (basically, an approximation of [lng,0] and [0,lat]), 
        let coordGridOrigin = [Math.trunc(coord[0]), Math.trunc(coord[1])];
        //we get the distance to each of those lines
         let lngDistance = geolib.getDistance(coord, [coordGridOrigin[0], coord[1]], 0.1);
         let latDistance = geolib.getDistance(coord, [coord[0], coordGridOrigin[1]], 0.1);

        //now we'll calculate each individually, to get our new long and lat:

        //first, get a modulo of the distance compared to the grid factor,
        //this will give us the distance from the last "grid line" 
        let lngMod = lngDistance % gridFactor;
        lngMod = parseFloat(lngMod.toFixed(4));
        //TODO see if this is neccessary
        //if it is bigger than 1/2 of the gridfacter, then we move "left" (negative), otherwise, we move "right"(positive)
        //let xDir = lngMod > gridFactor / 2.0 ? -1 : 1;
        
        //do the same for lat
        let latMod = latDistance % gridFactor;
        latMod = parseFloat(latMod.toFixed(4));
        //if it is larger, we move "up" which will be based off of 0 deg, while down is 180 deg. This will come into play later.
        //let yDir = latMod > gridFactor / 2.0 ? 0 : 180;

        //now, we get two new points, one East and one South, at the above calculated distances from the grid lines
        let newLng = geolib.computeDestinationPoint(coord, lngMod, 90);
        let newLat = geolib.computeDestinationPoint(coord, latMod, 180);

        //we have to add or subtract this angle from either 180 or 0deg, to get the bearing:
        //angle = yDir + (xDir * angle);
        //angle = angle < 0 ? 360 + angle : angle //make sure that the degrees are positive

        //a new coordinate is taken with the new latitude and logitude coordinates
       
        //let newCoord = geolib.computeDestinationPoint(coord, dist, angle);
        let newCoord = [newLng.longitude, newLat.latitude];

        //return [newCoord.longitude, newCoord.latitude];
        return newCoord;
    }

    function snapToBins(coord:[number,number]){
        let latDetected = false, longDetected = false;
        props?.binSpecs.forEach(element => {
            if(!latDetected && element.latitude != null){
                let dist = geolib.getDistance(coord, [coord[0], element.latitude]);
                if(dist <= DISTANCE_TO_SNAP){
                    coord[1] = element.latitude;
                    latDetected = true;
                }
            }

            if(!longDetected && element.longitude != null){
                let dist = geolib.getDistance(coord, [element.longitude, coord[1]]);
                if(dist <= DISTANCE_TO_SNAP){
                    coord[0] = element.longitude;
                    longDetected = true;
                }
            }
        });


        return coord;
    }

        async function onPress(e){

            if(props.type != 'location'){

                try
                {
                    setMapFrozen(true);
                    
                    let gridCoord = snappingChecked ? e.geometry.coordinates : e.geometry.coordinates;
                    setSelectedCoord(gridCoord);
                    
                    renderMarker(gridCoord);
                }
                catch(error)
                {
                    console.log("Error On Press: " + error)
                }
            }
                

        }

        async function onRespMove(e){
        
            if(mapFrozen){
                //get the coord for the location on screen that is touched
                let coord =  await _map.current.getCoordinateFromView([e.nativeEvent.locationX, e.nativeEvent.locationY]).then(res=>res);
                
                coord =  snappingChecked ? snapToBins(coord) : coord;
                
                setSelectedCoord(coord);
                renderMarker(coord);
            }
        }

        function renderMarker(gridCoord){
            let mk;
            if(props.type == 'location'){
                console.log('Trying to render marker');

                //no longer using marker, now we jut use center of map for location
               //  mk = <MapboxGL.MarkerView id={"locID"} coordinate={gridCoord} anchor={{x:.95, y:.5}} children={<Image style={{width:30, height:30,borderRadius: 5, overflow: 'hidden'}} source={require('../../../Icons/BlueLocation.png')}/>}/>
            }
            else{
                try
                {
              const radius = props?.selectedBinSpec?.diameter /2 
                // mk = <MapboxGL.ShapeSource 
                // id={"symbolLocationSurce"+ props?.selectedBinSpec?.id.toString()??'-2'}
                // hitbox={{width: 20, height: 20}}
                // //onPress={this.onSourceLayerPress}
                // shape={
                //     turfCircle.default([gridCoord[0],gridCoord[1]],radius,{units:'inches'})
                // }
                // >
                //    <MapboxGL.FillLayer id={"Fill" + props?.selectedBinSpec?.id.toString()??'-2'} style={{fillColor:getBinColor(null,null)}} />
                // </MapboxGL.ShapeSource>

             
                }
                catch(error)
                {
                    console.log('Render Marker Error: ' + error)
                  // mk = <MapboxGL.MarkerView id={"locID"} coordinate={gridCoord} anchor={{x:.95, y:.5}} children={<Image style={{width:30, height:30,borderRadius: 5, overflow: 'hidden'}} source={require('../../../Icons/BlueBin.png')}/>}/>

                }
        

            }
            setMarker(mk);
        }

        async function onRespRelease(e){

        }

        async function onSavePress(){
            zoom = await _map.current.getZoom();
            let coord = props.type == 'location' ? await _map.current.getCenter() : selectedCoord
            
            //run the save command passed in from the parent, giving the coordinate chosen
            props.onSave(coord, zoom);
        }

        async function onCancelPress(){
            //run the command pased in from the parent
            props.onCancel();
        }

        //this stylesheet is for the map's background containers
        const styles = StyleSheet.create({
            page: {
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center'
            },
            container: {
                flex:1,
                height:'80%',
                width:'100%'
            },
            map: {
            flex: 1,
            zIndex: 100
            },
            
            
        });
        //this "stylesheet" is in tileJSON format, and has the tiles info
        const mapStyle = {
            'version': 8,
            'sources': {
            'raster-tiles': {
                'type': 'raster',
                'tiles': [
                'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=sk.eyJ1IjoiYnJhZHl3YXJmb3JkIiwiYSI6ImNrd2tjeGUzNzE1c3oyb2w1c2NkOGZ2d2YifQ.zbUW4UH2FPV8i4E9fJrh8Q'
                ],
                'tileSize': 256,
            }
            },
            'layers': [{
            'id': 'simple-tiles',
            'type': 'raster',
            'source': 'raster-tiles',
            }]
        }

        function onChangePostalText(text){setPostalCode(text); }

        return (
            <View style={styles.page} > 


                    
                    {props.type == 'location' ?
                    <View>
                        <View style={{backgroundColor:"#F1F1F1"}}>
                            <Text style={{ marginLeft: 10, fontSize: 15}} >Search Postal Code or Nearest City:</Text>
                            <VGInput label="Location" onChangeText={onChangePostalText}
                                value={postalCode}
                            />
                            
                        </View>
                        <Text style={{fontSize:20, alignSelf:'center'}}>Fill Map With Location and Save</Text>
                    </View>
                    : null}
                   
            <View style={styles.container} > 
                { props.type == 'bin' && props.binSpecs?.length > 0 ?
                    <View style={{position:'absolute', top:10, left:10, zIndex:500, backgroundColor:'lightgrey', opacity:0.5, flexDirection:'row', borderRadius:7}} >
                    <Text style={{alignSelf:'center', marginLeft:5}}>Snapping</Text>
                    <CheckBox style={{alignSelf:'center'}} checked={snappingChecked} onPress={()=>{setSnappingChecked(!snappingChecked)}}></CheckBox>
                </View> : null}

                        {mapFrozen ? <View>
                            <TouchableOpacity
                            onPress={()=>{setMapFrozen(false)}}>
                                <Text style={{color:"white", backgroundColor:'red'}}>Map locked for dragging selection. Press here to unlock</Text></TouchableOpacity>
                        </View> : null}
{/* 
                <MapboxGL.MapView 
                scrollEnabled={scrollable}
                zoomEnabled={zoomable}
                onMoveShouldSetResponder={()=>shouldMapRespond} 
                onResponderMove={(e)=>{onRespMove(e)}} 
                onResponderRelease={(e)=>{onRespRelease(e)}}
                pitchEnabled={false} rotateEnabled={false}
                // onMoveShouldSetResponder={()=>shouldMapRespond} 
                //onResponderMove={(e)=>{onRespMove(e)}}
                // onResponderRelease={(e)=>{onRespRelease(e)}}
                style={styles.map} 
                styleJSON={JSON.stringify(mapStyle)} 
                ref={_map} 
                onPress={onPress}
                >
                    <MapboxGL.Camera ref={_cam} zoomLevel={zoom} 
                    // minZoomLevel={minZoom} maxZoomLevel={maxZoom} 
                    centerCoordinate={center}/>
                    {mapBins}
                    {marker}
                </MapboxGL.MapView> */}
            </View>

            {/* <View style={{flexDirection:'row',marginVertical:10}}> */}
                <VGButton role="PRIMARY" onPress={onSavePress}
                >Save</VGButton>

                <VGButton role="CANCEL" onPress={onCancelPress}
                >Cancel</VGButton>

            {/* </View> */}

        </View>
        );
}

export default YardMapPicker