import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, TouchableOpacity, Dimensions, Image, Platform} from 'react-native';
import Constants from 'expo-constants'
import * as geolib from 'geolib';
import { GetData, GetOfflineStateParsed } from '../../../Helpers/AsyncStorageHelper';
import { GeolibInputCoordinates } from 'geolib/es/types';
import { getCoord, getMapBinIcon } from '../../../Helpers/Functions/MapFunctions';
import { Bin, BinLocation, BinSampleHistory, GrainSubCrop, GrainType, MasterSample, StorageType } from '../../../Helpers/Storage/UserState';
import Toast from 'react-native-tiny-toast';
// import MapboxGL from '@rnmapbox/maps';
import * as turfCircle from '@turf/circle'
import { GetBinHistory, GetBinsByLocation, GetBinVolumesForLocation, GetCurrentBinVolume, GetStorageTypes } from '../../../Helpers/Functions/Offline/BinFunctions';
import { CapacityType, GetBinCapacities, GetStorageData, GetTotalBinVolume, GetVolumeHistoryArray, SaveVolumeHistoryRecord } from '../../../Helpers/Functions/Offline/StorageFunctions';
import { defaultBinLocation, defaultGrain, defaultGrainSubCrop } from '../../../Helpers/Storage/DefaultStates';
import { ValidID } from '../../../Helpers/Functions/GeneralFunctions';
import { StackScreenProps } from '@react-navigation/stack';
import _ from 'lodash';
import VGButton from '../../../Components/Button/Button';
import BinVolumeAdjuster from '../../HelperScreens/BinVolumeAdjuster';
import spacing from '../../../Styles/Spacing';
import { logoTitle } from '../../../App';
import { Overlay } from 'react-native-elements';
import BinDetailsComponent from '../BinContentsScreen/BinDetailsComponent';
import AppContext from '../../../Contexts/AppContext';
import pageStyles from '../../../Styles/styles';
import Spinner from 'react-native-loading-spinner-overlay';
import styles from '../../../Styles/styles';

// MapboxGL.setAccessToken('sk.eyJ1IjoiYnJhZHl3YXJmb3JkIiwiYSI6ImNrd2tjeGUzNzE1c3oyb2w1c2NkOGZ2d2YifQ.zbUW4UH2FPV8i4E9fJrh8Q');
/**
 * This screen is the "Yard Planner" in the app, and basicaly uses a map to show the user the position of their bins and allow for
 * interaction of these mapped bins for various purposes
 *
 */

function YardOverviewScreen({
  route,
  navigation,
}: {
  route: {
    params: {
      location: BinLocation;
      selectedBin?: Bin;
      binGrain?: string;
      imageName: string;
    };
  };
  navigation: any;
}) {
  //this makes sure the app doesn't crash in expo go. The map will not show up at all, though
  if (Constants.appOwnership !== "expo") {
    // this imports the mapboxgl. It NEEDS to be inside of the if statement

    //const Logger = require("@rnmapbox/maps").Logger;

    //this supresses many annoying logs from mapbox
    // Logger.setLogCallback((log) => {
    //   const { message } = log;

    //   // expected warnings - see https://github.com/mapbox/mapbox-gl-native/issues/15341#issuecomment-522889062
    //   if (
    //     message.match("Request failed due to a permanent error: Canceled") ||
    //     message.match("Request failed due to a permanent error: Socket Closed")
    //   ) {
    //     return true;
    //   }
    //   return false;
    // });


    /** the "bin" object for in memory to keep track of the image size and location. It is basically a collection of various parts, so that we don't have to constantly do list.find() methods
     * @param binSpec:BinSpec - the bin specifications of the bin. Includes wall type, diameter, and directional facing, among other things
     * @param bin:Bin - the bin that is represented on the map. This holds the actual bin data
     * @param grain:String - the name of the grain in the bin
     * @param status:String - the status of the bin. calculated when map loaded, to determine the bin icon to use
     * @param barcode:String - the barcode for the bin (if present)
     * @param coord:number[] - [longitude, latitude]. the center point where the bounds should be calculated from. stored in DB
     * @param keyNum:number - the current numBins. This is needed to generate unique keys and ids for each <ImageSource> and <RasterLayer>. This helps the app function more smoothly and avoids warnings/errors
     * @param diameter:number - the diameter of the bin. This number is divided by 2 to calculate bounds below. It is converted to meters if it is not meters
     * @param unit:unit - the unit of the diameter. Either unit.m or unit.ft. ft will be converted to m for the calculations
     * @param shape:{bounds, topLeft, topRight, botRight, botLeft} - coordinates that define the shape of the image to be displayed
     *        bounds:{minLat,maxLat,minLng,maxLng} - this is an object returned by geolib that gets the max lat and lng. used for calculating
     *        topLeft/topRight/botLeft/botRight:number[] - [longitude, latitude] these are the four corners that make up the boundary of the image
     */
    type MapBin = {
      bin: Bin;
      grain: String;
      status?: String;
      barcode?: String;
      volume: number;
      coord: GeolibInputCoordinates;
      id: number;
      diameter: number;
      image: Image;
      shape: { bounds; topLeft; topRight; botRight; botLeft };
    };

    /**
     * The State Machine for the various "modes" that the user can interact with the map.
     *
     * View: The user doesn't have any extra functionality and can simply view the map
     * Move: The user can move bins when they drag the bins around the screen
     * Selected: The state when a user taps on a bin- the bin is centered, and tools are drawn over the map to interact with the bin
     */
    enum StateMachine {
      View,
      Move,
      Selected,
    }

    //States==============================================================================================
    const [center, setCenter] = React.useState<number[]>(
      getCoord(route.params.location)
    );

    const [location, setLocation] = React.useState<BinLocation>(null);
    const [mapViewSize, setMapViewSize] = React.useState<{
      height: number;
      width: number;
    }>({ height: 0, width: 0 });
    const [binMarkers, setBinMarkers] = React.useState([]);
    const [binList, setBinList] = React.useState<MapBin[]>([
      // {coord: theFarm, keyNum:0, diameter:24, unit:Unit.ft, image: images.img1, shape:null}, {coord: theFarm2, keyNum:1, diameter:24, unit:Unit.ft, image: images.img2, shape:null}
    ]); //TODO clear this out when no longer needed for testing
    const [mapState, setMapState] = React.useState(StateMachine.View);

    const [loading,setLoading] = React.useState(false);
    const [selectedBin, setSelectedBin] = React.useState<{
      bin: Bin;
      grainData: {
          grainType: GrainType;
          grainSubCrop: GrainSubCrop;
      };
      volume: number;
      location: BinLocation;
      storageType: StorageType;
      lastState: "in" | "out";}>(null);
    
    const appContext = React.useContext(AppContext);
    const [selectedRow, setSelectedRow] = React.useState<MapBin[]>([]);

    const [scrollable, setScrollable] = React.useState(true);
    const [zoomable, setZoomable] = React.useState(true);
    const [shouldMapRespond, setShouldMapRespond] =
      React.useState<boolean>(true);

    const [zoomlevel, setZoomLevel] = React.useState<number>(
      route.params.location.zoom_level ?? 14
    );

    const [storageTypes, setStorageTypes] = React.useState<StorageType[]>([]);

    const [tools, setTools] = React.useState(null);


    
    //for bin adjuster
    const [binHistory, setBinHistory] = React.useState<BinSampleHistory[]>([]);
    const [adjustingVolume, setAdjustingVolume] = React.useState(false);
    const [viewingBin, setViewingBin] = React.useState(false);
    const [selectedVolume, setSelectedVolume] = React.useState<number>(0);
   

    //Refs
    /** ref for the map and cam objects. See https://github.com/react-native-mapbox-gl/maps/blob/HEAD/docs/MapView.md for methods and more details */
    let _map = React.useRef(null);
    let _cam = React.useRef(null);
    const calloutRef = React.useRef(null);

    //Constants ==============================================================================================

    var width = Dimensions.get("window").width;
    var height = Dimensions.get("window").height;

    /**Diameter in meters of area for offline caching of tiles */
    const yardArea = 1500;

    //Effects==========================================================================================================================================
    navigation.setOptions({headerTitle:()=>logoTitle(route.params.location.location)})

    React.useEffect(() => {
      const types = GetStorageTypes();

      if (types != null) {
        setStorageTypes(types);
      }
    }, []);


    //This effect loads all of the data in when a new map location is set.
    React.useEffect(() => {
      setLocation(route.params.location);


      async function GrabData() {
        let profile = await GetData("Profile");
        let bins = await GetBinsByLocation(route.params.location.id, profile);

        let bList: MapBin[] = [];

        bins.forEach((bs) => {
          //Only create if coordinates exist
          if (bs.longitude !== null) {
            let bin = bins.find((b) => b.id == bs.id);
            let finalHistory: {
              volume: number;
              output: number;
              created_at: Date;
              sample_id: number | null;
            }[] = [];
            let grain = "None";

            const binHistory = GetBinHistory(bs);

            const currentVolume = CalculateCurrentVolumeOfBin(binHistory);

            if (bin != null && bin != undefined) {
              bList.push({
                bin: bin,
                grain: binHistory.lastGrainUsed.name ?? "N/A",
                id: bs.id,
                volume: currentVolume,
                coord: [bs.longitude, bs.latitude],
                diameter: bs.diameter,
                image: getMapBinIcon(currentVolume, bin.size),
                shape: null,
              });
            }
          } else {
            console.log("Coordinates DO NOT for bin: " + bs.name + "\n\n");
          }
        });
        setBinList(bList);
      }

      GrabData();
    }, []);

    /**
     * This effect is for when the "selectedbin" is set in the params.
     * It moves the camera and selects the bin for us
     */
    React.useEffect(() => {
      if (route.params?.selectedBin !== undefined) {
        let selected = binList.find((mb) => {
          return mb.bin?.id === route.params?.selectedBin?.id;
        });
        if (selected === undefined) {
          Toast.show("Bin does not exist on map");
        } else {
          changeState(selected);
        }
      }


    }, [route.params]);

     function changeState(sel: MapBin) {

      setMapState(StateMachine.Selected);
     setCenter([sel.coord[0], sel.coord[1]]);

     //Get bin details to push to bin details popup

     const foundBin = GetOfflineStateParsed('bins').find(x => x.id == sel.bin.id);
     const loc = GetOfflineStateParsed('bin_locations').find(x => x.id == foundBin.location_id);
     const storage = GetOfflineStateParsed('storage_types').find(x => x.id == foundBin.storage_type_id);
     const binVolumeData = GetTotalBinVolume(foundBin);
     console.log('-----------FOUND BIN DATA-----------');
     if(binVolumeData != null)
     {
      setSelectedBin({...binVolumeData});
     }
     else
     {
      console.log('------FOUND MORE DATA---------');
      setSelectedBin({bin:foundBin,grainData:{grainType:defaultGrain,grainSubCrop:defaultGrainSubCrop}, volume:0,lastState:'in',location:loc,storageType:storage});
     }

     
   }

    //if the list of bins ever changes, we re-render
    React.useEffect(() => {
      renderMarkers();
    }, [binList]);

    React.useEffect(()=>{
      console.log(JSON.stringify(selectedBin));
    },[selectedBin])


    /**
     * This effect is for offline functionality
     */
    React.useEffect(() => {
      async function checkOfflinePack() {
        if (location != null && ValidID(location?.id)) {
          const op = await getOfflinePack();

          if (op == null) {
            createOfflinePack();
            console.log("cached pack created");
          }
        }
      }

      checkOfflinePack();
    }, [location]);

    /**
     * This effect handles any changes to the map state.
     * It runs the setTools() function to change the current buttons displayed,
     * as well as sets any map values like scrollable/zoomable
     */
    React.useEffect(() => {
      //render the toolbar for the specific map state
      setTools(renderTools());

      //set various things based on the stateMachine.
      switch (mapState) {
        case StateMachine.Move:
          setShouldMapRespond(true);
          setScrollable(false);
          setZoomable(true);
          break;

        case StateMachine.Selected:
          setShouldMapRespond(false);
          setScrollable(true);
          setZoomable(true);
          break;

        default: //StateMachine.View
          setScrollable(true);
          setZoomable(true);
          setShouldMapRespond(false);
          setSelectedRow([]);
          renderMarkers();
          break;
      }
    }, [mapState]);

    //async functions ===============================================================================================================================

    /**
     * function to render the markers onto the map.
     * Will recalculate any marker icons that have a shape === null
     */
    function renderMarkers() {
      console.log("rendering");

      //map each bin in the list to a marker(actually an imagesource with a rasterlayer) on the map
      let bl = binList.flatMap((item) => {
        //only recalculate if the bounds haven't been calculated yet- this is more efficient, as the bin's bounds only change when first placed or when moved,
        //but this method needs to be called every frame when moving a bin (right now anyway)
        if (item.shape === null) {
          calculateBoundsOfImage(item);
        }

        let sel = false;
        if (selectedRow != null) {
          sel = selectedRow.includes(item);
        }

        //the coordinates of the circle, 96 steps so we can divide by 8
        let circ = turfCircle.default(
          [item.coord[0], item.coord[1]],
          item.diameter / 2,
          { units: "inches", steps: 96 }
        ).geometry.coordinates;

        let fillPercent: number = 0;

        if (item.bin.size != null) {
          
          if(item.volume > item.bin.size)
          {
            fillPercent = (item.bin.size / item.bin.size) * 96; //we get the percentage of 96
          }
          else
          {
            fillPercent = (item.volume / item.bin.size) * 96; //we get the percentage of 96
          }
        
          console.log(fillPercent);
          fillPercent = Math.round(fillPercent / 12) * 12; //this is the nearest 12 - so we can get the 1/8th section to show
          console.log(fillPercent + " : nearest 12");
        }
        // fillPercent = 48; //this line is for hard-coding values for testing purposes

        //the array from turf goes counter-clockwise, so we start from the end to make the pieces "fill" clockwise
        let circFill = [
          [].concat(
            [[item.coord[0], item.coord[1]]],
            circ[0].slice(96 - fillPercent)
          ),
        ];
        let circEmpty = [
          [].concat(
            [[item.coord[0], item.coord[1]]],
            circ[0].slice(0, 96 - fillPercent + 1)
          ),
        ];

        // //return an array with both pieces- this gets flattened by the flatmap
        return [
          
          // <MapboxGL.ShapeSource
          //   id={"symbolLocationSourceEmpty" + item.bin.id.toString() ?? "-2"}
          //   key={"symbolLocationSourceEmpty" + item.bin.id.toString() ?? "-2"}
          //   hitbox={{ width: 20, height: 20 }}
          //   shape={{
          //     type: "Feature",
          //     geometry: { type: "Polygon", coordinates: circEmpty },
          //     properties: {},
              
          //   }}
            
          // >
          //   <MapboxGL.FillLayer
          //   accessible={true}
          //   accessibilityLabel={item.bin.name + '_Marker'}
          //     id={"FillEmpty" + item.bin.id.toString() ?? "-2"}
          //     style={{ fillColor: "green" }}
          //   />
          // </MapboxGL.ShapeSource>,
          // <MapboxGL.ShapeSource
          //   id={"symbolLocationSourceFilled" + item.bin.id.toString() ?? "-2"}
          //   key={"symbolLocationSourceFilled" + item.bin.id.toString() ?? "-2"}
          //   hitbox={{ width: 20, height: 20 }}
          //   shape={{
          //     type: "Feature",
          //     geometry: { type: "Polygon", coordinates: circFill },
          //     properties: {},
          //   }}
          // >
          //   <MapboxGL.FillLayer
          //     id={"FillFilled" + item.bin.id.toString() ?? "-2"}
          //     style={{ fillColor: "orange" }}
          //   />
          // </MapboxGL.ShapeSource>,
        ];
        

      });

      //now set the bin markers to that newly mapped list we just created, displaying it on the map
      setBinMarkers(bl);
    }

    async function getOfflinePack() {
      // const offlinePack = await MapboxGL.offlineManager.getPack(
      //   "offlinePack" + location?.location ?? ""
      // );
      // return offlinePack;
    }

    async function createOfflinePack() {
      const fauxBin: MapBin = {
        coord: getCoord(location),
        id: 0,
        volume: undefined,
        diameter: yardArea,
        image: null,
        shape: {
          bounds: undefined,
          topLeft: undefined,
          topRight: undefined,
          botRight: undefined,
          botLeft: undefined,
        },
        bin: undefined,
        grain: undefined,
      };

      calculateBoundsOfImage(fauxBin);

      const progressListener = (offlineRegion, status) => {
        // console.log("PROGRESSLISTENER");console.log(offlineRegion, status);
      };
      const errorListener = (offlineRegion, err) => {
        console.log("ERRORLISTENER");
        console.log(offlineRegion, err);
      };

      // await MapboxGL.offlineManager.createPack(
      //   {
      //     name: "offlinePack" + location?.location ?? "",
      //     styleURL: "mapbox://pageStyles/mapbox/satellite-v9",
      //     // minZoom: minZoom,
      //     // maxZoom: maxZoom,
      //     bounds: [
      //       [fauxBin.shape.topRight[0], fauxBin.shape.topRight[1]],
      //       [fauxBin.shape.botLeft[0], fauxBin.shape.botLeft[1]],
      //     ],
      //   },
      //   progressListener,
      //   errorListener
      // );
    }

    /**
     * The onResponderMove event for the map, is called when a touch event is fired on the map (press events are handled elsewhere)
     *
     * @param e touch event
     */
    async function onRespMove(e) {
      if (mapState === StateMachine.Move) {
        //get the coord for the location on screen that is touched
        const coord = await _map.current
          .getCoordinateFromView([
            e.nativeEvent.locationX,
            e.nativeEvent.locationY,
          ])
          .then((res) => res);

        //look for the bin (if any) that is on that point
        if (selectedBin === null) {
          const detected = detectImagesOnPoint(coord)[0];

          //Change this to use mapbin

          changeState(detected);
        }

        //as long as a bin was there, move it
        if (selectedBin != null) {
          //move the bin to the new point
          //selectedBin?.coord = coord;
          //make the shape null so the render method will recalculate it
         // selectedBin?.shape = null;
          renderMarkers();
        }
      }
    }

    /**
     * The onResponderRelease event for the map, fires when a touch event is done (aka when no fingers touching screen anymore)
     *
     * @param e touch event
     */
    async function onRespRelease(e) {
      setSelectedBin(null);
    }

    /**
     * The onpress method for the map
     *
     * @param e the touch event
     */
    async function onPress(e) {


      try
      {
         //get the coords of the press event
      const coord: [number, number] = e.geometry.coordinates;

      //what to do depends on the stateMachine
      if (
        mapState === StateMachine.View ||
        mapState === StateMachine.Selected
      ) {
        setLoading(true);
        // //if a bin is pressed, go to selection mode
        const detected = detectImagesOnPoint(coord);

        if (detected != null) {
          console.log("Detected something: ");
          //  _cam.current.moveTo(detected.coord);
          setZoomLevel(await _map.current.getZoom());
          // setCenter([detected.coord[0], detected.coord[1]]);
          calculateBoundsOfImage(detected);
          //  detectRowOfImages(detected);
          changeState(detected);
          setSelectedVolume(detected.volume);
          setMapState(StateMachine.Selected);
          renderMarkers();
        }
        setLoading(false);
      }
      }
      catch(error)
      {
        setLoading(false);
      }
     
    }

    /**
     * Handler for a longpress on the map
     * @param e
     */
    async function onLongPress(e) {
      if (mapState === StateMachine.View) {
        setMapState(StateMachine.Move);
      }
    }

    /**
     *
     */
    async function onViewTable() {
      //Grab storage data, based on location
      const profile = await GetData("Profile");
      const storage = GetStorageData("Location", location.id, profile);
      const selBin = selectedBin?.bin;
      setMapState(StateMachine.View);

      //TODO: update to the new storage screen
      navigation.navigate("StorageDisplay", {
        storageData: storage,
        selectedMapBin: selBin,
      });
    }


    //Other Functions ================================================================================================================================


    function OnPrimaryButtonPress()
    {
      setSelectedBin(null);  

      let bList: MapBin[] = [];

      binList.forEach((bs) => {
        //Only create if coordinates exist
        if (bs.bin.longitude !== null) {
     
          let finalHistory: {
            volume: number;
            output: number;
            created_at: Date;
            sample_id: number | null;
          }[] = [];
          let grain = "None";

          const binHistory = GetBinHistory(bs.bin);

          const currentVolume = CalculateCurrentVolumeOfBin(binHistory);

          if (bs.bin != null && bs.bin != undefined) {
            bList.push({
              bin: bs.bin,
              grain: binHistory.lastGrainUsed.name ?? "N/A",
              id: bs.id,
              volume: currentVolume,
              coord: [bs.bin.longitude, bs.bin.latitude],
              diameter: bs.diameter,
              image: getMapBinIcon(currentVolume, bs.bin.size),
              shape: null,
            });
          }
        } else {
          console.log("Coordinates DO NOT for bin: " + bs.bin.name + "\n\n");
        }
      });
      setBinList(bList);
    }


    function calculateBoundsOfImage(item: MapBin) {
      //get the radius to calculate the bounds
      const radius = item.diameter / 39.37;
      const c0 = item.coord[0],
        c1 = item.coord[1];
      const top = geolib.computeDestinationPoint([c0, c1], radius, 0);
      const right = geolib.computeDestinationPoint([c0, c1], radius, 90);
      const bottom = geolib.computeDestinationPoint([c0, c1], radius, 180);
      const left = geolib.computeDestinationPoint([c0, c1], radius, 270);

      const bounds = geolib.getBounds([top, right, bottom, left]);

      //use the bounds to create the four corners of the image
      item.shape = {
        bounds: bounds,
        topLeft: [bounds.minLng, bounds.maxLat],
        topRight: [bounds.maxLng, bounds.maxLat],
        botRight: [bounds.maxLng, bounds.minLat],
        botLeft: [bounds.minLng, bounds.minLat],
      };
    }

    /**
     * Detects bins (images) where they overlap a certain point.
     *
     * @param point The point to check
     * @returns an array of all Bin objects in the bin list that are at that point
     */
    function detectImagesOnPoint(point) {
      return binList.find((bin) => {
        if (bin.shape !== null) {
          return geolib.isPointInPolygon(point, [
            bin.shape.topLeft,
            bin.shape.topRight,
            bin.shape.botRight,
            bin.shape.botLeft,
          ]);
        } else {
          return false;
        }
      });
    }

    /**
     * Detects a row of images that are facing the same way
     * @param bin the mapbin that was selected to start detecting from
     */
    function detectRowOfImages(bin: MapBin) {
      const bs = [].find((bs) => {
        return bs.id === bin.id;
      });
      //first, we check if our bin faces N/S or E/W
      const facing = bs.direction;

      selectedRow.splice(0, selectedRow.length);
      selectedRow.push(bin);

      //then, we create a line going the opposite way, to each end of the boundary
      //the checkline function sets the list of selected bins to selectedRow
      if (facing === "North" || facing === "South") {
        checkLine("E", facing, bin.coord, 0);
        checkLine("W", facing, bin.coord, 0);
      } else {
        checkLine("N", facing, bin.coord, 0);
        checkLine("S", facing, bin.coord, 0);
      }
    }

    /**
     * A recursive method to check a line to determine if any mapBins are in the row. Used by detectRowOfImages()
     * @param dir the direction that we are checking
     * @param facing the direction that the found bins should face. If a bin is found facing another direction, we stop and assume we have hit a different row
     * @param point the point we are checking from. We assume we have already checked this point, but use it to calculate the next point (which we will check now)
     * @param count a count of how many times we have found nothing. We assume that finding nothing 5 times (15m) means that the row is complete
     */
    function checkLine(
      dir: "N" | "S" | "E" | "W",
      facing: string,
      point: GeolibInputCoordinates,
      count: number
    ) {
      const deg = dir == "N" ? 0 : dir == "E" ? 90 : dir == "S" ? 180 : 270;

      //we check every 3m on the line, to see if any bins are along the line.
      let nextPoint = geolib.computeDestinationPoint(point, 3, deg);
      const found = detectImagesOnPoint(nextPoint);

      //if we find something:
      if (found !== undefined) {
        //we reset the count
        count = 0;

        //we check that it faces the same direction as our bin
        if (found.bin.direction.valueOf() === facing) {
          //we then add it to the list of selected bins
          //provided that it is not already in that list
          if (!selectedRow.includes(found)) {
            selectedRow.push(found);

            //we also reset the checking center point to the center of the new bin. This allows us to check even if the bins are not perfectly straight
            nextPoint = { longitude: found.coord[0], latitude: found.coord[1] };
          }
        } else {
          //if we find a bin that faces the other way, we immediately set count to 5 to end the search
          count = 5;
        }
      }
      //if we don't find anything, we increase count
      else {
        count++;
      }

      //check recursively until count reaches 5 (15 m of no bins);
      if (count != 5) {
        checkLine(dir, facing, nextPoint, count);
      }
    }

    /**
     * This object sets a react state called tools, which is a section above the map (for now) that
     * contains inputs or controls that may be needed when in a specific mapState.
     *
     * @returns a JSX object that contains any extra tools that are needed for a certain mapState
     */
    function renderTools() {
      switch (mapState) {
        case StateMachine.Move:
          return (
            <>
              <Text>Move Bins</Text>
              <Button
                title="CANCEL"
                onPress={() => {
                  setMapState(StateMachine.View);
                }}
              ></Button>
            </>
          );
        // case StateMachine.Selected:
        // return <>
        // <Button title="CANCEL" onPress={()=>{setMapState(StateMachine.View)}}></Button>
        // <View style={{position:'absolute', top:mapViewSize.height * 0.4, right:mapViewSize.width * 0.25, zIndex:500}}><Button title="Duplicate Bin" onPress={()=>{setMapState(StateMachine.Duplicate)}}></Button></View>
        // </>
        default: //any states that do not have tools
          return null;
      }
    }

    //stylesheets ===========================================================================================================================

    //this stylesheet is for the map's background containers
    const pageStyles = StyleSheet.create({
      page: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5FCFF",
      },
      container: {
        height: "70%",
        width: "100%",
        backgroundColor: "white",
      },
      map: {
        flex: 1,
        zIndex: 100,
      },
      buttonText: {
        color: "white",
        textAlign: "center",
        alignSelf: "center",
        textAlignVertical: "center",
        fontSize: 16,
        flexWrap: "wrap",
      },
    });

    //These pageStyles are for the tooltip box
    const tooltipStyles = StyleSheet.create({
      container: {
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        width: 180,
        zIndex: 1,
      },
      tip: {
        zIndex: 0,
        marginTop: -2,
        elevation: 0,
        backgroundColor: "transparent",
        borderTopWidth: 16,
        borderRightWidth: 8,
        borderBottomWidth: 0,
        borderLeftWidth: 8,
        borderTopColor: "white",
        borderRightColor: "transparent",
        borderBottomColor: "transparent",
        borderLeftColor: "transparent",
      },
      content: {
        position: "relative",
        padding: 8,
        flex: 1,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.2)",
        backgroundColor: "white",
      },
      title: {
        color: "black",
        textAlign: "left",
        marginVertical: 3,
        marginHorizontal: 5,
        fontSize: 16,
      },
      close: {
        color: "black",
        textAlign: "right",
        fontSize: 18,
        fontWeight: "bold",
      },
    });

    //this "stylesheet" is in tileJSON format, and has the tiles info
    const mapStyle = {
      version: 8,
      sources: {
        "raster-tiles": {
          type: "raster",
          tiles: [
            "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=sk.eyJ1IjoiYnJhZHl3YXJmb3JkIiwiYSI6ImNrd2tjeGUzNzE1c3oyb2w1c2NkOGZ2d2YifQ.zbUW4UH2FPV8i4E9fJrh8Q",
          ],
          tileSize: 256,
        },
        // 'labels':{
        //   'type':'vector',
        //   'tiles':[
        //     'https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.mvt?access_token=sk.eyJ1IjoiYnJhZHl3YXJmb3JkIiwiYSI6ImNrd2tjeGUzNzE1c3oyb2w1c2NkOGZ2d2YifQ.zbUW4UH2FPV8i4E9fJrh8Q'
        //   ],
        //   // 'url':'mapbox://mapbox.mapbox-streets-v8'
        // },
      },
      layers: [
        {
          id: "simple-tiles",
          type: "raster",
          source: "raster-tiles",
          //these limit the style itself, making the map show only empty, black space if the zoom levels are exceded
          // 'minzoom': 10,
          // 'maxzoom': 18
        },
        // {
        //   'id':'labels-streets',
        //   'type':'line',
        //   'source':'labels',
        //   'source-layer':'admin',
        //   "paint": {
        //     "line-color": "#ffffff"
        //     }
        // },
        // {
        //   "id": "place_label",
        //   "source": "labels",
        //   "source-layer": "place_labels",
        //   "type":"symbol",
        //   "layout":{
        //     "text-field":"test"
        //   }
        //   },
      ],
    };

    //THE RENDER RETURN ==========================================================================================================================================

    return (
      <View style={pageStyles.page}>

{loading ? <Spinner
        visible={loading}
        textContent={'Loading...'}
        cancelable={false}
        textStyle={styles.spinner}
      /> : null}

        {/* Buttons for when selecting a bin + row */}
        {mapState === StateMachine.Selected ? (
          <View
            style={{ flexDirection: "row", justifyContent: "space-around" }}
          >
            
          </View>
        ) : (
          <View>
            <Text>Tap on a Bin to see more information</Text>
          </View>
        )}



        {adjustingVolume ? <BinVolumeAdjuster OnClosed={() => {setAdjustingVolume(false)}} OnSaved={(volumeHistory) => {
    let currentHistory = binHistory.find(x => x.bin.id == selectedBin.bin.id);
    currentHistory.binHistory.push({added:true,creation_date:new Date(),volume:volumeHistory,sample:null})
    setBinHistory([currentHistory]);
    setAdjustingVolume(false);
    renderMarkers();
  }} historyData={binHistory.find(x => x.bin.id == selectedBin.bin.id)} adjustingVolume={adjustingVolume}  />: null}

        <View
          style={pageStyles.container}
          onLayout={(e) => {
            setMapViewSize({
              height: e.nativeEvent.layout.height,
              width: e.nativeEvent.layout.width,
            });
          }}
        >
          {tools}


          <Overlay  isVisible={selectedBin?.bin != null}  onBackdropPress={() => {setSelectedBin(null)}}>

      <View style={{width:width/1.2,height:height/1.2}}>
      <BinDetailsComponent OnPrimaryButtonPress={OnPrimaryButtonPress} bin={selectedBin?.bin} binLocation={selectedBin?.location} currentVolume={selectedBin?.volume} storageType={selectedBin?.storageType} grainData={selectedBin?.grainData} isPopUp={true} status={selectedBin?.lastState} />

        </View>
        </Overlay>

          {/* <MapboxGL.MapView
            scrollEnabled={scrollable}
            zoomEnabled={zoomable}
            pitchEnabled={false}
            rotateEnabled={false}
            onMoveShouldSetResponder={() => shouldMapRespond}
            onResponderMove={(e) => {
              onRespMove(e);
            }}
            onResponderRelease={(e) => {
              onRespRelease(e);
            }}
            style={pageStyles.map}
            styleJSON={JSON.stringify(mapStyle)}
            ref={_map}
            localizeLabels={true}
            onPress={onPress}
            // onLongPress={onLongPress} //only handles moving bins now, which isn't really supported
          >
            <MapboxGL.Camera
              ref={_cam}
              zoomLevel={zoomlevel}
              // minZoomLevel={minZoom} maxZoomLevel={maxZoom}
              centerCoordinate={center}
              // bounds={{ne:null,sw:null}}
            />
            {binMarkers}
           
          
          </MapboxGL.MapView> */}
        </View>
      </View>
    );
  } else {
    return <Text>Map Content Goes Here (use Emulator)</Text>;
  }
}

export default YardOverviewScreen;

function CalculateCurrentVolumeOfBin(binHistory: {
  bin: Bin;
  binHistory: {
    volume: number;
    added: boolean;
    sample?: MasterSample;
    creation_date: Date;
  }[];
}): number {
  let total = 0;

  binHistory.binHistory.forEach((x) => {
    if (x?.sample != null) {
      if (x.added) {
        total += x.volume;
      } else {
        total -= x.volume;
      }
    } else {
      total += x.volume;
    }
  });

  return total;
}

    
