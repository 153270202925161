import { RFC_2822 } from "moment";
import  { Bin, BinLocation, Buyer,MasterSample, Enterprise, Field, GrainType,GrainSubCrop,GrainSupplier, GrainVariety, GrowerUser, PendingUser, SampleImage, SampleOriginTransportation, SubSample, UserInfo, EquipmentModel, BinMake, BinModel, BinWallType, UserState, Document, UserEquipment, EquipmentBrand, UserInstrumentModel } from "./UserState";

//Bin Constructors
export const defaultBin: Bin = {
    id: -2, updated_at: null, expired:0, name: "", storage_type_id: null, location_id: -1, size: 0
}
export const addBinSelector: Bin = {
    id: -1, updated_at: null, expired:0, name: 'Add New Storage', storage_type_id: null, location_id: -1, size: 0
};


//Bin Location Constructors
export const defaultBinLocation: BinLocation = { id: -2, location: "", profile: "", expired:0, updated_at: undefined };
export const addBinLocationSelector: BinLocation = { id: -1, location: "Add New Storage Location", profile: "", expired:0, updated_at: undefined };


//Field Constructors
export const defaultField: Field = { id: -2, created_at: null, expire_date: null, updated_at: null, profile: "", meridian: "", name: "", quarter: "", rangev: null, section: null, township: null };
export const addFieldSelector: Field = { id: -1, name: 'Add New Field', meridian: "", rangev: 0, township: 0, section: 0, quarter: "", profile: "", updated_at: null, expire_date: null, created_at: null }

//Buyer Constructors
export const defaultBuyer: Buyer = { id: -2, name: "", buyer_email: "", location: "", created_at: null, expire_date: null, profile: "", updated_at: null };
export const addBuyerSelector: Buyer = { id: -1, name: 'Add New Buyer/Broker', buyer_email: "", location: 'Add New Buyer/Broker', created_at: null, expire_date: null, profile: "", updated_at: null };


// Equipment Constructors 
export const defaultUserEquipment: UserEquipment = {
    id: -2, name: "", profile: "", bushels_hr_rate: 0, sem: "", updated_at: undefined, equipment_model_id: null,expired:0,truck_load:null,unload_min:null,unload_sec:null
};
export const addUserEquipmentSelector: UserEquipment = {
    id: -1, name: "Add New User Equipment", profile: "", bushels_hr_rate: 0, sem: "", updated_at: undefined, equipment_model_id: null,expired:0,truck_load:null,unload_min:null,unload_sec:null
};


export const defaultEquipmentBrand: EquipmentBrand = { id: -1, name: "", updated_at: undefined };
export const defaultEquipmentModel: EquipmentModel = { id: -2, brand_id: -2, name: "", bushel_loading : 0,  profile: "", equipment_type: "", updated_at: undefined };

//Grain Constructors
export const defaultGrain: GrainType = { id: -2,  name: "", updated_at: null, profile: null};
export const addGrainSelector: GrainType = { id: -1, name: 'Request a Grain be added', updated_at: null, profile: null};;

export const defaultGrainSupplier:GrainSupplier = {id:null,name:'',profile:null,updated_at:null}
//Grain Sub Crop Constructors
//IMPORTANT, This grain subcrop must align with what is on the DB
export const defaultGrainSubCrop:GrainSubCrop = {id:26,name:null,profile:null,updated_at:null};

export const addGrainSubCropSelector:GrainSubCrop = {id:-2,name:'Request a Grain Sub Crop Be Added',profile:null,updated_at:null};

export const defaultGrainVariety:GrainVariety = {id:-2,cfia:'', name:'',supplier_id:null,updated_at:null,profile:null}

export const defaultEnterprise: Enterprise = {
    id: -2, enterprise_email: "", enterprise_url: "", border_color: "lightgrey", image_path: "", location: "", name: "",
    created_at: undefined,
    updated_at: undefined
};;



export const defaultSubSample: SubSample = { id: null, master_sample_id: null, parent_sample_id: null, updated_at: null,expired:0};

export const defaultDocument: Document = { id: null, master_sample_id: null, created_at: null, doc_type: null, file_path: null, title: "", updated_at: null };;

export const defaultImage: SampleImage = { id: null, master_sample_id: null, created_at: null, image_type: null, file_path: null, updated_at: null };;


export const defaultGrowerUser: GrowerUser = { id: null, allow_completed_samples: -1, allow_from_date: null, created_at: null, enterprise_id: -1, profile: "", updated_at: null };;



export const defaultSampleTransportation: SampleOriginTransportation = { master_sample_id: null, created_at: null, updated_at: null, profile: '', user_id: 0, transportation_id: -1, transportation_name: '' };


export const defaultUser: UserInfo = { id: -1, city: '', company: '', country: '', email: '', name: '', phone: '', profile: '', province: '', admin: 0 }

export const defaultPendingUser: PendingUser = { id: null, admin_id: -1, city: '', company: '', country: '', email: '', name: '', phone: '', profile: '', province: '' }


export const defaultUserInstrument:UserInstrumentModel = { id:-1,model_id:-1,profile:'',updated_at:null };
export const defaultBinMake: BinMake = {
    id: -2,
    name: "",
    created_at: undefined,
    updated_at: undefined
};
export const defaultBinModel: BinModel = {
    id: -2, bin_make_id: -2, diameter: 0, height: 0, model_name: '', size: 0, storage_type_id: -2, wall_type_id: 0, created_at: undefined, updated_at: undefined,
    wall_tier: 0
};

export const defaultBinWallType: BinWallType = {
    id: -2,
    name: "",
    created_at: undefined,
    updated_at: undefined
};



export const defaultMasterSample: MasterSample = { barcode: 0, container_size: '', created_at: null, id: null, profile: '', updated_at: null, user_id: 0,equipment_type:'',completed:null,dial:null,latitude:null,longitude:null,manual_sample_type_id:null,sample_size:null,sample_taken_at:null,sample_time:null,samples_required:null,scoop_size:null,target_time:null,time_current:null,volume:null };

export const defaultUserState: UserState = {
    allowed_sample_grains: [],
    allowed_samples: [],
    bin_locations: [],
    bin_makes: [],
    bin_models: [],
    bin_volume_history: [],
    bin_wall_types: [],
    bins: [],
    buyers: [],
    characteristic_sub_options: [],
    characteristic_types: [],
    combined_sample_records: [],
    enterprise_users: [],
    enterprises: [],
    equipment_brands: [],
    equipment_models: [],
    user_equipment: [],
    equipment_sem:[],
    fields: [],
    grain_varieties: [],
    grain_types:[],
    grain_sub_crops:[],
    grain_suppliers:[],
    crop_select_options:[],
    grower_users: [],
    lab_grain_lookup: [],
    labs: [],
    manual_progress_times: [],
    master_samples: [],
    pending_analysis: [],
    pending_buyers: [],
    sample_record_characteristics: [],
    sample_destination_transportations: [],
    sample_destination: [],
    sample_equipment: [],
    sample_grain_varieties: [],
    sample_origin_transportations: [],
    sample_source: [],
    storage_types: [],
    sub_samples: [],
    transportation_options: [],
    user_grains: [],
    manual_sampling_types: [],
    documents:[],
    sample_images:[],
    instrument_brands:[],
    instrument_characteristics:[],
    instrument_models:[],
    user_instrument_models:[],
    users:[]
};












































