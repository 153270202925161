import React from "react";
import { Text, View } from "react-native";
import Colours from "../Styles/Colours";
import spacing from "../Styles/Spacing";
import textStyles from "../Styles/Text";
import { Icon } from "./Icon";

export function RequiredText(){
    return <Text style={[{ color:Colours.ERROR, alignSelf: 'flex-start', padding: 10 }, textStyles.info]}>* Indicates Required Field</Text>
}

type passwordType={
    visible:boolean,
    status:{
        upper:boolean;
        lower:boolean;
        number:boolean;
        size:boolean;
    }
}
export function PasswordRequireText(props:passwordType){
    return (
        <>
        {props.visible ?    
            <View style={spacing.Container}>
                <Text style={textStyles.label}>Passwords must:</Text>
                <View style={{flexDirection:'row',alignItems:'center'}}>
                    <Text style={[textStyles.info, {color:Colours.INFO}]}>Have an upper case character</Text> 
                    {props.status.upper ? <Icon name="good" color={Colours.SUCCESS} /> : null}
                </View>

                <View style={{flexDirection:'row',alignItems:'center'}}>
                    <Text style={[textStyles.info, {color:Colours.INFO}]}>Have a lower case character</Text> 
                    {props.status.lower ? <Icon name="good" color={Colours.SUCCESS} /> : null}
                </View>

                <View style={{flexDirection:'row',alignItems:'center'}}>
                    <Text style={[textStyles.info, {color:Colours.INFO}]}>Have a number</Text> 
                    {props.status.number ? <Icon name="good" color={Colours.SUCCESS} /> : null}
                </View>

                <View style={{flexDirection:'row',alignItems:'center'}}>
                    <Text style={[textStyles.info, {color:Colours.INFO}]}>Be at least 8 characters long</Text> 
                    {props.status.size ? <Icon name="good" color={Colours.SUCCESS} /> : null}
                </View>

            </View>
            :
            <></>
        }
        </>
    )
}
