import { ja } from "date-fns/locale";
import Config from "../../config";
import { GetAsyncDataRaw, SaveDataToOfflineState, StoreAsyncDataUnsafe, UpdateDataInOfflineState, ReplaceDataInOfflineState, GetOfflineStateParsed, GetAsyncDataParsed, storeError, RemoveDataInOfflineState } from "../AsyncStorageHelper";
import { ApiCall, ValidID } from "../Functions/GeneralFunctions";
import { FullSync } from "../Functions/OfflineFunctions";
import { EnsureArray } from "../GeneralTypes";
import {Document, AllowedSample, AllowedSampleGrain, Bin, BinLocation, BinMake, BinModel, BinVolumeHistory, BinWallType, Buyer, CharacteristicSubOption, CharacteristicType, CombinedSampleRecord, Enterprise, EnterpriseUser, EquipmentModel, Field, GrainVariety, GrowerUser, Lab, LabGrainLookup, ManualProgressTimes, ManualSamplingTypes, MasterSample, PendingAnalysis, PendingBuyer, SampleRecordCharacteristic, SampleDestination, SampleDestinationTransportation, SampleEquipment, SampleGrainVariety, SampleOriginTransportation, SampleSource, SemEquipment, StorageType, SubSample, TransportationOption, UserGrain, UserInfo, UserState, UserStateKey, SampleImage, EquipmentBrand, UserEquipment, InstrumentBrand, InstrumentModel, InstrumentCharacteristic, UserInstrumentModel, GrainType, GrainSupplier, GrainSubCrop, CropSelectOption } from "./UserState";


type Operation = "add" | "update" | "delete" | "perm_delete"
type Sync<T> = T & { operation: Operation };

type IDPairs = {
    [Property in UserStateKey]: { old_id: number, new_id: number }[]
}

type SyncResponse = {
    "successes": UserState;
    "failures": UserState;
    "ids": IDPairs
}

const sync_url = "/offline/sync-updates";


export class OfflineQueue implements UserState {
    allowed_sample_grains: Sync<AllowedSampleGrain>[];
    allowed_samples: Sync<AllowedSample>[];
    bin_locations: Sync<BinLocation>[];
    bin_makes: Sync<BinMake>[];
    bin_models: Sync<BinModel>[];
    bin_volume_history: Sync<BinVolumeHistory>[];
    bin_wall_types: Sync<BinWallType>[];
    bins: Sync<Bin>[];
    buyers: Sync<Buyer>[];
    characteristic_sub_options: Sync<CharacteristicSubOption>[];
    characteristic_types: Sync<CharacteristicType>[];
    combined_sample_records: Sync<CombinedSampleRecord>[];
    enterprise_users: Sync<EnterpriseUser>[];
    enterprises: Sync<Enterprise>[];
    equipment_brands: Sync<EquipmentBrand>[];
    equipment_models: Sync<EquipmentModel>[];
    user_equipment: Sync<UserEquipment>[];
    equipment_sem:Sync<SemEquipment>[];
    fields: Sync<Field>[];
    grain_types:Sync<GrainType>[];
    grain_sub_crops:Sync<GrainSubCrop>[];
    grain_suppliers:Sync<GrainSupplier>[];
    grain_varieties:Sync<GrainVariety>[];
    crop_select_options:Sync<CropSelectOption>[];
    grower_users: Sync<GrowerUser>[];
    instrument_brands: Sync<InstrumentBrand>[];
    instrument_models: Sync<InstrumentModel>[];
    lab_grain_lookup: Sync<LabGrainLookup>[];
    labs: Sync<Lab>[];
    manual_progress_times: Sync<ManualProgressTimes>[];
    master_samples: Sync<MasterSample>[];
    pending_analysis: Sync<PendingAnalysis>[];
    pending_buyers: Sync<PendingBuyer>[];
    sample_record_characteristics: Sync<SampleRecordCharacteristic>[];
    sample_destination_transportations: Sync<SampleDestinationTransportation>[];
    sample_destination: Sync<SampleDestination>[];
    sample_equipment: Sync<SampleEquipment>[];
    sample_grain_varieties: Sync<SampleGrainVariety>[];
    sample_origin_transportations: Sync<SampleOriginTransportation>[];
    sample_source: Sync<SampleSource>[];
    storage_types: Sync<StorageType>[];
    sub_samples: Sync<SubSample>[];
    transportation_options: Sync<TransportationOption>[];
    user_grains: Sync<UserGrain>[];
    instrument_characteristics: Sync<InstrumentCharacteristic>[];
    user_instrument_models: Sync<UserInstrumentModel>[];
    users: Sync<UserInfo>[];
    manual_sampling_types: Sync<ManualSamplingTypes>[];
    sample_images: Sync<SampleImage>[];
    documents: Sync<Document>[];
    other_users:Sync<UserInfo>[];
        /**
     * Create a new Offline Queue. Optionally provide JSON data to prepopulate it with.
     * @param json JSON data to fill the Offline Queue with
     */
    public constructor(json?: string) {
        if (json) {
            let temp: OfflineQueue = null;
            let parsed = JSON.parse(json);

            // Handle "overstringified" strings
            if (typeof parsed === 'string')
                parsed = JSON.parse(parsed);
            temp = parsed;

            for (const key in temp) {
                this[key] = temp[key];
            }
        } else {
            
            for (const key of Object.keys(this)) {
                this[key] = new Array
            }
        }
    }

    public stringify(): string {
        return JSON.stringify(this);
    }


    public enqueue<T extends keyof OfflineQueue>(table: T, record: EnsureArray<OfflineQueue[T]>[number]): boolean {
        try {
            let arr;
            if (this[table] != null && Array.isArray(this[table])) {
                arr = this[table];
            }
            else {
                arr = [];
            }

            if (record.operation !== "add") {
                // Check if it is in the queue already
                let index = -1;

                if ("id" in record) {
                    index = arr.findIndex(x => x.id === record.id);
                }

                if (index != -1) {
                    const old_record = arr[index];
                    // If the old record was an add operation, then we want this new record
                    // to be an add and not an update, since the old record has not been added yet
                    record.operation = old_record.operation === "add" ? "add" : old_record.operation;
                    arr[index] = record;
                    return true;
                }
            }

            // we are either doing an add operation or it wasn't in the queue already
            // we can just put it in the queue safely now
            arr.push(record as any);
            this[table] = arr;
            return true;
        } catch (e) {
            console.log('Enqueue Error: ', e);
            return false;
        }
    }

    public size(): number {

        let keyf = '';
        try
        {
            let totalSize = 0;
            for (const key in this) {

                keyf = key.toString();
                if (this.hasOwnProperty(key) && typeof (this[key] as any).length === "number") {
                    totalSize += (this[key] as unknown as any).length;
                }
            };
    
            return totalSize;

        }
        catch(error)
        {
            console.log('Error: ' + keyf + '------' + error)
        }

    }

}

export async function ResetQueue() {
    const empty_queue = new OfflineQueue;
    return SaveQueueLocally(empty_queue);
}


export function SaveQueueLocally(q: OfflineQueue) {
    StoreAsyncDataUnsafe("OfflineQueue", q.stringify());
}



export async function SyncQueue() {
    const q = await GetOfflineQueue();
    const size = q.size();

    if (size < 1) {
        console.log("No items in queue...");
        return;
    }

    console.log(`\n\nSyncing ${size} item${size != 1 ? 's' : ''} in the Queue to the DB...\n`);


    //For some reason, empty arrays still go through in the API Call
    //delete keys that dont have any data in them
    let finalList:OfflineQueue = q;
    Object.keys(finalList).forEach(key => {
        if(finalList[key].length == 0)
        {
            delete finalList[key];
        }
    })

    console.log('--->>>' +JSON.stringify({ data: finalList}));
    const status = await ApiCall<SyncResponse>(sync_url,JSON.stringify({ data: finalList}), true);


    /** TODO: handle API return
     * 
     * Clear offline queue of any successfully saved data
     * Update locally saved copies of saved data to match the returned version (ie: update temp ids with real ids)
     *      What do we do if a user has field with temp id '217620' open and it has been updated?
     * Figure out how to handle data that could not be saved
     * 
    */

    // Handle the updated ids first
    const failed_id_updates: { old_id: number, new_id: number, record: any, table: string }[] = [];

    for (const table in status.successes) {
        const records = status.successes[table];
        const pairs: IDPairs[keyof IDPairs] = status.ids[table];

        for (const record of records) {
            let updated = false;

            if (pairs.find(x => x.new_id === record.id) != null) {
                const pair = pairs.find(x => x.new_id === record.id);

                if(record.operation != 'perm_delete')
                {
                    updated = await UpdateDataInOfflineState(table as any, record, pair.old_id);
                }
                else
                {
                    updated = await RemoveDataInOfflineState(table as any,record,pair.old_id);
                }
             
                if (!updated) {
                    failed_id_updates.push({ ...pair, record, table });
                    console.log("Couldn't update newly added record: ", { ...pair, record, table });
                }
            } else {

                if(record.operation != 'perm_delete')
                {
                    updated = await UpdateDataInOfflineState(table as any, record);

                }
                else
                {
                    updated = await RemoveDataInOfflineState(table as any,record);
                }

                if (!updated) {
                    console.log("Couldn't update pre-existing record: ", { record, table });
                }
            }


            //TODO: handle failures

            if (!updated) {
                console.error(`\nFailure updating record locally: \n${record}\n\n`);
            }
        }
    }

    for (const table in status.failures) {
        if (status.failures[table].length > 0) {
            console.log(`${table} failures:\n`);
            console.log(status.failures[table]);
            let err = status.failures[table];
            for (const e in err) {
                err[e].table = table.toString();
                await storeError(err[e]);
            }
        }
    }



    //TODO: only remove successfully added records from queue
    // or alternatively remove all of them and then set the failed records aside
    ResetQueue();

    //TODO: store failed updates
    // StoreAsyncData('FailedRecords', ...);

}

export  function GetOfflineQueue(): OfflineQueue {
    const json =  GetAsyncDataRaw("OfflineQueue");
    const temp: OfflineQueue = new OfflineQueue(json);
    return temp;
}

//const delete_permanently: UserStateKey[] = ["user_grains"];

async function SaveDataLocally<T extends UserStateKey>(table: T, record: EnsureArray<UserState[T]>[number], operation: Operation) {
    let result: boolean;
    switch (operation) {
        case "add":
            result = await SaveDataToOfflineState(table, record);
            break;
        case "update":
        case "delete":
            result = await UpdateDataInOfflineState(table, record);
            break;
        case "perm_delete":
            result = await RemoveDataInOfflineState(table,record);
        break;
        default:
            return false;
        break;
    }

    return result;
}

export  function AddToQueue<T extends UserStateKey>(table: T, record: EnsureArray<UserState[T]>[number], operation: Operation): boolean
export  function AddToQueue<T extends UserStateKey>(table: T, record: EnsureArray<UserState[T]>, operation: Operation): EnsureArray<UserState[T]>
export  function AddToQueue<T extends UserStateKey>(table: T, record: EnsureArray<UserState[T]>[number] | EnsureArray<UserState[T]>, operation: Operation): boolean | any[]{
    const q =  GetOfflineQueue();
    const failed = [];
    if (Array.isArray(record)) {
        for (const rec of record) {
            const added = q.enqueue<T>(table, { ...rec, operation });

            // TODO: clean up this error handling
            //If the record has not been added, push failed record to array
            if (!added) {
                console.log('Failed to add to queue');
                failed.push(rec);
            } else {
                SaveDataLocally(table, rec, operation);
            }

        }
    } else {
        const rec: Sync<EnsureArray<UserState[T]>[number]> = { ...record, operation };
        const success = q.enqueue<T>(table, rec as EnsureArray<OfflineQueue[T]>[number]);

        if (!success) return false;
        SaveDataLocally(table, record, operation);
    }


 //   console.log('Current Queue: ', q, '\n');

     SaveQueueLocally(q);

    // TODO: Auto sync queue? Potentially re-enable this

    // const offline = await GetAsyncDataParsed<boolean>("isOffline");

    // if (!offline) {
    //     OfflineQueue.SyncQueue();
    // }

    if(!Config.usingOffline)
    {
        const Toast = require('react-native-tiny-toast');

        console.log('Trying to sync.');
        const start = new Date();
        FullSync()
            .then(() => Toast.show("Sync finished successfully!"))
            .catch((error) => {
                console.log("SYNC FAILED: " + error);
                Toast.show("Sync failed...")
            }
            )
            .finally(() => {
                console.debug('SYNC TOOK: ', (new Date()).getTime() - start.getTime(), 'ms to complete.');
              
            });
    }

    return Array.isArray(record) ? failed : true;
}

