
import React from "react";
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView, TextInput,GestureResponderEvent} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Colours from "../../Styles/Colours";

type PropType = {
   onPress:(event: GestureResponderEvent)=>void,
   testLabel?:string,
   labelName:string,
    editable?:boolean,
    checked:boolean,
}

/**
 * 
 * @param onPress
 * An Onpress event callback used when tapping on an input with a dropdown selection
 * 
 * @param inputName
 * Name of the input - I.E THe name that appears on the left side of the input
 * @param testLabel
 * The label used for testing by setting the parameter passed in to the accessibility label
 * By Default sets to Input_Option
 * @param ref
 * An optional ref property that allows a textInput to be focusable if you tap around the input
 * 
 * @param editable
 * Is the textInput allowed to be used manually? If not it simply acts as a text field
 * 
 * @param value
 * The value of the object property to display
 * 
 * 
 * 
 * 
 */

function RadioForm(props: PropType) {


        return (
        <TouchableOpacity
        accessibilityLabel={props?.testLabel??'radio_input'}
        style={{flexDirection:'row',justifyContent:'center'}}
        onPress={props.onPress} >
               <Icon style={{textAlign:'right'}} name={props.checked ? "circle" : "circle-o"} size={30} color={props.checked ? Colours.VG_AMBER: Colours.VG_GREY_70} />
            <Text style={{ paddingLeft: 5, fontSize: 18,marginRight:5,textAlignVertical:'center'}}>{props.labelName}</Text>            
        </TouchableOpacity>
    );

}
export default RadioForm;

