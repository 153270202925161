import { Alert } from "react-native";
//import Toast from "react-native-tiny-toast";
import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { Field } from "../../Storage/UserState";
import {  GenerateNumberID } from "../GeneralFunctions";


export function GetFields(): Field[] {
    let fields = GetOfflineStateParsed("fields");

    fields.sort(function (a, b) {
        const reA = /[^a-zA-Z]/g;
        const reN = /[^0-9]/g;
        const aA = a.name.replace(reA, "");
        const bA = b.name.replace(reA, "");
        if (aA === bA) {
            const aN = parseInt(a.name.replace(reN, ""), 10);
            const bN = parseInt(b.name.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    })
    return fields;
}

export async function GetDeletedFields(profile: string): Promise<Field[]> {
    const deletedFields = await GetOfflineStateParsed("fields");

    return deletedFields.filter(x => x.profile == profile && x.expire_date != null);
}

export async function RestoreDeletedFields(deletedFields: Field[], profile: string): Promise<Field[]> {
    const restoredFields = [];

    for (const field of deletedFields) {
        if (field.id != -1 && field.profile == profile) {
            const status = await AddToQueue("fields", {...field, expire_date: null}, "update");
            if (status) {
                restoredFields.push(field);
            }
        }
    }

    return restoredFields.length > 0 ? restoredFields : null;
}

export async function DeleteField(to_delete: Field): Promise<boolean> {
    if (to_delete.profile != null) {
        const now = new Date();
        to_delete.expire_date = new Date(now.setMonth(now.getMonth() + 6));

        const status = await AddToQueue("fields", to_delete, "delete");

        return status;
    }

    return false;
}

export async function UpdateField(field: Field): Promise<boolean> {
    if (field.profile != null && field.name != 'Add New Field' && field.name.length != 0 && ValidateField(field).length == 0) {
        const success = await AddToQueue("fields", field, "update");
    
        return success;
    }

 
}

export async function AddField(currentField: Field, profile: string): Promise<Field> {
    if (profile || currentField.profile) {
        console.log(currentField.section);

        const parsedField = {
            ...currentField,
            expire_date: null,
            id: await GenerateNumberID(),
            meridian: !currentField.meridian ? '' : currentField.meridian,
            name: currentField.name,
            profile: !currentField.profile || currentField.profile === '' ? profile : currentField.profile,
            quarter: !currentField.quarter ? '' : currentField.quarter,
        }

        await AddToQueue("fields", parsedField, "add");

        return parsedField;
    }
    
    return currentField;
}

export function ValidateField(currentField: Field): string[] {

    console.log(currentField);
    let errorMessageArray: string[] = []

    if (currentField.name == "") {
        errorMessageArray.push("Field Name cannot be empty");
    }

    if(currentField.name.length > 50) {
        errorMessageArray.push("Field name must be less than 50 characters long.");
    }

    if (currentField.rangev != null && currentField.rangev != 0) {
        if (currentField.rangev < 1 || currentField.rangev > 99) {

            errorMessageArray.push("Range must be a number between 1 and 99")
        }
    }

    if (currentField.section != null && currentField.section != 0) {
        if (currentField.section < 1 || currentField.section > 36) {

            errorMessageArray.push("Section must be a number between 1 and 36, set to: " + currentField.rangev)
        }
    }

    if (currentField.township != null && currentField.township != 0) {
        if (currentField.township < 1 || currentField.township > 99) {

            errorMessageArray.push("Township must be a number between 1 and 99")
        }
    }

    return errorMessageArray;
}


