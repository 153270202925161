import moment from "moment";
import React from "react";
import { Alert, Platform } from "react-native";
import Config from "../../config";
import AppContext from "../../Contexts/AppContext";
import { GetAsyncDataParsed, GetData, DateKeys } from "../AsyncStorageHelper";
import { storeError } from "./OfflineSentry";
export async function ApiCall<T>(link: string, body?: FormData, debug?: boolean, ignoreOfflineStatus?: boolean): Promise<T>;
export async function ApiCall<T>(link: string, body: object, debug?: boolean, ignoreOfflineStatus?: boolean): Promise<T>
export async function ApiCall<T>(link: string, body: string, debug?: boolean, ignoreOfflineStatus?: boolean): Promise<T>

/**
 * A generic function for calling the api, it takes in a link and an optional body for the POST request.
 * Takes in a type that is used for the return type for the api call.
 * @param link The URL to send the api request to
 * @param body Any parameters needed by the api function can be added to the FormData
 * @returns A promise containing the generic type passed into the function.
 */
export async function ApiCall<T>(route: string, body?: any, debug = false, ignoreOfflineStatus = false): Promise<T> {
    const offlineStatus = await GetAsyncDataParsed<boolean>("isOffline");

    const link = Config.apiUrl + route;

    if (!ignoreOfflineStatus && offlineStatus) {
        console.error(`Calling api while offline: ${link}`);
        return null;
    }
    console.log(link);



    const myHeaders = new Headers();

    myHeaders.append('token','$2y$12ri75v9amOGZgTs2oTK3mZe1YEOburB7BJfJ64M9QKAM5YTggvoPVa');
    myHeaders.append('Accept', 'multipart/form-data, application/json');

    //If we are dealing with syncing JSON objects we need to add the Content Type 'application/json to the list of headers'
    //Any API Call that uses the multipart/formdata header does not need the content type
    if(typeof body === 'string')
    {
        myHeaders.append('Content-Type','application/json');
    }

    let requestOptions: { method: 'POST', headers: Headers, body?: string | FormData } = {
        method: 'POST',
        headers: myHeaders,
        body: body
    };

    // if (body != null) {

    //     if(typeof body === 'string')
    //     {
    //         requestOptions.body = JSON.stringify(body, (key, value) => {
    //             if(DateKeys.indexOf(key) > -1 && value != null ) {
    //                 //convert the dates to an sql acceptable format
    //                 return moment.utc(value).utc().format("YYYY-MM-DD HH:MM:SS");
    //             }
    //             return value;
    //         });
    //     }
  
    // }


    try
    {
        const apiCall = (): Promise<T> => fetch(link, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            storeError('API call error while calling ' + ` ${link}\n` + error);
            console.log('API CALL ERROR: ', error,
                `\n\tError occured while calling ${link}`);
            return null;
        });

        const wasLoginSuccessful = await apiCall();
      return wasLoginSuccessful;
    }
    catch(error)
    {
        console.log('...This again, ' + error);
        return null;
    }

}

/**
 * Parses an integer from a string and checks for NaN
 * @param text Text to parse int from
 * @param base The default value that should be returned if NaN
 * @returns The parsed number or the base
 */
export function safeParseInt(text: string, base = 0): number {
    const parsed = parseInt(text);
    return isNaN(parsed) ? base : parsed;
}


/**
 * Converts number to string and checks for NaN
 * @param num Number to convert to string
 * @returns Returns the number as a string or an empty string if NaN
 */
export function safeToString(num: number | null): string {
    return !isNaN(num) && num != null ? num.toString() : '';
}


//Find index of where to place sorted value, instead of having to sort the entire array, 
//we just need to find the right spot to place element
export function SortedIndex(array, value) {
    var low = 0,
        high = array.length;

    while (low < high) {
        var mid = (low + high) >>> 1;
        if (array[mid] > value) low = mid + 1;
        else high = mid;
    }
    return low;
}

/**
 * Generates a random integer in a given range
 * @param min Low end of range (inclusive)
 * @param max Max end of range (inclusive)
 * @returns A random integer in the given range
 */
function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Generates a random negative number prefixed with the current user's id if available.
 * @returns A negative 6 digit number
 */
export async function GenerateNumberID(): Promise<number> {
    let userID = (await GetData("ID")) ?? "";

    // We want the ID to be 6 digits, this random number should be 6 - userID.length digits long
    const length = 6 - userID.length;
    const rand = getRandomInt(Math.pow(10, length - 1), parseInt("".padEnd(length, "9"))).toString();

    return Number.parseInt(`-${userID}${rand}`);
}

export function GenerateNumberIDSync(userID: number | string): number {
    const user_id = typeof userID === "string" ? userID : userID.toString();

    const length = 6 - user_id.length;
    const rand = getRandomInt(Math.pow(10, length - 1), parseInt("".padEnd(length, "9"))).toString();

    return Number.parseInt(`-${user_id}${rand}`);
}

/**
 * Simple helper method to check if an id is not a placeholder id
 * @param id A number representing an id
 * @returns True if the id is greater than 0 or less than -10 (for temporary ids)
 */
export function ValidID(id: number): boolean {
    return id != null && (id > 0 || id < -10);
}

export function toJsonSet(aset: any[] | Set<any>): string {
    return JSON.stringify([...new Set(aset)].sort()); 
}

export function FormatErrorMessages(errors:string[]):string
{
    let fullString = ``;
    errors.forEach(error => {
      fullString += error + `\n\n`;
    });  
    return fullString;
}

export function AlertError(title:string,errorMessages:string[],appContext:any)
{
  const fullString = FormatErrorMessages(errorMessages);
    appContext.setAlertOptions({title:title,desc:fullString,options:[
        {text:'OK',role:'PRIMARY',onPress:() => { appContext.setShowAlert(false);}}
      ]});
       appContext.setShowAlert(true);
}



export function GroupBy(array,f)
{
    let groups = {};
    array.forEach(function(o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group]||[];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function(group) {
        return groups[group];
    })
}