import * as React from 'react';
import {GetData} from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, TouchableOpacity, Alert, Image} from 'react-native';
import Toast from 'react-native-tiny-toast';
import OverlayPicker from '../../HelperScreens/OverlayPicker';
import { addFieldSelector, defaultField } from '../../../Helpers/Storage/DefaultStates';
import { Field } from '../../../Helpers/Storage/UserState';
import { AddField, ValidateField } from '../../../Helpers/Functions/Offline/FieldFunctions';
import spacing from '../../../Styles/Spacing';
import textStyles from '../../../Styles/Text';
import VGInput from '../../../Components/Input/Input';
import FieldForm from '../../../Components/Forms/FieldForm';
import VGButton from '../../../Components/Button/Button';
import AppContext from '../../../Contexts/AppContext';

//This function houses a modal with is used to add a new location to the profile's location data
function AddNewSampleField(props: {fields:Field[], currentSampleFields:Field[],isAddingField:boolean,OnSavedField:(field:Field)=>void,OnCancel:()=>void})
{

    const appContext = React.useContext(AppContext);
    const [currentField,setCurrentField] = React.useState<Field>({...defaultField});
    

    async function onSavePress(){

        try {
            const profile = await GetData('Profile');
            //Check if field is valid, if it is, add it
            let status = ValidateField(currentField);

            let returnField;
            if (status) {
                returnField = await AddField(currentField, profile);
                //as long as the returned field is not null, then add to the list
                if (returnField != undefined) {
                    props.OnSavedField(returnField);

                }
                else {
                    Toast.show('Could not add field');
                }
            }



        }
        catch (error) {
            appContext.setAlertOptions({title:'Could not add field',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
        
                    appContext.setShowAlert(false);
                  }}
              ]});
               appContext.setShowAlert(true); 
        }

   }

       return (

        <View style={spacing.Modal}> 
            <View style={spacing.ScreenHeader}>
                <Text style={textStyles.title}>Add Field</Text>    
            </View>  

           <FieldForm currentField={currentField} setCurrentField={setCurrentField} />
          
           <VGButton role="PRIMARY" 
           onPress={onSavePress}>Save</VGButton>

           <VGButton role="CANCEL"  
           onPress={props.OnCancel}
           >Close</VGButton>


           

           {/* </View> */}

           
          
           </View>
    )
}


export default AddNewSampleField