import { useIsFocused } from "@react-navigation/native";
import moment from "moment";
import React from "react";
import { Dimensions, View, Text, FlatList, TouchableOpacity, Alert } from "react-native";
import { Overlay } from "react-native-elements";
import { GetData, GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import { ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import { CheckValidGrain, RedirectToStore } from "../../../Helpers/Functions/Online/AnalysisFunctions";
import * as FileSystem from 'expo-file-system';
import { GetMasterSampleRecord } from "../../../Helpers/Functions/Offline/SampleFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { addBuyerSelector, defaultBuyer, defaultEnterprise, defaultUserInstrument } from "../../../Helpers/Storage/DefaultStates";
import { Buyer, CharacteristicSubOption, CharacteristicType, Enterprise, GrainSubCrop, GrainType, InstrumentModel, Lab, MasterSample, SampleRecordCharacteristic, SubSample, UserInstrumentModel } from "../../../Helpers/Storage/UserState";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import { CombinedAnalysisRecords } from "../PrimarySampleScreens/SampleListScreen";
import BuyerSamplePicker from "../SampleDataPickers/BuyerSamplePicker";
import CharacteristicsSelectorModal from "../StackModals/Characteristics/CharacteristicsSelectorModal";
import { GetBuyers, GetEnterprises } from "../../../Helpers/Functions/Offline/BuyerFunctions";
import VGButton from "../../../Components/Button/Button";
import AppContext from "../../../Contexts/AppContext";
import UpdatedInstrumentScreen from "../../DrawerScreens/UpdatedInstrumentScreen";
import Toast from "react-native-tiny-toast";

export type AnalysisTypeOptions = { name: "Buyer/Broker" | "Lab" | "User Instrument" };

function AddNewSampleCharacteristics(props: { addFromAnalysis?: boolean, analysisRecords?: CombinedAnalysisRecords[], setAnalysisRecords?: setProp<CombinedAnalysisRecords[]>, grain:{
    grainType: GrainType;
    grainSubCrop: GrainSubCrop;
}, labs: Lab[], setLabs: any,buyers:Buyer[],setBuyers:any,enterprises:Enterprise[],setEnterprises:any, userInstruments: InstrumentModel[], setUserInstruments: setProp<InstrumentModel[]>, sample: MasterSample, setPickingData: any, sampleCharacteristics: SampleRecordCharacteristic[], setSampleCharacteristics: any, characteristicTypes: CharacteristicType[], characteristicSubOptions: CharacteristicSubOption[], selectedSampleChar?: SampleRecordCharacteristic, setSelectedSampleChar?: setProp<SampleRecordCharacteristic>,OnRefresh?:Function }) {

    const appContext = React.useContext(AppContext);
    const isFocused = useIsFocused();
    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height

    const enum dataPick {
        Default,
        PickingSource,
        PickingCharacteristics,
        PickingLab,
        PickingUserInstrument,
        PickingBuyer,
        InstrumentMasterList
    };
    const [pickingData, setPickingData] = React.useState<dataPick | null>(dataPick.PickingSource);
    const analysisTypes = ['Buyer/Broker', 'Lab', 'User Instrument'] as const;
    const [analysisType, setAnalysisType] = React.useState<AnalysisTypeOptions>({ name: null });
    const [cachedEnterpriseImages,setCachedEnterpriseImages] = React.useState<{enterprise_id:number,fileUri:string}[]>([]);

    const [selectedLab, setSelectedLab] = React.useState<Lab>({ city: '', created_at: null, id: -2, name: '', province: '', redirect_link: '', title: '', updated_at: null });
    const [selectedBuyer, setSelectedBuyer] = React.useState<Buyer>({ ...defaultBuyer });
    const [selectedEnterprise, setSelectedEnterprise] = React.useState<Enterprise>({ ...defaultEnterprise });
    const [selectedUserInstrument, setSelectedUserInstrument] = React.useState<InstrumentModel>({brand_id:-2,created_at:null,id:-2,internet_option:'Internet',model_name:'',profile:'',updated_at:null,web_link:''});

    const [filteredCharacteristics, setFilteredCharacteristics] = React.useState<{ types: CharacteristicType[], options: CharacteristicSubOption[] }>({ types: [], options: [] });

    const [buyers, setBuyers] = React.useState<Buyer[]>([]);
    const [enterprises, setEnterprises] = React.useState<Enterprise[]>([]);


    React.useEffect(() => {
        try {
            const GetBuyerData = async() => {
                let buyerList = await GetBuyers();
                buyerList.sort((a, b) => a.name.localeCompare(b.name));
    
    
                //Find the enterprise account if a user is a grower account tied to an enterprise
                 let enterpriseData = await GetEnterprises(); 
                 if(enterpriseData != null)
                        {
    
                            let cachedImages:{enterprise_id:number,fileUri:string}[] = []
    
                            for(let enterprise of enterpriseData)
                            {
                                //const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;
                    
                                const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
                             
                                const image = await FileSystem.getInfoAsync(path);
            
                                if(image.exists)
                                {
                                    cachedImages.push({enterprise_id:enterprise.id,fileUri:image.uri});
                                }
                       
               
                            }
              
                         setCachedEnterpriseImages(cachedImages);
                             
                            
                                enterpriseData.forEach(enterprise => {
                                    buyerList.unshift({id:enterprise.id,name:enterprise.name,created_at:null,expire_date:null,profile:'',updated_at:null,buyer_email:enterprise.enterprise_email,location:enterprise.location});
                                })
                            setEnterprises(enterpriseData);
                        }
                buyerList.push(addBuyerSelector);
                setBuyers([...buyerList]);
            }
            GetBuyerData();
          
        }
        catch
        {
            setBuyers([addBuyerSelector]);
        }
    }, [isFocused]);

    React.useEffect(() => {
        if (analysisType.name != null) {
            switch (analysisType.name) {
                case 'Buyer/Broker':
                    setPickingData(dataPick.PickingBuyer);
                    break;
                case 'Lab':
                    setPickingData(dataPick.PickingLab);
                    break;
                case 'User Instrument':
                    setPickingData(dataPick.PickingUserInstrument);
                    break;
            }
        }
    }, [analysisType]);

    React.useEffect(() => {

        if (ValidID(selectedUserInstrument.id)) {
            const loadChars = async () => {
                const user_instrument_chars = (await GetOfflineStateParsed("instrument_characteristics")).filter(x => x.instrument_model_id === selectedUserInstrument.id);
     
                const sub_ids = new Set(user_instrument_chars.map(x => x.characteristic_sub_id));

                const char_subs =  (await GetOfflineStateParsed("characteristic_sub_options")).filter(x => sub_ids.has(x.id));
                const type_ids = new Set(char_subs.map(x => x.characteristic_type_id));
                const types = props.characteristicTypes.filter(x => type_ids.has(x.id));
                const options = props.characteristicSubOptions.filter(x => sub_ids.has(x.id));

                setFilteredCharacteristics({ types, options });
                setPickingData(dataPick.PickingCharacteristics);
            }

            loadChars();
        }
    }, [selectedUserInstrument]);

    React.useEffect(() => {

        if (ValidID(selectedBuyer.id)) {
            
            if(enterprises.find(x => x.id == selectedBuyer.id && x.name == selectedBuyer.name))
            {
                setSelectedEnterprise(enterprises.find(x => x.id == selectedBuyer.id && x.name == selectedBuyer.name));
                setSelectedBuyer({...defaultBuyer});
            }
            setFilteredCharacteristics({types:props.characteristicTypes,options:props.characteristicSubOptions});
            setPickingData(dataPick.PickingCharacteristics);
        }
    }, [selectedBuyer]);



    //Comes into effect when a lab is selected
    React.useEffect(() => {
        //If the lab is not null 
        if (selectedLab.id > 0) {
            console.log('we labbing');
            //if the user did not choose the sample analysis option, we simply open the characteristics screen
            if (!props.addFromAnalysis) {
                setFilteredCharacteristics({types:props.characteristicTypes,options:props.characteristicSubOptions});

                setPickingData(dataPick.PickingCharacteristics);
            }
            else {
                const getOrderLookup = async () => {

                  
                        //is it a valid lab-grain combination?
                        let validGrain =  CheckValidGrain(selectedLab.id, props.grain.grainType.id);
                        //if so proceed
                        if (validGrain != null) {

                            const barcode = props.sample.barcode?.toString();

                            const profile = await GetData('Profile');

                            const userID = await GetData('ID');

                            const sampleID = props.sample.id.toString().padStart(8, '0');

                            const momentDate = moment().add(2, 'hours').unix();

                            //If in dev environment, display toast instead for testing on the redirect link + grain name,useful for testing purposes
                            if(__DEV__)
                            {
                                props.setPickingData(null);
                                appContext.setAlertOptions({title:'Success',desc:'Valid Lab-Grain Combination was found',options:[
                                    {text:'OK', role:'PRIMARY', onPress:() => {
                                        props.setPickingData(null);
                                        appContext.setShowAlert(false);
                                      }}
                                  ]});
                                   appContext.setShowAlert(true); 
                            }
                            else
                            {
                                props.setPickingData(null);
                                RedirectToStore(selectedLab, validGrain.grainType.name, validGrain.lab.redirect_link, sampleID, barcode, userID, profile, momentDate);
                            }
                            
                        }
                        else {
                            appContext.setAlertOptions({title:'No grain pricings for this lab',desc:'"Please contact VeriGrain for information on analyses for this grain type.” \n\n Email: sales@verigrain.com \n Phone: 833-630-2550.',options:[
                                {text:'OK', role:'PRIMARY', onPress:() => {
                                    props.setPickingData(null);
                                    appContext.setShowAlert(false);
                                  }}
                              ]});
                               appContext.setShowAlert(true); 
         

                        }
                    

                    //Open the store
                    console.log('Opening the store');
                }
                getOrderLookup();
            }
        }
    }, [selectedLab])


    React.useEffect(() => {
        if (!isFocused) {
            console.log('Closing AddSampleCharacteristics Screen');
        }
    }, [isFocused]);

    React.useEffect(() => {
        return () => {
            if(props.selectedSampleChar != null && props.setSelectedSampleChar != null) {
                props.setSelectedSampleChar(null);
            }
        }
    }, []);
    let renderSeparatorView = () => {
        return (
            <View style={{
                height: 2,
                width: width,
                backgroundColor: "orange",
            }}
            />
        );
    };

    //This function will open the master list of user instruments to select from
    function OnAddFromMasterList() {
        console.log('hi');
        setPickingData(dataPick.InstrumentMasterList);
    }

    function OnSaveInstrumentMasterList() {
        setPickingData(null);
        props.setPickingData(null);
        props.OnRefresh();
    }

    return (
        <>

            {pickingData == dataPick.PickingSource || pickingData == dataPick.Default ?
                <Overlay onDismiss={() => {
                    setPickingData(dataPick.PickingSource);
                    props.setPickingData(null);

                }} isVisible={pickingData == dataPick.PickingSource} onBackdropPress={() => { props.setPickingData(null) }}>

                    <View style={{ width: width / 1.2, height: height / 1.5 }}>

                        <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                            <Text style={{ alignSelf: 'center', fontSize: 28 }}>Select Source</Text>
                        </View>


                        <FlatList
                            ItemSeparatorComponent={renderSeparatorView}
                            contentContainerStyle={{ justifyContent: 'space-evenly', alignContent: 'flex-end' }}
                            data={analysisTypes}
                            renderItem={({ item }) =>


                                <TouchableOpacity accessibilityLabel={item} onPress={() => {
                                    setAnalysisType({ name: item });
                                    //  setPickingData(dataPick.PickingCharacteristics)
                                }} >
                                    <Text style={{ fontSize: 24, padding: 10 }}>{item}</Text>
                                </TouchableOpacity>
                            }
                            keyExtractor={(item, index) => index.toString()}
                        >
                        </FlatList>
                    </View>



                    <View>
                        <VGButton role="CANCEL" onPress={() => {
                            props.setPickingData(null);
                           setPickingData(null);
                        }}>Cancel</VGButton>
                    </View>
                </Overlay>

                : null}

{pickingData == dataPick.PickingBuyer ? <BuyerSamplePicker canAdd={false} cachedEnterpriseImages={cachedEnterpriseImages} enterprise={enterprises}  buyers={buyers} setCurrentBuyer={setSelectedBuyer} currentBuyer={selectedBuyer} setBuyers={setBuyers} setPickingData={setPickingData} pickingData={pickingData == dataPick.PickingBuyer} /> : null}

            {pickingData == dataPick.InstrumentMasterList ? 
            
            <Overlay isVisible={pickingData ==dataPick.InstrumentMasterList}>
                <View style={{width:width/1.2,height:height/1.2}}>
                <UpdatedInstrumentScreen fromSample={true} OnClose={OnSaveInstrumentMasterList} /> 

                </View>
            </Overlay>
          : null}
            {pickingData == dataPick.PickingLab ? <OverlayPicker type={['name']} text={'Pick a Lab'} isVisible={pickingData == dataPick.PickingLab} setVisible={(_visible: boolean) => setPickingData(dataPick.PickingSource)} setData={setSelectedLab} data={props.labs} />: null}
            {pickingData == dataPick.PickingUserInstrument ? <OverlayPicker noItemMessage="Please add instruments from master list" type={['model_name']} text={'Pick an Instrument'} isVisible={pickingData == dataPick.PickingUserInstrument} setVisible={(_visible: boolean) => setPickingData(dataPick.PickingSource)} setData={setSelectedUserInstrument} data={props.userInstruments} addingNewItem={true} addingNewItemButtonName="Add Instrument From Master list" onAddFromMasterList={OnAddFromMasterList} />: null}

            {pickingData == dataPick.PickingCharacteristics ?
                <CharacteristicsSelectorModal  addFromAnalysis={props.addFromAnalysis}  analysisType={analysisType} charOptions={filteredCharacteristics} isVisible={pickingData == dataPick.PickingCharacteristics} mode={'Sample'} sampleChars={props.sampleCharacteristics} selectedSample={props.sample} setSampleChars={props.setSampleCharacteristics} setVisible={(_visible: boolean) => {setPickingData(dataPick.PickingSource); props.setPickingData(null)}} selectedBuyer={selectedBuyer}
                    selectedEnterprise={selectedEnterprise} selectedLab={selectedLab} selectedUserInstrument={selectedUserInstrument} readonly={false}
                />
                : null}


        </>
    );
}

export default AddNewSampleCharacteristics;

