import * as React from 'react';
import { DrawerActions, useIsFocused } from '@react-navigation/native';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, Image, TouchableOpacity, DeviceEventEmitter, Dimensions } from 'react-native';
import styles from '../../../Styles/styles'
import { FlatList } from 'react-native-gesture-handler';
import Spinner from 'react-native-loading-spinner-overlay';
import { GetDeletedFields, GetFields } from '../../../Helpers/Functions/Offline/FieldFunctions';
import { Field } from '../../../Helpers/Storage/UserState';
import AddFieldScreen from './AddFieldScreen';
import UpdateFieldScreen from './UpdateFieldScreen';
import RestoringFieldScreen from './RestoringFieldScreen';
import { addFieldSelector } from '../../../Helpers/Storage/DefaultStates';
import { useContext } from 'react';
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';
import { GetLocalUserManual } from '../../../Helpers/Functions/Online/UserFunctions';
import AppContext from '../../../Contexts/AppContext';
import VGButton from '../../../Components/Button/Button';
import Toast from 'react-native-tiny-toast';

function FieldsScreens({ navigation }: { navigation: any }) {
    let isFocused = useIsFocused();
    const currentContext = useContext(AppContext);
    //Reset the screen if the user switches to a different menu
    React.useEffect(() => {

        if (!isFocused) {
            setIsAddingField(false);
            setIsModifyingField(false);
            setIsRestoring(false);

        }
        else
        {
            const getPages = async() => {
                                        
                let data =await GetLocalUserManual();
                if(data != null)
                {
                  currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'FieldMenu'))
                }
                else
                {
                  currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='FieldMenu'));
                }
              };
              getPages(); 
        }
    }, [isFocused])
    //grab the width and height of the screen
    const width = Dimensions.get('window').width; //full width

    //Same as other screens, once the user taps on the hamburger menu it will toggle the drawer
    DeviceEventEmitter.addListener('OpenMenu', OpenDrawer);
    function OpenDrawer() {
        navigation.dispatch(DrawerActions.openDrawer())
    }
    //States to keep track of
    const [fields, setFields] = React.useState<Field[]>([]);
    const [IsAddingField, setIsAddingField] = React.useState(false);
    const [isModifyingField, setIsModifyingField] = React.useState(false);
    const [currentField, setCurrentField] = React.useState<Field>({ id: 0, created_at: null, expire_date: null, updated_at: null, profile: '', meridian: '', name: '', quarter: '', rangev: 0, section: 0, township: 0 });

    const [isLoading, setIsLoading] = React.useState(false);

    const [isRestoring, setIsRestoring] = React.useState(false);
    const [deletedFields, setDeletedFields] = React.useState<Field[]>([]);
    const [checkedFields, setCheckedFields] = React.useState<Field[]>([]);

    //This useEffect will grab the data before rendering the page
    //this means we dont need to use a button to grab data!
    React.useEffect(() => {
        const GrabFieldData = async () => {
            //updatw

            //set loader, disable user input
            setIsLoading(true);
            //grab fields 
            try {
                let profile = GetData('Profile');
                const data: Field[] = await GetFields();
                setFields(data);

                const delFields:Field[] = await GetDeletedFields(profile);
                setDeletedFields(delFields);
            }
            catch
            {
               Toast.show('No Fields could be found');
            }

            setIsLoading(false);


        }
        GrabFieldData();
    }, [])


    async function onRestorePress(){

        let data =await GetLocalUserManual();
        if(data != null)
        {
          currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'RestoreObjects'))
        }
        else
        {
          currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='RestoreObjects'));
        }
        
        setIsRestoring(true);
    }


    return (
        <View style={{ justifyContent: 'space-evenly', flex: 1, marginHorizontal: 10 }}>

            <Spinner
                visible={isLoading}
                textContent={'Loading'}
                cancelable={false}
                textStyle={styles.spinner}
            />

            {isRestoring ?
                <RestoringFieldScreen fields={fields} setFields={setFields} checkedFields={checkedFields} setCheckedFields={setCheckedFields} deletedFields={deletedFields} setDeletedFields={setDeletedFields} isRestoring={isRestoring} setIsRestoring={setIsRestoring} />
                : null}




            {IsAddingField ?
                <AddFieldScreen currentField={currentField} setCurrentField={setCurrentField} fields={fields} setFields={setFields} isAddingField={IsAddingField} setIsAddingField={setIsAddingField} />
                : null}


            {isModifyingField ?
                <UpdateFieldScreen currentField={currentField} deletedFields={deletedFields} setDeletedFields={setDeletedFields} setCurrentField={setCurrentField} fields={fields} setFields={setFields} isModifyingField={isModifyingField} setIsModifyingField={setIsModifyingField} />
                : null}




            {!isModifyingField && !IsAddingField && !isRestoring ?
                <View style={{ flex: 1, justifyContent: 'space-evenly', marginHorizontal: 10, marginTop: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                        <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Fields</Text>
                    </View>

                    <FlatList
                        data={fields.filter(x => x.expire_date == null)}
                        renderItem={({ item }: { item: Field }) =>
                          

                                    <View style={{ flexDirection: 'row', borderWidth: 3, borderColor: 'lightgrey', marginVertical: 3, alignSelf: 'stretch' }}>
                                        <TouchableOpacity
                                        accessibilityLabel={item.name}
                                            style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignSelf: 'center', padding: 5 }}
                                            onPress={() => {
                                                setCurrentField(item);
                                                setIsModifyingField(true);
                                            }} >
                                            <Image style={{
                                                width: 30, height: 30, borderRadius: 5,
                                                overflow: 'hidden'
                                            }} source={require('../../../Icons/BlueField.png')} />

                                            <Text style={{ fontSize: 16, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5 }}>{item.name}</Text>
                                        </TouchableOpacity>
                                    </View>
                       
                        }
                        keyExtractor={(item, index) => index.toString()}
                    >
                    </FlatList>

                    <VGButton role="PRIMARY" onPress={()=>{ setIsAddingField(true);}}
                    >Add New Field</VGButton>

                    <VGButton role="SECONDARY" onPress={onRestorePress}
                    >Restore Deleted Fields</VGButton>

                </View>

                : null}
        </View>
    );
}

export default FieldsScreens