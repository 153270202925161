import { Alert } from "react-native";
import Toast from "react-native-tiny-toast";
import { GetData, GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { Bin, BinLocation, BinMake, BinModel, BinVolumeHistory, BinWallType, GrainSubCrop, GrainType, MasterSample, StorageType } from "../../Storage/UserState";
import { GenerateNumberID, ValidID } from "../GeneralFunctions";
import { ApiResponse } from "../../GeneralTypes";
import { defaultBin, defaultGrain } from "../../Storage/DefaultStates";
import { ApiCall } from "../GeneralFunctions";

//This method will attempt to grab the locations based on the users profile
//returns an array of locations
export function GetLocations():BinLocation[] {


let locationArray :BinLocation[]= GetOfflineStateParsed('bin_locations');

locationArray.sort(function (a, b) {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;
  const aA = a.location.replace(reA, "");
  const bA = b.location.replace(reA, "");
  if (aA === bA) {
      const aN = parseInt(a.location.replace(reN, ""), 10);
      const bN = parseInt(b.location.replace(reN, ""), 10);
      return aN === bN ? 0 : aN < bN ? 1 : -1;
  } else {
      return aA < bA ? 1 : -1;
  }
})

  return locationArray;
}

export async function UpdateBinLocation(updated_location: BinLocation): Promise<boolean> {
  if (updated_location.profile != null && updated_location.location != 'Add New Location' && updated_location.location.length != 0) {
    const status = await AddToQueue("bin_locations", updated_location, "update");

    return status;
  }

  return false;
}


export async function DeleteBin(to_delete: Bin): Promise<boolean> {

    const status = await AddToQueue("bins", { ...to_delete, expired:1 }, "update");

    return status;
  

}


export async function AddLocation(location: BinLocation): Promise<BinLocation> {
  const to_add = { ...location };
  to_add.profile = await GetData("Profile");
  to_add.id = await GenerateNumberID();
  const status = await AddToQueue("bin_locations", to_add, "add");

  if (status) {
    return to_add;
  }


  return null;
}

export async function GetDeletedBinLocations(profile: string): Promise<BinLocation[]> {
  if (profile != null) {
    const deleted_bins = (await GetOfflineStateParsed("bin_locations")).filter(x => x.profile === profile && x.expired == 0);

    return deleted_bins;
  }

  return null;
}



export async function UpdateBinDetails(bin: Bin, binLocation: BinLocation): Promise<boolean> | null {
      bin.location_id = binLocation.id;

      const status = await AddToQueue("bins", {
        ...bin,
        location_id: binLocation.id,
      }, "update");

      if (status) {
        Toast.show("Updated Bin!")

        return true
      }
  return false;
}

export async function SaveBinDetails(bin: Bin, profile: string): Promise<Bin> | null {

    if (profile != null) {

      const to_add = {
        ...bin,
        id: await GenerateNumberID(),
        profile: profile,
      }

      const status = await AddToQueue("bins", to_add, "add");

      if (status) {
        return to_add;
      }
      else {
        return null;
      }
    }
  return null
}


//This function will take a list of deleted bins and restore them
export async function RestoreDeletedBins(deletedBins: Bin[], profile: string): Promise<Bin[]> {

  if (profile != null) {
    const restoredBins = [];

    for (const bin of deletedBins) {
      if (bin.expired != null && bin.id != 0) {
        bin.expired = 0;
        const status = await AddToQueue("bins", bin, "update");

        if (status) {
          restoredBins.push(bin);
        }
      }
    }

    return restoredBins;
  }


  return null;
}





export async function GetBinsByLocation(locationID: number, profile: string): Promise<Bin[]> {
  if (profile != null) {
    const binList = await GetOfflineStateParsed("bins");
    const filtered = binList.filter((x) =>  x.location_id === locationID);

    if (filtered.length > 0) {
      filtered.sort(function (a, b) {
        const reA = /[^a-zA-Z]/g;
        const reN = /[^0-9]/g;
        const aA = a.name.replace(reA, "");
        const bA = b.name.replace(reA, "");
        if (aA === bA) {
          const aN = parseInt(a.name.replace(reN, ""), 10);
          const bN = parseInt(b.name.replace(reN, ""), 10);
          return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          return aA > bA ? 1 : -1;
        }
      })
      return filtered;
    }
  }

  return null;
}


export async function GetDeletedBins(profile: string): Promise<Bin[]> {
  if (profile != null) {
    const binList = await GetOfflineStateParsed("bins");
    const filtered = binList.filter((x) =>  x.expired == 1);

    if (filtered.length > 0) {
      return filtered;
    }
  }

  return null;
}

export  function GetAllBins():Bin[] {
  let binArray: Bin[] = [];


    const binList =  GetOfflineStateParsed("bins");

  return binList;
}


export async function GetBinDataByBarcode(profile: string, barcode: string): Promise<{ location: BinLocation; bin: Bin; }> {
  const bin = (await GetOfflineStateParsed("bins")).find(x => x.barcode === barcode);
  const location = (await GetOfflineStateParsed("bin_locations")).find(x => x.id === bin.location_id);

  return { location: location, bin: bin };
}

/**
 * Gets the most recent record from the bin_volume_history
 * @param binID The id of the bin to get the volume of
 * @returns Current volume of the selected bin
 */
export async function GetCurrentBinVolume(binID: number): Promise<number | null> {
  const volume_history = await GetOfflineStateParsed("bin_volume_history");
  let current_volume: number | null = null;
  if (volume_history != null) {
    const last_record = volume_history.sort((a, b) => {
      let ret = 0;
      if (a.updated_at != null && b.updated_at != null) {
        ret = b.updated_at.getTime() - a.updated_at.getTime()
      }
      else {
        ret = b.id - a.id;
      }
      return ret;
    }
    ).find((x) => x.id == binID);
    current_volume = last_record != null ? last_record.current_volume : null;
  }

  return current_volume;
}

//This function will attempt to grab the last grain used in a bin
export function GetLastGrainUsedInBin(bin:Bin):GrainSubCrop
{

  //TODO GET CURRENT BIN VOLUME

  //If volume == 0 -> return null
  //else continue

  const lastSampleSource = GetOfflineStateParsed('sample_source').find(x => x.source_bin_id == bin.id);
  const lastSampleDestination = GetOfflineStateParsed('sample_destination').find(x => x.destination_bin_id == bin.id);

  let masterSample:MasterSample = null;
  let grainSubCrop:GrainSubCrop = null;
  if(lastSampleDestination != null && lastSampleSource != null)
  {
        if(lastSampleSource.created_at > lastSampleDestination.created_at)
        {
          masterSample = GetOfflineStateParsed('master_samples').find(x => x.id == lastSampleSource.master_sample_id);
       //   grainSubCrop = GetOfflineStateParsed('grain_sub_crops').find(x => x.id == masterSample.sub_crop_id);
        }
        else
        {
          masterSample = GetOfflineStateParsed('master_samples').find(x => x.id == lastSampleDestination.master_sample_id);
      //    grainSubCrop = GetOfflineStateParsed('grain_sub_crops').find(x => x.id == masterSample.sub_crop_id);
        }
  }
  else if(lastSampleDestination != null)
  {
    masterSample = GetOfflineStateParsed('master_samples').find(x => x.id == lastSampleDestination.master_sample_id);
        //  grainSubCrop = GetOfflineStateParsed('grain_sub_crops').find(x => x.id == masterSample.sub_crop_id);
  }
  else if(lastSampleSource != null)
  {
    masterSample = GetOfflineStateParsed('master_samples').find(x => x.id == lastSampleSource.master_sample_id);
    //grainSubCrop = GetOfflineStateParsed('grain_sub_crops').find(x => x.id == masterSample.sub_crop_id);
  }
  return grainSubCrop;
}


 export async function GetBinVolumesForLocation(location_id: number): Promise<BinVolumeHistory[]> {
  const ids = new Set(GetOfflineStateParsed("bins").filter(x => x.location_id == location_id).map(x => x.id));
  const volume_history = GetOfflineStateParsed("bin_volume_history");

  return volume_history.filter(x => ids.has(x.id));
}

export async function GetVolumeOfBin(master_sample_id: number): Promise<{ source_bin_volume: number, destination_bin_volume: number }> {

  //Track the source and destination totals
  let sourceTotal = 0;
  let destinationTotal = 0;
  //Grab the sample source/destination fields and see if it contains a bin
  const completeSourceList = await GetOfflineStateParsed("sample_source");
  const completeDestinationList = await GetOfflineStateParsed("sample_destination");

  const source = completeSourceList.find(x => x.master_sample_id === master_sample_id && x.source_bin_id != null);
  const destination = completeDestinationList.find(x => x.master_sample_id === master_sample_id && x.destination_bin_id != null);

  //If you are changing the volume of a destination, grain goes down else grain moves up...

  //We could be dealing with a potential bin->bin scenerio where we would need the volume of both bins to determine if it's feasible to switch
  if (source != null) {
    const binVolumeHistory = await (await GetOfflineStateParsed('bin_volume_history')).filter(x => x.id == source.source_bin_id).sort((a, b) => {
      if (a.created_at > b.created_at) {
        return 1;
      }
      return 0;
    });

    const sourceList = completeSourceList.filter(x => x.source_bin_id === source.source_bin_id && x.created_at > binVolumeHistory[0].created_at);
    const destinationList = completeDestinationList.filter(x => x.destination_bin_id === source.source_bin_id && x.created_at > binVolumeHistory[0].created_at);
    const fullSampleList = (await GetOfflineStateParsed('master_samples'))

    const sources = fullSampleList.filter(function (array_el) {
      return sourceList.filter(function (anotherOne_el) {
        return anotherOne_el.master_sample_id == array_el.id;
      }).length == 0
    });
    const destinations = fullSampleList.filter(function (array_el) {
      return destinationList.filter(function (anotherOne_el) {
        return anotherOne_el.master_sample_id == array_el.id;
      }).length == 0
    });

    sources.forEach(x => { sourceTotal += x.volume });
    destinations.forEach(x => { sourceTotal -= x.volume });

  }

  if (destination != null) {

    const binVolumeHistory = await (await GetOfflineStateParsed('bin_volume_history')).filter(x => x.id == destination.destination_bin_id).sort((a, b) => {
      if (a.created_at > b.created_at) {
        return 1;
      }
      return 0;
    });

    const sourceList = completeSourceList.filter(x => x.source_bin_id === destination.destination_bin_id && x.created_at > binVolumeHistory[0].created_at);
    const destinationList = completeDestinationList.filter(x => x.destination_bin_id === destination.destination_bin_id && x.created_at > binVolumeHistory[0].created_at);
    const fullSampleList = (await GetOfflineStateParsed('master_samples'))

    const sources = fullSampleList.filter(function (array_el) {
      return sourceList.filter(function (anotherOne_el) {
        return anotherOne_el.master_sample_id == array_el.id;
      }).length == 0
    });
    const destinations = fullSampleList.filter(function (array_el) {
      return destinationList.filter(function (anotherOne_el) {
        return anotherOne_el.master_sample_id == array_el.id;
      }).length == 0
    });

    sources.forEach(x => { destinationTotal += x.volume });
    destinations.forEach(x => { destinationTotal -= x.volume });

  }

  return { source_bin_volume: sourceTotal, destination_bin_volume: destinationTotal };
}

export function GetStorageTypes(): StorageType[] {
  const data =  GetOfflineStateParsed("storage_types");

  return data;
}



//returns an array of locations
export async function GetBinModelCount(profile: string, binModelID: number): Promise<number> {

  const bins = (await GetOfflineStateParsed("bins")).filter(x => x.model_id === binModelID);

  return bins.length;

}

export async function GetBinMakeModelData(storageTypeID: number | null): Promise<{ make_list: BinMake[], model_list: BinModel[] }> {
  const makes = await GetOfflineStateParsed("bin_makes");
  let models = await GetOfflineStateParsed("bin_models");

  if ( storageTypeID != null && ValidID(storageTypeID)) {
    models = models.filter(x => x.storage_type_id === storageTypeID);
  }


  return { make_list: makes, model_list: models };
}

export async function GetBinWallTypes(): Promise<BinWallType[]> {
  const wall_types = await GetOfflineStateParsed("bin_wall_types");

  return wall_types;
}

function ValidateBin(bin: Bin): string[] {
  let errors = [];
  if (bin.name == '' || bin.name.length > 40) {
    errors.push('Bin name cannot be empty, max 40 characters');
  }
  if (bin.location_id == null) {
    errors.push('A location must be selected.');
  }
  if (bin.storage_type_id == 0 || bin.storage_type_id == -1) {
    errors.push('Type cannot be empty, max 40 characters');
  }
  if (bin.size == undefined || bin.size == 0) {
    errors.push('Size must be greater than 0.');
  }

  //TODO: remove this?
  if ((bin.storage_type_id == 4 || bin.storage_type_id == 5 || bin.storage_type_id == 6) && (bin.name == '' || bin.name.length > 40)) {
    errors.push('description is required for bags or piles');
  }
  return errors;
}

export function ValidateQuickBin(tempBin: Bin): string[] {
  let errorLog: string[] = [];
  if (tempBin.name == '' || tempBin.name.length > 40) {
    errorLog.push('Bin name cannot be empty, max 40 characters');
  }
  if (tempBin.location_id == null || tempBin.location_id == 0) {
    errorLog.push('Must select a location.');
  }
  if (tempBin.storage_type_id == null || tempBin.storage_type_id == 0) {
    errorLog.push('Type cannot be empty, max 40 characters');
  }
  if (tempBin.size <= 0) {
    errorLog.push('Size cannot be empty,size cannot be 0');
  }
  return errorLog;
}

export function ValidateLocation(location:BinLocation):string[]
{
  let errorLog:string[] = [];

  if(location.location == '' || location.location == null)
  
  return errorLog;
}

export function ValidateBoardBin(currentBinBoardSpec: Bin): string[] {
  let errorLog: string[] = [];

  if(currentBinBoardSpec.model_id <= 0 && currentBinBoardSpec.diameter <= 0 && currentBinBoardSpec.wall_tier <= 0 && currentBinBoardSpec.wall_type_id <= 0 )
  {
      return [];
  }
  if (currentBinBoardSpec.model_id <= 0) {
    console.log(currentBinBoardSpec.model_id + 'Mpdel ID');
    errorLog.push('A Manufacturer and model must be selected');
  }
  if (currentBinBoardSpec.diameter <= 0 || currentBinBoardSpec.diameter == null) {
    errorLog.push('A Diameter is required');
  }
  if (currentBinBoardSpec.wall_tier <= 0) {
    errorLog.push('A Wall Tier is required');
  }
  if (currentBinBoardSpec.wall_type_id == 0 || currentBinBoardSpec.wall_type_id == null) {
    errorLog.push('A Wall Type is required');
  }

  return errorLog;
}



export async function SaveBin(bin:Bin,profile:string): Promise<{bin:Bin}|null>
{

  try
  {
    let binReturnStatus: {bin:Bin} = {bin:null}
    if (profile != null) {

      const to_add_bin:Bin = {
        ...bin,
        id: await GenerateNumberID(),
      }

      const binStatus = await AddToQueue("bins", to_add_bin, "add");

      if (binStatus) {
        binReturnStatus.bin = to_add_bin;
      }
      else {
        return null;
      }
    }
    return binReturnStatus;
  }
  catch(error)
  {
    return null;
  }



}


export  function UpdateBin(bin:Bin):{bin:Bin}
{

   let binReturnStatus: {bin:Bin} = {bin:null}

   const updated = AddToQueue("bins", bin, "update");

   if(updated)
   {
      binReturnStatus.bin = bin;

   }
   return binReturnStatus;


}


export function CalculateBinImage(min: number, max: number, type: string, cap: 'in' | 'out'):string {
  //do the calculation and it up into 8ths to see which image we need
  let minVol = min;
  let maxVol = max;
  let calcVol = minVol / maxVol;
  let binImage = 'FlatbottomTemplate';
  let last;
  console.log(calcVol);
  switch (true) {

    case (calcVol > 0 && calcVol < 0.25): 
    binImage = type == 'flatbottom' ? 'FlatbottomOneIn' : 'HopperOneOut'; //1/8
      break;

    case (calcVol >= 0.25 && calcVol < 0.375): 
    binImage = type == 'flatbottom' ? 'FlatbottomTwoOut' : 'HopperTwoIn'; // 2/8
      break;

    case (calcVol >= 0.375 && calcVol < 0.5): 
    binImage = type == 'flatbottom' ? 'FlatbottomThreeOut' : 'HopperThreeIn'; //3/8
      break;

    case (calcVol >= 0.5 && calcVol < 0.625): 
    binImage = type == 'flatbottom' ? 'FlatbottomFourOut' : 'HopperFourIn'; //4/8
      break;

    case (calcVol >= 0.625 && calcVol < 0.75): 
    binImage = type == 'flatbottom' ? 'FlatbottomFiveIn' : 'HopperFiveOut'; //5/8
      break;

    case (calcVol >= 0.75 && calcVol < 0.875): 
    binImage = type == 'flatbottom' ? 'FlatbottomSixIn' : 'HopperSixOut'; //6/8
      break;

    case (calcVol >= 0.875 && calcVol < 1 ): 
    binImage = type == 'flatbottom' ? 'FlatbottomSevenIn' : 'HopperSevenOut'; //7/8
      break;

    case (calcVol >= 1.00): 
    binImage = type == 'flatbottom' ? 'FlatbottomEight' : 'HopperEight'; //8/8
      break;
      
    default: 
    binImage = type == 'flatbottom' ? 'FlatbottomTemplate' : 'HopperTemplate';
  }
  console.log(binImage);
  return binImage;
}


export function GetBinHistory(bin:Bin):{bin:Bin,lastGrainUsed:GrainType,binHistory:{volume:number,added:boolean,sample?:MasterSample,creation_date:Date}[]}
{
  let list:{bin:Bin,lastGrainUsed:GrainType,binHistory:{volume:number,added:boolean,sample?:MasterSample,creation_date:Date}[]} = {bin:bin,lastGrainUsed:defaultGrain,binHistory:[]};

  const masterSamples = GetOfflineStateParsed('master_samples');
  const sampleSource = GetOfflineStateParsed('sample_source').filter(x => x.source_bin_id == bin.id);
  const sampleDestination = GetOfflineStateParsed('sample_destination').filter(x => x.destination_bin_id == bin.id);

  const binVolumeHistory = GetOfflineStateParsed('bin_volume_history').filter(x => x.bin_id == bin.id);

  sampleSource.forEach(source => {

    const masterSample = masterSamples.find(x => x.id == source.master_sample_id);
    list.binHistory.push({volume:masterSample.volume,added:false,sample:masterSample,creation_date:masterSample.created_at});
  })

  sampleDestination.forEach(destination => {

    const masterSample = masterSamples.find(x => x.id == destination.master_sample_id);
    list.binHistory.push({volume:masterSample.volume,added:true,sample:masterSample,creation_date:masterSample.created_at});
  })

  binVolumeHistory.forEach(history => {
    list.binHistory.push({volume:history.current_volume,added:history.current_volume >= 0?true:false,sample:null,creation_date:history.created_at});
  })

 list.binHistory = list.binHistory.sort((a,b) => 
    Date.parse(a.creation_date.toDateString())/1000 -Date.parse(b.creation_date.toDateString())/1000
  )

  const lastMaster = list.binHistory.find(x => x.sample != null);
  if(lastMaster && lastMaster?.sample != null && lastMaster?.sample?.id != null)
  {
    const sampleGrain = GetOfflineStateParsed('sample_grain_varieties').find(x => x.master_sample_id == lastMaster?.sample?.id);
    const grain = GetOfflineStateParsed('grain_types').find(x => x.id == sampleGrain?.grain_type_id);
    list.lastGrainUsed = grain??defaultGrain;
  }


return list;
}