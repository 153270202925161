
//Lookup values when the user presses the help icon on the top left of the screen
//Each value in the array is a paragraph/section
export const learnMoreLookupData = [
    {id:1,screen_name:'Login'},
    {id:2,screen_name:'PasswordReset'},
    {id:3,screen_name:'MainMenu'},
    {id:4,screen_name:'SlideOutMenu'},
    {id:5,screen_name:'StorageMenu'},
    {id:6,screen_name:'AddStorage'},
    {id:7,screen_name:'BuyerMenu'},
    {id:9,screen_name:'FieldMenu'},
    {id:10,screen_name:'GrainMenu'},
    {id:11,screen_name:'EquipmentMenu'},
    {id:12,screen_name:'EditEquipment'},
    {id:13,screen_name:'UserInfoScreen'},
    {id:14,screen_name:'AddNewUser'},
    {id:15,screen_name:'UserInstrumentMenu'},
    {id:16, screen_name:'RestoreObjects'},
    {id:17,screen_name:'MainIntelligenceMenu'},
    {id:18,screen_name:'BinBoardsetup'},
    {id:19,screen_name:'AddBinBoard'},
    {id:20,screen_name:'StorageSearchAndDisplay'},
    {id:21,screen_name:'RapidSample'},
    {id:22,screen_name:'NewSample'},
    {id:23,screen_name:'SampleAssist'},
    {id:24,screen_name:'OtherSample'},
    {id:25,screen_name:'SampleSearch'},
    {id:26,screen_name:'AddSubSample'},
    {id:27,screen_name:'CombineSample'},
    {id:28, screen_name:'SampleAnalysis'},
    {id:29,screen_name:'SamplesInProgress'},
    {id:30,screen_name:'PendingAnalysis'},
    {id:31,screen_name:'AddSampleDetails'},
    {id:32,screen_name:'BluetoothSmartSampler'},
    {id:33,screen_name:'SmartSampleScreen'},
    {id:34,screen_name:'SmartSampleTimer'},
    {id:35,screen_name:'SmartSampleStart'},
    {id:36,screen_name:'SmartSampleCompleted'},
    {id:37,screen_name:'SavingSmartSample'},
]