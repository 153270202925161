import React, { useContext } from "react";
import { View, Text, Image, TextInput, TouchableOpacity, Alert, } from "react-native";
import Toast from "react-native-tiny-toast";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { AddLocation, UpdateBinLocation } from "../../../Helpers/Functions/Offline/BinFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { Bin, BinLocation } from "../../../Helpers/Storage/UserState";
import { ListLocState } from "./BinsScreen";

import YardMapPicker from "../../StackScreens/StackModals/YardMapPicker";
import AppContext from "../../../Contexts/AppContext";
import * as geolib from 'geolib';

import { EditBinLocState } from "./EditBinLocation";
import textStyles from "../../../Styles/Text";
import VGInput from "../../../Components/Input/Input";
import Colours from "../../../Styles/Colours";
import { RequiredText } from "../../../Components/SimpleComponents";
import VGButton from "../../../Components/Button/Button";

import { FormatErrorMessages } from "../../../Helpers/Functions/GeneralFunctions";

type PropTypes = {
    type:"add";
    locations: BinLocation[];
    setLocations: setProp<BinLocation[]>;
    parentMode: ListLocState;
    setParentMode: setProp<ListLocState>;
} | {
    type:'edit';
    locations: BinLocation[];
    setLocations: setProp<BinLocation[]>;
    parentMode: EditBinLocState;
    setParentMode: setProp<EditBinLocState>;
    currentLocation: BinLocation;
    setCurrentLocation:setProp<BinLocation>;
}

/**
 * Add new Bin Location
 */
function AddingBinLocationScreen(props: PropTypes) {
    const initLoc: BinLocation = {
        id: -1,
        location: "",
        profile: "",
        expired:0,
        updated_at: undefined,
    };
    const appContext = React.useContext(AppContext);
    const theFarm = [-107.235386, 51.968342]; //TODO remove this once testing is closer to being done
    const [tempLoc, setTempLoc] = React.useState<BinLocation>(props.type == 'add' ? initLoc : props.currentLocation);
    const currentContext = useContext(AppContext);
    const [pickingLocation,setPickingLocation] = React.useState(false);
    const locationRef = React.useRef(null);

    const centerPoint = props.type == 'add' || props.currentLocation.latitude == null ? theFarm : [props.currentLocation.longitude,props.currentLocation.latitude];

    

    function getCoordStringFromCoord(coord:number[]){
        const coordString =
                    geolib.decimalToSexagesimal(coord[1]) +
                    (coord[1] > 0 ? "N " : "S ") +
                    '\n' +
                    geolib.decimalToSexagesimal(coord[0]) +
                    (coord[0] > 0 ? "E" : "W");
        
                    return coordString;
      }

    async function OnSavePress() {

        let tempLocation = tempLoc;

        const validateLocation = ValidateBinLocation(tempLocation);
        if (validateLocation.length != 0) {
            const fullString = FormatErrorMessages(validateLocation)
            appContext.setAlertOptions({title:'Invalid Location',desc:fullString,options:[
                {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}},
              ]});
               appContext.setShowAlert(true);
        }
        else {
            if(props.type == 'add'){

                try {
                    const profile = await GetData('Profile');
                    const status = await AddLocation({...tempLocation, profile});
                    
                    if (status != null) {
                        let oldLocList = props.locations;
                        
                        const newLoc = status;
        
                        oldLocList.push(newLoc);

                        props.setLocations([...oldLocList]);
                        props.setParentMode({...props.parentMode,current_mode:'list_bins',current_location:newLoc})
                        //exit to the bins list of the newly added location
              
                        
                    }
                    else {
                        Toast.show('Could not add field');
                    }
                }
                catch (err)
                {
      
                    appContext.setAlertOptions({title:'Could not add storage location',desc:'Please try again at a later time',options:[
                        {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}},
                      ]});
                       appContext.setShowAlert(true);
                }
            }
            else{

                try{
                    const profile = await GetData('Profile');
                    const status = await UpdateBinLocation({...tempLocation, profile});

                    if(status != null){
                        //get the new version of the location object in the list
                        let oldLocList = props.locations;
                        const index = oldLocList.indexOf(oldLocList.find(l => l.id == tempLocation.id));
                        oldLocList.splice(index, 1 , tempLocation)
                        props.setLocations([...oldLocList]);
                        props.setCurrentLocation(tempLocation);
                        //exit back to the bins list of the location
                        props.setParentMode({...props.parentMode,current_mode:'edit_location'})

                    }


                }
                catch(err){
                    console.log(err);
                    appContext.setAlertOptions({title:'Could not edit storage location',desc:'Please try again at a later time',options:[
                        {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}},
                      ]});
                       appContext.setShowAlert(true);                }

                
            }
    
        }
    }

    function ValidateBinLocation(location:BinLocation):string[]
    {
        let errMsg:string[] = [];
        if(location.location == null || location.location == '' || location.location.length >= 50)
        {
            errMsg.push('Name cannot be blank, max 50 characters');
        }
        return errMsg;
    }

  async  function SaveButtonPress(tl: BinLocation) {
        const validate = ValidateBinLocation(tl);
        if(validate.length == 0)
        {
            if(props.type == 'add')
            {
                const stat = await AddLocation(tl);
                if(stat != null)
                {
                    props.setLocations([...props.locations,stat]);
                    props.setParentMode({...props.parentMode,current_mode:'list_bins',current_location:stat})

                }
            }
            else
            {
                appContext.setAlertOptions({title:'Error',desc:'Could not save location',options:[
                    {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}},
                  ]});
                   appContext.setShowAlert(true);
            }
        }
        else
        {
            const formatted = FormatErrorMessages(validate);
            appContext.setAlertOptions({title:'Invalid Location',desc:formatted,options:[
                {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}},
              ]});
               appContext.setShowAlert(true);
        }
    }

    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', marginHorizontal: 10 }}>

<View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3, marginTop: 10 }}>
                <Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>{props.type == 'add' ? "Add Storage Location" : "Edit Location Details"}</Text>
    </View>

    { pickingLocation ? 

            <YardMapPicker type='location' center={centerPoint} onSave={async (coord, zoom)=>
            {
                const tl:BinLocation = {...tempLoc, longitude:coord[0], latitude:coord[1], zoom_level:zoom}
                SaveButtonPress(tl);
            }} 
            
            onCancel={()=>{
                setPickingLocation(false);
            }}/>

            :
             <View style={{flex:1}}> 
             
             <RequiredText />
             
             <VGInput label="Name" value={tempLoc.location} 
                onChangeText={(text)=>{
                    setTempLoc({...tempLoc,location:text})}}
                required
             />

    <>
                <TouchableOpacity
                 accessible={true} accessibilityLabel="Add_Location_Coordinates_Button"
                style={{ flexDirection: 'row',borderWidth: 3, borderColor: 'lightgrey', alignContent: 'center', alignSelf: 'center', alignItems: 'center',paddingVertical:5,marginVertical:10 }}>
                    <Text style={{fontSize: 18, marginLeft:5}}>Coordinates:</Text>
                    <Text style={{flex:1, fontSize: 18, marginLeft:10}}>{(props.type == 'add' && props.parentMode.current_location?.latitude == null) || (props.type=='edit' && props.currentLocation.latitude == null) 
                    ? 'Location not on Map'
                    : props.type=='add' ? getCoordStringFromCoord([props.parentMode.current_location.longitude, props.parentMode.current_location.latitude]) 
                    : getCoordStringFromCoord([props.currentLocation.longitude, props.currentLocation.latitude]) 
                    }</Text>
                </TouchableOpacity>
         </> 

<View style={{flex:1}}></View>


    <>
        <VGButton role="PRIMARY"  onPress={() => { setPickingLocation(true); }}>
            {props.type=='add' || props.currentLocation.latitude == null ? 'Add to Map' : 'Replace Location on Map'} 
        </VGButton>
    </>
      
    
            <VGButton role="SECONDARY" onPress={OnSavePress}>Save</VGButton>

            <VGButton role="CANCEL" buttonStyle={{marginBottom:10}}
            onPress={() => {
                if(props.type == 'add')
                {
                    props.setParentMode({...props.parentMode,current_mode:'list_locations'});

                }
                else
                {
                    props.setParentMode({...props.parentMode,current_mode:'edit_location'});
                }
            }}
            >Close</VGButton>
                </View>}
        

            

 

            </View>
    
        );
    





}
export default AddingBinLocationScreen

