import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView, TextInput,GestureResponderEvent} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import styles from "../../Styles/styles";


type PropType = {
    onPress:(event: GestureResponderEvent)=>void,
    testLabel?:string,
     name:string
 }

function RemoveItemButton(props: PropType) {

    return (
        <View style={styles.removeItemButton}>

        <Text style={{ fontSize: 16, alignSelf: 'center', flexWrap: 'wrap', flexShrink: 1 }}>
            {props?.name??''}   
         </Text>

        <TouchableOpacity
           accessibilityLabel={props.testLabel??'Remove_Item_Button'}
        style={{ alignSelf: 'center', paddingLeft: 5, paddingRight: 20 }} onPress={props.onPress}>
            <Icon name="minus-square" size={35} color="red" />
        </TouchableOpacity>
    </View>
    );

}
export default RemoveItemButton;