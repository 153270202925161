import { Alert } from "react-native";
import Toast from "react-native-tiny-toast";
import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { AddToQueue, GetOfflineQueue } from "../../Storage/OfflineQueue";
import { UserInstrumentModel, InstrumentModel, InstrumentCharacteristic, CharacteristicType, CharacteristicSubOption, InstrumentBrand } from '../../Storage/UserState';
import { GenerateNumberID, GenerateNumberIDSync } from "../GeneralFunctions";



export type InstrumentModelsRaw = {
    brands: InstrumentBrand[],
    instrument_models: InstrumentModel[]
    instrument_chars:InstrumentCharacteristic[],
    char_types:CharacteristicType[],
    char_sub_options:CharacteristicSubOption[]
} 

//This method is used to pull the instrument models, brands, and characteristics from the backend.
export async function GetInstrumentsAndChars(profile: string): Promise<InstrumentModelsRaw> {
    const instrument_models = (await GetOfflineStateParsed("instrument_models")).filter((x) => (x.profile == null || x.profile == profile));
    const brands = await GetOfflineStateParsed("instrument_brands");
    const instrument_chars = (await GetOfflineStateParsed("instrument_characteristics"));
    const char_types = (await GetOfflineStateParsed("characteristic_types"));
    const char_sub_options = (await GetOfflineStateParsed("characteristic_sub_options"));
    //console.log('CHARACTERISTICS: ' + JSON.stringify(instrument_chars));
    return {
        instrument_models,
        brands,
        instrument_chars,
        char_types,
        char_sub_options,
    };

}

export async function GetUserInstruments(profile: string): Promise<UserInstrumentModel[]> {
    const user_instrument_models = (await GetOfflineStateParsed("user_instrument_models")).filter(x => x.profile == null || x.profile == profile);

    if (user_instrument_models) {
        return user_instrument_models;
    }

    return null;
}

//Not used currently
// export async function GetInstruments(profile: string): Promise<InstrumentModelsRaw>{
//     const instrument_models = (await GetOfflineStateParsed("instrument_models")).filter((x) => (x.profile == null || x.profile == profile));
//     const brands = await GetOfflineStateParsed("instrument_brands");
//     return {
//         instrument_models,
//        brands
//     };
// }

//This function is used to save a user instrument model
export async function SaveUserInstrumentModels(selectedInstrumentModels: InstrumentModel[], profile: string,fromSample:boolean = false): Promise<{ userInstrumentToRemove: UserInstrumentModel[]; userInstrumentToAdd: UserInstrumentModel[]; }|null>{

    const userInstrumentModels = GetOfflineStateParsed('user_instrument_models');
    const currentQueueStatus = GetOfflineQueue();
    let userModelsToAdd:UserInstrumentModel[] = [];
    let userModelsToRemove:UserInstrumentModel[] = [];

    for(const selection of selectedInstrumentModels)
    {
        let queueStatus = -1;
        if(currentQueueStatus?.user_instrument_models != null)
        {
            queueStatus = currentQueueStatus?.user_instrument_models?.findIndex(x => x.model_id == selection.id);
        }
        const index = userInstrumentModels.findIndex( x=> x.model_id == selection.id);
        if(index == -1 && queueStatus == -1)
        {
            userModelsToAdd.push({id:await GenerateNumberID(),profile:profile,model_id:selection.id,updated_at:null});
        }
    }

    userInstrumentModels.forEach(model => {
        let queueStatus = -1;
        if(currentQueueStatus?.user_instrument_models != null)
        {
            queueStatus = currentQueueStatus?.user_instrument_models?.findIndex(x => x.model_id == model.id);

        }
        const removalIndex = selectedInstrumentModels.findIndex(x => x.id == model.model_id);
        if(removalIndex == -1 && queueStatus == -1)
        {
            userModelsToRemove.push(model);
        }
    })

    const failedAdd = await AddToQueue('user_instrument_models',userModelsToAdd,'add');

    const failedDelete = await AddToQueue('user_instrument_models',userModelsToRemove,'perm_delete');

    if(failedAdd.length > 0 || failedDelete.length > 0)
    {
        console.log('Something has failed, removing everything from queue');
        return null;
    }
    else
    {
        console.log('Successfully added to queue');

        Toast.show('SUCCESS!');
        return {userInstrumentToAdd:userModelsToAdd,userInstrumentToRemove:userModelsToRemove};
    }

}


//Used to add a new instrument brand to the db. As of now only used when requesting a new instrument. Brand should be created in code before being saved.
export async function SaveNewInstrumentBrand(brand: InstrumentBrand, profile: string): Promise<InstrumentBrand> {
    brand.id = await GenerateNumberID();
    brand.profile = profile;

    const status = await AddToQueue("instrument_brands", brand, "add");

    if (status) {
        return brand;
    }
    return null;
}

//Confused of how characteristics interact with instrument in the backend. Refer to SaveUserInstrumentModel examples
export async function SaveNewInstrumentModel(instrument: InstrumentModel,instrumentCharacteristics: CharacteristicSubOption[], profile: string): Promise<{instrumentModel:InstrumentModel,instrumentCharacteristics:InstrumentCharacteristic[]}> {
    instrument.id = await GenerateNumberID();
    instrument.profile = profile;

    const status = await AddToQueue("instrument_models", instrument, "add");

    let instrumentChars:InstrumentCharacteristic[] = [];

    for(const sub of instrumentCharacteristics)
    {
        instrumentChars.push({id:await GenerateNumberID(), characteristic_sub_id:sub.id,instrument_model_id:instrument.id,created_at:null,updated_at:null})
    }

    await AddToQueue('instrument_characteristics',instrumentChars,"add");

    if (status) {
        return {instrumentModel:instrument,instrumentCharacteristics:instrumentChars};
    }

    return null;
}





export function GetInstrumentCharacteristicOptions(instrument_id: number, profile: string): {instrument_chars:InstrumentCharacteristic[], char_types:CharacteristicType[],char_sub_options:CharacteristicSubOption[]} {
    const instrument_chars = ( GetOfflineStateParsed("instrument_characteristics")).filter(x => x.instrument_model_id == instrument_id);
    const char_sub_options = ( GetOfflineStateParsed("characteristic_sub_options")).filter(x => instrument_chars.findIndex(y => y.characteristic_sub_id == x.id));
    //const char_types = (await GetOfflineStateParsed("characteristic_types")).filter(x => instrument_chars.findIndex(y => y.characteristic_sub_id == x.id));
    const char_types = ( GetOfflineStateParsed("characteristic_types")).filter(x => char_sub_options.findIndex(y => y.characteristic_type_id == x.id));
    return { instrument_chars, char_types, char_sub_options };
}