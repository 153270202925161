import * as React from 'react';
import { Alert, Dimensions, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Overlay } from 'react-native-elements';
import VGButton from '../../Components/Button/Button';
import VGInput from '../../Components/Input/Input';
import { GetData } from '../../Helpers/AsyncStorageHelper';
import { SaveVolumeHistoryRecord } from '../../Helpers/Functions/Offline/StorageFunctions';
import { setProp } from '../../Helpers/GeneralTypes';

import spacing, { width, height } from '../../Styles/Spacing';
import textStyles from '../../Styles/Text';
import { Bin, BinSampleHistory, MasterSample } from '../../Helpers/Storage/UserState';
import { GetCurrentBinVolume } from '../../Helpers/Functions/Offline/BinFunctions';
import AppContext from '../../Contexts/AppContext';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

type PropType = {
    OnClosed:Function,
    OnSaved:Function,
    adjustingVolume:boolean
    historyData:{bin:Bin,binHistory:{volume:number,added:boolean,sample?:MasterSample,creation_date:Date}[]}
}

function BinVolumeAdjuster(props:PropType){

    const appContext = React.useContext(AppContext);
   
    const [initialVol,setInitialVol] = React.useState<number>();
    const [vol,setVol] = React.useState<number>();

    React.useEffect(()=>{
     
        let current = 0;
        props.historyData.binHistory.forEach(x => {

            if(x?.sample != null)
            {
                if(x.added)
                {
                    current+=x.volume;
                }
                else
                {
                    current-= x.volume;
                }
            }
            else
            {
                current+=x.volume;
            }})
            setInitialVol(current);
    },[])

    async function onSavePress(){

        const profile = await GetData('Profile');
        const ID = await GetData('ID');

        const volumeToSave = CalculateVolumeToSave(props.historyData,vol);

        if(volumeToSave == 0)
        {

            appContext.setAlertOptions({title:'Volume has not changed',desc:'Volume entered must be different than the current volume',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
            appContext.setShowAlert(true);
            
        }
        else
        {
            let status = await SaveVolumeHistoryRecord(props.historyData.bin.id, volumeToSave, profile, ID);

            if (status) {
             
                    props.OnSaved(volumeToSave);
            }
            else {
                console.log('Could not save history record');
            }
        }
    }

    
    function onChangeVolume(text) {
        if (text == '') {
            setVol(0);
        }
        else if (!isNaN(parseInt(text))) {
            setVol(parseInt(text));
        }
    }

    function CalculateVolumeToSave(historyData: { bin: Bin; binHistory: { volume: number; added: boolean; sample?: MasterSample; creation_date: Date; }[]; }, adjustedVolume: number) {
        
        let current = 0;

        historyData.binHistory.forEach(x => {

            if(x?.sample != null)
            {
                if(x.added)
                {
                    current+=x.volume;
                }
                else
                {
                    current-= x.volume;
                }
            }
            else
            {
                current+=x.volume;
            }
    
        })

        const resultToSave = adjustedVolume - current;
        return resultToSave;

    }

    return(
        <Overlay isVisible={props.adjustingVolume} overlayStyle={{height:height/1.2, width:width/1.2}} onBackdropPress={() => { props.OnClosed() }}>
            <KeyboardAwareScrollView>
            <View style={{flex:1}}>
                
                <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                    <Text style={{ alignSelf: 'center', fontSize: 28 }}>Adjust Volume</Text>
                </View>

                <View style={{flex:1, marginTop:15, justifyContent:"space-evenly"}}>
                    <Text style={textStyles.label} >Current Volume: {initialVol} Bushels</Text>
                    <Text style={textStyles.label} >Max Volume: {props.historyData.bin.size} Bushels</Text>

                    <View style={spacing.VerticalSpacer}></View>

                    <VGInput label="Adjusted Volume" keyboardType={'numeric'} onChangeText={onChangeVolume}
                        value={vol > 0 ? vol.toString() : ''}
                    />

                    {/* Empty and fill buttons */}
                    <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                        <VGButton role="SECONDARY" buttonStyle={{flex:1}}
                            onPress={() => {
                                setVol(0);
                            }}
                            >Empty</VGButton>

                        {/* Empty Space */}
                        <View style={{width:15}}></View>

                        <VGButton role="SECONDARY" buttonStyle={{flex:1}}
                            onPress={() => {
                                setVol(props.historyData.bin.size);
                            }}
                            >Full</VGButton>
                    </View>

                    {/* +/-50 bushel buttons */}
                    <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                        <VGButton role="SECONDARY" buttonStyle={{flex:1}}
                            onPress={() => {
                                let newVol = vol - 50;
                                setVol(newVol >= 0 ? newVol : 0);
                            }}
                            >-50</VGButton>

                        {/* Empty Space */}
                        <View style={{width:15}}></View>

                        <VGButton role="SECONDARY" buttonStyle={{flex:1}}
                            onPress={() => {
                                let newVol = vol + 50;
                                setVol(newVol <= props.historyData.bin.size ? newVol : props.historyData.bin.size);
                            }}
                            >+50</VGButton>
                    </View>

                </View>

                {/* Bottom buttons */}
                <View style={{flex:1, justifyContent:"flex-end"}}>
                    <VGButton role="PRIMARY" onPress={onSavePress} 
                    >Save</VGButton>

                    <VGButton role="CANCEL" 
                        onPress={() => {
                            props.OnClosed();
                        }}
                        >Cancel</VGButton>
                </View>
    
            </View>
            </KeyboardAwareScrollView>
        </Overlay>
    )
}

export default BinVolumeAdjuster;