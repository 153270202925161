import React, { useEffect, useState, useMemo } from "react";
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView, Alert } from "react-native";
import { GrainType,GrainSubCrop, GrainVariety, CropSelectOption, UserGrain } from "../../../Helpers/Storage/UserState";
import { defaultGrain, defaultGrainSubCrop } from "../../../Helpers/Storage/DefaultStates";
import { GetGrainAndSubCropTypes, GrabCropSelectOptionFromUserSelection, SaveUserGrains,GetSelectedGrains, UpdateMasterList, GetSelectedVarieties } from "../../../Helpers/Functions/Grains";
import styles from "../../../Styles/styles";
import textStyles from "../../../Styles/Text";
import Icon from "react-native-vector-icons/FontAwesome";
import GrainSectionListKey from "./GrainSectionListKey";
import GrainSectionListValue from "./GrainSectionListValue";
import { FlatList } from "react-native-gesture-handler";
import VGButton from "../../../Components/Button/Button";
import { Overlay } from "react-native-elements";
import RequestGrain from "./RequestGrain";
import { GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import Spacing from "../../../Styles/Spacing";
import Toast from "react-native-tiny-toast";
import { useIsFocused } from "@react-navigation/native";
import AppContext from "../../../Contexts/AppContext";
import { AddToQueue } from "../../../Helpers/Storage/OfflineQueue";
function MasterGrainList(props: {fromSample:boolean,OnClose:(userGrainToAdd,userGrainToRemove) => void|null,OnCancel:() => void|null}) {


const appContext = React.useContext(AppContext);
  //#region variables
    const isFocused = useIsFocused();
  const [show,setShow] = React.useState<boolean>(true);
    //An array of grain_type/sub_crop combinations
    const [grains,setGrains] = React.useState<{
      grainType:GrainType,
      grainSubCrop:GrainSubCrop|null,
  }[]>([]);

  const [refreshList,setRefreshList] = React.useState<boolean>(true);
  //The full list of varieties
  const [varieties,setVarieties] = React.useState<GrainVariety[]>([]);

  //The list of selected grain_Varietyies
  const [selectedGrainsVarieties,setSelectedGrainsVarieties] = React.useState<GrainVariety[]>([]);
  const [selectedGrains, setSelectedGrains] = React.useState<{
    grainType:GrainType,
    grainSubCrop:GrainSubCrop|null,
}[]>([]);


  //The list to render on screen (We don't want to render all varieties)
  const [shownVarieties,setShownVarieties] = React.useState<{typeID:number,subCropID:number,grainVariety:GrainVariety}[]>([]);

  //The actual list of options (Use the Type + SubCrop to get the list of varieties)
  const [cropSelectOptions,setCropSelectOption] = React.useState<CropSelectOption[]>([]);
  //#endregion



  //#region UseEffects

  //This useEffect will close all opened sub menus when the user leaves this screen!
  React.useEffect(() => {
    if(!isFocused)
    {
     
      setShownVarieties([]);
    }
  },[isFocused])
    //This UseEffect will attempt to grab all the grain/subcrop combinations and store in array
    React.useEffect(() => {

    
        //Grab the entire grain list useful for displaying data in the master list
        const crops = GetOfflineStateParsed('crop_select_options');
        setCropSelectOption(crops);
        let grainAndSubCrop = GetGrainAndSubCropTypes();
        setGrains(grainAndSubCrop.types);
        setVarieties(grainAndSubCrop.varieties);

        const userGrains = GetOfflineStateParsed('user_grains')
        const selectedGrains = GetSelectedGrains(userGrains,grainAndSubCrop.types);
        setSelectedGrains(selectedGrains);

        const selectedVarieties = GetSelectedVarieties(userGrains,grainAndSubCrop.varieties);
        setSelectedGrainsVarieties(selectedVarieties);
    },[refreshList])


  
      //This use Effect will trigger when a user has selected or deselected a grain variety
      //useful for checking the grain type/sub checkbox if it wasnt checked yet
      React.useEffect(() => {
        

        selectedGrainsVarieties.forEach(variety => {
          const cropSelectOption = cropSelectOptions.find(x => x.grain_variety_id == variety.id);

          const selectedGrainIndex = selectedGrains.findIndex( x=> x.grainType.id == cropSelectOption.grain_type_id && x.grainSubCrop.id == cropSelectOption.grain_sub_crop_id);

          if(selectedGrainIndex == -1)
          {
            const foundGrain = grains.find(x => x.grainType.id == cropSelectOption.grain_type_id && x.grainSubCrop.id == cropSelectOption.grain_sub_crop_id);
              handlePress(foundGrain);
          }

        })
  
      },[selectedGrainsVarieties])



  //#endregion

 
 

//#region FUNCTIONS
const handlePress = React.useCallback((item) => {
  setSelectedGrains((previousState) => {
  const index = previousState.findIndex(x => x.grainSubCrop.id == item.grainSubCrop.id && x.grainType.id == item.grainType.id);


    if (index !== -1) {
      const cloned = [...previousState];
      cloned.splice(index, 1);

      //Deselect all varieties as well - Thismight just break everything...
      const cropSelection = cropSelectOptions.filter(x => x.grain_type_id == item.grainType.id && x.grain_sub_crop_id == item.grainSubCrop.id);
      
      let selVars = [...selectedGrainsVarieties];
      selectedGrainsVarieties.forEach(x => {
          const varIndex = cropSelection.findIndex(j => x.id == j.grain_variety_id);
          if(index != -1)
          {
            selVars.splice(varIndex,1);
          }
      });
      setSelectedGrainsVarieties([...selVars]);

   return cloned;
     
    } else {
      return [...previousState,item];
    }
  });
}, [selectedGrains,cropSelectOptions,selectedGrainsVarieties]);


//This call back will handle what grain varieties to show to the user 
//(This ensures we dont have to deal with rendering issues with 1000+ varieties!)
const handleShownPress = React.useCallback((item:{
  grainType: GrainType;
  grainSubCrop: GrainSubCrop | null;
}) => {

  const filteredCropSelection = cropSelectOptions.filter(x => x.grain_type_id == item.grainType.id && x.grain_sub_crop_id == item.grainSubCrop.id);

    const shownVarIndex = shownVarieties.findIndex(x => x.typeID == item.grainType.id && x.subCropID == item.grainSubCrop.id);
    if(shownVarIndex == -1)
    {
      let cloned = [...shownVarieties];

      const vars = varieties.filter(function(x:GrainVariety){
        if(filteredCropSelection.find(j => j.grain_variety_id == x.id && j.grain_variety_id != null))
        {
      
          return x;
        }
      })
      vars.forEach(v => {
        cloned.push({typeID:item.grainType.id, subCropID:item.grainSubCrop.id, grainVariety:v});
      })
      setShownVarieties([...cloned]);

    }
    else
    {
        const removed = shownVarieties.filter(x => x.typeID != item.grainType.id && x.subCropID != item.grainSubCrop.id);
      setShownVarieties([...removed]);
    }

  }, [cropSelectOptions,varieties,shownVarieties]);




  //This callback will handle selecting a grain Variety!
const handlePressVariety = React.useCallback((item) => {

    setSelectedGrainsVarieties((previousState) => {
    const index = previousState.findIndex( x => x.id == item.grainVariety.id);

      if (index !== -1) {
        const cloned = [...previousState];
        cloned.splice(index, 1);
        return cloned;
      } else {
        return [...previousState,item.grainVariety];
      }
    });
  }, []);


  async function SaveGrains()
  {
      const status = await SaveUserGrains(cropSelectOptions,selectedGrains,selectedGrainsVarieties);

      if(status != null)
      {
        if(props.fromSample)
        {
          props.OnClose(status.userGrainToAdd,status.userGrainToRemove);
        }
        Toast.show('SUCCESS');
      }

  }


  
/**
 * 
 * @param result The result if a new user grain request was successful ornot
 */
function RefreshList(result:boolean)
{
  //If result is true (Grain was saved to queue)
  if(result)
  {
    
    //Show the master list
    setShow(true);
    //Flip Flop the refresh list to force a re-render of data
    setRefreshList(refreshList ? false : true);
    //Show success message
    Toast.show('Success')
  }
  else
  {
    //Error alert, grain could not be saved
    appContext.setAlertOptions({title:'Could not save new grain',desc:'Please try again at a later time',options:[{text:'OK', role:'PRIMARY', onPress:()=>{appContext.setShowAlert(false);}}]})
    appContext.setShowAlert(true);
  }
}
//#endregion
 





   






    return (

        <View style={{flex:1,marginHorizontal:10}}>

{show ?
  <>

  <View style={Spacing.ScreenHeader} accessible={true} accessibilityLabel="Master Grain List">
                     <Text style={textStyles.title}>Master Grain List</Text>
  </View>
  
                    <SectionList
             
                    keyExtractor={(item, index) => index.toString()}
                      sections={grains.map(x => {return {title:x, data: shownVarieties.filter(j => j.typeID == x.grainType.id && j.subCropID == x.grainSubCrop.id)}})}
                      renderItem={({item,section}) => (
  
                          <GrainSectionListValue
                          // Only the `checked` property will change for the changed items
                          checked={selectedGrainsVarieties.some(x => x.id == item.grainVariety.id)}
                          shown={shownVarieties.includes(item)}
                          onPress={handlePressVariety}
                          item={item}
                        />
  
                        )}
                        renderSectionHeader={({section: {title}}) => (
                          <GrainSectionListKey
                          // Only the `checked` property will change for the changed items
                          checked={selectedGrains.includes(title)}
                          onPress={handlePress}
                          onShownPress={handleShownPress}
                          item={title}
                          shown={shownVarieties.some(x => x.typeID == title.grainType.id && x.subCropID == title.grainSubCrop.id)}
                        />
                        )}
                  />
                  <VGButton role="PRIMARY"  onPress={SaveGrains} >Save</VGButton>
  
                  <VGButton role="SECONDARY" onPress={()=>{setShow(false)}} >Request</VGButton>
                 
                 {props.fromSample ?<VGButton role="SECONDARY" onPress={props.OnCancel} >Close</VGButton>
                  : null}
                  </>
:
                <RequestGrain grainTypes={grains.map(x=> {return x.grainType})} cropSelectOptions={cropSelectOptions} subcrops={grains.map(x => {return x.grainSubCrop})} onClosed={() => {setShow(true)}} onSaved={RefreshList} /> 

 }




          

      </View>
    );
}


  

export default MasterGrainList;

