import * as React from 'react';
import { DrawerActions, useIsFocused } from '@react-navigation/native';
import { GetAsyncDataParsed, GetData, RemoveData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, TouchableOpacity, DeviceEventEmitter, Image, Dimensions } from 'react-native';
import styles from '../../../Styles/styles'
import Toast from 'react-native-tiny-toast';
import { FlatList, ScrollView } from 'react-native-gesture-handler';

import AddNewUserModal from '../../LibraryScreens/UsersAndProfile/AddNewUserModal';
import Spinner from 'react-native-loading-spinner-overlay';
import { Alert } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import OverlayPicker from '../../HelperScreens/OverlayPicker';
import { CheckIfPhoneExists, DeletePrimaryUser, DeleteSecondaryUser, GetAllSecondaryUsers, GetUser, UpdateUser } from '../../../Helpers/Functions/Online/UserFunctions';

import { OfflineUser, UserInfo } from '../../../Helpers/Storage/UserState';
import NetworkContext from '../../../Contexts/NetworkContext';
import VGInput from '../../../Components/Input/Input';
import spacing from '../../../Styles/Spacing';
import textStyles from '../../../Styles/Text';
import AppContext from '../../../Contexts/AppContext';
import VGButton from '../../../Components/Button/Button';
import PhoneInput from '../../../Components/Input/PhoneInput';


function ProfileScreen({ navigation }: { navigation: any }) {

  const appContext = React.useContext(AppContext);
  let states: string[] = ["Alaska", "Alabama", "Arkansas", "American Samoa", "Arizona", "California", "Colorado", "Connecticut", "District of Columbia", "Delaware", "Florida", "Georgia", "Guam", "Hawaii", "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky", "Louisiana", "Massachusetts", "Maryland", "Maine", "Michigan", "Minnesota", "Missouri", "Mississippi", "Montana", "North Carolina", "North Dakota", "Nebraska", "New Hampshire", "New Jersey", "New Mexico", "Nevada", "New York", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Virginia", "Virgin Islands", "Vermont", "Washington", "Wisconsin", "West Virginia", "Wyoming"];
  let provinces: string[] = ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"];
  let countries: string[] = ["Canada", "USA"];
  const isFocused = useIsFocused();
  const currentContext = React.useContext(NetworkContext);

  const [statesOrProvinces, setStatesOrProvinces] = React.useState(['']);
  const [name, setName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [province, setProvince] = React.useState<{ name: string }>();
  const [country, setCountry] = React.useState<{ name: string }>();
  const [city, setCity] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState<{ number: string, valid: boolean }>({ number: '', valid: false });
  const [secUserPhone, setSecUserPhone] = React.useState<{ number: string, valid: boolean }>({ number: '', valid: false });
  const [adminNum, setAdminNum] = React.useState('');
  const [isAddingUser, setIsAddingUser] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [users, setUsers] = React.useState<UserInfo[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [isPickingCountry, setIsPickingCountry] = React.useState(false);
  const [isPickingProvince, setIsPickingProvince] = React.useState(false);
  const [ID, setID] = React.useState(0);
  const [isViewingSecondaryProfiles, setIsViewingSecondaryProfiles] = React.useState(false);
  const [selectedSecondaryUser, setSelectedSecondaryUser] = React.useState<UserInfo>(null);

  let nameRef = React.useRef(null);
  let companyRef = React.useRef(null);
  let cityRef = React.useRef(null);
  let emailRef = React.useRef(null);


  //Reset the screen if the user switches to a different menu
  React.useEffect(() => {
    if (!isFocused) {
      setIsAddingUser(false);
      setIsViewingSecondaryProfiles(false);

    }
  }, [isFocused]);


  //This useEffect will grab the data before rendering the page
  //this means we dont need to use a button to grab data!
  React.useEffect(() => {
    const GrabProfileData = async () => {

      setIsLoading(true);
      let grabbedEmail = await GetData("Email");

      const profile = await GetData('Profile');
      //Grab secondary Users
      let userData = await GetAllSecondaryUsers(profile, grabbedEmail);

      setIsLoading(false);

      if (grabbedEmail == null) {
        grabbedEmail = '';
      }
      setEmail(grabbedEmail);

      let data = await GetUser(grabbedEmail);

      if (!data) {
        throw new Error("Could not reach API");
      }

      if (data.country != null) {
        setCountry({ name: data.country });
        if (data.country == "Canada") {
          setStatesOrProvinces(provinces);
        }
        else {
          setStatesOrProvinces(states);
        }
      }

      setCompany(data.company);
      let province: string = data.province;
      if (province) {
        province.replace("+", ' ');
      }
      setProvince({ name: province });
      setCity(data.city);

      setName(data.name);
      setID(data.id);

      setPhone({ number: data.phone, valid: false });
      if (data.admin == 1) {
        setAdminNum('1');
        setIsAdmin(true);
        //admin is logged in, have a list of secondary users
        if (userData != null) {
          console.log(userData);
          setUsers(userData);
        }
        else {
          console.log('What, no users???')
          setUsers([]);
        }

      }
      else {
        setSelectedSecondaryUser(data);
        setAdminNum('0');
        setIsAdmin(false);
        setUsers([])
      }

      setIsLoading(false);


    }

    const GrabProfileDataOffline = async () => {

      setIsLoading(true);
      let grabbedEmail = await GetData("Email");

      //Grab secondary Users
      let userData = await GetAsyncDataParsed<OfflineUser[]>("SecondaryUsers");

      setIsLoading(false);

      if (grabbedEmail == null) {
        grabbedEmail = '';
      }
      setEmail(grabbedEmail);

      let data = await GetAsyncDataParsed<OfflineUser>("User");

      if (!data) {
        throw new Error("Could not reach API");
      }

      if (data.country != null) {
        setCountry({ name: data.country });
        if (data.country == "Canada") {
          setStatesOrProvinces(provinces);
        }
        else {
          setStatesOrProvinces(states);
        }
      }

      setCompany(data.company);
      let province: string = data.province;
      if (province) {
        province.replace("+", ' ');
      }
      setProvince({ name: province });
      setCity(data.city);

      setName(data.name);
      setID(data.id);

      setPhone({ number: data.phone, valid: false });
      if (data.admin == '1') {
        setAdminNum('1');
        setIsAdmin(true);
        //admin is logged in, have a list of secondary users
        if (userData != null) {
          console.log(userData);
          setUsers(userData.map(x => { return { ...x, user_pass: "", admin: parseInt(x.admin) == 0 ? 0 : 1 } }));
        }
        else {
          console.log('What, no users???')
          setUsers([]);
        }

      }
      else {
        setSelectedSecondaryUser({ ...data, admin: 0 });
        setAdminNum('0');
        setIsAdmin(false);
        setUsers([])
      }

      setIsLoading(false);


    }

    try {
      if (currentContext.isOffline) {
        GrabProfileDataOffline();
      }
      else {
        GrabProfileData().catch((e) => {
          console.log('error: ', e);
          navigation.navigate("Main Menu");
        });
      }
    }
    catch (e) {
      appContext.setAlertOptions({title:'Could not grab profiledata',desc:'Please try again at a later time',options:[{text:'OK', role:'PRIMARY', onPress:()=>{appContext.setShowAlert(false);}}]})
      appContext.setShowAlert(true);
    }

  }, [isFocused])

  React.useEffect(() => {
    setSecUserPhone({ number: selectedSecondaryUser?.phone ?? '', valid: false });

    return function cleanup() {
      setSecUserPhone({ number: '', valid: false });
    }
  }, [selectedSecondaryUser]);

  DeviceEventEmitter.addListener('OpenMenu', OpenDrawer);
  function OpenDrawer() {
    navigation.dispatch(DrawerActions.openDrawer())
  }

  async function onSaveSecondaryPress() {

    let status = await UpdateUser(selectedSecondaryUser);
    if (status != null) {
      let oldUsers = users;
      const index = oldUsers.findIndex(x => x.id == selectedSecondaryUser.id);
      oldUsers[index] = selectedSecondaryUser;
      setUsers([...oldUsers]);
      setIsViewingSecondaryProfiles(false);
      setSelectedSecondaryUser(undefined);
      Toast.show('Updated User');
    }
  }

  async function onDeletePress(primaryUser:boolean){


    appContext.setAlertOptions({title:'Delete User',desc:primaryUser ? 'Are you sure you want to delete your account? This will delete ALL DATA associated with your account' : 'Are you sure you want to delete your account? Primary account will still have access to all data created',
    options:[
      {text:'Delete', role:'DESTRUCTIVE', onPress:()=>{
        appContext.setAlertOptions({title:'WARNING!', desc:'Are you really sure? You cannot restore your account!',
        options:[
          {text:'Delete', role:'DESTRUCTIVE', onPress:async() => {
            let profile;
            if(primaryUser) { profile = await GetData('Profile');}
            const email = await GetData('Email');
            let status = primaryUser ? await DeletePrimaryUser(ID, email, profile) : await DeleteSecondaryUser(ID, email);
            if (status) {
              RemoveData('Email');
              RemoveData('Password');
              RemoveData('Profile');
              navigation.navigate('Login');
              let oldList = users;
              const index = oldList.findIndex(x => x.id == ID);
              oldList.splice(index, 1);
              setUsers([...oldList]);

              setIsViewingSecondaryProfiles(false);
              setSelectedSecondaryUser(undefined);
              appContext.setShowAlert(false);
              Toast.show('Removed User');
            }
          }},
          {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
        ]})
        appContext.setShowAlert(false);}},
      {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
    
    ]})
    appContext.setShowAlert(true);
  }
    

  return (
    <View style={{ alignContent: 'flex-start', marginHorizontal: 10, flex: 1 }}>

      {isAddingUser ? <AddNewUserModal users={users} setUsers={setUsers} setVisible={setIsAddingUser} company={company} adminName={name} /> : null}

      {isLoading ? <Spinner
        visible={isLoading}
        textContent={'Loading...'}
        cancelable={false}
        textStyle={styles.spinner}
      /> : null}

      {isViewingSecondaryProfiles ?
        <>


          <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
            <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Secondary Users</Text>
          </View>

          {selectedSecondaryUser != undefined ?

            <>

              <View style={spacing.MainContainer}>
                
                <VGInput label="Name"  value={selectedSecondaryUser.name} 
                  editable={!currentContext.isOffline} 
                  onChangeText={(text) => {
                    setSelectedSecondaryUser({ ...selectedSecondaryUser, name: text });
                  }}
                />

                <VGInput label="Company" value={selectedSecondaryUser.company}
                  editable={!currentContext.isOffline}
                  onChangeText={(text) => {
                    setSelectedSecondaryUser({ ...selectedSecondaryUser, company: text });
                  }}
                />

                <VGInput label="City" value={selectedSecondaryUser.city}
                  editable={!currentContext.isOffline}
                  onChangeText={(text) => {
                    setSelectedSecondaryUser({ ...selectedSecondaryUser, city: text });
                  }}
                />
      
                  {/* pickers don't have a 'disabled' form currently: */}
                  {currentContext.isOffline ?
                  <>
                    <VGInput label="Country" type="picker" 
                      onPress={()=>setIsPickingCountry(true)}
                      value={selectedSecondaryUser.country}
                    />
                  
                    <VGInput label="Province" type="picker"
                      onPress={()=>setIsPickingProvince(true)}
                      value={selectedSecondaryUser.province}
                    />
                  </>
                  :null}

              </View>

              <VGButton
                role="PRIMARY"
                disabled={currentContext.isOffline}
                onPress={onSaveSecondaryPress}
                textStyle={{ color: 'white', textAlign: 'center', fontSize: 22 }}
              >
                Save
              </VGButton>


              <VGButton disabled={currentContext.isOffline}
                role="DESTRUCTIVE"
                onPress={async () => {

                  appContext.setAlertOptions({title:'Delete User',desc:'Are you sure you want to delete this secondary user?',
                  options:[
                    {text:'Delete', role:'DESTRUCTIVE', onPress:()=>{
                      appContext.setAlertOptions({title:'WARNING!', desc:'Are you really sure? You cannot restore your account!',
                      options:[
                        {text:'Delete', role:'DESTRUCTIVE', onPress:async() => {
                          let status = await DeleteSecondaryUser(selectedSecondaryUser.id, selectedSecondaryUser.email);
                          if (status) {
                            let oldList = users;
                            const index = oldList.findIndex(x => x.id == selectedSecondaryUser.id);
                            oldList.splice(index, 1);
                            setUsers([...oldList]);

                            setIsViewingSecondaryProfiles(false);
                            setSelectedSecondaryUser(undefined);
                            Toast.show('Removed User');
                          }
                          appContext.setShowAlert(false);
                        }},
                        {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
                      ]})
                      appContext.setShowAlert(false);}},
                    {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
                  
                  ]})
                  appContext.setShowAlert(true);

                }}>
                Delete
              </VGButton>

              <VGButton role="CANCEL" onPress={() => { setSelectedSecondaryUser(undefined) }}>
                Close
              </VGButton>
            </>
            :
            <>
              <FlatList
                data={users}
                renderItem={({ item }) =>
                  <View style={{ margin: 5 }}>
                    <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingVertical: 5, borderColor: 'lightgrey', borderWidth: 1 }} onPress={() => {
                      //Pass the credentials over, use this when we want to change any secondary user detail
                      setSelectedSecondaryUser(item);
                    }}>

                      <Image style={{
                        width: 30, height: 30, borderRadius: 5,
                        overflow: 'hidden', marginLeft: 5
                      }} source={require('../../../Icons/BluePerson.png')} />

                      <Text style={{ flex: 1, paddingLeft: 10, fontSize: 18 }}>Name</Text>
                      <Text style={{ paddingRight: 10, fontSize: 18 }}>{item.name}</Text>
                    </TouchableOpacity>
                  </View>
                }
                keyExtractor={(item, index) => index.toString()}
              >
              </FlatList>
              <VGButton role="CANCEL" onPress={() => { setIsViewingSecondaryProfiles(false) }}>
                Close
              </VGButton>
            </>
          }
        </>
        : null}

      {!isAddingUser && !isViewingSecondaryProfiles ?
        <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-evenly', marginHorizontal: 10 }}>

          <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
            <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>{isAdmin ? 'Primary User Profile' : 'Secondary User Profile'}</Text>
          </View>

          <OverlayPicker text={'Pick an Country'} type={['name']} isVisible={isPickingCountry} setVisible={setIsPickingCountry} setData={setCountry} data={countries.map(x => { return { name: x } })} />
          <OverlayPicker text={'Pick a Province'} type={['name']} isVisible={isPickingProvince} setVisible={setIsPickingProvince} setData={setProvince} data={statesOrProvinces.map(x => { return { name: x } })} />

          <View style={{ flex: 1, justifyContent: 'space-evenly' }}>
            
            
            <View style={{flexDirection:'row'}}>
              <Text style={textStyles.label}>Email</Text>
              <View style={spacing.HorizontalLabelSpacer}></View>
              <Text style={textStyles.input}>{email}</Text>
            </View>
            
            <View style={spacing.VerticalSpacer}></View>
           

            <VGInput label="Name" value={name} 
              onChangeText={setName} 
              editable={!currentContext.isOffline} 
            />

            <PhoneInput onSetVal={setPhone} />

            <VGInput label="Company" value={company}
              onChangeText={setCompany} editable={isAdmin ? !currentContext.isOffline : false}
            />

            <VGInput label="City" 
              onChangeText={setCity} value={city}
              editable={isAdmin ? !currentContext.isOffline : false}
            />

            <VGInput label="Country" type="picker"
              onPress={()=>setIsPickingCountry(true)}
              value={country?.name}
            />
            
            <VGInput label="Province" type="picker" 
              onPress={()=>setIsPickingProvince(true)}
              value={province?.name}
            />

          </View>
          <VGButton
            disabled={currentContext.isOffline}
            role={'PRIMARY'}
            buttonStyle={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch'}} 
            
            onPress={async () => {

              if (phone.valid === false) {
                
                appContext.setAlertOptions({title:'Invalid Phone Number',desc:'Please ensure you entered a valid phone number and try again.',options:[
                  {text:'OK', role:'PRIMARY', onPress:() => {
                      appContext.setShowAlert(false);
                      return;
                    }}
                ]});
                 appContext.setShowAlert(true); 
            
              }
              const newUser: UserInfo = {
                id: ID, email: email, city: city, province: province?.name, country: country?.name, name: name, company: company, phone: phone.number, profile: await GetData('Profile'),
                admin: 0
              }
              let phoneStatus = await CheckIfPhoneExists(newUser.phone, newUser.id);
              if (phoneStatus === 1) {


                appContext.setAlertOptions({title:'Phone Number in use',desc:'The entered number is already being used, please try another phone number.',options:[
                  {text:'OK', role:'PRIMARY', onPress:() => {
                      appContext.setShowAlert(false);
                      return;
                    }}
                ]});
                 appContext.setShowAlert(true); 
              }

              const status = await UpdateUser(newUser);

              if (status == 0) {
                console.log('updated');
                Toast.show('Updated Account');
              }
              else {
                console.log(status);
                Toast.show('Could not update Account.')
              }
            }
            }
          >
            Save
          </VGButton>


          {isAdmin ?

            <>
              <VGButton 
                role='SECONDARY'
                onPress={() => { setIsAddingUser(true); }}
              >Add a User</VGButton>

              <VGButton role="CANCEL"
                onPress={() => { setIsViewingSecondaryProfiles(true) }}
                >Display Secondary Users</VGButton>

              <VGButton disabled={currentContext.isOffline} role='DESTRUCTIVE' onPress={()=>{onDeletePress(true);}}>Delete</VGButton>

            </>
            :

            <VGButton disabled={currentContext.isOffline} role="DESTRUCTIVE" onPress={()=>{onDeletePress(false);}}>
              Delete
            </VGButton>
          }

        </ScrollView >
        : null}
    </View >
  );
}


export default ProfileScreen;