import { Alert } from "react-native";
import Toast from "react-native-tiny-toast";
import { GetOfflineStateParsed, GetParsedData } from "../../AsyncStorageHelper";
import { ApiResponse } from "../../GeneralTypes";
import { addBuyerSelector } from "../../Storage/DefaultStates";
import { AddToQueue, OfflineQueue } from "../../Storage/OfflineQueue";
import { Buyer, Enterprise } from "../../Storage/UserState";
import { GenerateNumberID, ValidID } from "../GeneralFunctions";

import { ApiCall } from "../GeneralFunctions";
import { CacheManager } from 'expo-cached-image';


export  function GetBuyers(): Buyer[] {
  let buyers: Buyer[] = [];

  buyers = GetOfflineStateParsed("buyers");
  // console.log("\n\nBuyers: ", status);

  if (buyers != null) {


    buyers.sort(function (a, b) {
      const reA = /[^a-zA-Z]/g;
      const reN = /[^0-9]/g;
      const aA = a.location.replace(reA, "");
      const bA = b.location.replace(reA, "");
      if (aA === bA) {
        const aN = parseInt(a.name.replace(reN, ""), 10);
        const bN = parseInt(b.name.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    })

    return buyers;
  }

  return [];
}

export async function RestoreDeletedcompanies(deletedBuyers: Buyer[]): Promise<Buyer[]> {
  const restoredBuyers = [];

  for (const buyer of deletedBuyers) {
    if (buyer.profile && buyer.id != -1) {
      const to_restore = { ...buyer, expire_date: null };
      const status = await AddToQueue("buyers", to_restore, "update");

      if (status) {
        restoredBuyers.push(to_restore);
      }
    }
  }

  return restoredBuyers.length > 0 ? restoredBuyers : null;

}



export async function GetDeletedcompanies(profile: string): Promise<Buyer[]> {
  const data = new FormData();
  data.append("SelectedProfile", profile);

  const status = await GetOfflineStateParsed("buyers");

  if (status) {
    const deletedCompanies = status.filter(x => x.expire_date != null);


    return deletedCompanies;
  }

  return null;
}


export async function UpdateCompanyDetails(company: Buyer, profile: string): Promise<boolean> {

    company.profile = profile;
    const status = await AddToQueue("buyers", company, "update");

    if (status) {
      Toast.show("Updated New Company!")
      return true
    }
  return false;
}


export async function SaveCompanyDetails(company: Buyer, profile: string): Promise<Buyer|null> {

    company.profile = profile;
    company.id = await GenerateNumberID();

    const status = await AddToQueue("buyers", company, "add");


    if (status) {
      Toast.show("Added New Buyer!")
      return company;
    }
  return null;
}


export async function DeleteCompany(to_delete: Buyer): Promise<number> {
  const now = new Date();
  to_delete.expire_date = new Date(now.setMonth(now.getMonth() + 6));

  const status = await AddToQueue("buyers", to_delete, "delete");

  if (status) {
    return to_delete.id;
  }

  return null;
}

export  function GetEnterprises(): Enterprise[]
{
  const growers =  GetOfflineStateParsed("grower_users");
  const enterprises =  GetOfflineStateParsed("enterprises");

  const myArrayFiltered = enterprises.filter((el) => {
    return growers.some((f) => {
      return f.enterprise_id === el.id;
    });
  });

  return myArrayFiltered;

}
export async function GetEnterpriseByGrowerID(id: string): Promise<Enterprise> {
  const growers = await GetOfflineStateParsed("grower_users");
  const enterprises = await GetOfflineStateParsed("enterprises");

  const enterprise_id = growers?.find((x) => x.id == parseInt(id))?.enterprise_id ?? null;


  if (ValidID(enterprise_id)) {
    return enterprises.find(x => x.id == enterprise_id);
  }

  return null;
}

export function ValidateBuyerFields(buyer: Buyer): string[] {
  let errorMessages: string[] = [];

  if (buyer.name == '' || buyer.name == addBuyerSelector.name) {
    errorMessages.push('Name cannot be empty');
  }

  if (buyer.name.length > 50) {
    errorMessages.push('Name must be less than 50 characters.');
  }

  if (buyer.location == '' || buyer.location == addBuyerSelector.location) {
    errorMessages.push('Location cannot be empty');
  }

  if (buyer.location.length > 50) {
    errorMessages.push('Location must be less than 50 characters.');
  }

  if (buyer.buyer_email != null && buyer.buyer_email.length > 255) {
    errorMessages.push('Name must be less than 50 characters.');
  }

  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!reg.test(buyer.buyer_email) || buyer.buyer_email.length > 255) {
    errorMessages.push('Email cannot be empty, must be a valid email');
  }

  return errorMessages;
}
