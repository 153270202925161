import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { MasterSample, PendingAnalysis, PendingBuyer } from "../../Storage/UserState";

export async function GetPendingBuyerRecords(profile: string): Promise<PendingBuyer[]> {
   // const data: PendingBuyer[] = [];
    const pending_buyers = (await GetOfflineStateParsed("pending_buyers")).filter(x => x.profile === profile);


    return pending_buyers;
}

/**
 * Gets the buyer names and grain names for a list of pending buyers. The key for the buyer names is the pending buyer id, and the key for the grain name map is the master_sample_id
 * @param pending_buyers A list of pending buyers to get related info for
 * @returns [buyer_names, grain_names]
 */
export async function GetGrainAndBuyerNames(pending_buyers: PendingBuyer[]): Promise<[Map<number, string>, Map<number, string>]> {
    const grain_name_map = new Map<number, string>();
    const name_map = new Map<number, string>();

    const master_sample_ids = new Set(pending_buyers.map(x => x.master_sample_id));
    const sample_grain_varieties = (await GetOfflineStateParsed("sample_grain_varieties")).filter(x => master_sample_ids.has(x.master_sample_id));
    const grains = await GetOfflineStateParsed("grain_types");
    const grain_varieties = await GetOfflineStateParsed("grain_varieties");

    for (const sample_id of master_sample_ids) {
        const sgvs = sample_grain_varieties.filter(x => x.master_sample_id == sample_id);
        let grain_var_name = '';
        let grain_name = '';
        if (sgvs.length > 0) {
            grain_name = grains.find(x => x.id == sgvs[0].grain_variety_id).name;
            if (sgvs.length > 1) {
                grain_var_name = "Multiple Varieties";
            }
            else {
                grain_var_name = grain_varieties.find(x => x.id == sgvs[0].grain_variety_id).name;
            }
        }

        grain_name_map.set(sample_id, grain_var_name == '' ? grain_name : `${grain_name}-${grain_var_name}`);
    }

    const buyers = await GetOfflineStateParsed("buyers");
    const enterprises = await GetOfflineStateParsed("enterprises");

    for (const pending_buyer of pending_buyers) {
        let name = '';
        if (pending_buyer.buyer_type == "grower") {
            const b = buyers.find(x => x.id === pending_buyer.buyer_id);
            name = `${b.location}-${b.name}`;
        }
        else {
            const e = enterprises.find(x => x.id === pending_buyer.buyer_id);
            name = `${e.location}-${e.name}`;
        }
        name_map.set(pending_buyer.id, name);
    }

    return [name_map, grain_name_map];
}

export async function GetRelatedMasterSamples(pending_buyers: PendingBuyer[]): Promise<MasterSample[]> {
    const master_sample_ids = new Set(pending_buyers.map(x => x.master_sample_id));
    
    return (await GetOfflineStateParsed("master_samples")).filter(x => master_sample_ids.has(x.id));
}