import { Bin, Buyer, Enterprise} from "../../../Helpers/Storage/UserState";

import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList, KeyboardAvoidingView, Platform} from 'react-native';
import { Overlay } from "react-native-elements";
import AddNewSampleBuyer from "../AddSampleModals/AddNewSampleBuyer";
import { addBuyerSelector } from "../../../Helpers/Storage/DefaultStates";
import CustomImage from "../../HelperScreens/CustomImage";
import VGButton from "../../../Components/Button/Button";
import Spacing from "../../../Styles/Spacing";

// TODO: See if you can use this component in both the new sample and sample search screens
function BuyerSamplePicker(props:{enterprise:Enterprise[],canAdd:boolean,cachedEnterpriseImages:{enterprise_id:number,fileUri:string}[], buyers:Buyer[],setBuyers?:any,setPickingData:any,pickingData:boolean,currentBuyer?:Buyer,setCurrentBuyer?:any})
{
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height

    let renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: width,
              backgroundColor: "orange",
            }}
          />
        );
      };

      const [isAddingBuyer,setIsAddingBuyer] = React.useState(false);

    return (
        <>
        
 
                <Overlay  isVisible={props.pickingData}  onBackdropPress={() => {props.setPickingData('')}}>
                <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding':'height'}>
                {!isAddingBuyer ?
                <View style={Spacing.Modal}>
          
                    <View style={{flexDirection:'row',alignSelf:'center'}}>
                        <Text style={{alignSelf:'center',fontSize:28}}>Select Buyer</Text>
                    </View>
                  
                    <FlatList 
           
                        contentContainerStyle={{justifyContent:'space-evenly',alignContent:'flex-end'}}
                        keyExtractor={(item,index) => index.toString()}
                        data={props.buyers}
                        renderItem={({ item }) => 
                            <TouchableOpacity accessibilityLabel={item == addBuyerSelector ? item.name : item.name + ' - ' + item.location} onPress={() => {
                   
                                    props.setCurrentBuyer(item);
                                    props.setPickingData('');
                                
                            }}>

                {props?.enterprise && props?.enterprise?.find(x => x.id == item.id && x.name == item.name && x.location == item.location)?
                                                    <View style={{flexDirection:'row',marginVertical:5,marginHorizontal:10,justifyContent:'center',alignSelf:'center',borderWidth:3,borderColor:props.enterprise.find(x => x.id == item.id && x.name == item.name && x.location == item.location)?.border_color??'black'}}>
                                                        {props.cachedEnterpriseImages.find(x => x.enterprise_id == item.id) ?
                                                                    
                                                                    <Image
                                                                    source={{uri: props.cachedEnterpriseImages.find(x => x.enterprise_id == item.id).fileUri}}
                                                                    style={{ height: Math.round(height / 10), width: Math.round(width/1.3) }}
                                                                    />                        
                                                                : 
                                                                <CustomImage  enterpriseID={item.id} width={Math.round(width/1.2)} height={ Math.round(height / 10)} />
                                                        }                    
                                                    </View>
                                                    :
                                                      item == addBuyerSelector ?  
                                                    <>
                                                    <Text style={{fontSize:24,padding:10}}>{item.name}</Text>
                                                <View style={{height: 2,width: width, backgroundColor: "orange" }}/>
                                                    </>
                                    
                                                    :   
                                                    <>
                                                    <Text style={{fontSize:24,padding:10}}>{item.name} - {item.location}</Text>
                                                <View style={{height: 2,width: width, backgroundColor: "orange" }}  />
                                                    </>    
                                                }
                            </TouchableOpacity>
                    }
                        >
                    </FlatList>

                        {props.canAdd ? 
                        <VGButton role="PRIMARY" onPress={() => { setIsAddingBuyer(true);}}
                        >Add Buyer/Broker</VGButton>
                        : null}
                   
                      <VGButton role="CANCEL" onPress={() => {props.setPickingData(0)}}
                        >Cancel</VGButton>
               
                </View>
                      
                      : 
                      <View style={{width:width/1.2,height:height/1.5}}>
                          <AddNewSampleBuyer companies={props.buyers} currentCompany={props.currentBuyer} setCompanies={props.setBuyers} setCurrentCompany={props.setCurrentBuyer} setPickingData={props.setPickingData} pickingData={props.pickingData} />
                                      </View>
                       }
              
        
      </KeyboardAvoidingView>
                </Overlay>
   
        </>
    )
}
export default BuyerSamplePicker