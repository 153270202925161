import * as React from 'react';
import { DrawerActions, NavigationContainer, useIsFocused } from '@react-navigation/native';
import { View, Text, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList} from 'react-native';


import { Overlay } from "react-native-elements";
import { FontAwesome } from '@expo/vector-icons';
import { useContext } from 'react';
import AppContext from '../../Contexts/AppContext';
import spacing from '../../Styles/Spacing';
import VGButton from '../../Components/Button/Button';
//Similar to the barcode camera, except it has no barcode scan capabilities
function OverlayPicker(props: any) {

  const isFocused = useIsFocused();

  const currentContext = useContext(AppContext);
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height


    let renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: width,
              backgroundColor: "orange",
            }}
          />
        );
      };
  
    // if (hasPermission === null) {
    //   return (<View />);
    // }
    // if (hasPermission === false) {
    //   return (<Text>No access to camera</Text>);
    // }


 


    return(

        <Overlay overlayStyle={spacing.Modal} isVisible={props.isVisible}  onBackdropPress={() => {props.setVisible(false)}}>

        <View style={{flex:1}}>
  
  <View style={{flexDirection:'row',alignSelf:'center'}}>
  <Text style={{alignSelf:'center',fontSize:28,textAlign:'center',flex:1}}>{props.text}</Text>
  <TouchableOpacity   onPress={() => {

        }}>
             <FontAwesome name="question-circle-o" size={35} color="grey" />
        </TouchableOpacity>
  </View>



            {props.data == undefined || props.data.length == 0 ?
              <View style={{flexDirection:'row',alignSelf:'center'}}>
              <Text style={{alignSelf:'center',fontSize:20}}>{props?.noItemMessage??'No Data Available'}</Text>
              </View>
           :
           null
           }
          
        <FlatList 
        ItemSeparatorComponent={renderSeparatorView}
              contentContainerStyle={{justifyContent:'space-evenly',alignContent:'flex-end'}}
              data={props.data}
              renderItem={({ item }) => 
            

                  <TouchableOpacity accessibilityLabel={
                    props.type.map((property, index) => (    
                      item?.[property] != null ?
                      item?.[property]??'' + ' '
                      : ''
                      )).toString()
                  } onPress= {() => {
                     
                    try
                    {
                      props.setData({...item});
                      props.setVisible(false);
                    }
                    catch(error)
                    {
                      console.log('Error selecting: ' + error);
                    }
       
                  
                  }} >


<Text style={{fontSize:24,padding:10}}> 
        {props.type.map((property, index) => (    
        item?.[property]!= null ?
        item?.[property]??'' + ' '
             : ''
       
        ))}
         </Text>
                  </TouchableOpacity>
                  }
                  keyExtractor={(item,index) => index.toString()}
              >
              </FlatList>
              </View>

              <View>
                {props?.addingNewItem ?
                  <VGButton role="PRIMARY" onPress={() => {
                    props.setVisible(false);
                    props.onAddFromMasterList();
                  }}>{props?.addingNewItemButtonName??'Add Item'}</VGButton>
                : null}

              <VGButton role="CANCEL" onPress={() => {
                props.setVisible(false);
              }}>Cancel</VGButton>
        </View>
        </Overlay>
      
    );
  };

export default OverlayPicker