import React from "react";
import { View, Text, Image, TouchableOpacity, TextInput, Alert } from "react-native";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { GetAllBins, GetLocations } from "../../../Helpers/Functions/Offline/BinFunctions";
import { GetFields } from "../../../Helpers/Functions/Offline/FieldFunctions";
import { addBinLocationSelector, addBinSelector, addBuyerSelector, addFieldSelector, defaultBin, defaultBinLocation, defaultBuyer, defaultEnterprise, defaultGrain, addGrainSelector, defaultSubSample, defaultUserEquipment, defaultMasterSample, addUserEquipmentSelector } from "../../../Helpers/Storage/DefaultStates";
import { Bin, BinLocation, Buyer, Enterprise, Field, GrainSubCrop, GrainType, GrainVariety, MasterSample, SampleDestination, SampleEquipment, SampleGrainVariety, SampleSource, SubSample, UserEquipment, UserGrain } from "../../../Helpers/Storage/UserState";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { GetBuyers, GetEnterpriseByGrowerID, GetEnterprises } from "../../../Helpers/Functions/Offline/BuyerFunctions";
import { GetUserEquipment } from "../../../Helpers/Functions/Offline/EquipmentFunctions";
import { CheckValidBarcode, CheckValidSubSampleBarcode, GetSampleTimeData, SaveSubSampleRecord } from "../../../Helpers/Functions/Offline/SampleFunctions";
import FieldSamplePicker from "../SampleDataPickers/FieldSamplePicker";
import BinSamplePicker from "../SampleDataPickers/BinSamplePicker";
import BuyerSamplePicker from "../SampleDataPickers/BuyerSamplePicker";
import EquipmentSamplePicker from "../SampleDataPickers/EquipmentSamplePicker";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { TakeImage } from "../../../Helpers/Functions/Online/ImageFunctions";
import SubSampleBarcodeModal from "../StackModals/SubSampleBarcodeModal";
import BarcodeReader from "../../HelperScreens/BarcodeReader";
import { SaveSampleImage } from "../../../Helpers/Functions/Online/SampleFunctions";
import { ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import * as FileSystem from 'expo-file-system';
import VGButton from "../../../Components/Button/Button";
import { GetGrainAndSubCropTypes } from "../../../Helpers/Functions/Grains";
import SampleGrainPicker from "../SampleDataPickers/SampleGrainPicker";
import spacing from "../../../Styles/Spacing";
import VGInput from "../../../Components/Input/Input";
import textStyles from "../../../Styles/Text";
import AppContext from "../../../Contexts/AppContext";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import { RequiredText } from "../../../Components/SimpleComponents";
import VGToggle from "../../../Components/Toggle";
function CreateSubSampleScreen({ route, navigation }: { route: any, navigation: any }) {

    const appContext = React.useContext(AppContext);
    //This enum is used to determine what pick screen gets shown, instead of having a million useStates for each option
    enum pickSelection { none, sourceBin, sourceField, destinationBin, destinationBuyer, destinationEnterprise, equipment, grain, barcode, manualEquipment, focusBarcode, scanBarcode, finish, reset }

const {data,masterSampleList,childSubSamples,grabSubSamples}: {
        data:{
            sample:MasterSample,sampleOption:string,source:{field:Field[]|null,bin:{bin:Bin,location:BinLocation}|null},destination:{bin:{bin:Bin,location:BinLocation}|null,buyer:Buyer|null,enterprise:Enterprise|null},volume:number,equipment:SampleEquipment, grain:{
                grain: GrainType;
                subCrop: GrainSubCrop;
                grainVarieties: GrainVariety[] | null;
            }
        },
        masterSampleList:MasterSample[],
        setmasterSampleList,
        childSubSamples:{
            masterSample: MasterSample;
            subSample: SubSample;
        }[],
        grabSubSamples:(subList: {masterSample:MasterSample,subSample:SubSample}[]) => void
} = route.params;
    const [bins, setBins] = React.useState<Bin[]>([]);
    const [binLocations, setBinLocations] = React.useState<BinLocation[]>([]);
    const [fields, setFields] = React.useState<Field[]>([]);
    const [buyers, setBuyers] = React.useState<Buyer[]>([]);
    const [equipmentList, setEquipmentList] = React.useState<UserEquipment[]>([]);
    const [grainList, setGrainList] = React.useState<{
        grainType:GrainType,
        grainSubCrop:GrainSubCrop|null,
    }[]>([]);

    const [grainVarList, setGrainVarList] = React.useState<GrainVariety[]>([]);
    const [userGrainList, setUserGrainList] = React.useState<UserGrain[]>([]);
    //Only 1 enterprise per user
    const [enterprise, setEnterprise] = React.useState<Enterprise[]>([]);
    const [cachedEnterpriseImages,setCachedEnterpriseImages] = React.useState<{enterprise_id:number,fileUri:string}[]>([]);
    const [currentSample, setCurrentSample] = React.useState<SubSample>({ ...defaultSubSample });
    const [currentMasterSample,setCurrentMasterSample] = React.useState<MasterSample>({...defaultMasterSample});
    //The optional source/destination params
    const [sourceType, setSourceType] = React.useState<"bin" | "field" | "">("");
    const [destinationType, setDestinationType] = React.useState<"bin" | "buyer" | "enterprise" | "">("");
    const [sourceBin, setSourceBin] = React.useState<{ bin: Bin, location: BinLocation }|null>({...data.source.bin});
    const [sourceFields, setSourceFields] = React.useState<Field[]|null>(data.source.field);
    const [destinationBin, setDestinationBin] = React.useState<{ bin: Bin, location: BinLocation }|null>({...data.destination.bin});
    const [destinationBuyer, setDestinationBuyer] = React.useState<Buyer>(data.destination.buyer);
    const [destinationEnterprise, setDestinationEnterprise] = React.useState<Enterprise>(data.destination.enterprise);

    const [equipment, setEquipment] = React.useState<UserEquipment>({...defaultUserEquipment});
    const [currentGrain, setCurrentGrain] = React.useState<{
        grain: GrainType;
        subCrop: GrainSubCrop;
        grainVarieties: GrainVariety[] | null;
    }>({ ...data.grain});
    const [sourceImage, setSourceImage] = React.useState('');
    const [destinationImage, setDestinationImage] = React.useState('');

    //Current pick selection, defaults to none when not picking any option
    const [currentSelection, setCurrentSelection] = React.useState<pickSelection>(pickSelection.none);

    const barcodeRef = React.useRef(null);
    const volumeRef = React.useRef(null);
    const equipmentRef = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const otherSourceOptions = [{ name: 'Tisdale/AEG automatic sampler' }, { name: 'Enter other source information' }];
    const [sourceOption, setSourceOption] = React.useState({ name: '' });
    const [sampleOption,setSampleOption] = React.useState('');

    const [savedSubSamples,setSavedSubSamples] = React.useState<{
        masterSample: MasterSample;
        subSample: SubSample;
    }[]>([...childSubSamples]??[]);
    const [savedMasterSamples,setSavedMasterSamples] = React.useState<MasterSample[]>([...masterSampleList]??[]);

    const Origins = ["Field", "Storage"] as const;
    type OrigType = typeof Origins[number];
    const [origin, setOrigin] = React.useState<OrigType>("Field");

    const Destinations = ["Storage" , "Buyer"] as const;
    type DestType = typeof Destinations[number]
    const [dest, setDest] = React.useState<DestType>("Storage");

    const [barcodeError, setBarcodeError] = React.useState<string>("")

    //#region grab data
    //Grab all Bins
    React.useEffect(() => {
        console.log('data: ' + JSON.stringify(route.params));

        setIsLoading(true);
        const fetchBins = async () => {
            try {
                const profile = await GetData('Profile');
                let curBins = await GetAllBins().filter(x => x?.expired == 0 || x?.expired == null);

                curBins.sort((a, b) => a.name.localeCompare(b.name));
                setBins(curBins);
                let curLocations = await GetLocations().filter(x => x?.expired == 0 || x?.expired == null);;
                curLocations.sort((a, b) => a.location.localeCompare(b.location));
                setBinLocations(curLocations);

            }
            catch { 

            }
        };
        fetchBins();
    }, []);



 

    //Grab all equipment
    React.useEffect(() => {

        setIsLoading(true);
        const fetchEquipment = async () => {
            try {
              
                let curEquipment = await GetUserEquipment();

                curEquipment.sort((a, b) => a.name.localeCompare(b.name));

                //this was causing issue in getting the equipment
                //TODO figure out if this is necessary for anything
                // if(data.equipment.equipment_id == null)
                // {
                //     setSampleOption('other');
                // }
                // else
                // {
                //     // const eq = curEquipment.find(x => x.id == data.equipment.equipment_id);
                //     // setEquipment(eq);
                //     setSampleOption('Automatic');
                // }
                setEquipmentList(curEquipment);
            }
            catch {  }
        };
        fetchEquipment();
    }, [])


    
    //Grab all bin Locations

    //Grab all fields
    React.useEffect(() => {
        setIsLoading(true);
        const fetchFields = async () => {
            try {
                const profile = await GetData('Profile');
                let fields = await GetFields().filter(x => x.expire_date == null);
                fields.sort((a, b) => a.name.localeCompare(b.name));
                setFields(fields);

            }
            catch { }
        };
        fetchFields();
    }, [])

    //Grab all buyers
    React.useEffect(() => {
        setIsLoading(true);
        const fetchBuyers = async () => {
            try {
                const profile = await GetData('Profile');
                const id = await GetData('ID');
                let buyers = await GetBuyers().filter(x => x.expire_date == null);
                buyers.sort((a, b) => a.name.localeCompare(b.name));

                let enterpriseData = await GetEnterprises();
                if (enterpriseData != null) {
                    let cachedImages:{enterprise_id:number,fileUri:string}[] = []

                    for(let enterprise of enterpriseData)
                    {
                        buyers.unshift({id:enterprise.id,name:enterprise.name,created_at:null,expire_date:null,profile:'',updated_at:null,buyer_email:enterprise.enterprise_email,location:enterprise.location});

                        //const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;
            
                        const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
                     
                        const image = await FileSystem.getInfoAsync(path);
    
                        if(image.exists)
                        {
                            cachedImages.push({enterprise_id:enterprise.id,fileUri:image.uri});
                        }
               
       
                    }
      
                 setCachedEnterpriseImages(cachedImages);
                       
                    setEnterprise(enterpriseData);                }
               

                setBuyers(buyers);

            }
            catch {  }
        };
        fetchBuyers();
    }, [])

    React.useEffect(() => {
        const grains = GetGrainAndSubCropTypes();
        if(grains != null)
        {
            setGrainList(grains.types);
            setGrainVarList(grains.varieties)

        }
  
    },[])

    //#endregion



    //#region data change updates

    React.useEffect(() => {
        //Did the user pick a field?
        if (sourceFields && sourceFields.length > 0) {
            //Set the sourceType to field
            let oldSample = currentSample;
            console.log('source fielding');
            setSourceType('field');
            setOrigin(Origins[0]);
            setCurrentSample({ ...oldSample });
            
            //Set the source bin to it's default state/ deselect it
            setSourceBin({ bin: defaultBin, location: defaultBinLocation });
        }
    }, [sourceFields])

    React.useEffect(() => {

        //Did the user pick a bin?
        if (sourceBin && ValidID(sourceBin?.bin?.id) && ValidID(sourceBin?.location?.id)) {
            console.log(sourceBin);
            //Set the sourceType to field
            let oldSample = currentSample;
            setSourceType('bin');
       
            setOrigin(Origins[0]);
            setCurrentSample({ ...oldSample });
            //Set the source field to it's default state/ deselect it
            setSourceFields([]);
        }
    }, [sourceBin])

    React.useEffect(() => {

        //Did the user pick a bin?
        if (destinationBin && ValidID(destinationBin?.bin?.id) && ValidID(destinationBin?.location?.id)) {


            //Set the sourceType to field
            let oldSample = currentSample;
            setDestinationType('bin');
            setDest('Storage');
            setCurrentSample({ ...oldSample });
            //Set the source field to it's default state/ deselect it
            setDestinationBuyer(defaultBuyer);
            setDestinationEnterprise({...defaultEnterprise});
        }
    }, [destinationBin])

    React.useEffect(() => {


    if(destinationBuyer && ValidID(destinationBuyer?.id))
    {
                            
        setDestinationEnterprise({...defaultEnterprise});
        setDestinationType('buyer');
        setDest('Buyer');
        enterprise.forEach(ent => {
            if(ent.id == destinationBuyer.id && ent.name == destinationBuyer.name)
            {
                setDestinationType('enterprise');
                setDestinationEnterprise(ent);
                setDestinationBuyer({...defaultBuyer});
                return;
            }
        })
        //Set the source field to it's default state/ deselect it
        setDestinationBin({bin: {...defaultBin},location:{...defaultBinLocation}});

    }
}, [destinationBuyer])



    React.useEffect(() => {
        if (currentMasterSample && currentMasterSample.barcode && currentMasterSample.barcode.toString().length == 8) {
            let barStatus = CheckValidSubSampleBarcode(currentMasterSample.barcode.toString());
            if (barStatus != 0 && barStatus != undefined) {
                setBarcodeError('');
                console.log(barStatus);

                let binSize = '';
                switch (barStatus) {
                    case 1:
                        binSize = '2 Gal';
                        break;
                    case 2:
                        binSize = '3.5 Gal';
                        break;
                    case 3:
                        binSize = '5 Gal';
                        break;
                    case 4:
                        binSize = '1 Kg';
                        break;
                }
                let oldSample = currentMasterSample;
                oldSample.container_size = binSize;

                setCurrentMasterSample({ ...oldSample });
            }
            else
            {
                setBarcodeError('Invalid Barcode, must start with 4 and be 8 digits in length')
            }
        }
    }, [currentMasterSample.barcode])

    React.useEffect(() => {
        if (currentSelection == pickSelection.focusBarcode) {
            try
            {
                barcodeRef.current.focus();

            }
            catch
            {
                console.log('could not focus barcode');
            }
        }
        else if (currentSelection == pickSelection.finish) {

            try
            {
                console.log('New sub list: ' + JSON.stringify(savedSubSamples));
                grabSubSamples(savedSubSamples);
            }
            catch(error)
            {
                console.log('could not update sub samples' + error);
            }
          
            navigation.goBack();
        }
        else if(currentSelection == pickSelection.reset)
        {
            setCurrentMasterSample({...currentMasterSample,barcode:null,volume:0});
            appContext.setAlertOptions({title:'Continue Sub Sampling?', desc:'Would you like to add more sub sample records?',options:[
                {text:'Finish', role:'PRIMARY', onPress:() => {
                    setCurrentSelection(pickSelection.finish);
                    appContext.setShowAlert(false);
                }},
                {text:'Continue', role:'SECONDARY', onPress:() => {
                    appContext.setShowAlert(false);
                    setCurrentSelection(pickSelection.none);
                }} 
            ]});
                appContext.setShowAlert(true);
        }
    }, [currentSelection])

    //#endregion

    async function onSavePress(){
        let errors = await ValidateSubSampleRecord(currentMasterSample,currentSample, sourceBin, sourceFields, destinationBin, destinationBuyer, destinationEnterprise, currentGrain, equipment, '')


        if (errors.length == 0) {
            const profile = await GetData('Profile');
            const id = await GetData('ID');

            let finishedMasterSample = currentMasterSample;
            let finishedSubSample = currentSample;
            finishedMasterSample.id = undefined
            //Ensure the sample id is undefined
            finishedSubSample.id = undefined;
            finishedSubSample.parent_sample_id = data.sample.id;
            finishedMasterSample.equipment_type = data.sampleOption??'automatic';
            
            //Save the sample
            const sampleStatus = await SaveSubSampleRecord(finishedMasterSample,finishedSubSample, sourceBin, sourceFields, destinationBin, destinationBuyer, destinationEnterprise, currentGrain, equipment, '', id, profile);

            //If successful, we can save other data

            if (sampleStatus != null) {
             

                console.log('SUCCESS');
                let currentSubList = savedSubSamples;
                currentSubList.push({masterSample:sampleStatus.masterSample,subSample: sampleStatus.subSample});
                setSavedSubSamples([...savedSubSamples]);

                let currentMasterList = savedMasterSamples;
                currentMasterList.push(sampleStatus.masterSample);

                setCurrentSelection(pickSelection.reset);
            }
        }
        else{
            let errorMessage:string = errors.join('\n');
            appContext.setAlertOptions({
            title:"Invalid Sub-Sample", desc:errorMessage,
            options:[
                {
                    text:"OK", role:'PRIMARY',
                    onPress:()=>{
                        appContext.setShowAlert(false);
                    }
                },
            ]});

            appContext.setShowAlert(true);
        }
    }

    function onBarcodePress(){
        setCurrentSelection(pickSelection.barcode)
      }

      function onBarcodeChangeText(text) {
        if(!isNaN(parseInt(text)) &&  text.length <= 8)
        {
            let curSample = currentMasterSample;
            currentMasterSample.barcode = parseInt(text);
            setCurrentMasterSample({...curSample});
        }
        else if(text == '')
        {
            let curSample = currentMasterSample;
            curSample.barcode = 0;
            setCurrentMasterSample({...curSample});
        
        }
        }

        function onGrainPress(){
            setCurrentSelection(pickSelection.grain);
        }

        function onOriginFieldPress(){
            setCurrentSelection(pickSelection.sourceField);
        }

        function onOriginStoragePress(){
            setCurrentSelection(pickSelection.sourceBin)
        }

        function onDestBuyerPress(){
            setCurrentSelection(pickSelection.destinationBuyer)
        }
        
        function onDestStoragePress(){
            setCurrentSelection(pickSelection.destinationBin)
        }

        function onSubmitEditingBushels(){
            if (currentMasterSample?.volume >= 10000) {

                appContext.setAlertOptions({title:'Warning',desc:'Samples greater than 10,000 may have too much variation to provide accurate results',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
                appContext.setShowAlert(true);
            }
        }

        function onChangeBushelsText(text){
            let oldSample = currentMasterSample;
            oldSample.volume = parseInt(text);
            setCurrentMasterSample({ ...oldSample });
        }

        function onEquipmentPress(){          
            sampleOption == 'other' ? setCurrentSelection(pickSelection.manualEquipment) : setCurrentSelection(pickSelection.equipment);
        }

 
        function onBarcodeRead(data){
            let newSample = {...currentMasterSample};
            newSample.barcode = parseInt(data);
            setCurrentMasterSample(newSample)
        }

    return (

        <View style={spacing.MainContainer}>
        <View style={{flex:1}}>
        <KeyboardAwareScrollView>

            <RequiredText />

            {/* Barcode and "Container Size" Row */}
            <View style={{flexDirection:"row"}}>
                {/* Barcode Input */}
                <View style={{flex:1}}>
                    <VGInput label="Barcode" required icon="barcode" type="picker" 
                        onPress={onBarcodePress}
                        value={currentMasterSample?.barcode != null && currentMasterSample?.barcode > 0 ? currentMasterSample?.barcode.toString()??'' : ''}
                        error={barcodeError}

                    />
                </View>

                <View style={[spacing.HorizontalSpacer, {flexWrap:"wrap"}]}></View>
        
                {/* Container Size Display */}
                <View style={spacing.SectionItem} >
                {
                    currentMasterSample?.container_size ? 
                    <>
                    <Text style={[textStyles.label, {marginRight:10}]} >Sample Size: </Text>
                    <View style={spacing.HorizontalLabelSpacer}></View>
                    <Text style={textStyles.input}>{currentMasterSample?.container_size ?? ''}</Text>
                    </>
                    :null
                }
                </View>
            </View>

            {/* Grain Input */}
            <VGInput label="Grain Type" type="picker" required 
                onPress={onGrainPress}
                value={(currentGrain?.grain && ValidID(currentGrain?.grain.id) ? currentGrain?.grain?.name + " - " ?? '' : '' ) + (currentGrain?.subCrop && currentGrain?.subCrop.id != 26 ? currentGrain?.subCrop?.name??'N/A' : 'N/A') +  
                (currentGrain?.grainVarieties && currentGrain?.grainVarieties.length > 0 ? currentGrain?.grainVarieties.length > 1 ? 'Multiple Varieties' : currentGrain?.grainVarieties[0]?.name ?? '' : '')}
            />
           
           <View style={spacing.VerticalSpacer}></View>

            {/* Grain Origins */}
           
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 2 }}>
                    <Text style={textStyles.label}>Grain Origin:</Text>

                    <View style={spacing.HorizontalLabelSpacer}></View>

                    <VGToggle options={Origins} onSelected={setOrigin} default={origin == 'Storage' ? 1 : 0} />
                </View>

                <View style={spacing.VerticalLabelSpacer}></View>

                {
                    origin == "Field" ?
                    <VGInput label="Field" type="picker" required testLabel="Input_Origin_Field"
                        onPress={onOriginFieldPress} 
                        value={sourceFields != undefined && sourceFields.length > 0 ?
                            sourceFields.length > 1 ?
                                "Multiple Fields"
                                :
                                sourceFields[0]?.name ?? ''
                            : ""}
                    />
                    :
                    <VGInput label="Storage" type="picker" required
                        onPress={onOriginStoragePress} testLabel="Input_Origin_Storage"
                        value={
                            sourceBin && ValidID(sourceBin?.bin?.id) && ValidID(sourceBin?.location?.id) ?
                            sourceBin?.bin?.name + ' - ' + sourceBin?.location?.location ?? ''
                            : ""
                        }
                    />
                }

            <View style={spacing.VerticalSpacer}></View>

            {/* Grain Destinations */}
                
                <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}} >
                    <Text style={textStyles.label}>Grain Destination:</Text>

                    <View style={spacing.HorizontalLabelSpacer}></View>

                    <VGToggle options={Destinations} onSelected={setDest} default={dest == 'Buyer' ? 1 : 0} />

                </View>

                <View style={spacing.VerticalLabelSpacer}></View>

                {
                    dest == "Buyer" ?
                    <VGInput label="Buyer" type="picker" required
                        onPress={onDestBuyerPress} testLabel="Input_Destination_Buyer"
                        value={destinationBuyer && ValidID(destinationBuyer.id) || (destinationEnterprise && ValidID(destinationEnterprise.id)) ? 
                            destinationBuyer && ValidID(destinationBuyer.id) ? destinationBuyer?.name + ' - ' + destinationBuyer?.location
                            : destinationEnterprise?.name??''
                         :
                         ""
                        }
                    />
                    :
                    <VGInput label="Storage" type="picker" required
                        onPress={onDestStoragePress} testLabel="Input_Destination_Storage"
                        value={
                            destinationBin && ValidID(destinationBin?.bin?.id) && ValidID(destinationBin?.location?.id) ?
                                destinationBin?.bin?.name + '-' + destinationBin?.location?.location
                                : ""
                        }
                    />
                }

            <View style={spacing.VerticalSpacer}></View>
            
            {/* Sample Volume Input */}
            <VGInput label="Bushels Represented" required 
                keyboardType={'numeric'} onChangeText={onChangeBushelsText}
                onSubmitEditing={onSubmitEditingBushels}
                value={currentMasterSample?.volume && currentMasterSample?.volume > 0 ? currentMasterSample?.volume?.toString() ?? '' : ''}
            />
            

            {/* Equipment Input */}
            <VGInput label={sampleOption == 'other' ? 'Other Source' : 'Handling Equipment'} required type="picker"
                onPress={onEquipmentPress}
                value={equipment?.name ?? ''}
            />





        </KeyboardAwareScrollView>
        </View>
        
        {/* Save */}
        <View style={[spacing.Bottom, {flex:0}]}>
            <VGButton role="PRIMARY" onPress={onSavePress}
            >Save</VGButton>
        </View>



        {currentSelection == pickSelection.barcode ? <BarcodeReader isNumeric={true} onBarcodeRead={onBarcodeRead} setPickingData={()=>{setCurrentSelection(pickSelection.none)}} /> : null}
            {currentSelection == pickSelection.sourceField ? <FieldSamplePicker canAdd={true} fields={fields} currentSampleFields={sourceFields} pickingData={currentSelection == pickSelection.sourceField} OnSaveFieldSelection={(selectedFields:Field[])=>{setSourceFields(selectedFields);setCurrentSelection(pickSelection.none);}} OnAddNewField={(field:Field)=>{setFields([...fields,field]); setCurrentSelection(pickSelection.sourceField);}} OnCancel={() => {setCurrentSelection(pickSelection.none);}} /> : null}
            {currentSelection == pickSelection.sourceBin ? <BinSamplePicker canAdd={true} bins={bins} setBins={setBins} setLocations={setBinLocations} locations={binLocations} currentBin={sourceBin} setCurrentBin={setSourceBin} pickingData={currentSelection == pickSelection.sourceBin} setPickingData={(x: boolean) => setCurrentSelection(x ? pickSelection.sourceBin : pickSelection.none)} /> : null}
            {currentSelection == pickSelection.destinationBin ? <BinSamplePicker canAdd={true} bins={bins} setBins={setBins} setLocations={setBinLocations} locations={binLocations} currentBin={destinationBin} setCurrentBin={setDestinationBin} pickingData={currentSelection == pickSelection.destinationBin} setPickingData={(x: boolean) => setCurrentSelection(x ? pickSelection.sourceBin : pickSelection.none)} /> : null}
            {currentSelection == pickSelection.destinationBuyer ? <BuyerSamplePicker canAdd={true} enterprise={enterprise} cachedEnterpriseImages={cachedEnterpriseImages}  pickingData={currentSelection == pickSelection.destinationBuyer} setPickingData={setCurrentSelection} setCurrentBuyer={setDestinationBuyer} currentBuyer={destinationBuyer} buyers={buyers} setBuyers={setBuyers} /> : null}
            {currentSelection == pickSelection.equipment ? <EquipmentSamplePicker equipmentList={equipmentList} setEquipmentList={setEquipmentList} pickingData={currentSelection == pickSelection.equipment} setPickingData={setCurrentSelection} currentEquipment={equipment} setCurrentEquipment={setEquipment} /> : null}
            {currentSelection == pickSelection.manualEquipment ? <OverlayPicker type={['name']} text={'Pick a Source Option'} isVisible={currentSelection == pickSelection.manualEquipment} setVisible={setCurrentSelection} setData={setSourceOption} data={otherSourceOptions} />: null}

            {currentSelection == pickSelection.grain ? <SampleGrainPicker canAdd={true} grain={grainList} varieties={grainVarList}
            OnConfirmed={(grainType,grainSubCrop,grainVarieties) => {
                setCurrentGrain({grain:grainType,subCrop:grainSubCrop,grainVarieties:grainVarieties});
                setCurrentSelection(pickSelection.none);
            }}
            OnClosed={()=>{        
                setCurrentSelection(pickSelection.none)
            }} /> : null}
                    
    </View>
    );
}

export default CreateSubSampleScreen;



async function ValidateSubSampleRecord(currentMasterSample:MasterSample, currentSample: SubSample, sourceBin: { bin: Bin; location: BinLocation; }, sourceFields: Field[], destinationBin: { bin: Bin; location: BinLocation; }, destinationBuyer: Buyer, destinationEnterprise: Enterprise, currentGrain: {
    grain: GrainType;
    subCrop: GrainSubCrop;
    grainVarieties: GrainVariety[] | null;
}, equipment: UserEquipment, equipment_type: string) {
    let errorMessages: string[] = [];

    if (currentMasterSample.barcode == null || (currentMasterSample.barcode.toString().length != 8 && currentMasterSample.barcode.toString().match(/^[4]{1}[0-9]{7}$/) == null)) {
        errorMessages.push("Sub Sample Barcode must be a valid barcode (8 digits and start with 4)\n Ex: 41234567");
    }

    if (currentMasterSample.container_size == null || currentMasterSample.container_size == '') {
        errorMessages.push("Container size cannot be empty");
    }

    if (currentMasterSample.volume == null || currentMasterSample.volume <= 0 || currentMasterSample.volume.toString().match(/^[0-9]+$/) == null) {
        errorMessages.push("Volume cannot contain letters and cannot be empty");
    }
    if (
        ((sourceBin?.bin?.id <= 0 && sourceBin?.location?.id <= 0) && (sourceFields == null || sourceFields.length <= 0))
    ) {
        errorMessages.push('Sample Source cannot be empty');
    }

    if (currentGrain == null || (!ValidID(currentGrain.grain.id) || !ValidID(currentGrain.subCrop.id))) {
        errorMessages.push('Grain cannot be empty');
      }

    //destination checker
    if (
        ((destinationBin?.bin?.id <= 0 && destinationBin?.location?.id <= 0) && (destinationBuyer == null || destinationBuyer?.id <= 0) && (destinationEnterprise == null || destinationEnterprise?.id <= 0))
    ) {
        errorMessages.push('Sample Destination cannot be empty');
    }

    if (equipment_type == 'other' && equipment.name == '') {
        errorMessages.push('Equipment/Other Source cannot be empty');
    }

    if (equipment_type != 'other' && equipment.id < 0) {
        errorMessages.push('Equipment/Other Source cannot be empty');
    }

return errorMessages;

}

