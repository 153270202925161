import { GrainSubCrop, GrainSupplier, GrainType, GrainVariety } from "../../../Helpers/Storage/UserState";
import React, { useEffect, useState, useMemo } from "react";
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView, TextInput } from "react-native";
import { Overlay } from "react-native-elements";
import VGButton from "../../../Components/Button/Button";
import { defaultGrain, defaultGrainSubCrop, defaultGrainSupplier, defaultGrainVariety } from "../../../Helpers/Storage/DefaultStates";
import Icon from "react-native-vector-icons/FontAwesome";
import HybridInput from "../../../Components/Input/HybridInput";
import AddItemButton from "../../../Components/Button/AddItemButton";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import OverlayDropdown from "../../../Components/Input/OverlayDropdown";
import { GetData, GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import CustomTextInput from "../../../Components/Input/CustomTextInput";
import { GenerateNumberID, ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import Spacing from "../../../Styles/Spacing";
import RadioButton from "../../../Components/Input/RadioButton";
import SelectItemButton from "../../../Components/Button/SelectItemButton";
import { AddToQueue } from "../../../Helpers/Storage/OfflineQueue";
import { SaveGrainSupplier } from "../../../Helpers/Functions/Grains";
import VGInput from "../../../Components/Input/Input";
import textStyles from "../../../Styles/Text";
function AddVariety( props : {
    onSaved:Function,
    onClosed:Function
}) {



    //#region VARIABLES
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;
    //Create a new list that we can use incase the user cancels the save process

    const [currentVariety,setCurrentVariety] = React.useState<GrainVariety>({...defaultGrainVariety});
    const [selectedSupplier,setSelectedSupplier] = React.useState<GrainSupplier>({...defaultGrainSupplier});
    const [customSupplier,setCustomSupplier] = React.useState<GrainSupplier>({...defaultGrainSupplier});
    const varietyRef = React.useRef(null);
    const CFIARef = React.useRef(null);
    const supplierRef = React.useRef(null);
    enum ScreenState {Default,PickingDistributor};
    const [currentScreenState,setCurrentScreenState] = React.useState<ScreenState>(ScreenState.Default);

    const [suppliers,setSuppliers] = React.useState<GrainSupplier[]>([]);

    const [addingCustomSupplier,setAddingCustomSuplier] = React.useState<boolean>(false);
    //#endregion


  
    //#region USE EFFECTS
    React.useEffect(() => {
        const supplierList = GetOfflineStateParsed('grain_suppliers');
        setSuppliers(supplierList);
    },[])

    React.useEffect(() => {
        if(ValidID(selectedSupplier.id))
        {
            setCurrentVariety({...currentVariety,supplier_id:selectedSupplier.id})
        }
    },[selectedSupplier])
    //#endregion


    //#region FUNCTIONS

        function ChangeScreenState(state:ScreenState)
        {
            setCurrentScreenState(state);
        }

        function OnSupplierSelected(supplier:GrainSupplier)
        {
            setSelectedSupplier(supplier);
            setCurrentScreenState(ScreenState.Default);  
        }

        function OnTextInputFocus(ref:React.MutableRefObject<any>)
        {
            ref.current.focus();
        }
        function OnChangeTextSupplier(text:string)
        {
            setCustomSupplier({...customSupplier,name:text});
        }
        function OnChangeTextCFIA(text:string)
        {
            setCurrentVariety({...currentVariety,cfia:text});
        }

        function OnChangeTextVarietyName(text:string)
        {
            setCurrentVariety({...currentVariety,name:text});
        }


        function OnClosed()
        {
            console.log('hi');
            props.onClosed();
        }
   
     function toggleGrainSupplier(stateToChange:boolean)
        {
            setAddingCustomSuplier(stateToChange);
        }


        async function SaveVariety()
        {
            if(addingCustomSupplier && customSupplier?.name != '' && customSupplier?.name != null)
            {

                const saveSupplierStatus = await SaveGrainSupplier(customSupplier);
                if(saveSupplierStatus != null)
                {
                    let updatedVar = {...currentVariety};
                    updatedVar.supplier_id =  saveSupplierStatus.id;
                    setSuppliers([...suppliers,{...saveSupplierStatus}]);
                    props.onSaved(updatedVar);
                }
               
            }
            else
            {
                props.onSaved(currentVariety)
            }
        }

    //#endregion



    return(


        <View style={{flex:1}}>
            <View style={Spacing.TitleBar}>
                <Text style={textStyles.title}>Request Grain Variety</Text>
            </View>

            <OverlayDropdown dropdownStatus={currentScreenState == ScreenState.PickingDistributor ? true: false} listToSelectFrom={suppliers} propertiesToShow={['name']} onCanceled={() => {ChangeScreenState(ScreenState.Default)}} onSelected={(item) => {OnSupplierSelected(item)}}/>

            <VGInput label="Variety Name" type="text" required
    onChangeText={(text) => {OnChangeTextVarietyName(text)}}
    ref={varietyRef} 
    value={currentVariety.name}
  />
            <VGInput label="CFIA" type="text"
    onChangeText={(text) => {OnChangeTextCFIA(text)}}
    ref={CFIARef} 
    value={currentVariety.cfia}
  />

            <View style={Spacing.RadioRow}>
                <RadioButton labelName="Default" editable={true} checked={!addingCustomSupplier} onPress={() => {toggleGrainSupplier(false)}} testLabel="Default_Supplier_Radio_Button" />
                <RadioButton labelName="Custom Supplier" editable={true} checked={addingCustomSupplier} onPress={() => {toggleGrainSupplier(true)}} testLabel="Custom_Supplier_Radio_Button" />
            </View>



            {addingCustomSupplier ? 
   
                    <VGInput label="Supplier" type="text" 
                    onChangeText={(text) => {OnChangeTextSupplier(text)}}
                    ref={supplierRef} 
                    value={customSupplier.name}
                  />
            :
                <VGInput label="Supplier" type="picker" 
                onPress={() => {ChangeScreenState(ScreenState.PickingDistributor)}}
                ref={supplierRef} 
                value={selectedSupplier.name}
              />
          }

            <View style={{flexDirection:'column',justifyContent:'flex-end'}}>
            <VGButton role="PRIMARY"  onPress={SaveVariety} >Save</VGButton>

            <VGButton role="CANCEL" onPress={OnClosed} >Close</VGButton>

            </View>

        </View>

    );

}
export default AddVariety