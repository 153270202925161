import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TouchableOpacity, Image, TextInput, } from 'react-native';
import { Alert } from 'react-native';
import { DeleteCompany, UpdateCompanyDetails } from '../../../Helpers/Functions/Offline/BuyerFunctions';
import { Buyer } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import Icon from 'react-native-vector-icons/FontAwesome';
import { useContext } from 'react';
import AppContext from '../../../Contexts/AppContext';
import VGButton from '../../../Components/Button/Button';
import spacing from '../../../Styles/Spacing';
import { RequiredText } from '../../../Components/SimpleComponents';
import VGInput from '../../../Components/Input/Input';

function UpdatingBuyerScreen(props: { currentCompany: Buyer, setCurrentCompany: setProp<Buyer>, companies: Buyer[], setCompanies: setProp<Buyer[]>, setIsModifyingCompany: setProp<boolean>, deletedCompanies: Buyer[] }) {
    const nameRef = React.useRef(null);
    const locationRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const appContext = useContext(AppContext);

    async function onSavePress(){

        try {
            const profile = await GetData('Profile');
            const status = await UpdateCompanyDetails(props.currentCompany, profile);

            if (status != null) {
                let oldList = props.companies;
                let index = oldList.findIndex(x => x.id == props.currentCompany.id);
                oldList[index] = props.currentCompany;
                props.setCompanies(oldList);
                props.setIsModifyingCompany(false);
                //currentContext.setCurrentScreenName({id:'BuyerMenu'});
            }

        }
        catch
        {
            appContext.setAlertOptions({title:'Could not save buyer',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);
        }

    }

    async function onDeletePress(){

        try {
            const profile = await GetData('Profile');
            props.currentCompany.profile = profile;
            const status = await DeleteCompany(props.currentCompany);

            if (status != null) {
                let oldList = props.companies;
                let index = oldList.findIndex(x => x.id == props.currentCompany.id);

                let oldDeletedList = props.deletedCompanies;

                oldDeletedList.push(props.currentCompany);


                oldList.splice(index, 1);
                props.setCompanies(oldList);


                props.setIsModifyingCompany(false);
               // currentContext.setCurrentScreenName({id:'BuyerMenu'});
            }

        }
        catch
        {
            appContext.setAlertOptions({title:'Could not delete buyer',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);
        }

    }

    return (

        <View style={spacing.MainContainer}>

            <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3, marginBottom: 20 }}>
                <Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Edit Buyer</Text>
            </View>

            <RequiredText />

            <VGInput label="Buyer Name" required
                onChangeText={(text) => {
                    let oldCompany = props.currentCompany;
                    oldCompany.name = text;
                    props.setCurrentCompany({ ...oldCompany })
                }}
                value={props.currentCompany.name}
            />

            <VGInput label="Location" required
                onChangeText={(text) => {
                    let oldCompany = props.currentCompany;
                    oldCompany.location = text;
                    props.setCurrentCompany({ ...oldCompany })
                }}
                value={props.currentCompany.location}
            />

            <VGInput label="Buyer Email" required
                keyboardType='email-address'
                onChangeText={(text) => {
                    let oldCompany = props.currentCompany;
                    oldCompany.buyer_email = text;
                    props.setCurrentCompany({ ...oldCompany });
                }}
                value={props.currentCompany.buyer_email}
            />


            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <VGButton role="PRIMARY"  onPress={onSavePress}>Save</VGButton>

                <VGButton role="DESTRUCTIVE" onPress={onDeletePress}>Delete</VGButton>

                <VGButton role="CANCEL" 
                    onPress={async () => {
                        props.setIsModifyingCompany(false);
                      //  currentContext.setCurrentScreenName({id:'BuyerMenu'});
                    }}>Close</VGButton>
                    
            </View>
        </View>
    )
}

export default UpdatingBuyerScreen