import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, Image, Alert, TouchableOpacity, KeyboardAvoidingView, useWindowDimensions, Platform, Linking} from 'react-native';

import styles from '../../Styles/styles'; 
import Toast from 'react-native-tiny-toast';

import Spinner from 'react-native-loading-spinner-overlay';
import Icon from 'react-native-vector-icons/FontAwesome';
const image = { uri: "/Users/lenniebudgell/Documents/SKPLY/verigrain/vgrepos/testreact/vgtest/assets/bgmenu.png" }
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import OverlayPicker from '../HelperScreens/OverlayPicker';
import { Overlay } from 'react-native-elements';
import { AddDemoDataToAccount, CheckCouponCode, CreateAccount, redirectSubscription, SendSubscriptionEmail, ValidatePassword } from '../../Helpers/Functions/Online/UserFunctions';
import { PasswordRequireText, RequiredText } from '../../Components/SimpleComponents';
import VGInput from '../../Components/Input/Input';
import spacing from '../../Styles/Spacing';
import textStyles from '../../Styles/Text';
import VGToggle from '../../Components/Toggle';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import VGButton from '../../Components/Button/Button';
import PhoneInput from '../../Components/Input/PhoneInput';
import AppContext from '../../Contexts/AppContext';


function CreateAccountScreen({ navigation }: { navigation: any }) {

  const appContext = React.useContext(AppContext);
  const windowHeight = useWindowDimensions().height;
  const windowWidth = useWindowDimensions().width;
//Will need to determine which to use, states or provinces, depending on country
  const states: string[] = ["Alaska", "Alabama", "Arkansas", "American Samoa", "Arizona", "California", "Colorado", "Connecticut", "District of Columbia", "Delaware", "Florida", "Georgia", "Guam", "Hawaii", "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky", "Louisiana", "Massachusetts", "Maryland", "Maine", "Michigan", "Minnesota", "Missouri", "Mississippi", "Montana", "North Carolina", "North Dakota", "Nebraska", "New Hampshire", "New Jersey", "New Mexico", "Nevada", "New York", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Virginia", "Virgin Islands", "Vermont", "Washington", "Wisconsin", "West Virginia", "Wyoming"];
 const provinces: string[] = ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"];
 const countries: string[] = ["Canada", "USA"];
 const [statesOrProvinces, setStatesOrProvinces] = React.useState(['']);
    const [email, setEmail] = React.useState('');
    const [emailStatusColor,setEmailStatusColor] = React.useState('lightgrey');

    const [phone, setPhone] = React.useState<{ number: string; valid: boolean }>({ number: '', valid: false });
    
    const [password, setPassword] = React.useState('');
    const [passwordStatusColor,setPasswordStatusColor] = React.useState('lightgrey');

    const [confirmpassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordStatusColor,setConfirmPasswordStatusColor] = React.useState('lightgrey');

    const [name, setName] = React.useState('');
    const [nameStatusColor,setNameStatusColor] = React.useState('lightgrey');

    const [company, setCompany] = React.useState('');
    const [companyStatusColor,setCompanyStatusColor] = React.useState('lightgrey');

    const [city, setCity] = React.useState('');
    const [cityStatusColor,setCityStatusColor] = React.useState('lightgrey');

    const [province, setProvince] = React.useState<{name:string}>();
    const [provinceStatusColor,setProvinceStatusColor] = React.useState('lightgrey');

    const [country, setCountry] = React.useState<string>();
    const [countryStatusColor,setCountryStatusColor] = React.useState('lightgrey');

    const [isValidAccount, setIsValidAccount] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    const mailRef = React.useRef(null);
    const passwordRef = React.useRef<TextInput>(null);
    const confirmPasswordRef = React.useRef<TextInput>(null);
    const cityRef = React.useRef(null);
    const nameRef = React.useRef(null);
    const companyRef = React.useRef(null);
    const couponCodeRef = React.useRef(null);
  const [isPickingCountry,setIsPickingCountry] = React.useState(false);
  const [isPickingProvince,setIsPickingProvince] = React.useState(false);

    const [showConfirmScreen,setShowConfirmScreen] = React.useState(false);
    const [hidePassword,setHidePassword] = React.useState(true);
    const [hidePasswordConfirm,setHidePasswordConfirm] = React.useState(true);

    const [showPasswordInfo,setShowPasswordInfo] = React.useState(false);
    const [passwordStatus,setPasswordStatus] = React.useState<{upper:boolean,lower:boolean,number:boolean,size:boolean,valid:boolean}>();


    const [couponCode,setCouponCode] = React.useState('');
    React.useEffect(() => {
      if(country == 'Canada')
      {
        setStatesOrProvinces(provinces);
      }
      else
      {
        setStatesOrProvinces(states);
      }
    },[country])


    //Check when the user types email, change the border color if it's either empty
    //not a valid email, or a good email
    React.useEffect(() => {
const reg = /^[ ]*?(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z ]{2,}))$/;
      console.log(reg.test(email));
      if(email == '')
      {
        setEmailStatusColor('lightgrey');
      }
      else if(reg.test(email))
      {
        setEmailStatusColor('green');
      }
      else
      {
        setEmailStatusColor('red');
      }
    },[email])

    //Check weather a user has entered a valid password, changes color of border for different status types
    React.useEffect(() => {
    
      let status = ValidatePassword(password);

      setPasswordStatus(status);

      if(password == '')
      {
        setPasswordStatusColor('lightgrey');
      }
      else if(status.upper == true && status.lower == true && status.number == true && status.size == true)
      {
        setPasswordStatusColor('green');
      }
      else
      {
        setPasswordStatusColor('red');
      }
    },[password])



    //Check weather a user has entered a valid password, changes color of border for different status types
    React.useEffect(() => {
      let status = ValidatePassword(password);

      if(password == '')
      {
        setConfirmPasswordStatusColor('lightgrey');
      }
      else if(status && password == confirmpassword)
      {
        setConfirmPasswordStatusColor('green');
      }
      else
      {
        setConfirmPasswordStatusColor('red');
      }
    },[confirmpassword])


    React.useEffect(() => {
      if(name == '')
      {
        setNameStatusColor('lightgrey');
      }
      else if(name.length > 0 && name.length <= 30)
      {
        setNameStatusColor('green');
      }
      else
      {
        setNameStatusColor('red');
      }
    },[name])


    React.useEffect(() => {
      if(company == '')
      {
        setCompanyStatusColor('lightgrey');
      }
      else if(company.length > 0 && company.length <= 30)
      {
        setCompanyStatusColor('green');
      }
      else
      {
        setCompanyStatusColor('red');
      }
    },[company])


    React.useEffect(() => {
      if(city == '')
      {
        setCityStatusColor('lightgrey');
      }
      else if(city.length > 0 && city.length <= 30)
      {
        setCityStatusColor('green');
      }
      else
      {
        setCityStatusColor('red');
      }
    },[city])

    React.useEffect(() => {
      if(province?.name == '' || province == undefined)
      {
        setProvinceStatusColor('lightgrey');
      }
      else if(province?.name.length > 0 && province?.name.length <= 30)
      {
        setProvinceStatusColor('green');
      }
      else
      {
        setProvinceStatusColor('red');
      }
    },[province])



    async function onCreatePress(){
      try
      {
        setIsLoading(true);
        let status =  await CreateAccount(email,phone, password,confirmpassword, company, name, city, country, province?.name,couponCode);
        setIsLoading(false);
        if(status != null)
        {
          let couponStatus = await CheckCouponCode(couponCode);
          if(couponCode != '' && couponStatus != null)
          {
            setIsLoading(true);
            console.log('Valid Demo Account Coupon Code');
            let demoAdditions = await AddDemoDataToAccount(status.profile);
            setIsLoading(false);
            if(demoAdditions != null)
            {
              Toast.show('Success!');
              navigation.navigate('Login');
              return;
            }
          }  

          SendSubscriptionEmail(status.id.toString(),email);

          appContext.setAlertOptions({title:'Subscription must be purchased',desc:'Please purchase a subscription to activate your account\n\n If invited to an Enterprise account, please use coupon provided',options:[
            {text:'Proceed', role:'PRIMARY', onPress:async() => {
              await redirectSubscription(status.id,email);
              navigation.navigate('Login')
                appContext.setShowAlert(false);
              }},
              {text:'Cancel', role:'CANCEL', onPress:()=>{
                setShowConfirmScreen(true);
                appContext.setShowAlert(false);
              }}
          ]});
           appContext.setShowAlert(true); 

        }
      }
      catch
      {
        setIsLoading(false);
        Toast.show('Could not create account at this time')
      }
    }

    function onProvincePress(){
      setIsPickingProvince(true);
    }

    function onPasswordPress(){
      setShowPasswordInfo(true);
      passwordRef.current.focus();
    }

    return (
      <View style={spacing.MainContainer}>
        
        {/*  OVERLAYS */}
        {isLoading ?     <Spinner
              visible={isLoading}
              textContent={'Creating Account...'}
              cancelable={false}
              textStyle={styles.spinner}
          /> : null }
        
        
        {showConfirmScreen ?
            <Overlay  isVisible={showConfirmScreen}  onBackdropPress={() => {setShowConfirmScreen(false)}}>
                <View style={{width:windowWidth/1.2,height:windowHeight/3.5,justifyContent:'space-evenly'}}>
                  <Text style={{alignSelf:'center', fontSize:20}}>An email has been sent to the email provided to finish settting up your account</Text>

                  <VGButton role='PRIMARY' onPress={() => {
                    setShowConfirmScreen(false);
                  navigation.navigate('Login')
                  }}>OK</VGButton>
                </View>
            </Overlay>
          : null}

        <OverlayPicker text={'Pick a Province'} type={['name']} isVisible={isPickingProvince} setVisible={setIsPickingProvince} setData={setProvince} data={statesOrProvinces.map(x => {return {name:x}})} />
        
        <OverlayPicker text={'Pick a Country'} type={['name']} isVisible={isPickingCountry} setVisible={setIsPickingCountry} setData={setCountry} data={countries.map(x => {return {name:x}})}  />

        {/* MAIN SCROLL VIEW */}
        <View style={{flex:1}}>
        <KeyboardAwareScrollView>

          <RequiredText />
          {/* TODO validation */}

          <VGInput label="Email" required
            onChangeText={setEmail} value={email}
          />
            
            
          <PhoneInput onSetVal={setPhone} />
         

          {showPasswordInfo ? <PasswordRequireText visible={showPasswordInfo} status={passwordStatus} /> : null}
            
            {/* Password */}
          <TouchableWithoutFeedback onPress={onPasswordPress}>
            <VGInput label="Password" required ref={passwordRef} 
              error={passwordStatusColor} secureTextEntry={hidePassword}
              onChangeText={setPassword} value={password}
              onSubmitEditing={() => {
                confirmPasswordRef.current.focus();
              }}
              icon={hidePassword ? "password-show" : "password-hide"}
              onIconPress={
                () => {hidePassword ? setHidePassword(false) : setHidePassword(true)}
              }
              />
          </TouchableWithoutFeedback>
          
          {/* Confirm Pasword */}
          <VGInput label="Confirm Password" required ref={confirmPasswordRef}
              error={confirmPasswordStatusColor}
              secureTextEntry={hidePasswordConfirm}
              onChangeText={setConfirmPassword} value={confirmpassword} 
              onSubmitEditing={() => {
                //TODO ref stuff??????
                nameRef.current.focus();
              }}
              icon={hidePasswordConfirm ? "password-show" : "password-hide"}
              onIconPress={
                () => {hidePasswordConfirm ? setHidePasswordConfirm(false) : setHidePasswordConfirm(true)}
              }
            />  

            {/* First Name */}
            <VGInput label="First Name" required error={nameStatusColor} ref={nameRef}
              onChangeText={setName} value={name}
              onSubmitEditing={() => {
                companyRef.current.focus();
              }}
            />

              {/* Company */}
            <VGInput label="Company" required error={companyStatusColor} ref={companyRef}
              onChangeText={setCompany} value={company}
              onSubmitEditing={() => {
                cityRef.current.focus();
              }}
            />

              {/* City */}
            <VGInput label="City" required error={cityStatusColor} ref={cityRef}
              onChangeText={setCity} value={city}
            />

            {/* Country  */}
            <View style={{flexDirection:"row", alignItems:"center"}}>
              <Text style={textStyles.label} >Country:</Text>
              <View style={spacing.HorizontalLabelSpacer}></View>
              <VGToggle options={countries} onSelected={setCountry} />
            </View>      

            <View style={spacing.VerticalSpacer}></View>

            {/* Province */}
            <VGInput type="picker" label="Province/State" required
              onPress={onProvincePress} value={province?.name}
            />

            {/* Coupon Code */}
            <VGInput label="Coupon Code"
            onChangeText={setCouponCode} value={couponCode}
            />
        </KeyboardAwareScrollView>
        </View>

        <View style={spacing.Bottom}>
          <VGButton role="PRIMARY" onPress={onCreatePress}
          >Create account</VGButton>
        </View>
      </View>
    );

  }



  export default CreateAccountScreen;

