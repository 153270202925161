import * as React from 'react';
import { View, Text, TextInput, Alert, TouchableOpacity, Image, Platform, ImageBackground } from 'react-native';
import styles from '../../Styles/styles';
import { ConcatOfflineState, GetAsyncDataParsed, GetAsyncDataRaw, GetData, RemoveAsyncData, RemoveData, StoreAsyncData, StoreData } from '../../Helpers/AsyncStorageHelper';
import { Dimensions } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import { FontAwesome } from '@expo/vector-icons';
import { GetSubscriptionStatus, redirectSubscription, UserLogin } from '../../Helpers/Functions/Online/UserFunctions';

import NetworkContext from '../../Contexts/NetworkContext';
import VGInput from '../../Components/Input/Input';
import spacing from '../../Styles/Spacing';
import VGButton from '../../Components/Button/Button';
import AppContext from '../../Contexts/AppContext';
import textStyles from '../../Styles/Text';



//This function returns typescript which will render a login screen to the user
function LoginScreen({ navigation }: { navigation: any }) {

  const appContext = React.useContext(AppContext);
  const networkContext = React.useContext(NetworkContext);
  const width = Dimensions.get('window').width; //full width
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [hidePassword, setHidePassword] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const [autoLogin,setAutoLogin] = React.useState(false);
const [isWebView, setIsWebView] = React.useState(false);

  React.useEffect(() => {
    if (Platform.OS === "web"){
      setIsWebView(true);
    }
    if(autoLogin)
    {
      const logInUser = async () => {
        try {
          onLoginPress();
  
        } catch (error) {
          console.log('Keychain couldn\'t be accessed!', error);
        }
      }
  
      logInUser();

    }

  }, [autoLogin]);

  React.useEffect(() =>{
  
    const logUser = async() => {
      const email = await GetData('Email');
      const pwd = await GetData('Password');
      if(email != null && pwd != null)
      {
        RemoveAsyncData('LastUpdate');
        navigation.navigate('MainActivityNavigation');
        return;
      }
    }
    logUser();
  },[])

  React.useEffect(() => {

    try
    {
      const logInUser = async () => {
        try {
          const email = await GetData('Email');
          const pwd = await GetData('Password');
          
          if (email != null && pwd != null) {
  
            setEmail(email);
            setPassword(pwd);
          
 
          }
  
     
  
  
        } catch (error) {
          console.log('Keychain couldn\'t be accessed!', error);
        }
      }
      logInUser();
    }
    catch(error)
    {

    }
  },[])


  async function onLoginPress(){

       
    const isOffline = await GetAsyncDataParsed('isOffline');
       
    //Is the user offline?
     if(isOffline)
     {

       //If so see if you can find the auth token issued out when user has logged in before
       const authToken = await GetData('AuthToken');

       //if found
       if(authToken != null)
       {

         //Split the token by the header and payload
           const tokenParts = authToken.split('.');

           const Buffer = require("buffer").Buffer;
           const header = Buffer.from(tokenParts[0], 'base64').toString();
           const payload = Buffer.from(tokenParts[1], 'base64').toString();
                   


           const jsonData = JSON.parse(payload);

           //grab expiration date
           const expiration = jsonData['exp'];
           if(parseInt(expiration) >(Date.now()/1000))
           {
             console.log('Valid Token... Proceed with login');
             navigation.navigate('MainActivityNavigation');
             return;
           }
           else
           {
             //Date.now is in milliseconds, divide by 1000 to get unix time in seconds
             console.log('date on token: ' + expiration + '  date: ' + (Date.now()/1000));
             console.log('Token found, but expiration date has elapsed');
         
           }

     
       }
       else
       {

        appContext.setAlertOptions({title:'Online Login Required',desc:'You must be online in order to login',options:[
          {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}}
        ]});
         appContext.setShowAlert(true);
       }
     }
     else
     {


      setIsLoading(true);
      const loginStatus = await UserLogin(email,password);
      setIsLoading(false);
        switch(loginStatus.status)
        {
          case 0:
          RemoveAsyncData('LastUpdate');
          navigation.navigate('MainActivityNavigation');
          break;
          case 1:
          case 2:
             appContext.setAlertOptions({title:'Invalid Credentials',desc:'Username or password is incorrect',options:[
              {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}},
            ]});
             appContext.setShowAlert(true);
          break;
    
          case 3:
           appContext.setAlertOptions({title:'Subscription not found',desc:'Please purchase a subscription to use the Verigrain App',options:[
            {text:'Proceed', role:'PRIMARY', onPress:async() => {redirectSubscription(loginStatus.id,email)}},
            {text:'Cancel', role:'CANCEL', onPress:() => { appContext.setShowAlert(false);}}
          ]});
           appContext.setShowAlert(true);
          break;
          case -1:
          default:
             appContext.setAlertOptions({title:'Unknown Error Occurred',desc:'',options:[
              {text:'OK', role:'PRIMARY', onPress:() => { appContext.setShowAlert(false);}}
            ]});
             appContext.setShowAlert(true);
          break;
        }
     }
       }
  return (
    <View style={Platform.OS==="web" ? spacing.WebMainContain :spacing.MainContainer}>
      {isLoading ? <Spinner
          visible={isLoading}
          textContent={'Logging In...'}
          cancelable={false}
          textStyle={styles.spinner}
        /> : null}
      <Image style={styles.WebLogo} source={Platform.OS==="web"?require('../../assets/verigrainLogo.png'):null}/>
      <View style={Platform.OS ==="web" ? [styles.card,]:null}>
        <View style={Platform.OS ==="web" ? [styles.cardcontent] : null}>
          
          {isWebView ? 
          <Text style={[textStyles.topTitle, {marginBottom:'50px'}]}>Login</Text> 
          :null}
        
        
          <VGInput label="Email" testLabel='Login_Email_Input'
            value={email} onChangeText={setEmail}
            onSubmitEditing={() => {
              passwordRef.current.focus();
            }}
            />
          <VGInput label="Password" testLabel='Login_Password_Input' ref={passwordRef}
            value={password} onChangeText={setPassword}
            secureTextEntry={hidePassword}
            icon={hidePassword ? "password-show" : "password-hide"}
            onIconPress={() => { hidePassword ? setHidePassword(false) : setHidePassword(true) }}
            />
            <View style={spacing.VerticalSpacer}></View>
            <VGButton role="PRIMARY"  onPress={onLoginPress} 
            >Login</VGButton>
            <VGButton role="SECONDARY" onPress={() => navigation.navigate('CreateAccount')}
              >Create an account</VGButton>
            <VGButton role="CANCEL" onPress={() => { navigation.navigate('ForgotPassword') }}
            >Can't Remember Password</VGButton>
          </View>
        </View>
      <Image style={Platform.OS === "web" ? styles.WebHomeGraphic : styles.HomeGraphic} source={Platform.OS === "web"? require('../../assets/HomeGraphic.png'): null} />
      {/* <ImageBackground style={Platform.OS === "web" ? styles.WebHomeGraphic : styles.HomeGraphic} resizeMode={'cover'} source={require('../../assets/HomeGraphic.png')}>
      </ImageBackground> */}
    </View>
    
      
  );

}



export default LoginScreen;


