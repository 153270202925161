import { CropSelectOption, GrainSubCrop, GrainType, GrainVariety, UserGrain } from "../../../Helpers/Storage/UserState";
import React, { useEffect, useState, useMemo } from "react";
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView, TextInput } from "react-native";
import { Overlay } from "react-native-elements";
import VGButton from "../../../Components/Button/Button";
import { defaultGrain, defaultGrainSubCrop } from "../../../Helpers/Storage/DefaultStates";
import Icon from "react-native-vector-icons/FontAwesome";
import HybridInput from "../../../Components/Input/HybridInput";
import AddItemButton from "../../../Components/Button/AddItemButton";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import OverlayDropdown from "../../../Components/Input/OverlayDropdown";
import { GetSelectedGrains, GetSelectedVarieties, SaveGrainSelection, ValidateGrain, ValidateVariety } from "../../../Helpers/Functions/Grains";
import { AlertError, ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import { FlatList } from "react-native-gesture-handler";
import RemoveItemButton from "../../../Components/Button/RemoveItemButton";
import SelectItemButton from "../../../Components/Button/SelectItemButton";
import MasterGrainList from "../../DrawerScreens/Grains/MasterGrainList";
import { GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import { useIsFocused } from "@react-navigation/native";
import VGInput from "../../../Components/Input/Input";
function SampleGrainPicker( props : {
  grain:{grainType:GrainType,grainSubCrop:GrainSubCrop|null}[],
  varieties: GrainVariety[],
  canAdd:boolean,
    OnConfirmed:Function,
    OnClosed:Function
}) {

    const isFocused = useIsFocused();
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;
    //Create a new list that we can use incase the user cancels the save process
    const [currentGrainTypes,setCurrentGrainTypes] = React.useState<{grainType:GrainType,grainSubCrop:GrainSubCrop|null}[]>([...props.grain]);

    const [currentVarieties,setCurrentVarieties] = React.useState<GrainVariety[]>([...props.varieties]);
    const [filteredVarieties,setFilteredVarieties] = React.useState<GrainVariety[]>([]);
    const [selectedVarieties,setSelectedVarieties] = React.useState<GrainVariety[]>([]);
    enum ScreenState {Default,PickingGrainType,PickingVariety,AddingGrain};
    const [currentScreenState,setCurrentScreenState] = React.useState<ScreenState>(ScreenState.Default);

    const [selectedGrainType,setSelectedGrainType] = React.useState<GrainType>({...defaultGrain});
    const [selectedGrainSubcrop,setSelectedGrainSubcrop] = React.useState<GrainSubCrop>({...defaultGrainSubCrop});
    const grainTypeRef = React.useRef(null);
    const grainSubRef = React.useRef(null);

    
    const [modifiedGrainToDisplay,setModifiedGrainToDisplay] = React.useState<{grain_type_id:number,grain_sub_crop_id:number|null,name:string}[]>([]);
    const [modifiedGrainSelected,setModifiedGrainSelected] = React.useState<{grain_type_id:number,grain_sub_crop_id:number|null,name:string}>({grain_type_id:-1,grain_sub_crop_id:-1,name:''});
    const [userGrainList, setUserGrainList] = React.useState<UserGrain[]>([]);
    
    const [cropSelectOptions,setCropSelectOptions] = React.useState<CropSelectOption[]>([]);
    React.useEffect(() => {

        let curList: {grain_type_id:number,grain_sub_crop_id:number|null,name:string}[] = [];
        currentGrainTypes.forEach(grain => {
            let name = grain.grainType.name;

            if(grain?.grainSubCrop != null && grain?.grainSubCrop.name != null)
            {
                name += ' - ' + grain?.grainSubCrop?.name;
            }
            curList.push({grain_type_id:grain.grainType.id,grain_sub_crop_id:grain?.grainSubCrop?.id??26,name:name})
        })
        setModifiedGrainToDisplay(curList);
    },[currentGrainTypes])


    React.useEffect(() => {
        setUserGrainList(GetOfflineStateParsed('user_grains'));
        setCropSelectOptions(GetOfflineStateParsed('crop_select_options'));
    },[])


    React.useEffect(()=>{

        setSelectedVarieties([]);
        if(selectedGrainType != null)
        {
            let varsFound:GrainVariety[] = [];
            let CSO:CropSelectOption[] = [];
            if(selectedGrainSubcrop != null)
            {
              CSO = cropSelectOptions.filter(x => x.grain_type_id == selectedGrainType.id && x.grain_sub_crop_id == selectedGrainSubcrop.id);

            }
            else
            {
                CSO = cropSelectOptions.filter(x => x.grain_type_id == selectedGrainType.id && x.grain_sub_crop_id == 26);

            }

            CSO.forEach(option => {

               const variety = currentVarieties.find(x => x.id == option.grain_variety_id);
               if(variety != null)
               {
                varsFound.push(variety);
               }
            })
            setFilteredVarieties(varsFound);
        }
    },[selectedGrainType,selectedGrainSubcrop])

    React.useEffect(() => {

            const selectedGrains = GetSelectedGrains(userGrainList,props.grain);

            setCurrentGrainTypes(selectedGrains);
    
            const selectedVarieties = GetSelectedVarieties(userGrainList,props.varieties);
            setCurrentVarieties(selectedVarieties);
    
        

    },[userGrainList])

    React.useEffect(() => {
        if(ValidID(modifiedGrainSelected.grain_type_id) && ValidID(modifiedGrainSelected.grain_sub_crop_id))
        {
            const grainSub = currentGrainTypes.find(x => x.grainType.id == modifiedGrainSelected.grain_type_id && x.grainSubCrop.id == modifiedGrainSelected.grain_sub_crop_id);

            setSelectedGrainType(grainSub.grainType);
            setSelectedGrainSubcrop(grainSub.grainSubCrop);
        }
    },[modifiedGrainSelected])

            /**
         * 
         * @param state The screen state to change to
         * @returns Event callback
         */
        function OpenDropDown(state:ScreenState)
        { setCurrentScreenState(state);

        }
     
        function OnSelectGrainType(item:{grain_type_id:number,grain_sub_crop_id:number|null,name:string})
        {
            setModifiedGrainSelected(item);
            //setAddingCustomGrainType(false);
            setCurrentScreenState(ScreenState.Default);
        }

 
        function RemoveVarietyFromList(index:number)
        {
            setSelectedVarieties([...selectedVarieties.slice(0,index),...selectedVarieties.slice(index+1)]);

        }

        function Close()
        {
            props.OnClosed();
        }
         
        function HandleGrainSelection()
        {
            props.OnConfirmed(selectedGrainType,selectedGrainSubcrop,selectedVarieties);
        }

    
        function OnSelectGrainVariety(item:GrainVariety)
        {
            setSelectedVarieties([...selectedVarieties,item]);
            setCurrentScreenState(ScreenState.Default);
        }

        function OnSaveSelection(userGrainToAdd:UserGrain[],UserGrainToRemove:UserGrain[])
        {
            let newGrainList = userGrainList;

            userGrainToAdd.forEach(x => {

                console.log('Grain to add to list: ' + x.grain_type_id + ' - ' + x.grain_sub_crop_id + '\n\n');
                newGrainList.push(x);
            })
            UserGrainToRemove.forEach(x => {
                console.log('Grain to remove from list: ' + x.grain_type_id + ' - ' + x.grain_sub_crop_id + '\n\n');

                var index = UserGrainToRemove.findIndex(j => j.id == x.id);
                newGrainList.splice(index,1);
            })
            
            setUserGrainList([...newGrainList]);
            setCurrentScreenState(ScreenState.Default);
        }


    return(

<Overlay isVisible={true}>
    <View style={{width:width,height:height,marginHorizontal:10,paddingHorizontal:10}}>
{currentScreenState != ScreenState.AddingGrain ?
 <View style={{flex:1}}>
 <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
     <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Select Grain</Text>
 </View>



 <OverlayDropdown dropdownStatus={currentScreenState == ScreenState.PickingGrainType ? true: false} listToSelectFrom={modifiedGrainToDisplay} propertiesToShow={['name']} onCanceled={() => {OpenDropDown(ScreenState.Default)}} onSelected={(item) => {OnSelectGrainType(item)}}/>

 <VGInput label="Grain Type" type="picker" required
  onPress={() => {OpenDropDown(ScreenState.PickingGrainType)}} 
  value={selectedGrainType.name}
/>

<OverlayDropdown dropdownStatus={currentScreenState == ScreenState.PickingVariety ? true: false} listToSelectFrom={filteredVarieties} propertiesToShow={['name']} onCanceled={() => {OpenDropDown(ScreenState.Default)}} onSelected={(item) => {OnSelectGrainVariety(item)}}/>


 <AddItemButton  buttonName="Select Varieties" currentCount={selectedVarieties?.length??0} onPress={() => {OpenDropDown(ScreenState.PickingVariety)}} selecting={false} testLabel="Add_Variety_Button" />

 <View style={{flex:1,flexDirection:'column',borderColor:'black',borderWidth:3,marginVertical:5}}>
     <Text style={{alignSelf:'center',fontSize:24}}>Varieties</Text>

        <FlatList
            keyExtractor={(item, index) => index.toString()}
            data={selectedVarieties}
            renderItem={({item,index}) => (
                <RemoveItemButton name={item.name} onPress={() => {RemoveVarietyFromList(index)}}/>
            )}
        />
 </View>
 <View style={{flexDirection:'column',justifyContent:'flex-end'}}>
 <VGButton role="PRIMARY" onPress={HandleGrainSelection} >OK</VGButton>

{props.canAdd ? 
    <VGButton role="SECONDARY" onPress={() => {OpenDropDown(ScreenState.AddingGrain)}} >Add Grain From Master List</VGButton>

: null}

 <VGButton role="CANCEL" onPress={Close} >Close</VGButton>

 </View>

</View>
 :
 <MasterGrainList fromSample={true} OnCancel={() =>{setCurrentScreenState(ScreenState.Default)}} OnClose={(userGrainToAdd,userGrainToRemove) => {OnSaveSelection(userGrainToAdd,userGrainToRemove)}}/>
 }
</View>
</Overlay>


    );

}
export default SampleGrainPicker