import * as React from 'react';
import { DrawerActions } from '@react-navigation/native';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TouchableOpacity, DeviceEventEmitter, Dimensions, Image, Linking } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useIsFocused } from "@react-navigation/native";
import { GetBuyers, GetDeletedcompanies, GetEnterpriseByGrowerID, GetEnterprises } from '../../../Helpers/Functions/Offline/BuyerFunctions';
import { Buyer, Enterprise } from '../../../Helpers/Storage/UserState';
import RestoringBuyerScreen from './RestoringBuyerScreen';
import UpdatingBuyerScreen from './UpdatingBuyerScreen';
import AddingBuyerScreen from './AddingBuyerScreen';
import Spinner from 'react-native-loading-spinner-overlay';
import styles from '../../../Styles/styles';
import { addBuyerSelector, defaultBuyer, defaultEnterprise } from '../../../Helpers/Storage/DefaultStates';
import Toast from 'react-native-tiny-toast';
import Icon from 'react-native-vector-icons/FontAwesome';
import CustomImage from '../../HelperScreens/CustomImage';
import { GrowerUser } from "../../../Helpers/Storage/UserState";
import { useContext } from 'react';
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';
import AllowSampleOverlay from '../../StackScreens/StackModals/AllowSampleOverlay';
import Carousel from 'react-native-snap-carousel';
import * as FileSystem from 'expo-file-system';
import { GetGrowerList } from '../../../Helpers/Functions/Offline/UserFunctions-Offline';
import AppContext from '../../../Contexts/AppContext';
import { GetLocalUserManual } from '../../../Helpers/Functions/Online/UserFunctions';
import VGButton from '../../../Components/Button/Button';
function BuyerScreen({ navigation }: { navigation: any }) {
    const nameRef = React.useRef(null);
    const locationRef = React.useRef(null);
    const currentContext = useContext(AppContext);
    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height

    const [companies, setCompanies] = React.useState<Buyer[]>([]);
    const [currentCompany, setCurrentCompany] = React.useState<Buyer>({...defaultBuyer});
    const [isModifyingCompany, setisModifyingCompany] = React.useState(false);
    const [isViewingCompanies, setisViewingCompanies] = React.useState(false);
    const [isAddingCompany, setisAddingCompany] = React.useState(false);

    const [allowingSamples,setAllowingSamples] = React.useState(false);
    const [isRestoring, setIsRestoring] = React.useState(false);
    const [deletedcompanies, setDeletedcompanies] = React.useState<Buyer[]>([]);
    const [checkedCompanies, setCheckedCompanies] = React.useState<Buyer[]>([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const isFocused = useIsFocused();

    const [enterprise, setEnterprise] = React.useState<Enterprise[]>([])
    const [cachedEnterpriseImages,setCachedEnterpriseImages] = React.useState<{enterprise_id:number,fileUri:string}[]>([]);


    const [growerUser,setGrowerUser] = React.useState<GrowerUser[]>([]);


    React.useEffect(() => {

        const growerStatus = async () => {

 const profile = await GetData('Profile');

       
            const growerList = await GetGrowerList()
            if(growerList != null)
            {
                setGrowerUser(growerList);
            }
            else
            {
    
                console.log('Could not find grower record with an enterprise account tied to it');
            }
        };
        growerStatus();
    },[])

    
    React.useEffect(() => {
        try {

            //grab buyers
            let getBuyerData = async () => {
                setIsLoading(true);
                const profile = await GetData('Profile');
                let buyerStatus = await GetBuyers();
                setIsLoading(false);
                if (buyerStatus != null) {


                    const profile = await GetData('Profile');
                    const userID = await GetData('ID');
                    // let enterpriseData = await GetEnterprises();
    
                    // if(enterpriseData != null)
                    // {
                        
                    //         let cachedImages:{enterprise_id:number,fileUri:string}[] = []
            
                    //         for(let enterprise of enterpriseData)
                    //         {
                    //             //const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;
                    
                    //             const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
                             
                    //             const image = await FileSystem.getInfoAsync(path);
            
                    //             if(image.exists)
                    //             {
                    //                 cachedImages.push({enterprise_id:enterprise.id,fileUri:image.uri});
                    //             }
                       
               
                    //         }
              
                    //      setCachedEnterpriseImages(cachedImages);
                    //     setEnterprise(enterpriseData);
                    // }
                    setCompanies(buyerStatus);
                }

           
            }
            getBuyerData();
        }
        catch
        {
           Toast.show('Could not find any buyers/brokers');
        }


    }, [isFocused])

    React.useEffect(() => {
        try {
            let getBuyerData = async () => {
                const profile = await GetData('Profile');
                let buyerStatus = await GetDeletedcompanies(profile);

                if (buyerStatus != null) {
                    setDeletedcompanies(buyerStatus);
                }
            }
            getBuyerData();
        }
        catch
        {
            console.log('could not find any deleted companies')
        }
    }, [isFocused])

    React.useEffect(() => {
        if (!isFocused) {

        
            setisAddingCompany(false);
            setisModifyingCompany(false);
            setisViewingCompanies(false);
            setIsRestoring(false);
        }
        else
        {
            console.log('Setting buyer menu');
            const getPages = async() => {
                                        
                let data =await GetLocalUserManual();
                if(data != null)
                {
                  currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'BuyerMenu'))
                }
                else
                {
                  currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='BuyerMenu'));
                }
              };
              getPages(); 
        }
    }, [isFocused])

    DeviceEventEmitter.addListener('OpenMenu', OpenDrawer);
    function OpenDrawer() {
        navigation.dispatch(DrawerActions.openDrawer())
    }

    return (
        <View style={{ flex: 1, justifyContent: 'space-evenly' }}>
            <Spinner
                visible={isLoading}
                textContent={'Loading...'}
                cancelable={false}
                textStyle={styles.spinner}
            />

            {isRestoring ?
                <RestoringBuyerScreen checkedCompanies={checkedCompanies} companies={companies}
                    deletedCompanies={deletedcompanies} setCheckedCompanies={setCheckedCompanies}
                    setCompanies={setCompanies} setDeletedCompanies={setDeletedcompanies}
                    setIsRestoring={setIsRestoring} />
                : null}

            {isModifyingCompany ?
                <UpdatingBuyerScreen deletedCompanies={deletedcompanies} companies={companies}
                    currentCompany={currentCompany} setCompanies={setCompanies} setCurrentCompany={setCurrentCompany}
                    setIsModifyingCompany={setisModifyingCompany} />
                : null}

            {
                isAddingCompany ?
                    <AddingBuyerScreen companies={companies} currentCompany={currentCompany}
                        setCompanies={setCompanies} setCurrentCompany={setCurrentCompany} setIsAddingCompany={setisAddingCompany} />
                    : null
            }

            {!isModifyingCompany && !isAddingCompany && !isRestoring ?
                <View style={{ flex: 1, justifyContent: 'flex-start', marginHorizontal: 10 }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                        <Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Buyers</Text>
                    </View>


                    {enterprise != null && enterprise.length > 0 ?

<View>

<Carousel
            windowSize={1}
            vertical={true}
            enableSnap={true}
            enableMomentum={false}
            lockScrollWhileSnapping={true}
            loop={true}
            autoplayInterval={3000}
            autoplay={true}
            data={enterprise}
            firstItem={enterprise.length}
            useScrollView={true}
            initialScrollIndex={enterprise.length-3}
            scrollEnabled={false}
  
            renderItem={({item, index}) => {
                return (
                    <TouchableOpacity style={{flexDirection:'row',paddingHorizontal:7,marginVertical:5,justifyContent:'center',alignSelf:'center',borderWidth:3,borderColor:item.border_color??'black'}}
                    onPress={() => {
                        if(enterprise != null)
                        {
            
                            setAllowingSamples(true);
                        }
                    }}
                    >

                        {cachedEnterpriseImages.find(x => x.enterprise_id == item.id) ?
                        
                            <Image
                            source={{uri: cachedEnterpriseImages.find(x => x.enterprise_id == item.id).fileUri}}
                            style={{ height: Math.round(height / 8), width: Math.round(width/1.1) }}
                            />                        
                        : 
                         <CustomImage  enterpriseID={item.id} width={Math.round(width/1.1)} height={ Math.round(height / 8)} />
            }

                    </TouchableOpacity> 
                );
            }}
            sliderWidth={Math.round(width)}
            itemWidth={Math.round(width)}
            sliderHeight={Math.round(height/6.5)}
            itemHeight={Math.round(height/2)}
            />
</View>
: null}

                    <FlatList 
                   // style={{flex:1}}
                       data={companies.filter(x => x.expire_date == null)}
                       renderItem={({ item, index } : {item:Buyer,index:number}) =>
                       <View >
                               { enterprise.find(x => x.id == item.id && x.name == item.name && x.location == item.location)?

                        <View>
                <TouchableOpacity style={{flexDirection:'row',justifyContent:'center', alignSelf:'stretch', paddingVertical:7,marginVertical:5,borderWidth:3,borderColor: enterprise.find(x => x.id == item.id && x.name == item.name && x.location == item.location)?.border_color??'lightgrey'}}
                onPress={async() => {
                        setAllowingSamples(true);
                }}
            >
          
          <CustomImage enterpriseID={ enterprise.find(x => x.id == item.id && x.name == item.name && x.location == item.location)?.id??0} width={width/1.3} height={50} />
        </TouchableOpacity>
 
                               </View>
                                : 
                              
                                         
                           <View style={{flexDirection:'row', borderWidth:1, borderColor:'lightgrey', marginVertical:3,alignSelf:'stretch'}}>
                   <TouchableOpacity
                   accessibilityLabel={item.location + ' - ' + item.name}
style={{flex:1,flexDirection:'row',alignContent:'center',alignSelf:'center',padding:5,alignItems:'center'}} 
                      onPress= {() => {
   
                        setCurrentCompany(item)
                        const getPages = async() => {
                                        
                            let data =await GetLocalUserManual();
                            if(data != null)
                            {
                              currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'EditBuyer'))
                            }
                            else
                            {
                              currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='EditBuyer'));
                            }
                          };
                          getPages(); 
                           setisModifyingCompany(true);
                       }} >
                 
                        <Image style={{width:30, height:30,borderRadius: 5,
overflow: 'hidden'}} source={require('../../../Icons/BlueBin.png')}/>
                    
                      
                      
                       <Text style={{fontSize:16,alignSelf:'center',paddingRight:5,right:5,flex:1,textAlign:'right'}}>{item.location} - {item.name} </Text>
                            </TouchableOpacity>
                          </View> 
                          
                     }
                    
                       
                       </View>
                       }
                       keyExtractor={(item,index) => index.toString()}
                   >
                   </FlatList>

                   <VGButton role="PRIMARY" onPress={()=>{ setisAddingCompany(true);}}
                    >Add New Buyer/Broker</VGButton>

                    <VGButton role="SECONDARY"
                        onPress={async() => {
                            let data =await GetLocalUserManual();
                            if(data != null)
                            {
                              currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'RestoreObjects'))
                            }
                            else
                            {
                              currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='RestoreObjects'));
                            }
                            setIsRestoring(true);
                       
                        }}
                    >Restore Deleted Buyers</VGButton>


                </View>
                : null}
            {allowingSamples  ? <AllowSampleOverlay alreadyChecked={false } growerUsers={growerUser} setGrowerUser={setGrowerUser} allowedSamples={null} setAllowedSamples={null} sample={null} isVisible={allowingSamples} setVisible={setAllowingSamples} enterprises={enterprise}  /> : null} 


        </View>


    );
}

export default BuyerScreen;