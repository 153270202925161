import { Dimensions } from "react-native";
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import { SampleImage, SubSample } from "../../Storage/UserState";

import { ApiResponse } from "../../GeneralTypes";
import { ApiCall } from "../GeneralFunctions";


export async function TakeImage(): Promise<string> {

    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height


    let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        aspect: [4, 3],

        quality: .8,

        base64: true,

    });



    if (!result.cancelled) {

        const imageData = result as ImageInfo;
        const manipResult = ImageManipulator.manipulateAsync(imageData.uri, [{ resize: { width: width, height: 500 } }], { format: ImageManipulator.SaveFormat.PNG, base64: true })
        const base64data = (await manipResult).base64;

        if (base64data != undefined) {
            console.log('SSSS');
            return base64data.toString()
        }
        else {
            return null;
        }

    }
    else {
        return null;
    }
}


export async function ChooseImage(): Promise<string> {

    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height


    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        aspect: [4, 3],

        quality: .8,

        base64: true,

    });



    if (!result.cancelled) {

        const imageData = result as ImageInfo;
        const manipResult = ImageManipulator.manipulateAsync(imageData.uri, [{ resize: { width: width, height: 500 } }], { format: ImageManipulator.SaveFormat.PNG, base64: true })
        const base64data = (await manipResult).base64;

        if (base64data != undefined) {
            console.log('SSSS');
            return base64data.toString()
        }
        else {
            return null;
        }

    }
    else {
        return null;
    }
}

export async function SaveImage(sampleImage: SampleImage, uri: string) {
    const data = new FormData();

    data.append('sample_image', JSON.stringify(sampleImage, (k, v) => v ?? undefined));
    data.append('uri', uri);

    const status = await ApiCall<ApiResponse & { image: SampleImage }>("/api/save-sample-image", data, true);

    if (status?.response == 0) {
        return status.image;
    }
    else {
        return null;
    }
}

export async function UpdateImage(sampleImage: SampleImage, uri: string) {
    const data = new FormData();

    data.append('sample_image', JSON.stringify(sampleImage, (k, v) => v ?? undefined));
    data.append('uri', uri);

    const status = await ApiCall<ApiResponse & { image: SampleImage }>("/api/update-sample-images", data, true);

    if (status?.response == 0) {
        return status.image;
    }
    else {
        return null;
    }
}

async function SaveSampleImage(sourceImage: string, destinationImage: string, sample: SubSample, sampleType: string) {
    const formData = new FormData();

    if (sourceImage != '') {
        formData.append('source_uri', sourceImage);
    }
    if (destinationImage != '') {
        formData.append('destination_uri', destinationImage);
    }

    formData.append('sample', JSON.stringify(sample, (k, v) => v ?? undefined))
    formData.append('sample_type', sampleType)
    const wasLoginSuccessful = await ApiCall<ApiResponse>("/api/save-primary-sample-image", formData, true);

    if (wasLoginSuccessful != null) {
        console.log('SUCCESS');
        return wasLoginSuccessful;
    }
    else {
        return null;
    }
}