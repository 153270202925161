import { FontAwesome } from '@expo/vector-icons';
import React from 'react'
import { ColorValue, TextStyle, View } from 'react-native';
import Colours from '../Styles/Colours';

const IconSize = {
    "none": 0,
    "barcode": {name:"barcode", size:30},
    "picker": {name:"chevron-down", size:16},
    "back":{name:"chevron-left", size:35},
    "hamburger":{name:"navicon", size:35},
    "help":{name:"question-circle-o", size:35},
    "plus":{name:"plus", size:24},
    "happy":{name:"smile-o", size:24},
    "okay":{name:"meh-o", size:24},
    "sad":{name:"frown-o", size:24},
    "camera":{name:"camera", size:24},
    "list-open":{name:"caret-down", size:20},
    "list-closed":{name:"caret-right", size:20},
    "map":{name:"map-marker", size:24},
    "good": {name:"check-circle", size:20},
    "bad": {name:"exclamation-circle", size:20},
    "password-show": {name:"eye", size:30},
    "password-hide": {name:"eye-slash", size:30},
    "checked-icon":{name:'check-square',size:40},
    "unchecked-icon": {name:'square-o',size:40},
    "chevron-up": {name:'chevron-up',size:40},
    "chevron-down": {name:'chevron-down',size:40},
    "plus-square": {name:'plus-square-o',size:40}
}

export type IconNames = keyof typeof IconSize;

type proptype = {name:IconNames, style?:TextStyle, color?:ColorValue}

export function Icon(props:proptype){
    return (
        <>
            {props.name == "none" ?
            <></> 
            : 
            <View style={[{width:IconSize[props.name]["size"], height:IconSize[props.name]["size"], alignItems:"center"}, props.style??{}]}>
                <FontAwesome name={IconSize[props.name]["name"] as keyof typeof FontAwesome.glyphMap} size={IconSize[props.name]["size"]} style={{color:props.color??Colours.BLACK}} />
            </View>
            }
        </>
    )
}
