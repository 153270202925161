
export const AccuSamplerIdentifiers = {
     BT_SMARTSCOOP_ID: '00001000-ABBA-0000-0000-D104768A1214',
     BT_CURRENT_TIME_ID : '00001002-ABBA-0000-0000-D104768a1214',
     BT_TARGET_TIME_ID : '00001004-ABBA-0000-0000-D104768A1214',
     BT_RECORDED_TIME_ID : '00001005-ABBA-0000-0000-D104768A1214',
     BT_SAMPLES_REQUIRED_ID : '00001006-ABBA-0000-0000-D104768A1214',
     BT_SAMPLES_TAKEN_ID : '00001007-ABBA-0000-0000-D104768A1214',
     BT_BATTERY_VOLTAGE_ID : '00001011-ABBA-0000-0000-D104768A1214',
     BT_IS_PAUSED_ID : '00001008-ABBA-0000-0000-D104768A1214',
     BT_APP_SAMPLE_TAKEN_ID : '00001012-ABBA-0000-0000-D104768A1214',
};

export const UltraSamplerIdentifiers = {
    BT_ULTRASAMPLER_ID:'6e400001-b5a3-f393-e0a9-e50e24dcca9e',
    BT_WRITE_ID:'6e400002-b5a3-f393-e0a9-e50e24dcca9e',
    BT_READ_ID:'6e400003-b5a3-f393-e0a9-e50e24dcca9e',
    BT_TARGET_TIME_ID:'1004',
    BT_CURRENT_TIME_ID:'1002',
    BT_RECORDED_TIME_ID:'1005',
    BT_SAMPLES_REQUIRED_ID:'1006',
    BT_SAMPLES_TAKEN_ID:'1007',
    BT_BARRERY_VOLTAGE_ID:'1011',
    BT_IS_PAUSED_ID:'1008',
    BT_APP_SAMPLE_TAKEN_ID:'1012'
};