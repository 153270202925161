import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TouchableOpacity, Image, TextInput } from 'react-native';
import { Alert } from 'react-native';

import { SaveCompanyDetails, ValidateBuyerFields } from '../../../Helpers/Functions/Offline/BuyerFunctions';
import { Buyer } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import { GrowerUser } from "../../../Helpers/Storage/UserState";
import { useContext } from 'react';
import { defaultBuyer } from '../../../Helpers/Storage/DefaultStates';
import AppContext from '../../../Contexts/AppContext';
import VGButton from '../../../Components/Button/Button';
import VGInput from '../../../Components/Input/Input';
import { RequiredText } from '../../../Components/SimpleComponents';
import styles from '../../../Styles/Spacing';


function AddingBuyerScreen(props: { currentCompany: Buyer, setCurrentCompany: setProp<Buyer>, companies: Buyer[], setCompanies: setProp<Buyer[]>, setIsAddingCompany: setProp<boolean> }) {
    const nameRef = React.useRef(null);
    const locationRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const appContext = useContext(AppContext);

    async function onSavePress() {
        try {

            const errorMessageArray = ValidateBuyerFields(props.currentCompany);


            if(errorMessageArray.length <= 0)
            {
                const profile = await GetData('Profile');
                const status = await SaveCompanyDetails(props.currentCompany, profile);

                if (status != null) {
                    let oldList = props.companies;
                    oldList.splice(oldList.length - 1, 0, status);
                    props.setCompanies(oldList);
                    props.setIsAddingCompany(false);
                    props.setCurrentCompany({...defaultBuyer});
                  //  currentContext.setCurrentScreenName({id:'BuyerMenu'});
                }

            }
            else
            {
                let fullString = ``;
                errorMessageArray.forEach(error => {
                  fullString += error + `\n\n`;
                });

                  appContext.setAlertOptions({title:'Invalid Fields',desc:fullString,options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                        appContext.setShowAlert(false);}}
                  ]});
                   appContext.setShowAlert(true);
            }
         
        }
        catch
        {
            appContext.setAlertOptions({title:'Could not save buyer',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);   
        }

    }

    return (
        <View style={styles.MainContainer}>
            
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3, marginBottom: 20 }}>
                <Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Add Buyer</Text>
            </View>
            
            <RequiredText />
                
            <VGInput label="Buyer Name" required
                onChangeText={(text) => {
                        let oldCompany = props.currentCompany;
                        oldCompany.name = text;
                        props.setCurrentCompany({ ...oldCompany });
                    }}
            />
        
            <VGInput label="Location" required 
                onChangeText={(text) => {
                    let oldCompany = props.currentCompany;
                    oldCompany.location = text;
                    props.setCurrentCompany({ ...oldCompany });
                }}
            />

            <VGInput label="Buyer Email" required 
                keyboardType='email-address' 
                onChangeText={(text) => {
                    let oldCompany = props.currentCompany;
                    oldCompany.buyer_email = text;
                    props.setCurrentCompany({ ...oldCompany });
                }}
            />              

            <View style={{ flex: 1, justifyContent: 'flex-end' }}>

                <VGButton role="PRIMARY" onPress={onSavePress}>Save</VGButton>

                <VGButton role="CANCEL"
                    onPress={async () => {
                      //  currentContext.setCurrentScreenName({id:'BuyerMenu'});
                        props.setIsAddingCompany(false);
                    }}>Close</VGButton>
            </View>

        </View>
    )
}

export default AddingBuyerScreen;