import * as React from 'react';
import { DrawerActions, NavigationContainer, useIsFocused } from '@react-navigation/native';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList, GestureResponderEvent} from 'react-native';


import { Overlay } from "react-native-elements";
import { FontAwesome } from '@expo/vector-icons';
import { useContext } from 'react';
import AppContext from '../../Contexts/AppContext';
import VGButton from '../../Components/Button/Button';
import { setProp } from '../../Helpers/GeneralTypes';
import styles from '../../Styles/styles';
import textStyles from '../../Styles/Text';
function OverlayDropdown(props:{
    dropdownStatus:boolean,
    onCanceled: (event:GestureResponderEvent)=>void,
    listToSelectFrom:any[],
    propertiesToShow:string[],
    onSelected:(item:any)=>void,
    canAddItem?:boolean
    canAddItemName?:string
    canAddOnPress?: (item:any)=>void

})
{

    const renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: width,
              backgroundColor: "orange",
            }}
          />
        );
      };
    const height = Dimensions.get('window').height;
    const width = Dimensions.get('window').width;
    return (
        <Overlay  isVisible={props.dropdownStatus}  onBackdropPress={() => {props.onCanceled}}>

            <View style={{width:width/1.2,height:height/1.2}}>
            
            {props.listToSelectFrom.length == 0 ?
                <View style={{flex:1,alignSelf:'center'}}>
                <Text style={textStyles.title}>Nothing Found</Text>
                </View>
            : null}


            <FlatList 
        ItemSeparatorComponent={renderSeparatorView}
        keyExtractor={(item, index) => index.toString()}
              contentContainerStyle={{justifyContent:'space-evenly',alignContent:'flex-end'}}
              data={props.listToSelectFrom}
              renderItem={({ item }) =>
                <TouchableOpacity
                accessibilityLabel={
                    props.propertiesToShow.map((user, index) => (    
                      item[user.toString()]
                     )).toString()
                  }
                onPress={() => {props.onSelected(item)}}>

                    <Text style={{fontSize:24,padding:10}}> 
                    {props.propertiesToShow.map((property, index) => (    
                        item[property.toString()] + ' '
                    ))}
                    </Text>
                </TouchableOpacity>
              } />
            
            {props.canAddItem ?
                <VGButton role="PRIMARY"  onPress={props.canAddOnPress}>{props.canAddItemName}</VGButton>
            : null}
            <VGButton role="CANCEL" onPress={props.onCanceled}>Cancel</VGButton>
            </View>
        </Overlay>
    );
}
export default OverlayDropdown;