import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, TouchableOpacity, Image, Alert, useWindowDimensions, } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { FlatList } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome5';

import {  EquipmentBrand, EquipmentModel } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import { SaveEquipment, ValidateEquipmentModel,SaveEquipmentModel } from '../../../Helpers/Functions/Offline/EquipmentFunctions';
import OverlayPicker from '../../HelperScreens/OverlayPicker';
import { defaultEquipmentModel } from '../../../Helpers/Storage/DefaultStates';
import VGButton from '../../../Components/Button/Button';
import spacing from '../../../Styles/Spacing';
import VGInput from '../../../Components/Input/Input';
import textStyles from '../../../Styles/Text';
import AppContext from '../../../Contexts/AppContext';

type PropTypes = {
    equipmentBrand: EquipmentBrand;
    equipmentBrandList:EquipmentBrand[];
    setMode: React.Dispatch<React.SetStateAction<"add" | "view">>;
    equipmentModelList : EquipmentModel[];
    setEquipmentModelList: setProp<EquipmentModel[]>;
    equipmentModel : EquipmentModel;
    setEquipmentModel : setProp<EquipmentModel>;

};

function RequestEquipmentModel(props: PropTypes) {


    enum stateSelections {
        default,
        pickingMake,
        pickingEquipmentType,
    }

    const appContext = React.useContext(AppContext);
    const [equipmentModel,setEquipmentModel] = React.useState<EquipmentModel>({...defaultEquipmentModel});
    const [equipmentBrand,setEquipmentBrand] = React.useState<EquipmentBrand>({...props.equipmentBrand});
    const equipmentTypes = [{name:'Auger'}, {name:'Conveyor'}];
    const [selectedType,setSelectedType] = React.useState<{name:string}>({name:''});



    const [currentState,setCurrentState] = React.useState<stateSelections>(stateSelections.default);

    const nameRef = React.useRef(null);
    const bushelRef = React.useRef(null);

    React.useEffect(() => {
        setEquipmentModel({...equipmentModel,equipment_type:selectedType.name });

    },[selectedType])

    React.useEffect(() => {

    setEquipmentModel({...equipmentModel,brand_id:equipmentBrand.id });
    },[equipmentBrand])


    async function onRequestPress(){

        const profile = await GetData('Profile');
        let equip = equipmentModel;
        equip.profile = profile
        const errorList = ValidateEquipmentModel(equip);
        if(errorList.length == 0)
        {
            const savedModel = await SaveEquipmentModel(equip);
            if(savedModel != null)
            {
                props.setEquipmentModel(savedModel);
                let oldModelList = props.equipmentModelList;
                oldModelList.splice(oldModelList.length-1,0,savedModel);
                props.setEquipmentModelList(oldModelList);
                setEquipmentModel({...defaultEquipmentModel});
                setSelectedType({name:''});

                props.setMode('view');

            }
            else
            {
                appContext.setAlertOptions({title:'Could not add Equipment model',desc:'Please try again at a later time',options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                        appContext.setShowAlert(false);}}
                  ]});
                   appContext.setShowAlert(true);
            }
        }
        else
        {
            let fullString = '';
            errorList.forEach(error => {
                fullString += error + `\n\n`;
              });
              appContext.setAlertOptions({title:'Invalid Equipment',desc:fullString,options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);        }

    }


    return (
            <View style={spacing.MainContainer}>


            <View style={{ flex:1 }}>
                  <View style={spacing.ScreenHeader}>
                   <Text style={textStyles.title}>Request Equipment Model</Text>
               </View>

                <VGInput label="Make" type="picker"
                    onPress={()=>setCurrentState(stateSelections.pickingMake)}
                    value={equipmentBrand?.name??''}
                />
                

                <VGInput label='Name' required 
                    value={ equipmentModel?.name??''} 
                    onChangeText={(text) => {
                        setEquipmentModel({ ...equipmentModel, name: text });
                    }}
                />

                <VGInput label='Bushel Rating' required 
                    keyboardType="numeric"
                    value={equipmentModel.bushel_loading == 0 ? '' : equipmentModel.bushel_loading.toString()} 
                    onChangeText={(text) => {
                        if(isNaN(parseInt(text)) || text == ''){
                            setEquipmentModel({ ...equipmentModel, bushel_loading: 0 });
                        }
                        else{
                            setEquipmentModel({ ...equipmentModel, bushel_loading: parseInt(text) });
                        }
                    }}
                />

                <VGInput label="Equipment Type" type="picker"
                    onPress={()=>setCurrentState(stateSelections.pickingEquipmentType)}
                    value={selectedType?.name??''}
                />


            </View>

            

            <VGButton role="PRIMARY" onPress={onRequestPress}
            >Request</VGButton>

            <VGButton role="CANCEL" onPress={() => {
             props.setMode('view');
            }}
            >Close</VGButton>

            {stateSelections.pickingEquipmentType ? <OverlayPicker type={['name']} text={'Pick Equipment Type'} isVisible={currentState == stateSelections.pickingEquipmentType} overrideExit={false} setVisible={() => {setCurrentState(stateSelections.default);}} setData={setSelectedType} data={equipmentTypes} /> 
            : null}
            {stateSelections.pickingEquipmentType ? <OverlayPicker type={['name']} text={'Pick Equipment Make'} isVisible={currentState == stateSelections.pickingMake} overrideExit={false} setVisible={() => {setCurrentState(stateSelections.default);}} setData={setEquipmentBrand} data={props.equipmentBrandList} /> 
            : null}

            </View>
         

    );
}

export default RequestEquipmentModel;