import React, { MutableRefObject } from "react";
import { TouchableOpacity, View, Image, Text, Alert, Dimensions } from "react-native";
import { FlatList, TextInput } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import { GetData, GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";

import { ApiResponse } from "../../../Helpers/GeneralTypes";

import { AllowedSample, Bin, BinLocation, Buyer, CharacteristicSubOption, CharacteristicType, Enterprise, Field, GrainVariety, GrowerUser, Lab, MasterSample, PendingAnalysis, PendingBuyer, SampleRecordCharacteristic, SampleDestination, Document, SampleEquipment, SampleGrainVariety, SampleImage, SampleSource, SubSample, UserInfo, UserEquipment, InstrumentModel, UserInstrumentModel, GrainType, GrainSubCrop, ManualProgressTimes, CropSelectOption, CombinedSampleRecord } from "../../../Helpers/Storage/UserState";
import * as Progress from 'react-native-progress';

import moment from "moment";
import { ApiCall, ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import * as FileSystem from 'expo-file-system';
import NetworkContext from "../../../Contexts/NetworkContext";
import { Overlay } from "react-native-elements";
import { defaultDocument, defaultGrain, defaultGrainSubCrop, defaultMasterSample } from "../../../Helpers/Storage/DefaultStates";
import AddSampleCharacteristics from "../CharacteristicModals/AddSampleCharacteristics";
import Toast from "react-native-tiny-toast";
import { UpdateSampleRecordCharacteristic } from "../../../Helpers/Functions/Offline/CharacteristicFunctions";
import { ChooseImage, TakeImage } from "../../../Helpers/Functions/Online/ImageFunctions";
import Carousel from "react-native-snap-carousel";
import CustomImage from "../../HelperScreens/CustomImage";
import AddSampleDocument from "../DocumentModals/AddSampleDocument";
import ViewSampleDocument from "../DocumentModals/ViewSampleDocument";
import PendingAnalysisScreen from "../StackModals/PendingAnalysisScreen";
import AllowSampleOverlay from "../StackModals/AllowSampleOverlay";
import { GetAllUsers, GetGrowerList } from "../../../Helpers/Functions/Offline/UserFunctions-Offline";
import { GetGrainAndSubCropTypes } from "../../../Helpers/Functions/Grains";
import AppContext from "../../../Contexts/AppContext";
import styles from "../../../Styles/styles";
import textStyles from "../../../Styles/Text";

export type CombinedAnalysisRecords = { PendingAnalysis: PendingAnalysis, PendingBuyer: null } | { PendingAnalysis: null, PendingBuyer: PendingBuyer };

function SampleListScreen({ route, navigation }: { route: any, navigation: any }) {

    const currentContext = React.useContext(NetworkContext);
    const appContext = React.useContext(AppContext);

    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height

    const [showDetails, setShowDetails] = React.useState(false);
    const [addFromAnalysis, setAddFromAnalysis] = React.useState(false);

    let sampleCharRefs = React.useRef([])
    const [isLoading, setIsLoading] = React.useState(false);
    const [masterSampleList, setMasterSampleList] = React.useState<MasterSample[]>(route.params.master_sample_list)
    const [selectedSample, setSelectedSample] = React.useState<MasterSample>({ ...defaultMasterSample })
    const [selectedAnalysisGrain, setSelectedAnalysisGrain] = React.useState<{grainType:GrainType,grainSubCrop:GrainSubCrop}>({grainType:defaultGrain,grainSubCrop:defaultGrainSubCrop });
    const [sampleSourceList, setSampleSourceList] = React.useState<SampleSource[]>([]);
    const [sampleDestinationList, setSampleDestinationList] = React.useState<SampleDestination[]>([]);
    const [sampleEquipmentList, setsampleEquipmentList] = React.useState<SampleEquipment[]>([]);
    const [sampleGrainList, setSampleGrainList] = React.useState<SampleGrainVariety[]>([]);
    const [primarySamples, setPrimarySamples] = React.useState<MasterSample[]>([]);
    const [sampleCharacteristics, setSampleCharacteristics] = React.useState<SampleRecordCharacteristic[]>([]);
    const [allowedSamples, setAllowedSamples] = React.useState<AllowedSample[]>([]);

    //How to deal with sub samples? Grab by both master and parent id?

    //The masterSample in this one, refers to the Parent
    const [foundSubSamples, setFoundSubSamples] = React.useState<{parentMasterSample:MasterSample,subSample:SubSample}[]>([]);
    
    //Found combined
    const [combinedSamples,setCombinedSamples] = React.useState<{parentSample:CombinedSampleRecord,childRecords:MasterSample[]}[]>([]);
    //The masterSample in this one refers to tie record directly related to itself
    const [childSubSamples, setChildSubSamples] = React.useState<{masterSample:MasterSample,subSample:SubSample}[]>([]);
    const [foundPrimarySamples, setFoundPrimarySamples] = React.useState<MasterSample[]>([]);
    const [growerUser, setGrowerUser] = React.useState<GrowerUser[]>([]);

    const [manualSampleTimes,setManualSampleTimes] = React.useState<ManualProgressTimes[]>([]);
    //Used for updating characteristic value on this screen
    const [selectedCharacteristic, setSelectedCharacteristics] = React.useState<SampleRecordCharacteristic>()
    const [fields, setfields] = React.useState<Field[]>([]);
    const [bins, setbins] = React.useState<Bin[]>([]);
    const [binLocations, setBinLocations] = React.useState<BinLocation[]>([]);
    const [buyers, setBuyers] = React.useState<Buyer[]>([]);
    const [enterprises, setEnterprises] = React.useState<Enterprise[]>([]);
    const [cachedEnterpriseImages, setCachedEnterpriseImages] = React.useState<{ enterprise_id: number, fileUri: string }[]>([]);
    const [userInstrumentModels, setUserInstrumentModels] = React.useState<UserInstrumentModel[]>([]);
    const [instrumentModels,setInstrumentModels] = React.useState<InstrumentModel[]>([]);
    const [equipment, setEquipment] = React.useState<UserEquipment[]>([]);
    const [grains,setGrains] = React.useState<{grainType:GrainType,grainSubCrop:GrainSubCrop}[]>([]);
    const [varieties, setVarieties] = React.useState<GrainVariety[]>([]);
    const [cropSelectOptions,setCropSelectOptions] = React.useState<CropSelectOption[]>([]);
    const [characteristicTypes, setCharacteristicTypes] = React.useState<CharacteristicType[]>([]);
    const [characteristicSubOptions, setCharacteristicSubOptions] = React.useState<CharacteristicSubOption[]>([]);
    const [labs, setLabs] = React.useState<Lab[]>([]);
    const [pendingAnalysis, setPendingAnalysis] = React.useState<PendingAnalysis[]>([]);
    const [sharedSamples, setSharedSamples] = React.useState<MasterSample[]>([]);
    //THIS IS ONLY USED WHEN ONLINE
    const [images, setImages] = React.useState<SampleImage[]>([]);
    const [documents, setDocuments] = React.useState<Document[]>([]);
    const [selectedDocument, setSelectedDocument] = React.useState<Document>({ ...defaultDocument });

    const [users,setUsers] = React.useState<UserInfo[]>([]);

    const [sampleQuality,setSampleQuality] = React.useState<{master_sample_id:number,quality:number}[]>([]);
    //an enum of dataPickers, primarily used to move to different overlay screens within the
    //sample screen
    const enum dataPick {
        UpdateEquipment,
        UpdateSource,
        UpdateDestination,
        AddCharacteristics,
        AddDocument,
        SplitSample,
        AddSampleAnalysis,
        ViewAnalysisPending,
        ShareSample,
        Default,
        ViewDocument,
        AllowSample
    };
    const [pickingData, setPickingData] = React.useState<dataPick | null>(dataPick.Default);



    React.useEffect(() => {
        const getUserData = async() => {
            const profile = await GetData('Profile');
            let userData = await GetAllUsers();
            setUsers(userData);

        }
        getUserData();
          
    },[])
    //Since MMKV is so fast, it's better to grab all neccessary data once and store in state objects.
    //Rather than looping through each sample and adding the information one by one
    React.useEffect(() => {

        const mList = GetOfflineStateParsed("master_samples");
        //If the master sample list is not null and there is at least 1 sample record
        if (masterSampleList != null && masterSampleList.length > 0) {


            const manualSampleTimeList = GetOfflineStateParsed('manual_progress_times').filter(function (progressTime) {

                if (masterSampleList.find(x => x.id == progressTime.master_sample_id)) {
                    return progressTime;
                }
            });
            setManualSampleTimes(manualSampleTimeList);
            //Grab Sample Source List based on master samples
            const sampleSourceList = GetOfflineStateParsed("sample_source").filter(function (sampleSource) {

                if (masterSampleList.find(x => x.id == sampleSource.master_sample_id)) {
                    return sampleSource;
                }
            });
            setSampleSourceList(sampleSourceList);

            const sampleEquipmentLists = GetOfflineStateParsed("sample_equipment").filter(function (sampleEquipment) {

                if (masterSampleList.find(x => x.id == sampleEquipment.master_sample_id)) {
                    return sampleEquipment;
                }
            });
            setsampleEquipmentList(sampleEquipmentLists);

            //Grab Sample Destination List based on master samples
            const sampleDestinationList = GetOfflineStateParsed("sample_destination").filter(function (sampleDestination) {

                if (masterSampleList.find(x => x.id == sampleDestination.master_sample_id)) {
                    return sampleDestination;
                }
            });
            setSampleDestinationList(sampleDestinationList);




            const sampleImages = GetOfflineStateParsed("sample_images").filter(function (sampleImage) {

                if (masterSampleList.find(x => x.id == sampleImage.master_sample_id)) {
                    return sampleImage;
                }
            });
            setImages(sampleImages);

            const pendingAnalysisList = GetOfflineStateParsed("pending_analysis").filter(function (pending) {

                if (masterSampleList.find(x => x.id == pending.sample_id)) {
                    return pending;
                }
            });
            setPendingAnalysis(pendingAnalysisList);

            const documentList = GetOfflineStateParsed("documents").filter(function (document) {

                if (masterSampleList.find(x => x.id == document.master_sample_id)) {
                    return document;
                }
            });
            setDocuments(documentList);

            const allowedSampleList = GetOfflineStateParsed("allowed_samples").filter(function (allowed) {

                if (masterSampleList.find(x => x.id == allowed.master_sample_id)) {
                    return allowed;
                }
            });
            setAllowedSamples(allowedSampleList);

            //Get sub samples found explicitly through the search screen
            const subSampleList = GetOfflineStateParsed("sub_samples");

            //The found Sample List is when the user explicitly searched for a sub sample
            const foundsubList = subSampleList.filter(function (subSample) {
                if (masterSampleList.find(x => x.id == subSample.master_sample_id)) {
                    return subSample;
                }
            });

            let parentMList:{parentMasterSample:MasterSample,subSample:SubSample}[]  = [];

            foundsubList.forEach(fsl => {

                const m = mList.find(x => x.id == fsl.parent_sample_id);
                if(m != null)
                {
                    parentMList.push({parentMasterSample:m,subSample:fsl});
                }
            })

         

            setFoundSubSamples(parentMList);

            let parentCList: {parentSample:CombinedSampleRecord,childRecords:MasterSample[]}[] = [];
            
            const parentRecords = GetOfflineStateParsed('combined_sample_records').filter(function(c) {
                if(mList.find(x => x.id == c.master_sample_id))
                {
                    return c;
                }
            });
            parentRecords.forEach(record => {
                const child = mList.filter(x => x.id == record.parent_sample_id);
                parentCList.push({parentSample:record,childRecords:child});
            })
            setCombinedSamples(parentCList);

            

            const primarySampleList = GetOfflineStateParsed("master_samples").filter(function (primarySample) {
                if (subSampleList.find(x => x.parent_sample_id == primarySample.id)) {
                    return primarySample;
                }
            });
            setPrimarySamples(primarySampleList);

            //The childSub list is when the user explicitly searced for a primary sample
            const childsubList:{masterSample:MasterSample,subSample:SubSample}[] = [];
            
            subSampleList.forEach(function (subSample) {
               
       
                const master = mList.find(x => x.id == subSample.parent_sample_id);
                if(master != null)
                {
                    console.log('Sub List found' + subSample.parent_sample_id);
                    const directMaster = mList.find(x => x.id == subSample.master_sample_id);
                    childsubList.push({masterSample:directMaster,subSample:subSample});
                }
            });
            setChildSubSamples(childsubList);

            //Because the barcodes are stored on the master sample list, we'll probably want to change things up a bit. 




            const foundPrimaryList = GetOfflineStateParsed('master_samples').filter(function (primarySample) {
                if (foundSubSamples.find(x => x.subSample.parent_sample_id == primarySample.id)) {
                    return primarySample;
                }
            });

            setFoundPrimarySamples(foundPrimaryList);


            setSampleDestinationList(sampleDestinationList);

            const sampleGrain = GetOfflineStateParsed('sample_grain_varieties').filter(function (grain) {
                if (masterSampleList.find(x => x.id == grain.master_sample_id)) {
                    return grain;
                }
            });
            setSampleGrainList(sampleGrain);



            let refs: MutableRefObject<any[]> = { current: [] };
            const sampleCharList = GetOfflineStateParsed("sample_record_characteristics").filter(function (sampleChar) {


                if (masterSampleList.find(x => x.id == sampleChar.master_sample_id)) {

                    return sampleChar;
                }
            });

            setSampleCharacteristics(sampleCharList);

        }


        //IF user is online, we can pull certificates and images!
        if (!currentContext.isOffline) {

        }
    }, [masterSampleList])





    React.useEffect(() => {

        const growerStatus = async () => {

            const id = await GetData('ID');
            const grower = await GetGrowerList();

            if (grower != null) {
                setGrowerUser(grower);
            }
            else {

                console.log('Could not find grower record with an enterprise account tied to it');
            }
        };
        growerStatus();
    }, [])


    //This works in our favour as we can use this list to update samples!
    //We could filter this even more by using the sampleSource/Destination list after the fact
    //But I have not ran into any serious performance hits yet.
    React.useEffect(() => {
        const binList = GetOfflineStateParsed('bins');
        setbins(binList);
        const binLocationList = GetOfflineStateParsed('bin_locations');
        setBinLocations(binLocationList);
        const fieldList = GetOfflineStateParsed('fields');
        setfields(fieldList);
        const buyerList = GetOfflineStateParsed('buyers');
        setBuyers(buyerList);

        const userInstrumentModelList = GetOfflineStateParsed('user_instrument_models');
        
        console.log('-----------INSTRU-----------\n' + JSON.stringify(userInstrumentModelList));
        setUserInstrumentModels(userInstrumentModelList);

        const instrumentModels = GetOfflineStateParsed('instrument_models');

        setInstrumentModels(instrumentModels);


        const enterpriseList = GetOfflineStateParsed('enterprises');
        setEnterprises(enterpriseList);


        if (enterpriseList != null) {
            //Check filesystem for any cached enterprise logo's 
            const cache = async () => {
                let cachedImages: { enterprise_id: number, fileUri: string }[] = []

                for (let enterprise of enterpriseList) {
                    //const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;

                    const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;

                    const image = await FileSystem.getInfoAsync(path);

                    if (image.exists) {
                        cachedImages.push({ enterprise_id: enterprise.id, fileUri: image.uri });
                    }


                }
                setCachedEnterpriseImages(cachedImages);
            }
            cache();
        }



        const equipmentList = GetOfflineStateParsed('user_equipment');
        setEquipment(equipmentList);

        const crops = GetOfflineStateParsed('crop_select_options');
        setCropSelectOptions(crops);
        let grainAndSubCrop = GetGrainAndSubCropTypes();
        setGrains(grainAndSubCrop.types);
        setVarieties(grainAndSubCrop.varieties);

        const labs = GetOfflineStateParsed('labs');
        setLabs(labs);
        const charTypes = GetOfflineStateParsed('characteristic_types');
        setCharacteristicTypes(charTypes);
        const charOptions = GetOfflineStateParsed('characteristic_sub_options');
        setCharacteristicSubOptions(charOptions);

        let qualityArray = [];
        masterSampleList.forEach(sample => {
            const times = manualSampleTimes.filter(x => x.master_sample_id == sample.id);
            const quality = GetManualSampleQuality(sample,times);
            qualityArray.push({master_sample_id:sample.id,quality:quality});
        })
        setSampleQuality([...qualityArray]);


    }, [masterSampleList])

    //This function will attempt to grab the destination name based on either bin,buyer, or enterprise
    function FindDestinationNameByMasterSampleID(masterSampleID: number) {

        const sdList = sampleDestinationList?.find(s => s.master_sample_id == masterSampleID);

        if(sdList?.destination_bin_id != null)
        {
            const bin = bins?.find(x => x.id == sampleDestinationList.find(s => s.master_sample_id == masterSampleID)?.destination_bin_id)
            const binLoc = binLocations.find(x => x.id == bin?.location_id);
            if (bin != null) {
                return binLoc.location + '_' + bin.name;
            }
        }
        else if(sdList?.destination_buyer_id != null)
        {
            const buyer = buyers?.find(x => x.id == sampleDestinationList.find(s => s.master_sample_id == masterSampleID)?.destination_buyer_id)

            if (buyer != null) {
                return buyer.name;
            }
        }
        else if(sdList?.destination_enterprise_id != null)
        {
            const enterprise = enterprises?.find(x => x.id == sampleDestinationList.find(s => s.master_sample_id == masterSampleID)?.destination_enterprise_id)
            if (enterprise != null) {
                return enterprise.location + '_' + enterprise.name;
            }
        }
    
        return 'N/A';
    }

    //This function will attempt to grab the source name base don either field, or bin
    function FindSourceNameFromMasterSampleID(masterSampleID: number) {

        const ssList = sampleSourceList?.find(s => s.master_sample_id == masterSampleID);

        if(ssList?.source_bin_id != null)
        {
            const bin = bins?.find(x => x.id == sampleSourceList?.find(s => s.master_sample_id == masterSampleID)?.source_bin_id)

            const binLoc = binLocations.find(x => x.id == bin?.location_id);
            if (bin != null && binLoc != null) {
                return binLoc.location + '_' + bin.name;
            }
            else
            {
                return 'N/A';
            }
    
        }
        else
        {
            let fieldList: Field[] = [];

            sampleSourceList.map(x => {
                if (x.master_sample_id == masterSampleID) {
                    fieldList.push(fields.find(j => j.id == x.source_field_id));
                }
            })
            if (fieldList.length > 1) {
                return 'Multiple Fields';
            }
            else if (fieldList != null && fieldList.length == 1) {
                return fieldList[0]?.name??'a field';
            }
        }
  
   
        return 'N/A';


    }


    function FindEquipmentNameFromMasterSampleID(mastersampleID: number) {
        const equipmentList = equipment.find(x => x.id == sampleEquipmentList?.find(j => j.master_sample_id == mastersampleID)?.equipment_id);

        if (equipmentList != null) {
            return equipmentList.name;
        }

        return 'N/A';
    }
    function FindGrainNameFromMasterSampleID(masterSample:MasterSample) {

        let grainName = 'N/A'
        const grainType =grains.find(x => x.grainType.id == masterSample.grain_type_id );

        if (grainType != null) {

            console.log('Grain found!');
            grainName = grainType.grainType.name;
            
            const grainSub = grains.find(x => x.grainSubCrop.id == masterSample.sub_crop_id)
           
            if(grainSub != null)
            {
                if(grainType.grainSubCrop != null && grainType.grainSubCrop.name != '' && grainType.grainSubCrop.name != null)
                {
                    grainName += ' - ' + grainType.grainSubCrop.name;
                }
            }

            const sampleGrainVar = sampleGrainList.filter(x => x.master_sample_id == masterSample.id);
            if(sampleGrainVar.length > 0)
            {
                if(sampleGrainVar.length == 1 && sampleGrainVar[0] != undefined)
                {
                    const varName = varieties?.find(x => x.id == sampleGrainVar[0]?.grain_variety_id);
                    if(varName?.name != undefined)
                    {
                        grainName += ' - ' + varName?.name??'';

                    }
                }
                else
                {
                    grainName += ' - Multiple Varieties'
                }
            }
            return grainName;
        }

        return 'N/A';
    }

    function GrabDataToPopulateSubSample(id: number): { sample: MasterSample, source: { field: Field[] | null; bin: { bin: Bin, location: BinLocation } | null }; destination: { bin: { bin: Bin, location: BinLocation } | null; buyer: Buyer | null; enterprise: Enterprise | null; }; volume: number; equipment: SampleEquipment; grain: { grain: GrainType; subCrop:GrainSubCrop; grainVarieties: GrainVariety[] | null; }; } {
        let current: { sample: MasterSample, source: { field: Field[] | null; bin: { bin: Bin, location: BinLocation } | null; }; destination: { bin: { bin: Bin, location: BinLocation } | null; buyer: Buyer | null; enterprise: Enterprise | null; }; volume: number; equipment: SampleEquipment; grain: { grain: GrainType; subCrop:GrainSubCrop; grainVarieties: GrainVariety[] | null; }; } = { sample: { ...defaultMasterSample }, source: { field: null, bin: null }, destination: { buyer: null, bin: null, enterprise: null }, equipment: null, grain: { grain: { ...defaultGrain },subCrop:{...defaultGrainSubCrop}, grainVarieties: null }, volume: 0 };

        current.sample = masterSampleList.find(x => x.id == id);

        const sampleSourceLists = sampleSourceList.filter(function (sampleSource) {

            if (id == sampleSource.master_sample_id) {
                return sampleSource;
            }
        });

        if (sampleSourceLists[0].source_field_id != null) {

            const fieldsList = fields.filter(function (field) {
                return sampleSourceList.some(x => x.source_field_id == field.id);
            });
            current.source.field = fieldsList;
            current.source.bin = null;
        }
        else if (sampleSourceLists[0].source_bin_id != null) {
            const bin = bins.find(x => x.id == sampleSourceList[0].source_bin_id);
            const binLocation = binLocations.find(x => x.id == bin?.location_id);
            current.source.field = null;
            current.source.bin = { bin: bin, location: binLocation };

        }






        const sampleDestinationLists = sampleDestinationList.find(x => x.master_sample_id == id);

        console.log(JSON.stringify(sampleDestinationLists));
        if (sampleDestinationLists.destination_bin_id != null) {
            const bin = bins.find(x => x.id == sampleDestinationLists.destination_bin_id);
            const binLocation = binLocations.find(x => x.id == bin?.location_id);
            current.destination.enterprise = null;
            current.destination.buyer = null;
            current.destination.bin = { bin: bin, location: binLocation };
        }
        else if (sampleDestinationLists.destination_buyer_id != null) {
            const buyer = buyers.find(x => x.id == sampleDestinationLists.destination_buyer_id);
            current.destination.enterprise = null;
            current.destination.buyer = buyer;
            current.destination.bin = null;
        }
        else if (sampleDestinationLists.destination_enterprise_id != null) {
            const enterprise = enterprises.find(x => x.id == sampleDestinationLists.destination_enterprise_id);
            current.destination.enterprise = enterprise;
            current.destination.buyer = null;
            current.destination.bin = null;
        }


        const sampleEquipment = sampleEquipmentList?.find(x => x.master_sample_id == id);
        current.equipment = sampleEquipment;

        const grainType = grains?.find(x => x.grainType.id == current.sample.grain_type_id).grainType;
        const grainSubCrop = grains?.find(x => x.grainSubCrop.id == current.sample.sub_crop_id).grainSubCrop;

        const grainVarieties = varieties.filter(function (grainVar) {
            return sampleGrainList.some(x => x.grain_variety_id == grainVar.id);
        });
        current.grain.grain = grainType;
        current.grain.subCrop = grainSubCrop;
        current.grain.grainVarieties = grainVarieties;

        const primary = primarySamples.find(x => x.id == id);
        if (primary != null) {
            current.volume = primary.volume;
        }

        return current;
    }


    function GetManualSampleQuality(item: MasterSample,manualSampleTimes:ManualProgressTimes[]):number {
        
       const foundSampleTimes = manualSampleTimes.filter(x => x.master_sample_id == item.id);

       if(foundSampleTimes != null && foundSampleTimes?.length > 0)
       {
        let total = 0;
        foundSampleTimes.forEach(time => {
            total += time.time;
        });
  
        const avg = total / foundSampleTimes.length;

        //Grab the remaining sample count!
        const samplesRemainingCount = item.samples_required - foundSampleTimes.length;
       if(!isNaN(avg))
       {



        const PerfectQuality = item.target_time * .5;
        const OkayQuality = item.target_time * .7;
   
      
        if(avg <= item.target_time + PerfectQuality && avg >=  item.target_time - PerfectQuality )
        {
      
          return 3;
        }
        else if (avg <= item.target_time + OkayQuality && avg >=  item.target_time - OkayQuality )
        {
            return 2;
        }
        else
        {
            return 1;
        }

    
       }
       }
       else
       {
        return 0;
       }
    }

    function OnRefresh()
        {
            console.log('refreshing');
            const userInstrumentModelList = GetOfflineStateParsed('user_instrument_models');
        
            setUserInstrumentModels(userInstrumentModelList);
    
            const instrumentModels = GetOfflineStateParsed('instrument_models');
    
            setInstrumentModels(instrumentModels);
        }

    function FindSampleTypeName(manual_sample_type_id: number) {

        try
        {
            switch(manual_sample_type_id)
            {
                case 0:
                    return 'Manual'
                break;
                case 1:
                    return 'Standard'
                break;
                case 2:
                    return 'AccuSampler'
                break;
                case 3:
                    return 'SmartSampler'
                break;
                case 4:
                    return 'Manual'
                break;
                case 5:
                return 'Ultra Sampler'
                break;
                default:
                    return 'Standard';
                break;
            }
        }
        catch(error)
        {
            return 'Standard'
        }
    }

    return (

        <>

            <Overlay overlayStyle={{ backgroundColor: 'rgba(0,0,0,0)', shadowColor: 'rgba(0,0,0,0)' }} isVisible={isLoading} >
                <Progress.CircleSnail size={Dimensions.get('screen').width / 1.75} color={'orange'} thickness={10} />
                <Text style={{ alignSelf: 'center', color: 'white', fontSize: 22 }}>Fetching Data...</Text>
            </Overlay>

            <View style={{ flex: 1, justifyContent: 'space-evenly', marginHorizontal: 10 }}>

                {/* Summary Detail Row */}
                <View style={{ borderColor: 'lightgrey', flexDirection: 'column', borderWidth: 1, marginVertical: 5, paddingVertical: 3, justifyContent: 'space-between', alignItems: 'flex-start' }}>

                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10, marginHorizontal: 5 }}>
                        <TouchableOpacity style={{ flexDirection: 'row', flex: 1 }}
                            onPress={() => { setShowDetails(false) }}
                        >
                            <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', marginLeft: 5 }} source={!showDetails ? require('../../../Icons/OrangeHamMenu.png') : require('../../../Icons/BlueHamMenu.png')} />
                            <Text style={{ paddingLeft: 5, fontSize: 16, alignSelf: 'center' }}>Summary</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{ flexDirection: 'row' }}
                            onPress={() => { setShowDetails(true) }}
                        >
                            <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', marginLeft: 5, alignSelf: 'flex-end' }} source={showDetails ? require('../../../Icons/OrangeHamMenu.png') : require('../../../Icons/BlueHamMenu.png')} />
                            <Text style={{ paddingLeft: 5, fontSize: 16, alignSelf: 'center' }}>Details</Text>
                        </TouchableOpacity>
                    </View>



                    <View style={{ flexDirection: 'row', marginVertical: 5, marginHorizontal: 5, paddingTop: 10 }}>
                        <TouchableOpacity style={{ flexDirection: 'row', alignContent: 'center', alignSelf: 'center', marginRight: 15 }} onPress={async () => {

                            if (currentContext.isOffline) {
                                
                                appContext.setAlertOptions({title:'Cannot Send',desc:'You must be online to share sample records',options:[
                                    {text:'OK', role:'PRIMARY', onPress:() => {
                                     
                                        appContext.setShowAlert(false);
                                      }}
                                  ]});
                                   appContext.setShowAlert(true); 
                            }
                            else if (sharedSamples.length > 0) {
                                navigation.navigate('ShareScreen', { shareSampleList: sharedSamples });
                            }
                            else {
                            
                                appContext.setAlertOptions({title:'No Samples Selected',desc:'Plese select at least 1 sample using the Share Select Feature',options:[
                                    {text:'OK', role:'PRIMARY', onPress:() => {
                                     
                                        appContext.setShowAlert(false);
                                      }}
                                  ]});
                                   appContext.setShowAlert(true); 
                            }

                        }}>
                            <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', marginLeft: 5 }} source={require('../../../Icons/BlueShare.png')} />
                            <Text style={{ fontSize: 16, alignSelf: 'center', paddingLeft: 5 }}>Share Send</Text>
                        </TouchableOpacity>
                    </View>

                </View>
                {/*End Of Summary Detail Row */}

                {/* Sample Scroll View */}
                <FlatList
                    initialNumToRender={5}
                    maxToRenderPerBatch={5}
                    windowSize={5}
                    data={masterSampleList}
                    keyExtractor={(item, index) => item.id.toString()}
                    renderItem={({ item }: { item: MasterSample }) => (

                        <>

                            {enterprises != null && enterprises.length > 0 ?

                                <Carousel
                                    windowSize={1}
                                    vertical={true}
                                    enableSnap={true}
                                    enableMomentum={false}
                                    lockScrollWhileSnapping={true}
                                    loop={true}
                                    autoplayInterval={2500}
                                    autoplay={true}
                                    data={enterprises}
                                    firstItem={enterprises.length}
                                    useScrollView={true}
                                    initialScrollIndex={enterprises.length - 3}
                                    scrollEnabled={false}

                                    renderItem={({ item, index }) => {
                                        return (
                                            <TouchableOpacity style={{ flexDirection: 'row', paddingHorizontal: 7, marginVertical: 5, justifyContent: 'center', alignSelf: 'center', width: width / 1.05, borderWidth: 3, borderColor: item.border_color ?? 'black' }}
                                                onPress={() => {
                                                    setSelectedSample(item);
                                                    setPickingData(dataPick.AllowSample);
                                                }}
                                            >

                                                {cachedEnterpriseImages.find(x => x.enterprise_id == item.id) ?
                                                    <Image
                                                        source={{ uri: cachedEnterpriseImages.find(x => x.enterprise_id == item.id).fileUri }}
                                                        style={{ width: Math.round(width / 1.1), height: Math.round(height / 8) }}
                                                     
                                                    />
                                                    :
                                                    <CustomImage enterpriseID={item.id} width={Math.round(width / 1.1)} height={Math.round(height / 8)} />
                                                }

                                            </TouchableOpacity>
                                        );
                                    }}
                                    sliderWidth={Math.round(width)}
                                    itemWidth={Math.round(width)}
                                    sliderHeight={Math.round(height / 6.5)}
                                    itemHeight={Math.round(height / 5)}
                                />
                                : null}

                            <View style={{ flexDirection: 'row', borderColor: 'orange', borderWidth: 2, marginVertical: 5, alignItems: 'center', paddingVertical: 3, paddingLeft: 5, paddingRight: 5 }}>
                                <Image style={{ width: 30, height: 30 }} source={require('../../../Icons/BlueBarcode.png')} />
                                <Text style={{ flex: 1, marginLeft: 5, left: 5, fontSize: 16, color: 'grey' }}>Barcode</Text>

                                <Text accessible={true} accessibilityLabel='Input_Barcode' style={{ fontSize: 16 }}>
                                    {foundSubSamples.find(x => x.subSample.master_sample_id == item.id) ? '[Sub] ' : combinedSamples.find(x => x.parentSample.master_sample_id == item.id) ? '[Combined] ' :  '[Primary] '}
                                    {item?.barcode}</Text>
                            </View>

                            {item.manual_sample_type_id != null ?
                      
                                      <TouchableOpacity style={{ flexDirection: 'row', borderColor: 'lightgrey', borderWidth: 2, marginVertical: 5, alignItems: 'center', paddingVertical: 10, paddingLeft: 5, paddingRight: 5 }} onPress={() => {
                                                    navigation.navigate('SampleProgressReport',{sample:item,users:users});
                                                }}>
                                    <Text style={{ flex: 1, marginLeft: 5, left: 5, fontSize: 16, color: 'black' }}>Quality:</Text>
                                    <Icon style={{ paddingRight: 10 }} name={sampleQuality.find(x => x.master_sample_id == item.id)?.quality??0 > 0 ? 'star' : 'star-o'} size={25} color="black" />
                                    <Icon style={{ paddingRight: 10 }} name={sampleQuality.find(x => x.master_sample_id == item.id)?.quality??0 > 1 ? 'star' : 'star-o'} size={25} color="black" />
                                    <Icon style={{ paddingRight: 10 }} name={sampleQuality.find(x => x.master_sample_id == item.id)?.quality??0> 2 ? 'star' : 'star-o'} size={25} color="black" />

                                                    
                                                </TouchableOpacity>
                                
                                : null}



                            {/* Sample Analysis Buttons (Adding Characteristics, lab results,etc) */}
                            <View>
                                <TouchableOpacity
                                accessibilityLabel="Sample_Analysis_Button"
                                onPress={() => {

                                    setSelectedSample(item);
                                    const grainFound = grains.find(x => x.grainType.id == item.grain_type_id && x.grainSubCrop.id == item.sub_crop_id);

                                    if (grainFound != null) {
                                        setSelectedAnalysisGrain(grainFound);
                                        setAddFromAnalysis(true);
                                    }
                                    setPickingData(dataPick.AddCharacteristics);
                                }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', backgroundColor: 'orange', paddingVertical: 7, marginVertical: 5 }}>
                                        <Text style={{ color: 'white', fontSize: 20, alignSelf: 'center' }}>Sample Analysis</Text>
                                    </View>
                                </TouchableOpacity>


                                <TouchableOpacity style={{
                                    flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch',
                                    backgroundColor: sampleCharacteristics.filter(x => x.master_sample_id == item.id && x.value == null).length > 0 ?'orange' : 'grey' , paddingVertical: 7, marginVertical: 5
                                }}
                                    onPress={() => {
                                        setSelectedSample(item);
                                        setPickingData(dataPick.ViewAnalysisPending)
                                    }}>
                                    <Text style={{ color: 'white', fontSize: 20, alignSelf: 'center' }}>Analysis Pending</Text>
                                </TouchableOpacity>


                            </View>
                            {/* End of Sample Analysis Buttons */}



                            <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5 }}>

                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                        onPress={() => {
                                            let oldShare = sharedSamples;
                                            if (oldShare.find(x => x == item)) {
                                                console.log('removing');
                                                oldShare = oldShare.filter(x => x != item);
                                            }
                                            else {
                                                console.log('adding');
                                                oldShare.push(item);
                                            }
                                            setSharedSamples([...oldShare]);
                                        }}>
                                        {sharedSamples.find(x => x == item) ?
                                            <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/OrangeShare.png')} />
                                            :
                                            <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/BlueShare.png')} />
                                        }

                                        <Text style={{ paddingLeft: 10, fontSize: 16, alignSelf: 'center', color: 'black' }}>Share Select</Text>
                                    </TouchableOpacity>
                                </View>

                            </View>


                            {combinedSamples.find(x => x.parentSample.master_sample_id == item.id) ?

<View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>

<View><Text style={textStyles.label}>Combined Samples</Text></View>
                                  <FlatList
                                  data={combinedSamples.find(x => x?.parentSample?.master_sample_id == item.id)?.childRecords}
                                  keyExtractor={(item5, index) => item5?.id?.toString()??index + '_Sample' + item?.id + index}
                                  renderItem={({ item }: { item: MasterSample }) => (
                                      <TouchableOpacity accessibilityLabel={'Sub_' + item?.barcode??''} style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => {
                                            
                                      }}>
                                          <Image style={{ width: 30, height: 30 }} source={require('../../../Icons/BlueBarcode.png')} />
                                          <Text style={{ flex: 1, paddingLeft: 10, fontSize: 16 }}>Sample ID</Text>
                                          <Text style={{ paddingRight: 10, fontSize: 16 }}> {item?.barcode??'N/A'} </Text>

                                      </TouchableOpacity>
                                  )}></FlatList>
                                  </View>
                            :
                                  <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>

                                  <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                      <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                          onPress={() => {
                                          }}>
                                          <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueDateTime.png')} />
                                          <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Date/Time</Text>
                                          {item?.sample_taken_at == null ?  <Text style={{ fontSize: 16 }}>{moment(item.created_at).local().format('LLL')}</Text> :   <Text style={{ fontSize: 16 }}>{moment.unix(item.sample_taken_at).local().format('LLL')}</Text>}
                                        
                                      </TouchableOpacity>
                                  </View>
  
                                  <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                      <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                          onPress={() => {
                                          }}>
                                          <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueDateTime.png')} />
                                          <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Sample Source</Text>
                                          <Text style={{ fontSize: 16 }}>
                                              {FindSampleTypeName(item?.manual_sample_type_id??0)}
                                          </Text>
                                      </TouchableOpacity>
                                  </View>
  
                                  <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                      <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                          onPress={() => {
                                          }}>
                                          <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueBin.png')} />
                                          <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Origin</Text>
                                          <Text style={{ fontSize: 16 }}>{FindSourceNameFromMasterSampleID(item.id)}</Text>
                                      </TouchableOpacity>
                                  </View>
  
                                  <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                      <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                          onPress={() => {
                                          }}>
                                          <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueBin.png')} />
                                          <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Destination</Text>
                                          <Text style={{ fontSize: 16 }}>{FindDestinationNameByMasterSampleID(item.id)}</Text>
                                      </TouchableOpacity>
                                  </View>
  
                                  <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                      <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                          onPress={() => {
                                          }}>
                                          <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueGear.png')} />
                                          <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Equipment</Text>
                                          <Text style={{ fontSize: 16 }}>{FindEquipmentNameFromMasterSampleID(item.id)}</Text>
                                      </TouchableOpacity>
                                  </View>
  
                                  <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                      <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                          onPress={() => {
                                          }}>
                                          <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueGrain.png')} />
                                          <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Grain</Text>
                                          <Text style={{ fontSize: 16 }}>{FindGrainNameFromMasterSampleID(item)}</Text>
  
                                      </TouchableOpacity>
                                  </View>
  
                                  <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                      <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                          onPress={() => {
                                          }}>
                                          <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueDateTime.png')} />
                                          <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Bushels Represented</Text>
                                          <TextInput onEndEditing={() => {
                                          }} style={{ fontSize: 16 }}>
                                              {item?.volume??'N/A'}
                                          </TextInput>
                                      </TouchableOpacity>
                                  </View>
  
                                  {/* Inner Details Section */}
                                  {showDetails ?
                                      <>
  
  
  
  <View style={{flexDirection:'row', paddingVertical:5,alignItems:'center'}}>
  <TouchableOpacity style={{flex:1,flexDirection:'row',alignContent:'center',alignItems:'center',alignSelf:'center'}}
  onPress={() => {
  }}> 
  <Image style={{width:30, height:30,borderRadius: 5,overflow: 'hidden', }} source={require ('../../../Icons/BluePerson.png')} />
  <Text style={{paddingLeft:10,flex:1,fontSize:16,color:'grey'}}>Taken By: </Text>
  <Text style={{fontSize:16}}>{users.find(x => x.id == item?.user_id)?.name??'N/A'}</Text>
  </TouchableOpacity>
  </View>
  
                                          <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                              <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                                  <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueDateTime.png')} />
                                                  <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Latitude</Text>
                                                  <Text style={{ fontSize: 16 }}>
                                                      {item?.latitude ?? 'N/A'}
                                                  </Text>
  
                                              </View>
                                          </View>
  
                                          <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                              <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                                  <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueDateTime.png')} />
                                                  <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Longitude</Text>
                                                  <Text style={{ fontSize: 16 }}>
                                                      {item?.longitude ?? 'N/A'}
                                                  </Text>
                                              </View>
                                          </View>
  
                                          <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                              <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                                  onPress={() => {
                                                  }}>
                                                  <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueDateTime.png')} />
                                                  <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Accu-Rate Setting</Text>
                                                  <Text style={{ fontSize: 16 }}>
                                                      {item?.dial ?? 'N/A'}
                                                  </Text>
                                              </TouchableOpacity>
                                          </View>
  
                                          <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                              <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                                  onPress={() => {
                                                  }}>
                                                  <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueDateTime.png')} />
                                                  <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'grey' }}>Seconds Between Samples</Text>
                                                  <Text style={{ fontSize: 16 }}>
                                                      {item?.target_time ?? 'N/A'}
                                                  </Text>
                                              </TouchableOpacity>
                                          </View>
                                      </>
                                      : null}
  
  
                                  {/* End of Inner Details Section */}
  
                                  <View style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center', paddingVertical: 3 }}>
                                      <Icon style={{}} name="edit" size={35} color="#1c448c" />
                                      <Text style={{ flex: 1, marginLeft: 5, left: 5, fontSize: 16, color: 'grey' }}>* Select icon to modify input</Text>
                                  </View>
  
  
  
                              </View>
                            }
                      

                            {/* Start of Document/Characteristics Section */}
                            <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>

                                {/* Start of Dynamic Characteristic List */}
                                <FlatList
                                    data={sampleCharacteristics.filter(x => x.master_sample_id == item.id)}
                                    keyExtractor={(item, index) => (item.id + '_Char' + item.master_sample_id + index)}
                                    renderItem={({ item }: { item: SampleRecordCharacteristic }) => (
                                        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: item.enterprise_id != null ? 'lightgrey' : 'white' }} onPress={() => {
                                            sampleCharRefs.current['charRef_' + item.id.toString()].focus();
                                        }}>

                                            <Icon style={{}} name="edit" size={35} color="#1c448c" />

                                            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'nowrap' }}>
                                                <Text style={{ fontSize: 16, paddingLeft: 10 }} numberOfLines={1}>
                                                    {characteristicSubOptions.find(x => x.id == item.characteristic_sub_id)?.name ?? ''}
                                                </Text>
                                                {item.buyer_id != null ? <Text style={{ fontSize: 16 }}> - {buyers?.find(x => x.id == item.buyer_id)?.name ?? 'N/A'}</Text> : null}
                                                {item.enterprise_id != null ? <Text style={{ fontSize: 16 }}> - {enterprises.find(x => x.id == item.enterprise_id)?.name ?? 'N/A'}</Text> : null}
                                                {item.lab_id != null ? <Text style={{ fontSize: 16 }}> - {labs.find(x => x.id == item.lab_id)?.name ?? 'N/A'}</Text> : null}
                                                {item.user_instrument_id != null ? <Text style={{ fontSize: 16 }}> - {instrumentModels.find(x => x.id === item.user_instrument_id)?.model_name ?? 'N/A'}</Text> : null}

                                            </View>




                                            <View style={{ flex: 1, maxWidth: 75, minWidth: 75, height: 25 }}>
                                                <TextInput ref={el => (sampleCharRefs.current['charRef_' + item.id.toString()] = el)} style={{ paddingRight: 5, marginLeft: 5, flexDirection: 'row', textAlign: 'right' }} value={item?.value ?? ''} keyboardType={item?.characteristic_sub_id == 2 ? 'default' : 'numeric'}

                                                    onChangeText={(text) => {
                                                        let oldList = sampleCharacteristics;
                                                        oldList.find(x => x == item).value = text;
                                                        setSampleCharacteristics([...oldList]);

                                                    }}
                                                    onEndEditing={async () => {
                                                        const record = sampleCharacteristics.find(x => x == item);

                                                        try {
                                                            let updateStatus = await UpdateSampleRecordCharacteristic(record);
                                                            if (updateStatus != null) {
                                                                Toast.show('Updated Value');
                                                            }
                                                        }
                                                        catch (e) {

                                                            appContext.setAlertOptions({title:'Error',desc:'Could not update value',options:[
                                                                {text:'OK', role:'PRIMARY', onPress:() => {
                                                                 
                                                                    appContext.setShowAlert(false);
                                                                  }}
                                                              ]});
                                                               appContext.setShowAlert(true); 
                                                        }
                                                    }}
                                                >
                                                </TextInput>
                                            </View>



                                        </TouchableOpacity>
                                    )}
                                ></FlatList>


                                {/* End of Dynamic Characteristic List */}

                                <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                    <TouchableOpacity accessibilityLabel="Add_Analysis_Button" style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                        onPress={() => {
                                            if (ValidID(item?.id)) {
                                                setAddFromAnalysis(false);
                                                setSelectedSample(item);
                                                setPickingData(dataPick.AddCharacteristics);
                                            }
                                        }}>
                                        <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueAdd.png')} />
                                        <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }}>Add Analysis Results</Text>

                                    </TouchableOpacity>
                                </View>


                                {/* Start of Dynamic Documents List */}

                                <FlatList
                                    data={documents}
                                    keyExtractor={(item2, index) => (item2.id + '_DOC' + item.id + index)}
                                    renderItem={({ item }: { item: Document }) => (
                                        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }}
                                            onPress={() => {
                                                setSelectedDocument(item);
                                                setPickingData(dataPick.ViewDocument)
                                            }}>
                                            <Icon style={{}} name="square" size={35} color="#1c448c" />
                                            <Text style={{ fontSize: 16, paddingLeft: 10 }}>{item?.title ?? 'Document'} [{item?.doc_type ?? 'doc'}]</Text>
                                        </TouchableOpacity>
                                    )}
                                ></FlatList>

                                <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                    <TouchableOpacity disabled={currentContext.isOffline} style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                        onPress={() => {
                                            setSelectedSample(item);
                                            setPickingData(dataPick.AddDocument);
                                        }}>
                                        <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueAddReport.png')} />
                                        <Text style={[{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }, currentContext.isOffline ? { color: 'grey' } : {}]}>Add Document</Text>

                                    </TouchableOpacity>
                                </View>

                            </View>
                            {/* End of Document/Characteristics Section */}

                            {/* Start of Image Section */}
                            <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>


                                <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>

                                        <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }}>Sample Photo</Text>

                                    </View>
                                </View>


                                {images.find(x => x.master_sample_id == item.id && x.image_type == 'Photo') ?
                                    <Image key={images.find(x => x.master_sample_id == item.id && x.image_type == 'Photo')?.updated_at.toString() ?? item.id} style={{ alignSelf: 'center', width: width - 25, height: height - 50 }} source={{ uri: 'https://webservice.verigrain.com/documents/' + images.find(x => x.master_sample_id == item.id && x.image_type == 'Photo')?.file_path + '?' + images.find(x => x.master_sample_id == item.id && x.image_type == 'Photo')?.updated_at.toString() ?? '', cache: 'reload' }} />

                                    : null}


                                <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                    <TouchableOpacity disabled={currentContext.isOffline} style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                        onPress={async () => {


                                            appContext.setAlertOptions({title:'Image option',desc:'Please choose an image option',options:[
                                                {text:'Take Photo', role:'PRIMARY', onPress:async() => {
                                                    appContext.setShowAlert(false);
                                                    let photo = await TakeImage();
                                                    const userID = await GetData('ID');
                                                    const profile = await GetData('Profile');
                                                    let sampleImage: SampleImage = { id: null, created_at: null, updated_at: null, file_path: '', image_type: 'Photo', master_sample_id: item.id }
                                                    const status = await TakeSampleImage(sampleImage, photo);

                                                    if (status != null) {
                                                        let oldImageList = images;
                                                        const index = images.findIndex(x => x.master_sample_id == item.id && x.image_type == 'Photo');

                                                        if (index != -1) {
                                                            status.image.updated_at = new Date();
                                                            oldImageList[index] = status.image;
                                                        }
                                                        else {
                                                            oldImageList.push(status.image);
                                                        }
                                                        setImages([...oldImageList]);
                                                    }
                                                
                                                  }},
                                                  {text:'Pick from library', role:'SECONDARY', onPress:async()=>{
                                                    appContext.setShowAlert(false);
                                                    let photo = await ChooseImage();
                                                    const userID = await GetData('ID');
                                                    const profile = await GetData('Profile');
                                                    let sampleImage: SampleImage = { id: null, created_at: null, updated_at: null, file_path: '', image_type: 'Photo', master_sample_id: item.id }
                                                    const status = await TakeSampleImage(sampleImage, photo);

                                                    if (status != null) {
                                                        let oldImageList = images;
                                                        const index = images.findIndex(x => x.master_sample_id == item.id && x.image_type == 'Photo');

                                                        if (index != -1) {
                                                            oldImageList[index] = status.image;
                                                        }
                                                        else {
                                                            oldImageList.push(status.image);
                                                        }
                                                        setImages([...oldImageList]);
                                                    }
                                                  }},
                                                  {text:'Cancel', role:'CANCEL', onPress:()=>{
                                                    appContext.setShowAlert(false);
                                                  }}
                                              ]});
                                               appContext.setShowAlert(true); 

                                    
                                        }}>
                                        <Icon style={{ paddingLeft: 5 }} name="camera" size={30} color="#1c448c" />
                                        <Text style={[{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }, currentContext.isOffline ? { color: 'grey' } : {}]}>Add Picture</Text>

                                    </TouchableOpacity>
                                </View>

                            </View>


                            {showDetails ?
                                <>
                                    <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>
                                        <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                            <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                                <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }}>Origin Photo</Text>
                                            </View>
                                        </View>

                                        {images.find(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto') ?
                                            <Image key={images.find(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto')?.updated_at.toString() ?? item.id} style={{ alignSelf: 'center', width: width - 25, height: height - 50 }} source={{ uri: 'https://webservice.verigrain.com/documents/' + images.find(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto')?.file_path + '?' + images.find(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto')?.updated_at.toString() ?? '', cache: 'reload' }} />

                                            : null}


                                        <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                            <TouchableOpacity disabled={currentContext.isOffline} style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                                onPress={async () => {

                                                    appContext.setAlertOptions({title:'Image option',desc:'Please choose an image option',options:[
                                                        {text:'Take Photo', role:'PRIMARY', onPress:async() => {
                                                            appContext.setShowAlert(false);
                                                            let photo = await TakeImage();
                                                            const userID = await GetData('ID');
                                                            const profile = await GetData('Profile');
                                                            let sampleImage: SampleImage = { id: null, created_at: null, updated_at: null, file_path: '', image_type: 'OriginPhoto', master_sample_id: item.id }
                                                            const status = await TakeSampleImage(sampleImage, photo);

                                                            if (status != null) {
                                                                let oldImageList = images;
                                                                const index = images.findIndex(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto');

                                                                if (index != -1) {
                                                                    status.image.updated_at = new Date();
                                                                    oldImageList[index] = status.image;
                                                                }
                                                                else {
                                                                    oldImageList.push(status.image);
                                                                }
                                                                setImages([...oldImageList]);
                                                            }
                                                        
                                                          }},
                                                          {text:'Pick from library', role:'SECONDARY', onPress:async()=>{
                                                            appContext.setShowAlert(false);
                                                            let photo = await ChooseImage();
                                                            const userID = await GetData('ID');
                                                            const profile = await GetData('Profile');
                                                            let sampleImage: SampleImage = { id: null, created_at: null, updated_at: null, file_path: '', image_type: 'OriginPhoto', master_sample_id: item.id }
                                                            const status = await TakeSampleImage(sampleImage, photo);

                                                            if (status != null) {
                                                                let oldImageList = images;
                                                                const index = images.findIndex(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto');

                                                                if (index != -1) {
                                                                    oldImageList[index] = status.image;
                                                                }
                                                                else {
                                                                    oldImageList.push(status.image);
                                                                }
                                                                setImages([...oldImageList]);
                                                            }
                                                          }},
                                                          {text:'Cancel', role:'CANCEL', onPress:()=>{
                                                            appContext.setShowAlert(false);
                                                          }}
                                                      ]});
                                                       appContext.setShowAlert(true); 

                                                }}>
                                                <Icon style={{ paddingLeft: 5 }} name="camera" size={30} color="#1c448c" />
                                                <Text style={[{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }, currentContext.isOffline ? { color: 'grey' } : {}]}>Add Picture</Text>

                                            </TouchableOpacity>
                                        </View>

                                    </View>

                                    <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>
                                        <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                            <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                                <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }}>Destination Photo</Text>
                                            </View>
                                        </View>
                                        {images.find(x => x.master_sample_id == item.id && x.image_type == 'DestinationPhoto') ?
                                            <Image key={images.find(x => x.master_sample_id == item.id && x.image_type == 'DestinationPhoto')?.updated_at.toString() ?? item.id} style={{ alignSelf: 'center', width: width - 25, height: height - 50 }} source={{ uri: 'https://webservice.verigrain.com/documents/' + images.find(x => x.master_sample_id == item.id && x.image_type == 'DestinationPhoto')?.file_path + '?' + images.find(x => x.master_sample_id == item.id && x.image_type == 'DestinationPhoto')?.updated_at.toString() ?? '', cache: 'reload' }} />

                                            : null}


                                        <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                            <TouchableOpacity disabled={currentContext.isOffline} style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                                onPress={async () => {

                                                    appContext.setAlertOptions({title:'Image option',desc:'Please choose an image option',options:[
                                                        {text:'Take Photo', role:'PRIMARY', onPress:async() => {
                                                            appContext.setShowAlert(false);
                                                            let photo = await TakeImage();
                                                            const userID = await GetData('ID');
                                                            const profile = await GetData('Profile');
                                                            let sampleImage: SampleImage = { id: null, created_at: null, updated_at: null, file_path: '', image_type: 'OriginPhoto', master_sample_id: item.id }
                                                            const status = await TakeSampleImage(sampleImage, photo);

                                                            if (status != null) {
                                                                let oldImageList = images;
                                                                const index = images.findIndex(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto');

                                                                if (index != -1) {
                                                                    status.image.updated_at = new Date();
                                                                    oldImageList[index] = status.image;
                                                                }
                                                                else {
                                                                    oldImageList.push(status.image);
                                                                }
                                                                setImages([...oldImageList]);
                                                            }
                                                        
                                                          }},
                                                          {text:'Pick from library', role:'SECONDARY', onPress:async()=>{
                                                            appContext.setShowAlert(false);
                                                            let photo = await ChooseImage();
                                                            const userID = await GetData('ID');
                                                            const profile = await GetData('Profile');
                                                            let sampleImage: SampleImage = { id: null, created_at: null, updated_at: null, file_path: '', image_type: 'OriginPhoto', master_sample_id: item.id }
                                                            const status = await TakeSampleImage(sampleImage, photo);

                                                            if (status != null) {
                                                                let oldImageList = images;
                                                                const index = images.findIndex(x => x.master_sample_id == item.id && x.image_type == 'OriginPhoto');

                                                                if (index != -1) {
                                                                    oldImageList[index] = status.image;
                                                                }
                                                                else {
                                                                    oldImageList.push(status.image);
                                                                }
                                                                setImages([...oldImageList]);
                                                            }
                                                          }},
                                                          {text:'Cancel', role:'CANCEL', onPress:()=>{
                                                            appContext.setShowAlert(false);
                                                          }}
                                                      ]});
                                                       appContext.setShowAlert(true); 
                                                }}>
                                                <Icon style={{ paddingLeft: 5 }} name="camera" size={30} color="#1c448c" />
                                                <Text style={[{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }, currentContext.isOffline ? { color: 'grey' } : {}]}>Add Picture</Text>

                                            </TouchableOpacity>
                                        </View>


                                    </View>
                                </>
                                : null}




                            {/* End of Image Section */}

                            {/* Start of Sub-Sample Section */}

                            {foundSubSamples.find(x => x.subSample.master_sample_id == item.id) ?


                                <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>
                                    <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                        <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                            onPress={ () => {

                                            
                                              

                                            }}>
                                            <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/OrangeSplit.png')} />


                                            <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }}>Primary Sample</Text>
                                        </TouchableOpacity>
                                    </View>

                                    <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => {
                                        try
                                        {
                                            const masterSampleFound = foundSubSamples.find(x => x.subSample.master_sample_id == item.id)?.parentMasterSample;
                                            setMasterSampleList([masterSampleFound]);
                                        }
                                        catch(error)
                                        {
                                            console.log(error);
                                        }
                                    }}>
                                        <Image style={{ width: 30, height: 30 }} source={require('../../../Icons/BlueBarcode.png')} />
                                        <Text style={{ flex: 1, paddingLeft: 10, fontSize: 16 }}>Sample ID</Text>
                                        <Text style={{ paddingRight: 10, fontSize: 16 }}>[Primary-Sample]  {foundSubSamples.find(x => x.subSample.master_sample_id == item.id)?.parentMasterSample.barcode??"N/A"}</Text>

                                    </TouchableOpacity>

                                </View>
                                :

                                <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 5, paddingLeft: 5, paddingRight: 5, marginVertical: 5 }}>
                                    <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center' }}>
                                        <TouchableOpacity accessibilityLabel="Split_Samples_Button" style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                            onPress={async () => {

                                                const subSamplePopulateData: { sample: MasterSample, source: { field: Field[] | null, bin: { bin: Bin, location: BinLocation } | null }, destination: { bin: { bin: Bin, location: BinLocation } | null, buyer: Buyer | null, enterprise: Enterprise | null }, volume: number, equipment: SampleEquipment, grain: { grain: GrainType,subCrop:GrainSubCrop, grainVarieties: GrainVariety[] | null } } = GrabDataToPopulateSubSample(item.id);

                                                navigation.navigate('CreateSubSampleScreen', {
                                                    data: subSamplePopulateData,
                                                    masterSampleList: masterSampleList,
                                                    childSubSamples: childSubSamples,
                                                    grabSubSamples: (subList: {masterSample:MasterSample,subSample:SubSample}[]) => { setChildSubSamples([...subList]) }

                                                })

                                            }}>
                                            {childSubSamples && childSubSamples.find(x => x.subSample.parent_sample_id == item?.id) ?
                                                <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/OrangeSplit.png')} />
                                                :
                                                <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden', }} source={require('../../../Icons/BlueSplit.png')} />
                                            }
                                            <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16, color: 'black' }}>Split Sample</Text>
                                        </TouchableOpacity>
                                    </View>

                                    <FlatList
                                        data={childSubSamples.filter(x => x?.subSample?.parent_sample_id == item.id)}
                                        keyExtractor={(item4, index) => item4?.masterSample?.id?.toString()??index + '_Sample' + item?.id + index}
                                        renderItem={({ item }: { item: {masterSample:MasterSample,subSample:SubSample} }) => (
                                            <TouchableOpacity accessibilityLabel={'Sub_' + item?.masterSample?.barcode??''} style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => {
                                                    setMasterSampleList([item.masterSample]);
                                            }}>
                                                <Image style={{ width: 30, height: 30 }} source={require('../../../Icons/BlueBarcode.png')} />
                                                <Text style={{ flex: 1, paddingLeft: 10, fontSize: 16 }}>Sample ID</Text>
                                                <Text style={{ paddingRight: 10, fontSize: 16 }}>[Sub-Sample] {item?.masterSample?.barcode??'N/A'} </Text>

                                            </TouchableOpacity>
                                        )}></FlatList>

                                </View>

                            }

                        </>
                    )}
                ></FlatList>
                {/* End of Sample Scroll View */}




            </View>

            {pickingData == dataPick.AddCharacteristics ? <AddSampleCharacteristics
                buyers={buyers} labs={labs} characteristicSubOptions={characteristicSubOptions} characteristicTypes={characteristicTypes} enterprises={enterprises}
                grain={selectedAnalysisGrain} sample={selectedSample} sampleCharacteristics={sampleCharacteristics}
                setBuyers={setBuyers} setEnterprises={setEnterprises} setLabs={setLabs}
                setPickingData={setPickingData} setSampleCharacteristics={setSampleCharacteristics} setUserInstruments={setInstrumentModels} userInstruments={instrumentModels.filter(function(instrumentModel){

                    if(userInstrumentModels.find(x => x.model_id == instrumentModel.id))
                    {
                        return instrumentModel;
                    }
              })}
                addFromAnalysis={addFromAnalysis}
                OnRefresh={OnRefresh}
            /> : null}

            {pickingData == dataPick.AddDocument ? <AddSampleDocument documents={documents} setDocuments={setDocuments} setPickingData={setPickingData} sample={selectedSample} /> : null}
            {pickingData == dataPick.ViewDocument ? <ViewSampleDocument document={selectedDocument} setPickingData={setPickingData} /> : null}
            {pickingData == dataPick.ViewAnalysisPending ? <PendingAnalysisScreen sample={selectedSample} setPickingData={setPickingData} pendingAnalysis={pendingAnalysis} setPendingAnalysis={setPendingAnalysis} setSampleCharacteristics={setSampleCharacteristics}  sampleCharacteristics={sampleCharacteristics.filter(x => x.master_sample_id == selectedSample.id && x.value == null)} /> : null}
            {pickingData == dataPick.AllowSample ? <AllowSampleOverlay alreadyChecked={allowedSamples.find(x => x.master_sample_id == selectedSample?.id) ? true : false} growerUsers={growerUser} setGrowerUser={setGrowerUser} allowedSamples={allowedSamples} setAllowedSamples={setAllowedSamples} sample={selectedSample} isVisible={pickingData == dataPick.AllowSample} setVisible={setPickingData} enterprises={enterprises} /> : null}

        </>
    );
}

export default SampleListScreen

async function TakeSampleImage(item: SampleImage, photoURI: string) {

    const formData = new FormData();
    formData.append('sample_image', JSON.stringify(item, (k, v) => v ?? undefined))
    formData.append('uri', photoURI)
    console.log(JSON.stringify(item, (k, v) => v ?? undefined));
    const wasLoginSuccessful = await ApiCall<ApiResponse & { image: SampleImage }>("/api/take-sample-image", formData, true);

    if (wasLoginSuccessful != null && wasLoginSuccessful.response == 0) {
        console.log('Successfully saved image');
        return wasLoginSuccessful;
    }
    else {
        console.log('Failed to save Image');
        return null;
    }
}



