import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TouchableOpacity, Image, } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { FlatList } from 'react-native-gesture-handler';
import { Alert } from 'react-native';
import { RestoreDeletedcompanies } from '../../../Helpers/Functions/Offline/BuyerFunctions';
import { Buyer } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import VGButton from '../../../Components/Button/Button';
import AppContext from '../../../Contexts/AppContext';

function RestoringBuyerScreen(props: { deletedCompanies: Buyer[], checkedCompanies: Buyer[], setCheckedCompanies: setProp<Buyer[]>, setDeletedCompanies: setProp<Buyer[]>, companies: Buyer[], setCompanies: setProp<Buyer[]>, setIsRestoring: setProp<boolean> }) {

    const appContext = React.useContext(AppContext);
    async function onRestorePress() {

        try {
            let restoredCompanies = await RestoreDeletedcompanies(props.checkedCompanies);

            if (restoredCompanies != undefined) {
                let oldCompanyList = props.companies;
                oldCompanyList.pop();
                restoredCompanies.forEach(element => {
                    oldCompanyList.push(element);


                    let oldDeletedBuyerList = props.deletedCompanies;
                    restoredCompanies?.forEach(x => {
                        let index = oldDeletedBuyerList.findIndex(c => c.id == x.id);
                        oldDeletedBuyerList.splice(index, 1);

                    })
                    props.setDeletedCompanies(oldDeletedBuyerList);

                });
                props.setCompanies(oldCompanyList);
                props.setCheckedCompanies([]);
                props.setIsRestoring(false);
            }
            else {
                Toast.show('Could not restore companies');
            }
        }
        catch (error) {
            appContext.setAlertOptions({title:'Could not restore buyers',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);
        }

    }

    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', marginHorizontal: 10 }}>
            <FlatList
                data={props.deletedCompanies}
                renderItem={({ item, index }) =>
                    <View >

                        <View style={{ flexDirection: 'row', borderWidth: 1, borderColor: 'lightgrey', marginVertical: 3, alignSelf: 'stretch' }}>
                            <TouchableOpacity
                                style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignSelf: 'center', padding: 5 }}
                                onPress={() => {
                                    if (props.checkedCompanies?.find(x => x.id == item.id) != undefined) {
                                        let oldField = props.checkedCompanies;
                                        const index = oldField.findIndex(x => x.id == item.id);
                                        oldField.splice(index, 1);
                                        props.setCheckedCompanies([...oldField])
                                        console.log(oldField);
                                    }
                                    else {
                                        let oldField = props.checkedCompanies;
                                        oldField?.push(item);
                                        props.setCheckedCompanies([...oldField]);
                                        console.log(oldField);


                                    }

                                }} >
                                {props.checkedCompanies?.find(x => x.id == item.id) != undefined ?
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/OrangeField.png')} />

                                    :
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/BlueField.png')} />


                                }

                                <Text style={{ fontSize: 16, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5 }}>{item.name} - {item.location}</Text>
                            </TouchableOpacity>
                        </View>


                    </View>
                }
                keyExtractor={(item, index) => index.toString()}
            >
            </FlatList>

            <VGButton role="PRIMARY" onPress={onRestorePress} >Restore</VGButton>


            <VGButton role="CANCEL"
                onPress={() => {
                    props.setIsRestoring(false);
                }}
            >Cancel</VGButton>


        </View>
    );
}

export default RestoringBuyerScreen;