import { ApiResponse } from "../../GeneralTypes";
import { Buyer, Sample, Document, SubSample } from "../../Storage/UserState";
import { ApiCall } from "../GeneralFunctions";

export async function SaveSampleImage(sourceImage: string, destinationImage: string, sample: Sample | SubSample) {
    const formData = new FormData();

    if (sourceImage != '') {
        formData.append('source_uri', sourceImage);
    }
    if (destinationImage != '') {
        formData.append('destination_uri', destinationImage);
    }

    formData.append('sample', JSON.stringify(sample, (k, v) => v ?? undefined))

    const wasLoginSuccessful = await ApiCall<ApiResponse>("/api/save-primary-sample-image", formData, true);

    if (wasLoginSuccessful != null) {
        console.log('SUCCESS');
        return wasLoginSuccessful;
    }
    else {
        return null;
    }
}

export async function ShareSamples(sampleList: Sample[], emailList: Buyer[], profile: string, email: string) {

    console.log(sampleList);


    let formData = new FormData();

    let jsonSampleList = JSON.stringify(sampleList);
    let jsonEmaiList = JSON.stringify(emailList);

    console.log('JSON String: ' + jsonSampleList);
    console.log('Email String' + jsonEmaiList);
    formData.append('samples', jsonSampleList);
    formData.append('buyers', jsonEmaiList);
    formData.append('email', email);
    const status = await ApiCall<ApiResponse & { samples: any }>("/api/share-sample-records", formData, true);

    if (status != null) {
        return true;
    }

}

export async function SaveSampleDocument(file: string, document: Document) {
    const formData = new FormData();


    formData.append('document', JSON.stringify(document, (k, v) => v ?? undefined))
    formData.append('file', file);



    const wasLoginSuccessful = await ApiCall<ApiResponse & { document: Document }>("/api/save-sample-document", formData, true);

    if (wasLoginSuccessful != null) {
        console.log('SUCCESS');
        return wasLoginSuccessful.document;
    }
    else {
        return null;
    }
}