
import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity, Modal, Alert, Dimensions, Image, FlatList, KeyboardAvoidingView, Platform } from 'react-native';
import { Overlay } from "react-native-elements";
import AddNewSampleField from "../AddSampleModals/AddNewSampleField";
import { addFieldSelector } from "../../../Helpers/Storage/DefaultStates";
import { useIsFocused } from "@react-navigation/native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { setProp } from "../../../Helpers/GeneralTypes";
import { Field } from "../../../Helpers/Storage/UserState";
import VGButton from '../../../Components/Button/Button';
import Spacing from '../../../Styles/Spacing';

// TODO: See if you can use this component in both the new sample and sample search screens
function FieldSamplePicker(props: { fields: Field[],canAdd:boolean, currentSampleFields: Field[], pickingData: boolean,OnSaveFieldSelection:(fields:Field[])=>void, OnAddNewField:(field:Field)=>void,OnCancel:()=>void }) {
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height

    let isFocused = useIsFocused();

    const [isAddingField, setIsAddingField] = React.useState(false);
    const [cancelledFields, setCancelledFields] = React.useState<Field[]>([]);
    const [tempSelectedFields, setTempSelectedFields] = React.useState<Field[]>([]);

    let renderSeparatorView = () => {
        return (
            <View style={{
                height: 2,
                width: width,
                backgroundColor: "orange",
            }}
            />
        );
    };


    //Store the current field list in case the user Cancels the selection
    React.useEffect(() => {
        if (isFocused) {
            const fields = Object.assign([], props.currentSampleFields);
            setCancelledFields([...fields]);
        }
    }, [isFocused]);

    return (
        <>


            <Overlay style={{}} isVisible={props.pickingData} onBackdropPress={() => { props.OnCancel }}>

            <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding':'height'}>
                {!isAddingField ?
                    <View style={Spacing.Modal}>

                        <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                            <Text style={{ alignSelf: 'center', fontSize: 28 }}>Select one or more fields</Text>
                        </View>

                        <FlatList
                            ItemSeparatorComponent={renderSeparatorView}
                            contentContainerStyle={{ justifyContent: 'space-evenly', alignContent: 'flex-end' }}
                            keyExtractor={(item, index) => index.toString()}
                            data={props.fields}
                            renderItem={({ item }: { item: Field }) =>
                                <TouchableOpacity accessibilityLabel={item.name} onPress={() => {


                                        const oldCurFieldList = tempSelectedFields ?? [];

                                        
                                        const index = oldCurFieldList?.findIndex(x => x.id == item.id);
                                        console.log((index > -1 ? 'Des' : 'S') + 'electing Field: ', item);

                                        if (index > -1) {
                                            oldCurFieldList.splice(index, 1);
                                            setTempSelectedFields([...oldCurFieldList])
                                        }
                                        else {
                                            oldCurFieldList.push({...item});
                                            setTempSelectedFields([...oldCurFieldList])
                                        }
                                    

                                }}>

                                    {tempSelectedFields != null && tempSelectedFields.find(x => x.id == item.id) != null && item != addFieldSelector ?
                                        <Text style={{ color: 'orange', fontSize: 24, padding: 10 }}>{item.name}</Text>
                                        :
                                        <Text style={{ color: 'black', fontSize: 24, padding: 10 }}>{item.name}</Text>
                                    }
                                </TouchableOpacity>
                            }
                        >
                        </FlatList>

                        <View>

                            <VGButton role="PRIMARY" onPress={() => {props.OnSaveFieldSelection(tempSelectedFields)}}>OK</VGButton>
                            
                            {props.canAdd ? <VGButton role="SECONDARY" onPress={() => { setIsAddingField(true);}}>Add New Field</VGButton>
: null}

                            <VGButton role="CANCEL" onPress={props.OnCancel}>Cancel</VGButton>
                            
                        </View>

                    </View>



                    :

                            <AddNewSampleField fields={props.fields} currentSampleFields={props.currentSampleFields} isAddingField={isAddingField} OnCancel={() =>{setIsAddingField(false);}} OnSavedField={(savedField)=>{
                                props.OnAddNewField(savedField);
                                setIsAddingField(false);
                            }} />
                }
</KeyboardAvoidingView>

            </Overlay>
        </>
    )
}
export default FieldSamplePicker