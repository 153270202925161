import React, { useEffect, useState, useMemo } from "react";
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView } from "react-native";
import { GrainType,GrainSubCrop, GrainVariety } from "../../../Helpers/Storage/UserState";
import textStyles from "../../../Styles/Text";
import Icon from "react-native-vector-icons/FontAwesome";
import spacing from "../../../Styles/Spacing";


const GrainTypeSectionListValue = React.memo((props: {checked:boolean,
  item: {
    typeID: number;
    subCropID: number;
    grainVariety: GrainVariety;
}
,onPress:any,shown:boolean}) => (


    <TouchableOpacity
    accessibilityLabel={(props.checked ? 'Checked' : 'Unchecked') + '_' + props.item.grainVariety.name}
    onPress={() => {
        props.onPress(props.item)
   }} style={[spacing.SectionValueRow,{display:props.shown ? 'flex': 'none'}]}>
      

      <Icon style={spacing.paddingRight20} name={props.checked  ? "check-square" : "square-o"} size={40} color="black" />

        <View style={spacing.flexOne}>
        <Text accessible={true} accessibilityLabel={props.item.grainVariety.name} style={textStyles.sectionListValue}>{props.item.grainVariety.name}</Text>

        </View>

    
    </TouchableOpacity>


));

export default GrainTypeSectionListValue;