import * as React from 'react';
import {StoreData, GetData} from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity, SectionList, DeviceEventEmitter, Dimensions, Image, Platform, Alert} from 'react-native';
import styles from '../../../Styles/styles'
import { FlatList } from 'react-native-gesture-handler';
import { useIsFocused } from "@react-navigation/native";
import Spinner from 'react-native-loading-spinner-overlay';
import YardMapPicker from '../StackModals/YardMapPicker';
import {GetAllBins, GetLocations, UpdateBin, UpdateBinLocation} from '../../../Helpers/Functions/Offline/BinFunctions'
import { Bin, BinLocation} from '../../../Helpers/Storage/UserState'
import Toast from 'react-native-tiny-toast';
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';

import { useContext } from 'react';
import AppContext from '../../../Contexts/AppContext';
import { GetLocalUserManual } from '../../../Helpers/Functions/Online/UserFunctions';

import VGInput from '../../../Components/Input/Input';
import spacing from '../../../Styles/Spacing';
import textStyles from '../../../Styles/Text';
import BarcodeReader from '../../HelperScreens/BarcodeReader';



 function YardSelectionScreen({ navigation }: { navigation: any })
{
    {
        const isFocused = useIsFocused();
        var width = Dimensions.get('window').width; //full width
        var height = Dimensions.get('window').height; //full height
       
        const [locations, setLocations]= React.useState<BinLocation[]>([]);
        const [currentLocation, setCurrentLocation] = React.useState<BinLocation>();
        const [isLoading,setIsLoading] = React.useState(false);
        const [isPickingLocation, setIsPickingLocation] = React.useState<boolean>(false);
        const [bins, setBins] = React.useState<Bin[]>([]);
        const [scanningBarcode, setScanningBarcode] = React.useState(false)
        const [currentBarcode, setCurrentBarcode] = React.useState<string>("")

        const barcodeRef = React.useRef(null);

        const images = {
            "blue-loc": require('../../../Icons/BlueLocation.png'),
            "orange-loc": require('../../../Icons/OrangeLocation.png')
        }
    
        const appContext = useContext(AppContext);
    
        React.useEffect(  () => {
        
            if(isFocused){


                const getPages = async() => {
                                        
                    let data =await GetLocalUserManual();
                    if(data != null)
                    {
                      appContext.setCurrentScreenName(data.find(x => x.screen_name == 'MainIntelligenceMenu'))
                    }
                    else
                    {
                      appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='MainIntelligenceMenu'));
                    }
                  };
                  getPages();  
                  
                const GrabBinData = async() => {
                
                   
                    setIsLoading(true);
    
                    try
                    {
                        const profile = await GetData('Profile');
                        let locations = await GetLocations().filter(x => x.expired == 0);
                        
                        let barcodes = await GetAllBins().filter(x => x.expired == 0);
                        setBins(barcodes);
                
                        if(locations != null)
                        {
                            locations.sort(function(a,b) {
                                var reA = /[^a-zA-Z]/g;
                                var reN = /[^0-9]/g;
                                var aA = a.location.replace(reA, "");
                                var bA = b.location.replace(reA, "");
                                if (aA === bA) {
                                var aN = parseInt(a.location.replace(reN, ""), 10);
                                var bN = parseInt(b.location.replace(reN, ""), 10);
                                return aN === bN ? 0 : aN < bN ? 1 : -1;
                                } else {
                                return aA < bA ? 1 : -1;
                                }
                            })

        
                            setLocations(locations);
                            
                        }
                        else
                        {
                            setLocations([]);
                        }
                    }
                    catch{
                        setLocations([]); 
                    }
                    
                    setIsLoading(false);
                }

                GrabBinData();
                
            }
        },[isFocused]);

        /**
         * This is the onCLick function used by the find barcode button
         */
        async function findBarcodeButton(val:string){

            if(val[0] == 'S'){
                setCurrentBarcode(val);
            }
            else{
                setCurrentBarcode("S" + val);
                val = "S"+val;
            }



            //check if he barcode is in the list
            let barcodeFind = bins.find((x)=>{return x.barcode == val})
            
            if(barcodeFind != undefined){
                //if it is, we check the bin associated with the barcode
                    const profile = await GetData('Profile');

                    if(bins != null){
                        let curBin = bins.find(bin=>bin.id===barcodeFind.id);
                        console.log(curBin)
                        if(curBin != undefined){
                            let loc = locations.find(l => l.id === curBin.location_id)

                            //only navigate if the coordinates exist, otherwise, the location doesn't have a map area
                            if(loc != undefined && loc.longitude != null){

                                navigation.navigate('YardOverview', {location:loc, bins:bins, selectedBin:curBin});
                            }
                            else{
                                appContext.setAlertOptions({title:'Warning',desc:'Sorry, this location does not have an associated mapped area',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
                                appContext.setShowAlert(true);
                            }
                        }
                    }
            }
            else{
                appContext.setAlertOptions({title:'Warning',desc:'Sorry, Barcode does not exist',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
                appContext.setShowAlert(true);
            }
        }


        /**
         * This onSave handler handles when the save button is pressed in the YardMapPicker to save the location's coordinate
         * 
         * @param coord the coordinate to save to the location
         */
        async function onSave(coord, zoom){

            currentLocation.longitude = coord[0];
            currentLocation.latitude = coord[1];
            currentLocation.zoom_level = zoom;

            //get the profile for the helper function
            const profile = await GetData('Profile');
            //update the bin's coord using the helper
            let status = await UpdateBinLocation({...currentLocation, profile});

            
            
            if(status != null){
                if(status == false){
       
                    appContext.setAlertOptions({title:'Could not pick location',desc:'Please try again at a later time',options:[
                        {text:'OK', role:'PRIMARY', onPress:() => {
                         
                            appContext.setShowAlert(false);
                          }}
                      ]});
                       appContext.setShowAlert(true); 
                }
                else{
                        //get the new version of the location object in the list
                    const oldLocList = locations;
                    const index = oldLocList.indexOf(oldLocList.find(l => l.id == currentLocation.id));
                    oldLocList.splice(index, 1 , currentLocation)
                    setLocations(oldLocList);
                }
            }
            else{
                appContext.setAlertOptions({title:'Could not pick location',desc:'Please try again at a later time',options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                     
                        appContext.setShowAlert(false);
                      }}
                  ]});
                   appContext.setShowAlert(true); 
            }

            setIsPickingLocation(false);
        }


        async function onBarcodeRead(val:string){
                
            findBarcodeButton(val);          
        }

        function onBarcodePress(){setScanningBarcode(true)}
    
        return (
            <View style={{flex:1, justifyContent:'space-evenly' }}>

        {isLoading ?     <Spinner
        visible={isLoading}
        textContent={'Loading Location Data...'}
        cancelable={false}
        textStyle={styles.spinner}
        /> : null }
        
        {scanningBarcode ? 
            <BarcodeReader isNumeric={false} onBarcodeRead={onBarcodeRead} setPickingData={setScanningBarcode}  />
            :
            null
        }
    
  
            { !isPickingLocation ?
     <View style={{flex:1, justifyContent:'space-evenly',marginHorizontal:10,marginTop:10 }}>


        {/* Barcode Input */}
            <Text style={textStyles.info}>Scan for Storage</Text>
            <VGInput label="Barcode" type="picker"
                icon="barcode" onPress={onBarcodePress}
                value={currentBarcode}
            />
      

     <View style={spacing.ScreenHeader}>
         <Text style={textStyles.title}>Locations</Text>
     </View>

     
    
                    <FlatList 
                                data={locations}
                                renderItem={({ item, index }) =>
                                    
                                    <View style={{flexDirection:'row',flex:1, borderWidth:3, borderColor:'lightgrey',alignContent:'center',alignItems:'center', marginVertical:3,alignSelf:'stretch'}}>
                              
                              
                               <TouchableOpacity
                                    style={{flexDirection:'column',  justifyContent: 'flex-start', alignItems: 'flex-start',marginVertical:5,marginHorizontal:5}}
                                onPress= {() => {
                                    setCurrentLocation(item);
                                    setIsPickingLocation(true);
                                    
                                }} >
                                       <Image style={{width:30, height:30,borderRadius: 5, overflow: 'hidden'}} source={ item.longitude != null ? images['blue-loc'] : images['orange-loc']}/>
                                       {item?.longitude == null ?
                                        <Text style={{color:'red', fontSize:10}}>Pick Location</Text>
                                       : null}
                                </TouchableOpacity>
                            
                                    <TouchableOpacity accessibilityLabel={item.location}
                                    style={{flex:1}} onPress={()=>{
                                        if(item.longitude != null){
                                            navigation.navigate('YardOverview', {location:item,bins:bins});
                                       }
                                    }}>
                                <Text style={{fontSize:18,alignSelf:'center',paddingRight:5}}>{item.location}</Text>
                                    </TouchableOpacity>
                                   </View>
                                
                               
                                }
                                keyExtractor={(item,index) => index.toString()}
                            >
                            </FlatList>
                </View>
            : null}

{ isPickingLocation ? 
                    
            <YardMapPicker type='location' center={[-106,52]} onSave={(coord,zoom)=>onSave(coord,zoom)} 
            
            onCancel={()=>{
                setIsPickingLocation(false);
            }}/>
            : null}
        
            </View>
    
        );
    }


}
        
        export default YardSelectionScreen;
