import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { ApiResponse } from "../../GeneralTypes";
import { defaultGrain } from "../../Storage/DefaultStates";
import { AddToQueue } from "../../Storage/OfflineQueue";

import { Bin, BinLocation, Buyer, Enterprise, UserEquipment, Field, GrainType, GrainVariety, ManualProgressTimes, ManualSamplingTypes, MasterSample } from "../../Storage/UserState";
import { GenerateNumberIDSync, ValidID,ApiCall } from "../GeneralFunctions";



export async function CreateManualRecord(equipment: UserEquipment, sample: MasterSample, profile: string, userID: string, manualTypeID: number): Promise<MasterSample> {

    let sampleTime = await GetSampleTime(sample.container_size, sample.volume, equipment.bushels_hr_rate, 0,manualTypeID)


    //round to nearest number
    sampleTime = round5(sampleTime);

    if(sampleTime <= 5)
    {
    sampleTime = 5;
    }
    let sampleScoop = 1;
    
    const multipliedByTwo = sampleTime * 2;
    //If sampleTime is less than 60 && the sample Time multiplied by 2 gives us a value greater or equal to 60
    //set to 2 
    if(sampleTime < 60 && multipliedByTwo >= 60)
    {
        console.log('Sample Time is : ' + sampleTime*2);
        sampleTime *= 2;
    sampleScoop = 2;
    }
    else if(multipliedByTwo < 60 && sampleTime < 60) //Set to 3 if sample time is less than 60 and if the multiplied time is still less then 60
    {
        sampleTime *= 3;
        sampleScoop = 3;
    }


    let totalSamples = GetNumberOfSamples(sample.container_size, sampleScoop)

    let record: MasterSample = {...sample};

    record.manual_sample_type_id = manualTypeID;
    record.time_current = sampleTime;
    record.target_time = sampleTime;
    record.samples_required = totalSamples;
    record.scoop_size = sampleScoop;

    const status = await AddToQueue("master_samples", record, "update");

    return status ? record : null;
}


export async function UpdateManualSampleRecord(manualSampleRecord: MasterSample, progressTimes: ManualProgressTimes[] | null): Promise<boolean> {
    const status = await AddToQueue("master_samples", manualSampleRecord, "update");

    if (progressTimes != null) {
        const with_ids = progressTimes.map(x => {
            return {
                ...x,
                id: GenerateNumberIDSync(x.user_id)
            }
        });
        await AddToQueue("manual_progress_times", with_ids, "add");
    }

    return status;
}

function round5(x: number) {
    return Math.round(x / 5) * 5;
}

export function GetIncompleteProgressRecords()
{
    let records:{
        manualRecord?: MasterSample
    }[] = [];
    const manual_progress = GetOfflineStateParsed("master_samples").filter(x => x.completed == 0 || (x.completed == null && x.manual_sample_type_id != null));
    manual_progress.forEach(man => {
        records.push({manualRecord:man})
    })

    return records;
}
export function GetProgressRecords(profile: string, completed: number | null, sampleList: string | null): ProgressRecordData[] | null {

    let currentData: ProgressRecordData[] = [];


    let samples:MasterSample[] = [];
    if(completed == null)
    {
        samples =  GetOfflineStateParsed("master_samples").filter(x => x.completed == 0 || x.completed == null);
    }
    else
    {
        samples =  GetOfflineStateParsed("master_samples");
    }
    
    const sample_sources =  GetOfflineStateParsed("sample_source");
    const sample_destinations =  GetOfflineStateParsed("sample_destination");
    const bins =  GetOfflineStateParsed("bins");
    const bin_locations =  GetOfflineStateParsed("bin_locations");
    const fields =  GetOfflineStateParsed("fields");
    const buyers =  GetOfflineStateParsed("buyers");
    const sample_grains =  GetOfflineStateParsed("sample_grain_varieties");
    const grain_varieties =  GetOfflineStateParsed("grain_varieties");
    const grains =  GetOfflineStateParsed("grain_types");
    const enterprises =  GetOfflineStateParsed("enterprises");
    const masterSamples =  GetOfflineStateParsed("master_samples");

    for (const record of samples) {
        const data = GetProgressDataHelper(record);
        if (data != null) {
            currentData.push(data);
        }
    }


    return currentData;


    function GetProgressDataHelper(sample?: MasterSample): ProgressRecordData {
    

        if (sample != null) {
            try {
                const sample_grain_var = sample_grains.find(x => x.master_sample_id === sample.id);
                const sample_source = sample_sources.filter(x => x.master_sample_id === sample.id);
                let sourceBin = null;
                let sourceField: Field[] = null;

                if (sample_source.length == 1 && ValidID(sample_source[0].source_bin_id)) {
                    const bin = bins.find(x => x.id === sample_source[0].source_bin_id);
                    sourceBin = { bin, location: bin_locations.find(x => x.id === bin.location_id) };
                }
                else if (sample_source != null) {
                    const ids = new Set(sample_source.map(x => x.source_field_id));
                    sourceField = fields.filter(x => ids.has(x.id));
                }

                const sample_destination = sample_destinations.find(x => x.master_sample_id === sample.id);

                let destinationBin = null;
                let destinationBuyer = null;
                let destinationEnterprise = null;

                if (sample_destination) {
                    if (ValidID(sample_destination.destination_bin_id)) {
                        const bin = bins.find(x => x.id === sample_destination.destination_bin_id);
                        const destinationBinLocationData = bin_locations.find(x => x.id === bin.location_id);
                        destinationBin = { bin, location: destinationBinLocationData };
                    }

                    destinationBuyer = ValidID(sample_destination.destination_buyer_id) ? buyers.find(x => x.id === sample_destination.destination_buyer_id) : null;
                    destinationEnterprise = ValidID(sample_destination.destination_enterprise_id) ? enterprises.find(x => x.id === sample_destination.destination_bin_id) : null;
                }
                let grain_info: { grain_type: GrainType, grain_varieties: GrainVariety[] } = null;
                if (sample_grain_var != null) {

                    const grainVar = grain_varieties.filter(x => x.id === sample_grain_var.grain_variety_id);
                    const grainType = grains.find(x => x.id == grainVar[0]?.id)??{...defaultGrain};
                    grain_info = { grain_type: grainType, grain_varieties: grain_varieties.filter(x => x.id === sample_grain_var.grain_variety_id) };
                }

                return {
                    sourceBin,
                    sourceField,
                    destinationBin,
                    destinationBuyer,
                    destinationEnterprise,
                    grain: grain_info,
                    masterSample:sample
                };
            }
            catch (e) {
                console.log('ERROR GETTING PROGRESS DATA: ', e);
            }
        }

        return null;
    }
}

type ProgressRecordData = {
    masterSample:MasterSample,
    sourceBin?: { bin: Bin, location: BinLocation },
    sourceField?: Field[],
    destinationBin?: { bin: Bin, location: BinLocation },
    destinationBuyer?: Buyer,
    destinationEnterprise?: Enterprise,
    grain?: { grain_type: GrainType, grain_varieties: GrainVariety[] }
};

export async function GetSampleTime(containerSize: string, volume: number, bushels_hr_rate: number, cropFactor: number,sample_assist_type:number) {

    //remember that volume = bushels_represented
    let sampleTime = 0.0;
    let pailFactor = 0.0;
    switch (containerSize) {
        case '2 Gal':
            pailFactor = 0.2033;
            GetTime(sample_assist_type);
            break;
        case '3.5 Gal':
            pailFactor = 0.3365;
            GetTime(sample_assist_type);
            break;
        case '5 Gal':
            pailFactor = 0.5040;
            GetTime(sample_assist_type);
            break;
    }
    //round to 1 decimal place, converts to string so parse it as a float
    sampleTime = Math.round(sampleTime * 1e2) / 1e2;
    //return sampleTime
    return sampleTime;

    //This inner function will do some more complex calculations to grab the sample time
    //You may have to do more to convert stuff to doubles? 
    function GetTime(sample_assist_type:number) {

        let scoopSize = 0.01116;
        //Are you using the standard scoop?
        if(sample_assist_type == 1)
        {
            scoopSize = 0.006743;
        }
        sampleTime = (volume * 3600) / ((pailFactor / scoopSize) * bushels_hr_rate);

    }
}

export function GetNumberOfSamples(containerSize:string,scoopSize:number)
{
  
    let samples = 0;
    switch(containerSize)
    {
        case '2 Gal':
            samples = 18/scoopSize;
    
            break;
        case '3.5 Gal':
            samples = 30/scoopSize;
        
            break;
        case '5 Gal':
            samples = 45/scoopSize;
            break;

            //TODO, find samples required for 1 KG bags from Kevan
            case '1 Kg':
            samples = 2.5/scoopSize;
            if(samples == 0){samples = 1};
            break;
    }

    samples = Math.round(samples);
    return samples;
}

export  function GetProgressTimes(manualRecordID: number): ManualProgressTimes[] | null{
  
    const times =  GetOfflineStateParsed("manual_progress_times").filter(x => x.master_sample_id == manualRecordID);


    return times;
}

export function GetAverageManualProgressTime(masterSampleID:number):number
{
    const times =  GetOfflineStateParsed("manual_progress_times").filter(x => x.master_sample_id == masterSampleID);

    let avg = 0;
    times.forEach(time => {
        avg += time.time;
    })
    avg = avg / times.length;

    return avg;
}
/**
 * Checks if an in progress sample is available to be continued.
 * @param userID Current User ID
 * @param progress_id Sample Progress ID
 * @returns 0 if sample is available to continue, 1 if sample is in progress by another user
 */
export async function CheckCurrentSampleAssistStatus(userID: string, progress_id: number): Promise<number> {
    //TODO: not sure how the offline behaviour should actually work
    const manual_progress = (await GetOfflineStateParsed("master_samples")).find(x => x.id == progress_id);
    const user_id = parseInt(userID);
    console.log(`Checking use status of progress [${progress_id}] by user [${user_id}]`);

    return 0;

    // if (manual_progress == null || manual_progress.current_user_id == null || manual_progress.current_user_id == 0 || manual_progress.current_user_id == user_id) {
    //     // Good to go
    //     return 0;
    // }
    // else {
    //     // occupied
    //     return 1;
    // }

}

/**
 * Checks if an in progress sample is available to be continued.
 * @returns A list of sampling types
 * @TODO Switch to offline use
 */
export async function GetManualSamplingOptions():Promise<ManualSamplingTypes[]>
{
    const manual_sampling_types = (await GetOfflineStateParsed("manual_sampling_types"))

    if(manual_sampling_types != null)
    {
        return manual_sampling_types;
    }
    else
    {
        return null;
    }
}