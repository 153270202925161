import * as React from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, Dimensions, Image, ScrollView } from 'react-native';
import Toast from 'react-native-tiny-toast';

import Icon from 'react-native-vector-icons/FontAwesome';
import OverlayPicker from '../../HelperScreens/OverlayPicker';
import { Overlay } from 'react-native-elements';
import { CheckIfEmailExists, CheckIfPhoneExists } from '../../../Helpers/Functions/Online/UserFunctions';
import { PendingUser, UserInfo } from '../../../Helpers/Storage/UserState';
import { setProp } from '../../../Helpers/GeneralTypes';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { defaultPendingUser } from '../../../Helpers/Storage/DefaultStates';
import Colours from '../../../Styles/Colours';
import VGInput from '../../../Components/Input/Input';
import VGButton from '../../../Components/Button/Button';
import PhoneInput from '../../../Components/Input/PhoneInput';
import AppContext from '../../../Contexts/AppContext';

//This function houses a modal with is used to add a new location to the profile's location data
function AddNewUserModal(props: { company: string, users: UserInfo[], setUsers: setProp<UserInfo[]>, setVisible: setProp<boolean>, adminName: string }) {

  const appContext = React.useContext(AppContext);
  var width = Dimensions.get('window').width; //full width
  var height = Dimensions.get('window').height; //full height
  let states: string[] = ["Alaska", "Alabama", "Arkansas", "American Samoa", "Arizona", "California", "Colorado", "Connecticut", "District of Columbia", "Delaware", "Florida", "Georgia", "Guam", "Hawaii", "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky", "Louisiana", "Massachusetts", "Maryland", "Maine", "Michigan", "Minnesota", "Missouri", "Mississippi", "Montana", "North Carolina", "North Dakota", "Nebraska", "New Hampshire", "New Jersey", "New Mexico", "Nevada", "New York", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Virginia", "Virgin Islands", "Vermont", "Washington", "Wisconsin", "West Virginia", "Wyoming"];
  let provinces: string[] = ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"];
  let countries: string[] = ["Canada", "USA"]
  //a state for location

  const [userInfo, setUserInfo] = React.useState<PendingUser>({ ...defaultPendingUser });

  const [isLoading, setIsLoading] = React.useState(false);

  const [emailStatusColor, setEmailStatusColor] = React.useState('lightgrey');
  const [passwordStatusColor, setPasswordStatusColor] = React.useState('lightgrey');
  const [confirmPasswordStatusColor, setConfirmPasswordStatusColor] = React.useState('lightgrey');
  const [nameStatusColor, setNameStatusColor] = React.useState('lightgrey');
  const [provinceStatusColor, setProvinceStatusColor] = React.useState('lightgrey');
  const [countryStatusColor, setCountryStatusColor] = React.useState('lightgrey');
  const [companyStatusColor, setCompanyStatusColor] = React.useState('lightgrey');
  const [cityStatusColor, setCityStatusColor] = React.useState('lightgrey');

  const [phoneNumber, setPhoneNumber] = React.useState({ number: '', valid: false })
  const [province, setProvince] = React.useState<{ name: string }>();
  const [confirmpassword, setConfirmPassword] = React.useState('');
  const [country, setCountry] = React.useState<{ name: string }>();

  const [statesOrProvinces, setStatesOrProvinces] = React.useState(['']);



  const [showPassword, setShowPassword] = React.useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(true);
  const [isPickingCountry, setIsPickingCountry] = React.useState(false);
  const [isPickingProvince, setIsPickingProvince] = React.useState(false);
  const [showConfirmScreen, setShowConfirmScreen] = React.useState(false);

  let nameRef = React.useRef(null);
  let companyRef = React.useRef(null);
  let cityRef = React.useRef(null);
  let emailRef = React.useRef(null);


  React.useEffect(() => {
    if (country?.name == 'Canada') {
      setStatesOrProvinces(provinces);
    }
    else {
      setStatesOrProvinces(states);
    }
  }, [country])

  React.useEffect(() => {
    const populateData = async () => {

      const profile = await GetData('Profile');
      const ID = await GetData('ID')
      setUserInfo({ ...userInfo, profile: profile, admin_id: parseInt(ID), company: props.company })
    };
    populateData();
  }, [])

  // React.useEffect(() => {
  //   {
  //     const GrabUserData = async () => {
  //       await GenerateRandomPassword(setUserInfo,userInfo, setConfirmPassword);

  //       setUserInfo({...userInfo, company:props.company})
  //     }
  //     GrabUserData();
  //   }
  // }, [])

  React.useEffect(() => {
    if (phoneNumber.valid) {
      setUserInfo({ ...userInfo, phone: phoneNumber.number });
    }

  }, [phoneNumber])
  //Check when the user types email, change the border color if it's either empty
  //not a valid email, or a good email
  React.useEffect(() => {
    let emailRegex = RegExp('^([\\w-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$');

    if (userInfo.email == '') {
      setEmailStatusColor('lightgrey');
    }
    else if (emailRegex.test(userInfo.email)) {
      setEmailStatusColor('green');
    }
    else {
      setEmailStatusColor('red');
    }
  }, [userInfo.email])




  React.useEffect(() => {
    if (userInfo.name == '') {
      setNameStatusColor('lightgrey');
    }
    else if (userInfo.name.length > 0 && userInfo.name.length <= 30) {
      setNameStatusColor('green');
    }
    else {
      setNameStatusColor('red');
    }
  }, [userInfo.name])


  React.useEffect(() => {
    if (userInfo.company == '') {
      setCompanyStatusColor('lightgrey');
    }
    else if (userInfo.company.length > 0 && userInfo.company.length <= 30) {
      setCompanyStatusColor('green');
    }
    else {
      setCompanyStatusColor('red');
    }
  }, [userInfo.company])


  React.useEffect(() => {
    if (userInfo.city == '') {
      setCityStatusColor('lightgrey');
    }
    else if (userInfo.city.length > 0 && userInfo.city.length <= 30) {
      setCityStatusColor('green');
    }
    else {
      setCityStatusColor('red');
    }
  }, [userInfo.city])

  React.useEffect(() => {
    if (province?.name == '' || province == undefined) {
      setProvinceStatusColor('lightgrey');
    }
    else if (province?.name.length > 0 && province?.name.length <= 30) {
      setProvinceStatusColor('green');
      setUserInfo({ ...userInfo, province: province.name })
    }
    else {
      setProvinceStatusColor('red');
    }
  }, [province])

  React.useEffect(() => {
    if (country?.name == '' || country == undefined) {
      setCountryStatusColor('lightgrey');
    }
    else if (country?.name.length > 0 && country?.name.length <= 30) {
      setUserInfo({ ...userInfo, country: country.name })
      setCountryStatusColor('green');
    }
    else {
      setCountryStatusColor('red');
    }
  }, [country])

  async function onInvitePress(){

    try {

      let status = await CheckIfEmailExists(userInfo.email);
      let phoneStatus = await CheckIfPhoneExists(userInfo.phone);


      if (status == 0 && phoneStatus == 0) {

        // setIsLoading(true);

        //  let user = await AddUser(email, phone, password, confirmpassword, company, name, city, country?.name, province?.name, props.adminName);
      //  let userStatus = await InviteSecondaryUser(userInfo);
      let userStatus;  
      setIsLoading(false);
        console.log(userStatus);
        if (userStatus != null) {

        //   // let oldList = props.users;

        //   // console.log('here' + props.users);

        //   // oldList.push(userStatus);
        //   // props.setUsers([...oldList]);
        //   setShowConfirmScreen(true);
        //   //add secondary user to list of users


        // }
        // else {
        //   Toast.show('Coult not add secondary user');
        // }

      }
      else {
        if (!status) {
          appContext.setAlertOptions({title:'Email already exists!',desc:'Please use a different email and try again',options:[
            {text:'OK', role:'PRIMARY', onPress:() => {
                appContext.setShowAlert(false);
              }}
          ]});
           appContext.setShowAlert(true); 
        }
        else if (phoneStatus) {
          appContext.setAlertOptions({title:'Phone number already already exists!',desc:'Please use a different phone number and try again',options:[
            {text:'OK', role:'PRIMARY', onPress:() => {
                appContext.setShowAlert(false);
              }}
          ]});
           appContext.setShowAlert(true); 
        }
      }

    }
  }
    catch (error) {
      setIsLoading(false);
      console.log(error);
      appContext.setAlertOptions({title:'Could not add a new user at this time',desc:'Please wait and try again later',options:[
        {text:'OK', role:'PRIMARY', onPress:() => {
            appContext.setShowAlert(false);
          }}
      ]});
       appContext.setShowAlert(true); 
    }

  }

  return (

    <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-evenly', marginHorizontal: 10 }}>

      <Overlay isVisible={showConfirmScreen} onBackdropPress={() => { setShowConfirmScreen(false) }}>
        <View style={{ width: width / 1.2, height: height / 3.5, justifyContent: 'space-evenly' }}>
          <Text style={{ alignSelf: 'center', fontSize: 20 }}>An email has been sent out the secondary user with the credentials</Text>

          <VGButton role="PRIMARY" onPress={() => {
            setShowConfirmScreen(false);
            props.setVisible(false);
          }}>OK</VGButton>
        </View>
      </Overlay>


      <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
        <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Add New User</Text>
      </View>

          {/* TODO validation stuff here */}
      <VGInput label="Email" error={emailStatusColor}
        value={userInfo.email} 
        onChangeText={(text) => {
          setUserInfo({ ...userInfo, email: text })
        }}
      />

      <VGInput label="Name" error={nameStatusColor}
        value={userInfo.name} 
        onChangeText={(text) => {
          setUserInfo({ ...userInfo, name: text })
        }} 
      />

        <PhoneInput onSetVal={setPhoneNumber} />
     

      <VGInput label="Company" error={companyStatusColor}
        value={userInfo.company} 
        onChangeText={(text) => {
          setUserInfo({ ...userInfo, company: text })
        }}
      />

      <VGInput label="City" error={cityStatusColor} 
        value={userInfo.city} 
        onChangeText={(text) => {
          setUserInfo({ ...userInfo, city: text })
        }}
      />


{/* TODO change to selection buttons- only 2 options */}
      <VGInput label="Country" type='picker'
        onPress={()=>setIsPickingCountry(true)}
        value={country?.name}
      />

     
      <OverlayPicker text={'Pick a Country'} type={['name']} isVisible={isPickingCountry} setVisible={setIsPickingCountry} setData={setCountry} data={countries.map(x => { return { name: x } })} />
      <OverlayPicker text={'Pick a Province'} type={['name']} isVisible={isPickingProvince} setVisible={setIsPickingProvince} setData={setProvince} data={statesOrProvinces.map(x => { return { name: x } })} />


      <VGInput type="picker" label="Province/State"
      value={province?.name}
        onPress={() => {
          setIsPickingProvince(true);
        }}
      />


      <VGButton role="PRIMARY" onPress={onInvitePress}
        >Invite</VGButton>

      <VGButton role="CANCEL" onPress={() => {
        props.setVisible(false)
      }}>Cancel</VGButton>

    </ScrollView>


  )
}

export default AddNewUserModal;
