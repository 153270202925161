import { DrawerActions } from "@react-navigation/native";
import moment from "moment";
import React, { useContext } from "react";
import { DeviceEventEmitter, Dimensions, View,Text, FlatList, TouchableOpacity, Alert } from "react-native";
import { Overlay } from "react-native-elements";
import Toast from "react-native-tiny-toast";
import { GetData, GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import { Bin, BinLocation, Buyer, Enterprise, UserEquipment, Field, GrainType,GrainSubCrop, GrainVariety, implementsTKeys, ManualProgressTimes, MasterSample, ManualSamplingTypes } from "../../../Helpers/Storage/UserState";
import * as Progress from 'react-native-progress';
import { defaultUserEquipment } from "../../../Helpers/Storage/DefaultStates";
import EquipmentVolumeAdjustScreen from "./EquipmentVolumeAdjustScreen";
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';
import { useIsFocused } from "@react-navigation/native";
import AppContext from "../../../Contexts/AppContext";
import { GetLocalUserManual } from "../../../Helpers/Functions/Online/UserFunctions";
import { GetProgressRecords, GetProgressTimes, UpdateManualSampleRecord } from "../../../Helpers/Functions/Offline/ManualProgressFunctions";
import spacing from "../../../Styles/Spacing";
import textStyles from "../../../Styles/Text";
import VGButton from "../../../Components/Button/Button";

 function SampleProgressSearchScreen({ navigation }: { navigation: any })
 {
    const appContext = useContext(AppContext);
    const isFocused = useIsFocused();
    React.useEffect(() => {
      if(isFocused)
      {
        const getPages = async() => {
                                        
            let data =await GetLocalUserManual();
            if(data != null)
            {
              appContext.setCurrentScreenName(data.find(x => x.screen_name == 'SamplesInProgress'))
            }
            else
            {
              appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='SamplesInProgress'));
            }
          };
          getPages();       }
    },[isFocused])
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height
    DeviceEventEmitter.addListener('OpenMenu',OpenDrawer);
    
    function OpenDrawer(){
    navigation.dispatch(DrawerActions.openDrawer())
    }


    const [userID,setUserID] = React.useState(0);

    const [isLoading,setIsLoading] = React.useState<boolean>(false);

    const [adjustingEquipmentVolume,setAdjustingEquipmentVolume] = React.useState(false);

    const [selectedEquipment,setSelectedEquipment] = React.useState<UserEquipment>({...defaultUserEquipment});
    const [records,setRecords] = React.useState<{
        masterSample?:MasterSample
        sourceBin?:{bin:Bin,location:BinLocation},
        sourceField?:Field[],
        destinationBin?:{bin:Bin,location:BinLocation},
        destinationBuyer?:Buyer,
        destinationEnterprise?:Enterprise,
        grain?:{grain_type:GrainType,grain_varieties:GrainVariety[]},
        equipment?:UserEquipment
}[]>([]);

const [manualSamplingTypes,setManualSamplingTypes] = React.useState<ManualSamplingTypes[]>([]);

React.useEffect(() => {
    const types = GetOfflineStateParsed('manual_sampling_types');

    if(types != null)
    {
        setManualSamplingTypes(types);
    }

},[])


React.useEffect(() => {
    if(selectedEquipment != null && selectedEquipment.id > 0)
    {
        setAdjustingEquipmentVolume(true);
    }
},[selectedEquipment])
    //Since a user could have a lot of sample progress times, we will ignore that and grab when a user has selected a record

    //This useEffect will grab ALL data associated with a sample record as well as the required data from the manual/automatic progress
    React.useEffect(() => {


        const GetSampleDataInProgress = async() => {



            try
            {
                const profile = await GetData('Profile');

                setIsLoading(true);
                let recordData = await GetProgressRecords(profile,null,null);
                console.log('--------------DATA-------------\n' + JSON.stringify(recordData));
                setIsLoading(false);
                setRecords(recordData);
            }
            catch(e)
            {
                setIsLoading(false);
            }
  
        };
        GetSampleDataInProgress();
    },[])



    React.useEffect(() => {
        const getUserID = async() => {
            const ID = await GetData('ID');
            setUserID(parseInt(ID));
        };
        getUserID();
    },[])

    async function onDonePress(item){
        console.log('removing auto sample from list' + item.masterSample.equipment_type);
            if(item.masterSample != null)
            {
                let oldRecord = item.masterSample;
                oldRecord.completed = 1;

                if(item?.masterSample?.manual_sample_type_id != null )
                {

                    const times = await GetProgressTimes(oldRecord.id);
                    //TODO - use sample record times
                    if(times?.length??0 < oldRecord.samples_required)
                    {

                        appContext.setAlertOptions({title:'Warning',desc:'This record is not finished yet, do you still wish it to count as completed?',options:[
                            {text:'Accept as Completed', role:'PRIMARY', onPress:async() => {
                                    appContext.setAlertOptions({title:'Sample Completion Check',desc:'The sample container should be approximately 75% full. If too much sample, increase handling equipment flow rate; if too little sample, decrease sampling equipment flow rate',options:[
                                        {text:'Complete Sample', role:'PRIMARY', onPress:async()=>{
                                            appContext.setShowAlert(false);
                                            const status = await UpdateManualSampleRecord(oldRecord,null);
                                            if(status != null)
                                            {
                                                let oldList = records;
                                                const index = oldList.findIndex(x => x?.masterSample?.id == item.masterSample.id);
                                               oldList.splice(index,1);
                                                setRecords([...oldList]);
                                            }
                                        }},
                                        {text:'Adjust Flow Rate', role:'SECONDARY', onPress:()=>{
                                            appContext.setShowAlert(false);
                                            console.log('Equipment: ' + item.equipment);
                                            setSelectedEquipment(item.equipment);
                                        }}
                                    ]});

                              }},
                              {text:'Go Back', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
                          ]});
                           appContext.setShowAlert(true); 
                  
                    }
                }

                else
                {
                    console.log('removing auto sample from list');
                    const status = await UpdateManualSampleRecord(oldRecord,null);
                    if(status != null)
                    {
                        let oldList = records;
                        const index = oldList.findIndex(x => x?.masterSample?.id == item.masterSample.id);
                       oldList.splice(index,1);
                        setRecords([...oldList]);
                    }
                }

            }


       }

       async function onContinuePress(item){
                              
        if(item.masterSample != null)
        {
            if(item.masterSample?.manual_sample_type_id != null)
            {
                const manual =  manualSamplingTypes.find(x => x.id == item.masterSample?.manual_sample_type_id);
                appContext.setAlertOptions({title: 'Current Sampling Option',desc:'Would you like to continue using the ' + manual.name + ' option?', 
                options:[
                    {text:'Continue', role:'PRIMARY', onPress:()=>{
                        appContext.setShowAlert(false);
                        switch(item.masterSample.manual_sample_type_id)
                        {
                            case 1:
                            default:

                                const standardTimes = GetProgressTimes(item.masterSample.id);
                                appContext.setSelectedSample(item.masterSample);
                                appContext.setManualProgressTimes(standardTimes);
                                appContext.setManualSamplingType(manual);
                                appContext.setShowSampleAssist(1);
                                navigation.navigate('Main Menu')
                            break;
                            case 2:
                                const accuTimes = GetProgressTimes(item.masterSample.id);
                                appContext.setSelectedSample(item.masterSample);
                                appContext.setManualProgressTimes(accuTimes);
                                appContext.setManualSamplingType(manual);
                                appContext.setShowSampleAssist(2);
                                navigation.navigate('Main Menu')
                            break;
                            case 3:
                                const smartTimes = GetProgressTimes(item.masterSample.id);
                                appContext.setSelectedSample(item.masterSample);
                                appContext.setManualProgressTimes(smartTimes);
                                appContext.setManualSamplingType(manual);
                                appContext.setShowSampleAssist(3);
                                navigation.navigate('Main Menu')
                            break;
                            case 5:
                                const ultraTimes = GetProgressTimes(item.masterSample.id);
                                appContext.setSelectedSample(item.masterSample);
                                appContext.setManualProgressTimes(ultraTimes);
                                appContext.setManualSamplingType(manual);
                                appContext.setShowSampleAssist(5);
                                navigation.navigate('Main Menu')
                            break;

                        }
                    }},
                    {text:'See Other Options', role:'SECONDARY', onPress:()=>{

                        appContext.setAlertOptions({title:'Sampling Option',desc:'Please select a sample option',options:[
                            {text:'SmartSampler', role:'SECONDARY',onPress:() => {
                             
                                appContext.setShowAlert(false);
                           
                                const currentTimes =  GetProgressTimes(item.masterSample.id);
                           
                    
                                appContext.setSelectedSample(item.masterSample);
                       
                                appContext.setManualProgressTimes(currentTimes);
                                appContext.setManualSamplingType(manualSamplingTypes.find(x=> x.id == 3));
                                appContext.setShowSampleAssist(3);
                         
                              }},
                              {text:'Sample Assist AccuSampler', role:'SECONDARY', onPress:() => {
                             
                                appContext.setShowAlert(false);
                                 
                          
                                const currentTimes =  GetProgressTimes(item.masterSample.id);
                             
                    
                                appContext.setSelectedSample(item.masterSample);
                       
                                appContext.setManualProgressTimes(currentTimes);
                                appContext.setManualSamplingType(manualSamplingTypes.find(x=> x.id == 2));
                                appContext.setShowSampleAssist(1);
                         
                                navigation.navigate('Main Menu')
                              }},
                              {text:'UltraSampler', role:'SECONDARY', onPress:() => {
                             
                                appContext.setShowAlert(false);
                              
                                const currentTimes =  GetProgressTimes(item.masterSample.id);
                                
                                appContext.setSelectedSample(item.masterSample);
                       
                                appContext.setManualProgressTimes(currentTimes);
                                appContext.setManualSamplingType(manualSamplingTypes.find(x=> x.id == 5));
                                appContext.setShowSampleAssist(5);
                         
                                navigation.navigate('Main Menu')
                              }}
                          ]});
                    }}
                ]})
                appContext.setShowAlert(true);

            }
            else
            {

                appContext.setAlertOptions({title:'Complete Automatic Sample?',desc:'',options:[
                    {text:'Complete', role:'PRIMARY',onPress:async() => {
                     
                        appContext.setShowAlert(false);
                        console.log('removing auto sample from list');
                        let oldRecord = item.masterSample;
                        oldRecord.completed = 1;
                        const status = await UpdateManualSampleRecord(oldRecord,null);
                        if(status != null)
                        {
                            let oldList = records;
                            const index = oldList.findIndex(x => x?.masterSample?.id == item.masterSample.id);
                           oldList.splice(index,1);
                            setRecords([...oldList]);
                        }
                      }},
                      {text:'Cancel', role:'CANCEL', onPress:() => {
                     
                        appContext.setShowAlert(false);
                      }}
                  ]});
                   appContext.setShowAlert(true); 
            
            }
    }
}


    return (

        <View style={{flex:1, justifyContent:'flex-start',marginHorizontal:10,marginTop:10 }}>
            

            {isLoading ?
        <Overlay overlayStyle={{backgroundColor:'rgba(0,0,0,0)',shadowColor:'rgba(0,0,0,0)'}} isVisible={isLoading} >
        <Progress.CircleSnail size={width/1.75} color={'orange'} thickness={10} />
        <Text style={{alignSelf:'center',color:'white',fontSize:22}}>Fetching Data...</Text>
        </Overlay>

        : null}

        {/* {adjustingEquipmentVolume ?
            <EquipmentVolumeAdjustScreen records={records} setRecords={setRecords} isVisible={adjustingEquipmentVolume} setVisible={setAdjustingEquipmentVolume} selectedEquipment={selectedEquipment} equipment={[]} setEquipment={undefined} />
        : null} */}
        
            <View style={spacing.ScreenHeader}>
                <Text style={textStyles.label}>Choose a sample to continue the sampling process:</Text>
            </View>

            <FlatList 
           keyExtractor={(item,index) => index.toString()}
                data={records}
                renderItem={({ item, index }) =>
                    <View style={[{borderWidth:3,borderColor:'lightgrey'}, spacing.Container]}>

                                <View style={{flexDirection:'row',paddingVertical:5}}>
                                <Text style={{fontSize:18,flex:1,left:5}}>Barcode: </Text>

                                <Text style={{fontSize:18,right:5}}>{item?.masterSample?.barcode}</Text>

                                </View>
                                

                                
                                <View style={{flexDirection:'row',paddingVertical:5}}>
                                <Text style={{fontSize:18,flex:1,left:5}}>Origin: </Text>

                                {item.sourceBin != null && item.sourceBin?.bin?.id > 0 && item.sourceBin?.location?.id > 0 ?
                                        <Text style={{fontSize:18,right:5}}>{item.sourceBin?.location?.location??'' + '-' + item.sourceBin.bin?.name??''}</Text>
                                : null}

                                {item.sourceField != null && item.sourceField?.length > 0?
                                     <Text style={{fontSize:18,right:5}}>{item.sourceField[0]?.name??''}</Text>
                                : null}


                                </View>


                                <View style={{flexDirection:'row',paddingVertical:5}}>
                                <Text style={{fontSize:18,flex:1,left:5}}>Destination: </Text>

                                {item.destinationBin != null  && item.destinationBin?.bin?.id > 0 && item.destinationBin?.location?.id > 0 ?
                                        <Text style={{fontSize:18,right:5}}>{item.destinationBin?.location?.location??'' + '-' + item.destinationBin?.bin?.name??''}</Text>
                                : null}

                                {item.destinationBuyer != null && item.destinationBuyer?.id > 0 ?
                                     <Text style={{fontSize:18,right:5}}>{item.destinationBuyer?.name??''}</Text>
                                : null}

                                {item.destinationEnterprise != null && item.destinationEnterprise?.id > 0?
                                     <Text style={{fontSize:18,right:5}}>{item.destinationEnterprise?.name??''}</Text>
                                : null}

                                </View>


                                <View style={{flexDirection:'row',paddingVertical:5}}>
                                <Text style={{fontSize:18,flex:1,left:5}}>Sampling Type: </Text>

                                        <Text style={{fontSize:18,right:5}}>{manualSamplingTypes?.find(x => x.id == item?.masterSample?.manual_sample_type_id)?.name ?? 'N/A'}</Text>
            

                                </View>


                                <View style={{flexDirection:'row',paddingVertical:5}}>
                                <Text style={{fontSize:18,flex:1,left:5}}>Created At: </Text>
                                        <Text style={{fontSize:18,right:5}}>{moment(item.masterSample.created_at).format('LLL')}</Text>
                        
                                </View>


                            <View style={{flexDirection:'row'}}>
                                
                                <View style={{flex:1}}>
                                    <VGButton role="SECONDARY" onPress={()=>{onDonePress(item);}}
                                    >Done</VGButton>
                                </View>

                                <View style={spacing.HorizontalSpacer}></View>

                                <View style={{flex:1}}>    
                                    <VGButton role="PRIMARY" onPress={()=>{onContinuePress(item);}}
                                    >Continue</VGButton>
                                </View>

                            </View>


                    </View>
                }/>

        </View>
    )
            
 }
 export default SampleProgressSearchScreen
