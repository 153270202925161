import React from "react";
import { setProp } from "../Helpers/GeneralTypes";
import { ManualProgressTimes, ManualSamplingTypes, MasterSample, UserManualPageLookup } from "../Helpers/Storage/UserState";
import ButtonRoles from "../Styles/ButtonRoles";


type ContextType = {
    currentScreenName: UserManualPageLookup,
    manualProgressTimes: ManualProgressTimes[],
    selectedSample: MasterSample,
    isTimerHidden: boolean,
    showSampleAssist:number,
    manualSamplingType:ManualSamplingTypes,
    setManualSamplingType:setProp<ManualSamplingTypes>
    setSelectedSample: setProp<MasterSample>,
    setManualProgressTimes: setProp<ManualProgressTimes[]>,
    setIsTimerHidden: setProp<boolean>,
    setCurrentScreenName: setProp<UserManualPageLookup>,
    setShowHelp:setProp<boolean>,
    setShowSampleAssist:setProp<number>,
    alertOptions:{title:string,desc:string,options:{text:string,onPress:Function, role:keyof typeof ButtonRoles}[]},
    setAlertOptions:setProp<{title:string,desc:string,options:{text:string,onPress:Function, role:keyof typeof ButtonRoles}[]}>,
    showAlert:boolean,
    setShowAlert:setProp<boolean>
};

const AppContext = React.createContext<ContextType>(null);
export default AppContext;