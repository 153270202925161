import React, { useContext } from "react";
import { View, Text, Image, TouchableOpacity, TextInput, Alert, KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { GetData, GetOfflineStateParsed, StoreData } from "../../../Helpers/AsyncStorageHelper";
import { GetAllBins, GetBinsByLocation, GetCurrentBinVolume, GetLastGrainUsedInBin, GetLocations } from "../../../Helpers/Functions/Offline/BinFunctions";
import { GetFields } from "../../../Helpers/Functions/Offline/FieldFunctions";
import { addBinLocationSelector, addBinSelector, addBuyerSelector, addFieldSelector, addGrainSelector, addUserEquipmentSelector, defaultBin, defaultBinLocation, defaultBuyer, defaultEnterprise, defaultGrain, defaultMasterSample, defaultUserEquipment } from "../../../Helpers/Storage/DefaultStates";
import { Bin, BinLocation, Buyer, CropSelectOption, Enterprise, Field, GrainSubCrop, GrainType, GrainVariety, ManualSamplingTypes, MasterSample, SampleDestination, SampleEquipment, SampleGrainVariety, SampleSource, UserEquipment, UserGrain } from "../../../Helpers/Storage/UserState";
import * as Permissions from 'expo-permissions';

import { GetBuyers, GetEnterprises } from "../../../Helpers/Functions/Offline/BuyerFunctions";

import { CheckIfEquipmentWorksWithAutoSampler, GetUserEquipment } from "../../../Helpers/Functions/Offline/EquipmentFunctions";
import { CheckValidBarcode, GetSampleMetaData, GetSampleRecords, GetSampleTimeData, SaveSampleRecord, ValidateSampleRecord } from "../../../Helpers/Functions/Offline/SampleFunctions";
import FieldSamplePicker from "../SampleDataPickers/FieldSamplePicker";
import BinSamplePicker from "../SampleDataPickers/BinSamplePicker";
import BuyerSamplePicker from "../SampleDataPickers/BuyerSamplePicker";
import EquipmentSamplePicker from "../SampleDataPickers/EquipmentSamplePicker"; 
import BarcodeReader from "../../HelperScreens/BarcodeReader";
import * as Location from 'expo-location';
import Toast from "react-native-tiny-toast";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';
import { CreateManualRecord, GetManualSamplingOptions } from "../../../Helpers/Functions/Offline/ManualProgressFunctions";
import { useIsFocused } from "@react-navigation/native";
import { ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import * as FileSystem from 'expo-file-system';
import AppContext from "../../../Contexts/AppContext";
import { setProp } from "../../../Helpers/GeneralTypes";
import spacing from "../../../Styles/Spacing";
import VGInput from "../../../Components/Input/Input";
import textStyles from "../../../Styles/Text";
import { Switch } from "react-native-gesture-handler";
import { RequiredText } from "../../../Components/SimpleComponents";
import VGToggle from "../../../Components/Toggle";
import { ValidateSampleBarcode } from "../../../Helpers/Functions/Validators";
import VGButton from "../../../Components/Button/Button";
import SampleGrainPicker from "../SampleDataPickers/SampleGrainPicker";
import { GetGrainAndSubCropTypes } from "../../../Helpers/Functions/Grains";
import moment from "moment";
import { GetTotalBinVolume } from "../../../Helpers/Functions/Offline/StorageFunctions";
import LoadingSpinner from "../../../Components/LoadingSpinner";

function NewSampleScreen({ route, navigation }: {
    route: {
        params: {
            sampleOption: string,
            latestSampleData?: {
                source: SampleSource[],
                destination: SampleDestination
                equipment?: SampleEquipment,
                grain?: SampleGrainVariety[],
                lastMasterSample:MasterSample
            }
        }
    }, navigation: any
}) {

    const appContext = useContext(AppContext);
    //This enum is used to determine what pick screen gets shown, instead of having a million useStates for each option
    enum pickSelection {
        none, sourceBin, sourceField, destinationBin, destinationBuyer, destinationEnterprise, equipment, grain, barcode, manualEquipment, showAutomaticProgressDetails, sourceTransportation, destinationTransportation,
        samplingType,finish
    }
    const { sampleOption, latestSampleData } = route.params;

    const [scanningBarcode, setScanningBarcode] = React.useState<boolean>(false);
    //The complete List of options A User can select
    const [bins, setBins] = React.useState<Bin[]>([]);
    const [binLocations, setBinLocations] = React.useState<BinLocation[]>([]);
    const [fields, setFields] = React.useState<Field[]>([]);
    const [buyers, setBuyers] = React.useState<Buyer[]>([]);
    const [equipmentList, setEquipmentList] = React.useState<UserEquipment[]>([]);


    const [grainList, setGrainList] = React.useState<{
        grainType:GrainType,
        grainSubCrop:GrainSubCrop|null,
    }[]>([]);

    const [grainVarList, setGrainVarList] = React.useState<GrainVariety[]>([]);
 

    //Only 1 enterprise per user
    const [enterprise,setEnterprise] = React.useState<Enterprise[]>([]);
    const [cachedEnterpriseImages,setCachedEnterpriseImages] = React.useState<{enterprise_id:number,fileUri:string}[]>([]);

    const [sampleOptions,setSampleOptions] = React.useState<{id:number,name:string}[]>([]);
    const [selectedSampleOption,setSelectedSampleOption] = React.useState<ManualSamplingTypes>({id:4, name:'Manual Sample'});

    const [showSamplingOption,setShowSamplingOption] = React.useState(false);


    //The optional source/destination params
    const [sourceBin, setSourceBin] = React.useState<{ bin: Bin, location: BinLocation }>({ bin: { ...defaultBin }, location: { ...defaultBinLocation } });
    const [sourceFields, setSourceFields] = React.useState<Field[]>([]);
    const [destinationBin, setDestinationBin] = React.useState<{ bin: Bin, location: BinLocation }>({ bin: { ...defaultBin }, location: { ...defaultBinLocation } });
    const [destinationBuyer, setDestinationBuyer] = React.useState<Buyer>({ ...defaultBuyer });
    const [destinationEnterprise, setDestinationEnterprise] = React.useState<Enterprise>({ ...defaultEnterprise });
    const [equipment, setEquipment] = React.useState<UserEquipment>({ ...defaultUserEquipment });
    const [currentGrain, setCurrentGrain] = React.useState<{ grain: GrainType,subCrop:GrainSubCrop, grainVarieties: GrainVariety[] }>({ grain: { ...defaultGrain },subCrop:null, grainVarieties: [] });

    const [currentMasterSample,setCurrentMasterSample] = React.useState<MasterSample>({...defaultMasterSample});
    //Current pick selection, defaults to none when not picking any option
    const [currentSelection, setCurrentSelection] = React.useState<pickSelection>(pickSelection.none);


    const [sourceOverride,setSourceOverride] = React.useState<boolean>(false);
    const [destinationOverride,setDestinationOverride] = React.useState<boolean>(false);
    const [GPSLocation, setGPSLocation] = React.useState<Location.LocationObject>(null);
    //List of Refs used to Auto-Focus Text Input
    const barcodeRef = React.useRef(null);
    const volumeRef = React.useRef(null);
    const equipmentRef = React.useRef(null);

    //Keep track of the last grain sub scrops used. 
    const [lastSourceGrain,setLastSourceGrain] = React.useState<GrainSubCrop|null>(null);
    const [lastDestinationGrain,setLastDestinationGrain] = React.useState<GrainSubCrop|null>(null);

    //Is it loading data?
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const otherSourceOptions = [{ name: 'Tisdale/AEG automatic sampler' }];
    const [sourceOption, setSourceOption] = React.useState({ name: '' });


    const [manualSampleTypes,setManualSampleTypes] = React.useState<ManualSamplingTypes[]>([]);

    const Origins = ["Field", "Storage"] as const;
    type OrigType = typeof Origins[number];
    const [origin, setOrigin] = React.useState<OrigType>("Field");

    const Destinations = ["Storage" , "Buyer"] as const;
    type DestType = typeof Destinations[number]
    const [dest, setDest] = React.useState<DestType>("Storage");


    const EquipType = ["Equipment" , "Other"] as const;
    type EquipType = typeof EquipType[number];
    const [equip, setEquip] = React.useState<EquipType>("Equipment");

    const [barcodeError, setBarcodeError] = React.useState<string>("")

    const isFocused = useIsFocused();


    //#region USE_EFFECTS

    //if the screen is focused, set the screen for the user manual
    React.useEffect(() => {
      if(isFocused)
      {
        appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='RapidSample'));
      }
    },[isFocused])



    React.useEffect(() => {
        const sampleType = GetOfflineStateParsed('manual_sampling_types');
        if(sampleType != null)
        {
            setManualSampleTypes(sampleType);
        }
    },[])

    //This effect will grab all bins on startup
    React.useEffect(() => {

        try
        {
            const locations = GetLocations().filter(x=>x.expired == 0);
            const bins = GetAllBins().filter(x => x.expired == 0);
            setBinLocations(locations);
            setBins(bins);
        }
        catch(error)
        {
            console.log('Error on fetching bins from Offline storage \n\n Error: ' + error);
        }
    },[]);

    //This effect will grab all fields on startup
    React.useEffect(() => {
        try
        {
            const fields = GetFields().filter(x => x.expire_date == null);
            setFields(fields);
        }
        catch(error)
        {
            console.log('Error on fetching Fields from Offline storage \n\n Error: ' + error);
        }
    },[]);

        //This effect will grab all buyers on startup
        React.useEffect(() => {
            try
            {
                let buyers = GetBuyers().filter(x => x.expire_date == null);
               

                let enterprises = GetEnterprises();
                if(enterprises != null && enterprises.length != 0)
                {
                    let cachedImages:{enterprise_id:number,fileUri:string}[] = []

                    for(let enterprise of enterprises)
                    {
                        buyers.unshift({id:enterprise.id,name:enterprise.name,created_at:null,expire_date:null,profile:'',updated_at:null,buyer_email:enterprise.enterprise_email,location:enterprise.location});
                        const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
                     const getFileInfo = async() => {
                        const image = await FileSystem.getInfoAsync(path);
    
                        if(image.exists)
                        {
                            cachedImages.push({enterprise_id:enterprise.id,fileUri:image.uri});
                        }
                     }
                     getFileInfo();
                      
                    }
                    setCachedEnterpriseImages(cachedImages);
                }

                setBuyers(buyers);
                setEnterprise(enterprises);
            }
            catch(error)
            {
                console.log('Error on fetching Buyers from Offline storage \n\n Error: ' + error);
            }

        },[]);

              //This effect will grab all equipment on startup
        React.useEffect(() => {
            try
            {
                const equipment = GetUserEquipment().filter(x => x.expired == 0);
                setEquipmentList(equipment);
            }
            catch(error)
            {
                console.log('Error on fetching Equipment from Offline storage \n\n Error: ' + error);
            }
        },[])

        //This effect will grab all grains on startup
        React.useEffect(() => {
            try
            {
                const grains = GetGrainAndSubCropTypes();
               setGrainList(grains.types);
               setGrainVarList(grains.varieties);
            }
            catch(error)
            {
                console.log('Error on fetching Grains from Offline storage \n\n Error: ' + error);
            }
        },[])


    //Set the Equipment type on start-up, selected from the main menu screen
    React.useEffect(() => {

        if (currentMasterSample != null) {
            let oldSample = currentMasterSample;
            oldSample.equipment_type = sampleOption;
            setCurrentMasterSample({ ...oldSample });
        }

    }, [sampleOption])

    React.useEffect(() => {
            let oldEquip = equipment;
            oldEquip.name = sourceOption.name;
            setEquipment({ ...oldEquip });
        
    }, [sourceOption])


    //If the user selected the quick start option, use the sample related data
    React.useEffect(() => {
        if (latestSampleData?.lastMasterSample != null) {
            let modifiedLast = {...latestSampleData.lastMasterSample};
            modifiedLast.barcode = 0;
            setCurrentMasterSample(modifiedLast);



        }
    }, [latestSampleData?.lastMasterSample]);

    //This Use Effect will run everytime the grain list changes AND if the user selected the previous sample option!
    React.useEffect(() => {

        if(latestSampleData?.lastMasterSample)
        {
            try
            {
                const grainType = grainList.find(x => x.grainType.id == latestSampleData.lastMasterSample.grain_type_id && x.grainSubCrop.id == latestSampleData.lastMasterSample.sub_crop_id)
                setCurrentGrain({grain:grainType.grainType,subCrop:grainType.grainSubCrop,grainVarieties:[]});
            }
            catch(error)
            {
                console.log('No Grain Found');
            }
        }
    },[grainList])
    
    React.useEffect(() => {
        if(latestSampleData?.lastMasterSample)
        {
            try
            {
                if(latestSampleData.source[0].source_field_id != null)
                {
                    const fieldList = fields.filter(function (field) {

                        if (latestSampleData.source.find(x => x.source_field_id == field.id)) {
                            return field;
                        }
                    });
                    setOrigin('Field');
                    setSourceFields(fieldList);
                }
                else
                {
                        const bin = bins.find(x=>x.id == latestSampleData.source[0].source_bin_id);
                        const loc = binLocations.find(x => x.id == bin.location_id);
                        if(bin != null && loc != null)
                        {
                            setOrigin('Storage');
                            setSourceBin({bin:bin,location:loc});
                        }
                }
            }
            catch(error)
            {
                console.log('No Source option found')
            }
        }
    },[fields,bins,binLocations])


    React.useEffect(() => {
        if(latestSampleData?.lastMasterSample)
        {
            try
            {
                if(latestSampleData?.destination.destination_bin_id != null)
                {
            
                        const bin = bins.find(x=>x.id == latestSampleData.destination.destination_bin_id);
                        const loc = binLocations.find(x => x.id == bin.location_id);
                        if(bin != null && loc != null)
                        {
                            console.log('-----FOUND BIN--------');
                            setDest('Storage');
                            setDestinationBin({bin:bin,location:loc});
                        }
                }
                else if(latestSampleData?.destination.destination_buyer_id != null)
                {
                    const buyer = buyers.find(x => x.id == latestSampleData?.destination.destination_buyer_id )
                    if(buyer != null)
                    {
                        setDest('Buyer');
                        setDestinationBuyer(buyer);
                    }
                }
                else
                {
                    const ent = enterprise.find(x => x.id == latestSampleData?.destination.destination_enterprise_id )
                    if(ent != null)
                    {
                        setDest('Buyer');

                        setDestinationEnterprise(ent);
                    }
                }
            }
            catch(error)
            {
                console.log('No Destination option found')
            }
        }
    },[bins,binLocations,buyers,enterprise])

    React.useEffect(() => {
        if(latestSampleData?.lastMasterSample)
        {
            try
            {

                const equip = equipmentList.find(x => x.id == latestSampleData?.equipment.equipment_id);
                if(equip != null)
                {
                    setEquipment(equip);
                }
            }
            catch(error)
            {
                console.log('No equipment found');
            }
        }
    },[equipmentList])

    React.useEffect(() => {
        //You only really need the sampling options if you've chosen a manual sample type
        if(route.params.sampleOption == 'manual' || (route.params.sampleOption == 'quick' && currentMasterSample.equipment_type == 'manual'))
        {
            const getOptions = async() => {
                const types = await GetManualSamplingOptions();

                if(types != null)
                {
                    setSampleOptions(types);
                }

            }
            getOptions();

         //grab sampling options to use   
        }
    },[route.params.sampleOption, currentMasterSample?.equipment_type])

    React.useEffect(() => {

        if(selectedSampleOption != null && selectedSampleOption.id >= 0 && currentSelection == pickSelection.finish)
        {
            appContext.setManualSamplingType(selectedSampleOption);
            const finish = async() => {
                const profile = await GetData('Profile');
                const id = await GetData('ID');
                switch(selectedSampleOption.id)
                {
                    case 0:
                        navigation.navigate('Main Menu');
                    break;
                    case 1:
                        const standard = await CreateManualRecord(equipment,currentMasterSample,profile,id,1);
                        // navigation.navigate('SampleAssistScreen',{manualRecord:record,manualProgressTimes:[],sampleRecord:sampleStatus,navigateFromMain:false});
                        appContext.setSelectedSample(standard);
           
                        appContext.setManualProgressTimes([]);
                        appContext.setShowSampleAssist(1);
                        navigation.navigate('Main Menu');
                    break;
                    case 2:
                          //text this is a sample assist accusampler
                          const acuu= await CreateManualRecord(equipment,currentMasterSample,profile,id,2);
                          // navigation.navigate('SampleAssistScreen',{manualRecord:record,manualProgressTimes:[],sampleRecord:sampleStatus,navigateFromMain:false});
                          appContext.setSelectedSample(acuu);
                  
                          appContext.setManualProgressTimes([]);
                          appContext.setShowSampleAssist(2);
                          navigation.navigate('Main Menu');
                    break;
                    case 3:
                        const smart = await CreateManualRecord(equipment,currentMasterSample,profile,id,3);
                        // navigation.navigate('SampleAssistScreen',{manualRecord:record,sampleRecord:sampleStatus});
                       //  navigation.navigate('SampleAssistScreen',{manualRecord:record,manualProgressTimes:[],sampleRecord:sampleStatus,navigateFromMain:false});
                             appContext.setSelectedSample(smart);
                     
                             appContext.setManualProgressTimes([]);
                             appContext.setShowSampleAssist(3);
                             navigation.navigate('Main Menu');
                    break;
                    case 4:
                        navigation.navigate('Main Menu');
                    break;
                    case 5:
                        const Ultra = await CreateManualRecord(equipment,currentMasterSample,profile,id,5);
                             appContext.setSelectedSample(Ultra);
                             appContext.setManualProgressTimes([]);
                             appContext.setShowSampleAssist(4);
                            
                             navigation.navigate('Main Menu');
                    break;
                }
            }
            finish();
          
        }
    },[currentSelection])



    //#region data change updates

    React.useEffect(() => {
     

            const AskForPermission = async () => {
                const permissionResponse = await Location.getForegroundPermissionsAsync();

                if (!permissionResponse.granted) {

                    appContext.setAlertOptions({title:'Location Permissions Required',desc:'VeriGrain collects location data to enable Bluetooth features and to record current location when a sample is taken',options:[
                        {text:'Allow', role:'PRIMARY', onPress:async() => {
                            appContext.setShowAlert(false);
                            let { status } = await Location.requestForegroundPermissionsAsync();
                            if (status == Permissions.PermissionStatus.DENIED) {
                                return;
                            }

                            let location = await Location.getCurrentPositionAsync({});
                            let oldSample = currentMasterSample;
                            oldSample.latitude = location.coords.latitude;
                            oldSample.longitude = location.coords.longitude;
                            setCurrentMasterSample({ ...oldSample });
                           
                          }},
                          {text:'Cancel', role:'CANCEL', onPress:() => {
                         
                            appContext.setShowAlert(false);
                          }}
                      ]});
                       appContext.setShowAlert(true); 

                }
            }
            AskForPermission();
  
    }, []);



    React.useEffect(() => {
        if(origin == 'Field')
        {
            setSourceBin({ bin: { ...defaultBin }, location: { ...defaultBinLocation } });
        }
        else
        {
            setSourceFields([])
        }
    },[origin])

    React.useEffect(() => {
        if(dest == 'Storage')
        {
            setDestinationEnterprise({...defaultEnterprise});
            setDestinationBuyer({...defaultBuyer})
        }
        else
        {
            setDestinationBin({ bin: { ...defaultBin }, location: { ...defaultBinLocation } });
        }
    },[dest])


    //Takes into effect when the user selects  a source bin
    React.useEffect(() => {

        //Did the user pick a bin?
        if (sourceBin && ValidID(sourceBin.bin.id) && ValidID(sourceBin.location.id)) {
            const getLastGrainUsedInBin = GetLastGrainUsedInBin(sourceBin.bin);
            setLastSourceGrain(getLastGrainUsedInBin);
        }
    }, [sourceBin])


    //This useEffect will trigger when the destination bin OR the Volume changes.
    //
    React.useEffect(() => {
        //Did the user pick a bin?
        if (destinationBin && ValidID(destinationBin?.bin?.id) && ValidID(destinationBin?.location?.id)) {
  
            //Set the source field to it's default state/ deselect it
            setDestinationBuyer({ ...defaultBuyer });
            setDestinationEnterprise({ ...defaultEnterprise });
            const getLastGrainUsedInBin = GetLastGrainUsedInBin(destinationBin.bin);
            setLastDestinationGrain(getLastGrainUsedInBin);
        }

    }, [destinationBin])


    React.useEffect(() => {
        if (equipment != null) {

            //Check if sample option is automatic
            if (sampleOption == 'automatic') {
                const getValidStatus = async () => {

                    if (ValidID(equipment.id)) {
                        const validEquipment = await CheckIfEquipmentWorksWithAutoSampler(equipment);

                        if (!validEquipment) {
                            setEquipment(undefined);

                            appContext.setAlertOptions({title:'Invalid Equipment',desc:'This piece of equipment needs a SEM Model to work with the VeriGrain Automated Sampler. Please choose a different equipment or update your current one.',options:[
                                {text:'OK', role:'PRIMARY', onPress:() => {
                                 
                                    appContext.setShowAlert(false);
                                  }}
                              ]});
                               appContext.setShowAlert(true); 
                        }
                        else {
                            Toast.show('Valid');
                        }
                    }

                }
                getValidStatus();


            }

            if (equipment == addUserEquipmentSelector) {
                setCurrentSelection(pickSelection.equipment);

            }
            else if (equipment != null && ValidID(equipment.id) && equipment.name != '') {
                console.log('setting the source');
                setEquipment(equipment)
                // setSampleSourceID(equipment.id.toString());
            }

        }
    }, [equipment])

    //Takes into effect when the user selects a destination buyer
    React.useEffect(() => {
           
        //Did the user pick a bin?
        if(destinationBuyer && ValidID(destinationBuyer?.id))
        {
                                
            setDestinationEnterprise({...defaultEnterprise});
 
            enterprise.forEach(ent => {
                if(ent.id == destinationBuyer.id && ent.name == destinationBuyer.name)
                {
                    setDestinationEnterprise(ent);
                    setDestinationBuyer({...defaultBuyer});
                    return;
                }
            })
            //Set the source field to it's default state/ deselect it
            setDestinationBin({bin: {...defaultBin},location:{...defaultBinLocation}});

        }

    
        
    },[destinationBuyer])



//This will enforce Grain sub_types if a bin has been chosen
    React.useEffect(() => {


        if(lastSourceGrain != null && lastDestinationGrain != null)
        {
            if(lastSourceGrain.id != lastDestinationGrain.id)
            {
                appContext.setAlertOptions({title:'Grain Warning',desc:'The grain from these 2 bins are incompatible with each other',options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                     
                        appContext.setShowAlert(false);
                      }}
                  ]});
                   appContext.setShowAlert(true); 
            }
        }
        else
        {
            if((lastSourceGrain != null && currentGrain.subCrop.id != lastSourceGrain.id) || (lastDestinationGrain != null && currentGrain.subCrop.id != lastDestinationGrain.id))
            {
                appContext.setAlertOptions({title:'Grain Warning',desc:'The grain you selected is different from the grain in your bin\n\n The selected grain will be overwritten by what is stored in the bin',options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                     
                        appContext.setShowAlert(false);
                      }}
                  ]});
                   appContext.setShowAlert(true); 
            }
        }

    },[lastSourceGrain,lastDestinationGrain])


    //Takes into effect when the barcode on the sample record changes
    React.useEffect(() => {

        setBarcodeError(ValidateSampleBarcode(currentMasterSample?.barcode.toString()));

        //If a sample exists, and the barcode matches 8 digits in length
        if (currentMasterSample && currentMasterSample?.barcode && currentMasterSample?.barcode.toString().length == 8) {
            const getBarcodeData = async () => {

                const userID = await GetData('ID');
                const profile = await GetData('Profile');
                const sampleData = await GetSampleRecords('Sequential', currentMasterSample.barcode.toString(), 'Primary', profile, null);

                const metaData = await GetSampleMetaData(sampleData.masterSamples);
                if (sampleData.masterSamples.length > 0 && scanningBarcode) {
                    setScanningBarcode(false);

                    appContext.setAlertOptions({title:'Previous Sample Found',desc:'Would you like to auto populate input fields?',options:[
                        {text:'Auto Populate', role:'PRIMARY', onPress:() => {
                            setEquipment({ ...equipmentList.find(x => x.id == metaData.metaData.sampleEquipment[0].equipment_id) } ?? { ...equipmentList[0] })

                                if (ValidID(metaData.metaData.sampleSource[0].source_bin_id)) {
                                    const sourceBin = bins.find(x => x.id == metaData.metaData.sampleSource[0].id) ?? { ...defaultBin };
                                    const sourceLocation = binLocations.find(x => x.id == sourceBin?.location_id) ?? { ...defaultBinLocation };
                                    setSourceBin({ bin: sourceBin, location: sourceLocation });
                                }

                                if (ValidID(metaData.metaData.sampleSource[0].source_field_id)) {
                                    let curFields: Field[] = [];
                                    metaData.metaData.sampleSource.forEach(field => {
                                        curFields.push(fields.find(x => x.id == field?.source_field_id) ?? null)
                                    })
                                    setSourceFields(curFields);
                                }

                                if (ValidID(metaData.metaData.sampleDestination[0].destination_bin_id)) {
                                    const destinationBin = bins.find(x => x.id == metaData.metaData.sampleDestination[0]?.destination_bin_id) ?? { ...defaultBin };
                                    const destinationBinLocation = binLocations.find(x => x.id == destinationBin?.location_id) ?? { ...defaultBinLocation };
                                    setDestinationBin({ bin: destinationBin, location: destinationBinLocation });
                                }

                                if (ValidID(metaData.metaData.sampleDestination[0].destination_buyer_id)) {
                                    const destinationBuyer = buyers.find(x => x.id == metaData.metaData.sampleDestination[0]?.destination_buyer_id) ?? { ...defaultBuyer };
                                    setDestinationBuyer(destinationBuyer);
                                }
                                if (Number.isInteger(metaData.metaData.sampleDestination[0].destination_enterprise_id)&&ValidID(metaData.metaData.sampleDestination[0].destination_enterprise_id)) {
                                    const destEnterprise = enterprise.find(x => x.id == metaData.metaData.sampleDestination[0]?.destination_enterprise_id)??{...defaultEnterprise};
                                    setDestinationEnterprise(destEnterprise);

                                }
                            appContext.setShowAlert(false);
                          }},
                          {text:'Cancel', role:'CANCEL', onPress:() => {
                         
                            appContext.setShowAlert(false);
                          }}
                      ]});
                       appContext.setShowAlert(true); 
                }
                else {
                    setScanningBarcode(false)
                }



                //Check if it matches Verigrain Barcode Specifications
                let barStatus = CheckValidBarcode(currentMasterSample.barcode.toString(), ['0']);

                //if the barcode status is not 0 that means it was a valid barcode!
                if (barStatus != 0 && barStatus != undefined) {

                    //Set the sample bin size
                    let binSize = '';
                    switch (barStatus) {
                        case 1:
                            binSize = '2 Gal';
                            break;
                        case 2:
                            binSize = '3.5 Gal';
                            break;
                        case 3:
                            binSize = '5 Gal';
                            break;
                        case 4:
                            binSize = '1 Kg';
                            break;
                    }
                    let oldSample = {...currentMasterSample};
                    oldSample.container_size = binSize;
                    setCurrentMasterSample(oldSample);
                }
            };
            getBarcodeData();

        }
    }, [currentMasterSample?.barcode])



    React.useEffect(() => {
        if(sourceBin != null && ValidID(sourceBin?.bin?.id))
        {
  
            try
            {
                if(!sourceOverride)
                {
                    
                    const binVolumeData = GetTotalBinVolume(sourceBin.bin);
                    const volume = binVolumeData?.volume??0;
             
    
                    console.log('---Volume----\n' + volume);
                    if(currentMasterSample?.volume != 0 && currentMasterSample?.volume > volume)
                    {
                        appContext.setAlertOptions({title:'Warning',desc:'Bin: ' + sourceBin.bin.name +  ' only has ' + volume + ' BU left.\n',
                        options:[{text:'OK',role:'PRIMARY', onPress:() =>{  
                            appContext.setShowAlert(false);
                             setCurrentMasterSample({...currentMasterSample,volume:binVolumeData?.volume??0});}},
                             {text:'Override', role:'SECONDARY', onPress:() => {
                                appContext.setShowAlert(false);
                                setSourceOverride(true);}}]})
                        appContext.setShowAlert(true);
                     
                    }
                }
      
            }
            catch(error){
                setSourceOverride(false);
            }
     
        }
    },[sourceBin,currentMasterSample?.volume])


    React.useEffect(() => {
        if(destinationBin != null && ValidID(destinationBin?.bin?.id))
        {
            
            try
            {
                if(!destinationOverride)
                {
                    console.log(destinationOverride);
                    const binVolumeData = GetTotalBinVolume(destinationBin.bin);
                    const volume = binVolumeData?.volume??0;
                    console.log('bin Bol: ' + volume);
                    const volLeft = destinationBin.bin.size - volume;
        
                    if(currentMasterSample?.volume != 0 && currentMasterSample?.volume > volLeft)
                    {
                        appContext.setAlertOptions({title:'Warning',desc:'Bin: ' + destinationBin.bin.name +  ' can only contain ' + volLeft + ' BU .\n',
                        options:[{text:'OK',role:'PRIMARY', onPress:() =>{ 
                            appContext.setShowAlert(false);
                              setCurrentMasterSample({...currentMasterSample,volume:volLeft});}},
                              {text:'Override', role:'SECONDARY', onPress:() => {    appContext.setShowAlert(false);
                                setDestinationOverride(true);}}]})
                        appContext.setShowAlert(true);
                    }
                }
           
            }
            catch(error){
                console.log('error' + error);
                setDestinationOverride(false);
            }
     
        }
    },[destinationBin,currentMasterSample?.volume])
    //#endregion
    //#endregion

    async function onSavePress(){
              
        setIsLoading(true);

        StoreData("SampleTypeDefault", selectedSampleOption.name);
        
        let validated = await ValidateSampleRecord(currentMasterSample,currentMasterSample, origin, dest, sourceBin, sourceFields, destinationBin, destinationBuyer, destinationEnterprise, currentGrain, equipment, sampleOption)

          if (validated.length == 0) {
              const profile = await GetData('Profile');
              const id = await GetData('ID');

              let sampleTimeData = GetSampleTimeData(equipment,currentMasterSample);

              if (sampleTimeData != null) {
                  currentMasterSample.sample_time = sampleTimeData.sampleTime;
                  currentMasterSample.sample_size = sampleTimeData.sampleSize;
                  currentMasterSample.dial = sampleTimeData.dial;
              }
              currentMasterSample.profile = profile;
              currentMasterSample.user_id = parseInt(id);
              currentMasterSample.id = undefined;
       
              let finalDest:string = dest;

              if(dest == 'Buyer' && ValidID(destinationEnterprise.id))
              {
                finalDest = 'Enterprise';
              }



              //Save the sample
              const sampleStatus = await SaveSampleRecord(currentMasterSample, origin, sourceBin, sourceFields, destinationBin,finalDest, destinationBuyer, destinationEnterprise, currentGrain, equipment, sampleOption, parseInt(id), profile);
              //If successful, we can save other data

              setIsLoading(false);
              if (sampleStatus != null) {

                  switch (sampleStatus.equipment_type) {
                      case 'automatic':
                          navigation.navigate('AutomaticSampleProgressScreen', { sample: sampleStatus, equipment: equipment })
                          break;
                      case 'manual':

                          setCurrentMasterSample({...sampleStatus});
                          setCurrentSelection(pickSelection.finish);
                          break;
                      case 'other':
                          navigation.navigate('MainActivityNavigation');
                          break;
                      case 'quick':
                          if (equipment.id <= 0) {
                              navigation.navigate('MainActivityNavigation');
                          }
                          else {

                            
                              const asyncAlert = () => new Promise((resolve) => {

                                appContext.setAlertOptions({title:'Use the SmartSampler?',desc:'Would you like to use the SmartSampler to record sample times?',options:[
                                    {text:'Use SmartSampler', role:'PRIMARY', onPress:async() => {
                                        resolve('Success');
                                        setCurrentMasterSample({...sampleStatus});
                                        setCurrentSelection(pickSelection.finish); 
                                        appContext.setShowAlert(false);
                                      }},
                                      {text:'Do Not', role:'CANCEL', onPress:async() => {
                                        resolve('Success');
                                        navigation.navigate('MainActivityNavigation');
                                        appContext.setShowAlert(false);
                                      }}
                                  ]});
                                   appContext.setShowAlert(true); 
                           
                              });
                              await asyncAlert();
                          }
                          break;
                      default:
                          navigation.navigate('MainActivityNavigation');
                          break;

                  }

              }

          }
          else
          {
            setIsLoading(false);
            let fullString = ``;
            validated.forEach(error => {
                fullString += error + `\n\n`;
                });
                appContext.setAlertOptions({title:'Invalid Sample',desc:fullString,options:[{text:'OK', role:'PRIMARY', onPress:() =>{appContext.setShowAlert(false);}}]})
                appContext.setShowAlert(true);  
          }
      }

      function onBarcodePress(){
        setCurrentSelection(pickSelection.barcode)
      }

      function onBarcodeChangeText(text) {
        if(!isNaN(parseInt(text)) &&  text.length <= 8)
        {
            let curSample = currentMasterSample;
            currentMasterSample.barcode = parseInt(text);
            setCurrentMasterSample({...curSample});
        }
        else if(text == '')
        {
            let curSample = currentMasterSample;
            curSample.barcode = 0;
            setCurrentMasterSample({...curSample});
        
        }
        }

        function onGrainPress(){
            setCurrentSelection(pickSelection.grain);
        }

        function onOriginFieldPress(){
            setCurrentSelection(pickSelection.sourceField);
        }

        function onOriginStoragePress(){
            setCurrentSelection(pickSelection.sourceBin)
        }

        function onDestBuyerPress(){
            setCurrentSelection(pickSelection.destinationBuyer)
        }
        
        function onDestStoragePress(){
            setCurrentSelection(pickSelection.destinationBin)
        }

        function onSubmitEditingBushels(){
            if (currentMasterSample?.volume >= 10000) {
                appContext.setAlertOptions({title:'Warning',desc:'Samples greater than 10,000 may have too much variation to provide accurate results',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
                appContext.setShowAlert(true);
            }
        }

        function onChangeBushelsText(text){
            let oldSample = currentMasterSample;
            oldSample.volume = parseInt(text);
            setCurrentMasterSample({ ...oldSample });
        }

        function onEquipmentPress(){          
            sampleOption == 'other' ? setCurrentSelection(pickSelection.manualEquipment) : setCurrentSelection(pickSelection.equipment);
        }

        function onSampleTypePress(){ 
            setCurrentSelection(pickSelection.samplingType)
        }

        function onBarcodeRead(data){
            setCurrentMasterSample({...currentMasterSample,barcode:parseInt(data)});
        }

    return (
        <View style={spacing.MainContainer}>
        <KeyboardAvoidingView  style={{ flex: 1, flexDirection: 'column',justifyContent: 'center'}} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} enabled   keyboardVerticalOffset={50}>
            <LoadingSpinner isLoading={isLoading} loadingText="Saving..." />
            <RequiredText />

            <ScrollView>
            <View style={{flexDirection:"row"}}>
                {/* Barcode Input */}
                <View style={{flex:1}}>
                    <VGInput label="Sample ID" required icon="barcode" type="picker" 
                        onPress={onBarcodePress}
                        value={currentMasterSample?.barcode != null && currentMasterSample?.barcode > 0 ? currentMasterSample?.barcode.toString()??'' : ''}
                        error={barcodeError}
                    />
                </View>

                <View style={spacing.HorizontalSpacer}></View>
        
                {/* Container Size Display */}
                <View style={[spacing.SectionItem, {flexWrap:"wrap"}]} >
                    {
                    currentMasterSample?.container_size.length > 0 ? 
                    <>
                    <Text style={textStyles.label} >Sample Size: </Text>
                
                    <View style={spacing.HorizontalLabelSpacer}></View>
                    <Text style={textStyles.input}>{currentMasterSample?.container_size ?? ''}</Text>
                    </>
                    :null
                }
                </View>
            </View>

            {/* Grain Input */}
            <VGInput label="Grain Type" type="picker" required 
                onPress={onGrainPress}
                value={(currentGrain?.grain && ValidID(currentGrain?.grain.id) ? currentGrain?.grain?.name??'':'') +
                (currentGrain?.subCrop && ValidID(currentGrain?.subCrop?.id) && currentGrain?.subCrop?.id != 26 ? ' - ' + currentGrain.subCrop.name??'' : '') +  
                (currentGrain?.grainVarieties && currentGrain?.grainVarieties.length > 0 ? currentGrain?.grainVarieties.length > 1 ? 'Multiple Varieties' : currentGrain?.grainVarieties[0]?.name ?? '' : '')}
            />
           
           <View style={spacing.VerticalSpacer}></View>

            {/* Grain Origins */}
           
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 2 }}>
                    <Text style={textStyles.label}>Grain Origin:</Text>

                    <View style={spacing.HorizontalLabelSpacer}></View>

                    <VGToggle options={Origins} onSelected={setOrigin} testLabel="Toggle_Origin" />
                </View>

                <View style={spacing.VerticalLabelSpacer}></View>

                {
                    origin == "Field" ?
                    <VGInput label="Field" type="picker" required testLabel="Input_Origin_Field"
                        onPress={onOriginFieldPress} 
                        value={sourceFields != undefined && sourceFields.length > 0 ?
                            sourceFields.length > 1 ?
                                "Multiple Fields"
                                :
                                sourceFields[0]?.name ?? ''
                            : ""}
                    />
                    :
                    <VGInput label="Storage" type="picker" required
                        onPress={onOriginStoragePress} testLabel="Input_Origin_Storage"
                        value={
                            sourceBin && ValidID(sourceBin.bin.id) && ValidID(sourceBin.location.id) ?
                            sourceBin?.bin?.name + ' - ' + sourceBin?.location?.location ?? ''
                            : ""
                        }
                    />
                }

            <View style={spacing.VerticalSpacer}></View>

            {/* Grain Destinations */}
                
                <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}} >
                    <Text style={textStyles.label}>Grain Destination:</Text>

                    <View style={spacing.HorizontalLabelSpacer}></View>

                    <VGToggle options={Destinations} onSelected={setDest} testLabel="Toggle_Destination" />

                </View>

                <View style={spacing.VerticalLabelSpacer}></View>

                {
                    dest == "Buyer" ?
                    <VGInput label="Buyer" type="picker" required
                        onPress={onDestBuyerPress} testLabel="Input_Destination_Buyer"
                        value={destinationBuyer && ValidID(destinationBuyer.id) || (destinationEnterprise && ValidID(destinationEnterprise.id)) ? 
                            destinationBuyer && ValidID(destinationBuyer.id) ? destinationBuyer?.name + ' - ' + destinationBuyer?.location
                            : destinationEnterprise?.name??''
                         :
                         ""
                        }
                    />
                    :
                    <VGInput label="Storage" type="picker" required
                        onPress={onDestStoragePress} testLabel="Input_Destination_Storage"
                        value={
                            destinationBin && ValidID(destinationBin?.bin?.id) && ValidID(destinationBin?.location?.id) ?
                                destinationBin?.bin?.name + '-' + destinationBin?.location?.location
                                : ""
                        }
                    />
                }

            <View style={spacing.VerticalSpacer}></View>
            
            {/* Sample Volume Input */}
            <VGInput label="Bushels Represented" required 
                keyboardType={'numeric'} onChangeText={onChangeBushelsText}
                onSubmitEditing={onSubmitEditingBushels}
                value={currentMasterSample?.volume && currentMasterSample?.volume > 0 ? currentMasterSample?.volume?.toString() ?? '' : ''}
            />
          
            {/* Equipment Input */}

            {sampleOption == 'other' ?
                 <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 2,paddingVertical:4}}>
                 <Text style={textStyles.label}>Equipment:</Text>

                 <View style={spacing.HorizontalLabelSpacer}></View>

                 <VGToggle options={EquipType} onSelected={setEquip} default={EquipType.indexOf("Equipment")} />
             </View>
            : null}
       

            {equip == 'Equipment' || sampleOption != 'other' ?  <VGInput label={sampleOption == 'other' ? 'Other Source' : 'Handling Equipment'} ref={equipmentRef} required type="picker"
                onPress={onEquipmentPress}
                value={equipment?.name ?? ''}
            /> :
            <VGInput label={sampleOption == 'other' ? 'Other Source' : 'Handling Equipment'} ref={equipmentRef} required type="text"
            value={equipment?.name ?? ''}
            onChangeText={(text)=>{setEquipment({...equipment,name:text})}}
        />
            }
          


            {/* Sampling Type Options */}
            {sampleOption != 'other'?
                <VGInput label="Sampling Type" 
                    type="picker" onPress={onSampleTypePress}
                    value={selectedSampleOption.name}
                />
                : null}
            
            </ScrollView>
            </KeyboardAvoidingView>
            <View style={[spacing.Bottom, {flex:0}]}>
            <VGButton role="PRIMARY" onPress={onSavePress}
            >Save</VGButton>
            </View>
        
        {/* Save */}
        



            {currentSelection == pickSelection.barcode ? <BarcodeReader isNumeric={true} onBarcodeRead={onBarcodeRead} setPickingData={()=>{setCurrentSelection(pickSelection.none)}} /> : null}
            {currentSelection == pickSelection.sourceField ? <FieldSamplePicker canAdd={true} fields={fields} currentSampleFields={sourceFields} pickingData={currentSelection == pickSelection.sourceField} OnSaveFieldSelection={(selectedFields:Field[])=>{setSourceFields(selectedFields);setCurrentSelection(pickSelection.none);}} OnAddNewField={(field:Field)=>{setFields([...fields,field]); setCurrentSelection(pickSelection.sourceField);}} OnCancel={() => {setCurrentSelection(pickSelection.none);}} /> : null}
            {currentSelection == pickSelection.sourceBin ? <BinSamplePicker canAdd={true} bins={bins} setBins={setBins} setLocations={setBinLocations} locations={binLocations} currentBin={sourceBin} setCurrentBin={setSourceBin} pickingData={currentSelection == pickSelection.sourceBin} setPickingData={(x: boolean) => setCurrentSelection(x ? pickSelection.sourceBin : pickSelection.none)} /> : null}
            {currentSelection == pickSelection.destinationBin ? <BinSamplePicker canAdd={true} bins={bins} setBins={setBins} setLocations={setBinLocations} locations={binLocations} currentBin={destinationBin} setCurrentBin={setDestinationBin} pickingData={currentSelection == pickSelection.destinationBin} setPickingData={(x: boolean) => setCurrentSelection(x ? pickSelection.sourceBin : pickSelection.none)} /> : null}
            {currentSelection == pickSelection.destinationBuyer ? <BuyerSamplePicker canAdd={true} enterprise={enterprise} cachedEnterpriseImages={cachedEnterpriseImages}  pickingData={currentSelection == pickSelection.destinationBuyer} setPickingData={setCurrentSelection} setCurrentBuyer={setDestinationBuyer} currentBuyer={destinationBuyer} buyers={buyers} setBuyers={setBuyers} /> : null}
            {currentSelection == pickSelection.equipment ? <EquipmentSamplePicker equipmentList={equipmentList} setEquipmentList={setEquipmentList} pickingData={currentSelection == pickSelection.equipment} setPickingData={setCurrentSelection} currentEquipment={equipment} setCurrentEquipment={setEquipment} /> : null}
            {currentSelection == pickSelection.manualEquipment ? <OverlayPicker type={['name']} text={'Pick a Source Option'} isVisible={currentSelection == pickSelection.manualEquipment} setVisible={setCurrentSelection} setData={setSourceOption} data={otherSourceOptions} />: null}
            {currentSelection == pickSelection.samplingType ?   <OverlayPicker type={['name']} text={'Pick a Sampling Option'} isVisible={currentSelection == pickSelection.samplingType} setVisible={setCurrentSelection} setData={setSelectedSampleOption} data={manualSampleTypes} /> : null}   
           

            {currentSelection == pickSelection.grain ? <SampleGrainPicker canAdd={true} grain={grainList} varieties={grainVarList}
            OnConfirmed={(grainType,grainSubCrop,grainVarieties) => {
                setCurrentGrain({grain:grainType,subCrop:grainSubCrop,grainVarieties:grainVarieties});
                setCurrentSelection(pickSelection.none);
            }}
            OnClosed={()=>{        
                setCurrentSelection(pickSelection.none)
            }} /> : null}
                    
    </View>
    );
}



export default NewSampleScreen;