import React from "react";
import { View,Text,Image,TextInput,TouchableOpacity, Dimensions, Alert } from "react-native";
import { Overlay } from "react-native-elements";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { defaultDocument } from "../../../Helpers/Storage/DefaultStates";
import { Document, SubSample, MasterSample } from "../../../Helpers/Storage/UserState";
import * as FileSystem from 'expo-file-system';
import * as DocumentPicker from 'expo-document-picker';
import { ApiResponse } from "../../../Helpers/GeneralTypes";
import { ApiCall } from "../../../Helpers/Functions/GeneralFunctions";
import { TakeImage } from "../../../Helpers/Functions/Online/ImageFunctions";
import AppContext from "../../../Contexts/AppContext";
import VGInput from "../../../Components/Input/Input";
import spacing from "../../../Styles/Spacing";
import textStyles from "../../../Styles/Text";
import VGButton from "../../../Components/Button/Button";

function AddSampleDocument(props: {sample:MasterSample,setPickingData:any,documents:Document[],setDocuments:any}){

    const appContext = React.useContext(AppContext);
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height
    const enum dataPick {
        Default,
        PickingDocument,
     };
     const [pickingData,setPickingData]  = React.useState<dataPick|null>(dataPick.Default);
    const [document,setDocument] = React.useState<Document>({...defaultDocument})

    const titleRef = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState(false);

     function onChangeTitleText(text){
        let oldDoc = document;
        oldDoc.title = text;
        setDocument({...oldDoc});
    }

    async function onAddDocPress(){

        appContext.setAlertOptions({title:'Document Type',desc:'Would you like to add the document as an image or as a Document?',options:[{text:'Add as Image', role:'SECONDARY', onPress: async() => {
            appContext.setShowAlert(false);
            let  photoStatus = await TakeImage(); 
            
            const profile = await GetData('Profile');

            document.doc_type = 'jpg';
                               
            document.master_sample_id = props.sample.id
            const saveDocStatus = await SaveDocument(photoStatus,document);

            if(saveDocStatus != null)
            {
                console.log('Saving and closing');
                let oldDocs = props.documents;
                oldDocs.push(saveDocStatus);
                props.setDocuments([...oldDocs]);
                props.setPickingData(9);
   
            }
            else
            {
                console.log('Document not saved: ' + JSON.stringify(saveDocStatus));
                props.setPickingData(9);
         
            }
        }}, {text:'Add as Document', role:'SECONDARY', onPress:async() => {
              
            console.log('Trying to grab document');
            appContext.setShowAlert(false);
            const profile = await GetData('Profile');
 
                             // Pick a single file
            let result = await DocumentPicker.getDocumentAsync({});
 
                            if(result.type == "success" && result != undefined)
                            {
                                let temp = await FileSystem.getInfoAsync(result.uri);

                                
                                if(ValidateDocument(temp,result,document))
                                {
                                    let isPDF = result.mimeType;
                                    const index = result.mimeType.lastIndexOf('/');
                                    isPDF = isPDF.substring(index+1,isPDF.length)
                                    document.doc_type = isPDF;
                               
                                    document.master_sample_id = props.sample.id

                                    const file = await FileSystem.readAsStringAsync(result.uri,  { encoding: FileSystem.EncodingType.Base64 });
                                    const saveDocStatus = await SaveDocument(file,document);

                                    if(saveDocStatus != null)
                                    {
                                        console.log('Saving and closing');
                                        let oldDocs = props.documents;
                                        oldDocs.push(saveDocStatus);
                                        props.setDocuments([...oldDocs]);
                                        props.setPickingData(9);
                                    }
                                    else
                                    {
                                        console.log('Document not saved: ' + JSON.stringify(saveDocStatus));
                                        props.setPickingData(9);
                                    }
                                }
                            }
         }}]})

     appContext.setShowAlert(true);
    }

    function onClosePress(){props.setPickingData(9)}


return (
    <>

    {pickingData  == dataPick.Default ?
             <Overlay  isVisible={pickingData == dataPick.Default}  onBackdropPress={() => {props.setPickingData(null)}}>

             <View style={{width:width/1.2,height:height/1.5}}>
                 {/* {isLoading ?     <Spinner
             visible={isLoading}
             textContent={'Saving PDF...'}
             cancelable={false}
             /> : null } */}
             
                         
                        <View style={spacing.ScreenHeader}>
                             <Text style={textStyles.title}>Add Document</Text>
                         </View>

                         <VGInput label="Title" onChangeText={onChangeTitleText}
                            value={document?.title??''}
                         />
             
                        {document.title != '' ?      
                            <VGInput label="Add Document" type="picker"
                                onPress={onAddDocPress} icon="plus"
                                value={document.file_path}
                            />
                            : null}
             
             
                        <View style={spacing.Bottom}>
                            <VGButton role="CANCEL" onPress={onClosePress}>Close</VGButton>
                        </View>
             
                        
                
                     </View>
             
                 </Overlay>
    
    : null}

    </>
);
}
export default AddSampleDocument;


async function SaveDocument(file:string,document:Document)
{
    const formData = new FormData();

    
    formData.append('document',JSON.stringify(document, (k, v) => v ?? undefined))
        formData.append('file',file);
    

 
    const wasLoginSuccessful = await ApiCall<ApiResponse & { document:Document}>("https://webservice.verigrain.com/api/take-sample-document", formData,true);
 
    if(wasLoginSuccessful != null)
    {
        console.log('SUCCESS');
        return wasLoginSuccessful.document;
    }
    else
    {
        return null;
    }
}

function ValidateDocument(temp: FileSystem.FileInfo, result: { type: "success"; name: string; size?: number; uri: string; mimeType?: string; lastModified?: number; file?: File; output?: FileList; },Document:Document) {
    


    if(temp.exists && result != null && (result.mimeType == 'application/pdf' || result.mimeType == 'image/jpeg' || result.mimeType == 'image/png'))
    {
        return true;
    } 
    else
    {
        return false;
    }
}
