import * as React from 'react';
import { GetAsyncDataParsed, GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, TouchableOpacity, Alert, Image } from 'react-native';

import { SendBuyerNotificationEmail } from '../../../Helpers/Functions/Online/BuyerFunctions';
import Icon from 'react-native-vector-icons/FontAwesome';
import { SaveCompanyDetails, ValidateBuyerFields } from '../../../Helpers/Functions/Offline/BuyerFunctions';
import { Buyer } from '../../../Helpers/Storage/UserState';
import { addBuyerSelector, defaultBuyer } from '../../../Helpers/Storage/DefaultStates';
import { RequiredText } from '../../../Components/SimpleComponents';
import spacing from '../../../Styles/Spacing';
import textStyles from '../../../Styles/Text';
import AppContext from '../../../Contexts/AppContext';
import VGInput from '../../../Components/Input/Input';
import VGButton from '../../../Components/Button/Button';

//This function houses a modal with is used to add a new location to the profile's location data
function AddNewSampleField(props: { companies: Buyer[], currentCompany: Buyer, setCompanies: any, setCurrentCompany: any, setPickingData: any, pickingData: boolean }) {
    const [isLoading, setIsLoading] = React.useState(false);

    const appContext = React.useContext(AppContext);
    const nameRef = React.useRef(null);
    const locationRef = React.useRef(null);

    const [locatationStatusColor, setLocationStatusColor] = React.useState('lightgrey');
    const [nameStatusColor, setNameStatusColor] = React.useState('lightgrey');
    const [buyerEmailStatusColor, setBuyerEmailStatusMessage] = React.useState('');
    const emailRef = React.useRef(null);

    const [currentBuyer, setCurrentBuyer] = React.useState({ ...defaultBuyer });


    //This useEffect will grab the data before rendering the page
    //this means we dont need to use a button to grab data!


    React.useEffect(() => {

        if (currentBuyer != addBuyerSelector && currentBuyer.location.length <= 40 && currentBuyer.location != '') {
            setLocationStatusColor('green');
        }
        else if (currentBuyer.location == null || currentBuyer.location == '') {
            setLocationStatusColor('lightgrey');
        }
        else {
            setLocationStatusColor('red');
        }
    }, [currentBuyer.location])

    React.useEffect(() => {

        if (currentBuyer != addBuyerSelector && currentBuyer.name.length <= 40 && currentBuyer.name != '') {
            setLocationStatusColor('green');
        }
        else if (currentBuyer.name == null || currentBuyer.name == '') {
            setLocationStatusColor('lightgrey');
        }
        else {
            setLocationStatusColor('red');
        }
    }, [currentBuyer.name])

    React.useEffect(() => {

        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!reg.test(currentBuyer.buyer_email) && currentBuyer.buyer_email != '') {
            setBuyerEmailStatusMessage('Please enter a valid email address');
        }
        else {
            setBuyerEmailStatusMessage('');
        }
    }, [currentBuyer.buyer_email])

    async function onSavePress(){

        const errorMessages = ValidateBuyerFields(currentBuyer);

        if (errorMessages.length == 0) {


            try {
                const profile = await GetData('Profile');
                let status = await SaveCompanyDetails(currentBuyer, profile);

                if (status != null) {

                    const userEmail = await GetData('Email');
                    const isOffline = await GetAsyncDataParsed<boolean>("isOffline");

                    if (!isOffline) SendBuyerNotificationEmail(userEmail, status.buyer_email);

                    let oldList = props.companies;
                    oldList.splice(oldList.length - 1, 0, status);
                    props.setCompanies(oldList);

                    props.setCurrentCompany(status);
                    props.setPickingData(false);

                }

            }
            catch
            {
                appContext.setAlertOptions({title:'Could not save buyer',desc:'Please try again at a later time',options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
            
                        appContext.setShowAlert(false);
                      }}
                  ]});
                   appContext.setShowAlert(true); 
            }



        }
        else {
            let fullString = ``;
            errorMessages.forEach(error => {
                fullString += error + `\n\n`;
            });
            appContext.setAlertOptions({title:'Invalid Buyer/Broker',desc:fullString,options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
        
                    appContext.setShowAlert(false);
                  }}
              ]});
               appContext.setShowAlert(true); 
        }
    }

    function onChangeLocationText(text){
        let oldBuyer = currentBuyer;
        oldBuyer.location = text;
        props.setCurrentCompany({ ...oldBuyer });
    }

    function onChangeNameText(text){
        let oldBuyer = currentBuyer;
        oldBuyer.name = text;
        props.setCurrentCompany({ ...oldBuyer });
    }

    function onChangeEmailText(text){
        let oldBuyer = currentBuyer;
        oldBuyer.buyer_email = text;
        props.setCurrentCompany({ ...oldBuyer });
    }

    return (
        <View style={spacing.Container}>

            <View style={spacing.ScreenHeader}>
                <Text style={textStyles.title}>Add Buyer</Text>
            </View>

            <RequiredText />

            <VGInput label="Location" 
                onChangeText={onChangeLocationText}
                value={currentBuyer.location}
            />

            <VGInput label="Name" 
                onChangeText={onChangeNameText}
                value={currentBuyer.name}
            />
            
            <VGInput label="Buyer Email" required 
                error={buyerEmailStatusColor}
                onChangeText={onChangeEmailText}
                value={currentBuyer.buyer_email}
            />

            <View style={spacing.Bottom}>
                <VGButton role="PRIMARY" onPress={onSavePress}
                    >Save</VGButton>
            
                <VGButton role="CANCEL" onPress={() => {
                        setCurrentBuyer({ ...defaultBuyer });
                        props.setPickingData(false);
                    }}>Close</VGButton>
            </View>

            
        </View>
    )
}


export default AddNewSampleField