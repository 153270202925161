import React, { useContext } from "react";
import { View, Image, Text, FlatList, TouchableOpacity } from "react-native";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { GetDeletedBinLocations, GetLocations } from "../../../Helpers/Functions/Offline/BinFunctions";
import { BinLocation } from "../../../Helpers/Storage/UserState";
import Spinner from 'react-native-loading-spinner-overlay';
import styles from '../../../Styles/styles';
import EditBinLocation from "./EditBinLocation";
import RestoringBinLocationScreen from "./RestoringBinLocationScreen";
import { useImmer } from 'use-immer';
import AddingBinLocationScreen from "./AddingBinLocationScreen";
import { useIsFocused } from "@react-navigation/native";
import { addBinLocationSelector } from "../../../Helpers/Storage/DefaultStates";
import { learnMoreLookupData } from "../../../Helpers/Storage/LearnMoreInfoLookup";
import { toJsonSet } from "../../../Helpers/Functions/GeneralFunctions";
import AppContext from "../../../Contexts/AppContext";
import { GetLocalUserManual } from "../../../Helpers/Functions/Online/UserFunctions";
import VGButton from "../../../Components/Button/Button";


type modes = 'list_locations' | 'list_bins' | 'add_location' | 'restore';
export type ListLocState = { current_mode: modes, current_location: BinLocation };

/**
 * List the Bin Locations of a user
 */
function BinsScreen({ navigation }: { navigation: any }) {

    const isFocused = useIsFocused();
    const currentContext = useContext(AppContext);

    const [locations, setLocations] = React.useState<BinLocation[]>([]);
    const [deletedLocations, setDeletedLocations] = React.useState<BinLocation[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // This is used to keep track of what state the screen is in
    // depending on the current mode we can switch to show a different bins sub-screen
    const [mode, setMode] = useImmer<ListLocState>({ current_mode: 'list_locations', current_location: null });



    React.useEffect(() => {
   
        if(isFocused)
        {
            try {
                setIsLoading(true);

                let locationList =  GetLocations();
                setIsLoading(false);
                console.log('Found Locations: ', locationList);
    
                if (locationList != null) {
    

    
                    setLocations([...locationList]);
                }

            }
            catch (e) {
                console.log("Error getting bins: ", e);
                setIsLoading(false);
          
            }
    
        }
         
 


    }, [isFocused]);

    React.useEffect(() => {
        if (!isFocused) {

            setMode({ current_mode: 'list_locations', current_location: null });
        }


    }, [isFocused]);

    async function onRestorePress(){
        let data =await GetLocalUserManual();
        if(data != null)
        {
            currentContext.setCurrentScreenName(data.find(x => x.screen_name == 'RestoreObjects'))
        }
        else
        {
            currentContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='RestoreObjects'));
        }
        setMode((draft) => {
            draft.current_mode = 'restore';
            draft.current_location = null;
        });
    }



    function OnAddLocationPress() {
        setMode((draft) => {
            currentContext.setCurrentScreenName({ id: 6, screen_name: 'AddStorage' });
            draft.current_mode = 'add_location';
            draft.current_location = null;
        });
    }
    function OnLocationPress(item:BinLocation) {
          
            setMode((draft) => {
                currentContext.setCurrentScreenName({ id: 5, screen_name: 'StorageMenu' });

                draft.current_mode = 'list_bins';
                draft.current_location = item;
            });
        
    }
    
    return (
        <View style={{ flex: 1, justifyContent: 'space-evenly', marginHorizontal: 10, marginTop: 10 }}>
            {
                mode.current_mode == 'list_locations' ?
                    <>
                        {/* This is the List of Bin Locations itself */}
                        <Spinner
                            visible={isLoading}
                            textContent={'Loading...'}
                            cancelable={false}
                            textStyle={styles.spinner}
                        />
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                            <Text accessible={true} accessibilityLabel="Storage - Locations" style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Storage - Locations</Text>
                        </View>

                        <FlatList
                            data={locations.filter(x => x?.expired != 1)}
                            renderItem={({ item }: any) =>
                                <TouchableOpacity
                                accessibilityLabel={item.location}
                                    style={{ marginVertical: 3 }}
                                    onPress={() =>{OnLocationPress(item)}} >
                                        {/* TODO make into FAB */}
                                    <View style={{ flexDirection: 'row', borderWidth: 3, borderColor: 'lightgrey', padding: 3, alignSelf: 'stretch', justifyContent: 'space-between' }}>
                                       <Image style={{
                                                width: 30, height: 30, borderRadius: 5,
                                                overflow: 'hidden'
                                            }} source={require('../../../Icons/BlueLocation.png')} />

                                            <Text style={{ fontSize: 18, alignSelf: 'center', paddingRight: 8 }}>{item.location}</Text>
                                    </View>
                                </TouchableOpacity>
                              
                            }
                            keyExtractor={(item, index) => index.toString()}
                        >
                        </FlatList>

                        <VGButton role='PRIMARY' buttonStyle={{marginBottom:10}}
                    onPress={OnAddLocationPress} >Add Location</VGButton>
                        <VGButton role="SECONDARY" buttonStyle={{marginBottom:10}} 
                         onPress={onRestorePress}
                        >Restore Deleted Locations</VGButton>
                        
                    </>
                    : null
            }

            {/* If we are not showing the list of bin locations then switch to the appropriate screen based off of the mode variable */}

            {mode.current_mode == 'add_location' ? <AddingBinLocationScreen type={"add"} parentMode={mode} setParentMode={setMode} locations={locations} setLocations={setLocations} /> : null}
            {mode.current_mode == 'list_bins' ? <EditBinLocation parentMode={mode} setParentMode={setMode} locations={locations} setLocations={setLocations}  /> : null}
            {mode.current_mode == 'restore' ? <RestoringBinLocationScreen parentMode={mode} setParentMode={setMode} locations={locations} setLocations={setLocations} /> : null}
        </View>
    );
}

export default BinsScreen;