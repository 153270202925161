import moment from "moment";
import { Dimensions, View,Text, ScrollView,StyleSheet, FlatList} from "react-native";
import { Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import VGButton from "../../../Components/Button/Button";
import { Bin, MasterSample } from "../../../Helpers/Storage/UserState";


function BinHistoryScreen({historyData,OnClosed}: {
    historyData: {bin:Bin,binHistory:{volume:number,added:boolean,sample?:MasterSample,creation_date:Date}[]},
    OnClosed:Function
})
{

    return (
            <Overlay overlayStyle={{width:Dimensions.get('screen').width,height:Dimensions.get('screen').height/1.2}} isVisible={true}>
                <View style={{flex:1}}>

                <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                    <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Storage - History</Text>
                </View>

                <ScrollView
                horizontal={true}>


                <View style={styles.table}>

                    <View style={styles.row}>

                        <View style={[styles.cell, { width: 120 }]}><Text>Barcode</Text></View>
                        <View style={[styles.cell, { width: 180 }]}><Text>Created At</Text></View>
                        <View style={[styles.cell, { width: 80 }]}><Text>Volume</Text></View>
                    </View>



                    <FlatList


                        nestedScrollEnabled
                        data={historyData.binHistory}

                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item }) => (

                            <View style={styles.row}>

                                <View style={[styles.cell, { width: 120 }]}>
                                    <Text >{item?.sample ? item?.sample?.barcode: 'N/A'}</Text>
                                </View>

                                <View style={[styles.cell, { width: 180 }]}>
                                    <Text>{moment(item.creation_date).format('MMM Do YYYY, h:mm a').toString()}</Text>
                                </View>

                                <View style={[styles.cell, { width: 80 }]}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', flex: 1 }}>
                                        <View style={{ flexDirection: 'column', flex: 1 }}><Text style={{}}>{item.volume}</Text></View>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Icon style={{}} name={item.added ? 'arrow-up' : 'arrow-down'} size={15} color={item.added? 'orange' :  '#1c448c'} />
                                        </View>

                                    </View>
                                </View>


                            </View>
                        )} />

                </View>
            </ScrollView>

            <VGButton role="CANCEL" onPress={() => {OnClosed()}}>Close</VGButton>
                </View>
            </Overlay>
    );
}
export default BinHistoryScreen

const styles = StyleSheet.create({
    table: {
        flex: 1,
    },
    row: { flexDirection: 'row' },
    column: { flexDirection: 'column' },
    cell: {
        borderWidth: 1, flexDirection: 'column', alignSelf: 'center', textAlign: 'center', justifyContent: 'flex-end', width: 200, paddingVertical: 5, paddingHorizontal: 5, alignItems: 'center'
    },
});