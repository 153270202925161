import React from "react";
import { Dimensions, View,Text,Image, TouchableOpacity, Vibration, Alert, GestureResponderEvent, AppState, Linking} from "react-native";
import { Overlay } from "react-native-elements";
import VGButton from "../../../Components/Button/Button";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { ConvertBase64ToValue, TakeSample, WriteCharacteristic } from "../../../Helpers/Functions/Offline/ProgressSampleFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { ManualProgressTimes, ManualSamplingTypes, MasterSample } from "../../../Helpers/Storage/UserState";
import spacing from "../../../Styles/Spacing";
import styles from "../../../Styles/styles";
import textStyles from "../../../Styles/Text";
import { Sound } from 'expo-av/build/Audio';
import { Audio } from 'expo-av';
import { AddToQueue } from "../../../Helpers/Storage/OfflineQueue";
import { BleManager, Device } from "react-native-ble-plx";
import { AccuSamplerIdentifiers } from "./SMARTSCOOP_IDENTIFIERS";
import { GenerateNumberIDSync } from "../../../Helpers/Functions/GeneralFunctions";
import AppContext from "../../../Contexts/AppContext";
import { useIsFocused } from "@react-navigation/native";
import { HasValidBTPermissions } from "./UltraSamplerScreen";
import Spinner from "react-native-loading-spinner-overlay";


function SmartSamplerScreen(props:{
    manualProgressTimes:ManualProgressTimes[],
    sample:MasterSample,
    isVisible:boolean,
    isHidden:boolean,
    manualSamplingType:ManualSamplingTypes,
    ToggleHide:() => void,
    OnFinish:() => void,
    OnSave:(data:string)=>void
})
{
    const isFocused = useIsFocused();

    const appState = React.useRef(AppState.currentState);
    const appContext = React.useContext(AppContext);
    const [alertSound, setAlertSound] = React.useState<Sound>();
    const [successSound,setSuccessSound] = React.useState<Sound>();
    const [manualProgressTimes,setManualProgressTimes] = React.useState<ManualProgressTimes[]>([...props.manualProgressTimes]);
    const [sample,setSample] = React.useState<MasterSample>(props.sample);
    const [selectedSamplingMethod,setSelectedSamplingMethod] = React.useState<ManualSamplingTypes>({...props.manualSamplingType});
    const [ticking,setTicking] = React.useState<boolean>(false);
    const [samplesRemaining,setSamplesRemaining] = React.useState(-1);
    const [average,setAverage] = React.useState(0);
    const [quality,setQuality] = React.useState(0);

    const [connecting,setConnecting] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [device,setDevice] = React.useState<Device>(null);
    const [bluetoothManager,setBluetoothManager] = React.useState<BleManager>(null);
    const [isLoading, setIsLoading] = React.useState(false);

    async function PlayAlertSound()
    {
        const {sound} = await Audio.Sound.createAsync(require('../../../assets/gentle-alarm-474.mp3'));
        setAlertSound(sound);
        await sound.playAsync();
    }

    async function PlaySuccessSound()
    {
        const {sound} = await Audio.Sound.createAsync(require('../../../assets/arpeggio-467.mp3'));
        setSuccessSound(sound);
        await sound.playAsync();
    }

    React.useEffect(() => {
        return alertSound
        ? () => {
            alertSound.unloadAsync();
          }
        : undefined;
    },[alertSound])

    React.useEffect(() => {
        return successSound
        ? () => {
            successSound.unloadAsync();
          }
        : undefined;
    },[successSound])

    /**
     * This function will toggle the timer start/stop
     */
    function OnTimerPress()
    {
        ticking ? setTicking(false) : setTicking(true);
    }


    async function WriteSampleDataToDevice() {
        
        await WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_SAMPLES_REQUIRED_ID,sample.samples_required);
         await WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_TARGET_TIME_ID,sample.target_time);
         await WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_CURRENT_TIME_ID,sample.time_current);
         await WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_IS_PAUSED_ID,1);
         const takenSamples = sample.samples_required - manualProgressTimes.length;
         await WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_SAMPLES_TAKEN_ID,takenSamples);
    }

    async function ReadDataFromDevice()
    {
        // const curTime = await device.readCharacteristicForService(AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_CURRENT_TIME_ID);
        // const curTimeValue = ConvertBase64ToValue(curTime.value);


        // const curTime = await device.readCharacteristicForService(AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_CURRENT_TIME_ID);
        // const curTimeValue = ConvertBase64ToValue(curTime.value);

        // const curTime = await device.readCharacteristicForService(AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_CURRENT_TIME_ID);
        // const curTimeValue = ConvertBase64ToValue(curTime.value);

        // setSample({...sample,time_current:curTimeValue})

    }

    async function MonitorSampleData()
    {
        const userID = await GetData('ID');
        bluetoothManager.monitorCharacteristicForDevice(device.id,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_CURRENT_TIME_ID,(error,char) => {
            if(char != undefined)
            {
                let value = ConvertBase64ToValue(char.value);
                setSample({...sample,time_current:value})
            }
        });



        bluetoothManager.monitorCharacteristicForDevice(device.id,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_RECORDED_TIME_ID, (error,char) => {
            if(char != undefined)
            {

                let value = ConvertBase64ToValue(char.value);
                const curDate = new Date();
                //push a new progress time object
                const manualEntry:ManualProgressTimes = { id: GenerateNumberIDSync(userID), master_sample_id: sample.id, created_at: curDate, time: sample.target_time - value, updated_at: curDate, user_id: parseInt(userID) };

                const res =  AddToQueue('manual_progress_times',manualEntry,'add');

                if(res)
                {
                    console.log('---Adding manual entry to queue---');
                    setManualProgressTimes(oldArray => [...oldArray,manualEntry]);
                }
            }
        });

    }

    function ConnectToSmartSampler()
    {
        setConnecting(true);
        setIsLoading(true);
        appContext.setAlertOptions({
            title:'Unable to find device',
            desc:"We couldn't find a device related to the smart sampler",
            options:[{
                role:'PRIMARY',
                text:'Confirm', 
                onPress:async() => {
                appContext.setShowAlert(false);
            }}
        ]});
        setTimeout(async ()=>{
            setIsLoading(false); // Timeout up, remove spinner
            console.log("TIMES UP");
            console.log(connecting);
            if(!connecting){ //If we are still connecting after 15 seconds, cancel it
            setConnecting(false);
            appContext.setShowAlert(true);
            }
        },10000)
        console.log('Scanning');

        const grabPermission = async() => {
            const stat =  await HasValidBTPermissions();
            if(!stat)
            {
              appContext.setAlertOptions({title:'Permissions required',desc:'VeriGrain collects location data to enable Bluetooth features and to record current location when a sample is taken.\n Open your app setting and enable. \n - Nearby Devices \n -Location',
              options:[
                  {text:'Confirm', role:'PRIMARY',onPress:() => {appContext.setShowAlert(false);Linking.openSettings(); }},
                  {text:'Cancel', role:'CANCEL',onPress:()=>{appContext.setShowAlert(false);}}
              ]})
              appContext.setShowAlert(true);
            }
            else
            {
                console.log('permissions already exist');
            }
          }
         grabPermission();
        
        bluetoothManager.startDeviceScan(
            null,
            null,
             (error, originalDevice:Device) => {
              if (error) {
                setIsLoading(false);
                setConnecting(false);
                appContext.setAlertOptions({title:'Permissions required',desc:'VeriGrain collects location data to enable Bluetooth features and to record current location when a sample is taken.\n Open your app setting and enable. \n - Nearby Devices \n -Location',
                options:[
                    {text:'Confirm', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);Linking.openSettings(); }},
                    {text:'Cancel', role:'CANCEL',onPress:()=>{appContext.setShowAlert(false);}}
                ]})
                appContext.setShowAlert(true);
                console.log('error', JSON.stringify(error));
              } else if (originalDevice) {         
                console.log(originalDevice.name);
                //If found stop the scan and set the device
                if ( originalDevice.name == 'SMARTSCOOP' ) {
                  setDisabled(false);
                  bluetoothManager.stopDeviceScan();
        
                  originalDevice.connect().then((originalDevice:Device) => {
                    setConnecting(false);
                    setIsLoading(false);
                    originalDevice.onDisconnected(() => {
                        console.log('...Device has been disconnected');
                        setDevice(null);
                        setTicking(false);
                    })
                    originalDevice.discoverAllServicesAndCharacteristics().then((originalDevice: Device) => {
                      setDevice(originalDevice)
                    })
                  })
                
                }
              }
            }
          );
    }

    function OnDonePressed()
    {

        appContext.setAlertOptions({title:'Are you sure you want to finish with the SmartSampler process?',desc:'Your sample will no longer be in-progress',options:[
            {text:'Confirm Done', role:'PRIMARY', onPress:async()=>{
                appContext.setShowAlert(false);
                FinishSample(true);
            }},
            {text:'Save Progress', role:'SECONDARY', onPress:async() => {
                appContext.setShowAlert(false);
                FinishSample();
            }},
            {text:'Return to Sampling', role:'CANCEL', onPress:() => {
                appContext.setShowAlert(false);
            }}
        ]})
        appContext.setShowAlert(true);

    }

    //This function will get the samples remaining
    function GetSamplesRemaining()
    {
        //Is the samples required field not null?
        if(sample?.samples_required != null)
        {

            //take the length of the progress time array and subtracct from samples requires to get samples remaining
            let num = sample?.samples_required - manualProgressTimes.length;
            console.log('Samples remaining...' + num);
            setSamplesRemaining(num);
        }
        else
        {
            console.log(':(');
        }

    }

    async function FinishSample(finish?:boolean)
    {
        
        const finishedSample:MasterSample = {...sample,completed:finish?1:0};
        const queueStatus = await AddToQueue('master_samples',finishedSample,'update');
        console.log('finish status: ' + queueStatus);
        if(queueStatus)
        {
            props.OnFinish();
        }
    }


    //This function will get the average time for a sample
    function GetAverageTime()
    {
        //grab the average by totalling the time array and dive by length
        let total = 0;
        manualProgressTimes.forEach(time => {
            total += time.time;
        });

        const avg = total / manualProgressTimes.length;

        //the min and max ranges to determine the quality
        //If the target time was 10, the maximum average to be considered a 'perfect' would be 15 and the minimum would be 5
        const PerfectQuality = sample.target_time * .5;
        const OkayQuality = sample.target_time * .7;
   
 
        if(!isNaN(avg))
        {
            if(avg <= sample.target_time + PerfectQuality && avg >= sample.target_time - PerfectQuality )
            {
                setQuality(3);
            }
            else if (avg <= sample.target_time + OkayQuality && avg >=  sample.target_time - OkayQuality )
            {
                setQuality(2);
            }
            else
            {
                setQuality(1);
            }
    
            setAverage(avg);
        }
    }

    /**
     * This use effect will keep track of a ticking boolean to determine whether to start the timer or not
     */
    React.useEffect(() => {
        //If the user started the timer
        if(ticking)
        {
             WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_IS_PAUSED_ID,0);

        }
        else
        {
             WriteCharacteristic(device,AccuSamplerIdentifiers.BT_SMARTSCOOP_ID,AccuSamplerIdentifiers.BT_IS_PAUSED_ID,1);

        }
    },[ticking])


    /**
     * This useeffect gets triggered each time a new manualProgress time is created OR when the samples required gets changes (useful on startup)
     */
    React.useEffect(() => {

     
        //Get samples remaining and average time
        GetSamplesRemaining();
        GetAverageTime();
    },[manualProgressTimes,sample?.samples_required]);


    /**
     * This use effect will trigger each time the sample remaining variable gets changed
     */
    React.useEffect(() => {
        //Ensure we dont check if its been initialized
        if(samplesRemaining != -1 && samplesRemaining != null)
        {
            //If there are no more samples to take
            if(samplesRemaining == 0)
            {
                //stop the clock
                setTicking(false);
          
                appContext.setAlertOptions({title:'Sample Completed',desc:'Your sample will no longer be in-progress',options:[
                    {text:'OK', role:'PRIMARY', onPress:async()=>{
                        appContext.setShowAlert(false);
                       await  FinishSample(true);
                    }}
                ]})
                appContext.setShowAlert(true);
            }
        }
    },[samplesRemaining])

    //TODO set permissions here


    /**
     * This useeffect will trigger each time the current_time is changed
     */
    React.useEffect(() => {

        //if the time is less than 10 and 3 seconds have elapsed than vibrate the phone and play an alert sound
        if(sample.time_current  % 3 == 0 && sample.time_current <= 10)
        {
          Vibration.vibrate([500,500], false);
          PlayAlertSound();
        }

    },[sample?.time_current])


 
 


    React.useEffect(() => {
        if(device != null && device?.name == 'SMARTSCOOP')
        {
            console.log('device do be changing');
            const ConnectData = async() => {
                await WriteSampleDataToDevice();
                await MonitorSampleData();
       
            }
            ConnectData();
         

        }
    },[device])

    React.useEffect(() => {
        setBluetoothManager(new BleManager());
    },[])



    React.useEffect(() => {
        if(isFocused)
        {
            AppState.addEventListener('change', async nextAppState => {
                if(appState.current.match(/inactive|background/) && nextAppState === "active")
                {
                    if(device != null)
                    {
                        ReadDataFromDevice();
                    }
                }
            })
        }
    },[isFocused])
    return(

        <>
        
        {!props.isHidden ?
        <Overlay fullScreen isVisible={!props.isHidden}>
       {isLoading ? <Spinner
        visible={isLoading}
        textContent={'Loading...'}
        cancelable={false}
        textStyle={styles.spinner}
      /> : null}
                <View style={spacing.TitleBar}>
                    <Text style={styles.title}>{selectedSamplingMethod.id == 1 ? 'Standard' : 'AccuSampler'}</Text>
                </View>

                <VGButton role={"SECONDARY" } disabled={device == null ? false:true} onPress={() => {ConnectToSmartSampler()}}>{device == null ? connecting ? 'Connecting...' : 'Connect' : 'Connected' }</VGButton>

                <VGButton disabled={disabled} role={ticking ? "SECONDARY" : "PRIMARY"} onPress={OnTimerPress}>{ticking ? 'Stop'  : 'Start' }</VGButton>


                <VGButton role="CANCEL" onPress={OnDonePressed}>Done</VGButton>


                <View style={styles.samplingListContainer}>

                    <View style={styles.samplingListInnerContainer}>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Number of samples taken</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{manualProgressTimes.length??'N/A'}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Number of samples required</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.samples_required??'N/A'}</Text>
                            </View>
                        </View>
                        
                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Number of samples remaining</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{samplesRemaining}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Sample Interval - seconds</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.target_time??0}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Time to take Next Sample</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.time_current??0}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Average Time for Sample</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{average}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Sample Time Score</Text>
                            </View>
                  

                            <View style={{flexDirection:'row'}}>
                                    {quality == 3 ?    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/FilledHappyFace.png')}
                                /> :    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/BlankHappyFace.png')}
                                /> }
                                {quality == 2 ?    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/FilledOkayFace.png')}
                                /> :    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/BlankOkayFace.png')}
                                /> }
                                    {quality == 1 ?    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/FilledSadFace.png')}
                                /> :    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/BlankSadFace.png')}
                                /> }
                           </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Scoop Size</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.scoop_size??'1'}</Text>
                            </View>
                        </View>

                    </View>
                </View>
             



            <VGButton role="CANCEL"
                 onPress={props.ToggleHide}>Main Menu</VGButton>

        </Overlay>
                :
                <TouchableOpacity style={{flex:1,
                    width: Dimensions.get('window').width - 10,
                    alignSelf:'center',
                    height: 50,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position:'absolute',
                    bottom:10}}  onPress={props.ToggleHide} >
         
             <Text style={{color:'white',textAlign:'center'}}>Return To AccuSampler</Text>
           </TouchableOpacity>
                }
        </>
    );
}
export default SmartSamplerScreen;


