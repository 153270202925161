import moment from "moment";
import { ApiResponse } from "../../GeneralTypes";

import { AddToQueue } from "../../Storage/OfflineQueue";
import { CombinedSampleRecord,MasterSample } from "../../Storage/UserState";
import { ApiCall, GenerateNumberIDSync } from "../GeneralFunctions";



export async function SaveCombinedSampleRecords(masterSample: MasterSample, combinedSampleRecords: CombinedSampleRecord[]): Promise<MasterSample> | null {


  const unix = moment().unix();
  

    const master_sample:MasterSample = {...masterSample,id:GenerateNumberIDSync(masterSample.user_id),sample_taken_at:unix};

      combinedSampleRecords.forEach(record => {
        record.id = GenerateNumberIDSync(masterSample.user_id);
        record.master_sample_id = master_sample.id
        
      })
 
      const ms_add = await AddToQueue("master_samples", master_sample, "add");

      if (!ms_add) return null;

      await AddToQueue("combined_sample_records", combinedSampleRecords, "add");

      return ms_add ? master_sample : null;
}
