import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, Alert, TouchableOpacity,KeyboardAvoidingView, useWindowDimensions,Image, Linking } from 'react-native';
import Toast from 'react-native-tiny-toast';
import {StoreData, GetData} from '../../Helpers/AsyncStorageHelper';
import {useNavigation} from '@react-navigation/native';
import { Header } from 'react-native/Libraries/NewAppScreen';
import { Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { FlatList } from 'react-native-gesture-handler';
import { Overlay } from 'react-native-elements';

import AddNewSampleBuyer from './AddSampleModals/AddNewSampleBuyer';
import CustomImage from '../HelperScreens/CustomImage';
import { GetBuyers, GetEnterpriseByGrowerID, GetEnterprises, UpdateCompanyDetails } from '../../Helpers/Functions/Offline/BuyerFunctions';
import { Buyer, Enterprise } from '../../Helpers/Storage/UserState';
import { addBuyerSelector } from '../../Helpers/Storage/DefaultStates';
import * as FileSystem from 'expo-file-system';
import { ShareSamples } from '../../Helpers/Functions/Online/SampleFunctions';
import VGInput from '../../Components/Input/Input';
import { RequiredText } from '../../Components/SimpleComponents';
import spacing from '../../Styles/Spacing';
import AppContext from '../../Contexts/AppContext';
import VGButton from '../../Components/Button/Button';


//This function returns typescript which will render a login screen to the user
function ShareScreen({ route, navigation }: { route: any, navigation: any }) {

const appContext = React.useContext(AppContext);
  var width = Dimensions.get('window').width; //full width
  var height = Dimensions.get('window').height; //full height

    let { shareSampleList} = route.params;

    const [hidePassword,setHidePassword] = React.useState(true);
  const [isLoading, setLoad] = React.useState(false);
  const windowHeight = useWindowDimensions().height;
  const windowWidth = useWindowDimensions().width;

  const [buyerList,setBuyerList] = React.useState<Buyer[]>([]);
  const [recentBuyer,setRecentBuyer] = React.useState<Buyer>();

    const [selectedList,setSelectedList] = React.useState<Buyer[]>([]);

    const [emailStatusColor,setEmailStatusColor] = React.useState('lightgrey');
    const [isAddingBuyer,setIsAddingBuyer] = React.useState(false);

    const [enterprises, setEnterprises] = React.useState<Enterprise[]>([])
    const [cachedEnterpriseImages,setCachedEnterpriseImages] = React.useState<{enterprise_id:number,fileUri:string}[]>([]);

    const [updateEmail,setUpdateEmail] = React.useState(false);

    const [buyerEmail,setBuyerEmail] = React.useState('');

    const emailRef = React.useRef(null);
    let renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: windowWidth,
              backgroundColor: "orange",
            }}
          />
        );

      };



    React.useEffect(() => {

  
             //This function will find all buyers
             const fetchBuyers = async() => {
              try{
                  const profile = await GetData('Profile');
               
                  let buyerList = await GetBuyers(profile);
                  buyerList.sort((a,b) => a.name.localeCompare(b.name));

            
                    
               
                  const userID = await GetData('ID');
                  let enterpriseData = await GetEnterprises();                    
                      if(enterpriseData != null)
                      {

                          let cachedImages:{enterprise_id:number,fileUri:string}[] = []

                          for(let enterprise of enterpriseData)
                          {
                              //const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;
                  
                              const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
                           
                              const image = await FileSystem.getInfoAsync(path);
          
                              if(image.exists)
                              {
                                  cachedImages.push({enterprise_id:enterprise.id,fileUri:image.uri});
                              }
                     
             
                          }
            
                       setCachedEnterpriseImages(cachedImages);
                              console.log('Found Enterpriser' + JSON.stringify(enterpriseData));
                          
                              enterpriseData.forEach(enterprise => {
                                  buyerList.unshift({id:enterprise.id,name:enterprise.name,created_at:null,expire_date:null,profile:'',updated_at:null,buyer_email:enterprise.enterprise_email,location:enterprise.location});
                              })
                          setEnterprises(enterpriseData);
                      }
          

                  buyerList.push(addBuyerSelector);
                  setBuyerList([...buyerList]);     

              }  
              catch(e){setBuyerList([addBuyerSelector]);
                   console.log('Something happened to the buyerr' + e)}
          };
            fetchBuyers();


},[])

async function onSavePress(){

  let profile = await GetData('Profile');
    let status = UpdateCompanyDetails(recentBuyer,profile);

    if(status != null)
    {
      let oldList = buyerList;
      let index = oldList.findIndex(x => x.id == recentBuyer.id);
      oldList[index].buyer_email = buyerEmail;
      setBuyerList([...oldList]);
      let oldSelectedList = selectedList;
      oldSelectedList.push(recentBuyer);
      setSelectedList([...oldSelectedList]);
      setUpdateEmail(false);
      setBuyerEmail('');
      setRecentBuyer(null);
    }
    else
    {
      Toast.show('Could not save Buyer Email at this time');
    }

 }

 function onBackdrop(){
  setIsAddingBuyer(false)
}

function onCancelPress(){
  setUpdateEmail(false);
}

async function onSendPress() {
  if(selectedList.length != 0)
  {

    try
    {
      const profile = await GetData('Profile');
      const email = await GetData('Email');
      let status = await ShareSamples(shareSampleList,selectedList,profile,email);
      if(status)
      {
          navigation.goBack();
      }
    }
    catch(error)
    {
      console.log(error);
      appContext.setAlertOptions({title:'Could not share samples',desc:'Please try again at a later time',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
      appContext.setShowAlert(true);
    }

  }
  else
  {
    Toast.show('Please add an email to share to');
  }

  }

    return (
      

      <View style={spacing.MainContainer}>
        


        {isAddingBuyer ?
        <Overlay  isVisible={isAddingBuyer}  onBackdropPress={onBackdrop}>
               <View style={{width:width/1.2,height:height/1.5}}>
 <AddNewSampleBuyer currentCompany={recentBuyer} pickingData={isAddingBuyer} setPickingData={setIsAddingBuyer} setCompanies={setBuyerList} companies={buyerList} setCurrentCompany={setRecentBuyer} />
 </View>
        </Overlay>
        : null }

        {updateEmail ?
        
        <Overlay  isVisible={updateEmail}  onBackdropPress={() => {setUpdateEmail(false)}}>
        <View style={{width:width/1.2,height:height/1.5}}>
        <View style={{flex:1}}>

<View style={{justifyContent:'space-evenly',flex:1 }}>    


<View style={{flexDirection:'row',justifyContent:'center', alignSelf:'stretch', paddingVertical:3}}>
        <Text style={{  fontSize: 28, alignSelf: 'flex-start', color:'black'}}>Add an Email</Text>    
   </View>  

    <RequiredText />

    <VGInput label="Buyer Email" required
      onChangeText={(text) => {
            setBuyerEmail(text);
        }}
    />
  

        
            <VGButton role="PRIMARY" onPress={onSavePress}
            >Save</VGButton>
  
        <VGButton role="CANCEL" onPress={onCancelPress} >Close</VGButton>

   </View>
</View>
      </View>
      </Overlay>
        : null}
  
          <View style={{flexDirection:'column',flex:2, borderWidth:2,borderColor:'lightgrey',marginBottom:5,marginTop:10}}>

              <View style={{flexDirection:'row',alignSelf:'center',marginVertical:10}}>
              <Text style={{color:'black',alignSelf:'center',fontSize:22}}>Email List</Text> 

              </View>
    <FlatList 
    style={{flex:1}}
                            data={buyerList}
                            renderItem={({ item, index }: {item:Buyer,index:number}) =>
                            <>
       
                           <TouchableOpacity style={{flexDirection:'row', marginVertical:enterprises.find(x => x.id == item.id && x.location == item.location && x.name == item.name)? 0 : 10,alignSelf:'stretch',left:enterprises.find(x => x.id == item.id && x.location == item.location && x.name == item.name) ? 0 : 10}}
                            onPress={() => {

                              if(item == addBuyerSelector)
                              {
                                setIsAddingBuyer(true);
                              }
                              else if(item.buyer_email == '' || item.buyer_email == null)
                              {

                                appContext.setAlertOptions({title:'Email not associated with buyer',desc:'The buyer you have selected does not contain a email address.',options:[
                                  {text:'Add Email', role:'PRIMARY', onPress:() => {
                                    setRecentBuyer(item);
                                    setUpdateEmail(true);
                                      appContext.setShowAlert(false);
                                    }},
                                  {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
                                ]});
                                 appContext.setShowAlert(true); 
                            
                              }
                              else
                              {
                                if(selectedList.find(x => x.id == item.id) != undefined)
                                {
                                  let oldList = selectedList;
                                  oldList.splice(selectedList.findIndex(x => x.id == item.id),1);
                                  setSelectedList([...oldList]);
                                }
                                else
                                {
                                  let oldList = selectedList;
                                  oldList.push(item);
                                  setSelectedList([...oldList]);
                                }
                              }

                            }}
                           >
                            {enterprises.find(x => x.id == item.id && x.location == item.location && x.name == item.name) ? 
                            
                          

                            <View style={{flexDirection:'row',flex:1,marginHorizontal:5,marginVertical:5,justifyContent:'center',alignSelf:'center',borderWidth:3,borderColor:selectedList.find(x => x.id == item.id)?'orange':'black'}}>
          {cachedEnterpriseImages.find(x => x.enterprise_id == item.id) ?
                                                                    
                                                                    <Image
                                                                    source={{uri: cachedEnterpriseImages.find(x => x.enterprise_id == item.id).fileUri}}
                                                                    style={{ height: Math.round(height / 12), width: Math.round(width/1.3),marginVertical:5 }}
                                                                    />                        
                                                                : 
                                                                <CustomImage  enterpriseID={item.id} width={Math.round(width/1.2)} height={ Math.round(height / 12)} />
                                                        }   
                               </View>
                        
                            : 
                            <View style={{borderBottomWidth:2,alignSelf:'center',borderColor:'orange',flex:1}}>

                                        <Text style={{fontSize:22,marginBottom:5,textAlignVertical:'center',flex:1, color: selectedList.find(x => x.id == item.id) != undefined ? 'orange' : 'black'}}>{item.name} - {item.location}</Text>

                            </View>
                            }
                  
                            </TouchableOpacity>

                            </>
                            }
                            
                            keyExtractor={(item,index) => index.toString()}
                        >
 
                        </FlatList>
        

</View>


<View style={{alignItems:'stretch', justifyContent: 'center', marginTop:10,marginBottom:10 }}>

<VGButton role="PRIMARY" onPress={onSendPress}>Send</VGButton>
           
</View>


 
          </View>
    );

  }




  //This function will validate all credentials, 
  export default ShareScreen; 