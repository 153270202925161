import Spinner from "react-native-loading-spinner-overlay";
import styles from "../Styles/styles";


function LoadingSpinner(props:{isLoading:boolean,loadingText?:string})
{

    return (
        <Spinner
        visible={props.isLoading}
        textContent={props.loadingText??'Loading...'}
        cancelable={false}
        textStyle={styles.spinner}
      /> 
    );
}
export default LoadingSpinner;