
import { Dimensions, StyleSheet } from 'react-native';
import Colours from './Colours'

var width = Dimensions.get('window').width; //full width
var height = Dimensions.get('window').height; //full height
const opacity = 'rgba(0, 0, 0, .6)';
//This is used to style the pages
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign:'center'
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'space-evenly',
  },
  topInnerContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    alignSelf: 'stretch'
  },
  bottomInnerContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  middleInnerContainer: {
    flex: 3,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  fullWidthButton: {
    flex: 5,
    justifyContent: "flex-start",
    alignItems: 'stretch',

  },
  hamburgerMenu: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    padding: 10
  },
  flatListKey: {
    fontSize: 22,
    padding: 20,
    alignSelf: 'flex-start'
  },
  flatListTextKey:{
    fontSize:22
  },
  flatListValue: {
    fontSize: 22,
    padding: 8,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  flatListTextValue:{
    fontSize:18
  },
  flatListValueLeft: {
    fontSize: 22,
    padding: 8,
    alignSelf: 'flex-start',
    justifyContent: 'flex-start'
  }
  ,
  flatList: {
    justifyContent: "space-evenly",
    alignSelf: 'stretch',
    width: width,
    paddingBottom: 80,


  },
  flatListSample: {
    justifyContent: "space-evenly",
    alignItems: 'flex-start',
    paddingRight: 20

  },
  flatListContainer: {
    flex: 5,
    justifyContent: 'space-evenly',
  }
  ,
  spinner: {
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  error:
  {
    color: '#D8000C',
    backgroundColor: '#FFBABA'
  },
  textInput:
  {
    paddingTop: 10,
    flex: 1,
    fontSize: 20,
    alignSelf: 'center',
    margin: 10,
  },
  textInputSmall:
  {
    padding: 1,
    fontSize: 18,
    alignSelf: 'center',
    margin: 2,
  },
  rowInput: {
    flexDirection: 'row',
    alignContent: 'flex-start',
    padding: 5,
    minWidth: 150
  },
  layerTop: {
    flex: 2,
    backgroundColor: opacity
  },
  layerCenter: {
    flex: 1,
    flexDirection: 'row'
  },
  layerLeft: {
    flex: 3,
    backgroundColor: opacity
  },
  focused: {
    flex: 10
  },
  layerRight: {
    flex: 3,
    backgroundColor: opacity
  },
  layerBottom: {
    flex: 2,
    backgroundColor: opacity
  },
  circle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ACACAC',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkedCircle: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: '#794F9B',
  },
  flatListSampleView: {
    flexDirection: 'row', paddingTop: 3, justifyContent: 'space-between'
  },
  textInputBorder: { borderWidth: 8, margin: 5, borderColor: 'white', backgroundColor: 'white' },
  CreateAccountTextInput: {
    margin: 5,
    backgroundColor: 'white',
    minHeight: 45
  },
  CreateAccountRowStyle: {
    flexDirection: 'row',
    alignContent: 'flex-start',
    padding: 2,
    minWidth: 150
  },
  scanoverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  unfocusedContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  middleContainer: {
    flexDirection: 'row',
    flex: .5,
  },
  focusedContainer: {
    flex: 12,
  },
  removeItemButton: {flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', marginVertical: 3, left: 15},
  dropdownInput:{   flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 5,
  marginVertical: 5,
  borderColor: 'orange',
  borderWidth: 3 },
  samplingListContainer:{flexDirection:'column',flexGrow:1,justifyContent:'flex-start',borderColor:'lightgrey',borderWidth:1},
  samplingListInnerContainer:{flexDirection:'column',justifyContent:'space-evenly',flex:1},
  padding20:{marginHorizontal:20},
  HomeGraphic: {width: width, height: (width / 1022) * 385, top: 10},
  WebHomeGraphic:{flex: 1, resizeMode:'contain', justifyContent: 'center',},
  // WebHomeGraphic:{width: width, height: '100%'},
  WebLogo:{flex: 1, resizeMode:'center',width:'40%', alignSelf: 'center'},
  card:{
    borderRadius: 8,
    //elevation: 3,
    backgroundColor: Colours.VG_AMBER,
    shadowOffset:{width:1, height:11},
    shadowColor: Colours.BLACK,
    shadowOpacity: 0.3,
    shadowRadius: 10,
    marginHorizontal: 6,
    marginVertical: 6,
    borderColor:Colours.VG_GREY + '30',
    borderWidth:5,
    width:'20%',
    minWidth:'300px',
    alignSelf:'center',
  },
  cardcontent:{
    marginHorizontal: 18,
    marginVertical: '15%',
    alignSelf:'center',
    width:'85%'
  },
  popupAlertMobile:{width:Dimensions.get('window').width/1.2,height:Dimensions.get('window').height/2},
  popupAlertWeb:{width:500,height:250,borderRadius:4},
  alertOverlay:{borderRadius:20},
  overlayImage:{ width:width,height:(width/1088)*1009,bottom:-45,left:20, aspectRatio: 1, resizeMode: 'cover',top:undefined},
  overlayImageContainer:{opacity:1,flex:1,zIndex:2}

});
export default styles;