import * as React from 'react';
import { DrawerActions, NavigationContainer, useIsFocused } from '@react-navigation/native';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList, ScrollView} from 'react-native';

import { Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import Toast from "react-native-tiny-toast";
import DateTimePicker from '@react-native-community/datetimepicker';
import { AllowedSample, AllowedSampleGrain, Enterprise, Grain, GrowerUser, MasterSample, Sample, SubSample } from "../../../Helpers/Storage/UserState";
import GrainTypePicker from "./GrainTypePicker";
import moment from "moment";
import { SaveAllowedSamples } from '../../../Helpers/Functions/Offline/AllowedSampleFunctions';
import { UpdateGrowerUser } from '../../../Helpers/Functions/Offline/UserFunctions-Offline';
import { AddAllowedSampleGrains, GetAllowedSampleGrains, RemoveAllAllowedSampleGrains } from '../../../Helpers/Functions/Offline/AllowedGrainFunctions';
import VGButton from '../../../Components/Button/Button';
//Similar to the barcode camera, except it has no barcode scan capabilities
 function AllowSampleOverlay(props: {sample:MasterSample, enterprises:Enterprise[],isVisible:boolean,setVisible:any,alreadyChecked:boolean,allowedSamples:AllowedSample[]|null,setAllowedSamples:any,growerUsers:GrowerUser[],setGrowerUser:any })
{

  const isFocused = useIsFocused();

    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height

    const [date, setDate] = React.useState<Date>()
    
    const [pickingDate,setPickingDate] = React.useState(false);
    const [linkOne,setLinkOne] = React.useState(false);
    const [linkAll,setLinkAll] = React.useState(false);
    const [linkGrain,setLinkGrain] = React.useState(false);
    const [grainList,setGrainList] = React.useState<Grain[]>([]);
    const [pickingGrain,setPickingGrain] = React.useState(false);

    const [selectedGrainList,setSelectedGrainList] = React.useState<AllowedSampleGrain[]>([]);

    const [checkedLinkSampleEnterprises,setCheckedLinkSampleEnterprises] = React.useState<Enterprise[]>([]);

    const [growerUsers,setGrowerUsers] = React.useState<GrowerUser[]>([...props.growerUsers]);
    let renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: width,
              backgroundColor: "orange",
            }}
          />
        );
      };

      React.useEffect(() => {


        if(props.allowedSamples != null)
        {



  let checkedLinked:Enterprise[] = [];
    props.enterprises.forEach(enterprise => {
      if(props?.allowedSamples.find(x => x.enterprise_company_id == enterprise.id && x.master_sample_id == props.sample.id))
      {
        console.log('PUSHYING');
        checkedLinked.push(enterprise)
      }

    });

    setCheckedLinkSampleEnterprises(checkedLinked);
        }
       
      },[isFocused])

     

      React.useEffect(() => {
          const getGrains = async() => {

            if(props.alreadyChecked)
            {
                setLinkOne(true);
            }

            const profile = await GetData('Profile');
            //Get all grains + selected Grains
            const curGrainList = await GetAllowedSampleGrains(profile);
            setGrainList(curGrainList.grainList);
            setSelectedGrainList(curGrainList.allowedSampleGrainList);

            growerUsers.forEach(growerUser => {
              if(growerUser?.allow_completed_samples == 1 && growerUser?.allow_from_date != null)
              {
                const curDate = new Date(growerUser?.allow_from_date);
                setDate(curDate);
                if(curGrainList.allowedSampleGrainList.length > 0)
                {
                 setLinkAll(false);
                  setLinkGrain(true);
                }
                else
                {
                  setLinkGrain(false);
                  setLinkAll(true);
                }
             
              }
            })
          
        

          }
          getGrains();
      },[])

      React.useEffect(() => {

        if(pickingGrain == false && linkGrain)
        {
         setPickingDate(true);
        }
      },[selectedGrainList])

    
      async function onSavePress(){

          const userID = await GetData('ID');
          const profile = await GetData('Profile');


          if(props?.sample != null)
          {

            if(linkOne)
            {
              let allowedSampleList:AllowedSample[] = [];



              checkedLinkSampleEnterprises.forEach(enterprise => {
    
                allowedSampleList.push({id:null,created_at:null,enterprise_company_id:enterprise.id,updated_at:null,master_sample_id:props.sample.id})

                
              })
              const status = await SaveAllowedSamples(allowedSampleList);

          if(status != null)
          {
          let oldAllowedList = props.allowedSamples;
        
          oldAllowedList = oldAllowedList.filter(x => x.master_sample_id != status[0].master_sample_id);

          console.log(JSON.stringify(status));
          oldAllowedList = oldAllowedList.concat(status);

              props.setAllowedSamples([...oldAllowedList]);
              props.setVisible(false);
            }

          }
        }


        let oldUser = growerUsers;
        if(date != null)
        {
          oldUser.forEach(user => {
            if(user.allow_completed_samples == 1)
            {
              user.allow_from_date = date;
            }
            else
            {
              user.allow_from_date = null;
            }
            
        
          });

          let status = await UpdateGrowerUser(oldUser);
          if(status != null)
          {
            if(selectedGrainList && selectedGrainList.length > 0)
            {
              const grainStat = await AddAllowedSampleGrains(selectedGrainList);

              if(grainStat != null)
              {
                console.log('Success!', 'added grain to allowed sample');
              }
            }
            else
            {
                  const grainStatus = await RemoveAllAllowedSampleGrains(profile);
                          if(grainStatus != null)
                          {

                            console.log('SUCCESS! Deleted some grain stuff');
                          }
            }
            Toast.show('Updated permissions');
          }
          else
          {
                Toast.show('Could not update permissions at this time');

          }
        }
    
        props.setVisible(false);
      }

 
    return(

        <Overlay  isVisible={props.isVisible}  onBackdropPress={() => {}}>

        <ScrollView style={{width:width/1.12,height:height/1.1}}>
  


        <View style={{flexDirection:'row',alignSelf:'center'}}>
        <Text style={{alignSelf:'center',fontSize:24}}>Select Companies & Options</Text>
        </View>

        <View style={{flex:1,justifyContent:'space-evenly'}}>


        {props?.sample != null ?

        <View style={{borderWidth:3,borderColor:'orange',marginVertical:10}}>
                <TouchableOpacity style={{flexDirection:'row',alignSelf:'flex-start',marginHorizontal:5,marginVertical:5}}   onPress={() => {
                  if(linkOne)
                  {
                    setLinkOne(false)
                    setCheckedLinkSampleEnterprises([]); 
                  }
                  else
                  {
                    setLinkOne(true);
                  }
              }}>
                       <Text style={{alignSelf:'center',fontSize:22,flex:1}}>Link this sample record</Text>
                       {linkOne ?
                          <Icon style={{paddingRight:10}} name="check-square" size={40} color="black" /> 
                                              :
                        <Icon style={{paddingRight:10}} name="square-o" size={40} color="black" />
                        }
            
                  </TouchableOpacity>

  
                  {linkOne ?
                  props.enterprises.map(enterprise => {
                    return (
                      <TouchableOpacity key={enterprise.id} style={{flexDirection:'row',alignSelf:'flex-start',marginHorizontal:5,marginVertical:5}}   onPress={() => {
                        let oldList = checkedLinkSampleEnterprises;
                        if(checkedLinkSampleEnterprises.find(x => x.id == enterprise.id))
                        {

                          setCheckedLinkSampleEnterprises([...oldList.filter(x => x.id != enterprise.id)]);
                        }
                        else
                        {
                          oldList.push(enterprise)
                          setCheckedLinkSampleEnterprises([...oldList]);

                        }
                    }}>
                             <Text style={{alignSelf:'center',fontSize:22,flex:1}}>{enterprise.name}</Text>
                             {linkOne && checkedLinkSampleEnterprises.find(x => x.id == enterprise.id) ?
                                <Icon style={{paddingRight:10}} name="check-square" size={40} color="black" /> 
                                                    :
                              <Icon style={{paddingRight:10}} name="square-o" size={40} color="black" />
                              }
                  
                        </TouchableOpacity>
                    );
                  })
                
           
                   : null}
              </View>    
        : null}


<View style={{borderWidth:3,borderColor:'orange'}}>
     <TouchableOpacity style={{flexDirection:'row',alignSelf:'flex-start',marginHorizontal:5,marginVertical:5}}  onPress={() => {

     if(linkAll)
     {
        setLinkAll(false)
     }
     else
     {
       setLinkGrain(false);
        setLinkAll(true);
        setPickingDate(true);
     }
     
 }}>    
       <Text style={{alignSelf:'center',fontSize:22,flex:1}}>Share all Records from</Text>
       {linkAll ?
     
          <Icon style={{paddingRight:10}} name="check-square" size={40} color="black" /> 
     
                  :
                  <Icon style={{paddingRight:10}} name="square-o" size={40} color="black" />
                  }
 
     </TouchableOpacity>



        {pickingDate ?
                           <DateTimePicker
                           testID="dateTimePicker"
                           value={date ? date : new Date()}
                           mode={'date'}
                           is24Hour={true}
                           display="default"
                           onChange={(event,selectedDate) => {
                             
                            if(selectedDate != undefined)
                            {
                              console.log('closing date picker' + selectedDate);
                              setDate(selectedDate);
                           setPickingDate(false);
                            }
                          
                           }}
                           
                         />
         : null}
       


<TouchableOpacity style={{flexDirection:'row',alignSelf:'flex-start',marginHorizontal:5,marginVertical:5}}  onPress={() => {

if(linkGrain)
{
   setLinkGrain(false)
}
else
{
  setLinkAll(false);
    setLinkGrain(true);
   setPickingGrain(true);
}

}}>
  <Text style={{alignSelf:'center',fontSize:22,flex:1}}>Share record(s) for specific grain(s)</Text>
  {linkGrain ?
                 <Icon style={{paddingRight:10}} name="check-square" size={40} color="black" />
             :
             <Icon style={{paddingRight:10}} name="square-o" size={40} color="black" />
             }

</TouchableOpacity>

{(linkGrain || linkAll) && date != null ?
         <View>
         <TouchableOpacity onPress={() => {setPickingDate(true);}} style={{borderWidth:3,borderColor:'lightgrey',flexDirection:'row',alignContent:'center',alignItems:'center',marginVertical:5,marginHorizontal:5,paddingVertical:5}} >
               
               <Icon style={{paddingLeft:10}} name="calendar" size={30} color="black" />
                <Text style={{flex:1,paddingLeft:10}}>{ moment(date).format('MMM Do YYYY').toString()}</Text>

         </TouchableOpacity>

            {
               props.enterprises.map(enterprise => {
                return (
                  <TouchableOpacity key={enterprise.id} style={{flexDirection:'row',alignSelf:'flex-start',marginHorizontal:5,marginVertical:5}}   onPress={() => {
                    let oldList = growerUsers;
                    if(growerUsers.find(x =>x.enterprise_id == enterprise.id).allow_completed_samples == 1)
                    {
                      console.log('removing');
                      const index = growerUsers.findIndex(x =>x.enterprise_id == enterprise.id)
                      let user = growerUsers.find(x =>x.enterprise_id == enterprise.id);
                      user.allow_completed_samples = 0;
                      user.allow_from_date = null;
                      oldList.splice(index,0,user);
                      setGrowerUsers([...oldList]);
                    }
                    else
                    {
                      console.log('Adding');
                      const index = growerUsers.findIndex(x =>x.enterprise_id == enterprise.id)
                      let user = growerUsers.find(x =>x.enterprise_id == enterprise.id);
                      user.allow_completed_samples = 1;
                      
                      oldList.splice(index,0,user);

                      setGrowerUsers([...oldList]);

                    }
                }}>
                         <Text style={{alignSelf:'center',fontSize:22,flex:1}}>{enterprise.name}</Text>
                         { growerUsers?.find(x => x.enterprise_id == enterprise.id)?.allow_completed_samples == 1 ?
                            <Icon style={{paddingRight:10}} name="check-square" size={40} color="black" /> 
                                                :
                          <Icon style={{paddingRight:10}} name="square-o" size={40} color="black" />
                          }
              
                    </TouchableOpacity>
                );
              })
            }
         </View>
    : null}
     

     </View>





{pickingGrain ? 
    <GrainTypePicker visible={pickingGrain} setVisible={setPickingGrain} grainList={grainList} userGrainList={selectedGrainList} setUserGrainList={setSelectedGrainList} />
: null}

</View>

              <VGButton role="PRIMARY" onPress={onSavePress}
              >Save</VGButton>
       
             
    <VGButton role="CANCEL" onPress={() => {
     props.setVisible(false);
    }}>Cancel</VGButton>
  
       
        </ScrollView>
        </Overlay>
        
    );
}



export default AllowSampleOverlay




