import * as React from 'react';

import { View, Text,TouchableOpacity, DeviceEventEmitter,Image, Alert, Linking, ScrollView } from 'react-native';

import { NavigationContainer, useIsFocused } from '@react-navigation/native';
import { DrawerActions } from '@react-navigation/native';
import Toast from 'react-native-tiny-toast';
import { Dimensions } from 'react-native';
import { GetData, GetOfflineStateParsed } from '../../Helpers/AsyncStorageHelper';
import CustomImage from '../HelperScreens/CustomImage';
import { Enterprise, ManualSamplingTypes, MasterSample, StorageType } from '../../Helpers/Storage/UserState';
import { GetEnterprises } from '../../Helpers/Functions/Offline/BuyerFunctions';
import { defaultBin, defaultBinLocation, defaultEnterprise, defaultField, defaultGrain } from '../../Helpers/Storage/DefaultStates';
import { useContext } from 'react';
import { learnMoreLookupData } from '../../Helpers/Storage/LearnMoreInfoLookup';
import Carousel from 'react-native-snap-carousel';
import * as FileSystem from 'expo-file-system';
import AppContext from '../../Contexts/AppContext';
import NetworkContext from '../../Contexts/NetworkContext';
import { GetLocalUserManual } from '../../Helpers/Functions/Online/UserFunctions';
import { GetSampleMetaData, GetSampleRecords } from '../../Helpers/Functions/Offline/SampleFunctions';
import { FullSync } from '../../Helpers/Functions/OfflineFunctions';
import Config from '../../config';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import styles from '../../Styles/styles';
import CustomAlert from '../HelperComponents/CustomAlert';


function MainActivityScreen({ navigation }: { navigation: any }, isLoggingOut: boolean) {

  const appContext = React.useContext(AppContext);
     const networkContext = useContext(NetworkContext);

    const [width,height] = [Dimensions.get('window').width,Dimensions.get('window').height];
    //the image that shows the main menu splash art
    const image = { uri: "../../assets/mainmenusplash.png" };
    const [pickSampleOption,setPickSampleOption] = React.useState(false);
    const [pickSampleRecordOption,setPickSampleRecordOption] = React.useState(false);
    const [pickIntelligenceOption,setPickIntelligenceOption] = React.useState(false);

    const [enterprises, setEnterprises] = React.useState<Enterprise[]>([])

    const [syncing, setSyncing] = React.useState(false);
    const [cachedEnterpriseImages,setCachedEnterpriseImages] = React.useState<{enterprise_id:number,fileUri:string}[]>([]);

    //listen to when the hamburger menu is tapped
     DeviceEventEmitter.addListener('OpenMenu',OpenDrawer);
function OpenDrawer(){
    //open the drawer
    navigation.dispatch(DrawerActions.openDrawer())
}
const isFocused = useIsFocused();

const isFocused2 = useIsFocused();
const [demoStatus,setDemoStatus] = React.useState<boolean>(false);
const [manualSampleTypes,setManualSampleTypes] = React.useState<ManualSamplingTypes[]>([]);



React.useEffect(() => {
    const sampleType = GetOfflineStateParsed('manual_sampling_types');
    if(sampleType != null)
    {
        setManualSampleTypes(sampleType);
    }
},[])


React.useEffect(() => {

    if(!isFocused)
    {
        setPickSampleOption(false);
        setPickSampleRecordOption(false);
        setPickIntelligenceOption(false);

    }
    else
    {
        const getPages = async() => {

            let data =await GetLocalUserManual();
            if(data != null)
            {
               appContext.setCurrentScreenName(data.find(x => x.screen_name == 'MainMenu'))
            }
            else
            {
               appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='MainMenu'));
      
            }
          };
          getPages(); 
    }

},[isFocused])


React.useEffect(() => {

    const getEnterpriseData = async () => {

        let enterpriseData = await GetEnterprises();
    
        if(enterpriseData != null)
        {
            
                let cachedImages:{enterprise_id:number,fileUri:string}[] = []

                for(let enterprise of enterpriseData)
                {
                    //const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;
        
                    const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
                 
                    const image = await FileSystem.getInfoAsync(path);

                    if(image.exists)
                    {
                        cachedImages.push({enterprise_id:enterprise.id,fileUri:image.uri});
                    }
           
   
                }
  
             setCachedEnterpriseImages(cachedImages);
            setEnterprises(enterpriseData);
        }
    };
    getEnterpriseData();

},[])




React.useEffect(() => {
  if (isFocused && !networkContext.isOffline && appContext.showSampleAssist < 0) {
      
    if(syncing || !isFocused)
    {
      return;
    }
    else
    {
      const syncData = async() => {
        if (syncing || !isFocused) return;
        setSyncing(true);
  
        console.log('Trying to sync.' );
        console.log('IS FOCUSED' + isFocused);
        const start = new Date();
       await FullSync()
            .then(() => Toast.show("Sync finished successfully!"))
            .catch((error) => {
                console.log("SYNC FAILED: " + error);
                Toast.show("Sync failed...")
                setSyncing(false);
            }
            )
            .finally(() => {
                console.debug('SYNC TOOK: ', (new Date()).getTime() - start.getTime(), 'ms to complete.');
                setSyncing(false);
            });
      }
      syncData();
    }

    //If we are online
   
  }
}, [isFocused, networkContext.isOffline,  appContext?.showSampleAssist]);

   return (
       <>

<CustomAlert title={ appContext.alertOptions.title} desc={ appContext.alertOptions.desc} displayAlert={appContext.showAlert} options={ appContext.alertOptions.options}   />

       {syncing ? <Spinner 
       visible={syncing}
        textContent={'Syncing Data...'}
        cancelable={false}
        textStyle={styles.spinner} /> : null}
    <ScrollView contentContainerStyle={{flexGrow:1,justifyContent:'space-evenly',marginHorizontal:10 }}> 

        <TouchableOpacity
        accessibilityLabel='Intelligence_Button'
            style={{backgroundColor:'orange', flex:1,marginVertical:5,flexDirection:'row',justifyContent:'center'}}
                onPress={() => {
                    pickIntelligenceOption ? setPickIntelligenceOption(false) :  setPickIntelligenceOption(true)
                }}
            >
                <Text style={{color:'white', alignSelf: 'center',fontSize:28}}>Intelligence</Text>
            </TouchableOpacity>

            {pickIntelligenceOption ?
            
            <View style={{alignContent:'stretch'}}>
         <View style={ {backgroundColor:'#1c448c',marginTop:5}}>
                <Text style={{color:'white', alignSelf: 'center',fontSize:28,textAlign:'center'}}>Select Option</Text>
                </View>


                <TouchableOpacity accessibilityLabel='Storage_Content_Button' style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {


//Toast.show('Coming Soon');
  navigation.navigate("StorageContentSearchScreen");

}}>
    <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Storage Contents Profile</Text>

</TouchableOpacity>

<View
style={{
borderBottomColor: 'orange',
borderBottomWidth: 3,
paddingTop:5
}}
/>
            
            {demoStatus == false ? 
            <>
                         <TouchableOpacity accessibilityLabel='Digital_Bin_Board_Button' style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {

navigation.navigate("YardSelection");
}}>
    <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Digital Bin Board</Text>

</TouchableOpacity>
            <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>
            </>
           
            : null}



{/* UNCOMMENT WHEN NEEDED */}
<TouchableOpacity style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {
               navigation.navigate("StorageSearch") 
            }}>
                <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Storage Search and Display</Text>

            </TouchableOpacity>

            <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>



            <TouchableOpacity style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {

setPickIntelligenceOption(false) 
}}>
    <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Close</Text>

</TouchableOpacity>
            </View>
            :  null}








        <TouchableOpacity
         accessibilityLabel='New_Sample_Button'
        onPress={() => {pickSampleOption ? setPickSampleOption(false) :  setPickSampleOption(true)}}
                            style={{backgroundColor:'orange', flex:1,marginVertical:5,flexDirection:'row',justifyContent:'center'}}
                            >
                <Text style={{color:'white', alignSelf: 'center',fontSize:28}}>New Sample</Text>
            </TouchableOpacity>

            {pickSampleOption ?
            
            <View style={{alignContent:'stretch'}}>
         <View style={ {backgroundColor:'#1c448c',marginTop:5}}>
                <Text style={{color:'white', alignSelf: 'center',fontSize:28,textAlign:'center'}}>Select Sampling Method</Text>
                </View>

                <TouchableOpacity
                accessibilityLabel='Sample_Setup_Button'
                style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {
                
                const getPages = async() => {
                                        
                    let data =await GetLocalUserManual();
                    if(data != null)
                    {
                       appContext.setCurrentScreenName(data.find(x => x.screen_name == 'NewSample'))
                    }
                    else
                    {
                       appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='NewSample'));
                    }
                  };
                  getPages(); 
                navigation.navigate("NewSample",{sampleOption:'manual'});
                setPickSampleOption(false);

            }}>
                <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Standard Sample Set-up</Text>

            </TouchableOpacity>


            <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>

                <TouchableOpacity
                accessibilityLabel='Rapid_Sample_Button'
                style={ {paddingTop:5, justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={async() => {

                  try
                  {
                    const userID = await GetData('ID');
                    const profile = await GetData('Profile');
                    const sampleData = await GetSampleRecords('Last',userID,'Primary',profile,null);
                
                    const metaData = await GetSampleMetaData([sampleData.masterSamples[0]]);
                  
                    if(sampleData.masterSamples.length > 0)
                    {
                        //Only want to grab the first sample
                        const sample:MasterSample = sampleData.masterSamples[0];
                
                        console.log('moving to rapid' + JSON.stringify(metaData));
                
                
                        navigation.navigate("NewSample",{sampleOption:'quick',latestSampleData:{lastSample:sample,
                          lastMasterSample: sampleData.masterSamples[0],
                            source: metaData.metaData.sampleSource,
                            destination: metaData.metaData.sampleDestination[0],
                            equipment:metaData.metaData.sampleEquipment[0],
                            grain: metaData.metaData.sampleGrain}});
                    }
                    else
                    {
                      appContext.setAlertOptions({title:'No Previous Sample Found',desc:'Create sample anyway?',options:[{text:'Create', role:'PRIMARY', onPress:() => {
                        navigation.navigate("NewSample",{sampleOption:'quick'});
                        appContext.setShowAlert(false);
                      }},{text:'Cancel', role:'CANCEL',onPress:() => {appContext.setShowAlert(false)}}]})
                      appContext.setShowAlert(true);
                    }
                    setPickSampleOption(false);
    
                  }
                  catch(error)
                  {
                    appContext.setAlertOptions({title:'No Previous Sample Found',desc:'Create sample anyway?',options:[{text:'Create',role:'PRIMARY', onPress:() => {
                      navigation.navigate("NewSample",{sampleOption:'quick'});
                      appContext.setShowAlert(false);
                    }},{text:'Cancel',role:'CANCEL', onPress:() => {appContext.setShowAlert(false)}}]})
                    appContext.setShowAlert(true);
                    setPickSampleOption(false);
                  }
                }}>
                <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Previous Sample Set-up</Text>

            </TouchableOpacity>
            <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>



   



<TouchableOpacity 
accessibilityLabel='Other_Sample_Source_Button'
style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {

const getPages = async() => {
                                        
    let data =await GetLocalUserManual();
    if(data != null)
    {
       appContext.setCurrentScreenName(data.find(x => x.screen_name == 'OtherSample'))
    }
    else
    {
       appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='OtherSample'));
    }
  };
  getPages(); 
                navigation.navigate("NewSample",{sampleOption:'other'});
                setPickSampleOption(false);

            }}>
                <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Other Sample Source</Text>

            </TouchableOpacity>


            <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>

<TouchableOpacity 

accessibilityLabel='Blend_Samples_Button'
style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {
   navigation.navigate("CombineSampleScreen");
   setPickSampleOption(false);
}}>
    <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Blend Samples</Text>

</TouchableOpacity>

<View
style={{
borderBottomColor: 'orange',
borderBottomWidth: 3,
paddingTop:5
}}
/>




            <TouchableOpacity style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {

setPickSampleOption(false) 
}}>
    <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Close</Text>

</TouchableOpacity>

            </View>
            :  null}




  
                    <TouchableOpacity
                    
accessibilityLabel='Sample_Records_Button'
                onPress={() => {
                    pickSampleRecordOption ? setPickSampleRecordOption(false) : setPickSampleRecordOption(true);
                }}

                style={{backgroundColor:'orange', flex:1,marginVertical:5,flexDirection:'row',justifyContent:'center'}}

            >
                
                <Text style={{color:'white', alignSelf: 'center',fontSize:28,textAlign:'center'}}>Sample Records</Text>
   

            </TouchableOpacity>

   
                 


            {pickSampleRecordOption ?
                  <View style={{alignContent:'stretch'}}>
         <View style={ {backgroundColor:'#1c448c',marginTop:5}}>
                        <Text style={{color:'white', alignSelf: 'center',fontSize:28,textAlign:'center'}}>Select Sample Source</Text>
                        </View>
        
                        <TouchableOpacity
                        accessibilityLabel='Completed_Samples_Button'
                onPress={() => {
                    navigation.navigate("MySamplesPicker",{isActive:false}) 
                }
            }
            style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}}
            >
                <Text style={{color:'black', alignSelf: 'center',fontSize:24}}>Completed Samples</Text>
            </TouchableOpacity>
        
            <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>

                    <TouchableOpacity
                    accessibilityLabel='Samples_In_Progress_Button'
                    style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={ () => {

                                   setPickSampleRecordOption(false);
                       navigation.navigate("SampleProgressSearchScreen");
             
        
                    }}>
                        <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Samples in Progress</Text>
        
                    </TouchableOpacity>


                    <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>

<TouchableOpacity style={ {paddingTop:5, justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {
                      navigation.navigate("SamplesPending") 
            }}>
                <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Analysis Pending</Text>

            </TouchableOpacity>
            <View
  style={{
    borderBottomColor: 'orange',
    borderBottomWidth: 3,
    paddingTop:5
  }}
/>

            <TouchableOpacity style={ { justifyContent:'flex-start', alignContent:'stretch',marginVertical:5}} onPress={() => {

setPickSampleRecordOption(false) 
}}>
    <Text style={{color:'black', alignSelf: 'center',fontSize:24,textAlign:'center'}}>Close</Text>

</TouchableOpacity>
                    </View>
 : null}


<>

{appContext.showSampleAssist < 0 ?
    <TouchableOpacity
                onPress={async () => {

                    const supported = await Linking.canOpenURL('https://verigrain.com/store');
  
                    const id = await GetData('ID');
                    if (supported) {
                      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
                      // by some browser in the mobile
                      await Linking.openURL('https://verigrain.com/store' );
                    } else {
                      appContext.setAlertOptions({title:'Cannot open website at this time',desc:'',options:[
                        {text:'OK',role:'PRIMARY',onPress:() => { appContext.setShowAlert(false);}},
                      ]});
                       appContext.setShowAlert(true);
                    }
                    
                }}
                style={{backgroundColor:'orange', flex:1,marginVertical:5,flexDirection:'row',justifyContent:'center'}}
                >
                <Text style={{color:'white', alignSelf: 'center',fontSize:28}}>Verigrain.com</Text>
            </TouchableOpacity>
: 
            <TouchableOpacity
                onPress={async () => {
                     appContext.setIsTimerHidden(false);
                }}
                style={{backgroundColor:'grey', flex:1,marginVertical:5,flexDirection:'row',justifyContent:'center'}}
                >
                <Text style={{color:'white', alignSelf: 'center',fontSize:28}}>Return to Sampler</Text>
            </TouchableOpacity>
}

</>




        {enterprises != null && enterprises.length > 0 && ! appContext.isTimerHidden ?



            <Carousel
            windowSize={1}
            vertical={true}
            enableSnap={true}
            enableMomentum={false}
            lockScrollWhileSnapping={true}
            loop={true}
            autoplayInterval={3000}
            autoplay={true}
            data={enterprises}
            firstItem={enterprises.length}
            useScrollView={true}
            initialScrollIndex={enterprises.length-3}
            scrollEnabled={false}
  
            renderItem={({item, index}) => {
                return (
                    <TouchableOpacity style={{flexDirection:'row',padding:10,justifyContent:'center',alignSelf:'center',borderWidth:3,borderColor:item.border_color??'black'}}
                    onPress={() => {
                        Linking.openURL(item.enterprise_url);
                        //redirectSubscription("207","SGilmour@verigrain.com");
                    }}
                    >

                        {cachedEnterpriseImages.find(x => x.enterprise_id == item.id) ?
                        
                            <Image
                            source={{uri: cachedEnterpriseImages.find(x => x.enterprise_id == item.id).fileUri}}
                            style={{ height: Math.round(height / 9), width: Math.round(width/1.15),resizeMode:'contain' }}
                            />                        
                        : 
                         <CustomImage  enterpriseID={item.id} width={Math.round(width/1.15)} height={ Math.round(height / 9)} />
            }

                    </TouchableOpacity> 
                );
            }}
            sliderWidth={Math.round(width/1.15)}
            itemWidth={Math.round(width/1.15)}
            sliderHeight={Math.round(height/9)}
            itemHeight={Math.round(height/5)}
            />
        : null}
            
       </ScrollView>

</>

   )
}

export default MainActivityScreen; 