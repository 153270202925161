import * as React from 'react';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, Image, TouchableOpacity, Dimensions, Alert } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome';
import { AddField, ValidateField } from '../../../Helpers/Functions/Offline/FieldFunctions';
import { Field } from '../../../Helpers/Storage/UserState';
import { Overlay } from 'react-native-elements';
import { setProp } from '../../../Helpers/GeneralTypes';
import { FormatErrorMessages, safeParseInt } from '../../../Helpers/Functions/GeneralFunctions';
import { defaultField } from '../../../Helpers/Storage/DefaultStates';
import { useContext } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import AppContext from '../../../Contexts/AppContext';
import VGButton from '../../../Components/Button/Button';
import spacing from '../../../Styles/Spacing';
import VGInput from '../../../Components/Input/Input';
import { RequiredText } from '../../../Components/SimpleComponents';
import textStyles from '../../../Styles/Text';
import FieldForm from '../../../Components/Forms/FieldForm';


function AddFieldScreen(props: { currentField: Field, setCurrentField: setProp<Field>, fields: Field[], setFields: setProp<Field[]>, isAddingField: boolean, setIsAddingField: setProp<boolean> }) {

    const appContext = React.useContext(AppContext);
    const [currentField,setCurrentField] = React.useState<Field>({...defaultField});

    React.useEffect(() => {
        setCurrentField({...defaultField});
    }, [])

    async function onSavePress(){

        try {
            const profile = await GetData('Profile');
            //Check if field is valid, if it is, add it
            let status = ValidateField(currentField);

            let returnField;
            if (status.length == 0) {
                returnField = await AddField(currentField, profile);

                //we dont want to call the api again, instead if it succeeded, just add the field to the list
                let oldFieldList = props.fields;
                //as long as the returned field is not null, then add to the list
                if (returnField != undefined) {
                    oldFieldList.push(returnField);
                    props.setFields(oldFieldList);
                    props.setCurrentField({...currentField});
                    setCurrentField({...defaultField});
                    props.setIsAddingField(false);
              //      currentContext.setCurrentScreenName({id:'FieldMenu'});


                }
                else {
                    Toast.show('Could not add field');
                }
            }
            else
            {
              const fullString =  FormatErrorMessages(status);
                appContext.setAlertOptions({title:'Invalid Field',desc:fullString,options:[
                    {text:'OK', role:'PRIMARY', onPress:() => {
                        appContext.setShowAlert(false);}}
                  ]});
                   appContext.setShowAlert(true);   
            }



        }
        catch (error) {

            appContext.setAlertOptions({title:'Could not add field',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);        }
        }

    

    return (
        <View style={spacing.MainContainer}>

            <View style={spacing.ScreenHeader}>
                <Text style={textStyles.title}>Add Field</Text>
            </View>
           
            <FieldForm currentField={currentField} setCurrentField={setCurrentField} />

            <VGButton role="PRIMARY" onPress={onSavePress}
                >Save</VGButton>

            <VGButton role="CANCEL" 
                onPress={() => {
                    setCurrentField({...defaultField});
                    props.setIsAddingField(false);
                }}>Close</VGButton>

        </View>
    );

            }
export default AddFieldScreen;