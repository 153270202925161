import { GetAsyncDataParsed, GetAsyncDataRaw, StoreAsyncData} from "../AsyncStorageHelper";
//import * as Sentry from 'sentry-expo';

/**
 * 
 * @param error Can be of type Error, String, Object, else it will just send whatever it is to sentry
 * @param level Is the severity, using Sentry.Native.Severity. Critical,Debug,Error,Fatal,Info,Log,Warning
 */
export async function logError(error, level: any) {
    //Setting the scope so we can se the error
    // Sentry.Native.withScope(function(scope){
    //     scope.setLevel(level);
    //     //If its an error send it, if its a string make a new error and send it
    //     if (error instanceof Error) {
    //         Sentry.Native.captureException(error);
    //     } else if (typeof error === 'string'){
    //         Sentry.Native.captureException(new Error(error))
    //     } else if (typeof error === 'object'){
    //         for(const err in error){
    //             console.log("Sending to Sentry:");
    //             console.log(JSON.stringify(error[err], null, 2));
    //             Sentry.Native.captureException(new Error(JSON.stringify(error[err], null, 2)));
    //         }
    //     } else { // If it's not one the types we will send a message so that we will know in the future
    //         scope.setLevel(Sentry.Native.Severity.Warning);
    //         Sentry.Native.captureException(new Error("Type of error not found line 33 OfflineSentry: " + error));
    //     }
    // });  
    //After the logs are sent they should be cleared
    await clearSentryErrors();
};

export async function storeError(error: NonNullable<any>){
    try{
        let data = [];
        let errors = await GetAsyncDataParsed<Array<object>>('SentryStorage'); // get the current errors
        if(errors){
            for(const obj in errors)
            {
                data.push(errors[obj]); // Loop through the current sentry storage data and add it to a new array
            }
        }
        data.push(error); // Add the newest error to the array
        await StoreAsyncData('SentryStorage', data); //Add it to and update the storage
    } catch (e) {
        console.log('There is an error with storeError: ' + e);
       // Sentry.Native.captureMessage('There is an error with storeError: ' + e);
    }
};

export async function uploadErrors () {
    let errors = await GetAsyncDataParsed<Array<object>>('SentryStorage');
    if(errors?.length > 0){
      //  await logError(errors, Sentry.Native.Severity.Error);
    } else {
        console.log("No errors to log");
    }
    //Use for displaying errors in testing
    // console.log("uploadErrors called, Errors returned were:");
    // console.log(errors);

}

export async function clearSentryErrors () {
    let data = [];
    await StoreAsyncData('SentryStorage', data);
    console.log("Sentry Storage Cleared!");
    let errors = await GetAsyncDataParsed<Array<object>>('SentryStorage'); // get the current errors
    console.log("Sentry Storage " + errors.length);
}