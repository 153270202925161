import * as React from 'react';
import { View, Dimensions} from 'react-native';
import { Overlay } from "react-native-elements";
import Spinner from 'react-native-loading-spinner-overlay/lib';
import VGButton from '../../Components/Button/Button';
//import Pdf from 'react-native-pdf';
 function LearnMoreOverlay(props: {pageNumber:number,isVisible:boolean,setIsVisible:any })
 {

const source = require('../../documents/app_guide.pdf');
const onlineSource = {uri:"https://webservice.verigrain.com/documents/app_guide.pdf"};
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height
    const [doneLoading,setDoneLoading] = React.useState(false);
    return (
        <Overlay isVisible={props.isVisible}>

        
        <View style={{width:width,height:height}}>


     {/* <Pdf
      trustAllCerts={false}
      page={props.pageNumber}
                    source={onlineSource}
                    onLoadComplete={(numberOfPages,filePath) => {
                        console.log(`Number of pages: ${numberOfPages}`);
                    }}
                    onPageChanged={(page,numberOfPages) => {
                        console.log(`Current page: ${page}`);
                    }}
                    onError={(error) => {
                        console.log(error);
                    }}
                    onPressLink={(uri) => {
                        console.log(`Link pressed: ${uri}`);
                    }}
                    style={{flex:1,width:width/1.15,height:height/1.15}}
                    /> */}
              <VGButton role="CANCEL" onPress={() => {
                props.setIsVisible(false);
                }}>Close</VGButton>
        </View>
        </Overlay>
    );
 }
 export default LearnMoreOverlay