import React from "react";
// import { View, Text } from "react-native";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { CalculateGrainFlow, DeleteEquipment, GetMasterEquipmentData, SaveEquipment, UpdateEquipment, ValidateEquipment } from "../../../Helpers/Functions/Offline/EquipmentFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { defaultBinModel, defaultUserEquipment, defaultEquipmentBrand, defaultEquipmentModel } from "../../../Helpers/Storage/DefaultStates";
import {
  UserEquipment,
  EquipmentBrand,
  EquipmentModel,
} from "../../../Helpers/Storage/UserState";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import { View, Text, TextInput, TouchableOpacity, Alert, Image, ScrollView } from 'react-native';
import RequestEquipmentModel from "./RequestEquipmentModel";
import Icon from "react-native-vector-icons/FontAwesome";
import { FormatErrorMessages, ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import spacing from "../../../Styles/Spacing";
import VGInput from "../../../Components/Input/Input";
import VGButton from "../../../Components/Button/Button";
import textStyles from "../../../Styles/Text";
import AppContext from "../../../Contexts/AppContext";

function AddEquipmentScreenUpdated(props: {
  equipmentList: UserEquipment[];
  setEquipmentList: setProp<UserEquipment[]>;
  setStateSelection: any;
  currentEquipment?:UserEquipment;
  setCurrentEquipment?:setProp<UserEquipment>;
}) {
    enum stateSelections {
        default,
        addingEquipment,
        updatingEquipment,
        restoringEquipment,
    }
    enum addEquipmentStatus {
        SelectingMake,
        SelectingModel,
        Default,
        SelectingEquipmentType,
        SelectingSEM
    }
const appContext = React.useContext(AppContext);
    const [currentStatus, setCurrentStatus] = React.useState<addEquipmentStatus>(addEquipmentStatus.Default);
    const [currentEquipment, setCurrentEquipment] = React.useState<UserEquipment>(props.currentEquipment??{...defaultUserEquipment});
    const [currentMake, setCurrentMake] = React.useState<EquipmentBrand>({...defaultEquipmentBrand});
    const [currentModel, setCurrentModel] = React.useState<EquipmentModel>({...defaultEquipmentModel});

    const [equipmentMakeList, setEquipmentMakeList] = React.useState<EquipmentBrand[]>([]);
    const [equipmentModelList, setEquipmentModelList] = React.useState<EquipmentModel[]>([]);
    const [mode, setMode] = React.useState<'add' | 'view'>('view');

    const [equipmentTypes,setEquipmentTypes] = React.useState<{name:string}[]>([{name:'Auger'}, {name:'Conveyor'}]);

    const [selectedEquipmentType, setSelectedEquipmentType] = React.useState<{name:string}>({name:''});
    const [semEquipment,setSemEquipment] = React.useState<{name:string}[]>([{name:'10 inch'},{name:'13 inch'},{name:'16 inch'}]);
    const [selectedSEM,setSelectedSEM] = React.useState<{name:string}>({name:''});
    
    const [isUpdating,setIsUpdating] = React.useState<boolean>(false);

    const [calculatedBushelLoad,setCalculatedBushelLoad] = React.useState<number>(0);

    const [manualBushel,setManualBushel] = React.useState(true);

        //REFS
        const bushelRatingRef = React.useRef(null);
        const nameRef = React.useRef(null);
        const truckLoadRef = React.useRef(null);
        const unloadMinRef = React.useRef(null);
        const unloadSecRef = React.useRef(null);


    React.useEffect(() => {

        //If equipment ID is valid (it was created) then we want to update this particular equipment rather than adding
        if(ValidID(currentEquipment.id))
        {
            setIsUpdating(true);
            //TODO - Autopopulate fields with data, fill in SEM DAta, since it uses a seperate object, also auto populate make and model if available

            if(currentEquipment?.equipment_model_id != null && ValidID(currentEquipment?.equipment_model_id))
            {
                const equipmentModel = equipmentModelList.find(x => x.id == currentEquipment?.equipment_model_id);
                if(equipmentModel != null)
                {
                    const equipmentBrand = equipmentMakeList.find(x => x.id == equipmentModel.brand_id);
                    setCurrentMake({...equipmentBrand});

                    setCurrentModel({...equipmentModel});
                }
            }

            setSelectedSEM({name: currentEquipment?.sem??''});


        }
        else
        {
            setIsUpdating(false);
        }
    },[currentEquipment.id,equipmentModelList,equipmentMakeList])


    React.useEffect(() => {

        if(selectedEquipmentType.name != '')
        {
            //setCurrentEquipment({ ...currentEquipment, : text });
        }
    },[selectedEquipmentType])



  React.useEffect(() => {
    const grabEquipmentDetails = async () => {
        let data = await GetMasterEquipmentData();

        if(data != null)
        {
            setEquipmentMakeList(data.equipmentMakeList);

        data.equipmentModelList.unshift({id:-2,name:'Request Equipment',brand_id:-2,bushel_loading:0,profile:'',equipment_type:'',updated_at:null})
            setEquipmentModelList(data.equipmentModelList);
        }
    };

    grabEquipmentDetails();


  }, []);

  React.useEffect(() => {

        //TODO - If ID equals -2, open the request equipment modal screen
    if(ValidID(currentModel.id))
    {
        //TODO - Deal with auto-populating name if name has not already been set, and set unique name, if 
        //model was already used
        if(!isUpdating)
        {
            const brand = equipmentMakeList.find(x => x.id == currentModel.brand_id)?.name??'#';

            let modelCount = props.equipmentList.filter(x => x.id == currentModel.id)?.length??0;
            modelCount++;

            setCurrentEquipment({ ...currentEquipment, bushels_hr_rate: currentModel.bushel_loading,name:brand + '_' + currentModel.name + '_' + modelCount,equipment_model_id:currentModel.id});
        }
        else
        {
            //Model is valid auto-populate fields
            setCurrentEquipment({ ...currentEquipment, bushels_hr_rate: currentModel.bushel_loading,equipment_model_id:currentModel.id});
        }

        


    } else if(currentModel.name == 'Request Equipment')
    {
        setMode('add');
    }
    

  },[currentModel])

  React.useEffect(() => {

    if(selectedSEM != null && selectedSEM.name != '')
    {
        setCurrentEquipment({...currentEquipment, sem:selectedSEM.name});
    }
},[selectedSEM])


    React.useEffect(() => {
        if(currentEquipment.truck_load != 0 && currentEquipment.unload_min != 0 && currentEquipment.unload_sec != 0)
        {
            const bushelResult = CalculateGrainFlow(currentEquipment.truck_load,currentEquipment.unload_min,currentEquipment.unload_sec);
            console.log(bushelResult);

            if(!isNaN(bushelResult))
            {
                setCurrentEquipment({...currentEquipment, bushels_hr_rate:bushelResult});
            }
        }
    },[currentEquipment.truck_load,currentEquipment.unload_min,currentEquipment.unload_sec])
 // console.log("Current Status: ", currentStatus);
 // console.log(currentMake);
//   console.log("Current Status: ", addEquipmentStatus.SelectingMake);
  const onSelectMake = (item: EquipmentBrand) => {
    setCurrentMake({...item});
    setCurrentModel({...defaultEquipmentModel});
  };

  async function onSavePress(){

    const validateEquip = ValidateEquipment(currentEquipment);

if(validateEquip.length == 0)
{
    if(isUpdating)
    {
          const updateEquipmentStatus = await UpdateEquipment(currentEquipment);

          if(updateEquipmentStatus != null && updateEquipmentStatus.response == 0)
          {
              console.log('Sucessfully updated User Equipment');

              let oldUserEquipmentList = props.equipmentList;
              const index = oldUserEquipmentList.findIndex(x => x.id == currentEquipment.id);
              //Find and replace the old item with the new equipment (only really affects the list if the name has changed. )
              oldUserEquipmentList.splice(index,1,currentEquipment);
              props.setEquipmentList([...oldUserEquipmentList]);
              props.setStateSelection(0);
          }
    }
    else
    {
      const profile = await GetData('Profile');
      let curEquip = currentEquipment;
      curEquip.profile = profile;
      if(manualBushel)
      {
          curEquip.truck_load = null;
          curEquip.unload_min = null;
          curEquip.unload_sec = null;
      }
   
  
        const equipmentStatus = await SaveEquipment(curEquip)

        if(equipmentStatus != null && ValidID(equipmentStatus.id))
        {
        
            if(props?.currentEquipment != null)
            {
                props?.setCurrentEquipment({...equipmentStatus});
            }
                let oldUserEquipmentList = props.equipmentList;
                oldUserEquipmentList.push(currentEquipment);
                props.setEquipmentList([...oldUserEquipmentList]);
                props.setStateSelection(0);
        }

    

    }
}
else
{

      const fullString =  FormatErrorMessages(validateEquip);
      appContext.setAlertOptions({title:'Invalid Field',desc:fullString,options:[
          {text:'OK', role:'PRIMARY', onPress:() => {
              appContext.setShowAlert(false);}}
        ]});
         appContext.setShowAlert(true);   
    
}

  }

  function OnDeletePress()
  {
    appContext.setAlertOptions({title:'Are you sure you want to delete this equipment?',desc:'Deleted information will be retained for 6 months',options:[
        {text:'Delete', role:'DESTRUCTIVE', onPress:() => {
            try {
                appContext.setShowAlert(false);
                let fieldStatus = DeleteEquipment(currentEquipment);
                let equipList = props.equipmentList;
                const index = equipList.findIndex(x => x.id == fieldStatus.id);
                if(index != -1)
                {
                    equipList[index] = fieldStatus;
                    props.setEquipmentList([...equipList]);
                    props.setStateSelection(0);
                }
              
            }
            catch (error) {
                console.log(error);
         
                appContext.setAlertOptions({title:'Could not delete field',desc:'Please try again at a later time',options:[{text:'OK', role:'PRIMARY', onPress:()=>{appContext.setShowAlert(false);}}]})
            }
            appContext.setShowAlert(false);}},
        {text:'Cancel', role:'CANCEL', onPress:()=>{appContext.setShowAlert(false);}}
      ]});
       appContext.setShowAlert(true); 
  }

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1}}>
        {currentStatus === addEquipmentStatus.SelectingMake ? <OverlayPicker type={['name']} text={'Pick a Make'} isVisible={currentStatus == addEquipmentStatus.SelectingMake} overrideExit={addEquipmentStatus.Default} setVisible={setCurrentStatus} setData={onSelectMake} data={equipmentMakeList} /> 
        : null}
        {currentStatus === addEquipmentStatus.SelectingModel ? <OverlayPicker type={['name']} text={'Pick a Model'} isVisible={currentStatus == addEquipmentStatus.SelectingModel} overrideExit={addEquipmentStatus.Default} setVisible={setCurrentStatus} setData={setCurrentModel} data={equipmentModelList?.filter(x => x.brand_id == currentMake.id || x.id == -2)} /> 
        : null}
           {currentStatus == addEquipmentStatus.SelectingSEM ? <OverlayPicker type={['name']} text={'Pick Sample Module'} isVisible={currentStatus == addEquipmentStatus.SelectingSEM} overrideExit={addEquipmentStatus.Default} setVisible={setCurrentStatus} setData={setSelectedSEM} data={semEquipment} />
 : null}

        {mode === 'view' ? (
            <View style={spacing.MainContainer}>
                
                  <View style={spacing.ScreenHeader}>
                   <Text style={textStyles.title}>{isUpdating ?'Update Equipment' : 'Add Equipment'}</Text>
               </View>

                  
          

          <View style={{flex:1} }>

            <VGInput label="Make" type="picker"
                onPress={()=>setCurrentStatus(addEquipmentStatus.SelectingMake)}
                value={currentMake?.name??''}
            />
           
           {currentMake != null && currentMake?.id > 0  ?
            <VGInput label="Model" type="picker" 
                onPress={()=>setCurrentStatus(addEquipmentStatus.SelectingModel)}
                value={currentModel.name == 'Request Equipment' ? '' : currentModel.name}
            />
            :null}

                

                <VGInput label="Name"
                    value={currentEquipment.name}
                    onChangeText={(text) => {
                        setCurrentEquipment({ ...currentEquipment, name: text });
                    }}
                />
            


            <View>
                <View style={{ flexDirection:'row',paddingBottom:20 }}>
                    <TouchableOpacity
                        onPress={() => {
                            setManualBushel(true);
                        }}
                    style={{ flexDirection:'row' ,alignContent:'center',alignItems:'center',marginHorizontal:10}}>
                    <Icon style={{}} name={ manualBushel  ? "circle" :"circle-o"} size={30} color={manualBushel ? 'orange':"lightgrey"} />
                    <Text style={{ fontSize:18,paddingLeft:5 }}>Mfr Rating</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                    onPress={() => {
                        setManualBushel(false);
                    }}
                    style={{ flexDirection:'row' ,alignContent:'center',alignItems:'center',marginHorizontal:10}}>
                    <Icon style={{}} name={ !manualBushel  ? "circle" :"circle-o"} size={30} color={manualBushel ? 'lightgrey':"orange"} />
                    <Text style={{ fontSize:18,paddingLeft:5  }}>Calculate</Text>
                    </TouchableOpacity>
                </View>

                {manualBushel ?
                    <VGInput label="Bushel Rating" required 
                        keyboardType="numeric"
                        onChangeText={(text) => {
                            if(isNaN(parseInt(text)) || text == ''){
                                setCurrentEquipment({ ...currentEquipment, bushels_hr_rate: 0 });
                            }
                            else{
                                setCurrentEquipment({ ...currentEquipment, bushels_hr_rate: parseInt(text) });
                            }    
                        }}
                        value={currentEquipment.bushels_hr_rate == 0 ? '' : currentEquipment.bushels_hr_rate.toString()}
                    />
                : null}

                {!manualBushel ?
                    <View style={{ flex:1 }}>
                        <VGInput label="Truck Load" required keyboardType="numeric" 
                            value={currentEquipment?.truck_load == 0 || currentEquipment?.truck_load == null ? '' : currentEquipment.truck_load.toString()??''}
                            onChangeText={(text) => {
                                if(isNaN(parseInt(text)) || text == ''){
                                    setCurrentEquipment({ ...currentEquipment, truck_load: 0 });
                                }
                                else{
                                    setCurrentEquipment({ ...currentEquipment, truck_load:  parseInt(text) });
                                }
                            }}
                        />
                        
                        <View style={{ flexDirection:'row',justifyContent:'space-evenly',flex:1,marginVertical:5 }}>
                            <View style={{flex:1}}>
                                <VGInput label="Min" required keyboardType="numeric"
                                    value={currentEquipment?.unload_min == 0 || currentEquipment?.unload_min == null ? '' : currentEquipment.unload_min.toString()??''}
                                    onChangeText={(text) => {
                                        if(isNaN(parseInt(text)) || text == ''){
                                            setCurrentEquipment({ ...currentEquipment, unload_min: 0 });
                                        }
                                        else{
                                            setCurrentEquipment({ ...currentEquipment, unload_min: parseInt(text) });
                                        }                 
                                    }}
                                />
                            </View>
                        
                            <View style={{ alignSelf:'center',marginHorizontal:5 }}><Text style={{ fontSize:16 }}>:</Text></View>

                            <View style={{flex:1}}>
                                <VGInput label="Sec" required keyboardType="numeric"
                                    value={currentEquipment?.unload_sec == 0 || currentEquipment?.unload_sec == null ? '' : currentEquipment.unload_sec.toString()??''}
                                    onChangeText={(text) => {
                                        if(isNaN(parseInt(text)) || text == ''){
                                            setCurrentEquipment({ ...currentEquipment, unload_sec: 0 });
                                        }
                                        else{
                                            setCurrentEquipment({ ...currentEquipment, unload_sec: parseInt(text) });
                                        }                    
                                    }}
                                />
                            </View>
                           
                        </View>


                        <View style={{  flexDirection: 'row',marginVertical:5,flex:1, alignContent: 'center', alignSelf: 'center', alignItems: 'center',borderWidth:3,borderColor:'lightgrey',paddingVertical:5  }}>
                            <Text style={{ flex:1, color:'lightgrey' ,paddingVertical:5}}>Bushel Rating</Text>
                            <Text style={{paddingRight:5,color:'lightgrey' }}>{currentEquipment.bushels_hr_rate}</Text>
                        </View>

        
                    </View>
                : null}


            </View>

            <VGInput label="SEM" type="picker" 
                onPress={()=>setCurrentStatus(addEquipmentStatus.SelectingSEM)}
                value={selectedSEM?.name??''}
            />


 
            </View>

                <VGButton role="PRIMARY" onPress={onSavePress} >
                {isUpdating ?'Update' : 'Save'}
                </VGButton>

                {isUpdating ?
                     <VGButton role="DESTRUCTIVE" onPress={OnDeletePress} >Delete</VGButton>
                 : null}

                <VGButton role="CANCEL" onPress={() => {
                      props.setStateSelection(0);
                }}
                >Close</VGButton>
           
            </View>
       ) : (
        <RequestEquipmentModel equipmentBrandList={equipmentMakeList} setEquipmentModelList={setEquipmentModelList} equipmentModelList ={equipmentModelList} equipmentBrand={currentMake} equipmentModel ={currentModel} setEquipmentModel ={setCurrentModel} setMode={setMode} />
       )
    
    }

        
    </ScrollView>
  );
}
export default AddEquipmentScreenUpdated;
