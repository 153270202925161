import React from "react";
import { View, Text, Image, Dimensions } from "react-native";
import { Overlay } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";

import { Bin, BinLocation, BinVolumeHistory, CharacteristicSubOption, CharacteristicType, SampleRecordCharacteristic, SampleGrainVariety, StorageType, Lab, Buyer, Enterprise, SampleSource, SampleDestination, MasterSample, UserInstrumentModel, GrainType, GrainVariety } from "../../../Helpers/Storage/UserState";

import * as Progress from 'react-native-progress';
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { GetLocations } from "../../../Helpers/Functions/Offline/BinFunctions";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import { useIsFocused } from "@react-navigation/native";
import { GetStorageData } from "../../../Helpers/Functions/Offline/StorageFunctions";
import spacing from "../../../Styles/Spacing";
import VGInput from "../../../Components/Input/Input";


function StorageSearchScreen({ route, navigation }: { route: any, navigation: any }) {
    var width = Dimensions.get('window').width; //full width
    const isFocused = useIsFocused();
    const [locations, setLocations] = React.useState<BinLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = React.useState<BinLocation>();

    const [GrainTypeList, setGrainTypeList] = React.useState<GrainType[]>([]);
    const [selectedGrainType, setSelectedGrainType] = React.useState<GrainType>()
    //How the storage data is constructed, a location has multiple bins
    //Each Bin can hold an array of samples
    //Each Sample can hold an array of characteristics and GrainTypes
    const [storageData, setStorageData] = React.useState<{ location: BinLocation, bins: { bin: Bin, binVolumeHistory: BinVolumeHistory[], samples: { sample: MasterSample, characteristics: SampleRecordCharacteristic[], GrainTypes: SampleGrainVariety[] }[] }[] }[]>();

    //Grabbing all data will move some of that into it's own lookup object, should make it easier when in offline mode
    const [GrainTypes, setGrainTypes] = React.useState<{ GrainTypeTypes: GrainType[], GrainTypeVarieties: GrainVariety[] }>();
    const [characteristics, setCharacteristics] = React.useState<{
        charTypes: CharacteristicType[];
        charSubOptions: CharacteristicSubOption[];
    }>();

    const [characteristicSourceLookup, setCharacteristicSourceLookup] = React.useState<{ sourceID: number, sourceType: string, name: string }[]>([]);
    const [pickingLocation, setPickingLocation] = React.useState(false);

    const [pickingGrainType, setPickingGrainType] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    
    type LookupData = {
        storageTypes:StorageType[],
        userInstruments:UserInstrumentModel[],
        labs:Lab[],
        buyers:Buyer[],
        GrainTypeTypes:GrainType[],
        GrainTypeVarieties:GrainVariety[],
        characteristicTypes:CharacteristicType[],
        characterSubOptions:CharacteristicSubOption[],
        enterprises:Enterprise[]
    }
    
    type Storage = {
        binLocations:BinLocation[],
        bins:Bin[],
        samples:MasterSample[],
        binVolumeHistory:BinVolumeHistory[],
        sampleSource:SampleSource[],
        sampleDestination:SampleDestination[],
        sampleCharacteristics:SampleRecordCharacteristic[],
        sampleGrainTypes:SampleGrainVariety[]
    }

    React.useEffect(() => {
        if (!isFocused) {
            setSelectedLocation(null);
            setSelectedGrainType(null);
        }

    }, [isFocused])
    //This useEffect will grab all locations
    React.useEffect(() => {

        const GrabBinData = async () => {

            try {
                const profile = await GetData('Profile');
                let locations = await GetLocations();

                //If it succeeded in grabbing locations
                if (locations != null) {
                    //set locations
                    setLocations(locations.filter(x => x.expired == 0));
                }

            }
            catch {

            }
        }
        GrabBinData();
    }, [])



    //This Useeffect is called whenever a location is selected 
    React.useEffect(() => {

        //If the location is not null and its not -1
        if (selectedLocation && selectedLocation.id != -1) {
            try {
                    //Load the spinner
                    setIsLoading(true);

                    console.log('Starting!!!');
                    //Grab storage data, based on location
                    const storage = GetStorageData('Location', selectedLocation.id, null);

                    navigation.navigate('StorageDisplay',{storageData:storage});
                    setIsLoading(false);

            }
            catch
            {
                setIsLoading(false);
            }

        }
    }, [selectedLocation])


    //This useeffect does the exact same as the one above but uses the GrainType parameter instead
    React.useEffect(() => {

        if (selectedGrainType && selectedGrainType != undefined && selectedGrainType.id != -1) {
            try {
                console.log('Starting! GrainType ID: ' + selectedGrainType.id)
                const getStorage = async () => {
                    setIsLoading(true);
                    

                    const storage = GetStorageData('Grain', selectedGrainType.id, null);
                    navigation.navigate('StorageDisplay',{storageData:storage});
                    setIsLoading(false);
                };
                getStorage();
            }
            catch
            {
                setIsLoading(false);
            }

        }
    }, [selectedGrainType])

    //This is called when ever the storage data object is filled
    React.useEffect(() => {
        if (storageData) {


            //Move to the storage display screen with the storage data and the master list GrainTypes and characteristics
          //  navigation.navigate('StorageDisplay', { storageData: storageData, GrainTypes: GrainTypes, characteristics: characteristics, characteristicSourceLookup: characteristicSourceLookup });
        }
    }, [storageData])

    return (
        <View style={{ flex: 1 }}>

            {isLoading ?
                <Overlay overlayStyle={{ backgroundColor: 'rgba(0,0,0,0)', shadowColor: 'rgba(0,0,0,0)' }} isVisible={isLoading} >
                    <Progress.CircleSnail size={width / 1.75} color={'orange'} thickness={10} />
                    <Text style={{ alignSelf: 'center', color: 'white', fontSize: 22 }}>Fetching Data...</Text>
                </Overlay>

                : null}

            <OverlayPicker type={['location']} text={'Pick a Location'} isVisible={pickingLocation} setVisible={setPickingLocation} setData={setSelectedLocation} data={locations} />

            <OverlayPicker type={['name']} text={'Pick a GrainType Type'} isVisible={pickingGrainType} setVisible={setPickingGrainType} setData={setSelectedGrainType} data={GrainTypeList} />

            <View style={spacing.MainContainer}>
                
                <VGInput label="Storage Location" type="picker"
                    onPress={()=>setPickingLocation(true)}
                    value={selectedLocation ? selectedLocation.location : ''}
                />

                <VGInput label="Grain" type="picker" 
                    onPress={()=>setPickingGrainType(true)}
                    value={selectedGrainType ? selectedGrainType.name : ''}
                />

            </View>

        </View>
    );
}
export default StorageSearchScreen