
//This function will check if a grain-lab combo is valid, if so return the lab name and grain name

import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";
import { Linking } from "react-native";
import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { ApiResponse } from "../../GeneralTypes";
import { GrainType, Lab, PendingAnalysis } from "../../Storage/UserState";
import { ApiCall } from "../GeneralFunctions";

//used in the store URL, else return null
export function CheckValidGrain(labID: number, grainID: number):{lab:Lab,grainType:GrainType}|null {


    const labGrainLookup = GetOfflineStateParsed('lab_grain_lookup');
    
    const index = labGrainLookup.findIndex(x => x.lab_id == labID && x.grain_type_id == grainID);

        if(index != -1)
        {
            const lab = GetOfflineStateParsed('labs').find(x => x.id == labID);
            const grainType = GetOfflineStateParsed('grain_types').find(x => x.id == grainID);

            if(lab != null && grainType != null)
            {
                return {lab:lab,grainType:grainType};
            }
      
        }
        return null;
}


export async function UpdateRecordStatus(analysisRecords: { sampleID: number, barcode: number, grain: string, value: string, type: string, received: number, purchaseID: number }[] | undefined) {

    let IDString = '';
    let receiveString = '';
    let typeString = '';
    //loop through each record and add the receive boolean and ID
    analysisRecords?.forEach(x => {
        IDString += x.purchaseID + ',';
        receiveString += x.received + ',';
        typeString += x.type + ',';
    })

    const link = "/api/update-record-status";
    const formData = new FormData();

    formData.append('IDList', IDString);

    formData.append('ReceiveList', receiveString);
    formData.append('TypeList', typeString);

    const sampleData = await ApiCall<ApiResponse>(link, formData);

    if (sampleData.response == 0) {
        return true;
    }
    else {
        return false;
    }

}


export async function GetAllAnalysisRecords(profile: string) {
    let sampleAnalysisRecord: PendingAnalysis[] = [];

    sampleAnalysisRecord = (await GetOfflineStateParsed("pending_analysis")).filter(x => x.profile === profile);

    if (sampleAnalysisRecord != null) {
        return sampleAnalysisRecord;
    }
    else {
        console.log('Could not find any analysis records');

        return null;
    }
}


export function RedirectToStore(selectedLab: Lab, grain_name: string, lab_name: string, sample_id: string, barcode: string, user_id: string, profile: string, moment_date: number) {
    const key = 'pyFHc9k3r3hlsCPEF8V4IQSt5k320vpX';

    const token = JWT.encode({
        redirectLink: selectedLab.redirect_link + '-' + grain_name,
        sampleID: sample_id.toString(),
        barcode: barcode,
        userID: user_id,
        lab: lab_name,
        labID: selectedLab.id,
        profile: profile,
        sampleType: 'Primary',
        expireDate: moment_date
    }, key, { algorithm: SupportedAlgorithms.HS256 })

    //  create a url and send user to website

    const url = 'https://verigrain.com/?token=' + token;

    console.log(url);
    //open the store

    return Linking.openURL(url);
}