
import { BarCodeScanningResult, Camera, CameraType } from 'expo-camera';
import React from 'react';
import { Dimensions, View,Text,TouchableOpacity,StyleSheet, Alert, Linking, TextInput, Platform, KeyboardAvoidingView } from 'react-native';
import { Overlay } from 'react-native-elements';
import VGInput from '../../Components/Input/Input';
import spacing from '../../Styles/Spacing';
import VGButton from '../../Components/Button/Button';
import AppContext from '../../Contexts/AppContext';
import { setProp } from '../../Helpers/GeneralTypes';
import { Bin } from '../../Helpers/Storage/UserState';
import Colours from '../../Styles/Colours';




function BarcodeReader(props: { onBarcodeRead:(value:string)=>void, setPickingData: (value:boolean)=>void,isNumeric:boolean}) {

    const appContext = React.useContext(AppContext);
    const [scanned, setScanned] = React.useState(false);
    const [scanResult,setScanResult] = React.useState<BarCodeScanningResult>(null);
    const [manualEntry, setManualEntry] = React.useState<string>("");
    const [manualFocused, setManualFocused] = React.useState<boolean>(false)
    
    let _camera = React.useRef<Camera>(null);

    React.useEffect(() => {
     
        const getBarcodePermissions = async() => {
            const { status } = await Camera.requestCameraPermissionsAsync();

            if(status == 'denied')
            {
                appContext.setAlertOptions({title:'Camera Access Denied',desc:'Please enable camera access in the app settings',options:[
                    {text:'Go to settings',role:'PRIMARY',onPress:() => {
                        Linking.openSettings();
                        appContext.setShowAlert(false);
                      }},
                    {text:'CANCEL',role:'CANCEL',onPress:()=>{appContext.setShowAlert(false);}}
                  ]});
                   appContext.setShowAlert(true); 
             
            }
        }
        getBarcodePermissions();
        
      },[]);

    React.useEffect(()=>{
        if(_camera != null && _camera.current != null)
        {
            _camera.current.pausePreview();

        }
    }, [])

 
    function onBarcodeManualPress(){
        console.log('On Barcode Manual Press');
        if(manualEntry.length > 0){
            props.onBarcodeRead(manualEntry);
            props.setPickingData(false);
        }
    }

    function onManualFocus(){
        
        console.log('On Manual Focus');
        setManualFocused(true)
    
       
    }

    function onManualBlur(){
        
        console.log('On Manual Blur');
        setManualFocused(false)
       
    }


    return(
        
        <Overlay isVisible={true} onBackdropPress={()=>props.setPickingData(false)} >
             
            <View style={spacing.Modal}>
                {!manualFocused ?
                <Camera
                    ref={_camera}
                    type={CameraType.back}
                    onBarCodeScanned={(scanningResult:BarCodeScanningResult) => {
                        
                        try
                        {
                            console.log('Scanned: ' + scanningResult.data);
                            props.onBarcodeRead(scanningResult.data);
                            props.setPickingData(false);
                            
                        }
                        catch(error)
                        {
                            console.log('BARCODE ERROR: ' + error);
                            props.setPickingData(false);
                         
                            appContext.setAlertOptions({title:'Error',desc:'Sorry, there was a problem scanning the barcode',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
                            appContext.setShowAlert(true);
                        }
                        
                    }}
                    
                    style={[{flex:1}, spacing.Element]}


                />
                :<View style={[{flex:1, backgroundColor:Colours.BLACK, justifyContent:"center"}, spacing.Element]}><Text style={{color:Colours.WHITE, alignSelf:'center'}}>Camera Disabled While Typing</Text></View>
                 }
                
                <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding':'padding'}>
                <View style={spacing.Bottom}>
                    <View style={{flexDirection:"row"}}>
                        <View style={{flex:1}}>
                            <VGInput label="Manual Input" keyboardType={props.isNumeric ? 'numeric':'default'}
                                onChangeText={setManualEntry} onFocus={onManualFocus} onBlur={onManualBlur}
                            />
                        </View>

                        <View style={spacing.HorizontalSpacer}></View>

                        <VGButton  role="SECONDARY" onPress={onBarcodeManualPress} 
                        >Enter</VGButton>
                    </View>
                    <VGButton role="CANCEL" onPress={() => { props.setPickingData(false) }}
                    >Close</VGButton>
                </View>
                </KeyboardAvoidingView>
            </View>
     
        </Overlay>

    );
}
export default BarcodeReader;
