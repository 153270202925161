import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { GrowerUser, UserInfo } from "../../Storage/UserState";

export async function GetGrowerDetails(id: string): Promise<GrowerUser> {
   

    const grower = (await GetOfflineStateParsed("grower_users")).find(x => x.profile === id);

    if (grower != null) {
        return grower;
    }

    return null
}

export async function GetAllUsers():Promise<UserInfo[]>
{
    const users = (await GetOfflineStateParsed("users"))

    console.log('usersL ' + JSON.stringify(users));
    if (users != null) {
        return users;
    }

    return null
}

export async function GetGrowerList():Promise<GrowerUser[]>
{
    const grower = (await GetOfflineStateParsed("grower_users"));

    if (grower != null) {
        return grower;
    }

    return null
}

export async function UpdateGrowerUser(oldUser: GrowerUser[]): Promise<GrowerUser[]> {
        const success = await AddToQueue("grower_users", oldUser, "update");
        return success;
  
}



  