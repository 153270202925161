import * as React from 'react';
import { DrawerActions, NavigationContainer, useIsFocused } from '@react-navigation/native';
import { StoreData, GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity, Modal, Alert, Dimensions, Image, KeyboardAvoidingView } from 'react-native';
import { addBinLocationSelector, addBinSelector, defaultBin, defaultBinLocation } from '../../../Helpers/Storage/DefaultStates';
import { Bin, BinLocation } from '../../../Helpers/Storage/UserState';
import { AddLocation } from '../../../Helpers/Functions/Offline/BinFunctions';
import VGInput from '../../../Components/Input/Input';
import textStyles from '../../../Styles/Text';
import spacing from '../../../Styles/Spacing';
import VGButton from '../../../Components/Button/Button';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

//This function houses a modal with is used to add a new location to the profile's location data
function AddNewSampleBinLocation(props: {
    locations: BinLocation[], setLocations: any, isAddingLocation: boolean, setIsAddingLocation: any, setIsAddingBin: any, currentBin: {
        bin: Bin,
        location: BinLocation,
    }, setCurrentBin: any
}) {

    let isFocused = useIsFocused();

    const [isAddingLocation, setIsAddingLocation] = React.useState(false);

    //This useEffect will grab the data before rendering the page
    //this means we dont need to use a button to grab data!

    React.useEffect(() => { console.log('closing screen'); }, [isFocused])

    const [location, setLocation] = React.useState<BinLocation>({ ...defaultBinLocation });
    const locRef = React.useRef(null);

    function onChangeLocationText(text){
        let oldlocation = location;
        oldlocation.location = text;
        setLocation({ ...oldlocation });
    }

    async function onSavePress(){

        const profile = await GetData('Profile');
        location.profile = profile;
        let addLocationStatus = await AddLocation(location);

        if (addLocationStatus != null) {
            let oldLocs = props.locations;
            oldLocs.pop();
            oldLocs.push(addLocationStatus);
            oldLocs.push(addBinLocationSelector);
            props.setLocations([...oldLocs]);
            let curBin = { ...props.currentBin };

            curBin.location = addLocationStatus;
            props.setCurrentBin({ ...curBin });
            setLocation({ ...defaultBinLocation });
            props.setIsAddingLocation(false);
            props.setIsAddingBin(true);

        }
    }

    function onCancelPress(){
        setLocation({ ...defaultBinLocation });
        props.setIsAddingLocation(false);
    }

    return (

     
        <View style={[spacing.Container, {flex:1}]}>


            <View style={spacing.ScreenHeader}>
                <Text style={textStyles.title}>Add Storage Location</Text>
            </View>

     
    
     <VGInput label="Storage Location" 
                    value={location?.location ?? ''}
                    onChangeText={onChangeLocationText}
                 />


            <View style={spacing.Bottom} >   
                <VGButton role="PRIMARY" onPress={onSavePress}
                >Save</VGButton>

                <VGButton role="CANCEL" onPress={onCancelPress} >
                    Cancel
                </VGButton>
            </View>
          

        </View>

    )
}






export default AddNewSampleBinLocation