import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";
import moment from "moment";
import { Alert, Linking } from "react-native";
//import Toast from "react-native-tiny-toast";
import { GetData, StoreData } from "../../AsyncStorageHelper";
import { ApiResponse, setProp } from "../../GeneralTypes";
import { GrowerUser, PendingUser, UserInfo,UserManualPageLookup } from "../../Storage/UserState";
import { ApiCall } from "../GeneralFunctions";
import jwt_decode from "jwt-decode";
//#region Get Functions

export async function GetUser(email: string): Promise<UserInfo> {
  if (email != null) {
    const data = new FormData();
    data.append("Emailaddress", email);
   
    //    link = "/app/get-user" + data;
    const status = await ApiCall<ApiResponse & { user_info: UserInfo }>("/api/get-wp-user-info-phone", data);

    if (status.response == 0) {
      return status.user_info;
    }
  }

  return null;
}

export async function ResetAll()
{
  const status = await ApiCall<ApiResponse>("/offline/reset-all");

  if(status.response == 0)
  {
    return true;
  }
  else
  {
    return false;
  }

}
export async function GetUserManualPageLookup(): Promise<UserManualPageLookup[]>
{
  const status = await ApiCall<ApiResponse & {pages:UserManualPageLookup[]}>("/api/get-user-manual-pages");
  if(status.response == 0)
  {
    return status.pages;
  }
  return null;
}
export async function GetLocalUserManual(): Promise<UserManualPageLookup[]>
{
  const UM = await GetData('user_manual_lookup');
  if(UM != undefined)
  {
    const pages: UserManualPageLookup[] = JSON.parse(UM);
    return pages??null;
  }

return null;
}

//This function will grab all users incase you want to add a new user
export async function GetAllSecondaryUsers(profile: string, email: string): Promise<UserInfo[]> {
  if (profile != null && email != null) {
    const data = new FormData();
    data.append("SelectedProfile", profile);
    data.append('Emailaddress', email);

    const status = await ApiCall<ApiResponse & { user_info: UserInfo[] }>("/api/get-wp-secondary-users", data);

    if (status.response == 0) {
      return status.user_info;
    }
  }

  return null;
}

export async function GetAllUsers(profile:string)
{
  const data = new FormData();
  data.append("profile", profile);


  const status = await ApiCall<ApiResponse & { user_info: UserInfo[] }>("/api/get-all-users", data);

  if (status.response == 0) {
    return status.user_info;
  }


return null;
}
//#endregions

//#region Create Functions
export async function CreateAccount(email: string, phone: { number: string, valid: boolean }, password: string, passwordConfirm: string, company: string, name: string, city: string, country: string | undefined, province: string | undefined, couponCode: string | undefined): Promise<ApiResponse & { profile: string; id: number; }> {

    const link = "/api/sign-up-primary-wp-user-phone";

    const formData = new FormData();
    formData.append('Name', name);
    formData.append('Password', password);
    formData.append('Company', company);
    const trimEmail = email.trim();
    formData.append('Emailaddress',trimEmail);
    formData.append('Phone', phone.number.toString());
    formData.append('Country', country);
    formData.append('Province', province);
    formData.append('City', city);

    const didCreateAccount = await ApiCall<ApiResponse & { profile: string, id: number }>(link, formData);
      return didCreateAccount;
    
}


export async function SendSubscriptionEmail(id: string, email: string): Promise<number> {
  const profile = await GetData('Profile');

  const momentDate = moment().add(2, 'hours').unix();

  //VERY IMPORTANT
  const key = 'pyFHc9k3r3hlsCPEF8V4IQSt5k320vpX';

  const token = JWT.encode({
    redirectLink: 'annual-subscription',
    userID: id.toString(),
    profile: profile,
    expireDate: momentDate
  }, key, { algorithm: SupportedAlgorithms.HS256 })


  const url = "/api/send-subscription-email";

  const formData = new FormData();
  formData.append('Token', token);
  formData.append('Email', email);

  const emailStatus = await ApiCall<ApiResponse>(url, formData);


  //return the phone status
  return emailStatus.response;
}

export async function CheckIfPhoneExists(phone: string, user_id?: number): Promise<number> {
  const formData = new FormData();
  formData.append('Phone', phone);

  if(user_id != null) {
    formData.append('ID', user_id.toString());
  }

  //Fetch the status to determine if it exists, FAILURE means it does not exist, SUCCESS means it does 
  const phoneStatus = await ApiCall<ApiResponse>("/api/check-if-WP-phone-exists", formData);

  //return the phone status
  return phoneStatus.response;
}

//This function will check to see if an email already exists, it will send a status code to another function
//CheckEmail(status: string) to determine whether or not to make the account
export async function CheckIfEmailExists(email: string): Promise<number> {
  const formData = new FormData();
  formData.append('Emailaddress', email);

  //Fetch the status to determine if it exists, FAILURE means it does not exist, SUCCESS means it does
  const emailStatus = await ApiCall<ApiResponse>("/api/check-if-WP-email-exists", formData);


  //return the email status
  return emailStatus.response;
}



//This function will attempt to validate all the fields before sending it off
//If any of them fail, then its an invalid account
//TODO: Toast functionality
export function ValidateAllFields(Email: string, Password: string, ConfirmPassword: string, Company: string, Name: string, City: string, Province: string, Country: string): string[] {

  let errorList: string[] = [];
  if (!ValidateEmail(Email)) {
    errorList.push("Email cannot be empty and must be a valid email")
  }
  if (!ValidatePassword(Password) || Password != ConfirmPassword) {
    errorList.push("Password cannot be empty, minimum of 8 characters, must at least contain 1 upper case, 1 lower case, 1 number, and 1 special character, max 30 chars")
  }
  if (!ValidateGenericField(Name)) {
    errorList.push("Name cannot be empty and must be less then 40 characters")
  }
  if (!ValidateGenericField(Company)) {
    errorList.push("Company cannot be empty and must be less then 40 characters")
  }
  if (!ValidateGenericField(City)) {
    errorList.push("City cannot be empty and must be less then 40 characters")
  }
  if (Province == '' || Province == null) {
    errorList.push("Province cannot be empty")
  }
  if (Country == '' || Country == null) {
    errorList.push("Country cannot be empty")
  }
  console.log('Country is ' + Country);
  return errorList;
}

function ValidateEmail(Email: string): boolean {
  const reg = /^[ ]*?(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z ]{2,}))$/;
  if (reg.test(Email)) {
    return true;
  }
  else {
    return false;
  }
}

export function ValidateGenericField(Field: string): boolean {
  if (Field.length == undefined) {
    return false;
  }
  if (Field != '' && Field.length <= 40) {
    return true;
  }
  else {
    return false;
  }
}



/**
 * 
 * @param email The users email
 * @param password The users password
 * @returns a status of the Validity of credentials
 *  -1 -> Invalid
 *  0 -> Valid (Only one that allows user to navigate to main menu)
 *  1 -> username/password is incorrect
 *  2 -> account does not exist
 *  3 -> account exists and valid (No subscription purchased)
 */
export async function UserLogin(email:string,password:string):Promise<{status:number,id?:number,profile?:string}>
{
  try
  {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('email', email);
    const status = await ApiCall<ApiResponse & {token:any,demo_status:{response:number,message:string},subscription_status:{response:number,message:string}}>('/offline/login',formData,false);
    
    if(status.response == 0)
    {
      console.log('Valid Account');
        if(status.demo_status.response == 0 || status.subscription_status.response == 0)
        {
          console.log('Valid subscription' + status.token.toString());
          var decoded:{profile:string,id:number} = jwt_decode(status.token);

          console.log('Token: ' + JSON.stringify(decoded));
          //Store data
          StoreData('Profile',decoded.profile);
          StoreData('Email',email);
          StoreData('Password',password);
          StoreData('ID',decoded.id.toString());
          StoreData('AuthToken',status.token);
          return {status:0,id:decoded.id,profile:decoded.profile}; 
        }
        else
        {
          return {status:3,id:null,profile:null}; 
        }

    }
    else if(status.response == 1)
    {
      return {status:1,id:null,profile:null}; 
    }
    else if(status.response == 2)
    {
      return {status:2,id:null,profile:null}; 
    }
  
    return {status:-1,id:null,profile:null}; 

  }
  catch(error)
  {
    return {status:-1,id:null,profile:null}; 
  }


}


export function ValidatePassword(password: string): { upper: boolean; lower: boolean; number: boolean; special: boolean; size: boolean; valid: boolean; } {

  let passwordObj = { upper: false, lower: false, number: false, special: false, size: false, valid: false }


  for (var i = 0; i < password.length; i++) {

    if (password[i].match('[a-z]')) {
      passwordObj.lower = true;

    }


    if (password[i].match('[A-Z]')) {
      passwordObj.upper = true;

    }

    if (password[i].match('[0-9]')) {
      passwordObj.number = true;

    }

    //special character regex was never working for some reason for all special characters
    if (password[i] == '!' || password[i] == '@' || password[i] == '#' || password[i] == '$' || password[i] == '%' || password[i] == '^' || password[i] == '&' || password[i] == '*' || password[i] == '(' || password[i] == ')' || password[i] == '-' || password[i] == '_' || password[i] == '=' || password[i] == '+' || password[i] == '[' || password[i] == '{' || password[i] == ']' || password[i] == '}' || password[i] == '\\' || password[i] == '|' || password[i] == ';' || password[i] == ':' || password[i] == '\'' || password[i] == '\"' || password[i] == ',' || password[i] == '<' || password[i] == '.' || password[i] == '>' || password[i] == '/' || password[i] == '?') {
      passwordObj.special = true;

    }
  }

  if (password.length >= 8) {
    passwordObj.size = true;
  }
  return passwordObj;

}

//This will go ahead and actually reset the email
export async function ResetEmail(email: string): Promise<boolean> {
  if (email.length != 0) {
    const formData = new FormData();
    formData.append('EmailAddress', email);

    const status = await ApiCall<ApiResponse & {profile:string,id:number,email:string,demo_status:any,subscription_status:any}>("/api/create-password-reset", formData);


    if (status.response == 0) {
     // Toast.show("Success!");
      return true;
    }
  }

  return false;
}






export async function GetSubscriptionStatus(email: string, ID: string): Promise<number> {
  const formData = new FormData();
  formData.append('Email', email);
  formData.append('UserID', ID);

  const subscriptionStatus = await ApiCall<ApiResponse>("/api/get-latest-subscription", formData);

  console.log('Sub Status:  ' + subscriptionStatus.message + '  Code: ' + subscriptionStatus.response);
  return subscriptionStatus.response;
}

export async function CheckCouponCode(couponCode: string): Promise<{ enterpriseID: number }> {
  const buyerDetails: { enterpriseID: number } = { enterpriseID: 0 };
  const link = "/api/check-coupon-code";
  const data = new FormData();
  data.append('CouponCode', couponCode);

  const couponStatus = await ApiCall<ApiResponse & { enterpriseID: number }>(link, data);

  if (couponStatus.response == 0) {
    buyerDetails.enterpriseID = couponStatus.enterpriseID;
    return buyerDetails;
  }

  // enterpriseID is 0 unless a response was received from the API
  return null;
}

export async function AddCreatedGrowerToEnterpriseUser(profile: any, id: any, enterpriseID: number): Promise<boolean> {
  const formData = new FormData();
  formData.append('enterpriseID', enterpriseID.toString());
  formData.append('growerID', id.toString());
  formData.append('growerProfile', profile);

  const couponStatus = await ApiCall<ApiResponse>("/api/add-grower-to-enterprise-account", formData);

  return couponStatus.response == 0;
}

export async function DeleteSecondaryUser(ID: number, email: string): Promise<boolean> {
  const link = "/api/remove-secondary-wp-user";
  const formData = new FormData();
  formData.append('ID', ID.toString());
  formData.append('Email', email);

  //wait until the promise is returned before doing anything
  const status = await ApiCall<ApiResponse>(link, formData);

  return status.response == 0;
}

export async function redirectSubscription(ID: number, email: string): Promise<void> {
  const momentDate = moment().add(2, 'hours').unix();
  //VERY IMPORTANT
  const key = 'pyFHc9k3r3hlsCPEF8V4IQSt5k320vpX';

  const token = JWT.encode({
    redirectLink: 'annual-subscription',
    userID: ID.toString(),
    expireDate: momentDate
  }, key, { algorithm: SupportedAlgorithms.HS256 })

  const url = 'https://verigrain.com/product/annual-subscription/' + '?token=' + token;
  console.log(url);

  //open the store
  Linking.openURL(url);
}

export async function DeletePrimaryUser(ID: number, email: string, profile: string): Promise<boolean> {
  if (profile != null && email != null) {
    const link = "/api/remove-primary-wp-user";

    const formData = new FormData();
    formData.append('Profile', profile);
    formData.append('ID', ID.toString());
    formData.append('Email', email);

    const status = await ApiCall<ApiResponse>(link, formData);

    return status.response == 0;
  }

  return false;
}

export async function UpdateUser(user: UserInfo): Promise<0 | -1> {
  const profile = await GetData('Profile');

  if (profile != null) {
    const link = "/api/update-WP-user-phone";

    const formData = new FormData();
    formData.append('Name', user.name);
    formData.append('Company', user.company);
    formData.append('Email', user.email);
    formData.append('Phone', user?.phone?.toString()??'');
    formData.append('Country', user.country);
    formData.append('Province', user.province);
    formData.append('City', user.city);
    formData.append('SelectedProfile', profile);
    formData.append('ID', user.id.toString());

    const status = await ApiCall<ApiResponse>(link, formData,true);

    if (status.response == 0) {
      console.log('updating');
      return status.response;
    }
  }

  return -1;
}

export async function GetGrowerDetails(id: string): Promise<GrowerUser> {
  const link = '/api/get-grower-user';
  const formData = new FormData();
  formData.append("UserID", id);

  const status = await ApiCall<ApiResponse & { grower: GrowerUser }>(link, formData);

  if (status.response == 0) {
    return status.grower;
  }

  return null
}

export async function GetGrowerList(profile: string): Promise<GrowerUser[]> {
  const link = '/api/get-grower-list';
  const formData = new FormData();
  formData.append("profile", profile);

  const status = await ApiCall<ApiResponse & { growers: GrowerUser[] }>(link, formData);

  if (status.response == 0) {
    return status.growers;
  }

  return null
}

export async function UpdateGrowerUser(oldUser: GrowerUser): Promise<GrowerUser> {
  const link = '/api/update-grower-user';

  const formData = new FormData();
  formData.append('id', oldUser.id.toString());
  formData.append('enterprise_id', oldUser.enterprise_id.toString());
  formData.append('profile', oldUser.profile.toString());
  formData.append('allow_completed_samples', oldUser.allow_completed_samples ? '1' : '0');

  const status = await ApiCall<ApiResponse>(link, formData);

  if (status.response == 0) {
    return oldUser;
  }
  
  return null;
}


export async function AddDemoDataToAccount(profile: string) {
  if (profile != null) {
    const data = new FormData();
    data.append("profile", profile);

    //    link = "https://webservice.verigrain.com/app/get-user" + data;
    const status = await ApiCall<ApiResponse>("api/add-demo-data-to-account", data);

    if (status.response == 0) {
      return status.response;
    }
  }

  return null;
}
