import * as React from 'react';
import { Text, TouchableOpacity, Dimensions, SafeAreaView, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Overlay } from 'react-native-elements';
import { Buyer, CharacteristicSubOption, CharacteristicType, Enterprise, InstrumentModel, Lab, MasterSample, SampleRecordCharacteristic, UserInstrumentModel } from '../../../../Helpers/Storage/UserState';
import { setProp } from '../../../../Helpers/GeneralTypes';
import CharacteristicOption from './CharacteristicOption';
import { SaveSampleRecordCharacteristics, UpdateSampleRecordCharacteristic } from '../../../../Helpers/Functions/Offline/CharacteristicFunctions';
import CharacteristicInput from './CharacteristicInput';
import { GetData } from '../../../../Helpers/AsyncStorageHelper';
import Toast from 'react-native-tiny-toast';
import { ValidID } from '../../../../Helpers/Functions/GeneralFunctions';
import { AnalysisTypeOptions } from '../../CharacteristicModals/AddSampleCharacteristics';
import spacing from '../../../../Styles/Spacing';
import VGButton from '../../../../Components/Button/Button';


type UserInstrumentProps = {
  mode: "User Instrument";
  selectedSubOptions: CharacteristicSubOption[];
  setSelectedOptions: setProp<CharacteristicSubOption[]>;
  isVisible: boolean;
  setVisible: setProp<boolean>;
  readonly: boolean;
  charOptions: { types: CharacteristicType[]; options: CharacteristicSubOption[] };
}

type SampleProps = {
  mode: "Sample";
  isVisible: boolean;
  setVisible: any;
  readonly: boolean;
  charOptions: { types: CharacteristicType[]; options: CharacteristicSubOption[] };
  sampleChars: SampleRecordCharacteristic[];
  setSampleChars: setProp<SampleRecordCharacteristic[]>;
  analysisType: AnalysisTypeOptions;
  selectedSample: MasterSample;
  selectedBuyer?: Buyer;
  selectedEnterprise?: Enterprise;
  selectedUserInstrument?: InstrumentModel;
  selectedLab?: Lab;
};

function CharacteristicsSelectorModal(props: { sampleChars?: SampleRecordCharacteristic[], addFromAnalysis: boolean | null } & (UserInstrumentProps | SampleProps)) {
  const width = Dimensions.get('window').width; //full width
  const height = Dimensions.get('window').height; //full height

  const [tempSelected, setTempSelected] = React.useState<CharacteristicSubOption[]>([]);
  const [tempSampleChars, setTempSampleChars] = React.useState<SampleRecordCharacteristic[]>([]);

  React.useEffect(() => {

    if (props.mode === "User Instrument" && props.selectedSubOptions != null) {
      setTempSelected([ ...props.selectedSubOptions ]);
    }
  }, [props.mode, props.sampleChars]);

  function onSavePress(){
    if (props.mode === "User Instrument") {
      props.setSelectedOptions([...tempSelected]);
    }
    if (props.mode === "Sample") {
      const saveChars = async () => {


        const to_save = [];

        //Changed so we only save characteristics, no updates should be
        //preformed on this screen
        for (let x of tempSampleChars) {
          console.log('ID OF TEMP: ' + x.id);
          if (x.id == null) {
            to_save.push(x);
          }
        }
        const saved_chars = await SaveSampleRecordCharacteristics(to_save);
        let oldChars = props.sampleChars;
      oldChars =  oldChars.concat(saved_chars);
        props.setSampleChars([...oldChars]);
        Toast.show("Saved Sample Characteristics");
        setTempSampleChars([]);

    
      }
      if (tempSampleChars != undefined && tempSampleChars.length > 0) {
        saveChars();
      }
      else {
        Toast.show("No Sample Characteristics...");
      }
    }
    props.setVisible(false);
  }

  return (

    <Overlay isVisible={props.isVisible} overlayStyle={{ width: width/1.1, height: height/1.1 }}  onBackdropPress={() => { props.setVisible(false) }}>
      <SafeAreaView style={{ flex: 1, marginTop: 20}}>
        <View style={spacing.MainContainer} > 
        <FlatList
          nestedScrollEnabled={true}
          data={props.charOptions.types}
          keyExtractor={(item, index) => index.toString() + item.id + '_' + item.name}
          // data={miniGACOptions}
          renderItem={({ item, index }) => {

            // Are we adding characteristics to a user instrument for later use, or are we adding characteristics to a sample record?
            if (props.mode === "User Instrument") {
              return (<CharacteristicOption type={item} options={props.charOptions.options.filter((x) => x.characteristic_type_id === item.id)} readonly={props.readonly} selectedChars={tempSelected} setSelectedChars={setTempSelected}></CharacteristicOption>);
            } else {
              return (<CharacteristicInput characteristicType={item} characteristicSubOptions={props.charOptions.options.filter((x) => x.characteristic_type_id === item.id)} selectedSample={props.selectedSample} selectedEnterprise={props.selectedEnterprise} selectedLab={props.selectedLab} selectedInstrument={props.selectedUserInstrument} selectedBuyer={props.selectedBuyer} sampleChars={tempSampleChars} setSampleChars={setTempSampleChars} />)
            }
          }
          }
        />

        {
          !props.readonly ?
            <VGButton role="PRIMARY" onPress={onSavePress} 
            >Save</VGButton>
            : null
        }
        <VGButton role="CANCEL" onPress={() => { props.setVisible(false); }} 
        >Close</VGButton>
        </View>
      </SafeAreaView>

    </Overlay>

  )
}

export default CharacteristicsSelectorModal;

