
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView, TextInput,GestureResponderEvent} from "react-native";
import styles from "../../Styles/styles";
import textStyles from "../../Styles/Text";
import { Icon } from "../Icon";

type PropType = {
   onPress:(event: GestureResponderEvent)=>void,
   testLabel?:string,
    currentCount:number,
    selecting:boolean,
    buttonName:string,
    disabled?:boolean
}

/**
 * 
 * @param onPress
 * An Onpress event callback used when tapping on an input with a dropdown selection
 * 
 * @param buttonName
 * Name of the input - I.E THe name that appears on the left side of the input
 * 
 * @param currentCount
 * How many items that were either selected or added
 * @param selecting
 * Are you selected if not your adding...
 * @param testLabel
 * The label used for testing by setting the parameter passed in to the accessibility label
 * By Default sets to Input_Option to allow the textInput to modify state of object (Only used when editable is set to true)
 * 
 * 
 * 
 */

function AddItemButton(props: PropType) {

    return (
        <TouchableOpacity
        accessibilityLabel={props.testLabel??'item'}
        disabled={props.disabled??false}
         style={styles.dropdownInput}
        onPress={props.onPress} >
            <Icon name="plus-square" />
            <Text style={[textStyles.label,{color:props.disabled ? 'lightgrey' :'black'}]}>{props.buttonName} {props.currentCount > 0 ? " (" + props.currentCount + " Selected"  +")" : null}</Text>             
        </TouchableOpacity>
    );

}
export default AddItemButton;