import { Dimensions, StyleSheet } from 'react-native';
import Colours from './Colours';

export const width = Dimensions.get('screen').width; //full width
export const height = Dimensions.get('screen').height; //full height

export const NoSpacing = 0;
export const TinySpacing = 5;
export const SmallSpacing = 10;
export const MediumSpacing = 15;
export const LargeSpacing = 20;

//This is used to style the pages
const spacing = StyleSheet.create({
  TitleBar:{
    backgroundColor:Colours.WHITE,
  },
  /**
   * Requires an element at the bottom with MediumSpacing to have correct bottom margins.
   */
  MainContainer: {
    flex:1, marginTop:NoSpacing, marginHorizontal: LargeSpacing, marginBottom: 5 
  },
  WebMainContain:{
    flex:1, marginTop:MediumSpacing, marginHorizontal: LargeSpacing, marginBottom: 5 
  },
  ScreenHeader:{
    flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingBottom: MediumSpacing
  },
  SectionItem:{
    paddingVertical:SmallSpacing,flex:1,flexDirection:'row',alignContent:'center',alignItems:'center'
  },
  RadioRow:{
    flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', padding: 5, marginVertical: 5
  },
  HorizontalSpacer:{
    width:MediumSpacing
  },
  VerticalSpacer:{
    height:SmallSpacing
  },
  HorizontalLabelSpacer:{
    width:SmallSpacing
  },
  VerticalLabelSpacer:{
    height:SmallSpacing
  },
  Container:{
    padding:SmallSpacing,
    marginBottom:MediumSpacing
  },
  Modal:{
    padding:SmallSpacing,
    width:width /1.2 ,
    height:height /1.2
  },
  Element:{
    marginBottom:SmallSpacing
  },
  /**
   * For this to work correctly, must have another element above it with flex:1
   */
  Bottom:{
    justifyContent:"flex-end",
  },
  Row:{flexDirection:'row'},
  SamplingKey:{flex:1,paddingVertical:5,marginLeft:5},
  SamplingValue:{paddingVertical:5,marginRight:5},
  alertTitle:{alignSelf:'center',textAlign:'center'},
  alertDescription:{flex:1,alignSelf:'flex-start',textAlign:'left',marginVertical:20},
  CenterRightIcon:{
    flex:1,flexDirection:'row',alignSelf:'center'
  },
  SectionValueRow:{
    paddingHorizontal:28,paddingVertical:10,flex:0,flexDirection:'row',alignContent:'center',alignItems:'center'
  },
  flexOne:{
    flex:1
  },
  paddingRight20:{
    paddingRight:20
  }
});
export default spacing;
