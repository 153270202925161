import * as React from 'react';
import { View, Text, TextInput, StyleSheet, ImageBackground, Image, DeviceEventEmitter, Alert, EventEmitter, Dimensions, Platform, Vibration, TouchableOpacity, ScrollView, AppState } from 'react-native';
import {NavigationContainer,DefaultTheme, useIsFocused, useNavigation, DrawerActions, CommonActions  } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from './Screens/StackScreens/LoginScreen';
import CreateAccountScreen from './Screens/StackScreens/CreateAccountScreen';
import ForgotPasswordScreen from './Screens/StackScreens/ForgotPasswordScreen';
import ResetPasswordScreen from './Screens/StackScreens/ResetPasswordScreen';
import MainActivityNavigationScreen from './Screens/DrawerScreens/MainActivityNavigationScreen';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
const image = { uri: "/Users/lenniebudgell/Documents/SKPLY/verigrain/vgrepos/testreact/vgtest/assets/bgmenu.png" }
import { LogBox } from 'react-native';
import * as Updates from 'expo-updates';
import Constants from "expo-constants";
import { GetData, RemoveData, storage, StoreAsyncData, StoreData } from './Helpers/AsyncStorageHelper';
import ShareScreen from './Screens/StackScreens/ShareScreen';
import NotActivatedScreen from './Screens/StackScreens/NotActivatedScreen';
import OfflineNotice from './Screens/HelperScreens/OfflineNotice';
import SamplesPendingScreen from './Screens/StackScreens/SamplesPendingScreen';
import CombineSamplesScreen from './Screens/StackScreens/CombinedSampleScreens/CombineSamplesScreen';
import { FontAwesome } from '@expo/vector-icons';

import {TinyEmitter} from "tiny-emitter";
import StorageSearchScreen from './Screens/StackScreens/StorageScreens/StorageSearchScreen';
import StorageDisplayScreen from './Screens/StackScreens/StorageScreens/StorageDisplayScreen';
import * as ScreenOrientation from 'expo-screen-orientation';
import SampleProgressSearchScreen from './Screens/StackScreens/SampleProgressScreens/SampleProgressSearchScreen';
import { ManualProgressTimes, ManualSamplingTypes, MasterSample, UserManualPageLookup } from './Helpers/Storage/UserState';
import BinsScreen from './Screens/DrawerScreens/Bins/BinsScreen';
import BuyerScreen from './Screens/DrawerScreens/Buyers/BuyerScreen';
import FieldsScreens from './Screens/DrawerScreens/Fields/FieldsScreen';
import ListEquipments from './Screens/DrawerScreens/Equipment/ListEquipments';
import NewSampleScreen from './Screens/StackScreens/PrimarySampleScreens/NewSampleScreen';
import SampleSelectorScreen from './Screens/StackScreens/PrimarySampleScreens/SampleSelectorScreen';
import CreateSubSampleScreen from './Screens/StackScreens/SubSampleScreens/CreateSubSampleScreen';
import AutomaticSampleProgressScreen from './Screens/StackScreens/PrimarySampleScreens/AutomaticSampleProgressScreen';
import StorageDetailScreen from './Screens/StackScreens/BinContentsScreen/StorageDetailScreen';
import UpdatedInstrumentScreen from './Screens/DrawerScreens/UpdatedInstrumentScreen'
import { HeaderBackButton, HeaderTitleProps } from '@react-navigation/elements';
import YardSelectionScreen from './Screens/StackScreens/YardScreens/YardSelectionScreen';
import YardOverviewScreen from './Screens/StackScreens/YardScreens/YardOverviewScreen';
import { BleError, BleManager, Device } from 'react-native-ble-plx';
import LearnMoreOverlay from './Screens/HelperScreens/LearnMoreOverlay';
import { learnMoreLookupData } from './Helpers/Storage/LearnMoreInfoLookup';
import {Overlay } from 'react-native-elements';
import { useContext } from 'react';
import SampleProgressReportScreen from './Screens/StackScreens/SampleProgressScreens/SampleProgressReportScreen';
import StorageContentSearchScreen from './Screens/StackScreens/BinContentsScreen/StorageContentSearchScreen';


import * as FileSystem from 'expo-file-system';
import { GetEnterprises } from './Helpers/Functions/Offline/BuyerFunctions';
import { GetAllEnterprises } from './Helpers/Functions/EnterprisesFunctions';
import ProfileScreen from './Screens/DrawerScreens/Users/ProfileScreen';
import AppContext from './Contexts/AppContext';
import { GetUserManualPageLookup, ResetAll } from './Helpers/Functions/Online/UserFunctions';
import { CheckCurrentSampleAssistStatus } from './Helpers/Functions/Offline/ManualProgressFunctions';
import SampleListScreen from './Screens/StackScreens/PrimarySampleScreens/SampleListScreen';
import NetworkContext from './Contexts/NetworkContext';
import NetInfo from '@react-native-community/netinfo';
import Toast from 'react-native-tiny-toast';
import VGButton from './Components/Button/Button';
import {useFonts} from 'expo-font'
import MasterGrainList from './Screens/DrawerScreens/Grains/MasterGrainList';
import BinDetailsScreen from './Screens/StackScreens/BinContentsScreen/BinDetailsScreen';
import { defaultMasterSample } from './Helpers/Storage/DefaultStates';
import textStyles from './Styles/Text';
import styles from './Styles/styles';
import { Icon } from './Components/Icon';
import Colours from './Styles/Colours';
import spacing from './Styles/Spacing';
import CustomAlert from './Screens/HelperComponents/CustomAlert';
import StandardSamplingScreen from './Screens/StackScreens/SampleProgressScreens/StandardSamplingScreen';
import SmartSamplerScreen from './Screens/StackScreens/SampleProgressScreens/SmartSamplerScreen';
import UltraSamplerScreen from './Screens/StackScreens/SampleProgressScreens/UltraSamplerScreen';
import ButtonRoles from './Styles/ButtonRoles';

FontAwesome.loadFont();
LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
LogBox.ignoreAllLogs();//Ignore all log notifications
//This creates a drawer navigator used for hamburger menu type navigation
const Drawer = createDrawerNavigator();

//this creates a stack navigator used to traverse between pages usually with some sort of event handler on screen
const Stack = createStackNavigator();
var width = Dimensions.get('window').width; //full width
var height = Dimensions.get('window').height; //full height
//const route = useRoute();



export const logoTitle = (title?:string)=> {
  const navigation = useNavigation();
  const appContext = React.useContext(AppContext);
  return(
    <TouchableOpacity accessibilityLabel='Reset_All_Button'  onPress={() => {

      if(__DEV__)
      {
        appContext.setAlertOptions({title:'RESET!',desc:'Are you sure you want to reset?',options:[
          {text:'Confirm', role:'PRIMARY', onPress:async() => {appContext.setShowAlert(false);
                 const status = await ResetAll();
          if(status)
          {
            //TOAST HERE
            Toast.show('Success!');
          }
          else
          {
            //TOAST HERE
            Toast.show('Failure!');
          }
          }},
          {text:'Go To Main Menu', role:'SECONDARY',
          onPress:() => {
            appContext.setShowAlert(false);
                   navigation.dispatch(DrawerActions.jumpTo('Main Menu'));
          navigation.dispatch(CommonActions.navigate('MainActivityNavigation'));
      }},
          {text:'Cancel', role:'CANCEL', onPress:() => {appContext.setShowAlert(false);}}
        ]})
        appContext.setShowAlert(true);
      }
      
      
    }}>
      {
        title != null ?
        <Text style={[textStyles.topTitle, {width:"100%"}]} >{title}</Text>
        :
        <Image 
      source={require('./assets/Grower_Logo.png')}
      style={{width:200,height:50}}
    />
      }
    </TouchableOpacity>

  );
}

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background:'transparent'
  }
};

const hamburgerMenuIcon = () => {
  return (
    <TouchableOpacity accessible={true} accessibilityLabel="Hamburger_Menu" testID='Hamburger_Menu' style={{marginLeft:20}}
    
    onPress={async () =>  {
      

      DeviceEventEmitter.emit('OpenMenu')
      
      }}>
      <Icon name='hamburger' color={Colours.VG_BLUE} />
    </TouchableOpacity>
  );
}




//A custom drawer, essentially this will append some text (or other stuff) to the end of the hamburger menu
function CustomDrawerContent(props: any) {
React.useEffect(() => {
  if(Platform.OS != 'web')
  {
    ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);

  }
},[])


  const version = Constants.manifest.version;

  const appContext = React.useContext(AppContext);
  async function logOutPress() {
        

    appContext.setShowAlert(true);
      appContext.setAlertOptions({title:'Are you sure you want to log out?',desc:'All Offline data not yet synced will be lost and Logging back in will require an internet connection',options:[
        {text:'Log Out', role:'PRIMARY', onPress:async() => {
          
        appContext.setShowAlert(false);
                  await RemoveData("Profile");
        await RemoveData("Password");
        await RemoveData('ID');
        await RemoveData('TimeDetails');
        await RemoveData('Equipment');
        await RemoveData('Email');
        await RemoveData('AuthToken');
        storage.clearAll();
        props.navigation.navigate("Login");

        }},
        {text:'CANCEL', role:'CANCEL', onPress:() => { appContext.setShowAlert(false);}},
      ]});

     }

  return (
    <View style={{flex:1}}>
      <View style={{borderColor:'lightgrey',borderWidth:2,marginHorizontal:10,flex:9}}>
      <DrawerContentScrollView contentContainerStyle={{justifyContent:'flex-end'}}  {...props}>

       <DrawerItemList  {...props} />
       </DrawerContentScrollView>

       </View> 
       <View style={{flex:1,paddingLeft:10}}>
       <Text style={{alignSelf:'flex-start'}}>Support@Verigrain.com</Text>
        <Text style={{alignSelf:'flex-start'}}>Version: {version}</Text>  
    
       </View>
        

        <View style={{marginHorizontal:10,marginBottom:10}}>

        <VGButton role="CANCEL" onPress={logOutPress} >
            Log Out
        </VGButton>

        </View>
        </View>


  
  );
}


//A list of possible routes you can take when accessing the hamburger menu
function DrawerRoutes()
{

  
React.useEffect(() => {
  if(Platform.OS != 'web')
  {
  //Lock screen to portrait
  ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
  }
},[])
  return (

<Drawer.Navigator useLegacyImplementation={true} screenOptions={{headerShown:false}} drawerContent={props => <CustomDrawerContent {...props} />}>
  <Drawer.Screen name="Main Menu" component={MainActivityNavigationScreen}   />
  <Drawer.Screen name="Storage" component={BinsScreen}/>
  <Drawer.Screen name="Buyers/Brokers" component={BuyerScreen}/>
  <Drawer.Screen name="Fields"  component={FieldsScreens}/>
  <Drawer.Screen name="Grains"  component={MasterGrainList}/>
  {/* <Drawer.Screen name="Handling Equipment" component={EquipmentScreen} /> */}
  <Drawer.Screen name="Handling Equipment" component={ListEquipments} />
  <Drawer.Screen name="User Information" component={ProfileScreen} />
  <Drawer.Screen name="User Instruments" component={UpdatedInstrumentScreen}/>
</Drawer.Navigator>
  
  );
}


//A stack screen, used to navigate between some pages
  function App() { 

    const [fontsLoaded] = useFonts({
      'Barlow Semi Condensed Light': require('./assets/fonts/BarlowSemiCondensed-Light.ttf'),
      'Barlow Semi Condensed Regular': require('./assets/fonts/BarlowSemiCondensed-Regular.ttf'),
      'Barlow Semi Condensed Medium': require('./assets/fonts/BarlowSemiCondensed-Medium.ttf'),
      'Barlow Semi Condensed Bold': require('./assets/fonts/BarlowSemiCondensed-Bold.ttf'),
      'Barlow Semi Condensed Black': require('./assets/fonts/BarlowSemiCondensed-Black.ttf'),
      'Trebuchet MS': require('./assets/fonts/Trebuchet-MS-Regular.ttf'),
      'Noto Serif': require('./assets/fonts/NotoSerif-Regular.ttf'),
    });
  


    //IMPORTANT - The Bluetooth connection gets real finnicky if trying to track data from a different screen
    //The Sample assist screen and all it's data will be stored here until a better solution is found
    const appContext = React.useContext(AppContext);
 
    const [BTManager,setBTManager] = React.useState<BleManager>(null);

    const [connectedDevice,setConnectedDevice] = React.useState<Device>(null);
    
  const [currentScreenName,setCurrentScreenName] = React.useState<UserManualPageLookup>({id:1,screen_name:'Login'});


    //States to keep track globally
    const  [manualProgressTimes,setManualProgressTimes] = React.useState<ManualProgressTimes[]>([]);
    const [selectedSample,setSelectedSample] = React.useState<MasterSample>({...defaultMasterSample});
    const [showSampleAssist,setShowSampleAssist] = React.useState<number>(-1);
    const [isTimerHidden,setIsTimerHidden] = React.useState<boolean>(false);
    const [manualSamplingType,setManualSamplingType] = React.useState<ManualSamplingTypes|null>(null);

    const [showHelp,setShowHelp] = React.useState(false);
    const [alertOptions,setAlertOptions] = React.useState<{title:string,desc:string,options:{text:string,role:keyof typeof ButtonRoles,onPress:Function}[]}>({title:'',desc:'',options:[]});
    const [showAlert,setShowAlert] = React.useState<boolean>(false);
    
    //Context
    const BTSettings = {
      currentScreenName:currentScreenName,
      manualProgressTimes:manualProgressTimes,
      selectedSample,
      isTimerHidden:isTimerHidden,
      showSampleAssist:showSampleAssist,
      manualSamplingType:manualSamplingType,
      setManualSamplingType:setManualSamplingType,
      setSelectedSample,
      setManualProgressTimes,
      setIsTimerHidden,
      setCurrentScreenName,
      setShowHelp,
      setShowSampleAssist,
      alertOptions:alertOptions,
      setAlertOptions:setAlertOptions,
      showAlert:showAlert,
      setShowAlert:setShowAlert
    }
    const currentContext = useContext(AppContext);

    React.useEffect(() => {
      const getManual = async() => {
        // const pages = await GetUserManualPageLookup();
        // if(pages != null)
        // {
          
        //   StoreData('user_manual_lookup', JSON.stringify(pages));
        // }
      }
      getManual();
     
    },[])
    //This useEffect will cache all the enterprise images on startup
    //This ensures that we don't have to create another version every time
    //we add a new enterprise group
    // React.useEffect(() => {
    //   const getEnterprise = async() => {

    //     try
    //     {
    //       const enterpriseStatus = await GetAllEnterprises()

    //       if(enterpriseStatus != null)
    //       {
            
    //         for(let enterprise of enterpriseStatus)
    //         {
   
    //           const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;
          
    //             const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
    //             const image = await FileSystem.getInfoAsync(path);
  
    //             if(!image.exists)
    //             {
    //               await FileSystem.downloadAsync(url,path);                
    //             }
    //         }
        
            
    //       }
    //     }
    //     catch(error)
    //     {
    //       console.log('Enterprise ERROR: ' + error);
    //     }
     
    //   }
    //   getEnterprise();
    // },[])


    React.useEffect(() => {
      if(showSampleAssist)
      {
        currentContext?.setCurrentScreenName(learnMoreLookupData?.find(x => x?.screen_name=='SampleAssist')??{id:1,screen_name:'Login'});
      }
    },[showSampleAssist])


    const [start,setStart]= React.useState(false);
    const [offlineStatus, setOfflineStatus] = React.useState({isOffline: false});
 

      

   
React.useEffect(() => {
  if(Platform.OS != 'web')
  {
  ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
  }
},[])

React.useEffect(() => {
  const removeNetInfoSubscription = NetInfo.addEventListener((state) => {
    console.log('State: ' + state.isInternetReachable);
    const offline = !(state.isConnected && state.isInternetReachable);
    StoreAsyncData("isOffline", offline);
    setOfflineStatus({isOffline: offline});
    if(offline) {
//TOAST HERE
    }
    else {
     //TOAST HERE
    }
  })
  return () => removeNetInfoSubscription();
}, []);

  //Component that shows the help overlay, data changes depending on what screen your on
  const LearnMoreComponent = () => {

    return (
      <TouchableOpacity style={{marginRight:20}} onPress={() => {
        setShowHelp(true);
      }}>
           <Icon name="help" color={Colours.VG_BLUE} />
      </TouchableOpacity>
    )
  }

const BackIcon = ()=><View accessible={true} accessibilityLabel='Button_Back'><Icon name="back" color={Colours.VG_BLUE} /></View>

  return (
<NetworkContext.Provider value={offlineStatus}>
<AppContext.Provider value={BTSettings}>

{/* Uses the actual image dimentions to calculate the height */}
  <ImageBackground imageStyle={styles.overlayImage} style={styles.overlayImageContainer} source={require('./assets/hex.png')}>

<View style={{flex:1}}>


<CustomAlert title={ BTSettings?.alertOptions?.title??''} desc={ BTSettings?.alertOptions?.desc??''} displayAlert={BTSettings?.showAlert??false} options={ BTSettings?.alertOptions?.options??[]}   />

 
{offlineStatus.isOffline ?
  <View style={{    backgroundColor: '#b52424',
  height: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  width,
  alignContent:'center',
  alignSelf:'center'
}}>
    <Text style={{color: '#fff',width:width,height:30,textAlign:'center',textAlignVertical:'center'}}>No Internet Connection</Text>
  </View>
  : null}


    <NavigationContainer  theme={MyTheme} independent={true}>

      <Stack.Navigator screenOptions={{headerStatusBarHeight:0,headerStyle:spacing.TitleBar,headerRight:LearnMoreComponent, 
        headerBackImage:BackIcon, headerLeftContainerStyle:{width:"15%"}, headerRightContainerStyle:{width:"15%"}, headerTitleAlign:"center" }} initialRouteName="Login"  >

        {/* Login Screens */}
        <Stack.Screen name="Login" component={LoginScreen} options={{headerTitle:() => logoTitle("Account Login")}} />
        <Stack.Screen  name="CreateAccount" component={CreateAccountScreen} options={{headerTitle:()=>logoTitle("Create Account")}} />
        <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{headerTitle:()=>logoTitle("Forgot Password")}}/>
        <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} options={{headerTitle: ()=>logoTitle("Forgot Password"), headerBackTitle:'Back',headerStyle:{backgroundColor: '#fff', elevation:0,shadowColor:'transparent'},headerTitleAlign:'center'   } }/>
        
        <Stack.Screen name="NotActivatedScreen" component={NotActivatedScreen} options={{headerTitle:()=>logoTitle()}} />

        {/* MAIN SCREEN */}
        <Stack.Screen name="MainActivityNavigation" component={DrawerRoutes}
          options={{headerTitle:()=>logoTitle() , headerLeft:hamburgerMenuIcon , headerStyle:{backgroundColor:Colours.WHITE} }} 
        />

        {/* Intelligence Screens */}
        <Stack.Screen name="StorageContents" component={StorageContentSearchScreen}  options={{headerTitle:()=>logoTitle("Storage Contents")}}/>
        
        <Stack.Screen name="YardSelection" component={YardSelectionScreen} options={{headerTitle:()=>logoTitle("Bin Board")}} /> 
          <Stack.Screen name="YardOverview" component={YardOverviewScreen}  />

        <Stack.Screen name="StorageSearch" component={StorageSearchScreen}  options={{headerTitle:()=>logoTitle("Storage Search")}}/>
          <Stack.Screen name="StorageDisplay"  component={StorageDisplayScreen}  options={{headerTitle:()=>logoTitle("Storage Display")}}/>

        <Stack.Screen name="BinDetailsScreen" component={BinDetailsScreen} options={{headerTitle:()=>logoTitle("Bin Details")}} />

        {/* "New Sample" Screens */}
        <Stack.Screen name="NewSample" component={NewSampleScreen}  options={{headerTitle:()=>logoTitle("New Sample")}}/>
        <Stack.Screen name="CombineSampleScreen" component={CombineSamplesScreen}  options={{headerTitle:()=>logoTitle("Combine Samples")}}/>
        
        {/* Sample Record Screens */}
        <Stack.Screen name="MySamplesPicker" component={SampleSelectorScreen} options={{headerTitle:()=>logoTitle("Search Samples")}} />
          <Stack.Screen name="MySamples"  component={SampleListScreen}    options={{headerTitle:()=>logoTitle("Sample Records")}} />
            <Stack.Screen name="ShareScreen" component={ShareScreen} options={{headerTitle:()=>logoTitle("Share Sample")}} />
            <Stack.Screen name="CreateSubSampleScreen" component={CreateSubSampleScreen}  options={{headerTitle:()=>logoTitle("Create Sub-Sample") }}/>

        <Stack.Screen name="SampleProgressSearchScreen" component={SampleProgressSearchScreen} options={{headerTitle:()=>logoTitle("In Progress")}} />

        <Stack.Screen name="SamplesPending" component={SamplesPendingScreen}  options={{headerTitle:()=>logoTitle("Pending Analysis")}}/>

<Stack.Screen name="StorageContentSearchScreen" component={StorageContentSearchScreen} options={{headerTitle:() => logoTitle() }} />
<Stack.Screen name="StorageDetailScreen" component={StorageDetailScreen} options={{headerTitle:() => logoTitle() }  }/>


    


      </Stack.Navigator>

        {showHelp ?
          <LearnMoreOverlay pageNumber={currentScreenName?.id??1} isVisible={showHelp} setIsVisible={setShowHelp} />
        : null}

{showSampleAssist == 1 || showSampleAssist == 2 ? <StandardSamplingScreen sample={selectedSample} manualProgressTimes={manualProgressTimes} manualSamplingType={manualSamplingType} isVisible={showSampleAssist == 1 || showSampleAssist == 2} isHidden={isTimerHidden} 
OnFinish={() => {
  console.log('Finishing');
  setIsTimerHidden(false); 
  setShowSampleAssist(-1); }}
  ToggleHide={() => {isTimerHidden ? setIsTimerHidden(false):setIsTimerHidden(true);}}
  OnSave={(data:string)=>{console.log(data);}}
 /> : null}

 {showSampleAssist == 3 ? <SmartSamplerScreen sample={selectedSample} manualProgressTimes={manualProgressTimes} manualSamplingType={manualSamplingType} isVisible={showSampleAssist == 3}
  OnFinish={() => {
    console.log('Finishing');
  setIsTimerHidden(false); 
  setShowSampleAssist(-1);
  }}
   OnSave={() => {}} ToggleHide={() => {isTimerHidden ? setIsTimerHidden(false):setIsTimerHidden(true);}} isHidden={isTimerHidden} /> : null}

 {showSampleAssist == 5 ? <UltraSamplerScreen sample={selectedSample} manualProgressTimes={manualProgressTimes} manualSamplingType={manualSamplingType} isVisible={showSampleAssist == 5} OnFinish={() => { console.log('Finishing');
  setIsTimerHidden(false); 
  setShowSampleAssist(-1);}} OnSave={() => {}} ToggleHide={() => {isTimerHidden ? setIsTimerHidden(false):setIsTimerHidden(true);}} isHidden={isTimerHidden} /> : null}

    </NavigationContainer>



  </View>
  </ImageBackground>
    </AppContext.Provider>
    </NetworkContext.Provider>
  );

}
export default App;


