
import { BinLocation } from "../Storage/UserState";

export let images = {
  'bin-red': require('../../assets/bin-images/Bin-Red.png'),
  'bin-amber': require('../../assets/bin-images/Bin-Amber.png'),
  'bin-orange': require('../../assets/bin-images/Bin-Orange.png'),
  'bin-green': require('../../assets/bin-images/Bin-Green.png'),
  'bin-placing':require('../../assets/bin-images/Bin-White.png'),
};

export function getBinColor(currentVolume:number|null,totalVolume:number|null):string
{

  if(totalVolume == null && currentVolume == null){
    return 'white';
  }

  if(currentVolume == null || currentVolume === 0){
    return 'lightgreen';
  }
  else{
    

    //we determine the color based upon the amount filled:

    let filledPercent =  currentVolume / totalVolume * 100
    
    if(filledPercent <= 1){
      return 'lightgreen';
        }
    else if(filledPercent <= 50){
      return 'yellow';
    }
    else if(filledPercent <= 90){
      return 'orange';
    }
    else{
      return 'red';
    }

    //TODO purple/magenta? takes over if sold- To be implemented later
    //if(sold){image=images['bin-red']}
    
  }
}

export function getMapBinIcon(currentVolume?:number, totalVolume?:number){

  let image;

  if(totalVolume == null && currentVolume == null){
    return images['bin-placing'];
  }

  if(currentVolume == null || currentVolume === 0){
    image = images["bin-green"];
  }
  else{
    

    //we determine the color based upon the amount filled:

    let filledPercent =  currentVolume / totalVolume * 100
    
    if(filledPercent <= 1){
      image = images["bin-green"];
    }
    else if(filledPercent <= 95){
      image = images["bin-amber"];
    }
    else{
      image = images['bin-orange']
    }

    //TODO red takes over if sold- To be implemented later
    //if(sold){image=images['bin-red']}
    
  }

  return image
}

export async function geoCode(text:string){

    text = text.replace(/;/g, ""); //the search string cannot contain semicolons
    text = text.slice(0,255) //the string cannot be longer than 256 characters
    text = encodeURI(text); //encode for the uri
    let data = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + text + ".json?&access_token=sk.eyJ1IjoiYnJhZHl3YXJmb3JkIiwiYSI6ImNrd2tjeGUzNzE1c3oyb2w1c2NkOGZ2d2YifQ.zbUW4UH2FPV8i4E9fJrh8Q"
    const locStatus = () => fetch(data)
    .then(response=>response.json())
    .then(result=>{return result})
    .catch(error => {
        console.log('error', error);
        return 'error';
      });

      let coord;
      await locStatus().then(ret=>{
        coord = ret.features[0].center;
      });
      return coord;
}

export function getCoord(bl: BinLocation) :[number, number]{
  if(bl.latitude != null && bl.longitude != null)
  {
    return [bl.longitude, bl.latitude];
  }
  else
  {
    return [0,0];
  }

}