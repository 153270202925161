import React, { useEffect, useState, useMemo } from "react";
import { View,Text, Dimensions, SectionList, TouchableOpacity, ScrollView } from "react-native";
import { GrainType,GrainSubCrop, GrainVariety } from "../../../Helpers/Storage/UserState";
import textStyles from "../../../Styles/Text";
import Spacing from "../../../Styles/Spacing";
import { Icon } from "../../../Components/Icon";
import Input from "../../../Styles/Input";


const GrainTypeSectionListKey = React.memo((props: {checked:boolean,shown:boolean,item:{
  grainType: GrainType;
  grainSubCrop: GrainSubCrop | null;
},onPress:any,
onShownPress:any}) => (


<View  style={Spacing.SectionItem}>
      
<TouchableOpacity 
 accessibilityLabel={(props.checked ? 'Checked' : 'Unchecked') + '_' + props?.item?.grainType?.name + (props?.item?.grainSubCrop?.name != null && props?.item?.grainSubCrop?.name != '' ? ' - ' + props.item?.grainSubCrop?.name : '')}
style={{alignSelf:'center'}} onPress={() => {
     props.onPress(props.item)
}}>

  <Icon style={Input.CheckInput}  name={props.checked  ? "checked-icon" : "unchecked-icon"}/>
</TouchableOpacity>


<TouchableOpacity accessibilityLabel={props?.item?.grainType?.name+ (props?.item?.grainSubCrop?.name != null && props?.item?.grainSubCrop?.name != '' ? ' - ' + props?.item?.grainSubCrop?.name : '')} style={Spacing.CenterRightIcon} onPress={() => {
 props.onShownPress(props.item);
}}>
  <Text  style={[textStyles.sectionListKey,{flexWrap:'wrap',flex:1}]}>{props?.item?.grainType?.name}{props?.item?.grainSubCrop?.name != null && props?.item?.grainSubCrop?.name != '' ? ' - ' + props?.item?.grainSubCrop?.name : null}</Text>
  <Icon  name={props.shown ? "chevron-up" : "chevron-down"} />
</TouchableOpacity>

</View>
));

export default GrainTypeSectionListKey;