import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Colours from "../Styles/Colours";
import spacing from "../Styles/Spacing";
import textStyles from "../Styles/Text";



type PropType = {
    options: readonly string[] | string[]
    onSelected: (value)=>void
    default?: number;
    testLabel?: string;
}

/**
 * @param options 
 * The array of options to display
 * 
 * @param onSelected 
 * The function to call when toggle is pressed. 
 * 
 * Passes the option selected as a parameter
 * 
 * @param default(optional)
 *  
 * The index of the starting selected option. If not defined, this will be options[0]
 * 
 * @param testLabel(optional)
 * 
 * ONLY CHANGE IF NECESSARY
 * 
 * The testing label for this element. Each 'cell'/option has it's own label. 
 * Default is "Toggle_[value]".
 * 
 * If changed, it becomes "[testLabel]\_[value]" - the "_" is added. 
 * So, if testLabel="Origin", and the options are ["Storage", "Field"] the testing labels are
 * "Origin_Storage" and "Origin_Field". 
 */
export default function VGToggle(props:PropType){

    const [selected, setSelected] = React.useState<number>(props.default??0)
    const [width, setWidth] = React.useState<number>(0);

    React.useEffect(()=>{
        let val = props.options.map((value)=>10 * value.length).sort().pop()
        setWidth(val);
    },[props.options])

    React.useEffect(()=>{
        setSelected(props.default??0);
    },[props.default])

    function onPressElement(val:number){
        setSelected(val);
        props.onSelected(props.options[val])
    }

    return (
    
    <View style={{flexDirection:"row", borderRadius:45, backgroundColor:Colours.VG_BLUE, padding:1}}>
    {
        props.options.map((value, index, array)=>{
            return (
                <TouchableWithoutFeedback key={index} onPress={()=>{onPressElement(index)}} accessibilityLabel={props.testLabel != null ? props.testLabel+"_"+value : "Toggle_"+value}>
                    <View style={{backgroundColor:value === props.options[selected] ? Colours.VG_BLUE : Colours.WHITE,
                    width:width + 15, alignItems:"center", 
                    justifyContent:"center", paddingVertical:5, 
                    borderWidth:2, borderColor: Colours.VG_BLUE,
                    borderTopLeftRadius: index == 0 ? 45 : 0, borderBottomLeftRadius: index == 0 ? 45 : 0, 
                    borderTopRightRadius: index == array.length-1 ? 45 : 0, borderBottomRightRadius: index == array.length-1 ? 45 : 0
                }}>
                        <Text style={[textStyles.label, {color: selected == index ? Colours.WHITE : Colours.VG_BLUE}]} >{value}</Text>
                    </View>
                </TouchableWithoutFeedback>
            )
        })
    }
    </View>
    
    )
}