import React from "react";
import { ScrollView, View, Text, TouchableOpacity, Image, TextInput, Dimensions, Alert } from "react-native";
import { Overlay } from "react-native-elements";
import Icon from 'react-native-vector-icons/FontAwesome';
import { Bin, Field, Buyer, BinLocation, GrainVariety, Enterprise, MasterSample, GrainType,GrainSubCrop} from "../../../Helpers/Storage/UserState";

import * as Progress from 'react-native-progress';
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import BinSamplePicker from "../SampleDataPickers/BinSamplePicker";
import FieldSamplePicker from "../SampleDataPickers/FieldSamplePicker";
import BuyerSamplePicker from "../SampleDataPickers/BuyerSamplePicker";
import { GetSearchData, GetSampleRecords, GetMasterSampleRecords } from "../../../Helpers/Functions/Offline/SampleFunctions";
import { defaultBin, defaultBinLocation, defaultBuyer, defaultEnterprise, defaultGrain, defaultGrainSubCrop, defaultMasterSample } from "../../../Helpers/Storage/DefaultStates";
import { useIsFocused } from "@react-navigation/native";
import BarcodeReader from "../../HelperScreens/BarcodeReader";
import Toast from "react-native-tiny-toast";
import SampleDatePicker from "../SampleDataPickers/SampleDatePicker";
import { useContext } from 'react';
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';
import { ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import * as FileSystem from 'expo-file-system';
import AppContext from "../../../Contexts/AppContext";
import { ValidateSampleBarcode } from "../../../Helpers/Functions/Validators";
import SampleGrainPicker from "../SampleDataPickers/SampleGrainPicker";
import VGInput from "../../../Components/Input/Input";
import spacing from "../../../Styles/Spacing";
import textStyles from "../../../Styles/Text";


export type SampleDate = { id: number, date:number};

function SampleSelectorScreen({ route, navigation }: { route: any, navigation: any }) {
    var width = Dimensions.get('window').width; //full width

    const appContext = useContext(AppContext);
    const isFocused = useIsFocused();
    React.useEffect(() => {
      if(isFocused)
      {
        appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='SampleSearch'));
      }
    },[isFocused])

    const [sampleBarcode, setSampleBarcode] = React.useState<MasterSample>({ ...defaultMasterSample });


    const [bins, setBins] = React.useState<Bin[]>([]);
    const [locations, setLocations] = React.useState<BinLocation[]>([]);
    const [selectedBin, setSelectedBin] = React.useState<{ bin: Bin, location: BinLocation }>();

    const [fields, setFields] = React.useState<Field[]>();
    const [selectedFields, setSelectedFields] = React.useState<Field[]>([]);

    const [buyers, setBuyers] = React.useState<Buyer[]>([]);
    const [selectedBuyer, setSelectedBuyer] = React.useState<Buyer>();

    const [selectedDateTime, setSelectedDateTime] = React.useState<SampleDate>();

    const [grains, setGrains] = React.useState<{grainType:GrainType,grainSubCrop:GrainSubCrop}[]>([]);
    const [grainVarieties, setGrainVarieties] = React.useState<GrainVariety[]>([])
    const [selectedSampleGrain, setSelectedSampleGrain] = React.useState<{ grain: {grainType:GrainType,grainSubCrop:GrainSubCrop}, grainVarieties: GrainVariety[] }>({ grain: {grainType:defaultGrain,grainSubCrop:defaultGrainSubCrop}, grainVarieties: [] })

    const [enterprises,setEnterprises] = React.useState<Enterprise[]>([]);
    const [selectedEnterprise,setSelectedEnterprise] = React.useState<Enterprise>({...defaultEnterprise});
    const [cachedEnterpriseImages,setCachedEnterpriseImages] = React.useState<{enterprise_id:number,fileUri:string}[]>([]);
        const [isLoading, setIsLoading] = React.useState(false);

    const [sampleDates, setSampleDates] = React.useState<SampleDate[]>([]);

    const barcodeRef = React.useRef(null);

    //an enum of dataPickers, useful when ensuring dataNaming is consistent 
    const enum dataPick {
        Equipment = "Equipment",
        SourceBin = "SourceBin",
        SourceField = "SourceField",
        SourceBuyer = "SourceBuyer",
        DestinationBin = "DestinationBin",
        DestinationBuyer = "DestinationBuyer",
        Grain = "Grain",
        Barcode = "Barcode",
        Date = "Date",
        None = ''
    };
    const [pickingData, setPickingData] = React.useState(dataPick.None);




   //This UseEffect will grab every field related to a sample record used for searching
   React.useEffect(() => {

    const grabData = async() => {

        setIsLoading(true);
            const profile = await GetData('Profile');
        try
        {
            let data = await GetSearchData(profile);
            if(data != null)
            {
                setFields(data.fields);
                setBins(data.bins);
                setLocations(data.bin_locations);
              
                setGrains(data.grainData);
                setGrainVarieties(data.grain_varieties);
                setBuyers(data.buyers);
                setEnterprises(data.enterprises);
                setSampleDates(data.sampleDates);
       
     
                let cachedImages:{enterprise_id:number,fileUri:string}[] = []

                for(let enterprise of data.enterprises)
                {
                    //const url = 'https://webservice.verigrain.com/images/' + enterprise.image_path;
        
                    const path = `${FileSystem.documentDirectory}${enterprise.id}_${enterprise.image_path}`;
                 
                    const image = await FileSystem.getInfoAsync(path);

                    if(image.exists)
                    {
                        cachedImages.push({enterprise_id:enterprise.id,fileUri:image.uri});
                    }
           
   
                }
  
             setCachedEnterpriseImages(cachedImages);
            }
        }
        catch
        {
            console.log('No Sample fields found :(');
        }


        setIsLoading(false);
    }
    grabData();

},[])

    //This useeffect is triggered when a user has selected a buyer
    //The sample record as well as any info tied to that sample is sent over to the sample screen
    React.useEffect(() => {



        try {
            const getSamples = async () => {
                const profile = await GetData('Profile');
                if (selectedBuyer != null && ValidID(selectedBuyer.id)) {
                    setIsLoading(true);


                    const masterSamples = GetMasterSampleRecords(enterprises.find(x => x.id == selectedBuyer.id && x.name == selectedBuyer.name)? "Enterprise":"Buyer" ,selectedBuyer.id.toString());

                    if(masterSamples != null)
                    {
                        navigation.navigate('MySamples',{master_sample_list:masterSamples})
                    }
                    setIsLoading(false);
                }
            };
            getSamples();
        }
        catch
        {
            setIsLoading(false);
        }

    }, [selectedBuyer])

    //This useeffect is triggered when a user has selected a bin
    //The sample record as well as any info tied to that sample is sent over to the sample screen
    React.useEffect(() => {

        try {
                if (selectedBin != null && ValidID(selectedBin.bin.id)) {
                    setIsLoading(true);

                    const masterSamples = GetMasterSampleRecords('Bin',selectedBin.bin.id.toString());

                    if(masterSamples != null)
                    {
                        navigation.navigate('MySamples',{master_sample_list:masterSamples})
                    }
                    setIsLoading(false);

                    //TODO - Get Sub-Samples
                }

        }
        catch
        {
            setIsLoading(false);
        }

    }, [selectedBin])


    //This useeffect is triggered when a user has selected a date/time
    //The sample record as well as any info tied to that sample is sent over to the sample screen
    React.useEffect(() => {

        try {
            const getSamples = async () => {
                if (selectedDateTime != null && selectedDateTime.id != -1) {
                    setIsLoading(true);
                    const profile = await GetData('Profile');
                   const masterSamples = GetMasterSampleRecords('Date',selectedDateTime.id.toString());
                   setIsLoading(false);
                   if(masterSamples != null)
                   {
                       navigation.navigate('MySamples',{master_sample_list:masterSamples})
                   }
           
               }
          
            };
            getSamples();

  
        }
        catch
        {
            setIsLoading(false);
        }

    }, [selectedDateTime])


    //This useeffect is triggered when a user has selected one or more fields
    //The sample record as well as any info tied to that sample is sent over to the sample screen
    React.useEffect(() => {

               if (selectedFields != null && selectedFields.length > 0) {

                    let fieldIDString = '';
                    selectedFields.forEach(field => {
    
                        fieldIDString += field.id.toString() + ',';
                    })
                    fieldIDString = fieldIDString.slice(0, -1);
                    const masterSamples = GetMasterSampleRecords('Field',fieldIDString);
                    if(masterSamples != null)
                    {
                        navigation.navigate('MySamples',{master_sample_list:masterSamples})
                    }
        }
    }, [selectedFields])

    React.useEffect(() => {

        if (isFocused) {
            console.log('Clearing Search results');
            setSelectedBin({ bin: { ...defaultBin }, location: { ...defaultBinLocation } });
            setSelectedBuyer({ ...defaultBuyer });
            setSelectedFields([]);
            setSampleBarcode({ ...defaultMasterSample });
            setSelectedSampleGrain({ grain: {grainType:defaultGrain,grainSubCrop:defaultGrainSubCrop}, grainVarieties: [] });
            setSelectedDateTime({ id: -1, date:null});

        }
    }, [isFocused])

    //This useeffect is triggered when a user has entered a barcode
    //The sample record as well as any info tied to that sample is sent over to the sample screen
    React.useEffect(() => {


        try {
            const getSamples = async () => {
                if(sampleBarcode?.barcode != null && sampleBarcode.barcode != 0)
                {
               
                    if (sampleBarcode.barcode.toString().length == 8 && sampleBarcode.barcode.toString().match(/^[0-9]{8}$/) != null) {
                        setIsLoading(true);
                        console.log('trying to grab master sample');
    
                        const masterSamples = GetMasterSampleRecords('Barcode',sampleBarcode.barcode.toString());
    
                        if(masterSamples.length > 0)
                        {
                            navigation.navigate('MySamples',{master_sample_list:masterSamples})
                        }
                        else{
                            
                            appContext.setAlertOptions({title:'Warning',desc:"There are no samples with the barcode: " + sampleBarcode.barcode,options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
                            appContext.setShowAlert(true);
                            
                        }
                        setIsLoading(false);
                    }
                    else{
                        appContext.setAlertOptions({title:'Warning',desc:'Barcode must be exactly 8 digits. e.g. 12345678',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
                        appContext.setShowAlert(true);
                    }
                }
           
            };
            getSamples();
        }
        catch
        {
            setIsLoading(false);
        }

    }, [sampleBarcode])


    //This useeffect is triggered when a user has selected a grain
    //The sample record as well as any info tied to that sample is sent over to the sample screen
    React.useEffect(() => {

        try
        {
            if (selectedSampleGrain != null && ValidID(selectedSampleGrain?.grain?.grainType.id)) {

                const masterSamples = GetMasterSampleRecords('Grain',selectedSampleGrain?.grain?.grainType?.id + ',' + selectedSampleGrain?.grain?.grainSubCrop?.id);
                if(masterSamples != null)
                {
                    navigation.navigate('MySamples',{master_sample_list:masterSamples})
                }
            }
        }
        catch
        {
            console.log('Could not navigate to sample search screen through selecting a grain');
        }


    }, [selectedSampleGrain])

    function onBarcodeRead(data:string){
        //const valid = ValidateSampleBarcode(data);
        
            let newBarc = {...sampleBarcode};
            newBarc.barcode = parseInt(data);
            setSampleBarcode(newBarc)
        
    }

    return (

        <>
            <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-evenly', marginHorizontal: 10 }}>

                {isLoading ?
                    <Overlay overlayStyle={{ backgroundColor: 'rgba(0,0,0,0)', shadowColor: 'rgba(0,0,0,0)' }} isVisible={isLoading} >
                        <Progress.CircleSnail size={width / 1.75} color={'orange'} thickness={10} />
                        <Text style={{ alignSelf: 'center', color: 'white', fontSize: 22 }}>Fetching Data...</Text>
                    </Overlay>

                    : null}

             
                {pickingData == dataPick.Date ? <SampleDatePicker pickingData={pickingData == dataPick.Date} setPickingData={setPickingData} setData={setSelectedDateTime} data={sampleDates} /> : null}
                {pickingData == dataPick.Barcode? <BarcodeReader isNumeric={true} onBarcodeRead={onBarcodeRead} setPickingData={()=>{setPickingData(dataPick.None)}} /> : null}
                {pickingData == dataPick.SourceBin ? <BinSamplePicker canAdd={false} currentBin={selectedBin} setCurrentBin={setSelectedBin} bins={bins} setBins={setBins} setPickingData={(arg: boolean) => setPickingData(dataPick.None)} pickingData={pickingData == dataPick.SourceBin} locations={locations} setLocations={setLocations} /> : null}
                {pickingData == dataPick.DestinationBin ? <BinSamplePicker canAdd={false} currentBin={selectedBin} setCurrentBin={setSelectedBin} bins={bins} setBins={setBins} setPickingData={(arg: boolean) => setPickingData(dataPick.None)} pickingData={pickingData == dataPick.DestinationBin} locations={locations} setLocations={setLocations} /> : null}
                {/* {pickingData == dataPick.SourceField ? <FieldSamplePicker currentSampleFields={selectedFields} setCurrentSampleFields={setSelectedFields} fields={fields} setFields={setFields} setPickingData={setPickingData} pickingData={pickingData == dataPick.SourceField} /> : null} */}
               {pickingData == dataPick.SourceField ? <FieldSamplePicker canAdd={false} fields={fields} currentSampleFields={selectedFields} pickingData={pickingData == dataPick.SourceField} OnSaveFieldSelection={(selectedFields:Field[])=>{setSelectedFields(selectedFields);setPickingData(dataPick.None);}} OnAddNewField={(field:Field)=>{setFields([...fields,field]); setPickingData(dataPick.SourceField);}} OnCancel={() => {setPickingData(dataPick.None);}} /> : null}
                {pickingData == dataPick.DestinationBuyer ? <BuyerSamplePicker canAdd={false} cachedEnterpriseImages={cachedEnterpriseImages} enterprise={enterprises}  buyers={buyers} setCurrentBuyer={setSelectedBuyer} currentBuyer={selectedBuyer} setBuyers={setBuyers} setPickingData={setPickingData} pickingData={pickingData == dataPick.DestinationBuyer} /> : null}
                {pickingData == dataPick.Grain ? <SampleGrainPicker canAdd={false} grain={grains} varieties={grainVarieties} OnConfirmed={(grainType,grainSubCrop,grainVarieties) => {
   
                setSelectedSampleGrain({grain:{grainType:grainType,grainSubCrop:grainSubCrop},grainVarieties:grainVarieties});
                setPickingData(dataPick.None)
                }} OnClosed={()=>{setPickingData(dataPick.None)}} /> : null}
                
                <View style={spacing.MainContainer}>

                    {/* TODO barcode search */}
                    <VGInput label="Barcode" type="picker" icon="barcode" 
                        onPress={()=>setPickingData(dataPick.Barcode)} value=""
                    />

                    <VGInput label="Date / Time" type="picker"
                        value=""
                        onPress={()=> setPickingData(dataPick.Date)}
                    />
                    

                    {/*ORIGIN SELECTOR  */}
                        <Text style={textStyles.label}>Origin</Text>
                        
                        <View style={spacing.VerticalLabelSpacer}></View>

                        <VGInput label="Storage" type="picker" testLabel="Input_Origin_Storage"
                            onPress={()=>setPickingData(dataPick.SourceBin)}
                            value=""
                        />

                        <VGInput label="Field" type="picker" 
                            onPress={()=>setPickingData(dataPick.SourceField)}
                            value=""
                        />
                    {/*END OF ORIGIN SELECTOR  */}

                    <View style={spacing.VerticalSpacer}></View>

                    {/*DESTINATION SELECTOR  */}
                        
                        <Text style={textStyles.label}>Destination</Text>
                        <View style={spacing.VerticalLabelSpacer}></View>
                        
                        <VGInput label="Storage" type="picker" testLabel="Input_Destination_Storage"
                            onPress={()=> setPickingData(dataPick.DestinationBin)}
                            value=""
                        />

                        <VGInput label="Buyer" type="picker"
                            onPress={()=>setPickingData(dataPick.DestinationBuyer)}
                            value=""
                        />

                    {/*END OF DESTINATION SELECTOR  */}

                    <VGInput label="Grain" type="picker" 
                        onPress={()=>setPickingData(dataPick.Grain)}
                        value=""
                    />
                   
                </View>

            </ScrollView>

        </>
    );
}
export default SampleSelectorScreen;
