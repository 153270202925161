import * as Updates from 'expo-updates';
import Constants from "expo-constants";
const { manifest } = Constants;

//CREATE A NEW FILE AND RENAME TO config.js


//Change the apiurl to your localhost address8080
//http://192.168.1.1:8080
let Config = {
 apiUrl:'https://webservice.verigrain.com',
// apiUrl: 'http://'+manifest.debuggerHost.split(`:`).shift().concat(`:8080`),
  enableHiddenFeatures: false,
  usingOffline:true
};


if (Updates.channel == 'production') {
  Config.apiUrl = 'https://webservice.verigrain.com';
  Config.enableHiddenFeatures = false;
}


export default Config;