import { StyleSheet } from "react-native";
import Colours from "./Colours";

const Input = StyleSheet.create({
    dropdownInput: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        marginVertical: 5,
        borderColor: 'orange',
        borderWidth: 3 },

    RemoveItemInput: { flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', marginVertical: 3, left: 15 },
    CheckInput:{marginRight:20,textAlign:'center',alignSelf:'center'},
    RightChevron:{flex:1,flexDirection:'row',alignSelf:'center'}

});

export default Input;