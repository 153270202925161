import React from "react";
import { View, Text, Image, TextInput, TouchableOpacity, Alert, FlatList, Dimensions, } from "react-native";
import { Overlay } from "react-native-elements";
import Toast from "react-native-tiny-toast";
import Icon from 'react-native-vector-icons/FontAwesome';
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { ApiCall, FormatErrorMessages, safeParseInt, ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import { ApiResponse, setProp } from "../../../Helpers/GeneralTypes";
import { Bin, BinLocation, BinMake, BinModel, StorageType,BinWallType } from "../../../Helpers/Storage/UserState";
import OverlayPicker from "../../HelperScreens/OverlayPicker";
import { EditBinLocState } from "./EditBinLocation";
import { addBinSelector, defaultBin, defaultBinMake, defaultBinModel } from "../../../Helpers/Storage/DefaultStates";
import { ScrollView } from "react-native-gesture-handler";
import OverlayBinPicker from "./OverlayBinPicker";
import YardMapPicker from "../../StackScreens/StackModals/YardMapPicker";
import BarcodeReader from "../../HelperScreens/BarcodeReader";
import { useContext } from 'react';
import { FontAwesome } from "@expo/vector-icons";
import AppContext from "../../../Contexts/AppContext";
import { DeleteBin, GetBinModelCount, GetBinWallTypes, GetStorageTypes, UpdateBinDetails, UpdateBinLocation,SaveBin,UpdateBin } from "../../../Helpers/Functions/Offline/BinFunctions";
import VGButton from "../../../Components/Button/Button";
import VGInput from "../../../Components/Input/Input";
import textStyles from "../../../Styles/Text";
import spacing from "../../../Styles/Spacing";
import { RequiredText } from "../../../Components/SimpleComponents";
type PropTypes = {
    locations: BinLocation[];
    //Are you creating or updating a board bin or a quick bin?
    type: 'board' | 'quick'
    currentLocation: BinLocation;
    bins: Bin[];
    parentMode: EditBinLocState;
    binMakeList:BinMake[];
    binModelList:BinModel[];
    OnBinSave:(bin:Bin)=>void;
    OnLocationSave:(binLocation:BinLocation)=>void;
    OnCancel:()=>void;
    OnModeChange:()=>void;
};

function useStatusColor(text: string): 'lightgrey' | 'green' | 'red' {
    let statusColor: 'lightgrey' | 'green' | 'red' = 'lightgrey';

    if (text == '' || text == undefined) {
        statusColor = 'lightgrey';
    }
    else if (text != '') {
        statusColor = 'green';
    }
    else {
        statusColor = 'red';
    }

    return statusColor;
}

/**
 * Can either be used to Add a new Bin or Update an Existing Bin
 * @param props The props.mode changes this screen from add mode to edit mode
 */
function AddBinScreen(props: PropTypes) {

    const appContext = React.useContext(AppContext);
    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height
    const currentContext = useContext(AppContext);
    const [tempBin, setTempBin] = React.useState<Bin>({...defaultBin});
    const [selectedBinLocation, setSelectedBinLocation] = React.useState<BinLocation>(null);

    const [isSelectingType, setIsSelectingType] = React.useState(false);
    const [isSelectingLocation, setIsSelectingLocation] = React.useState(false);

    const [locationStatusColor, setLocationStatusColor] = React.useState('lightgrey');
    const [nameStatusColor, setNameStatusColor] = React.useState('lightgrey');
    const [typeStatusColor, setTypeStatusColor] = React.useState('lightgrey');
    const [sizeStatusColor, setSizeStatusColor] = React.useState('lightgrey');
    const [barcodeStatusMessage,setBarcodeStatusMessage] = React.useState('');

    const [storageTypes,setStorageTypes] = React.useState<StorageType[]>([]);
    const nameRef = React.useRef(null);
    const sizeRef = React.useRef(null);
    const barcodeRef = React.useRef(null);
    const [selectedType,setSelectedType] = React.useState<StorageType>();

    const [binMakeList,setBinMakeList] = React.useState<BinMake[]>([...props.binMakeList]);
    const [binModelList,setBinModelList] = React.useState<BinModel[]>([...props.binModelList]);

    const [binWallTypeList,setBinWallTypeList] = React.useState<BinWallType[]>([]);
    const [filteredWallTypeList, setFilteredWallTypeList] = React.useState<BinWallType[]>([]);
    const [filteredMakeList,setFilteredMakeList] = React.useState<BinMake[]>([]);
    const [filteredModelList,setFilteredModelList] = React.useState<BinModel[]>([]);
    const [currentMake,setCurrentMake]  = React.useState<BinMake>({...defaultBinMake});
    const [currentModel,setCurrentModel] = React.useState<BinModel>({...defaultBinModel});


    const [wallTiers,setWallTiers] = React.useState([]);
    //manual list of diameters, honestly should be recorded in backend, but running out of time to implement
    //In inches...
    const manualDiameterList:{inches:number,feet:string}[] = [{inches:108,feet:"9'"},{inches:120,feet:"10'"},{inches:144,feet:"12'"},{inches:150,feet:"12'6\""},{inches:156,feet:"13'"},{inches:162,feet:"13'6\""},{inches:168,feet:"14'"},{inches:180,feet:"15'"},
                {inches:186, feet:"15'6\""},{inches:192,feet:"16'"},{inches:197,feet:"5m"},{inches:198,feet:"16'6\""},{inches:210,feet:"17'5\""},{inches:216,feet:"18'"},{inches:222,feet:"18'6\""},{inches:234,feet:"19'6\""},{inches:236,feet:"6m"},
                {inches:240,feet:"20'"},{inches:252,feet:"21'"},{inches:264,feet:"22'"},{inches:270,feet:"23'6\""},{inches:277,feet:"7m"},{inches:288,feet:"24'"},{inches:300,feet:"25'"},{inches:315,feet:"8m"},{inches:324,feet:"27'"},
                {inches:336,feet:"28'"},{inches:354,feet:"9m"},{inches:360,feet:"30'"},{inches:372,feet:"31'"},{inches:394,feet:"10m"},{inches:396,feet:"33'"},{inches:408,feet:"34'"},{inches:432,feet:"36'"},{inches:433,feet:"11m"},
                {inches:444,feet:"37'"},{inches:468,feet:"39'"},{inches:504,feet:"42'"},{inches:512,feet:"13m"},{inches:516,feet:"43'"},{inches:540,feet:"45'"},{inches:576,feet:"48'"},{inches:591,feet:"15m"},{inches:594,feet:"49'6\""},
                {inches:612,feet:"51'"},{inches:648,feet:"54'"}];
    const [filteredDiameterList, setFilteredDiameterList] = React.useState(manualDiameterList);

    const [inInches,setInInches] = React.useState('');
    enum addBinStatus {
        ScanningBarcode,
        SelectingMake,
        SelectingModel,
        SelectingStorageType,
        SelectingDiameter,
        SelectingWallTier,
        SelectingWallType,
        SelectingAeration,
        SelectingDirection,
        SelectingLocation,
        SelectingCoordinates,
        SelectingDuplicateBin,
        PlacingLocation,
        Default
    }
  
 
    const [currentStatus,setCurrentStatus] = React.useState<addBinStatus>(addBinStatus.Default);

    
    
    function fullTiersList():{number:number}[]{
        let tiers = [];
        for(let i = 0; i < 20; i++)
        {
            tiers.push({number:i});
        }
        return tiers;
    }

    React.useEffect(() => {
        setWallTiers(fullTiersList())
    },[])

    React.useEffect(() => {
        const grabMetaData = async() => {

            let wallType = await GetBinWallTypes();
            if(wallType != null)
            {
                setBinWallTypeList(wallType);
                setFilteredWallTypeList(wallType);
            }
        }
        grabMetaData();
    },[])
    
    /**
     * Effect to change the barcode error message
     */
    React.useEffect(() => {
        if(tempBin?.barcode != null)
        {
            if(tempBin?.barcode.match(/^[Ss][0-9]{7}$/)) {
                setBarcodeStatusMessage('');
            }
            else {
                setBarcodeStatusMessage('Barcode must be "S" followed by 7 digits\ne.g.: S1234567');
            }
        }
    
    },[tempBin?.barcode])

    React.useEffect(() => {

        setSelectedBinLocation(props.currentLocation);

        setTempBin({...tempBin,location_id:props.currentLocation.id});
   

        if (props.currentLocation?.location == '' || props.currentLocation == undefined) {
            setLocationStatusColor('lightgrey');
        }
        else if (props.currentLocation.location != '') {

            setLocationStatusColor('green');
        }
        else {
            setLocationStatusColor('red');
        }
    }, [props.currentLocation]);

    React.useEffect(() => {
        console.log('Types: ' + props.type);
    },[props.type])
    React.useEffect(() => {
        //Is the make different? If so, reset the model
        if(currentModel?.bin_make_id != currentMake?.id)
        {
            setCurrentModel({...defaultBinModel});
            let modelList = binModelList.filter(x => x?.bin_make_id == currentMake?.id)
            filteredModelList.splice(0,filteredModelList.length);
            filteredModelList.push(...modelList)

        setTempBin({...tempBin,diameter:0,wall_type_id:0,wall_tier:0});
        }

        filterLists()
    },[currentMake])

    React.useEffect(() => {


        if(currentModel != null && currentModel.id > 0)
        {

            const data = async() => {

                const data = await GetData('Profile');
                let stat = await GetBinModelCount(data,currentModel.id);              
                //check if the user has used this particular bin before
    
                stat += 1;
                 console.log('MODEL: '+ JSON.stringify(currentModel));
          
                 let name = tempBin.name;
                 if(tempBin.name == '')
                 {
                    name = currentMake.name + '_' + currentModel.model_name + '_' + stat
                 }
                setTempBin({...tempBin,name:name,size:currentModel.size,diameter:currentModel.diameter,model_id:currentModel.id,wall_type_id:currentModel.wall_type_id,wall_tier:currentModel?.wall_tier??0});

                
            }
            data();
      
        }
    },[currentModel])

    React.useEffect(() => {

        if(tempBin.model_id > 0){
            
            const binModelSpecs = binModelList.find(x => x.id == tempBin.model_id);
            console.log('Model already Found, setting inches');
            const dia = manualDiameterList.find(dia=> dia.inches == binModelSpecs.nominal_diameter)
            const diaStr = dia != undefined ? dia.feet : '';
            setInInches(diaStr)
        }
        else{
            console.log('Model not populated yet, going by Diameter');
            const dia = tempBin.diameter;
            // const total = dia/12;
            // const feet = Math.floor(total);
            // const remain = total - feet;
            // const inches = (remain * 12).toFixed(0);
            // setInInches(feet.toString() + '" ' + inches.toString() + '\'');

            const d = manualDiameterList.find(d=>d.inches == dia)
            const diaStr = d != undefined ? d.feet : '';
            setInInches(diaStr);
        }
    },[tempBin.diameter])


    React.useEffect(() => {
        setNameStatusColor(useStatusColor(tempBin.name));
    }, [tempBin.name]);

    React.useEffect(() => {
        if(tempBin != null && tempBin?.size != null)
        {
            setSizeStatusColor(useStatusColor(tempBin.size == 0 ? '' : tempBin?.size.toString()));

        }
    }, [tempBin.size]);

    React.useEffect(() => {

        if(tempBin.storage_type_id == null)
        {
            setTypeStatusColor('lightgrey');
        }
        else
        {
            setTypeStatusColor('green');
        }
    }, [tempBin.storage_type_id]);



//  React.useEffect(() => {
//         if (props.parentMode.current_bin != null) {
//             setTempBin(props.parentMode.current_bin);

//         }

//         return (() => {
//             if(props.parentMode.current_bin != null)
//             {
//                 props.setParentMode( {...props.parentMode,current_bin:null});

//             }
//         })
//     }, [props.parentMode.current_bin]);



    let renderSeparatorView = () => {
        return (
            <View style={{
                height: 2,
                width: width,
                backgroundColor: "orange",
            }}
            />
        );
    };



    React.useEffect(() => {

        const getStorage = async() => {
            let storageTypes = await GetStorageTypes();

            let types = storageTypes.filter(x => x.short_name != 'Tote' && x.short_name != 'Bag' && x.short_name != 'Pile')
                if(storageTypes != null)
                {
                setStorageTypes(types);
                }
                else
                {
                    Toast.show('Could not grab storage types');
                }
        }
        getStorage();

        
    },[]);

    React.useEffect(()=>{
        if(currentStatus == null){
            setCurrentStatus(addBinStatus.Default)
        }
    },[currentStatus])



    //This useeffect will filter the manufacturer and model types based on the storage type ID
    React.useEffect(() => {
        //Did the user select a storage type?
        if(tempBin?.storage_type_id != null && tempBin?.storage_type_id > 0)
        {
            //filter the model list
           const modelList = binModelList.filter(x => x.storage_type_id == tempBin.storage_type_id);

        
           let makeList : BinMake[] = [];

           //Loop through and find all manufacturers using the filtered model list (We only want manufacturers of a specific storage type)
           for(let i = 0; i < modelList.length; i++)
           {
            for(let j = 0; j < binMakeList.length; j++)
            {
                if(modelList[i].bin_make_id == binMakeList[j].id && makeList.find(x => x.id == binMakeList[j].id) == null)
                {
                    makeList.push(binMakeList[j]);
                }
            }
           }
           //set the filtered items
           makeList.sort((a,b)=>{return a.name < b.name? -1 : a.name > b.name ? 1 : 0})
           setFilteredMakeList(makeList);
          setFilteredModelList(modelList);
        filteredModelList.splice(0,filteredModelList.length);
        filteredModelList.push(...modelList)
           filterLists();
            
        }
    },[tempBin.storage_type_id,binMakeList,binModelList])



    React.useEffect(()=>{
        if(tempBin.storage_type_id != null && tempBin.storage_type_id > 0 && currentMake.id != null && currentMake.id > 0){
            //filter the model list
            let modelList = binModelList.filter(x=>x.bin_make_id==currentMake.id && x.storage_type_id==tempBin.storage_type_id)
            //.filter(x => x.storage_type_id == tempBin.storage_type_id);

            //TODO this

            const dia = tempBin.diameter
            const tier = tempBin.wall_tier
            const wall = tempBin.wall_type_id
            
            if(dia != 0 && dia != null){
                modelList = modelList.filter(x=>x.diameter == dia);
            }
            
            if(tier != 0 && tier != null){
                modelList = modelList.filter(x=>x.wall_tier == tier);
            }
            
            if(wall != 0 && wall != null){
                modelList = modelList.filter(x=>x.wall_type_id == wall);
            }
            
            
            if(modelList.length == 1){
                console.log("only 1 model")
                setCurrentModel(modelList[0])
            }
            else{
                filteredModelList.splice(0, filteredModelList.length)
                filteredModelList.push(...modelList)
            }
            
            filterLists();
        }


    }, [tempBin.diameter,tempBin.wall_tier, tempBin.wall_type_id])

    function filterLists(){
        const dia = tempBin.diameter
        const tier = tempBin.wall_tier
        const wall = tempBin.wall_type_id

        //reset the filtered lists
        filteredDiameterList.splice(0,filteredDiameterList.length);
        //filter the models based on the other 2 values
        let tempModelList = binModelList.filter((bm)=>{
            let tierTrue = true;
            let wallTrue = true;
            if(tier != 0 && tier != null) tierTrue = bm.wall_tier == tier;
            if(wall != 0 && wall != null) wallTrue = bm.wall_type_id == wall;
            return bm.bin_make_id == currentMake?.id && tierTrue && wallTrue;
        })
        //filter the diameters based on the above filtered models
        let tempDiaList = manualDiameterList.filter(d=>tempModelList.find(model=>model.nominal_diameter == d.inches) != undefined)
        filteredDiameterList.push(...tempDiaList)

        //now the same with tiers
        tempModelList = binModelList.filter((bm)=>{
            let diaTrue = true;
            let wallTrue = true;
            if(dia != 0 && dia != null) diaTrue = bm.diameter == dia;
            if(wall != 0 && wall != null) wallTrue = bm.wall_type_id == wall;
            return bm.bin_make_id == currentMake?.id && diaTrue && wallTrue;
        })
        let tempTierList = fullTiersList().filter(x=>tempModelList.find(model=>model.wall_tier == x.number) != undefined);
        if(tempTierList.length > 0)
        {
            setWallTiers(tempTierList);
        }
        else
        {
            setWallTiers([{number:0}])
        }
       

        //finally, the same with wall types
        tempModelList = binModelList.filter((bm)=>{
            let diaTrue = true;
            let tierTrue = true;
            if(dia != 0 && dia != null) diaTrue = bm.diameter == dia;
            if(tier != 0 && tier != null) tierTrue = bm.wall_tier == tier;
            return bm.bin_make_id == currentMake?.id && diaTrue && tierTrue;
        })
        let tempWallList = binWallTypeList.filter(x=>tempModelList.find(model=>model.wall_type_id == x.id) != undefined)
        setFilteredWallTypeList(tempWallList);


    }


    function onChangeBushels(text){
        if(isNaN(parseInt(text)) || parseInt(text) <= 0)
        {
            setTempBin({...tempBin,size:0});

         
        }
        else
        {
            setTempBin({...tempBin,size:parseInt(text)});

        }
    }
    



    async function onSaveAndPlacePress() {
        if(selectedBinLocation.latitude != null){

            let validationStatus:string[] = []; 
            
            //If its a board bin, we need to validate every option
                //The base bin
                //Any Board Specification
                //Barcode
                //Map Placement
            validationStatus =  validationStatus.concat(ValidateQuickBin(tempBin));
            validationStatus =   validationStatus.concat(ValidateBoardBin(tempBin));
            validationStatus = validationStatus.concat(ValidatePlacing(tempBin));
            

            //Validation status returns a list of error messages, if there is more than 0 message
            //quit, and display invalid message
            if(validationStatus.length > 0)
            {
                let fullString = ``;
                validationStatus.forEach(error => {
                    fullString += error + `\n\n`;
                    });
                    appContext.setAlertOptions({title:'Invalid Bin',desc:fullString,options:[{text:'OK',role:'PRIMARY',onPress:() =>{appContext.setShowAlert(false);}}]})
                    appContext.setShowAlert(true);  
        
            }
            else //it's valid, open up the map to select coordinates
            {
                setCurrentStatus(addBinStatus.SelectingCoordinates);
            }
            
        }
        else{
           

            appContext.setAlertOptions({title:'Location not on map',desc:'This bin location is not mapped, would you like to add' + selectedBinLocation?.location??' the location ' + 'to the map?',options:[
                {text:'Place Location', role:'PRIMARY', onPress:() =>{
                    setCurrentStatus(addBinStatus.PlacingLocation);
                    appContext.setShowAlert(false);}},
                {text:'Save bin without placing',role:'SECONDARY', onPress:async() =>{

                   const profile = await GetData('Profile');
                   let bin = {...defaultBin};
                   //Trying to add additional details to a bin gets difficult, Object.assign helps elevate that
                   // but giving us a fresh copy to change
                   bin =    Object.assign(bin,tempBin);
                   bin.id = null;
   
                   
                   //Remember that the user chose the 'Save Bin without Placing Option', ensure that the 
                   //coordinates are set to null
                   bin.latitude = null;
                   bin.longitude = null;
   
               const binStatus = await SaveBin(bin,profile);
   
               //If the bin status returned a valid status
                   if(binStatus != null)
                   {

        
                       //Set the new bin list
                        props.OnBinSave(binStatus.bin);

                        Toast.show('Saved Storage');
                   }
         

                    appContext.setShowAlert(false);}},
                {text:'Cancel', role:'CANCEL',onPress:() =>{appContext.setShowAlert(false);}}
            ]})
            appContext.setShowAlert(true);  
        }
    }

    async function onSavePress(){
   

        let validationStatus:string[] = []; 
       

         validationStatus =  validationStatus.concat(ValidateQuickBin(tempBin));

         if(props.type == 'board')
         {
           validationStatus =   validationStatus.concat(ValidateBoardBin(tempBin));

         }

        if(validationStatus.length > 0)
        {
            let fullString = FormatErrorMessages(validationStatus);
       appContext.setAlertOptions({title:'Invalid Storage',desc:fullString,options:[
          {text:'OK',role:'PRIMARY',onPress:() => { appContext.setShowAlert(false);}}
        ]});
         appContext.setShowAlert(true); 
        }
        else
        {
           const profile = await GetData('Profile');

               let bin = {...defaultBin};
            bin =   Object.assign(bin,tempBin);
               bin.id = null;

             const  savedBinStatus =  await SaveBin(bin,profile);

             
               //SUCCESS, close screen and display bin in list
            if(savedBinStatus.bin != null)
            {
            props.OnBinSave(savedBinStatus.bin);
            }
     
       
        }

    }

    function onBarcodeRead(data:string){
        let newBin = {...tempBin};

        if(data.charAt(0) == 'S' || data.charAt(0) == 's')
        {
            newBin.barcode = data.toUpperCase();
        }
        else
        {
            newBin.barcode = 'S' + data.toUpperCase();
        }

        setTempBin(newBin);
    }


    async function OnYardSave(coord,zoom)
    {
        const tempLocation:BinLocation = {...selectedBinLocation, longitude:coord[0], latitude:coord[1], zoom_level:zoom}
        try{
            const profile = await GetData('Profile');
            const status = await UpdateBinLocation( tempLocation);

            if(status != null){
                //get the new version of the location object in the list
            
                setCurrentStatus(addBinStatus.Default);
                props.OnLocationSave(tempLocation);
                
                setSelectedBinLocation(tempLocation)

                
                //now go to place the bin
                appContext.setAlertOptions({title:'Location Placed',desc:selectedBinLocation?.location??'Location ' + 'has been placed. You can now place your bin',options:[
                    {text:'OK', role:'PRIMARY',onPress:() => {
                        setCurrentStatus(addBinStatus.SelectingCoordinates)
                        appContext.setShowAlert(false);}}
                  ]});
                   appContext.setShowAlert(true); 
               
                
            }


        }
        catch(err){
            console.log(err);
            appContext.setAlertOptions({title:'Could not edit storage location',desc:'Please try again at a later time',options:[
                {text:'OK', role:'PRIMARY', onPress:() => {
                    appContext.setShowAlert(false);}}
              ]});
               appContext.setShowAlert(true);
        }
    }

    async function OnYardBinSave(coord)
    {
     

            const oldBin:Bin = {...tempBin, longitude:coord[0],latitude:coord[1]};
     

           const profile = await GetData('Profile');

        
        const savedBinStatus = await SaveBin(oldBin,profile);

            if(savedBinStatus?.bin != null)
            {
              
 
                props.OnBinSave(savedBinStatus.bin);

                 Toast.show('Saved Storage');
            }
    
        
    }

    const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
        const paddingToBottom = 20;
        return layoutMeasurement.height + contentOffset.y >=
          contentSize.height - paddingToBottom;
      };


      function onManuPress(){
                   
        if(tempBin.storage_type_id > 0)
        {
         setCurrentStatus(addBinStatus.SelectingMake);

        }
        else
        {
            appContext.setAlertOptions({title:'',desc:'A storage type must be selected first',options:[{text:'OK',role:'PRIMARY',onPress:() => {appContext.setShowAlert(false);}}]})
            appContext.setShowAlert(true);
        }
        
      }



    return (
        <View style={{ flex: 1, justifyContent: 'space-evenly', marginHorizontal: 10, }}>

    {currentStatus == addBinStatus.PlacingLocation ? 
        <Overlay isVisible={currentStatus == addBinStatus.PlacingLocation } >
        <View style={{width:width/1.1,height:height/1.1}}>

    <YardMapPicker type="location" center={[-106, 52]} onCancel={()=>{setCurrentStatus(addBinStatus.Default)}} 
    onSave={ async (coord, zoom)=>{OnYardSave(coord,zoom);}
    }
/>
</View>
</Overlay>
    : null}

    {currentStatus == addBinStatus.SelectingCoordinates ?
    <Overlay isVisible={currentStatus == addBinStatus.SelectingCoordinates } >
            <View style={{width:width/1.1,height:height/1.1}}>

         
       <YardMapPicker selectedBinSpec={tempBin}  type="bin" center={[selectedBinLocation?.longitude??50, selectedBinLocation?.latitude??50]} zoom={selectedBinLocation?.zoom_level??12}  onSave={async (coord)=>{
            OnYardBinSave(coord);
        }} onCancel={()=>{
            setCurrentStatus(addBinStatus.Default);
        }} /> 
           </View>
    </Overlay>
 
    : null}

    {currentStatus == addBinStatus.SelectingDuplicateBin ?
    //TODO Duplicate Bin not working anymore- FIX
    <Overlay isVisible={currentStatus == addBinStatus.SelectingDuplicateBin}>
        <View style={{width:width/1.1,height:height/1.1}}>
            {/* <ScrollView> */}
            <View
            style={{
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "stretch",
                paddingVertical: 3,
            }}>
            <Text
                style={{
                fontSize: 28,
                flex: 1,
                color: "black",
                textAlign: "center",
                }}>
                Select Bin to Copy
            </Text>
            </View>
            <FlatList
            data={
                props.bins.filter(function(x) {
                    if(x?.model_id != null)
                    {
                        return x;
                    }
                })
            }

            renderItem={({ item, index }) => (
                item != null ?
                <View>
                <View
                    style={{
                    flexDirection: "row",
                    borderWidth: 3,
                    borderColor: "lightgrey",
                    marginVertical: 3,
                    alignSelf: "stretch",
                    }}
                >
                    <TouchableOpacity
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        alignContent: "center",
                        alignSelf: "center",
                        padding: 5,
                        alignItems: "center",
                    }}
                    onPress={() => {

                        let tempModel = binModelList.find(x=>x.id === item.model_id);
                        let tempMake = binMakeList.find(x=>x.id === tempModel.bin_make_id);

                        setCurrentMake(tempMake);
                        setCurrentModel(tempModel);

                        setCurrentStatus(addBinStatus.Default)

                    }}
                    >
                    <Image
                        style={{
                            width: 30,
                            height: 30,
                            borderRadius: 5,
                            overflow: "hidden",
                        }}
                        source={require("../../../Icons/BlueBin.png")}
                        />

                    <Text
                        style={{
                            fontSize: 18,
                            alignSelf: "center",
                            paddingRight: 5,
                            position: "absolute",
                            right: 5,
                        }}
                        >
                        {item.name}
                    </Text>
                    </TouchableOpacity>
                </View>
                </View> : null
            )}
            keyExtractor={(item, index) => index.toString()}
            ></FlatList>
        {/* </ScrollView> */}
        </View>
    </Overlay>
    : null}
    

     


   
     <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch'}}>
                 <Text style={{ fontSize: 28, color: 'black', textAlign: 'center' }}>Add Storage</Text>
             </View>
 
             <OverlayPicker type={['location']} text={'Pick a Location'} isVisible={isSelectingLocation} setVisible={setIsSelectingLocation} setData={setSelectedBinLocation} data={props.locations} />


             <ScrollView
             onScroll={({nativeEvent}) => {
                 if (isCloseToBottom(nativeEvent)) {
                     //TODO - Show/Hide an arrow indicator to tell the user they can scroll down further!
                   console.log('CLOSE TO BOTTOM');
                 }
               }}
             >
 
 <>
               <View style={{flex:1,justifyContent:'space-evenly'}}>
               {/* TODO fix this functionality!!!!
               //this doesn't seem to be working now
               {props.type == 'board' ?
                                <VGButton role="SECONDARY"
                               onPress={() => {
                                       setCurrentStatus(addBinStatus.SelectingDuplicateBin)
                               }}>
                               Find Duplicate Storage</VGButton>
               : null} */}

                 <RequiredText />

                <VGInput label="Location" type="picker" required
                    onPress={() => {
                        setIsSelectingLocation(true);
                    }}
                    value={selectedBinLocation != null ? selectedBinLocation.location : ''}
                />
                

                 <VGInput label="Name"  required 
                    value={tempBin.name}
                    onChangeText={(text) => {
              
                      setTempBin({...tempBin,name:text});
                    }}
                      
                 />
                 
                     

                  {/* Barcode Input */}

                  <VGInput label="Barcode" type="picker" icon="barcode" onPress={() => {setCurrentStatus(addBinStatus.ScanningBarcode)}}
                    value={tempBin?.barcode??''} error={barcodeStatusMessage} 
                  />
 

                {props.type == 'board' ? 
                <VGInput label="Direction Door Faces" type="picker" required
                    value={(tempBin != null ? tempBin?.direction  : '')as string}
                    onPress={()=>setCurrentStatus(addBinStatus.SelectingDirection)}
                />
                : null}

                <VGInput label="Type" required type="picker"
                    onPress={()=>setCurrentStatus(addBinStatus.SelectingStorageType)}
                    value={storageTypes.find(x => x.id == tempBin?.storage_type_id)?.short_name??''}
                />

                 {props.type == 'board' ? 

                 
                 
                 
           <>
                <VGInput label="Manufacturer" type="picker"
                    onPress={onManuPress} required
                    value={currentMake != null && currentMake?.id>0 ? currentMake.name : ''}
                />
                
                {/* TODO could probably be a toggle instead */}
                <VGInput label="Floor Aeration" type="picker" 
                    onPress={()=>setCurrentStatus(addBinStatus.SelectingAeration)}
                    value={tempBin != null ? tempBin?.aeration == 1 ? 'Yes' : 'No' : ''}
                />
                 
     

                 <Text style={textStyles.input}> Select Model to Autofill Data </Text>
                 <View style={spacing.HorizontalLabelSpacer}></View>

                 <VGInput label="Model" required type="picker"
                    onPress={() => setCurrentStatus(addBinStatus.SelectingModel)}
                    value={ currentModel != null && currentModel?.id>0 ? currentModel.model_name : ''}
                 />

                 <Text style={textStyles.input} > OR Select Data to Get Model </Text>
 
                <VGInput label="Diameter(feet)" required  type="picker"
                    onPress={() =>setCurrentStatus(addBinStatus.SelectingDiameter)}
                    value={tempBin != null && tempBin.diameter > 0 ? inInches : ''}
                />

                <VGInput label="Wall sheet tiers" type="picker" 
                    required={tempBin.wall_type_id != 4 || tempBin.model_id <= 0}
                    onPress={()=>setCurrentStatus(addBinStatus.SelectingWallTier)}
                    value={tempBin != null && tempBin.wall_tier > 0 ? tempBin.wall_tier.toString() : ''}
                />

                <VGInput label="Wall Type" required type="picker"
                    onPress={()=>setCurrentStatus(addBinStatus.SelectingWallType)}
                    value={tempBin != null && tempBin?.wall_type_id > 0 ? binWallTypeList.find(x => x?.id ==tempBin?.wall_type_id )?.name  : ''}
                />
 
            </>
            : null}
        
                 <VGInput label="Bushels - Calculated" required onChangeText={onChangeBushels}
                    keyboardType={"numeric"}
                    value={tempBin != null && tempBin?.size != 0? tempBin?.size.toString() : ''}
                 />
 
            
               </View>

               <View style={{flex:1,justifyContent:'flex-end'}}>

                {props.type == 'board' ?
                    <VGButton role="PRIMARY" onPress={onSaveAndPlacePress}>Save And Place</VGButton>
                    : null
                }
                <VGButton role={props.type == "board" ? "SECONDARY" : "PRIMARY"} 
                    onPress={onSavePress}>Save</VGButton>
                
                
                {props.type == 'quick' ?
                    <VGButton role="SECONDARY" 
                     onPress={props.OnModeChange}>Add Bin Board Details</VGButton>
                : null}

 
             <VGButton role="CANCEL"  
                 onPress={props.OnCancel}>Close</VGButton>
             </View>
 </>
 
   
     
                                 {/*NOTE: The dataToSave and setter must BOTH be aligned!  */}
                        {currentStatus == addBinStatus.SelectingStorageType ?   <OverlayBinPicker dataTodisplay={['full_name']} dataToSave={['id']} setter={['storage_type_id']} data={storageTypes} text={'Pick a Storage Type'} isVisible={currentStatus == addBinStatus.SelectingStorageType } setCurrentStatus={setCurrentStatus} binData={tempBin} setBinData={setTempBin} /> : null}
                        {currentStatus == addBinStatus.SelectingMake ?   <OverlayBinPicker dataTodisplay={['name']} dataToSave={['id','name']} setter={['id','name']} data={filteredMakeList} text={'Pick a Manufacturer'} isVisible={currentStatus == addBinStatus.SelectingMake } setCurrentStatus={setCurrentStatus} binData={currentMake} setBinData={setCurrentMake} /> : null}
                        {currentStatus == addBinStatus.SelectingModel ?   <OverlayBinPicker dataTodisplay={['model_name']} dataToSave={['id','bin_make_id', 'model_name', 'size', 'storage_type_id', 'full_name', 'short_name', 'diameter', 'height', 'wall_tier', 'wall_type_id', 'name']} setter={['id','bin_make_id', 'model_name', 'size', 'storage_type_id', 'full_name', 'short_name', 'diameter', 'height', 'wall_tier', 'wall_type_id', 'name']} data={filteredModelList.filter(x => x?.bin_make_id == currentMake?.id && x.storage_type_id == tempBin.storage_type_id)} text={'Pick a Model'} isVisible={currentStatus == addBinStatus.SelectingModel } setCurrentStatus={setCurrentStatus} binData={currentModel} setBinData={setCurrentModel} /> : null}
                        {currentStatus == addBinStatus.SelectingDirection ?   <OverlayBinPicker dataTodisplay={['name']} dataToSave={['name']} setter={['direction']} data={[{name:'North'}, {name:'South'}, {name:'East'}, {name:'West'}, {name:'NorthEast'}, {name:'SouthEast'}, {name:'NorthWest'}, {name:'SouthWest'}]} text={'Pick a Direction'} isVisible={currentStatus == addBinStatus.SelectingDirection } setCurrentStatus={setCurrentStatus} binData={tempBin} setBinData={setTempBin} /> : null}
                        {currentStatus == addBinStatus.SelectingAeration ?   <OverlayBinPicker dataTodisplay={['name']} dataToSave={['id']} setter={['aeration']} data={[{name:'Yes', id:1}, {name:'No', id:0}]} text={'Has Aeration?'} isVisible={currentStatus == addBinStatus.SelectingAeration } setCurrentStatus={setCurrentStatus} binData={tempBin} setBinData={setTempBin} /> : null}
                        {currentStatus == addBinStatus.SelectingDiameter ?   <OverlayBinPicker dataTodisplay={['feet']} dataToSave={['inches']} setter={['diameter']} data={filteredDiameterList} text={'Select a diameter'} isVisible={currentStatus == addBinStatus.SelectingDiameter } setCurrentStatus={setCurrentStatus} binData={tempBin} setBinData={setTempBin} /> : null}
                        {currentStatus == addBinStatus.SelectingWallType ?   <OverlayBinPicker dataTodisplay={['name']} dataToSave={['id']} setter={['wall_type_id']} data={filteredWallTypeList} text={'Select a Wall Type'} isVisible={currentStatus == addBinStatus.SelectingWallType } setCurrentStatus={setCurrentStatus} binData={tempBin} setBinData={setTempBin} /> : null}
                        {currentStatus == addBinStatus.SelectingWallTier ?   <OverlayBinPicker dataTodisplay={['number']} dataToSave={['number']} setter={['wall_tier']} data={wallTiers} text={'Select a Wall Tier'} isVisible={currentStatus == addBinStatus.SelectingWallTier } setCurrentStatus={setCurrentStatus} binData={tempBin} setBinData={setTempBin} /> : null}
                        {currentStatus == addBinStatus.ScanningBarcode ? <BarcodeReader isNumeric={false}  onBarcodeRead={onBarcodeRead} setPickingData={()=>{setCurrentStatus(addBinStatus.Default)}} /> : null}


                   
             </ScrollView>
             </View>

    );
}
export default AddBinScreen;


function ValidateQuickBin(tempBin: Bin): string[] {
    let errorLog:string[] = [];
    if(tempBin.name == '' || tempBin.name.length > 40)
    {
        errorLog.push('Bin name cannot be empty, max 40 characters');
    }
    if(!ValidID(tempBin.location_id))
    {
        errorLog.push('location cannot be empty, max 40 characters');
    }
    if(tempBin.storage_type_id <= 0)
    {
        errorLog.push('type cannot be empty, max 40 characters');
    }
    if(tempBin.size <= 0)
    {
        errorLog.push('size cannot be empty,size cannot be 0');
    }
    if(tempBin?.barcode != null && tempBin?.barcode != '' && tempBin?.barcode.match(/^[Ss][0-9]{7}$/) == null)
    {
        errorLog.push('The barcode must either be empty or be "S" followed by 7 digits \n e.g. (S1234567)')
    }
    return errorLog;
}

function ValidateBoardBin(tempBin:Bin): string[] {
    let errorLog:string[] = [];

    if(!ValidID(tempBin.model_id) && tempBin.diameter <= 0 && tempBin.wall_tier <= 0 && tempBin.wall_type_id <= 0 )
    {
        return [];
    }
    if(!ValidID(tempBin.model_id))
    {
        errorLog.push('A Manufacturer and model must be selected');
    }
    if(tempBin?.diameter <= 0 || tempBin?.diameter == null)
    {
        errorLog.push('A Diameter is required');
    }
    if(tempBin.storage_type_id != 3)
    {
        if(tempBin.wall_tier <= 0)
        {
            errorLog.push('A Wall Tier is required');
        }
    }

    if(tempBin?.wall_type_id <= 0 || tempBin?.wall_type_id == null)
    {
        errorLog.push('A Wall Type is required');
    }
    

    return errorLog;
}

function ValidatePlacing(tempBin: Bin):string[]{
    let errorLog:string[] = [];

    if(tempBin.direction == null)
    {
        errorLog.push('Direction must be selected to place bin')
    }

    return errorLog
}



