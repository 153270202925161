import * as React from 'react';
import { DrawerActions, NavigationContainer, useIsFocused } from '@react-navigation/native';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList} from 'react-native';

import { Overlay } from "react-native-elements";
import { useContext } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import AppContext from '../../../Contexts/AppContext';

import spacing from '../../../Styles/Spacing';
import VGButton from '../../../Components/Button/Button';
import { Bin } from '../../../Helpers/Storage/UserState';
//Similar to the barcode camera, except it has no barcode scan capabilities
 function OverlayBinPicker(props: {dataTodisplay:string[], dataToSave:string[],setter:string[], data:any, text:string, isVisible:boolean, setCurrentStatus:any,binData:any, setBinData:any})
{

  const isFocused = useIsFocused();

    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height
    const currentContext = useContext(AppContext);

    let renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: width,
              backgroundColor: "orange",
            }}
          />
        );
      };
  
      function OnBinClick(item:Bin)
      {
        let oldData = {...props.binData};

        for(let i = 0; i < props.setter.length;i++)
        {
          oldData[props?.setter[i]] = item[props?.dataToSave[i]];
        }
        console.log(JSON.stringify(oldData));
        props.setBinData({...oldData});

           props.setCurrentStatus(null);
      }

    return(

        <Overlay  isVisible={props.isVisible}  onBackdropPress={() => {props.setCurrentStatus(null)}}>

        <View style={spacing.Modal}>
        <View style={{flexDirection:'row',alignSelf:'center'}}>
  <Text style={{alignSelf:'center',fontSize:28,textAlign:'center',flex:1}}>{props.text}</Text>
  <TouchableOpacity   onPress={() => {
         // setShowHelp(true);
        // currentContext.setShowHelp(true);
        }}>
             <FontAwesome name="question-circle-o" size={35} color="grey" />
        </TouchableOpacity>
  </View>

            {props?.data == undefined || props?.data?.length == 0 ?
              <View style={{flexDirection:'row',alignSelf:'center'}}>
              <Text style={{alignSelf:'center',fontSize:20}}>No data available</Text>
              </View>
            :
            null
            }
          
        <FlatList 
        ItemSeparatorComponent={renderSeparatorView}
              contentContainerStyle={{justifyContent:'space-evenly',alignContent:'flex-end'}}
              data={props.data}
              renderItem={({ item }) => 
            
              <TouchableOpacity accessibilityLabel={
                  props.dataTodisplay.map((user, index) => (    
                    item[user.toString()]
                   )).toString()
                } onPress= {() => {OnBinClick(item)}} >

                    <Text style={{fontSize:24,padding:10}}> 
                        {props.dataTodisplay.map((property, index) => (    
                        item[property.toString()] + ' '
                        ))}
                    </Text>
                  </TouchableOpacity>
                  }

                  keyExtractor={(item,index) => index.toString()}
              >
              </FlatList>
              </View>
              

              <View>
                <VGButton role="CANCEL" onPress={() => {
                    props.setCurrentStatus(null);
                }}>Cancel</VGButton>
        </View>
        </Overlay>
        
    );
}



export default OverlayBinPicker