import { StyleSheet } from "react-native";
import Colours from "./Colours";

const textStyles = StyleSheet.create({
    title: {
        fontFamily: "Barlow Semi Condensed Medium",
        fontSize: 28
    },
    pickerItem: {
        fontFamily: "Barlow Semi Condensed Regular",
        fontSize: 24
    },
    button: {
        fontFamily: "Barlow Semi Condensed Medium",
        fontSize: 22
    },
    label: {
        fontFamily: "Barlow Semi Condensed Light",
        fontSize: 18
    },
    input: {
        fontFamily: "Barlow Semi Condensed Regular",
        fontSize: 18
    },
    info: {
        fontFamily: "Barlow Semi Condensed Regular",
        fontSize: 16
    },
    error: {
        fontFamily: "Barlow Semi Condensed Regular",
        fontSize: 16,
        color: Colours.ERROR
    },
    placeholder: {
        fontFamily: "Barlow Semi Condensed Light",
        fontSize: 16
    },
    sectionListKey:{
        fontFamily: "Barlow Semi Condensed Mediium",
        fontSize: 24
    },
    sectionListValue:{
        fontFamily: "Barlow Semi Condensed Regular",
        fontSize: 18
    },
    topTitle:{
        fontFamily: "Noto Serif",
        fontSize: 28,
        color:Colours.VG_BLUE,
    },

});

export default textStyles;