import { Alert } from "react-native";
import { GetOfflineStateParsed } from "../../AsyncStorageHelper";
import { ApiResponse } from "../../GeneralTypes";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { UserEquipment,SemEquipment, EquipmentBrand, EquipmentModel } from "../../Storage/UserState";
import { GenerateNumberID, ValidID } from "../GeneralFunctions";

//#region GET FUNCTIONS



export function GetUserEquipment(): UserEquipment[] {

    let equipment =  GetOfflineStateParsed("user_equipment");
    equipment.sort(function (a, b) {
      const reA = /[^a-zA-Z]/g;
      const reN = /[^0-9]/g;
      const aA = a.name.replace(reA, "");
      const bA = b.name.replace(reA, "");
      if (aA === bA) {
          const aN = parseInt(a.name.replace(reN, ""), 10);
          const bN = parseInt(b.name.replace(reN, ""), 10);
          return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
          return aA > bA ? 1 : -1;
      }
  })
    return equipment;
  

 // return null;
}

export function GetMasterEquipmentData(): {equipmentMakeList:EquipmentBrand[], equipmentModelList:EquipmentModel[]} {

  let equipmentList: {equipmentMakeList:EquipmentBrand[], equipmentModelList:EquipmentModel[]} = {equipmentMakeList:[],equipmentModelList:[]};
  
  const equipmentMakeList = GetOfflineStateParsed("equipment_brands");
  console.log('MAKES: ' + JSON.stringify(equipmentMakeList));
  equipmentList.equipmentMakeList = equipmentMakeList

  const equipmentModelList = GetOfflineStateParsed("equipment_models");
  equipmentList.equipmentModelList = equipmentModelList

  return equipmentList;
}

//function to add handling equipment to model table
export async function SaveEquipmentModel(currentField: EquipmentModel): Promise<EquipmentModel|null> {

  const parsedField = {
    ...currentField,
    updated_at: new Date(),
    id: await GenerateNumberID(),
}
    const status = await AddToQueue("equipment_models", parsedField, "add");

    
    if (status) {
      return parsedField;
    }
    else
    {
      return null;
    }
}

export function ValidateEquipmentModel(model:EquipmentModel):string[]
{
  let errorList:string[] = [];

    if(!ValidID(model.brand_id) || model.brand_id == null)
    {
      errorList.push('You must select a brand');
    }
    if(model.bushel_loading == null || model?.bushel_loading <= 0)
    {
      errorList.push('You must use a bushel rating greater than 0');
    }

    if(model?.equipment_type == '' || model?.equipment_type == null)
    {
      errorList.push('You must provide an equipment type');
    }
    if(model?.name == '' || model?.name == null || model?.name == 'Request Equipment')
    {
      errorList.push('You must provide a model name');
    }
    if(model?.profile == '' || model?.profile == null)
    {
      errorList.push('An error has occured when requesting equipment');
    }

  return errorList;
}

export async function GetEquipmentById(profile: string, equipmentId: number): Promise<UserEquipment> {
  if (profile != null) {
    const equipment = await GetOfflineStateParsed("user_equipment");

    return equipment.find(x => x.id == equipmentId);
  }

  return null;
}

export async function GetDeletedEquipment(profile: string): Promise<UserEquipment[]> {
  if (profile != null) {
    const equipment = await GetOfflineStateParsed("user_equipment");

    return equipment.filter(x => x.profile == profile && x.expired != null && x.expired == 1);
  }

  return null;
}

//#endregion

//#region PUT FUNCTIONS

export async function UpdateEquipment(equipment: UserEquipment): Promise<ApiResponse> {

  //If the user has entered in both the email and password fields
  if (equipment.profile != null) {
    const status = await AddToQueue("user_equipment", {...equipment, equipment_model_id: !ValidID(equipment.equipment_model_id)  ? null : equipment.equipment_model_id}, "update");

    if (status) {
      return { message: "Added to queue", response: 0 };
    }
  }
  return null;
}

export function RestoreDeletedEquipment(deletedEquipment: UserEquipment[]): UserEquipment[] {
  let restored: UserEquipment[] = [];

  deletedEquipment.forEach(x => {
    const restore = {...x,expired:0};
    const status =  AddToQueue("user_equipment", restore, "update");
    if (status) {
      console.log(':)');
      restored.push(restore);
    }
    else
    {
      console.log(':(')
    }
  })
  console.log(restored.length);

  return restored.length > 0 ? restored : null;
}

//#endregion

//#region POST FUNCTIONS

export async function SaveEquipment(equipment: UserEquipment): Promise<UserEquipment|null> {

  try
  {
    equipment.id = await GenerateNumberID();
    equipment.equipment_model_id = equipment.equipment_model_id == 0 ? null : equipment.equipment_model_id;
    equipment.truck_load = equipment.truck_load == 0 ? null: equipment.truck_load;
    equipment.unload_min = equipment.unload_min == 0 ? null: equipment.unload_min;
    equipment.unload_sec = equipment.unload_sec == 0 ? null: equipment.unload_sec;

    const status = await AddToQueue("user_equipment", equipment, "add");

    if (status) {
      return equipment;
    }

  return null;
  }
  catch(error)
  {
    return null;
  }
  
}

//#endregion

//#region DELETE FUNCTIONS

export  function DeleteEquipment(equipmentToDelete: UserEquipment): UserEquipment {
  const equipmentList =  GetOfflineStateParsed("user_equipment");
  let equipment = equipmentList.find(x => x.id == equipmentToDelete.id);

  const now = new Date();
  equipment.expired = 1;
  const status =  AddToQueue("user_equipment", equipment, "update");

  if (status) {
    return equipment;
  }

  return null;
}
//#endregion

//#region HELPER FUNCTIONS

export function ValidateEquipment(equipment: UserEquipment): string[] {
  let errorMessageArray: string[] = [];


  if (equipment.name == '' || equipment.name == null || equipment.name == 'Add New Equipment') {
    errorMessageArray.push('Description cannot be blank');
  }


  if (equipment.bushels_hr_rate == null || isNaN(equipment.bushels_hr_rate) || equipment.bushels_hr_rate < 500 || equipment.bushels_hr_rate > 25000) {
    errorMessageArray.push('Grain Flow cannot be empty, must be between 500 and 25000')
  }



  errorMessageArray.forEach(error => {
    if (error == "") {
      let location = errorMessageArray.indexOf(error);
      errorMessageArray.splice(location, 1);
    }

  });
  return errorMessageArray;
}


export function ValidateGrainFlow(truckLoad: string, unloadTimeMinute: string, unloadTimeSecond: string, manufacturerRating: string): string {
  let errormessage = '';
  let unloadTime = 0.0;
  let flowRate = 0.0;

  if ((unloadTimeMinute == '' || unloadTimeSecond == '') && manufacturerRating == '') {
    errormessage += "Unload time minute and second cannot be blank or the manufactuer rating cannot be blank";
  }
  //some sort of mathematical expression to figure out the unload time
  unloadTime = parseInt(unloadTimeMinute) * 60 + parseInt(unloadTimeSecond);

  //if its 0 or null its not valid
  if (unloadTime == 0 || unloadTime == null) {
    errormessage = "Unload time cannot be 0"
  }
  else {
    //another mathematical expression to figure out the flow rate
    flowRate = (parseInt(truckLoad) / unloadTime) * 3600;

    //round it to an integer, (no decimal places)
    let roundedResult = flowRate.toFixed(0);

    //rounded result returns a string so parse as an int
    let finalResult = parseInt(roundedResult);

    //if the final result is less then 500 or greater then 25000
    //then its not valid
    if (finalResult < 500 || finalResult > 25000) {
      errormessage += "Grain flow rate can only be between 500 and 25000"
    }
  }
  //return the boolean
  return errormessage;
}

export function CalculateGrainFlow(truckLoad: number, unloadTimeMinute: number, unloadTimeSecond: number): number {
  let errormessage = '';
  let unloadTime = 0.0;
  let flowRate = 0.0;

  //some sort of mathematical expression to figure out the unload time
  unloadTime = unloadTimeMinute * 60 + unloadTimeSecond;

  //another mathematical expression to figure out the flow rate
  flowRate = (truckLoad / unloadTime) * 3600;

  //round it to an integer, (no decimal places)
  let roundedResult = flowRate.toFixed(0);

  //rounded result returns a string so parse as an int
  let finalResult = parseInt(roundedResult);

  //return the bushel load/bushel_hr_rate
  
  return finalResult;
}

// TODO: verify this is correct
export async function CheckIfEquipmentWorksWithAutoSampler(equipment: UserEquipment): Promise<boolean> {
  return equipment.sem != null;
}
//#endregion

export async function GetEquipmentLookupData(): Promise<{ equipmentMakeList: EquipmentBrand[], equipmentModelList: EquipmentModel[], SEMEquipmentList: SemEquipment[] }> {
  const makes = await GetOfflineStateParsed("equipment_brands");
  const models = await GetOfflineStateParsed("equipment_models");
  const sem = await GetOfflineStateParsed("equipment_sem");


  return { equipmentMakeList: makes, equipmentModelList: models, SEMEquipmentList: sem };
}

export async function GetEquipmentModelCount(profile: string, equipmentModelID: number): Promise<number> {
  if (equipmentModelID == null) return 0;
  
  const equipment_list = (await GetOfflineStateParsed("user_equipment")).filter(x => x.equipment_model_id === equipmentModelID && x.profile === profile);

  return equipment_list.length;
}