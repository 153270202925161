import { ApiResponse } from "../../GeneralTypes";
import { ApiCall } from "../GeneralFunctions";

/**
 * **ONLINE ONLY** - Sends an email to the buyer.
 * //TODO: Handle offline functionality of this - could be postponed until online
 * @param userEmail Current User's email address
 * @param buyerEmail Email address of the buyer
 * @returns A typical API Response
 */
export async function SendBuyerNotificationEmail(userEmail: string, buyerEmail: string) {
    const status = await ApiCall<ApiResponse>("/api/send-buyer-email", { 'buyer_email': buyerEmail, 'email': userEmail });
    return status;
}

//TODO update this
export async function CreatePendingBuyerRecord(sampleID: Number | undefined, buyerID: number, userID: string, profile: string, sampleType: string, buyerType: string): Promise<number> {
    const formData = new FormData();

    formData.append('SelectedProfile', profile);
    formData.append('SampleID', sampleID.toString());
    formData.append('UserID', userID.toString());
    formData.append('BuyerID', buyerID.toString());
    formData.append('SampleType', sampleType.toString());
    formData.append('BuyerType', buyerType.toString());
    
    //wait until the promise is returned before doing anything
    const status = await ApiCall<ApiResponse & { id: number }>("/api/create-pending-buyer", formData);

    if (status?.response == 0) {
        return status.id;
    }
    else {
        return null;
    }
}