import { GetAsyncDataParsed, GetData, GetOfflineStateParsed, ReplaceDataInOfflineState, StoreAsyncData } from "../../AsyncStorageHelper";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { AllowedSampleGrain, GrainType,GrainSubCrop, UserState } from "../../Storage/UserState";
import { GenerateNumberIDSync } from "../GeneralFunctions";
import { GetGrainAndSubCropTypes } from "../Grains";


//TODO: update function
export async function AddAllowedSampleGrains(allowedSampleGrains: AllowedSampleGrain[]) {
    const profile = await GetData("Profile");
    await RemoveAllAllowedSampleGrains(profile);
    const user_id = await GetData("ID");
    const parsedList: AllowedSampleGrain[] = allowedSampleGrains.map(x => { return {...x, id: GenerateNumberIDSync(user_id), profile, user_id: parseInt(user_id)}});
    const added = await AddToQueue("allowed_sample_grains", parsedList, "add");
    return added;
}

export async function GetAllowedSampleGrains(profile: string): Promise<{ grainList: {grainType:GrainType,grainSubCrop:GrainSubCrop}[]; allowedSampleGrainList: AllowedSampleGrain[]; }> {

    const grain = GetGrainAndSubCropTypes();

    const allowedSampleGrainList = GetOfflineStateParsed('allowed_sample_grains');

    return { grainList: grain.types, allowedSampleGrainList: allowedSampleGrainList };
}

export async function RemoveAllAllowedSampleGrains(profile: string) {
    let allowed_sample_grains = await GetOfflineStateParsed("allowed_sample_grains");
    allowed_sample_grains = allowed_sample_grains.filter(x => x.profile !== profile );
    await ReplaceDataInOfflineState("allowed_sample_grains", allowed_sample_grains);

    //TODO: add removal to queue
}