import React from "react";
import { TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import Icon from 'react-native-vector-icons/FontAwesome';
import { setProp } from "../../../../Helpers/GeneralTypes";
import { CharacteristicSubOption, CharacteristicType, InstrumentCharacteristic } from "../../../../Helpers/Storage/UserState";

type PropTypes = {
    type: CharacteristicType;
    options: CharacteristicSubOption[];
    readonly: boolean;
    selectedChars: CharacteristicSubOption[];
    setSelectedChars: setProp<CharacteristicSubOption[]>;
}

function CharacteristicItem(props: PropTypes) {
    const [isCollapsed, setCollapsed] = React.useState(true);

    React.useEffect(() => {
        setCollapsed(!props.readonly);
    }, [props.readonly]);

    return (
        <>
            <TouchableOpacity accessibilityLabel={props.type.name} onPress={() => {
                setCollapsed(!isCollapsed);
            }} style={{ flexDirection: 'row', paddingVertical: 10 }}>
                <Text style={{ fontSize: 28, flex: 1, left: 10 }}>{props.type.name}</Text>
                <Icon style={{ right: 10 }} name={!isCollapsed ? "minus" : "plus"} size={24} color="#1c448c" />
            </TouchableOpacity>
            {props.options.map((sub) => {
                if (!isCollapsed) {
                    return <TouchableOpacity accessibilityLabel={sub.name} disabled={props.readonly} key={sub.id} onPress={() => {
                        let list = props.selectedChars;
                        const indexed = props.selectedChars.findIndex(x => x.id == sub.id);
                        if (props.selectedChars.find(c =>  c.id == sub.id) != undefined) {                
                            list.splice(indexed,1);
                        }
                        else {
                           
                            list.push(sub);
                        }
                        props.setSelectedChars([...list])
                    }} style={{ flexDirection: 'row', paddingVertical: 20, left: 10 }}>
                        {
                           props?.selectedChars?.find(c =>  c?.id == sub.id) != undefined?
                                <>

                                    <Icon style={{ alignSelf: 'center' }} name="square" size={35} color="orange" />
                                    <Text style={{ fontSize: 20, color: 'orange', paddingLeft: 10, alignSelf: 'center', flexShrink: 1 }}>{sub.name}</Text>
                                </>
                                :
                                <>
                                    <Icon style={{ alignSelf: 'center' }} name="square" size={35} color="#1c448c" />
                                    <Text style={{ fontSize: 20, color: 'black', alignSelf: 'center', paddingLeft: 10, flexShrink: 1 }}>{sub.name}</Text>
                                </>
                        }


                    </TouchableOpacity>
                }
                else {
                    return null;
                }

            })}
        </>
    );
}


export default React.memo(CharacteristicItem);