import React, { useContext } from "react";
import { View, Text, TextInput, Button, StyleSheet, ImageBackground ,TouchableOpacity, DeviceEventEmitter,Image, Alert, Linking, ScrollView, Dimensions, Platform, KeyboardAvoidingView, Keyboard } from 'react-native';
import { Overlay } from "react-native-elements";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import Icon5 from 'react-native-vector-icons/FontAwesome5';
import Icon from 'react-native-vector-icons/FontAwesome'
import { FlatList } from "react-native-gesture-handler";
import Toast from "react-native-tiny-toast";
import moment from "moment";
import {CombinedSampleRecord, MasterSample, SubSample } from "../../../Helpers/Storage/UserState";
import ViewSampleList from "./ViewSampleList";
import { ApiCall } from "../../../Helpers/Functions/GeneralFunctions";
import { ApiResponse } from "../../../Helpers/GeneralTypes";
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';
import { useIsFocused } from "@react-navigation/native";
import { defaultMasterSample } from "../../../Helpers/Storage/DefaultStates";
import { SaveCombinedSampleRecords } from "../../../Helpers/Functions/Offline/CombinedSampleRecordFunctions";
import AppContext from "../../../Contexts/AppContext";
import { GetLocalUserManual } from "../../../Helpers/Functions/Online/UserFunctions";
import { GetSampleRecords } from "../../../Helpers/Functions/Offline/SampleFunctions";
import VGButton from "../../../Components/Button/Button";
import Colours from "../../../Styles/Colours";
import VGInput from "../../../Components/Input/Input";
import BarcodeReader from "../../HelperScreens/BarcodeReader";
import spacing from "../../../Styles/Spacing";

function CombineSamplesScreen({route, navigation }: {route:any, navigation: any })
{
    const appContext = useContext(AppContext);
    const isFocused = useIsFocused();
    React.useEffect(() => {
      if(isFocused)
      {
        const getPages = async() => {
                                        
            let data =await GetLocalUserManual();
            if(data != null)
            {
              appContext.setCurrentScreenName(data.find(x => x.screen_name == 'CombineSample'))
            }
            else
            {
              appContext.setCurrentScreenName(learnMoreLookupData.find(x => x.screen_name=='CombineSample'));
            }
          };
          getPages(); 
              }
    },[isFocused])
    const enum dataPick {
        ViewSampleList,
        MainScreen,
        BarcodeEntry,
        BarcodeScanner
    };
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height
    const sampleNumpadRef = React.useRef(null);

const [masterSample,setMasterSample] = React.useState<MasterSample>({...defaultMasterSample});
    const [barcodeNumber,setBarcodeNumber] = React.useState('');

        const [combinedSampleRecords,setCombinedSampleRecords] = React.useState<CombinedSampleRecord[]>([]);
const [currentState,setCurrentState] = React.useState<dataPick>(dataPick.MainScreen);
const [barcodeStatusColor,setBarcodeStatusColor] = React.useState('');
const [barcodeLookup,setBarcodeLookup] = React.useState<{master_sample_id:number,barcode:number}[]>([]);
const [currentSampleList,setCurrentSampleList] = React.useState<MasterSample[]>([]);


//Check if the combined barcode number is set, if so, display pop-up to user to add a barode
React.useEffect(() => {

    //Does it match 8 in length and starts with a 1,2,3,4,or 6?
    if(barcodeNumber.length == 8 && barcodeNumber.match(/^([1-4]{1}[0-9]{7})|([6]{1}[0-9]{7})$/) != null )
    {

        const checkBarcode = async () => {

            const profile = await GetData('Profile');
            const sampleRecords = await GetSampleRecords('Barcode',barcodeNumber,null,profile,null)
            
            if(sampleRecords != null)
            {
               
              setCurrentSampleList(sampleRecords.masterSamples.filter(x => x.barcode.toString() == barcodeNumber));
                setCurrentState(dataPick.ViewSampleList);
            
            }
        }
        checkBarcode();



    }
},[barcodeNumber])



React.useEffect(() => {

    if(masterSample?.barcode == 0 || masterSample == null)
    {
        setBarcodeStatusColor('lightgrey');
    }
    else if(masterSample?.barcode.toString().length == 8)
    {
        let barStatus = CheckValidBarcode(masterSample.barcode.toString());

        if(barStatus != 0 && barStatus != undefined)
        {
            let oldCombinedRecord = masterSample;

           
            console.log(barStatus);
            setBarcodeStatusColor('green');

            switch(barStatus)
            {
                case 1:
                    setMasterSample(prevState =>{
                        return{
                             ...prevState,
                             container_size : '2 Gal'
                        }
                     })
                    break;
                case 2: 
                setMasterSample(prevState =>{
                    return{
                         ...prevState,
                         container_size : '3.5 Gal'
                    }
                 })
                break;
                case 3: 
                setMasterSample(prevState =>{
                    return{
                         ...prevState,
                         container_size : '5 Gal'
                    }
                 })
                break;
                case 4: 
                setMasterSample(prevState =>{
                    return{
                         ...prevState,
                         container_size : '1 Kg'
                    }
                 })
                break;
            }
        }
        else
        {

            setMasterSample(prevState =>{
                return{
                     ...prevState,
                     barcode : 0
                }
             })
            setBarcodeStatusColor('red'); 
        }
        
    }
    else if(masterSample?.barcode.toString().length > 8)
    {

        setMasterSample(prevState =>{
            return{
                 ...prevState,
                 barcode : parseInt((prevState.barcode).toString().slice(0, -1))
            }
         })
    }
    
    else
    {
        setBarcodeStatusColor('red');
    }
},[masterSample?.barcode])


React.useEffect(() => {
    if(currentState == dataPick.MainScreen)
    {
    
        setBarcodeNumber('');
    }
},[currentState])

function onBarcodeRead(data){
    setMasterSample({...masterSample,barcode:parseInt(data)});
    setCurrentState(dataPick.MainScreen);
}

function onBarcodeEntryRead(data)
{
    setBarcodeNumber(data);
}

    async function onSavePress(){

        const profile = await GetData('Profile');
        const userID = await GetData('ID');
        let curRecord = masterSample;
        curRecord.profile = profile;
        curRecord.user_id = parseInt(userID);
        const status = await SaveCombinedSampleRecords(curRecord,combinedSampleRecords);
        if(status != null)
        {
            Toast.show('Combined sample');
            navigation.navigate("MainActivityNavigation");
        }
        else
        {
            appContext.setAlertOptions({title:'Error',desc:'Could not combine samples',options:[{text:'OK', role:'PRIMARY', onPress:() => {appContext.setShowAlert(false);}}]})
            appContext.setShowAlert(true);
        }

    }

    return (

        // TODO rework this page
        <View style={spacing.MainContainer}>

            {/* Overlays */}
            {currentState == dataPick.BarcodeScanner ? <BarcodeReader isNumeric={true} onBarcodeRead={onBarcodeRead} setPickingData={()=>{setCurrentState(dataPick.MainScreen)}} /> : null}
            {currentState == dataPick.BarcodeEntry ? <BarcodeReader isNumeric={true} onBarcodeRead={onBarcodeEntryRead} setPickingData={()=>{setCurrentState(dataPick.MainScreen)}} /> : null}

            <VGInput label="Combined Sample ID" type="picker" icon="barcode"
                onPress={()=>setCurrentState(dataPick.BarcodeScanner)} 
                value={masterSample?.barcode != null && masterSample?.barcode != 0 ? masterSample.barcode.toString() : ""}
            />

<VGInput label="Sample Size" type="text" 
             onChangeText={(text)=>{}}
             editable={false}
            value={masterSample?.container_size?? ""}
            />

            {/* TODO from here */}
            <VGInput label="Add Sample to Combine" type="picker" icon="barcode"
    onPress={()=>setCurrentState(dataPick.BarcodeEntry)}
    value={""}
/>

            <ScrollView contentContainerStyle={{flexGrow:1,justifyContent:'space-evenly' }}> 



            <View style={{flexDirection:'column',   justifyContent: 'flex-start',borderColor:'grey', borderWidth:2,marginTop:20,flex:1}}>

        <View style={{flexDirection:'row',justifyContent: 'flex-start', alignItems: 'center',marginVertical:5}}>
            <Text style={{marginLeft:10,fontSize:16}}>Combined Samples</Text>

        </View>

<FlatList
 keyExtractor={(item,index) =>  index.toString()}
      data={combinedSampleRecords}
      renderItem={({ item,index }: { item: CombinedSampleRecord, index:number })  => 

      <>
      {item != undefined ?
      <View style={{flex:1,flexDirection:'row',marginVertical:5}}>

<View style={{flex:1}}>
<Image style={{width:30, height:30,borderRadius:5,
    overflow: 'hidden',left:10}} source={require ('../../../Icons/BlueCombine.png')} />
       
</View>

      <Text style={{ marginRight:20,fontSize:18,alignSelf:'center'}}>
        {/* Barcode look up has either the sample or sub sample records master ID, check that against the parent_sample_id to get the barcode# */}
      {barcodeLookup?.find(x => x.master_sample_id == item.parent_sample_id)?.barcode??''}
        </Text>
      <TouchableOpacity style={{marginRight:20}} onPress={() => {
                          let oldList = combinedSampleRecords;
                          oldList.splice(index,1);
                          setCombinedSampleRecords([...oldList]);
                            }}>
                                          <Icon  name="minus-square" size={35} color={Colours.ERROR} />
                            </TouchableOpacity>
  </View>
      : null}
      </>
 
}
>

</FlatList>

</View>


<View style={{flex:1, justifyContent:'flex-end', bottom:10}}>


<VGButton role="PRIMARY" disabled={ combinedSampleRecords.length == 0 }   onPress={onSavePress}
>Save</VGButton>


</View>



{currentState == dataPick.ViewSampleList ? <ViewSampleList barcodeLookup={barcodeLookup} setBarcodeLookup={setBarcodeLookup} currentState={currentState == dataPick.ViewSampleList} sampleList={currentSampleList} combinedSampleRecords={combinedSampleRecords} setCombinedSampleRecords={setCombinedSampleRecords} setCurrentState={setCurrentState}  /> : null}









    </ScrollView>
    </View>

    )


}

//This function will check if the barcode already exists in the DB, if so we can add it to our list
//if multiple of the same barcode exists, then we got the newest one
async function CheckIfBarcodeExists(barcode:string)
{

    const profile = await GetData('Profile');

    if(profile != null)
    {

         //append the data to a link (look at the android project for that link)
        const link =  "https://webservice.verigrain.com/api/check-if-record-exists?Profile=" + profile + '&Barcode=' + barcode ;

        console.log(link);
        //Token header to keep requests secure
        var myHeaders = new Headers();
        myHeaders.append(
          'token',
          '$2y$12ri75v9amOGZgTs2oTK3mZe1YEOburB7BJfJ64M9QKAM5YTggvoPVa',
        );
    //grab the api and check if email exists
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
    };
      const loginStatus = () => fetch(link, requestOptions)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      .catch(error => {
        console.log('error', error);
        return 'error';
      });

    //wait until the promise is returned before doing anything
    const status = await loginStatus();

 return status;
    }
}

//This function will save the barcode list into 1 combined barcode sample
async function SaveCombinedSample(masterSample: MasterSample ,scannedList:{sample:MasterSample|null,subSample:SubSample|null}[]) {


    const formData = new FormData();
    formData.append('combined_record',JSON.stringify(masterSample, (k, v) => v ?? undefined));
    
    const primeSamples = scannedList.filter(x => x.sample != null).map(x => {return x.sample});
    const subSamples = scannedList.filter(x => x.subSample != null).map(x => {return x.subSample});;
    formData.append('primary_samples',JSON.stringify(primeSamples, (k, v) => v ?? undefined));
    formData.append('sub_samples',JSON.stringify(subSamples, (k, v) => v ?? undefined));

    const stat = await ApiCall<ApiResponse & { combined:MasterSample}>("https://webservice.verigrain.com/api/create-combined-sample-record", formData,true);


    if(stat.response == 0)
    {
        return true;
    }
    else
    {
        return false;
    }

}


//will check if its a valid barcode
function CheckValidBarcode(barcode: string)
{


    //If its a valid barcode (8 in length, no characters)
    if(barcode.length == 8 && barcode.match(/^([1-4]{1}[0-9]{7})|([6]{1}[0-9]{7})$/) != null )
    {


            if(barcode.charAt(0) == "1")
            {
                return 1;
            }
            else if(barcode.charAt(0) == "2")
            {605
                return 2;
            } 
            else if(barcode.charAt(0) == "3")
            {
                return 3;
            }
            else if(barcode.charAt(0) == "4" || barcode.charAt(0) == "6")
            {
                return 4;
            }


        }

    

}



export default CombineSamplesScreen;



