import Toast from "react-native-tiny-toast";
import { GetData, GetOfflineStateParsed, RemoveDataInOfflineState, SaveDataToOfflineState, StoreAsyncDataUnsafe } from "../AsyncStorageHelper";
import { AddToQueue, GetOfflineQueue, SaveQueueLocally } from "../Storage/OfflineQueue";
import { GrainSubCrop, GrainSupplier, GrainType, GrainVariety,CropSelectOption, UserGrain, UserStateKey } from "../Storage/UserState";
import { GenerateNumberID, GroupBy } from "./GeneralFunctions";



//This function will Grab the grainTypes, grainSubcrops, and grain varieties
//Will return in a slightly different format compared to the DB as it is usually faster
//when dealing with 1000+ items in a list
export function GetGrainAndSubCropTypes(): {
    types:{
        grainType:GrainType,
        grainSubCrop:GrainSubCrop|null,
    }[],
    varieties:GrainVariety[]
}
{
    let currentGrainTypeList : {
        grainType:GrainType,
        grainSubCrop:GrainSubCrop|null,
    }[] = [];

    let currentGrainVarietyList:GrainVariety[] = [];
    const profile = GetData('Profile');
    //First group the cropSelectOption
    const cropSelectOptions = GetOfflineStateParsed('crop_select_options');

    var groupedGrains = GroupBy(cropSelectOptions, function (item) {
        return [item.grain_type_id, item.grain_sub_crop_id];
      });

      const grainTypes =  GetOfflineStateParsed("grain_types").filter(x =>(x.profile == profile || x.profile == null) );
 
      const grainSubCrops =  GetOfflineStateParsed('grain_sub_crops').filter(x =>(x.profile == profile || x.profile == null) );
      const grainVarieties = GetOfflineStateParsed('grain_varieties').filter(x =>(x.profile == profile || x.profile == null) );

      groupedGrains.forEach((group,index) => {

        let grainHeaderSelection: {
            grainType:GrainType,
            grainSubCrop:GrainSubCrop|null,
        };

            if(group[0] != null)
            {

                const grainType = grainTypes.find(x => x.id == group[0].grain_type_id);
                const grainSubCrop = grainSubCrops.find(x => x.id == group[0].grain_sub_crop_id);

                if(grainType != null && grainSubCrop != null)
                {
                    grainHeaderSelection = {grainType:grainType,grainSubCrop:grainSubCrop};
                    currentGrainTypeList.push(grainHeaderSelection);
                }
             
               
            }
        

      })

  
      const sorted = [...currentGrainTypeList];
      sorted.sort((a,b) => {
        return a.grainType.name.localeCompare(b.grainType.name);
      });

    return {types:sorted,varieties:grainVarieties};

}

/**
 * 
 * @param variety - the grain variety to check against
 * @returns a list of error messages 
 */
export function ValidateVariety(variety:GrainVariety):string[]
{
    let errorMessages:string[] = [];
    if(variety?.name == '' || variety?.name == null)
    {
        errorMessages.push('Name cannot be empty');
    }
    return errorMessages;
}
export function ValidateSupplier(grainSupplier?:GrainSupplier):string[]
{
    let errorMessages:string[] = [];


    return errorMessages;
}

export function ValidateGrain(grainType:GrainType,grainSubCrop:GrainSubCrop)
{
    let errorMessages:string[] = [];
    if(grainType?.name == '' || grainType?.name == null)
    {
        errorMessages.push('Name cannot be empty');
    }

    return errorMessages;
}

/**
 * 
 * @param grainType - The Grain Type to save
 * @param grainSubCrop = The Grain Sub Crop to save
 * @param addedVarieties - The list of varieties to save
 */
export async function SaveGrainSelection(grainType:GrainType,grainSubCrop:GrainSubCrop|null,addedVarieties:GrainVariety[],addingCustomType:boolean,addingCustomSubCrop:boolean):Promise<{ grainType: GrainType; grainSubCrop: GrainSubCrop; grainVarieties: GrainVariety[]; cropSelectOptions: CropSelectOption[];userGrains:UserGrain[];  }>|null
{
    const profile = await GetData('Profile');

    let grainTypeToUse = {...grainType};
    let grainSubCropToUse = {...grainSubCrop};
    let grainTypeStatus = true;
    let grainSubStatus = true;
    if(addingCustomType)
    {
        const stat = await AddGrainTypeToQueue(grainType,profile);
        grainTypeStatus = stat.status;
        grainTypeToUse = stat.grainType;
    }



    console.log('\nGrain Type' + grainTypeStatus);
    if(grainTypeStatus == true)
    {
        if(addingCustomSubCrop)
        {
            const stat =  await AddGrainSubCropToQueue(grainSubCrop,profile);
            grainSubStatus = stat.status;
            grainSubCropToUse = stat.grainSubCrop;

        }
       
        if(grainSubStatus == true)
        {
            const grainVarietyStatus = await AddGrainVarietiesToQueue(addedVarieties,profile,grainTypeToUse.id,grainSubCropToUse.id);
            console.log('\nGrain Variety ' + grainVarietyStatus.status);
            if(grainVarietyStatus.status)
            {
                return {grainType:grainTypeToUse,grainSubCrop:grainSubCropToUse,grainVarieties:grainVarietyStatus.grainVarieties,cropSelectOptions:grainVarietyStatus.cropSelectOptions,userGrains:grainVarietyStatus.userGrains};

            }
        }
    }


}



/**
 * 
 * @param grainType The grain type to add to queue
 * @param profile - The profile of the user
 * @returns The status (boolean) and the offline created grain type
 */
async function AddGrainTypeToQueue(grainType: GrainType,profile:string):Promise<{status:boolean,grainType:GrainType|null}>{
    let status = true;
    let modifyGrainType = {...grainType};

    //if the id is less than -10 (A valid offline record)
    if(grainType.id < -10)
    {
        modifyGrainType.profile = profile;
        modifyGrainType.id = await GenerateNumberID();
        status = await AddToQueue('grain_types',modifyGrainType,'add');
    }
    return {status:status,grainType:modifyGrainType};
}


/**
 * 
 * @param grainSubCrop The Grain Sub Crop to add to queue
 * @param profile - The profile of the user
 * @returns The status (boolean) and the offline created grain sub crop
 */
async function AddGrainSubCropToQueue(grainSubCrop: GrainSubCrop, profile: string):Promise<{status:boolean,grainSubCrop:GrainSubCrop|null}> {
    let status = true;
    let modifyGrainSubCrop = {...grainSubCrop};
    if(grainSubCrop.id < -10)
    {
        modifyGrainSubCrop.profile = profile;
        modifyGrainSubCrop.id = await GenerateNumberID();
        status = await AddToQueue('grain_sub_crops',modifyGrainSubCrop,'add');
    }
    return {status:status,grainSubCrop:modifyGrainSubCrop};
}


/**
 * 
 * @param grainVarieties A list of grain varieties to add
 * @param profile - The profile of the user
 * @param grainTypeID - The Grain Type ID (Used to create a crop selection record)
 * @param grainSubcropID - The Grain Sub Crop ID (Used to create a crop selection record)
 * @returns The status(boolean), the new crop selection list and the list of created varieties (generated)
 */
async function AddGrainVarietiesToQueue(grainVarieties:GrainVariety[],profile:string,grainTypeID:number,grainSubcropID:number):Promise<{status:boolean,grainVarieties:GrainVariety[]|null,cropSelectOptions:CropSelectOption[],userGrains:UserGrain[]}>
{
    let status = true;
    let cropSelection:CropSelectOption[] = [];
    let userGrains:UserGrain[] = [];
    let modifyVarieties = [...grainVarieties];


    for(let x of modifyVarieties)
    {
        x.profile = profile;
        x.id = await GenerateNumberID();
        
        cropSelection.push({id:await GenerateNumberID(),grain_type_id:grainTypeID,grain_sub_crop_id:grainSubcropID,grain_variety_id:x.id,updated_at:new Date()});
    }

    if(modifyVarieties.length != 0)
    {
        const varietyAddedStatus:GrainVariety[] = await AddToQueue('grain_varieties',modifyVarieties,'add');
        if(varietyAddedStatus.length != 0)
        {
          
            status = false;
        }
    }
    else
    {
        cropSelection.push({id:await GenerateNumberID(),grain_type_id:grainTypeID,grain_sub_crop_id:grainSubcropID,grain_variety_id:null,updated_at:new Date()});

    }

    const cropSelectionStatus = await AddToQueue('crop_select_options',cropSelection,'add');

        if(cropSelectionStatus.length != 0)
        {
            status = false;
        }
     
        for(let sel of cropSelection)
        {
            userGrains.push({grain_type_id:sel.grain_type_id,grain_sub_crop_id:sel.grain_sub_crop_id,grain_variety_id:sel.grain_variety_id,profile:profile,updated_at:new Date(),id:await GenerateNumberID()});
        }
        const userGrainStatus = await AddToQueue('user_grains',userGrains,'add');
        if(userGrainStatus.length != 0)
        {
            status = false;
        }

        return {status:status,grainVarieties:modifyVarieties,cropSelectOptions:cropSelectionStatus,userGrains:userGrains};
    }




/**
 * 
 * @param cropSelectOptions A full list of cropSelectOptions
 * @param grains A full list of grains
 * @param varieties A full list of grain varieties
 * @returns A list of all checked options for cropSelecton
 */
export function GrabCropSelectOptionFromUserSelection(cropSelectOptions:CropSelectOption[], grains: { grainType: GrainType; grainSubCrop: GrainSubCrop;}[], varieties: GrainVariety[]):CropSelectOption[] {
    
    let cropSelectionList:CropSelectOption[] = [];
    
    //For each grain
        grains.forEach(grain => {

                //Push a crop selection to list
                cropSelectionList.push({grain_type_id:grain.grainType.id,grain_sub_crop_id:grain.grainSubCrop.id,grain_variety_id:null,id:-2,updated_at:new Date()});


        

                //For each variety
                varieties.forEach(variety => {
                    
                    //If its found in crop select options (IT should, if it doesnt it might be a user requested grain and must be fixed)
                    const cropSelectionIndex = cropSelectOptions.findIndex(x => x.grain_type_id == grain.grainType.id && x.grain_sub_crop_id == grain.grainSubCrop.id && x.grain_variety_id == variety.id);
                    if(cropSelectionIndex != -1)
                    {
                        //push to list
                        cropSelectionList.push(cropSelectOptions[cropSelectionIndex]);
                    }
                })
            
        })
        return cropSelectionList;
  }


  /**
   * 
   * @param cropSelectOptions A full list of crop selection option (type + sub + variety)
   * @param grains A list of SELECTED grains (type + sub)
   * @param varieties A list of SELECTED Varieties (variety)
   * @returns 2 object arrays used to add/remove items from a selected list (basically checks or unchecks items in the master list)
   */
  export async function SaveUserGrains(cropSelectOptions:CropSelectOption[], grains: { grainType: GrainType; grainSubCrop: GrainSubCrop;}[], varieties:GrainVariety[],fromSample:boolean = false):Promise<{ userGrainToRemove: UserGrain[]; userGrainToAdd: UserGrain[]; }|null>
  {

    //Grab user profile
    const profile = await GetData('Profile');

    //Based on the selected grains and varieties, attempt to grab a list of crop selection items
    const cropSelection = GrabCropSelectOptionFromUserSelection(cropSelectOptions,grains,varieties);

    //Grab all user grains
        const userGrains = GetOfflineStateParsed('user_grains');

        const currentQueueStatus = GetOfflineQueue();
        //Create 2 variable arrays to determine what to add/remove
        let userGrainsToAdd:UserGrain[] = [];
        let userGrainsToRemove:UserGrain[] = [];

        //for each selection
        for(const selection of cropSelection)
        {
            //console.log('-----Crop Selection-----\n\n' + 'Type: ' + selection.grain_type_id + '\nSub: ' + selection.grain_sub_crop_id + '\nVar:' + selection.grain_variety_id);
            let queueStatus = -1;
            if(currentQueueStatus?.user_grains != null)
            {
                queueStatus = currentQueueStatus?.user_grains?.findIndex(x => x.grain_type_id == selection.grain_type_id && x.grain_sub_crop_id == selection.grain_sub_crop_id && x.grain_variety_id == selection.grain_variety_id);

            }

            //Try and find the index based on (type + sub + variety)
            const index = userGrains.findIndex(x => x.grain_type_id == selection.grain_type_id && x.grain_sub_crop_id == selection.grain_sub_crop_id && x.grain_variety_id == selection.grain_variety_id)
            //If not found add to list
            if(index == -1 && queueStatus == -1)
            {
                console.log('Adding');
                //push to the user grain list
                userGrainsToAdd.push({grain_type_id:selection.grain_type_id,grain_sub_crop_id:selection.grain_sub_crop_id,grain_variety_id:selection.grain_variety_id,id:await GenerateNumberID(),profile:profile,updated_at:new Date()});
            }
            else
            {
                console.log('Already Added');
            }
        }

        
        //For each user grain
        userGrains.forEach(userGrain => {

            let queueStatus = -1;
            if(currentQueueStatus?.user_grains != null)
            {
                queueStatus = currentQueueStatus?.user_grains?.findIndex(x => x.grain_type_id == userGrain.grain_type_id && x.grain_sub_crop_id == userGrain.grain_sub_crop_id && x.grain_variety_id == userGrain.grain_variety_id);

            }
            //If a userGrain is not found in the cropSelection, we can remove it
            const removalIndex = cropSelection.findIndex(x => x.grain_type_id == userGrain.grain_type_id && x.grain_sub_crop_id == userGrain.grain_sub_crop_id && x.grain_variety_id == userGrain.grain_variety_id);
            
            if(removalIndex == -1 && queueStatus == -1)
            {
                console.log('Removing');
                userGrainsToRemove.push(userGrain);
            }
            else
            {
                console.log('Already Removed OR grain was added');
            }
        });

        const failedAdd = await AddToQueue('user_grains',userGrainsToAdd,'add');

        const failedDelete = await AddToQueue('user_grains',userGrainsToRemove,'perm_delete');
 
        if(failedAdd.length > 0 || failedDelete.length > 0)
        {
            console.log('Something has failed, removing everything from queue');
            return null;
        }
        else
        {
            console.log('Successfully added to queue');

            Toast.show('SUCCESS!');
            return {userGrainToAdd:userGrainsToAdd,userGrainToRemove:userGrainsToRemove};
        }
  }


  /**
   * 
   * @param cropSelection The current user list
   * @param grains - A list of grains to check
   * @returns - A list of grains to add to the User Selection list
   */
  export function GetSelectedGrains(cropSelection:UserGrain[],grains:{
    grainType: GrainType;
    grainSubCrop: GrainSubCrop;

}[])
{
    let selectedGrains:{
        grainType: GrainType;
        grainSubCrop: GrainSubCrop;
 
    }[] = [];

    //For each user selected grain
    cropSelection.forEach(crop => {

        //Grab the index based on (Type + Sub)
        const index = grains.findIndex(x => x.grainType.id == crop.grain_type_id && x.grainSubCrop.id == crop.grain_sub_crop_id);
        
        //If it doesnt exist
        if(index != -1 )
        {
            //Also check if combination already exists!
            const selectIndex = selectedGrains.findIndex(x => x.grainType.id == grains[index].grainType.id && x.grainSubCrop.id == grains[index].grainSubCrop.id);
            if(selectIndex == -1)
            {
                //add to list
                selectedGrains.push(grains[index]);
            }
           
        }
    })

    return selectedGrains;

}

export function GetSelectedVarieties(userGrains: UserGrain[], varieties: GrainVariety[]):GrainVariety[] {

    let selectedList:GrainVariety[] = [];

        userGrains.forEach(userGrain => {
            const grainIndex = varieties.findIndex(x => x.id == userGrain.grain_variety_id)
            if(grainIndex != -1)
            {
                selectedList.push(varieties[grainIndex]);
            }
        })
    return selectedList;

  }
  


//This function will update the master list (Check/uncheck) 
//IS THIS NEEDED???? I'm actually going crazy.
export function UpdateMasterList(
    userGrains: { 
        userGrainToRemove:UserGrain[];
         userGrainToAdd: UserGrain[]; },
    grains:{
        grainType:GrainType,
        grainSubCrop:GrainSubCrop|null,}[],
    varieties:GrainVariety[],
    selectedGrains: {
        grainType: GrainType;
        grainSubCrop: GrainSubCrop; }[],
    selectedGrainsVarieties: GrainVariety[]):
    {
        selectedGrains:{
            grainType: GrainType;
            grainSubCrop: GrainSubCrop; }[],
            selectedVarieties:GrainVariety[]
    }
{
    let currentSelectedGrains = [...selectedGrains];
    let currentSelectedVarieties = [...selectedGrainsVarieties];

    userGrains.userGrainToAdd.forEach(grainAdded => {
        const grain = grains.find(x => x.grainType.id == grainAdded.grain_type_id && x.grainSubCrop.id == grainAdded.grain_sub_crop_id);
        if(grain != null)
        {
            currentSelectedGrains.push(grain);
        }
    })

    userGrains.userGrainToRemove.forEach(grainRemoved => {
        const grainIndex = grains.findIndex(x => x.grainType.id == grainRemoved.grain_type_id && x.grainSubCrop.id == grainRemoved.grain_sub_crop_id);
        if(grainIndex != -1)
        {
            currentSelectedGrains.splice(grainIndex,1);

        }
    })

    return {selectedGrains: currentSelectedGrains,selectedVarieties:currentSelectedVarieties}
}

export async function SaveGrainSupplier(customSupplier: GrainSupplier):Promise<GrainSupplier|null> {
        
    const profile = await GetData('Profile');
    let sup = {...customSupplier};
    sup.id = await GenerateNumberID();
    sup.profile = profile;

    const saveSupplierStatus = await AddToQueue('grain_suppliers',sup,'add');
    if(saveSupplierStatus)
    {
        return sup;
    }
    return null;
}
