import {  UserEquipment } from "../../../Helpers/Storage/UserState";

import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList, KeyboardAvoidingView, Platform} from 'react-native';
import { Overlay } from "react-native-elements";
import { addUserEquipmentSelector } from "../../../Helpers/Storage/DefaultStates";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import AddEquipmentScreenUpdated from "../../DrawerScreens/Equipment/AddEquipmentScreenUpdated";
import VGButton from "../../../Components/Button/Button";

// TODO: See if you can use this component in both the new sample and sample search screens
function EquipmentSamplePicker(props:{equipmentList:UserEquipment[],setEquipmentList?:any,setPickingData:any,pickingData:boolean,currentEquipment?:UserEquipment,setCurrentEquipment?:any})
{
    var width = Dimensions.get('window').width; //full width
    var height = Dimensions.get('window').height; //full height

    const [addingNewEquipment,setAddingNewEquipment] = React.useState(false);
    let renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: width,
              backgroundColor: "orange",
            }}
          />
        );
      };

    return (

        <>

         <Overlay  isVisible={props.pickingData}  onBackdropPress={() => {props.setPickingData('')}}>
         <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding':'height'}>
         {!addingNewEquipment ?
         <View style={{width:width/1.2,height:height/1.2}}>
         
             <View style={{flexDirection:'row',alignSelf:'center'}}>
                 <Text style={{alignSelf:'center',fontSize:28}}>Select Equipment</Text>
             </View>
           
             <FlatList 
                 ItemSeparatorComponent={renderSeparatorView}
                 contentContainerStyle={{justifyContent:'space-evenly',alignContent:'flex-end'}}
                 keyExtractor={(item,index) => index.toString()}
                 data={props.equipmentList}
                 renderItem={({ item }) => 
                     <TouchableOpacity accessibilityLabel={item.name} onPress={() => {

                             let oldEquip = props.currentEquipment;
                             oldEquip= item;
                             props.setCurrentEquipment({...oldEquip})
                             props.setPickingData(false);
                         
        
                     }}>
                         <Text style={{fontSize:24,padding:10}}>{item.name}</Text>
                     </TouchableOpacity>
                 }
                 >
             </FlatList>
         
             <VGButton role="PRIMARY" onPress={() => { setAddingNewEquipment(true);}}
               >Add Equipment</VGButton>
               <VGButton role="CANCEL" onPress={() => {props.setPickingData('')}}
               >Cancel</VGButton>

         </View>
                       :
                       <View style={{width:width/1.2,height:height/1.2}}>
                    <AddEquipmentScreenUpdated currentEquipment={props.currentEquipment} setCurrentEquipment={props.setCurrentEquipment} equipmentList={props.equipmentList} setEquipmentList={props.setEquipmentList} setStateSelection={props.setPickingData} />                     
                        </View>
                        }

         
         
       </KeyboardAvoidingView>
         </Overlay>
         
        </>
      
    )
}
export default EquipmentSamplePicker