import * as React from 'react';
import { DrawerActions, useIsFocused } from '@react-navigation/native';
import { GetData } from '../../../Helpers/AsyncStorageHelper';
import { View, Text,  TouchableOpacity, DeviceEventEmitter, Dimensions, Alert, Image, FlatList, ScrollView } from 'react-native';
import Toast from 'react-native-tiny-toast';

import Icon from 'react-native-vector-icons/FontAwesome';
import { CalculateGrainFlow, DeleteEquipment, GetUserEquipment, GetMasterEquipmentData, RestoreDeletedEquipment, SaveEquipment, UpdateEquipment } from '../../../Helpers/Functions/Offline/EquipmentFunctions';
import SEMModal from '../../HelperScreens/SEMModal';
import { UserEquipment } from '../../../Helpers/Storage/UserState';
import Spinner from 'react-native-loading-spinner-overlay';
import styles from '../../../Styles/styles';
import { addUserEquipmentSelector, defaultUserEquipment } from '../../../Helpers/Storage/DefaultStates';
import { safeParseInt } from '../../../Helpers/Functions/GeneralFunctions';
import { useContext } from 'react';
import { learnMoreLookupData } from '../../../Helpers/Storage/LearnMoreInfoLookup';
import AppContext from '../../../Contexts/AppContext';
import { GetLocalUserManual } from '../../../Helpers/Functions/Online/UserFunctions';
import AddEquipmentScreenUpdated from './AddEquipmentScreenUpdated';
import spacing from '../../../Styles/Spacing';
import VGButton from '../../../Components/Button/Button';
import RestoreEquipmentScreen from './RestoreEquipmentScreen';

function ListEquipments({ navigation }: { navigation: any }) {

const isFocused = useIsFocused();
    DeviceEventEmitter.addListener('OpenMenu', OpenDrawer);
    function OpenDrawer() {
        navigation.dispatch(DrawerActions.openDrawer())
    }

    const [equipment, setEquipment] = React.useState<UserEquipment[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [mode, setMode] = React.useState<'list' | 'add' | 'view'>('list');

    const [selectedEquipment,setSelectedEquipment] = React.useState<UserEquipment>({...defaultUserEquipment})
    enum  stateSelections{
        default,
        addingEquipment,
        updatingEquipment,
        restoringEquipment,
    }
    const [stateSelection,setStateSelection] = React.useState<stateSelections>(stateSelections.default);


    React.useEffect(() => {

        const GrabEquipmentData = async () => {
            setIsLoading(true);
            
            try {
                let data =  GetUserEquipment();
                setEquipment(data);
            }
            catch { Toast.show('No Equipment could be found') }



            setIsLoading(false);
        }

        GrabEquipmentData();
    }, [])


    React.useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            }, 5000);
        }
     
    }, [isLoading])

    React.useEffect(() => {
        if(!isFocused)
        {
            setStateSelection(stateSelections.default);
        }
        console.log('FOCUSING: ' + isFocused);
    },[isFocused])


    function UpdateRender(restoredEquip: UserEquipment[]) {
        setStateSelection(stateSelections.default);
        let equipList = equipment;
        restoredEquip.forEach(equip => {
            const index = equipment.findIndex(x => x.id == equip.id);
            if(index != -1)
            {
                equipList[index] = equip;
            }
        });
        setEquipment(equipList);
    }

    return (
        <View style={{ flex: 1 }}>
      <Spinner
                visible={isLoading}
                textContent={'Loading...'}
                cancelable={false}
                textStyle={styles.spinner}
            />

            {stateSelection == stateSelections.default ?
               <View style={spacing.MainContainer}>
               <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                   <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Handling Equipment</Text>
               </View>
               <FlatList
                   data={equipment.filter(x => x.expired == 0 || x.expired == null)}
                   renderItem={({ item }:any) =>
                               <TouchableOpacity
                                accessibilityLabel={item.name}
                                   style={{ marginVertical:3 }}
                                   onPress={() => {
                                       setSelectedEquipment(item);
                                        setStateSelection(stateSelections.updatingEquipment)
                                   }} >
                                    <View style={{ flexDirection: 'row', borderWidth: 3, borderColor: 'lightgrey', padding: 3, alignSelf: 'stretch', justifyContent: 'space-between' }}>
                                        
                                    <Image style={{
                                                width: 30, height: 30, borderRadius: 5,
                                                overflow: 'hidden'
                                            }} source={require('../../../Icons/BlueGear.png')} />

                                        <Text style={{ fontSize: 16, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5 }}>{item.name}</Text>
                                    </View>
                               </TouchableOpacity>
                               
                   }
                   keyExtractor={(item, index) => index.toString()}
               >
               </FlatList>
               
               <VGButton role="PRIMARY" onPress={()=>{ setStateSelection(stateSelections.addingEquipment);}}
                    >Add New Equipment</VGButton>

                    <VGButton role="SECONDARY"
                        onPress={async() => {
                setStateSelection(stateSelections.restoringEquipment)
                        }}
                    >Restore Deleted Equipment</VGButton>
           </View>
            : null}

               


            {stateSelection == stateSelections.addingEquipment ?
                <AddEquipmentScreenUpdated equipmentList={equipment} setStateSelection={setStateSelection} setEquipmentList={setEquipment} /> 
            : null}
                   {stateSelection == stateSelections.updatingEquipment ?
                <AddEquipmentScreenUpdated  currentEquipment={selectedEquipment} setCurrentEquipment={setSelectedEquipment} equipmentList={equipment} setStateSelection={setStateSelection} setEquipmentList={setEquipment} /> 
            : null}
            {stateSelection == stateSelections.restoringEquipment ?
            <RestoreEquipmentScreen deletedEquipment={equipment.filter(x => x.expired == 1)} OnCancel={() => {setStateSelection(stateSelections.default)}} OnSave={(restoredEquip:UserEquipment[]) => {UpdateRender(restoredEquip)}}  />
            : null}
      

         
        </View>
    );
}

export default ListEquipments;