import { Bin, BinLocation, GrainSubCrop, GrainType, MasterSample, StorageType } from "../../../Helpers/Storage/UserState";
import { View,Text, Dimensions } from "react-native";
import * as React from "react";
import { CalculateBinImage, GetBinHistory } from "../../../Helpers/Functions/Offline/BinFunctions";
import { ValidID } from "../../../Helpers/Functions/GeneralFunctions";
import BinHistoryScreen from "./BinHistoryScreen";
import BinVolumeAdjuster from "../../HelperScreens/BinVolumeAdjuster";
import BinSVG from "./bin_images";
import VGButton from "../../../Components/Button/Button";
import { GetOfflineStateParsed } from "../../../Helpers/AsyncStorageHelper";
import moment from "moment";


function BinDetailsComponent(props:{
        bin:Bin,
        binLocation:BinLocation,
        storageType:StorageType,
        grainData?:{
          grainType: GrainType;
          grainSubCrop: GrainSubCrop;
      },
        currentVolume:number,
        status?:string,
        isPopUp?:boolean;
        OnPrimaryButtonPress?:any;
    }
)
{
    const [imageName,setImageName] = React.useState('FlatbottomTemplate');
    const [binHistory,setBinHistory] = React.useState<{bin:Bin,binHistory:{volume:number,added:boolean,sample?:MasterSample,creation_date:Date}[]}>({bin:props.bin,binHistory:[]});

    const [currentVolume,setCurrentVolume] = React.useState(props.currentVolume);
    enum ScreenStatus{
      Default,
      ViewingHistory,
      ViewingMap,
      AdjustVolume
    }
    const [screenStatus,setScreenStatus] = React.useState<ScreenStatus>(ScreenStatus.Default);
    React.useEffect(() => {

      let current = 0;

      binHistory.binHistory.forEach(x => {

          if(x?.sample != null)
          {
              if(x.added)
              {
                  current+=x.volume;
              }
              else
              {
                  current-= x.volume;
              }
          }
          else
          {
              current+=x.volume;
          }})
          setCurrentVolume(current);
        const image = CalculateBinImage(current,props.bin.size,props.storageType.full_name,props?.status == 'in' ? 'in' : 'out');
        setImageName(image);


        console.log(JSON.stringify(props));
      },[binHistory])

    //This UseEffect will grab the history of grain coming in/out of a bin
    React.useEffect(() => {
      if(props.bin != null && ValidID(props.bin.id))
      {
        let binHistoryList = GetBinHistory(props.bin)

         binHistoryList.binHistory.sort((a, b) => {

          return moment(b.creation_date, 'YYYY-MM-DD hh:mm:ss').unix() - moment(a.creation_date, 'YYYY-MM-DD hh:mm:ss').unix();
      })
        setBinHistory(binHistoryList);
      }
    },[props.bin])

return (
  <View style={{flex:1,marginHorizontal:10}}>
    

  {screenStatus == ScreenStatus.ViewingHistory ? <BinHistoryScreen OnClosed={() =>{setScreenStatus(ScreenStatus.Default)}} historyData={binHistory}  /> : null}
  {screenStatus == ScreenStatus.AdjustVolume ? <BinVolumeAdjuster OnClosed={() => {setScreenStatus(ScreenStatus.Default)}} OnSaved={(volumeHistory) => {
    let currentHistory = binHistory.binHistory;
    currentHistory.push({added:true,creation_date:new Date(),volume:volumeHistory,sample:null})
    setBinHistory({...binHistory, binHistory:currentHistory});
    setScreenStatus(ScreenStatus.Default);
  }} historyData={binHistory} adjustingVolume={screenStatus == ScreenStatus.AdjustVolume}  /> : null}

  <View style={{flex:1,flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between'}}>

      <View style={{flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center'}}>
        {/* There a better way of sizing the bin image and putting it on the page? */}
      <BinSVG binImage={imageName??'FlatbottomTemplate'} width={Dimensions.get('window').width/1.1} height={250} /> 
      </View>


  </View>

  <View style={{flex:1,flexDirection:'row',marginHorizontal:Dimensions.get('window').width/10}}>

  <View style={{flex:1,flexDirection:'column',justifyContent:'flex-end'}}>
  <View style={{flexDirection:'row'}}><Text>Barcode</Text></View>
    <View style={{flexDirection:'row'}}><Text>Name</Text></View>
    <View style={{flexDirection:'row'}}><Text>Bin Capacity</Text></View>
    <View style={{flexDirection:'row'}}><Text>Current Capacity</Text></View>
    <View style={{flexDirection:'row'}}><Text>GrainType</Text></View>
    <View style={{flexDirection:'row'}}><Text>Status</Text></View>
  </View>

  <View style={{flex:1,flexDirection:'column',justifyContent:'flex-end',alignContent:'flex-end',alignItems:'flex-end'}}>
    <View style={{flexDirection:'row'}}><Text>{props.bin?.barcode??''}</Text></View>
    <View style={{flexDirection:'row'}}><Text>{props.bin.name}</Text></View>
    <View style={{flexDirection:'row'}}><Text>{props.bin.size}</Text></View>
    <View style={{flexDirection:'row'}}><Text>{currentVolume}</Text></View>
    <View style={{flexDirection:'row'}}><Text>{props?.grainData?.grainType?.name??''}</Text><Text>{props?.grainData?.grainSubCrop?.name != null ?props?.grainData?.grainSubCrop?.name : ''}</Text></View>
    {/* Status May need to change */}
    <View style={{flexDirection:'row'}}><Text>{currentVolume > 0 ? 'Filled' : 'Empty'}</Text></View>
  </View>

  </View>

  <View 
  style={{}}>


  <VGButton role="PRIMARY" onPress={() => {
    setScreenStatus(ScreenStatus.ViewingHistory);
  }}>View Bin History</VGButton>

  <VGButton
  role="SECONDARY" onPress={() => {
    setScreenStatus(ScreenStatus.AdjustVolume);
  }}>Adjust Volume</VGButton>


{/* TODO - Change depending on popup or screen */}
  <VGButton role={props?.isPopUp ? 'CANCEL':'SECONDARY'} onPress={props.OnPrimaryButtonPress}>{props.isPopUp ? 'Close': 'Bin Yard Overview' }</VGButton>
  </View>

  </View>
);
}
export default BinDetailsComponent;