
import React from "react";
import { View, Text, Image, TextInput, TouchableOpacity, Alert, FlatList, } from "react-native";
import Toast from "react-native-tiny-toast";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { RestoreDeletedBins, UpdateBin } from "../../../Helpers/Functions/Offline/BinFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { Bin, BinLocation } from "../../../Helpers/Storage/UserState";
import { EditBinLocState } from "./EditBinLocation";
import VGButton from "../../../Components/Button/Button";
import AppContext from "../../../Contexts/AppContext";

type PropTypes = {
    bins: Bin[];
    setBins: setProp<Bin[]>;
    currentLocation: BinLocation;
    parentMode: EditBinLocState;
    setParentMode: setProp<EditBinLocState>;
}

/**
 * Shows a list of deleted Bins and allows selecting them for restoration
 */
function RestoringBinScreen(props: PropTypes) {
    const appContext = React.useContext(AppContext);
    const [tempDeletedBins, setTempDeletedBins] = React.useState<Bin[]>([]);
    const [binsToRestore, setBinsToRestore] = React.useState<Bin[]>([]);

    React.useEffect(() => {
        if (props.currentLocation != null && props.currentLocation != null) {

            setTempDeletedBins(props.bins.filter(x => x.expired == 1));
        }
    }, [props.bins, props.currentLocation]);

    function ToggleToRestore(bin: Bin) {
        const index = binsToRestore.findIndex(x => x.id == bin.id)
        const tempBins = binsToRestore;

        if (index != -1) {
            tempBins.splice(index, 1);
        }
        else {
            tempBins.push(bin);
        }

        setBinsToRestore([...tempBins]);
    }

    async function onRestorePress () {
        try {
         
            let restoredBins = [];

            for(const bin of binsToRestore)
            {
                const restore = {...bin,expired:0};
                const status = await UpdateBin(restore);
                if(status)
                {
                    restoredBins.push(restore);
                }
            }
      
            if (restoredBins != undefined && restoredBins.length > 0) {
                const oldLocList = props.bins;
        

                const updatedList = oldLocList.map(bin => {
                    if(restoredBins.find(x => x.id == bin.id))
                    {
                        return {...bin, expired:0}
                    }
                    else
                    {
                        return bin;
                    }
                })
                props.setBins(updatedList);
                props.setParentMode({...props.parentMode, current_bin:null,current_mode:'edit_location'});
         
                setBinsToRestore([]);
           
        }
    }
        catch (error) {
            appContext.setAlertOptions({title:'Could not restore storage',desc:'Please try again at a later time',options:[{text:'OK', role:'PRIMARY', onPress:() =>{appContext.setShowAlert(false);}}]})
            appContext.setShowAlert(true);  
 
        }
    }


    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', marginHorizontal: 10 }}>


<View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3, marginBottom: 20 }}>
                <Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Restore Storage</Text>
            </View>

            {(tempDeletedBins == null || tempDeletedBins.length == 0) ?
                <Text style={{ fontSize: 18, alignSelf: 'center' }}>No Storage to Restore.</Text>
                : null}
            
            <FlatList
                data={tempDeletedBins}
                renderItem={({ item, index }: { item: Bin, index: number }) =>
                    <View >
                        <View style={{ flexDirection: 'row', borderWidth: 3, borderColor: 'lightgrey', marginVertical: 3, alignSelf: 'stretch' }}>
                            <TouchableOpacity
                            accessibilityLabel={item.name}
                                style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignSelf: 'center', padding: 5 }}
                                onPress={() => {
                                    ToggleToRestore(item);
                                }} >
                                {binsToRestore.findIndex(x => x.id == item.id) != -1 ?
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/OrangeField.png')} />
                                    :
                                    <Image style={{ width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../Icons/BlueField.png')} />
                                }

                                <Text style={{ fontSize: 18, alignSelf: 'center', paddingRight: 5, position: 'absolute', right: 5 }}>{item.name}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                }
                keyExtractor={(item, index) => index.toString()}
            >
            </FlatList>

            <VGButton role="PRIMARY" onPress={onRestorePress}>Restore</VGButton>

            <VGButton role="CANCEL" 
                  onPress={() => {
                    props.setParentMode({...props.parentMode,current_mode:'edit_location'});

        
                }}
            >Close</VGButton>

        </View>
    )
}

export default RestoringBinScreen;