import React from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Field } from "../../Helpers/Storage/UserState";
import OverlayPicker from "../../Screens/HelperScreens/OverlayPicker";
import VGInput from "../Input/Input";
import { RequiredText } from "../SimpleComponents";

type PropType ={
    currentField:Field;
    setCurrentField:(data:Field)=>void
}

export default function FieldForm(props:PropType){

    //meridian and quarter choices
    const meridianChoices = [{ name: 'W1' }, { name: 'W2' }, { name: 'W3' }, { name: 'W4' }, { name: 'W5' }, { name: 'W6' }, { name: 'W7' }];
    const quarterChoices = [{ name: 'NE' }, { name: 'NW' }, { name: 'SE' }, { name: 'SW' }];

    //status message states
    const [sectionStatusMessage, setSectionStatusMessage] = React.useState('');
    const [townshipStatusMessage, setTownshipStatusMessage] = React.useState('');
    const [rangeStatusMessage, setRangeStatusMessage] = React.useState('');

    //overlay toggles
    const [toggleQuarter, setToggleQuarter] = React.useState(false);
    const [toggleMeridian, setToggleMeridian] = React.useState(false)



    // #region useEffects for validation
    React.useEffect(() => {
        if (props.currentField.section > 0 && props.currentField.section <= 36) {
            setSectionStatusMessage('');
        }
        else {
            setSectionStatusMessage('Section must be between 1 and 36 inclusive');
        }
    }, [props.currentField.section])

    React.useEffect(() => {
        if (props.currentField.township > 0 && props.currentField.township <= 99) {
            setTownshipStatusMessage('');
        }
        else {
            setTownshipStatusMessage('Township must be between 1 and 99 inclusive');
        }
    }, [props.currentField.township])

    React.useEffect(() => {
        if (props.currentField.rangev > 0 && props.currentField.rangev <= 99) {
            setRangeStatusMessage('');
        }
        else {
            setRangeStatusMessage('Range must be between 1 and 99 inclusive');
        }
    }, [props.currentField.rangev])
    // #endregion

    // #region onPress Methods
    function onQuarterPress(){
        toggleQuarter ? setToggleQuarter(false) : setToggleQuarter(true);
    }

    function onMeridianPress(){
        toggleMeridian ? setToggleMeridian(false) : setToggleMeridian(true);
    }
    // #endregion

    return (
        <View style={{flex:1}}>
        <ScrollView >

            {toggleQuarter ? <OverlayPicker type={['name']} text={'Pick a Quarter'} isVisible={toggleQuarter} setVisible={setToggleQuarter} setData={(data)=>props.setCurrentField({...props.currentField, quarter:data.name})} data={quarterChoices} /> : null}
            {toggleMeridian ? <OverlayPicker type={['name']} text={'Pick a Meridian'} isVisible={toggleMeridian} setVisible={setToggleMeridian} setData={(data)=>props.setCurrentField({...props.currentField, meridian:data.name})} data={meridianChoices} /> : null}

                <RequiredText />

                <VGInput label="Name" required 
                    onChangeText={(text) => {
                        props.setCurrentField({...props.currentField, name:text});
                    }}
                    value={props.currentField?.name}
                />

                <VGInput label="Quarter" type="picker" 
                    onPress={onQuarterPress}
                    value={props.currentField?.quarter}
                />


                <VGInput label="Section" 
                    error={sectionStatusMessage} keyboardType={'numeric'}  
                    value={props.currentField.section != null ? props.currentField.section.toString() : ''}
                    onChangeText={(text) => {
                        if(isNaN(parseInt(text))){
                            props.setCurrentField({...props.currentField, section:null});
                        }
                        else{
                            props.setCurrentField({...props.currentField, section:parseInt(text)});
                        }
                    }}
                />

                <VGInput label="Township" 
                    keyboardType={'numeric'} error={townshipStatusMessage}
                    value={![null, 0].includes(props.currentField.township) ? props.currentField.township.toString() : ''}
                    onChangeText={(text) => {
                        if(isNaN(parseInt(text))){
                            props.setCurrentField({...props.currentField, township:null});
                        }
                        else{
                            props.setCurrentField({...props.currentField, township:parseInt(text)});
                        }
                    }} 
                />
                       
                <VGInput label="Range" 
                    keyboardType={'numeric'} error={rangeStatusMessage}
                    value={![null, 0].includes(props.currentField.rangev) ?props.currentField.rangev.toString() : ''}
                    onChangeText={(text) => {
                        if(isNaN(parseInt(text))){
                            props.setCurrentField({...props.currentField, rangev:null});
                        }
                        else{
                            props.setCurrentField({...props.currentField, rangev:parseInt(text)});
                        }
                    }} 
                />

                <VGInput label="Meridian" type="picker" 
                    onPress={onMeridianPress}
                    value={props.currentField?.meridian}
                />

            </ScrollView>
            </View>
    )
}