import { isValidPhoneNumber } from "libphonenumber-js";
import React from "react";
import VGInput from "./Input";

type PropType = {
    onSetVal:(value:{number:string, valid:boolean})=>void
};

export default function PhoneInput(props:PropType){
    
    const [maskedVal, setMaskedVal] = React.useState<string>("");
    const [error, setError] = React.useState("");
    
   

    function onChangeText(text:string){
        let newVal = stripMask(text);
        let err = getError(newVal);
        props.onSetVal({number:"+1"+newVal, valid:(err.length == 0)});
        setError(err);
        setMaskedVal(addMask(newVal));
    }

    function stripMask(value:string):string{
        return value.split('').filter(x=> '0' <= x && x <= '9').join('');
    }

    function addMask(value:string):string{
        let arr = value.split('');
        if(value.length > 10){
            value = value.substring(0, 10);
        }
        
        
        if(arr.length <= 4){
            while(arr.length < 4){
                arr.unshift('')
            }
            return `${arr[0]}${arr[1]}${arr[2]}${arr[3]}`
        }
        if(arr.length <= 7){
            while(arr.length < 7){
                arr.unshift('')
            }
            return `${arr[0]}${arr[1]}${arr[2]} - ${arr[3]}${arr[4]}${arr[5]}${arr[6]}`
        }
        else{
            while(arr.length < 10){
                arr.unshift('')
            }
            return `(${arr[0]}${arr[1]}${arr[2]}) ${arr[3]}${arr[4]}${arr[5]} - ${arr[6]}${arr[7]}${arr[8]}${arr[9]}`
        }
    }

    function getError(value:string):string{
        return value.length == 10 ? "" : "Phone number must be 10 digits";
    }

    return(
        <VGInput type="text" label="Phone Number" onChangeText={onChangeText}
            keyboardType={"numeric"} value={maskedVal} error={error}
        />
    )
}