import React from "react";
import { Dimensions, View,Text,Image, TouchableOpacity, Vibration, Alert, GestureResponderEvent} from "react-native";
import { Overlay } from "react-native-elements";
import VGButton from "../../../Components/Button/Button";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import { TakeSample } from "../../../Helpers/Functions/Offline/ProgressSampleFunctions";
import { setProp } from "../../../Helpers/GeneralTypes";
import { ManualProgressTimes, ManualSamplingTypes, MasterSample } from "../../../Helpers/Storage/UserState";
import spacing from "../../../Styles/Spacing";
import styles from "../../../Styles/styles";
import textStyles from "../../../Styles/Text";
import { Sound } from 'expo-av/build/Audio';
import { Audio } from 'expo-av';
import { AddToQueue } from "../../../Helpers/Storage/OfflineQueue";
import AppContext from "../../../Contexts/AppContext";


function StandardSamplingScreen(props:{
    manualProgressTimes:ManualProgressTimes[],
    sample:MasterSample,
    isVisible:boolean,
    isHidden:boolean,
    manualSamplingType:ManualSamplingTypes,
    ToggleHide:() => void,
    OnFinish:() => void,
    OnSave:(data:string)=>void
})
{

    const appContext = React.useContext(AppContext);
    const [alertSound, setAlertSound] = React.useState<Sound>();
    const [successSound,setSuccessSound] = React.useState<Sound>();
    const [manualProgressTimes,setManualProgressTimes] = React.useState<ManualProgressTimes[]>(props.manualProgressTimes);
    const [sample,setSample] = React.useState<MasterSample>(props.sample);
    const [selectedSamplingMethod,setSelectedSamplingMethod] = React.useState<ManualSamplingTypes>(props.manualSamplingType);
    const [ticking,setTicking] = React.useState<boolean>(false);
    const [samplesRemaining,setSamplesRemaining] = React.useState(-1);
    const [average,setAverage] = React.useState(0);
    const [quality,setQuality] = React.useState(0);

    const [overtimeAlert,setOvertimeAlert] = React.useState<boolean>(false);
    const [alertSample,setAlertSample] = React.useState(false);

    async function PlayAlertSound()
    {
        const {sound} = await Audio.Sound.createAsync(require('../../../assets/gentle-alarm-474.mp3'));
        setAlertSound(sound);
        await sound.playAsync();
    }

    async function PlaySuccessSound()
    {
        const {sound} = await Audio.Sound.createAsync(require('../../../assets/arpeggio-467.mp3'));
        setSuccessSound(sound);
        await sound.playAsync();
    }

    React.useEffect(() => {
        return alertSound
        ? () => {
            alertSound.unloadAsync();
          }
        : undefined;
    },[alertSound])

    React.useEffect(() => {
        return successSound
        ? () => {
            successSound.unloadAsync();
          }
        : undefined;
    },[successSound])

    /**
     * This function will toggle the timer start/stop
     */
    function OnTimerPress()
    {
        ticking ? setTicking(false) : setTicking(true);
    }

    /**
     * This function will add a manual progress time object to the queue system
     * and reset the timer
     */
    async function OnSampleTakenPress()
    {
        setOvertimeAlert(false);
        appContext.setShowAlert(false);
       // playSuccessSound();
        const userID = await GetData('ID');
        PlaySuccessSound();
        //Take a sample 
       const manualSampleProgress = TakeSample(sample,parseInt(userID));

       if(manualSampleProgress != null)
       {
        setSample({...sample,time_current:sample.target_time});
        setManualProgressTimes([...manualProgressTimes,manualSampleProgress]);
       }
    }


    function OnDonePressed()
    {
 

        appContext.setAlertOptions({title:'Are you sure you want to finish with the Standard Sampler process?',desc:'Your sample will no longer be in-progress',options:[
            {text:'Confirm Done', role:'PRIMARY', onPress:async()=>{
                appContext.setShowAlert(false);
                FinishSample(true);
            }},
            {text:'Save Progress', role:'SECONDARY', onPress:async() => {
                appContext.setShowAlert(false);
                FinishSample();
            }},
            {text:'Return to Sampling', role:'CANCEL', onPress:() => {
                appContext.setShowAlert(false);
            }}
        ]})
        appContext.setShowAlert(true);
    }

    //This function will get the samples remaining
    function GetSamplesRemaining()
    {
        //Is the samples required field not null?
        if(sample?.samples_required != null)
        {
            //take the length of the progress time array and subtracct from samples requires to get samples remaining
            let num = sample?.samples_required - manualProgressTimes.length;
            setSamplesRemaining(num);
        }

    }

    async function FinishSample(finish?:boolean)
    {
        
        const finishedSample:MasterSample = {...sample,completed:finish?1:0};
        const queueStatus = await AddToQueue('master_samples',finishedSample,'update');
        console.log('finish status: ' + queueStatus);
        if(queueStatus)
        {
            props.OnFinish();
        }
    }


    //This function will get the average time for a sample
    function GetAverageTime()
    {
        //grab the average by totalling the time array and dive by length
        let total = 0;
        manualProgressTimes.forEach(time => {
            total += time.time;
        });

        const avg = total / manualProgressTimes.length;

        //the min and max ranges to determine the quality
        //If the target time was 10, the maximum average to be considered a 'perfect' would be 15 and the minimum would be 5
        const PerfectQuality = sample.target_time * .5;
        const OkayQuality = sample.target_time * .7;
   
 
        if(!isNaN(avg))
        {
            if(avg <= sample.target_time + PerfectQuality && avg >= sample.target_time - PerfectQuality )
            {
                setQuality(3);
            }
            else if (avg <= sample.target_time + OkayQuality && avg >=  sample.target_time - OkayQuality )
            {
                setQuality(2);
            }
            else
            {
                setQuality(1);
            }
    
            setAverage(avg);
        }
    }

    /**
     * This use effect will keep track of a ticking boolean to determine whether to start the timer or not
     */
    React.useEffect(() => {
        //If the user started the timer
        if(ticking)
        {
            //https://developer.mozilla.org/en-US/docs/Web/API/setInterval
            let interval = setInterval(() => {
                setSample(lastTimerCount => {
                    //Takes the current tume and subtracts 1, clears the interval to continue
                    //lastTimerCount.time_current <= -(sample.target_time * 3) && clearInterval(interval)
                    lastTimerCount.time_current -=1;     
                    console.log(lastTimerCount.time_current);
                    //Put in square brackets to force update screen
                    return {...lastTimerCount}
                })
              }, 1000) //each count lasts for a second
              //cleanup the interval on complete
              return () => clearInterval(interval)
        }
    },[ticking])


    /**
     * This useeffect gets triggered each time a new manualProgress time is created OR when the samples required gets changes (useful on startup)
     */
    React.useEffect(() => {

        //Get samples remaining and average time
        GetSamplesRemaining();
        GetAverageTime();
    },[manualProgressTimes,sample?.samples_required]);


    /**
     * This use effect will trigger each time the sample remaining variable gets changed
     */
    React.useEffect(() => {
        //Ensure we dont check if its been initialized
        if(samplesRemaining != -1 && samplesRemaining != null)
        {
            //If there are no more samples to take
            if(samplesRemaining == 0)
            {
                //stop the clock
                setTicking(false);
   

                appContext.setAlertOptions({title:'Sample Completed',desc:'Your sample will no longer be in-progress',options:[
                    {text:'OK', role:'PRIMARY', onPress:async()=>{
                        appContext.setShowAlert(false);
                       await  FinishSample(true);
                    }}
                ]})
                appContext.setShowAlert(true);
            }
        }
    },[samplesRemaining])

    /**
     * This useeffect will trigger each time the current_time is changed
     */
    React.useEffect(() => {

        //if the time is less than 10 and 3 seconds have elapsed than vibrate the phone and play an alert sound
        if(sample.time_current  % 3 == 0 && sample.time_current <= 10)
        {
            if(!overtimeAlert)
            {
                setOvertimeAlert(true);

            }
          Vibration.vibrate([500,500], false);
          PlayAlertSound();
        }
    

    },[sample?.time_current])


    React.useEffect(() => {
        if(alertSample)
        {
            OnSampleTakenPress();
            setAlertSample(false);
        }
    },[alertSample])
    React.useEffect(() => {
        if(overtimeAlert)
        {
            appContext.setAlertOptions({title:'Time to Take Sample', desc:'A sample must now be taken',options:[{text:'OK',role:'PRIMARY',onPress:() => {setAlertSample(true)}
            }]})
            appContext.setShowAlert(true);
        }
    },[overtimeAlert])
    return(

        <>
        {!props.isHidden ?
        <Overlay fullScreen isVisible={!props.isHidden}>
 
                <View style={spacing.TitleBar}>
                    <Text style={styles.title}>{selectedSamplingMethod.id == 1 ? 'Standard' : 'AccuSampler'}</Text>
                </View>

                <VGButton role={ticking ? "SECONDARY" : "PRIMARY"} onPress={OnTimerPress}>{ticking ? 'Stop'  : 'Start' }</VGButton>

                <VGButton role="PRIMARY" disabled={!ticking} onPress={OnSampleTakenPress}>Sample Taken</VGButton>

                <VGButton role="CANCEL" onPress={OnDonePressed}>Done</VGButton>


                <View style={styles.samplingListContainer}>

                    <View style={styles.samplingListInnerContainer}>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Number of samples taken</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{manualProgressTimes.length??'N/A'}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Number of samples required</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.samples_required??'N/A'}</Text>
                            </View>
                        </View>
                        
                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Number of samples remaining</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{samplesRemaining}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Sample Interval - seconds</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.target_time??0}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Time to take Next Sample</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.time_current??0}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Average Time for Sample</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{average}</Text>
                            </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Sample Time Score</Text>
                            </View>
                  

                            <View style={{flexDirection:'row'}}>
                                    {quality == 3 ?    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/FilledHappyFace.png')}
                                /> :    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/BlankHappyFace.png')}
                                /> }
                                {quality == 2 ?    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/FilledOkayFace.png')}
                                /> :    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/BlankOkayFace.png')}
                                /> }
                                    {quality == 1 ?    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/FilledSadFace.png')}
                                /> :    <Image style= {{ width: 25, height: 25}}  
                            source={require('../../../Icons/BlankSadFace.png')}
                                /> }
                           </View>
                        </View>

                        <View style={spacing.Row}>
                            <View style={spacing.SamplingKey}>
                                <Text style={textStyles.label}>Scoop Size</Text>
                            </View>
                            <View style={spacing.SamplingValue}>
                                <Text style={textStyles.input}>{sample?.scoop_size??'1'}</Text>
                            </View>
                        </View>

                    </View>
                </View>
             



            <VGButton role="CANCEL"
                 onPress={props.ToggleHide}>Main Menu</VGButton>

        </Overlay>
                :
                <TouchableOpacity style={{flex:1,
                    width: Dimensions.get('window').width - 10,
                    alignSelf:'center',
                    height: 50,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position:'absolute',
                    bottom:10,backgroundColor:'orange'}}  onPress={props.ToggleHide} >
         
             <Text style={{color:'white',textAlign:'center'}}>Return To AccuSampler</Text>
           </TouchableOpacity>
                }
        </>
    );
}
export default StandardSamplingScreen;