import moment from "moment";
import React from "react";
import { Dimensions, View, Text, FlatList, TouchableOpacity, Image } from "react-native";
import { Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import VGButton from "../../../Components/Button/Button";
import { setProp } from "../../../Helpers/GeneralTypes";
import { Buyer, Enterprise, MasterSample, SampleRecordCharacteristic, PendingAnalysis } from "../../../Helpers/Storage/UserState";


function PendingAnalysisScreen(props: { sample:MasterSample, setPickingData: any, pendingAnalysis:PendingAnalysis[], setPendingAnalysis:any,sampleCharacteristics:SampleRecordCharacteristic[], setSampleCharacteristics:setProp<SampleRecordCharacteristic[]>}) {
    const [showAllAnalysis, setShowAllAnalysis] = React.useState(false);
    const [sortBy, setSortBy] = React.useState('Date');
    const [combinedAnalysis,setCombinedAnalysis] = React.useState<{pendingSampleCharacteristic:SampleRecordCharacteristic|null,pendingAnalysis:PendingAnalysis|null}[]>([]);
    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height
    const [buyers,setBuyers] = React.useState<Buyer[]>([]);
    const [enterprises,setEnterprises] = React.useState<Enterprise[]>([]);
    
    let renderSeparatorView = () => {
        return (
            <View style={{
                height: 2,
                width: width,
                backgroundColor: "orange",
            }}
            />
        );
    };

    React.useEffect(() => {
        const loadData = async () => {
   
            let oldCombined:{pendingSampleCharacteristic:SampleRecordCharacteristic|null,pendingAnalysis:PendingAnalysis|null}[] = [];
            props.sampleCharacteristics.forEach(x => {
                oldCombined.push({pendingSampleCharacteristic:x,pendingAnalysis:null});
            })
            props.pendingAnalysis.forEach(x => {
                oldCombined.push({pendingAnalysis:x,pendingSampleCharacteristic:null});
            })
            setCombinedAnalysis(oldCombined);
        }

        loadData();
    }, [props.sampleCharacteristics]);

    return (
        <>
            <Overlay onDismiss={() => {
                props.setPickingData(null);

            }} isVisible={true} onBackdropPress={() => { props.setPickingData(null) }}>

                <View style={{ flexDirection: 'row', alignSelf: 'flex-start' }}>
                    <TouchableOpacity style={{ left: 10 }} onPress={async () => {


                        props.setPickingData(null);
                    }}>
                        <Icon style={{ paddingRight: 10 }} name="chevron-left" size={35} color="black" />

                    </TouchableOpacity>

                </View>


                <View style={{ flexDirection: 'row', alignSelf: 'flex-start' }}>
                    <Text style={{ alignSelf: 'center', fontSize: 28, textAlign: 'center', flex: 1 }}>Pending Analysis</Text>
                </View>

                {showAllAnalysis ?
                    <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 3, marginVertical: 5, paddingLeft: 5, paddingRight: 5 }}>
                        <View style={{ flexDirection: 'row', alignSelf: 'flex-start', paddingVertical: 3 }}>
                            <Text style={{ alignSelf: 'center', fontSize: 20, textAlign: 'left', flex: 1 }}>Sort By:</Text>
                        </View>
                        <TouchableOpacity onPress={() => {

                        }} style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
                            <Image style={{
                                width: 30, height: 30, borderRadius: 5,
                                overflow: 'hidden',
                            }} source={sortBy == 'Date' ? require('../../../Icons/OrangeDateTime.png') : require('../../../Icons/BlueDateTime.png')} />
                            <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16 }}>Date</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                setSortBy('Grain');
                            }}
                            style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
                            <Image style={{
                                width: 30, height: 30, borderRadius: 5,
                                overflow: 'hidden',
                            }} source={sortBy == 'Grain' ? require('../../../Icons/OrangeGrain.png') : require('../../../Icons/BlueGrain.png')} />
                            <Text style={{ paddingLeft: 10, fontSize: 16, flex: 1 }} >Grain</Text>
                        </TouchableOpacity>

                    </View>
                    : null}

                <FlatList
                    ItemSeparatorComponent={renderSeparatorView}
                    contentContainerStyle={{ justifyContent: 'space-evenly', alignContent: 'flex-end' }}
                    data={combinedAnalysis.filter(x => x?.pendingAnalysis?.sample_id == props.sample.id || x.pendingSampleCharacteristic != null)}
                    renderItem={({ item }) =>
                        <View style={{ borderColor: 'lightgrey', borderWidth: 2, paddingVertical: 3, marginVertical: 5, paddingLeft: 5, paddingRight: 5 }}>

                            <View style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
                                <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16 }}>Barcode: </Text>
                                <Text style={{ paddingRight: 10, fontSize: 16 }} >{props.sample?.barcode}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
                                <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16 }}>Type: </Text>
                                <Text style={{ paddingRight: 10, fontSize: 16 }} >{item.pendingAnalysis != null ? 'Lab' : 'Buyer'}</Text>
                            </View>

                                <View style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
                                    <Text style={{ paddingLeft: 10, fontSize: 16, flex: 1 }}>Name: </Text>
                                    <Text style={{ fontSize: 16, flexWrap: 'wrap', paddingRight: 10}} numberOfLines={1} >{item.pendingAnalysis != null ? item.pendingAnalysis.name : item?.pendingSampleCharacteristic?.buyer_id != null ?
                                     'Buyer'
                                     :
                                     'Enterprise'
                                     }</Text>
                                </View>
                              

                            <View style={{ flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
                                <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16 }}>Grain: </Text>
                                <Text style={{ paddingRight: 10, fontSize: 16 }} >{item.pendingAnalysis != null ? item.pendingAnalysis.grain_name : 'Grain'}</Text>
                            </View>

                            <View style={{ flexDirection: 'row', flex: 1, marginVertical: 5, alignItems: 'center' }}>
                                <Text style={{ paddingLeft: 10, flex: 1, fontSize: 16 }}>Date Created: </Text>
                                <Text style={{ paddingRight: 10, fontSize: 16 }} >{item.pendingAnalysis != null ? moment(item.pendingAnalysis.date).format('LLL') : moment(item.pendingSampleCharacteristic.created_at).format('LLL')}</Text>
                            </View>







                            <VGButton role="SECONDARY" onPress={async () => {

                                //set the record received to either 0 or 1 (like a checkbox)
                                //TODO save to order_meta table
                                let oldRecords = props.pendingAnalysis;
                                let oldRec = props.sampleCharacteristics;
                                if (item.pendingAnalysis != null) {
                                    const index = oldRecords.findIndex(x => x.order_id == item.pendingAnalysis.order_id);
                                    oldRecords[index].received = oldRecords[index].received == 0 ? 1 : 0;
                                }
                                else {
                                    const index = oldRec.findIndex(x => x.id== item.pendingSampleCharacteristic.id);
                                    oldRec[index].value = '1';
                                }
                                props.setSampleCharacteristics([...oldRec]);
                                props.setPendingAnalysis(oldRecords);
                            }}>Remove from List</VGButton>


                        </View>
                    }
                />
            </Overlay>
        </>
    );
}
export default PendingAnalysisScreen
