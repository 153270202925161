import * as React from 'react';
import { DrawerActions, NavigationContainer, useIsFocused } from '@react-navigation/native';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground, TouchableOpacity,Modal, Alert, Dimensions,Image, FlatList, KeyboardAvoidingView} from 'react-native';

import Spinner from "react-native-loading-spinner-overlay";
import { Overlay } from "react-native-elements";

import { GetData } from "../../../Helpers/AsyncStorageHelper";

import { AllowedSampleGrain, Grain } from "../../../Helpers/Storage/UserState";
import VGButton from '../../../Components/Button/Button';
//Similar to the barcode camera, except it has no barcode scan capabilities
 function GrainTypePicker(props: {grainList:Grain[],userGrainList:AllowedSampleGrain[],setUserGrainList,visible:boolean,setVisible:any})
{

const height = Dimensions.get('screen').height;
const width = Dimensions.get('screen').width;


const [grains,setGrains] = React.useState<Grain[]>(props.grainList);
const [userGrains,setUserGrains] = React.useState<AllowedSampleGrain[]>(props.userGrainList);


        //This useEffect will grab the data before rendering the page


    let renderSeparatorView = () => {
        return (
          <View style={{
              height: 2, 
              width: width,
              backgroundColor: "orange",
            }}
          />
        );
      };

    

    

      //Look at all this grain, thats some good grain
    return(
        <Overlay   isVisible={props.visible}  >
        <KeyboardAvoidingView style={{width:width/1.1,height:height/1.1,flex:1}}>
       <View style={{flex:1}}>


                    

<View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3,marginTop:10 }}>
<Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Pick Grain Type(s)</Text>
</View> 
               <FlatList 
               ItemSeparatorComponent={renderSeparatorView}
                     contentContainerStyle={{justifyContent:'space-evenly',alignContent:'flex-end'}}
                         data={props.grainList}
                         renderItem={({ item, index }) =>
                         <TouchableOpacity onPress= {async() => {
                          let oldGrain = userGrains;
                          console.log(oldGrain.length);
                          const profile = await GetData('Profile');
                          const userID = await GetData('ID');
                      
                            if( oldGrain.length > 0 && oldGrain.find(x => x.grain_type_id == item.id) != undefined )
                            {
                              const index = userGrains.findIndex(x => x.grain_type_id == item.id);
                              if(index != -1)
                              {
                                oldGrain.splice(index,1);
                              }
                            }
                            else
                            {
                     
                              oldGrain.push({id:null,created_at:item.created_at,grain_type_id:item.id,profile:profile,updated_at:null})
                            }
                            setUserGrains([...oldGrain]);
    
                         }} >
                            
                             <Text style={{fontSize:18, padding:18, textAlign:'left', color:userGrains != undefined && userGrains.find(x => x.grain_type_id == item.id) != undefined ? 'orange' : 'black'}}>{item.name}</Text>
                         </TouchableOpacity>

                        
                         }
                         keyExtractor={(item,index) => index.toString()}
                     >
                     </FlatList>


                     <VGButton role="PRIMARY" onPress={()=>{
                        props.setUserGrainList([...userGrains]);
                        props.setVisible(false);
                    }}>Save</VGButton>

                    <VGButton role="CANCEL" onPress={()=>{
                         props.setVisible(false);
                     }}>Close</VGButton>

      
          </View>
          </KeyboardAvoidingView>
          </Overlay>
    );
  
}






export default GrainTypePicker





