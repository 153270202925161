import React from "react";
import { GestureResponderEvent, KeyboardAvoidingView, KeyboardTypeOptions, NativeSyntheticEvent, StyleSheet, Text, TextInput, TextInputFocusEventData, TextInputSubmitEditingEventData, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import Colours from "../../Styles/Colours";
import { Icon, IconNames } from "../Icon";
import textStyles from "../../Styles/Text";
import spacing from "../../Styles/Spacing";

export const height = 50;

type PropType = {
    type?: "text";
    label: string;
    onChangeText: (text:string)=>void
    width?: number,
    value?: string;
    icon?: IconNames;
    onIconPress? : (event:GestureResponderEvent)=>void
    error?: string;
    keyboardType?: KeyboardTypeOptions;
    required?: boolean;
    testLabel?: string;
    editable?:boolean;
    onSubmitEditing?:(e: NativeSyntheticEvent<TextInputSubmitEditingEventData>)=>void;
    secureTextEntry?: boolean;
    onFocus?:(e: NativeSyntheticEvent<TextInputFocusEventData>)=>void;
    onBlur?:(e:NativeSyntheticEvent<TextInputFocusEventData>)=>void;
} | {
    type: "picker";
    label: string;
    onPress: (event: GestureResponderEvent)=>void;
    value: string;
    icon?:IconNames;
    width?: number,
    required?: boolean;
    testLabel?: string;
    error?:string;
}

//TODO finishe desription of VGInput

/** 
 * @param label
 * The label to be displayed
 * 
 * @param testLabel
 * Optional label to overwrite the testing label.
 * SHOULD ONLY BE USED IF NECCESSARY. USE DEFAULT WHEN POSSIBLE
 * Default is "Input_[label]" e.g. "Input_Name" or "Input_Grain Type"
 * 
 * @returns 
*/
const VGInput = React.forwardRef<TextInput, PropType>(function VGInput(props:PropType, fRef){

    const [textLength, setTextLength] = React.useState<number>();
    const [labelStyle, setLabelStyle] = React.useState<TextStyle>();
    const [containerStyle, setContainerStyle] = React.useState<ViewStyle>();

    const [focus, setFocus] = React.useState(false);

    const [showError, setShowError] = React.useState(false);

    const inRef = React.useRef<TextInput>(null);
    const [inputRef, setInputRef] = React.useState<React.MutableRefObject<TextInput>>(inRef);


    const testLabel = props.testLabel ?? 'Input_' + props.label;

    React.useEffect(()=>{
        if(fRef != null && typeof fRef != 'function'){
            setInputRef(fRef);
        }
        else{
            setInputRef(inRef);
        }
    }, [fRef])

    React.useEffect(()=>{
        setTextLength(props.value == null ? 0 : props.value.length)
    }, [props.value])

    React.useEffect(()=>{
        if(props.type != "picker" && props.error && props.error.length > 0 && !focus){
            setShowError(true)
        }

        if(textLength == 0){
            setShowError(false)
        }
    },[focus])

    React.useEffect(()=>{
        const lblStyle:TextStyle[] = []
        const contStyle:ViewStyle[] = []

        contStyle.push(styles.container);

            //FILLED
            if(textLength > 0 || focus){
                //if any text or if focused, the label moves up top
                lblStyle.push(styles.topLabel)
                
                //NO VALIDATION
                if(props.error === undefined){
                    //FILLED
                    if(!focus){
                        lblStyle.push({color:Colours.VG_GREY})
                        contStyle.push(styles.filledContainer)
                        contStyle.push({borderBottomColor:Colours.VG_GREY})
                    }
                    //ACTIVE
                    else{
                        lblStyle.push({color:Colours.VG_BLUE})
                        contStyle.push(styles.focusContainer)
                        contStyle.push({borderBottomColor:Colours.VG_BLUE})
                    }
                }
                //VALID OR INVALID
                else{
                    //VALID
                    if(props.error === null || props.error.length == 0){
                        //FILLED
                        if(!focus){
                            lblStyle.push({color:Colours.VG_GREY})
                            contStyle.push(styles.filledContainer)
                            contStyle.push({borderBottomColor:Colours.VG_GREY})
                        }
                        //ACTIVE
                        else{
                            lblStyle.push({color:Colours.SUCCESS})
                            contStyle.push(styles.focusContainer)
                            contStyle.push({borderBottomColor:Colours.SUCCESS})
                        }
                    }
                    //INVALID
                    else{
                        //FILLED
                        if(!focus){
                            lblStyle.push({color:Colours.ERROR})
                            contStyle.push(styles.errorContainer)
                            contStyle.push({borderBottomColor:Colours.ERROR})
                        }
                        //ACTIVE
                        else{
                            lblStyle.push({color:Colours.ERROR})
                            contStyle.push(styles.focusContainer)
                            contStyle.push({borderBottomColor:Colours.ERROR})
                        }
                    }
                }
            }
        //UNFILLED
        else{
            lblStyle.push(styles.defaultLabel)
            contStyle.push(styles.defaultContainer)
        }
    
        
        setLabelStyle(StyleSheet.flatten(lblStyle))
        setContainerStyle(StyleSheet.flatten(contStyle))
    }, [focus, textLength, props.error])

    function onFocus(e){
        setFocus(true);
        if(props.type != "picker" && props.onFocus != null){
            props.onFocus(e)
        }
    }

    function onNoFocus(e){
        setFocus(false);
        if(props.type != "picker" && props.onBlur != null){
            props.onBlur(e)
        }
    }

    function onChangeText(text){
        if(props.type != "picker"){
            setTextLength(text.length);
            props.onChangeText(text);
        }
    }

    function onPressInternal(){
        if(typeof inputRef != 'function'){
            inputRef.current.focus();
        }
    }

    function asterisk(){ 
        return props.required ?
            !focus && textLength == 0 ?
                <Text style={styles.asterisk}>*</Text> 
                : <Text style={styles.topAsterisk}>*</Text> 
            : null
    }


    return (
        // <KeyboardAvoidingView behavior="position">
        <View style={spacing.Element}>
            <View style={{flexDirection:"row", width:props.width??"100%"}} >
                <View style={containerStyle}>
                    {props.type != "picker" ?
                        <TouchableOpacity onPress={onPressInternal} style={{flex:1}} >
                            <View style={{flexDirection:'row'}}>
                                <Text style={labelStyle}>{props.label}</Text>
                                {asterisk()}
                            </View>
                            <TextInput ref={inputRef} onFocus={onFocus} onBlur={onNoFocus} onChangeText={(text)=>{onChangeText(text)}}
                                value={props.value} accessible accessibilityLabel={testLabel} style={textStyles.input} keyboardType={props.keyboardType}
                                editable={props.editable??true} onSubmitEditing={props.onSubmitEditing} secureTextEntry={props.secureTextEntry} autoCapitalize={"none"}
                                />
                        </TouchableOpacity>
                    :
                        <TouchableOpacity onPress={props.onPress} style={{flex:1, flexDirection:"row"}}   >
                            <View style={{flex:1}}>
                                <View style={{flexDirection:"row"}}>
                                    <Text style={labelStyle}>{props.label}</Text>
                                    {asterisk()}
                                </View>
                                <Text accessible accessibilityLabel={testLabel} style={textStyles.input} >{props.value}</Text>
                            </View>
                            <View style={{justifyContent:"center"}}>
                                <Icon name={props.icon??"picker"} style={{marginRight:10, alignSelf:"flex-end"}} />
                            </View>
                        </TouchableOpacity>
                    }
                    {props.type != "picker" && props.icon !== undefined ?
                    <TouchableOpacity onPress={props.onIconPress} style={{alignSelf:"flex-end", justifyContent:"center", marginRight:10, padding:0, height:"100%"}} >
                        <Icon name={props.icon} />
                    </TouchableOpacity>
                    :null}
                </View>
                {props.error !== undefined ?
                    textLength>0 && props.error.length == 0 ? 
                    <Icon name="good" color={Colours.SUCCESS} style= {{alignSelf:"flex-end", marginBottom:5, marginLeft:5}} />
                    // :<View style={{alignSelf:"flex-end", width:20, marginLeft:5, marginBottom:5}} ></View>
                    :null
                :null}
            </View>
            {showError && textLength > 0 && props.error != undefined && props.error != "" ? 
            <View style={{marginTop:2, flexDirection:"row"}}>
                <Icon name="bad" color={Colours.ERROR} style={{ marginRight: 5}} />
                <Text style={textStyles.error}>{props.error}</Text>
            </View>
            :null}
        </View>
        // </KeyboardAvoidingView>
    )
})

const styles = StyleSheet.create({
    container:{
        height:height,
        borderBottomWidth:1,
        paddingLeft:7,
        flexDirection:'row',
        flex:1
    },
    defaultLabel:{
        fontSize:18,
        fontFamily:"Barlow Semi Condensed Light",
        marginTop: 12,
        color:Colours.BLACK
    },
    topLabel:{
        marginTop:2,
        fontSize:14,
        fontFamily: "Barlow Semi Condensed Medium",
    },
    defaultContainer:{
        backgroundColor:Colours.VG_GREY_INPUT,
        borderBottomColor:Colours.VG_GREY_70,
    },
    focusContainer:{
        backgroundColor:Colours.VG_BLUE_INPUT,
    },
    filledContainer:{
        backgroundColor:Colours.VG_GREY_INPUT,
    },
    errorContainer:{
        backgroundColor:Colours.ERROR_INPUT,
    },
    asterisk:{
        color:Colours.ERROR,
        fontSize:18,
        fontFamily: "Barlow Semi Condensed Bold",
        marginTop:10,
        paddingLeft: 5
    },
    topAsterisk:{
        color:Colours.ERROR,
        fontSize:14,
        fontFamily: "Barlow Semi Condensed Bold",
        marginTop:2,
        paddingLeft: 5
    }
})

export default VGInput;