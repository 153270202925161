import moment from "moment";
import { Alert } from "react-native";
import { BleManager, Device } from "react-native-ble-plx";
import Toast from "react-native-tiny-toast";
import { GetData, GetOfflineStateParsed, StoreData } from "../../AsyncStorageHelper";
import { AddToQueue } from "../../Storage/OfflineQueue";
import {  ManualProgressTimes, MasterSample } from "../../Storage/UserState";
import { GenerateNumberID, GenerateNumberIDSync } from "../GeneralFunctions";
import { CheckCurrentSampleAssistStatus, UpdateManualSampleRecord } from "./ManualProgressFunctions";






/**
 * 
 * @param volume Volume based on what the user has inputted for a sample record
 * @param augerRate //The equipment auger rating
 * @param binSize //size of the bin
 * @returns // the time to use for the accusampler 
 */
export function CalculateTime(volume: number, augerRate: number, binSize: number): number {
  
  let resultSeconds = volume * 3600 / (binSize * augerRate);
  return resultSeconds;
}


/**
 * 
 * @param number converts any number to hexidecimal format,
 * @returns 3 returns 0300, this is what gets sent to the actual board
 */
export function ConvertStringToBase64(number: number): string {

  const Buffer = require("buffer").Buffer;
  let hex = number.toString(16);

  //If the length is an odd number, we wabt to pad with 0 since we always want 1 number to fill 2 bytes worth of data
  //probably a better way of doing this using modulus
  if (hex.length == 1) {
    hex = hex.padStart(2, '0');
  }

  if (hex.length == 3) {
    hex = hex.padStart(4, '0');
    hex = changeEndianness(hex);
  }
  if(hex.length == 5)
  {
    hex = hex.padStart(6, '0');
    hex = changeEndianness(hex);
  }
  if(hex.length == 7)
  {
    hex = hex.padStart(8, '0');
    hex = changeEndianness(hex);
  }


  const base64 = new Buffer(hex, 'hex').toString("base64");

  return base64;
}


/**
 * This function will reverse the bytes of a string 0003 -> 0300
 * We do this since the arduino controller used for the sample assist handles data in a different endian system than what react native uses
 * @param str the string to reverse
 * @returns the updated he value in reverse
 */
export function changeEndianness(str): string {
  const result = [];
  let len = str.length - 2;
  while (len >= 0) {
    result.push(str.substr(len, 2));
    len -= 2;
  }
  return result.join('');
}

//Converts an integer to a Byte
//Always converts to 4 bits and pads with 0's if neccessary
export function ConvertIntToByte(time: number) {

  //Use bit shifting operators to grab the upper and lower halfs of a byte
  let upper = (time >> 8).toString();
  let lower = (time & 1111).toString();
  if (upper.length == 1) {
    upper = upper.padStart(2, '0');
  }
  if (lower.length == 1) {
    lower = lower.padStart(2, '0');
  }
 let finalLower = parseInt(lower).toString(16);
  let finalStr = upper + finalLower;
  
  finalStr = changeEndianness(finalStr);
  console.log('///////////Recorded Times////////////');
  console.log('Upper: ' + upper + 'Lower: ' + finalLower, '    Hex: ' + finalStr);
  return finalStr;

}

//Convert a base 64 string to an actual value (0030 -> 3)
export function ConvertBase64ToValue(value: string) {
  const Buffer = require("buffer").Buffer;
  let hex = Buffer.from(value, 'base64').toString('hex');
  hex = changeEndianness(hex);

  let final = hexToInt(hex);

  return final;

}

/**
 * 
 * @param hex the hex value
 * @returns an integer
 */
export function hexToInt(hex) {
  //Is the length of the hex code string NOT divisible by 2?
  if (hex.length % 2 != 0) {
    //add a 0 to beginning of hex
    hex = "0" + hex;
  }
  //Parse the hex to number
  var num = parseInt(hex, 16);


  //Raise 2 to the power of the length of the hex diviced by 2 and multiplied by 8 bits
  var maxVal = Math.pow(2, hex.length / 2 * 8);

  //If the number is greater than the maximum value we divice by 2 and subtract 1
  if (num > maxVal / 2 - 1) {
    num = num - maxVal
  }
  return num;
}




//THIS MAY STILL BE NEEDED IF WE ARE DEALING WITH ARRAY CHARACTERISTICS
// export function ConvertBase64ArrayToValue(value: string): number[] {
//   let array: number[] = [];
//   const Buffer = require("buffer").Buffer;
//   let hex = Buffer.from(value, 'base64').toString('hex');



//   hex = changeEndianness(hex);


//   console.log('Hex from BT number: ' + hex);
//   let numArray: string[] = hex.split(/(..)/g).filter(s => s);

//   console.log(JSON.stringify(numArray));
//   for (let i = 0; i < numArray.length / 2; i++) {
//     let upper = parseInt(numArray[(i * 2) + 1], 16);
//     let lower = parseInt(numArray[i * 2], 16);
//     let number = (((lower & 0xFF) << 8) | (upper & 0xFF));
//     console.log('Cur number: ' + number);
//     array.push(number);
//   }

//   console.log('Numbers: ' + JSON.stringify(array));
//   return array;

// }

/**
 * 
 * @param connectedDevice The Bluetooth device object
 * @param serviceID The Service id used to read/write to
 * @param characteristicID The characteristic to read/write to
 * @param value //the value to write
 * @param alreadyInHex //Is the value already in hex format?
 */
export async function WriteCharacteristic(connectedDevice: Device, serviceID: string, characteristicID: string, value: number,alreadyInHex:boolean= false) {
  let finalValue = value.toString();

  if(!alreadyInHex)
  {
    finalValue = ConvertStringToBase64(value);
  }
  console.log('Final ValueL ' + finalValue);
  
  try {

    await connectedDevice.writeCharacteristicWithResponseForService(serviceID, characteristicID, finalValue).then(() => {
      console.log('SUCCESS');
    });
  }
  catch (error) {
    console.log('Failure');
    
    connectedDevice.connect().then((device) => {
      device.discoverAllServicesAndCharacteristics().then(() => {

        //device.writeCharacteristicWithResponseForService(BTSMARTSCOOPID,BTISPAUSED,'MQ==').then(() => console.log('Stopping the device!'));;   
        WriteCharacteristic(connectedDevice, serviceID, characteristicID, value);
      });
    });
    console.log(error);
  }

}

/**
 * 
 * @param connectedDevice The connected device ID
 * @param serviceID The Service ID
 * @param characteristicID The Characteristic ID
 * @returns 
 */
export async function ReadCharacteristic(connectedDevice:Device,serviceID:string,characteristicID:string):Promise<number|null>
{
  
  try
  {
    let val =  await connectedDevice.readCharacteristicForService(serviceID,characteristicID);
    const result = ConvertBase64ToValue(val.value);
    console.log('SUCCESSFUL READING!!! Reading: ' + result);
    return result;
  }
  catch(error)
  {
    console.log('Error Reading :(');
    return null;
  }

}


/**
 * 
 * @param masterSample The master sample used with the BT device
 * @param progressTimes - The Times to save
 * @returns a boolean - did it save or not
 */
export async function SaveProgress(masterSample: MasterSample, progressTimes: ManualProgressTimes[]): Promise<boolean> {

  try {

      //filter the times if its null      
      let times = progressTimes?.filter(x => x?.id == null);

      //update manual sample times
      let updateRecord = await UpdateManualSampleRecord(masterSample, times ?? []);
      
      if (updateRecord != null) {
        
        console.log('Updated Record nonSmartSample');
        return true;
      }
      else {
        Toast.show('Could not update record');
        return false;
      }
    }
  catch(e) {
    console.error("Encountered error: ", e);
  }

}



export function TakeSample(masterSample: MasterSample, userID: number):ManualProgressTimes {

  console.log('Current Time: ' + masterSample.time_current + '\n' + 'Target Time: ' + masterSample.target_time + '\n' + 'Time Elapsed: ' + (masterSample.target_time - masterSample.time_current));

  const curDate = new Date();
  //push a new progress time object
 const manualEntry:ManualProgressTimes = { id: GenerateNumberIDSync(userID), master_sample_id: masterSample.id, created_at: curDate, time: masterSample.target_time - masterSample.time_current, updated_at: curDate, user_id: userID };

 const res = AddToQueue('manual_progress_times',manualEntry,'add');

 if(res)
 {
  return manualEntry;
 }
 return null;

}

/**
 * This function will adjust the current time based on the datetime when phone was sleeping and return the time
 * @param manualSampleRecord - master sample
 * @param setManualSampleRecord  - the setter for the master sample
 * @param date - The date when phone was sleeping/last used
 */
export function AdjustTimeFromBackground(manualSampleRecord: MasterSample, setManualSampleRecord: React.Dispatch<React.SetStateAction<MasterSample>>, date: Date) {

  let oldRecord = manualSampleRecord;

  const differenceInSeconds = Math.abs((new Date().getTime() - date.getTime()) / 1000);

  console.log('difference in seconds is: ' + differenceInSeconds);


  console.log('Trying to adjust time ');
  oldRecord.time_current = oldRecord.time_current - Math.round(differenceInSeconds);
  setManualSampleRecord({ ...oldRecord });

}