import * as React from 'react';
import { useIsFocused } from '@react-navigation/native';
import { View, Text, TouchableOpacity, DeviceEventEmitter, Dimensions, Image, SectionList, ScrollView } from 'react-native';
import { UserInstrumentModel, InstrumentBrand, InstrumentModel, CharacteristicType, CharacteristicSubOption, InstrumentCharacteristic } from '../../Helpers/Storage/UserState';
import styles from '../../Styles/styles'
import Spinner from 'react-native-loading-spinner-overlay';
import { InstrumentModelsRaw, GetInstrumentsAndChars, GetUserInstruments, SaveUserInstrumentModels } from '../../Helpers/Functions/Offline/InstrumentFunctions';
import { GetData } from '../../Helpers/AsyncStorageHelper';
import Icon from 'react-native-vector-icons/FontAwesome';
import Toast from 'react-native-tiny-toast';
import AddInstrumentScreenUpdated from '../StackScreens/Updated/AddInstrumentScreen-Updated';
import { ValidID } from '../../Helpers/Functions/GeneralFunctions';
import VGButton from '../../Components/Button/Button';
function UpdatedInstrumentScreen(props:{fromSample?:boolean,OnClose:Function|null }) {
    //Keep track of 
    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height

    //Keep track of loading/saving
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    //Is the screen in view?
    const isFocused = useIsFocused();

    const [mode, setMode] = React.useState<'list' | 'add'>('list');

    const [userProfile, setProfile] = React.useState<string>();

    //Load backend data existing in the system
    //UserInstrumentData will load in selected models that the user has selected
    const [userInstrumentData, setUserInstrumentData] = React.useState<UserInstrumentModel[]>([]);
    //Existing models in the db
    const [instrumentModels, setInstrumentModels] = React.useState<InstrumentModel[]>([]);
    //Existing Brands in the db
    const [instrumentBrands, setInstrumentBrands] = React.useState<InstrumentBrand[]>([]);


    const [selectedBrands, setSelectedBrands] = React.useState<InstrumentBrand[]>([]);
    const [selectedModels, setSelectedModels] = React.useState<InstrumentModel[]>([]);


    const [showModelCharacteristics, setShowModelCharacteristics] = React.useState<InstrumentModel[]>([]);

    const [characteristicTypes,setCharacteristicTypes] = React.useState<CharacteristicType[]>([]);
    const [characteristicSubOptions,setCharacteristicSubOptions] = React.useState<CharacteristicSubOption[]>([]);
    const [instrumentCharacteristics,setInstrumentCharacteristics] = React.useState<InstrumentCharacteristic[]>([]);


    //This is used to get all the data we will be working with when we are on the instruments page
    React.useEffect(() => {
        if (isFocused) {
            const GetInstrumentData = async () => {
                //setIsLoading(true);

                try {
                    let data: InstrumentModelsRaw = {
                        brands: [],
                        instrument_models: [],
                        instrument_chars: [],
                        char_types: [],
                        char_sub_options: []
                    };
                    const profile = await GetData('Profile');
                    setProfile(profile);

                    data = await GetInstrumentsAndChars(profile);
                    let userModels = await GetUserInstruments(profile);
                    setUserInstrumentData(userModels);
                    let instrument_models = data.instrument_models.sort((a, b) => a.model_name.localeCompare(b.model_name));
                    let brands = data.brands.sort((a, b) => a.name.localeCompare(b.name));
          
                    setInstrumentBrands(brands);
                    //Sort the data if we have it
                    if (data != null) {
                        instrument_models.sort(function (a, b) {
                            //const valid = (b.valid ?? 0) - (a.valid ?? 0);
                            if (userProfile === a.profile && b.profile || a.profile && b.profile == null) {
                                const reA = /[^a-zA-Z]/g;
                                const reN = /[^0-9]/g;
                                const aA = a.model_name.replace(reA, "");
                                const bA = b.model_name.replace(reA, "");
                                if (aA === bA) {
                                    const aN = parseInt(a.model_name.replace(reN, ""), 10);
                                    const bN = parseInt(b.model_name.replace(reN, ""), 10);
                                    return aN === bN ? 0 : aN > bN ? 1 : -1;
                                } else {
                                    return aA > bA ? 1 : -1;
                                }
                            }
                        })
                        data.instrument_models = instrument_models;
                        setInstrumentModels(data.instrument_models);
                        setCharacteristicTypes(data.char_types);
                        setCharacteristicSubOptions(data.char_sub_options);
                        setInstrumentCharacteristics(data.instrument_chars);


                        const selectedModels = instrument_models.filter(x => {
                            if (userModels.find(j => j.model_id == x.id))
                                return x;
                        })
                        setSelectedModels([...selectedModels]);
                        setIsLoading(false);
                    }

                }
                catch {
                    console.log('code broke in try catch UpdatedInstrumentScreen: isFocued useEffect')
                }

            }
            GetInstrumentData();
        }
        else {

            setSelectedBrands([]);
            setSelectedModels([]);
            setMode('list');
        }

    }, [isFocused])

    async function onSavePress() {

        const profile = await GetData('Profile');


        const status = await SaveUserInstrumentModels(selectedModels, profile);

        if (status != null) {
          
            if(props?.fromSample)
            {
                try
                {
                    props.OnClose(status.userInstrumentToAdd,status.userInstrumentToRemove);
                }
                catch
                {

                }
            }
            else
            {
                DeviceEventEmitter.emit('GoToMainMenu');
            }

        }
        else {

            Toast.show('Could not update Instrument list at this time');
        }
    }

    function OnRequestPress() {
        console.log('adding!');
        setMode('add');
    }

    return (
        <View style={{ flex: 1, justifyContent: 'space-evenly', marginHorizontal: 10 }}>

            {mode == 'list' ?
                <View style={{ flex: 1, justifyContent: 'space-evenly' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
                        <Text style={{ color: 'black', fontSize: 28, alignSelf: 'center' }}>Instrument Master List</Text>
                    </View>

                    <ScrollView contentContainerStyle={{ marginHorizontal: 10, marginVertical:5, paddingBottom: 10 }}>



                        {instrumentBrands.map((brand, index) => {
                            return <View key={index}>

                      
                                    <TouchableOpacity
                                        accessibilityLabel={brand.name}
                                        style={{ flex: 1, flexDirection: 'row', marginVertical: 5 }} onPress={() => {
                                            let oldBrandList = selectedBrands;

                                            const index = oldBrandList.findIndex(x => x.id == brand.id);
                                            if (index != -1) {
                                                console.log('removing');
                                                oldBrandList.splice(index, 1);
                                            }
                                            else {
                                                console.log('adding');
                                                oldBrandList.push(brand);
                                            }
                                            setSelectedBrands([...oldBrandList]);
                                        }} >
                                        {/* <Image style={{ left: 10, width: 30, height: 30, borderRadius: 5, overflow: 'hidden' }} source={require('../../../../Icons/BlueInstrument.png')} /> */}
                                        <Text style={{ fontSize: 24, flex: 1, color: selectedModels.find(x => x.brand_id == brand.id) ? 'orange' : 'black' }}>{brand.name}</Text>
                                        <Icon style={{ marginRight: 5, paddingRight: 10, paddingLeft: 10, justifyContent: 'flex-end' }} name={selectedBrands.find(x => x.id == brand.id) ? "chevron-up" : "chevron-down"} size={30} color="#1c448c" />
                                    </TouchableOpacity>
                                



                                {selectedBrands.find(x => x.id == brand.id) ?
                                    instrumentModels.filter(x => x.brand_id == brand.id).map((model, index) => {
                                        return <View>
                                            <View style={{ flexDirection: 'row', flex: 1, marginVertical: 10, left: 40 }}>

                                                <TouchableOpacity
                                                   accessibilityLabel={model.model_name}
                                                    //Last left off here, If we uncheck a model, it should be removed from the UserInstrumentModels table.
                                                    style={{}} onPress={() => {

                                                        let oldInstrumentModelList = selectedModels;

                                                        const index = oldInstrumentModelList.findIndex(x => x.id == model.id);
                                                        if (index != -1) {
                                                            console.log('removing');
                                                            oldInstrumentModelList.splice(index, 1);
                                                        }
                                                        else {
                                                            console.log('adding');

                                                            oldInstrumentModelList.push(model);
                                                        }
                                                        setSelectedModels([...oldInstrumentModelList]);
                                                    }}>
                                                    {selectedModels.find(x => x.id == model.id) != undefined ?
                                                        <Icon
                                                            accessible={true} accessibilityLabel={model?.model_name+'_Checked'??''}
                                                            style={{}} name="check-square" size={35} color="black" />
                                                        :
                                                        <Icon
                                                        accessible={true} accessibilityLabel={model?.model_name+'_Unchecked'??''}
                                                        style={{}} name="square-o" size={35} color="black" />
                                                    }
                                                </TouchableOpacity>

                                                <TouchableOpacity

                                                    accessible={true} accessibilityLabel={model?.model_name??''}
                                                    onPress={() => {
                                                        let oldInstrumentModelList = showModelCharacteristics;

                                                        const index = oldInstrumentModelList.findIndex(x => x.id == model.id);
                                                        if (index != -1) {
                                                            oldInstrumentModelList.splice(index, 1);
                                                        }
                                                        else {

                                                            oldInstrumentModelList.push(model);
                                                        }
                                                        setShowModelCharacteristics([...oldInstrumentModelList]);
                                                    }}
                                                    style={{ marginVertical: 5, left: 20, flexDirection: 'row' }}>
                                                    <Text style={{ fontSize: 18, color: selectedModels.find(x => x.id == model.id) ? 'orange' : 'black' }}>{model.model_name}</Text>
                                                    <Icon style={{ marginRight: 5, paddingRight: 10, paddingLeft: 10 }} name={showModelCharacteristics.find(x => x.id == model.id) ? "chevron-up" : "chevron-down"} size={30} color="#1c448c" />
                                                </TouchableOpacity>
                                            </View>

                                            {showModelCharacteristics.find(x => x.id == model.id) ?
                                                <View style={{ width: 300, borderColor: 'lightgrey', borderWidth: 2, marginVertical: 5, paddingVertical: 10, paddingLeft: 5, paddingRight: 5, left: 40 }}>
                                                    <Text style={{ fontSize: 18, alignSelf: 'center' }}>Characteristics</Text>

                                                    {instrumentCharacteristics.filter(x => x.instrument_model_id == model.id).map(char => {
                                                        return <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-start' }}>
                                                            <Text
                                                                accessible={true} accessibilityLabel={characteristicSubOptions.find(x=>x.id == char.characteristic_sub_id)?.name??''}
                                                                style={{ fontSize: 18, textAlign: 'left', alignSelf: 'flex-start' }}>
                                                                {characteristicSubOptions.find(x=>x.id == char.characteristic_sub_id)?.name??''}
                                                            </Text>
                                                        </View>
                                                    })}
                                                </View>

                                                : null}

                                        </View>
                                    })
                                    : null}
                            </View>
                        })}

                    </ScrollView>
                    
                    <VGButton role='PRIMARY' buttonStyle={{marginBottom:10}}
                    onPress={onSavePress} >Save</VGButton>
                    <VGButton role='SECONDARY' buttonStyle={{marginBottom:10}}
                    onPress={OnRequestPress} >Request New Instrument</VGButton>

                </View>
                : null}

            {mode == 'add' ?
                <AddInstrumentScreenUpdated mode={mode} setVisible={() => {
                    setMode('list');
                }}
                    user_instrument_data={userInstrumentData}
                    setUserInstrumentData={setUserInstrumentData}
                    instrument_brands={instrumentBrands} setInstrumentBrands={setInstrumentBrands}
                    instrument_models={instrumentModels} setInstrumentModels={setInstrumentModels}
                    characteristicTypes={characteristicTypes}
                    characteristicSubOptions={characteristicSubOptions}
                    instrumentCharacteristics={instrumentCharacteristics} setInstrumentCharacteristics={setInstrumentCharacteristics} />
                : null}

        </View>

    );
}

export default UpdatedInstrumentScreen;