import { CombinedSampleRecord, MasterSample, SubSample } from "../../../Helpers/Storage/UserState";
import React from "react";
import { View, Text, TouchableOpacity, Dimensions, FlatList } from 'react-native';
import { Overlay } from "react-native-elements";
import moment from "moment";
import { GetNamesOfRelatedData, RelatedSampleDataMap } from "../../../Helpers/Functions/Offline/SampleFunctions";
import { GetData } from "../../../Helpers/AsyncStorageHelper";
import VGButton from "../../../Components/Button/Button";

function ViewSampleList(props: {barcodeLookup:{master_sample_id:number,barcode:number}[],setBarcodeLookup:any, currentState: boolean, setCurrentState: any, sampleList: MasterSample[],combinedSampleRecords:CombinedSampleRecord[], setCombinedSampleRecords:any }) {
    const width = Dimensions.get('window').width; //full width
    const height = Dimensions.get('window').height; //full height

    const [relatedSampleData, setRelatedSampleData] = React.useState<RelatedSampleDataMap>(new Map());

    React.useEffect(() => {
      
        const loadData = async () => {
            try
            {
                const data = await GetNamesOfRelatedData(props.sampleList);
            
            //    console.log(JSON.stringify(data));
                setRelatedSampleData(data);
            }
            catch(error)
            {
                console.log('Error when grabbing related data: ' + error + '\n\n' + 'Line#: ' + error);
            }
    
        }
        loadData();
    }, [props.sampleList]);

    async function onSelectPress(item){
        const profile = await GetData('Profile');
        const userID = await GetData('ID');
        let oldList = props.combinedSampleRecords;
        
        if(item != null)
        {
            oldList.push({id:null,created_at:null,updated_at:null,user_id:parseInt(userID),master_sample_id:null,parent_sample_id:item.id??null})
            let oldBarcodeLookup = props.barcodeLookup;
            oldBarcodeLookup.push({master_sample_id:item.id,barcode:item.barcode});
            props.setBarcodeLookup([...oldBarcodeLookup]);
        }
        else
        {
            oldList.push({id:null,created_at:null,updated_at:null,user_id:parseInt(userID),master_sample_id:null,parent_sample_id:item.id??null})
            let oldBarcodeLookup = props.barcodeLookup;
            oldBarcodeLookup.push({master_sample_id:item.id,barcode:item.barcode});
            props.setBarcodeLookup([...oldBarcodeLookup]);
        }
        
      //  oldList.push({sample:item,subSample:item.subSample});

        props.setCombinedSampleRecords([...oldList]);
        props.setCurrentState(1);     
    }

    return (
        <Overlay isVisible={props.currentState} onBackdropPress={() => { props.setCurrentState(1) }}>

            <View style={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: width / 1.1, height: height / 1.1
            }}>

                <View style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3, marginTop: 10 }}>
                    <Text style={{ fontSize: 28, flex: 1, color: 'black', textAlign: 'center' }}>Pick Sample</Text>
                </View>
                {props.sampleList.length > 0 ?
                 <FlatList
                 style={{ flex: 1, alignSelf: 'stretch' }}
                 data={props.sampleList}
                 keyExtractor={(item, index) => index.toString()}
                 renderItem={({ item }: { item: MasterSample}) => (


                     <View style={{ borderColor: 'lightgrey', borderWidth: 2, marginVertical: 10, padding:5}}>
                         <View style={{ flexDirection: 'row', paddingVertical: 5, flex: 1 }}>
                             <Text style={{ fontSize: 18, left: 5, flex: 1 }}>Barcode: </Text>
                             <Text style={{ fontSize: 18, right: 5 }}>{item.barcode ?? 'N/A'}</Text>
                         </View>


                         <View style={{ flexDirection: 'row', paddingVertical: 5, flex: 1 }}>
                             <Text style={{ fontSize: 18, left: 5, flex: 1 }}>Origin: </Text>
                             <Text style={{ fontSize: 18, right: 5 }}>{relatedSampleData?.get(item.id ?? 0)?.source_name ?? 'N/A'}</Text>
                         </View>

                         <View style={{ flexDirection: 'row', paddingVertical: 5, flex: 1 }}>
                             <Text style={{ fontSize: 18, left: 5, flex: 1 }}>Destination: </Text>
                             <Text style={{ fontSize: 18, right: 5 }}>{relatedSampleData?.get(item.id ?? 0)?.destination_name ?? 'N/A'}</Text>
                         </View>

                         <View style={{ flexDirection: 'row', paddingVertical: 5, flex: 1 }}>
                             <Text style={{ fontSize: 18, left: 5, flex: 1 }}>Grain: </Text>
                             <Text style={{ fontSize: 18, right: 5 }}>{relatedSampleData?.get(item.id ?? 0)?.grain_name ?? 'N/A'}</Text>
                         </View>

                         <View style={{ flexDirection: 'row', paddingVertical: 5, flex: 1 }}>
                             <Text style={{ fontSize: 18, left: 5, flex: 1 }}>Created At: </Text>
                             <Text style={{ fontSize: 18, right: 5 }}>{moment(item.created_at).format('LLL')}</Text>
                         </View>

                         <VGButton role="SECONDARY" onPress={()=>{onSelectPress(item);}}
                         >Select</VGButton>

                     </View>



                 )} />
                :
                <View style={{flex:1}}>
                <Text style={{ fontSize: 18, color: 'black', textAlign: 'center',marginTop:20 }}>No Samples Found</Text>
            </View>
                }
               

                <VGButton role="CANCEL" onPress={() => {
                    props.setCurrentState(1);
                }}>Close</VGButton>

            </View>

        </Overlay>
    );
}
export default ViewSampleList;