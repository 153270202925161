import * as React from 'react';
import { View, Text, TextInput, Button, StyleSheet, ImageBackground } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
const image = { uri: "/Users/lenniebudgell/Documents/SKPLY/verigrain/vgrepos/testreact/vgtest/assets/bgmenu.png" }

function ResetPasswordScreen({route,navigation}) {
  const {email} = route.params; 
    return (
    <View style={{flex:1}}>
      <ImageBackground source={image} style={styles.image}>
        <Text>Password reset sent for {JSON.stringify(email)}</Text>
        <Button title={"Return To Login"} onPress={() => navigation.navigate('Login')} />
      </ImageBackground>
    </View>
    )
  }
  export default ResetPasswordScreen;