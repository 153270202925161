import { AllowedSample } from "../../Storage/UserState";
import { ApiCall } from "../GeneralFunctions";




export async function GetAllowedSamples(profile:string) : Promise<{ id: number; sample_id: number; sample_type: string; enterprise_company_id: number; grower_id: number; grower_profile: string;created_at:Date;updated_at:Date; }[]>
{

    let allowedSampleList:{ id: number; sample_id: number; sample_type: string; enterprise_company_id: number; grower_id: number; grower_profile: string; created_at:Date; updated_at:Date; }[] = [];

let link =  "https://webservice.verigrain.com/api/get-allowed-samples?profile=" + profile;

  console.log(link);

  //Token header to keep requests secure
  var myHeaders = new Headers();
  myHeaders.append(
    'token',
    '$2y$12ri75v9amOGZgTs2oTK3mZe1YEOburB7BJfJ64M9QKAM5YTggvoPVa',
  );
//grab the api and check if email exists
var requestOptions = {
method: 'POST',
headers: myHeaders,
};
const getSampleData = () => fetch(link, requestOptions)
.then(response => response.json())
.then(result => {
  return result;
})
.catch(error => {
  console.log('error with field', error);
  return 'error';
});



  const sampleData = await getSampleData();


  if(sampleData.response == 0)
  {

   sampleData.allowedSamples.forEach(sample => {
       allowedSampleList.push(sample);
       
   });
   return allowedSampleList;
  }
  else
  {
    return null;
  }
}

export async function SaveAllowedSamples(samples:AllowedSample[]):Promise<AllowedSample[]>
{
  const data = new FormData();
  data.append('allowed_samples', JSON.stringify(samples));


  const status = await ApiCall<{ response: 0 | 1, allowed_samples:AllowedSample[]}>("https://webservice.verigrain.com/api/save-allowed-samples", data,true);

    if(status.response == 0)
    {
      return status.allowed_samples;
    }
    else
    {
      return null;
    }

}
export async function DeleteAllowedSample(sampleID: number, sampleType: string, profile: string,enterprise_id:number|null) {


    let link =  "https://webservice.verigrain.com/api/delete-allowed-sample";

    console.log(link);
    const formData = new FormData();

    formData.append('ID',sampleID.toString());
    formData.append('SampleType',sampleType.toString());
    formData.append('Profile',profile.toString());

    if(enterprise_id != null)
    {
      formData.append('enterprise_id',enterprise_id.toString());
    }
   
  
    //Token header to keep requests secure
    var myHeaders = new Headers();
    myHeaders.append(
      'token',
      '$2y$12ri75v9amOGZgTs2oTK3mZe1YEOburB7BJfJ64M9QKAM5YTggvoPVa',
    );
  //grab the api and check if email exists
  var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body:formData
  };
  const getSampleData = () => fetch(link, requestOptions)
  .then(response => response.json())
  .then(result => {
    return result;
  })
  .catch(error => {
    console.log('Could not delete allowed Sample: ', error);
    return 'error';
  });
  
  
  
    const sampleData = await getSampleData();
  
  
    if(sampleData.response == 0)
    {
  
        return true;
    }

    else
    {
      return null;
    }
}