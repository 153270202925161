import { el } from "date-fns/locale";
import { sample } from "lodash";
import moment from "moment";
import { Alert } from "react-native";
import { SampleDate } from "../../../Screens/StackScreens/PrimarySampleScreens/SampleSelectorScreen";
import { GetData, GetOfflineStateParsed, ReplaceDataInOfflineState } from "../../AsyncStorageHelper";
import { AddToQueue } from "../../Storage/OfflineQueue";
import { AllowedSample, Bin, BinLocation, Buyer, CharacteristicSubOption, CharacteristicType, Enterprise, Field, GrainType,GrainSubCrop, GrainVariety, Lab, MasterSample, PendingAnalysis, SampleRecordCharacteristic, SampleDestination, Document, SampleEquipment, SampleGrainVariety, SampleImage, SampleSource, SubSample, CombinedSampleRecord, UserEquipment, UserInstrumentModel } from "../../Storage/UserState";
import { GenerateNumberID, GenerateNumberIDSync, ValidID } from "../GeneralFunctions";
import { GetGrainAndSubCropTypes } from "../Grains";



function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
}

export async function GetSearchData(profile: string): Promise<{ bins: Bin[]; bin_locations: BinLocation[]; fields: Field[]; buyers: Buyer[]; grainData: {grainType:GrainType,grainSubCrop:GrainSubCrop}[]; grain_varieties: GrainVariety[]; sampleDates: {id:number, date:number}[]; enterprises: Enterprise[]; }> {
 
 
  const sampleSources = (await GetOfflineStateParsed("sample_source"));
  const sourceBinIds = sampleSources.filter(x => x.source_bin_id != null).map(x => x.source_bin_id);
  const sourceBins = (await GetOfflineStateParsed("bins")).filter(x => sourceBinIds.indexOf(x.id) !== -1);
  const sourceBinLocationIds = sourceBins.map(x => x.location_id).filter(x => x != null);
  const sourceBinLocations = (await GetOfflineStateParsed("bin_locations")).filter(x => sourceBinLocationIds.indexOf(x.id) !== -1);

  const sampleDestinations = (await GetOfflineStateParsed("sample_destination"));
  const destinationBinIds = sampleDestinations.filter(x => x.destination_bin_id != null).map(x => x.destination_bin_id);
  const destinationBins = (await GetOfflineStateParsed("bins")).filter(x => destinationBinIds.indexOf(x.id) !== -1);
  const destinationBinLocationIds = destinationBins.map(x => x.location_id).filter(x => x != null);
  const destinationBinLocations = (await GetOfflineStateParsed("bin_locations")).filter(x => destinationBinLocationIds.indexOf(x.id) !== -1);

  const sourceFieldIds = sampleSources.filter(x => x.source_field_id != null).map(x => x.source_field_id);
  const sourceFields = (await GetOfflineStateParsed("fields")).filter(x => sourceFieldIds.indexOf(x.id) !== -1);

  const destinationBuyerIds = sampleDestinations.filter(x => x.destination_buyer_id != null).map(x => x.destination_buyer_id);
  const destinationBuyers = (await GetOfflineStateParsed("buyers")).filter(x => destinationBuyerIds.indexOf(x.id) !== -1);

  //get the first enterprise
  //TODO: handle multiple enterprises (this shouldn't ever happen but just in case)
  const enterprises = (await GetOfflineStateParsed("enterprises")).filter(x => x.id == sampleDestinations.map(y => y.destination_enterprise_id).shift());

  const sampleGrainVarieties = (await GetOfflineStateParsed("sample_grain_varieties"));

  //converting to a Set is the most efficient way to get unique values
  //the Set Has method is also slightly more efficient than using indexOf
  const grains = GetGrainAndSubCropTypes();
  const allMaster  = GetOfflineStateParsed('master_samples');


  const masterSamples = allMaster.sort((a, b) => b.id - a.id).slice(0, 10);

  const sampleDates = masterSamples.map(x => {

    if(x.sample_taken_at == null)
    {
      const localTime = convertUTCDateToLocalDate(new Date(x.created_at));

      return {id:x.id, date: parseInt((localTime.getTime() / 1000).toFixed(0))};
    }
    else
    {
    //  var date = new Date(x.sample_taken_at * 1000);
      //const localTime = convertUTCDateToLocalDate(new Date(date));

      return {id:x.id, date:x.sample_taken_at};

    }

 

    });

    let fullLocations = sourceBinLocations.concat(destinationBinLocations);
    const uniqueLoc = [...new Map(fullLocations.map(item =>
      [item['id'], item])).values()]
    let fullBins = sourceBins.concat(destinationBins);
    const uniqueBin = [...new Map(fullBins.map(item =>
      [item['id'], item])).values()]
  const searchData = {
    bins: uniqueBin,
    bin_locations: uniqueLoc,
    fields: sourceFields,
    buyers: destinationBuyers,
    grainData: grains.types,
    grain_varieties: grains.varieties,
    sampleDates,
    enterprises
  };

  return searchData;
}


const searchByVals = ["Bin", "Field", "Buyer", "Enterprise", "Barcode", "Grain", "Date", "Last", "Sequential"] as const;
/**
 * Different options for searching the samples
 */
type SearchOptions = typeof searchByVals[number];


export function GetMasterSampleRecords(searchBy:SearchOptions,id:string)
{
  let masterSampleList:MasterSample[] = [];
  
  const allSub = GetOfflineStateParsed('sub_samples');
  console.log('Search: ' + searchBy);
  switch (searchBy) {
    case 'Last':
      const primaryList = GetOfflineStateParsed("master_samples");
      const lastMaster =  primaryList[0];

      console.log('Last: ' + JSON.stringify(lastMaster));
      masterSampleList =  [lastMaster];

      break;
    case 'Sequential':
      break;
    case 'Barcode':
      masterSampleList =   GetOfflineStateParsed("master_samples").filter(x => x.barcode == parseInt(id));

      break;
    case 'Date':
    masterSampleList = GetOfflineStateParsed("master_samples").filter(x => x.id == parseInt(id));
      break;
    case 'Grain':
      const grainIDSplit = id.split(',');
        masterSampleList =  GetOfflineStateParsed("master_samples").filter(function(master){

          if(master.grain_type_id == parseInt(grainIDSplit[0]) && master.sub_crop_id == parseInt(grainIDSplit[1]))
          {
              return master;
          }
      });
      break;
    //set the search columns
    case 'Bin':
        const sample_bin_source =GetOfflineStateParsed("sample_source").filter(function(sourceBinList){

          if(parseInt(id) == sourceBinList.source_bin_id)
          {
              return sourceBinList;
          }
     });
        const sample_bin_destination = GetOfflineStateParsed("sample_destination").filter(function(destinationBinList){

          if(parseInt(id) == destinationBinList.destination_bin_id)
          {
              return destinationBinList;
          }
     });
     masterSampleList =GetOfflineStateParsed("master_samples").filter(function(master){

          if( sample_bin_source.find(x => x.master_sample_id == master.id) || sample_bin_destination.find(x => x.master_sample_id == master.id) )
          {
              return master;
          }
      });
      masterSampleList = masterSampleList.filter(function(master){

        if( allSub.find(x => x.master_sample_id == master.id) )
        {
          console.log('Found sub sample in bin search... removing');
          
        }
        else
        {
          return master;
        }
    });
      break;
    case 'Field':
      const fieldList = id.split(',');
      const sample_source =  GetOfflineStateParsed("sample_source").filter(function(sampleSource){

        if(fieldList.find(x =>parseInt(x) == sampleSource.source_field_id) )
        {
            return sampleSource;
        }
   });
        masterSampleList =  GetOfflineStateParsed("master_samples").filter(function(master){

          if( sample_source.find(x => x.master_sample_id == master.id))
          {
              return master;
          }
      });
      masterSampleList = masterSampleList.filter(function(master){

        if( allSub.find(x => x.master_sample_id == master.id) )
        {
          console.log('Found sub sample in bin search... removing');
          
        }
        else
        {
          return master;
        }
    });
      break;
    case 'Buyer':
          const sample_buyer_destination =   GetOfflineStateParsed("sample_destination").filter(function(destinationBuyerList){

            if(parseInt(id) == destinationBuyerList.destination_buyer_id)
            {
                return destinationBuyerList;
            }
      });
      masterSampleList =   GetOfflineStateParsed("master_samples").filter(function(master){

        if( sample_buyer_destination.find(x => x.master_sample_id == master.id))
        {
            return master;
        }
        });
        masterSampleList = masterSampleList.filter(function(master){

          if( allSub.find(x => x.master_sample_id == master.id) )
          {
            console.log('Found sub sample in bin search... removing');
            
          }
          else
          {
            return master;
          }
      });
      break;
    case 'Enterprise':
      const sample_enterprise_destination =  GetOfflineStateParsed("sample_destination").filter(function(destinationEnterpriseList){

        if(parseInt(id) == destinationEnterpriseList.destination_enterprise_id)
        {
            return destinationEnterpriseList;
        }
  });
  masterSampleList =  GetOfflineStateParsed("master_samples").filter(function(master){

    if( sample_enterprise_destination.find(x => x.master_sample_id == master.id))
    {
        return master;
    }
    });
    masterSampleList = masterSampleList.filter(function(master){

      if( allSub.find(x => x.master_sample_id == master.id) )
      {
        console.log('Found sub sample in bin search... removing');
        
      }
      else
      {
        return master;
      }
  });
      break;
  }
  return masterSampleList;
}

/**
 * 
 * @param searchBy What parameter to search the samples with
 * @param id The ID of the related search parameter
 * @param sampleType Primary, Sub, or Combined sample type
 * @param profile The user's profile
 * @param grainVarIDList A list of grain varieties
 * @returns A list of samples
 */
export async function GetSampleRecords(searchBy: SearchOptions, id: string, sampleType: string | null, profile: string, grainVarIDList: [0] | number[] | null): Promise<{masterSamples:MasterSample[], sampleCharacteristics: SampleRecordCharacteristic[], documents: Document[], images: SampleImage[], subSamples: SubSample[], allowedSamples: AllowedSample[], pendingAnalysis: PendingAnalysis[], grains: SampleGrainVariety[] }> {
  console.log("Searching by: ", searchBy);
  let sampleDataList: {  sampleCharacteristics: SampleRecordCharacteristic[], documents: Document[], images: SampleImage[], subSamples: SubSample[], allowedSamples: AllowedSample[], pendingAnalysis: PendingAnalysis[], grains: SampleGrainVariety[],masterSamples:MasterSample[] }
    = {
    sampleCharacteristics: [],
    subSamples: [],
    allowedSamples: [],
    grains: [],
    pendingAnalysis: [], // TODO: implement
    documents: [], // online only
    images: [], // online only
    masterSamples:[]
  };

  /**
   * Algorithm Breakdown
   * 
   * Samples can retrieved by one of several criteria
   * - Last Sample Taken (Last)
   * - Previous barcode taken (sequential)
   * - An exact Barcode
   * - A specific Date/Time
   * - The Grain used
   * - The Field used
   * - The Bin used
   * - The Buyer used
   * - The Enterprise used
   * 
   * All three sample records are tied to a Master Sample record
   * We first want to find the relevant master sample records
   * Using the master sample records we can find the primary and sub sample (in the future combined sample) records
   * Once the sample records are found we can find all related data for those sample records
   */

  let source_column: "source_bin_id" | "source_field_id";
  let destination_column: "destination_bin_id" | "destination_buyer_id" | "destination_enterprise_id";
  const all_master_samples = (await GetOfflineStateParsed("master_samples")).filter(x => x.profile === profile);


  const all_sub_samples = (await GetOfflineStateParsed("sub_samples")).filter(function(array_el){
    return all_master_samples.filter(function(anotherOne_el){
       return anotherOne_el.id == array_el.master_sample_id;
    }).length == 0
 });

 const lastMaster = all_master_samples.find(x => x.barcode.toString().match(/^[1-3]{1}[0-9]{7}$/) != null);

 
  let master_sample_ids = new Set<number>();

  switch (searchBy) {
    case 'Last':

    

      console.log('Last: ' + JSON.stringify(lastMaster));

      master_sample_ids.add(lastMaster.id);
      break;
    case 'Sequential':
      // Here we want to find the sample with the previous barcode
      const number_barcode = parseInt(id);
      const last_sample = all_master_samples.find(x => x.barcode === (number_barcode - 1));

      if (last_sample != null) {
        master_sample_ids.add(last_sample.id);
      }
      break;
    case 'Barcode':
      //Convert the barcode to an integer
      const barcode_num = parseInt(id);
      //Find all master samples with that barcode
      const found_master_samples = (await GetOfflineStateParsed("master_samples")).filter(x => x.barcode === barcode_num && x.profile === profile);
      master_sample_ids = new Set(found_master_samples.map(x => x.id));
      break;
    case 'Date':
      const number_id = parseInt(id);
      if (ValidID(number_id)) {
        master_sample_ids.add(number_id);
      }
      break;
    case 'Grain':
      const all_vars = await GetOfflineStateParsed("sample_grain_varieties");

      //no variety selected
      if (grainVarIDList.length == 0 || (grainVarIDList.length === 1 && grainVarIDList[0] === 0)) {
        master_sample_ids = new Set(all_vars.filter((x) => x.grain_type_id == parseInt(id)).map(x => x.master_sample_id));
      }
      else {
        master_sample_ids = new Set(all_vars.filter((x) => x.grain_type_id == parseInt(id) &&(grainVarIDList as number[]).indexOf(x.grain_variety_id) !== -1).map(x => x.master_sample_id));
      }
      break;
    //set the search columns
    case 'Bin':
      source_column = "source_bin_id";
      destination_column = "destination_bin_id";
      break;
    case 'Field':
      source_column = "source_field_id";
      break;
    case 'Buyer':
      destination_column = "destination_buyer_id";
      break;
    case 'Enterprise':
      destination_column = "destination_enterprise_id";
      break;
  }

  //if we are searching either the sample sources or destinations
  if (source_column != null) {
    (await GetOfflineStateParsed("sample_source")).filter((x) => x[source_column] == parseInt(id)).forEach(x => master_sample_ids.add(x.master_sample_id));    
  }

  if (destination_column != null) {
    (await GetOfflineStateParsed("sample_destination")).filter((x) => x[destination_column] == parseInt(id)).forEach(x => master_sample_ids.add(x.master_sample_id));
  }

  // First add the master sample ids of the sub samples parents
  // If the search criteria finds a sub sample directly then we want to also grab the parent primary sample
  all_sub_samples.filter(x => master_sample_ids.has(x.master_sample_id)).forEach(x => master_sample_ids.add(x.parent_sample_id));

  const master_filter = (x) => master_sample_ids.has(x.master_sample_id);

  // Now we need to get the data related to the master samples
  const samples = all_master_samples.filter(master_filter);
  const sample_ids = new Set(samples.map(x => x.id));
  const sub_samples = all_sub_samples.filter(x => master_sample_ids.has(x.master_sample_id) || master_sample_ids.has(x.parent_sample_id));

  // Sub samples may have been picked up from their parent being search but we need to add their master ids to the list
  // so that we can get the related data for them too
  sub_samples.forEach(x => master_sample_ids.add(x.master_sample_id));
  const sampleCharacteristics = (await GetOfflineStateParsed("sample_record_characteristics")).filter(master_filter);

  sampleDataList = {
    subSamples: sub_samples,
    sampleCharacteristics,
    allowedSamples: (await GetOfflineStateParsed("allowed_samples")).filter(master_filter),
    grains: (await GetOfflineStateParsed("sample_grain_varieties")).filter(master_filter),
    documents: [],
    images: [],
    pendingAnalysis: [] //TODO: implement,
    ,masterSamples:all_master_samples
  };

  return sampleDataList;

}

async function GetRelatedSampleInfo(samples: MasterSample[]): Promise<{ sampleCharacteristics: SampleRecordCharacteristic[], subSamples: SubSample[], allowedSamples: AllowedSample[], grains: SampleGrainVariety[] }> {
  let master_sample_ids = new Set(samples.map(x => x.id));
  const sample_ids = new Set(samples.map(x => x.id));

  const master_filter = (x) => master_sample_ids.has(x.master_sample_id);

  const subSamples = (await GetOfflineStateParsed("sub_samples")).filter((x) => master_sample_ids.has(x.parent_sample_id));
  master_sample_ids = new Set([...master_sample_ids, ...subSamples.map(x => x.master_sample_id)]);
  const sampleCharacteristics = (await GetOfflineStateParsed("sample_record_characteristics")).filter(master_filter);
  const allowedSamples = (await GetOfflineStateParsed("allowed_samples")).filter(master_filter);
  const grains = (await GetOfflineStateParsed("sample_grain_varieties")).filter(master_filter);

  return {
    allowedSamples,
    sampleCharacteristics,
    subSamples,
    grains
  };
}

export async function GetSampleMetaData(samples: MasterSample[]): Promise<{ metaData: { sampleSource: SampleSource[], sampleDestination: SampleDestination[], sampleGrain: SampleGrainVariety[], sampleEquipment: SampleEquipment[] } }> {
  const sample_source = await GetOfflineStateParsed("sample_source");
  const sample_destination = await GetOfflineStateParsed("sample_destination");
  const sample_grain_varieties = await GetOfflineStateParsed("sample_grain_varieties");
  const sample_equipment = await GetOfflineStateParsed("sample_equipment");

  const sample_ids = samples.map(x => x.id);

  const filter_func = (x) => sample_ids.findIndex(y => y == x.master_sample_id) != -1;

  const metaData = {
    sampleSource: sample_source.filter(filter_func),
    sampleDestination: sample_destination.filter(filter_func),
    sampleGrain: sample_grain_varieties.filter(filter_func),
    sampleEquipment: sample_equipment.filter(filter_func),
  }
  return { metaData };
}


//This function will calculate the sample time using the following parameters
export function GetSampleTime(containerSize: string, SEM_Model: string, volume: number, bushels_hr_rate: string, cropFactor: number): number {
  if (SEM_Model == '') {
    return 0;
  }
  //remember that volume = bushels_represented
  let sampleTime = 0.0;
  let pailFactor = 0.0;
  switch (containerSize) {
    case '2 Gal':
      pailFactor = 0.2033;
      GetTime();
      break;
    case '3.5 Gal':
      pailFactor = 0.3365;
      GetTime();
      break;
    case '5 Gal':
      pailFactor = 0.5040;
      GetTime();
      break;
    case '1 Kg':
      pailFactor = 0.02837;
      GetTime();
      break;
  }
  //round to 1 decimal place, converts to string so parse it as a float
  sampleTime = Math.round(sampleTime * 1e2) / 1e2;

  //return sampleTime
  return sampleTime;

  //This inner function will do some more complex calculations to grab the sample time
  //You may have to do more to convert stuff to doubles? 
  function GetTime() {
    switch (SEM_Model) {

      //CHANGE THE pailFactor/x => x is the constant when using the scoop!
      case "10 inch":
        sampleTime = (volume * 3600) / ((pailFactor / .001069) * parseFloat(bushels_hr_rate));
        break;
      case "13 inch":
        sampleTime = (volume * 3600) / ((pailFactor / .001637) * parseFloat(bushels_hr_rate)); break;
      case "16 inch":
        sampleTime = (volume * 3600) / ((pailFactor / .002121) * parseFloat(bushels_hr_rate)); break;
    }
  }
}

/**
 * 
 * @param sampleTime Sample Time
 * @returns a number between 0 and 30
 */
export function GetDialData(sampleTime: number): number {
  let dial = 0;
  let baseNumber = 2.88;

  for (let i = 1; i < 30; i++) {
    if (sampleTime >= (baseNumber * (i - 1)) && sampleTime < baseNumber * i) {
      dial = i;
      break;
    }
  }

  if (dial === 0 && sampleTime >= baseNumber * 29) {
    dial = 30;
  }

  return dial;
}

export function CheckValidSubSampleBarcode(barcode: string) {
   //If its a valid barcode (8 in length, no characters)
   if (barcode.length == 8 && barcode.match(/^([4]{1}[0-9]{7})|([6]{1}[0-9]{7})$/) != null) {
    return 4;
  }
  return 0;
}


export function CheckValidBarcode(barcode: string, barcodes?: string[], reuse: boolean = false): 0 | 1 | 2 | 3 | 4 {
  console.log('validating barcode: ' + barcode);



  //If its a valid barcode (8 in length, no characters)
  if (barcode.length == 8 && barcode.match(/^([1-4]{1}[0-9]{7})|([6]{1}[0-9]{7})$/) != null) {


    if (!reuse && barcodes != null) {
      const foundBarcode = barcodes.find(x => x == barcode);

      if (foundBarcode != undefined) {
       return 0;
      }
    }


    if (barcode.charAt(0) == "1") {
      return 1;
    }
    else if (barcode.charAt(0) == "2") {
      return 2;
    }
    else if (barcode.charAt(0) == "3") {
      return 3;
    }
    else if (barcode.charAt(0) == "4" || barcode.charAt(0) == "6") {
      return 4;
    }




  }

  return 0;

}

export async function AllowSample(masterSampleID: number, enterpriseCompanyID: number, growerID: number, growerProfile: string): Promise<number | null> {
  const new_allowed_sample: AllowedSample = {
    id: await GenerateNumberID(),
    master_sample_id: masterSampleID,
    enterprise_company_id: enterpriseCompanyID,
    created_at: undefined,
    updated_at: undefined
  };

  //TODO: update an existing allowed sample if it exists
  return AddToQueue("allowed_samples", new_allowed_sample, "add") ? new_allowed_sample.id : null;
}

export async function DeleteAllowedSample(master_sample_id: number, profile: string) {

  const allowed_samples = await GetOfflineStateParsed("allowed_samples");

  const index = allowed_samples.findIndex((x) =>  x.master_sample_id == master_sample_id);

  if (index > -1) {
    const new_array = allowed_samples.splice(index, 1);
    try {
      await ReplaceDataInOfflineState("allowed_samples", new_array);
      //TODO: implment full delete functionality
      // await AddToQueue("allowed_samples", to_delete, "delete")
      return true;
    }
    catch (e) {
      console.error("Error deleting allowed sample", e);
    }
  }

  return null;
}


//Save the sample record
export async function SaveSampleRecord(
  currentMasterSample:MasterSample,
   origin: string,
    sourceBin: { bin: Bin, location: BinLocation },
     sourceFields: Field[],
      destinationBin: { bin: Bin, location: BinLocation },
      dest:string, 
      destinationBuyer: Buyer, 
      destinationEnterprise: Enterprise, currentGrain: {  grain: GrainType;
  subCrop: GrainSubCrop;
  grainVarieties: GrainVariety[];},
   equipment: UserEquipment,
    sampleOption: string,
     user_id: number,
      profile: string): Promise<MasterSample> {
 
  const unix = moment().unix();
 
  const master_sample: MasterSample = {...currentMasterSample,
    id: GenerateNumberIDSync(user_id),
    user_id: user_id,
    profile: profile,
    equipment_type:sampleOption,
    sample_taken_at: unix,
    grain_type_id:currentGrain.grain.id,
    sub_crop_id:currentGrain.subCrop.id,
    created_at: undefined,
    updated_at: undefined
  };



  const ms_add = await AddToQueue("master_samples", master_sample, "add");

  if (!ms_add) return null;

  const sample_sources: SampleSource[] = [];

  if (origin === "Storage" && sourceBin?.bin != null && ValidID(sourceBin.bin.id)) {
    sample_sources.push({
      id: GenerateNumberIDSync(user_id),
      master_sample_id: master_sample.id,
      source_bin_id: sourceBin.bin.id,
      source_field_id: null,
      created_at: undefined,
      updated_at: undefined
    });
  } else if (origin === "Field" && sourceFields?.length > 0) {
    for (const field of sourceFields) {
      sample_sources.push({
        id: GenerateNumberIDSync(user_id),
        master_sample_id: master_sample.id,
        source_bin_id: null,
        source_field_id: field.id,
        created_at: undefined,
        updated_at: undefined
      });
    }
  }

  let sample_destination:SampleDestination = {
    id: GenerateNumberIDSync(user_id),
    master_sample_id: master_sample.id,
    destination_bin_id:  null,
    destination_buyer_id:  null,
    destination_enterprise_id: null,
    created_at: undefined,
    updated_at: undefined
  };

  switch(dest)
  {
    case 'Enterprise':
      sample_destination.destination_enterprise_id =  destinationEnterprise != null&& ValidID(destinationEnterprise?.id) ? destinationEnterprise.id : null;
      break;
    case 'Buyer':
      sample_destination.destination_buyer_id =  destinationBuyer != null&& ValidID(destinationBuyer?.id) ? destinationBuyer.id : null;

    break;
    case 'Storage':
      sample_destination.destination_bin_id =  destinationBin != null&& ValidID(destinationBin.bin?.id) ? destinationBin.bin.id : null;

      break;
      default:
        break;
  }


  const sample_equipment: SampleEquipment = {
    id: GenerateNumberIDSync(user_id),
    master_sample_id: master_sample.id,
    manual_entry: sampleOption === "other" && equipment.name != null ? equipment.name : null,
    equipment_id: equipment.id,
    created_at: undefined,
    updated_at: undefined
  };


  await AddToQueue("sample_source", sample_sources, "add");
  await AddToQueue("sample_destination", sample_destination, "add");
  await AddToQueue("sample_equipment", sample_equipment, "add");


  return ms_add ? master_sample : null;
}

export async function SaveSubSampleRecord(currentMasterSample:MasterSample,currentSample: SubSample, sourceBin: { bin: Bin; location: BinLocation; }, sourceFields: Field[], destinationBin: { bin: Bin; location: BinLocation; }, destinationBuyer: Buyer, destinationEnterprise: Enterprise, currentGrain: { 
  grain: GrainType;
  subCrop: GrainSubCrop;
  grainVarieties: GrainVariety[] | null;
}, equipment: UserEquipment, equipment_type: string, userID: string, profile: string): Promise<{subSample:SubSample,masterSample:MasterSample}> {
  const user_id = parseInt(userID);

  const d = new Date();
  const unix = moment().unix();
  const master_sample: MasterSample = {...currentMasterSample,
    id: GenerateNumberIDSync(user_id),
    user_id: user_id,
    profile: profile,
    sample_taken_at: unix,
    grain_type_id:currentGrain.grain.id,
    sub_crop_id:currentGrain.subCrop.id,
    created_at: undefined,
    updated_at: undefined
  };


  const added_master = await AddToQueue("master_samples", master_sample, "add");

  if (added_master) {
    currentSample.id = GenerateNumberIDSync(userID);
    currentSample.master_sample_id = master_sample.id;
    await AddToQueue("sub_samples", currentSample, "add");

    const sample_sources: SampleSource[] = [];

    if (sourceBin && ValidID(sourceBin?.bin?.id)) {
      const sample_source: SampleSource = {
        id: GenerateNumberIDSync(userID),
        master_sample_id: master_sample.id,
        source_bin_id: sourceBin.bin?.id ?? null,
        source_field_id: null,
        created_at: undefined,
        updated_at: undefined
      };
      sample_sources.push(sample_source);
    }
    else {
      for (const field of sourceFields) {
        const sample_source: SampleSource = {
          id: GenerateNumberIDSync(userID),
          master_sample_id: master_sample.id,
          source_bin_id: null,
          source_field_id: field.id,
          created_at: undefined,
          updated_at: undefined
        };

        sample_sources.push(sample_source);
      }
    }

    const sample_destination: SampleDestination = {
      id: GenerateNumberIDSync(userID),
      master_sample_id: master_sample.id,
      destination_bin_id: ValidID(destinationBin?.bin?.id) ? destinationBin?.bin?.id : null,
      destination_buyer_id: ValidID(destinationBuyer?.id) ? destinationBuyer?.id : null,
      destination_enterprise_id: ValidID(destinationEnterprise?.id) ? destinationEnterprise?.id : null,
      created_at: undefined,
      updated_at: undefined
    };

    const sample_equipment: SampleEquipment = {
      id: GenerateNumberIDSync(userID),
      master_sample_id: master_sample.id,
      manual_entry: equipment_type == "other" ? equipment.name : null,
      equipment_id:equipment_type != "other" ? equipment.id : null,
      created_at: undefined,
      updated_at: undefined
    };

    const sample_grains: SampleGrainVariety[] = [];

    if (currentGrain.grainVarieties?.length > 0) {
      for (let grain_var of currentGrain.grainVarieties) {

        let grainVarID = null;

        if(grain_var.id != 0 && ValidID(grain_var.id))
        {
          grainVarID = grain_var.id;
        }
        sample_grains.push({
          id: GenerateNumberIDSync(userID),
          grain_type_id: currentGrain.grain.id,
          grain_variety_id: grainVarID,
          master_sample_id: master_sample.id,
          created_at: undefined,
          updated_at: undefined
        });
      }
    }
    else {
      sample_grains.push({
        id: GenerateNumberIDSync(userID),
        grain_type_id: currentGrain.grain.id,
        grain_variety_id: null,
        master_sample_id: master_sample.id,
        created_at: undefined,
        updated_at: undefined
      });
    }

    await AddToQueue("sample_source", sample_sources, "add");
    await AddToQueue("sample_destination", sample_destination, "add");
    await AddToQueue("sample_equipment", sample_equipment, "add");
    await AddToQueue("sample_grain_varieties", sample_grains, "add");

    return {subSample:currentSample,masterSample:master_sample};
  }

  return null;
}

export function GetSampleTimeData(equipment: UserEquipment,masterSample:MasterSample): { dial: number, sampleTime: number, sampleSize: number } {

  let timeData: { dial: number, sampleTime: number, sampleSize: number } = { dial: 0, sampleTime: 0, sampleSize: 0 };

  timeData.sampleTime = GetSampleTime(masterSample.container_size, equipment.sem, masterSample.volume, equipment.bushels_hr_rate.toString(), 1);
  //Sample Size calculation
  timeData.sampleSize = (timeData.sampleTime * equipment.bushels_hr_rate) / 3600;
  timeData.sampleSize = Math.round(timeData.sampleSize * 1e2) / 1e2
  timeData.dial = GetDialData(timeData.sampleTime);

  return timeData;
}

//This function will check if the barcode already exists in the DB, if so we can add it to our list
//if multiple of the same barcode exists, then we got the newest one
export async function CheckIfBarcodeExists(barcode: string): Promise<{ response: number, masterSamples: MasterSample[] }> {

  const profile = await GetData('Profile');

let masterSamples:MasterSample[] = [];
  if (profile != null) {
    masterSamples = (await GetOfflineStateParsed("master_samples")).filter(x => x.barcode.toString() === barcode);

 }

  return { response: masterSamples.length > 0  ? 0 : 1, masterSamples };
}


export type RelatedSampleData = { source_name: string, destination_name: string, equipment_name: string, grain_name: string, created_at: string };
export type RelatedSampleDataMap = Map<number, RelatedSampleData>;

export async function GetNamesOfRelatedData(masterSampleList:MasterSample[]): Promise<RelatedSampleDataMap> {
  const data: RelatedSampleDataMap = new Map();

  const sample_ids = new Set(masterSampleList.map(x => x.id));


  const customCropSelection = GetOfflineStateParsed('crop_select_options');
  const grainType = GetOfflineStateParsed('grain_types');
  const grainSubCrop = GetOfflineStateParsed('grain_sub_crops');

  const sample_sources = (await GetOfflineStateParsed("sample_source")).filter(x => sample_ids.has(x.master_sample_id));
  const sample_destinations = (await GetOfflineStateParsed("sample_destination")).filter(x => sample_ids.has(x.master_sample_id));
  const bins = await GetOfflineStateParsed("bins");
const binLocs = await GetOfflineStateParsed("bin_locations");
  for (const ms of masterSampleList) {
    let source_name = "N/A";
    let destination_name = "N/A";
    let grain_name = "N/A";
    let equipment_name = "N/A";
    const sources = sample_sources.filter(x => x.master_sample_id === ms.id);
    if (sources.length > 1) {
      source_name = "Multiple Fields";
    }
    else if (sources.length === 1) {

      if (ValidID(sources[0].source_bin_id)) {
        const bin = bins.find(x => x.id === sources[0].source_bin_id);
        const binLoc = binLocs.find(x => x.id == bin.location_id);
        if (bin != null) {
          source_name = `${binLoc.location}-${bin.name}`;
         source_name = `${bin.name}`;
        }
      } else {
        source_name = (await GetOfflineStateParsed("fields")).find(x => x.id === sources[0].source_field_id).name ?? "N/A";
      }
    } else {
      console.log(`\n\nNo sources found for master sample id: ${ms}!\n\n`);
    }

    const destination = sample_destinations.find(x => x.master_sample_id === ms.id);


    if (ValidID(destination?.destination_bin_id)) {
      const bin = bins.find(x => x.id === destination.destination_bin_id);
      const binLoc = binLocs.find(x => x.id == bin.location_id);
      destination_name = `${binLoc.location}-${bin.name}`;
    }
    else if (ValidID(destination?.destination_buyer_id)) {
      const buyer = (await GetOfflineStateParsed("buyers")).find(x => x.id === destination.destination_buyer_id);
      destination_name = `${buyer.location}-${buyer.name}`;
    }
    else if (ValidID(destination?.destination_enterprise_id)) {
      destination_name = (await GetOfflineStateParsed("enterprises")).find(x => x.id === destination.destination_enterprise_id).name;
    }

    const sample_grain_varieties = (await GetOfflineStateParsed("sample_grain_varieties")).filter(x => x.master_sample_id === ms.id);
   

    // const grain_type = (await GetOfflineStateParsed("grains")).find(x => (sample_grain_varieties[0]?.grain_type_id) === x.id)?.name ?? '';
    const foundGrainType = grainType.find(x => x.id == ms.grain_type_id);
    const foundGrainSubCrop = grainSubCrop.find(x => x.id == ms.sub_crop_id);
    if(foundGrainSubCrop.id == 26 || foundGrainSubCrop.name == null || foundGrainSubCrop.name == '')
    {
      grain_name = foundGrainType.name
    }
    else
    {
      grain_name = foundGrainType.name + '-' + foundGrainSubCrop.name;
    }
    if (sample_grain_varieties.length > 1) {
      grain_name += `- Multiple Varieties`;
    }
    else if (sample_grain_varieties.length == 1) {
      const sample_grain_variety = sample_grain_varieties.pop();
      if (ValidID(sample_grain_variety.grain_variety_id)) {
        const grain_variety = (await GetOfflineStateParsed("grain_varieties")).find(x => x.id === sample_grain_variety.grain_variety_id);
        grain_name += grain_variety.name;
      }
    }


    const sample_equipment = (await GetOfflineStateParsed("sample_equipment")).find(x => x.master_sample_id === ms.id);
    if (sample_equipment?.manual_entry != null) {
      equipment_name = sample_equipment.manual_entry;
    }
    else if (ValidID(sample_equipment?.equipment_id)) {
      const equipment = (await GetOfflineStateParsed("user_equipment")).find(x => x.id === sample_equipment.equipment_id);
      equipment_name = equipment.name ?? '';
    }

    const created_at = moment.unix(ms.sample_taken_at).format("MM/DD/YYYY HH:mm");

    data.set(ms.id, { source_name, destination_name, equipment_name, grain_name, created_at });
  }

  return data;
}


export async function UpdateSampleSource(sample: MasterSample, sampleSource: SampleSource[]): Promise<SampleSource[]> {
  //TODO: implement a proper deletion of these records
  const sources = (await GetOfflineStateParsed("sample_source")).filter(x => x.master_sample_id === sample.id);
  await AddToQueue("sample_source", sources, "delete");
  const new_sources: SampleSource[] = [];
  for (const source of sampleSource) {
    new_sources.push({
      ...source,
      id: await GenerateNumberID(),
      master_sample_id: sample.id,
    });
  }
  return AddToQueue("sample_source", new_sources, "add");
}

export async function GetMasterSampleRecord(sample: MasterSample | SubSample | CombinedSampleRecord) {
  return (await GetOfflineStateParsed("master_samples")).find(x => x.id === sample.id);
}

//Validate the sample record
export function ValidateSampleRecord(currentMasterSample:MasterSample,currentSample: MasterSample, origin: string, destination: string, sourceBin: { bin: Bin; location: BinLocation; }, sourceFields: Field[], destinationBin: { bin: Bin; location: BinLocation; }, destinationBuyer: Buyer, destinationEnterprise: Enterprise,currentGrain: {
  grain: GrainType;
  subCrop: GrainSubCrop;
  grainVarieties: GrainVariety[];
}, equipment: UserEquipment, sampleOption: string):string[] {
  let errorMessages: string[] = [];

  try
  {
    if (currentMasterSample.barcode == null || (currentMasterSample.barcode.toString().length != 8 && currentMasterSample.barcode.toString().match(/^[1-3]{1}[0-9]{7}$/) == null)) {
      errorMessages.push("Primary Sample Barcode must be a valid barcode (8 digits and start with either 1,2 or 3)\n Ex: 12345678");
    }
  
    if (currentSample.container_size == null || currentSample.container_size == '') {
      errorMessages.push("Container size cannot be empty");
    }
  
    if (currentSample.volume == null || currentSample.volume <= 0 || currentSample.volume.toString().match(/^[0-9]+$/) == null) {
      errorMessages.push("Volume cannot contain letters and cannot be empty");
    }
  
    if (sampleOption == 'other' && equipment.name == '') {
      errorMessages.push('Equipment cannot be blank')
    }
    else if (sampleOption == 'manual' && !ValidID(equipment.id) && equipment.sem == '') {
      errorMessages.push('Equipment cannot be blank \n A Manual Sample Record needs equipment with a valid Sample Extractor Module');
    }
    else if (sampleOption != 'other' && (equipment == null || !ValidID(equipment?.id))) {
      errorMessages.push('Equipment cannot be blank')
    }
  
  
    if (currentGrain == null || (!ValidID(currentGrain.grain.id) || !ValidID(currentGrain.subCrop.id))) {
      errorMessages.push('Grain cannot be empty');
    }
  
  //Origin Checker
  if(origin == 'Field')
  {
    if(sourceFields.length == 0)
    {
      errorMessages.push('One or more source fields must be selected');
    }
  }
  else
  {
    if((!ValidID(sourceBin.bin.id) && !ValidID(sourceBin.location.id)) )
    {
      errorMessages.push('A Source Storage must be selected');
    }
  }
    //destination checker
    if(destination == 'Storage')
    {
      if((!ValidID(destinationBin.bin.id) && !ValidID(destinationBin.location.id)) )
      {
        errorMessages.push('A Destination Storage must be selected');
      }
    }
    else //Validate for Buyer
    {
      if((destinationBuyer == null || !ValidID(destinationBuyer.id)) && (destinationEnterprise == null || !ValidID(destinationEnterprise.id)))
      {
        errorMessages.push('A Destination Buyer must be selected');
      }
    }

  }
  catch(error)
  {
    errorMessages.push('Something went wrong');
    return errorMessages;
  }




    return errorMessages;


}




